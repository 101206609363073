import React, { useState } from 'react' 
import Navigation from '../toolsComponents/Navigation'

const MesEtudes = () => {

  const [ isLarge, setIsLarge ] = useState(true)

  const handleLargeDisplay = () => {
    setIsLarge(true)
  }

  const handleSmallDisplay = () => {
    setIsLarge(false)
  }

  return (
    <div>
      {/* <!-- MODAL SUPPRIMER UNE BANQUE --> */}
      <div className="modal fade" id="exampleModalDelBanque" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Supprimer une étude de marché</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                
            <p>Etes-vous sûr de vouloir supprimer l'étude de marché : Colmar ?</p>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="button" className="btn btn-danger">Supprimer</button>
            </div>
          </div>
        </div>
      </div>

      <Navigation granted_modules="ALL" />

      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {/* <!-- HEADER --> */}
              <div className="header">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-lg avatar-4by3">
                          <img src="/assets/img/mockups/mes-etudes-avatar.jpeg" alt="..." className="avatar-img rounded" />
                        </div>
                      </div>

                      <div className="col ml-n3 ml-md-n2">
                        {/* <!-- Pretitle --> */}
                        <h6 className="header-pretitle">
                          Vue globale
                        </h6>

                        {/* <!-- Title --> */}
                        <h1 className="header-title">
                          Mes études de marché
                        </h1>
                      </div>

                      <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                        {/* <!-- Button --> */}
                        <a href="/mockups/nouveau-projet" className="btn btn-primary d-block d-md-inline-block lift">
                          <i className="fe fe-plus"></i> Nouvelle étude de marché
                        </a>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>

              {/* Search and toggle */}
              <div className="row mb-4">
                <div className="col">

                  <form>
                    <div className="input-group input-group-lg input-group-merge">

                      <input type="text" className="form-control form-control-prepended list-search" placeholder="Rechercher" />

                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fe fe-search"></span>
                        </div>
                      </div>

                    </div>
                  </form>

                </div>
                <div className="col-auto">

                  <div className="nav btn-group" role="tablist">
                    <button className="btn btn-lg btn-white active" data-toggle="tab" data-target="#tabPaneOne" role="tab" aria-controls="tabPaneOne" aria-selected="true" onClick={handleLargeDisplay}>
                      <span className="fe fe-grid"></span>
                    </button>
                    <button className="btn btn-lg btn-white" data-toggle="tab" data-target="#tabPaneTwo" role="tab" aria-controls="tabPaneTwo" aria-selected="false"  onClick={handleSmallDisplay}>
                      <span className="fe fe-list"></span>
                    </button>
                  </div> 

                </div>
              </div>

              {/* CARDS */}
              <div className="row">

                {/* <!-- Ville 1 Colmar --> */}
                <div className="col-6">
                  <div className="card">
                    <div className="card-body">

                      <div className="mb-3">
                        <div className="row align-items-center">
                          <div className="col-auto">

                            <a href="#!" className="avatar">
                              <img src="/assets/img/mockups/colmar-avatar.png" alt="..." className="avatar-img rounded-circle" />
                            </a>

                          </div>
                          <div className="col ml-n2">

                            <h4 className="mb-1">
                              <a className="item-name text-reset" href="/mockups/mes-etudes/Colmar">Colmar - Haut-Rhin (68 000)</a>
                            </h4>

                          </div>
                          <div className="col-auto">

                            <div className="dropdown">
                              <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe fe-more-vertical"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a href="#!" className="dropdown-item">
                                  <i className="fe fe-trash"></i> Supprimer
                                </a>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <p className="mb-3">
                        <strong>Tarifs</strong> <br />
                        Prix d'achat moyen au m² pour un appartement : <strong>1958 € / m²</strong><br />
                        Evolution : &nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>+3,4%</strong> en 1 an</a>&nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>+1,8%</strong> en 2 ans</a>&nbsp;
                          <a href="#!" className="badge badge-soft-warning"><strong>+8,8%</strong> en 5 ans</a>
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Démographie</strong> <br />
                        Population actuelle : <strong>68 703 habitants</strong><br />
                        Evolution : &nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>69 889</strong> il y a 5 ans</a>&nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>67 409</strong> il y a 10 ans</a>&nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>63 498</strong> il y a 20 ans</a>
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Quartiers ideaux</strong><br />
                        Centre, Gare, Maraichers<br /><br />
                        <strong>Quartiers à éviter</strong><br />
                        Ouest, Nord
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Transports</strong><br />
                        <div className="row">
                          <div className="col">
                          Distance d'une autoroute : <a href="#!" className="badge badge-soft-success"><strong>1 km</strong></a><br />
                          Distance d'une nationale : <a href="#!" className="badge badge-soft-success"><strong>1 km</strong></a><br />
                          Distance d'une départementale : <a href="#!" className="badge badge-soft-success"><strong>1 km</strong></a>
                          </div>

                          <div className="col">
                            Aéroport : <a href="#!" className="badge badge-soft-danger"><strong>Non</strong></a><br />
                            Gare ferrovière : <a href="#!" className="badge badge-soft-success"><strong>Oui</strong></a><br />
                            Gare routière : <a href="#!" className="badge badge-soft-success"><strong>Oui</strong></a>
                          </div>
                        </div>
                        
                        
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Infrastructures</strong><br />
                        Hôpital : <a href="#!" className="badge badge-soft-success"><strong>Oui</strong></a><br />
                        &Eacute;cole supérieure : <a href="#!" className="badge badge-soft-success"><strong>Oui</strong></a>
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Nuisances</strong><br />
                        Zone innondable : <a href="#!" className="badge badge-soft-warning"><strong>Oui</strong></a><br />
                        Zone de bruits : <a href="#!" className="badge badge-soft-warning"><strong>Oui</strong></a>
                      </p>

                      <p className="mb-3">
                        <strong>Notes</strong><br />
                        Plus belle ville au monde
                      </p>

                    </div>
                  </div>
                </div>

                {/* <!-- Ville 2 Eguisheim --> */}
                <div className="col-6">
                  <div className="card">
                    <div className="card-body">

                      <div className="mb-3">
                        <div className="row align-items-center">
                          <div className="col-auto">

                            <a href="#!" className="avatar">
                              <img src="/assets/img/mockups/eguisheim-avatar.png" alt="..." className="avatar-img rounded-circle" />
                            </a>

                          </div>
                          <div className="col ml-n2">

                            <h4 className="mb-1">
                              <a className="item-name text-reset" href="/mockups/mes-etudes/Colmar">Eguisheim - Haut-Rhin (68 420)</a>
                            </h4>

                          </div>
                          <div className="col-auto">

                            <div className="dropdown">
                              <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe fe-more-vertical"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a href="#!" className="dropdown-item">
                                  <i className="fe fe-trash"></i> Supprimer
                                </a>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <p className="mb-3">
                        <strong>Tarifs</strong> <br />
                        Prix d'achat moyen au m² pour un appartement : <strong>2784 € / m²</strong><br />
                        Evolution : &nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>+2,2%</strong> en 1 an</a>&nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>+2,9%</strong> en 2 ans</a>&nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>+4,3%</strong> en 5 ans</a>
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Démographie</strong> <br />
                        Population actuelle : <strong>1 723 habitants</strong><br />
                        Evolution : &nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>1 738</strong> il y a 5 ans</a>&nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>1 622</strong> il y a 10 ans</a>&nbsp;
                          <a href="#!" className="badge badge-soft-success"><strong>1 548</strong> il y a 20 ans</a>
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Quartiers ideaux</strong><br />
                        Centre, Vignoble<br /><br />
                        <strong>Quartiers à éviter</strong>
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Transports</strong><br />
                        <div className="row">
                          <div className="col">
                          Distance d'une autoroute : <a href="#!" className="badge badge-soft-warning"><strong>10 km</strong></a><br />
                          Distance d'une nationale : <a href="#!" className="badge badge-soft-success"><strong>4 km</strong></a><br />
                          Distance d'une départementale : <a href="#!" className="badge badge-soft-success"><strong>1 km</strong></a>
                          </div>

                          <div className="col" style={{display: isLarge ? 'block' : 'none'}}>
                            Aéroport : <a href="#!" className="badge badge-soft-danger"><strong>Non</strong></a><br />
                            Gare ferrovière : <a href="#!" className="badge badge-soft-danger"><strong>Non</strong></a><br />
                            Gare routière : <a href="#!" className="badge badge-soft-danger"><strong>Non</strong></a>
                          </div>
                        </div>
                        
                        
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Infrastructures</strong><br />
                        Hôpital : <a href="#!" className="badge badge-soft-danger"><strong>Non</strong></a><br />
                        &Eacute;cole supérieure : <a href="#!" className="badge badge-soft-danger"><strong>Non</strong></a>
                      </p>

                      <p className="mb-3" style={{display: isLarge ? 'block' : 'none'}}>
                        <strong>Nuisances</strong><br />
                        Zone innondable : <a href="#!" className="badge badge-soft-success"><strong>Non</strong></a><br />
                        Zone de bruits : <a href="#!" className="badge badge-soft-success"><strong>Non</strong></a>
                      </p>

                      <p className="mb-3">
                        <strong>Notes</strong><br />
                        Charmant village typique alsacien au coeur du vignoble et sur la route des vins avec vue sur plusieurs chateaux
                      </p>

                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default MesEtudes