import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const SimpleAreaChart = ({ data, XDataKey, YDataKey}) => {

  return (
    <>
      <ResponsiveContainer width="100%" height={450}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={XDataKey} />
          <YAxis domain={['auto', 'auto']} />
          <Tooltip />
          <Area type="monotone" dataKey={YDataKey} stroke="#335cca" fill="#335eea" />
        </AreaChart>
      </ResponsiveContainer>
    </>
  )
}

export default SimpleAreaChart