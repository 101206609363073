import React from 'react'

class LeCredit extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      apportPersonnel: null,
      fraisGarantie: null,
      fraisDossier: null,
      dureeDiffere: null,
      dureeTotaleCredit: null,
      tauxHorsAssurance: null,
      tauxAssurance: null,
      montantAFinance: null,
      coutFraisGarantie: null,
      capitalEmprunteTotal:null,
      mensualite:null,
    };
  }

  handleChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const valueAsNumber = target.valueAsNumber;

   await this.setState({
      [name]: valueAsNumber || value
    });

    this.montantAFinance();
    this.fraisGarantie();
    this.totalCapitalEmprunté();
    this.mensualiteCredit();

  if(typeof this.props.onChange === 'function') this.props.onChange(this.state);

  }

  montantAFinance = () => {
    const infos = this.props.infosGeneralesProjet;
    this.setState({
      montantAFinance: infos.coutTotalProjet - infos.coutMeubles - this.state.apportPersonnel,
    });
  
  }

  fraisGarantie = () => {
    this.setState({
      coutFraisGarantie: ((this.props.infosGeneralesProjet.coutTotalProjet)*this.state.fraisGarantie/100),
    });
  
  }

  totalCapitalEmprunté = () => {
    this.setState({
      capitalEmprunteTotal: this.state.coutFraisGarantie + this.state.montantAFinance + Number(this.state.fraisDossier),
    });
  }

  tauxTotal = () => {
    return(Number(this.state.tauxAssurance) + Number(this.state.tauxHorsAssurance));
  }

  mensualiteCredit = () => {
    this.setState({
      mensualite: Number((((this.state.capitalEmprunteTotal * (this.tauxTotal()/100))/12) / (1-(Math.pow((1+(this.tauxTotal()/100/12)),-this.state.dureeTotaleCredit)))).toFixed())
    });
  }

  componentDidMount(){
    this.montantAFinance();
    this.fraisGarantie();
    this.totalCapitalEmprunté();
    this.mensualiteCredit();
  }

  componentDidUpdate(prevProps) {
 
    if (this.props.infosGeneralesProjet !== prevProps.infosGeneralesProjet) {
      this.setState({
        montantAFinance: this.props.infosGeneralesProjet.coutTotalProjet - this.props.infosGeneralesProjet.coutMeubles - this.state.apportPersonnel,
        capitalEmprunteTotal: this.props.infosGeneralesProjet.coutTotalProjet - this.props.infosGeneralesProjet.coutMeubles - this.state.apportPersonnel + this.state.coutFraisGarantie + Number(this.state.fraisDossier)
      });
    }
    
    
  }

  render(){

  return(<>

<div className="col-12 mt-4">
                  <h2 style={{ textDecoration: "underline", fontWeight: "800"}}>LE CRÉDIT:</h2>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Apport Personnel</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="apportPersonnel"
                        value={this.state.apportPersonnel}
                        onChange={this.handleChange}
                        placeholder="ex: 25000"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Montant à financer</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="coutTotalProjet"
                        value={this.state.montantAFinance + " €"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Frais de garantie %</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisGarantie"
                        value={this.state.fraisGarantie}
                        onChange={this.handleChange}
                        placeholder="ex: 0.5"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Coût frais de garantie</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="coutFraisGarantie"
                        value={this.state.coutFraisGarantie + " €"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Frais de dossier</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisDossier"
                        value={this.state.fraisDossier}
                        onChange={this.handleChange}
                        placeholder="ex: 750"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Capital Emprunté Total</label>
                    <div className="input-group mb-2">
                    <input
                        type="text"
                        className="form-control"
                        name="capitalEmprunteTotal"
                        value={this.state.capitalEmprunteTotal + " €"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Durée de différé</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="dureeDiffere"
                        value={this.state.dureeDiffere}
                        onChange={this.handleChange}
                        placeholder="ex: 18"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Durée totale du crédit</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="dureeTotaleCredit"
                        value={this.state.dureeTotaleCredit}
                        onChange={this.handleChange}
                        placeholder="ex: 240"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Taux hors assurance</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="tauxHorsAssurance"
                        value={this.state.tauxHorsAssurance}
                        onChange={this.handleChange}
                        placeholder="ex: 1.15"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Taux assurance</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="tauxAssurance"
                        value={this.state.tauxAssurance}
                        onChange={this.handleChange}
                        placeholder="ex: 0.35"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Mensualités</label>
                    <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                    <input
                        type="number"
                        className="form-control"
                        name="mensualite"
                        value={this.state.mensualite}
                        readOnly
                      />
                    </div>
                  </div>
                </div>



    </>)
  }
  return 
}


export default LeCredit;