import React from 'react'
import InputForm from '../../../../toolsComponents/forms/InputForm';
import SelectTypeLogement from '../../../../toolsComponents/SelectTypeLogement';

class LogementRevenus extends React.Component {
  
  constructor(props){
    super(props);

    if(this.props.initalState) {
      // With initial data
      this.state = {
        numerologementdansArray: this.props.numerologement,
        typelogement:this.props.initalState.typelogement,
        loyerNueHcEstime:this.props.initalState.loyerNueHcEstime,
        loyerMeubleHcEstime:this.props.initalState.loyerMeubleHcEstime,
        vacanceLocative: this.props.initalState.vacanceLocative,
        prixNuitLCD: this.props.initalState.prixNuitLCD,
        fraisSupplementaires: this.props.initalState.fraisSupplementaires,
        nombreNuitsMoyen: this.props.initalState.nombreNuitsMoyen,
        nombreRotationsMois: this.props.initalState.nombreRotationsMois,
        revenusTotauxLDNue: this.props.initalState.revenusTotauxLDNue,
        revenusTotauxLDMeuble: this.props.initalState.revenusTotauxLDMeuble,
        revenusTotauxLCD: this.props.initalState.revenusTotauxLCD,
      }
    }
    else {
      // There are no initial data
      this.state = {
      numerologementdansArray: this.props.numerologement,
      typelogement:"t1",
      loyerNueHcEstime:null,
      loyerMeubleHcEstime:null,
      vacanceLocative: null,
      prixNuitLCD: null,
      fraisSupplementaires: null,
      nombreNuitsMoyen: null,
      nombreRotationsMois: null,
      revenusTotauxLDNue: 0,
      revenusTotauxLDMeuble: 0,
      revenusTotauxLCD: 0,
    };
  }
}

  handleChange = async (event) => {
    await this.setState({
      [event.inputName]: event.value
    });

    this.setState({revenusTotauxLDNue: parseFloat((this.state.loyerNueHcEstime * (1-(this.state.vacanceLocative)/12)).toFixed(2))})

    this.setState({revenusTotauxLDMeuble: parseFloat((this.state.loyerMeubleHcEstime * (1-(this.state.vacanceLocative)/12)).toFixed(2))})

    this.setState({revenusTotauxLCD: parseFloat((this.state.prixNuitLCD * this.state.nombreNuitsMoyen + this.state.fraisSupplementaires * this.state.nombreRotationsMois))})

  if(typeof this.props.onChange === 'function') this.props.onChange(this.state);

  }

  componentDidMount(){
    this.props.onChange(this.state);
  }

  componentWillUnmount(){
    this.props.onChange(this.state);
  }
  
render(){
  return (<>
    <div className='row'>
      <div className='col-8'>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>
              Logement n°{this.props.numerologement}
            </h4>
          </div>

          <div className='card-body'>
            <div className='row'>
              <div className="col-6">
                <SelectTypeLogement
                  label='Type de logement'
                  inputName="typelogement"
                  value={this.state.typelogement}
                  onChange={this.handleChange}
                />
              </div>

              <div className="col-6">
                <InputForm
                  label={"Loyer longue durée nue HC"}
                  inputName="loyerNueHcEstime"
                  type="number"
                  value={this.state.loyerNueHcEstime}
                  onChange={this.handleChange}
                  placeholder="ex: 400"
                  prepend={"€/mois"}
                />
              </div>

              <div className="col-6">
                <InputForm
                  label={"Vacance locative pour la longue durée"}
                  inputName="vacanceLocative"
                  type="number"
                  value={this.state.vacanceLocative}
                  onChange={this.handleChange}
                  placeholder="ex: 2"
                  prepend={"Nb de mois / an"}
                />
              </div>

              <div className="col-6">
                <InputForm
                  label={"Loyer longue durée meublé HC"}
                  inputName="loyerMeubleHcEstime"
                  type="number"
                  value={this.state.loyerMeubleHcEstime}
                  onChange={this.handleChange}
                  placeholder="ex: 600"
                  prepend={"€/mois"}
                />
              </div>

              <div className="col-3">
                <InputForm
                  label={"Prix estimé LCD par nuit"}
                  inputName="prixNuitLCD"
                  type="number"
                  value={this.state.prixNuitLCD}
                  onChange={this.handleChange}
                  placeholder="ex: 59"
                  prepend={"€/nuit"}
                />
              </div>

              <div className="col-3">
                <InputForm
                  label={"Nb nuits louées par mois"}
                  inputName="nombreNuitsMoyen"
                  type="number"
                  value={this.state.nombreNuitsMoyen}
                  onChange={this.handleChange}
                  placeholder="ex: 21"
                  prepend={"Nb nuits"}
                />
              </div>

              <div className="col-3">
                <InputForm
                  label={"Frais suppl. facturés LCD"}
                  inputName="fraisSupplementaires"
                  type="number"
                  value={this.state.fraisSupplementaires}
                  onChange={this.handleChange}
                  placeholder="ex: 29"
                  prepend={"€/séjour"}
                  tooltip={"ex: ménage"}
                />
              </div>

              <div className="col-3">
                <InputForm
                  label={"Nb rotations par mois"}
                  inputName="nombreRotationsMois"
                  type="number"
                  value={this.state.nombreRotationsMois}
                  onChange={this.handleChange}
                  placeholder="ex: 9"
                  prepend={"Nb rotations"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-4'>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-header-title'>
              Revenus bruts du logement n°{this.props.numerologement}
            </h4>
          </div>

          <div className='card-body'>
            <div className="col-12">
              <InputForm
                label={"Revenus bruts en location longue durée nue"}
                inputName="revenusTotauxLDNue"
                type="number"
                value={this.state.revenusTotauxLDNue}
                readOnly
                prepend={"€/mois"}
                tooltip={"= loyer longue durée nue - le prorata de la vacance locative"}
              />
            </div>

            <div className="col-12">
              <InputForm
                label="Revenus bruts en location longue durée meublée"
                inputName="revenusTotauxLDMeuble"
                type="number"
                value={this.state.revenusTotauxLDMeuble}
                readOnly
                prepend={"€/mois"}
                tooltip={"= loyer longue durée meublée - le prorata de la vacance locative"}
              />
            </div>

            <div className="col-12">
              <InputForm
                label={"Revenus bruts en location courte durée"}
                inputName="revenusTotauxLCD"
                type="number"
                value={this.state.revenusTotauxLCD}
                readOnly
                prepend={"€/mois"}
                tooltip={"= (prix par nuit X nbre de nuits louées par mois) + (frais supplémentaires X nombre de rotations par mois)"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

                

            

                

                

                


                


                

    </>)

}}


export default LogementRevenus;