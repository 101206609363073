import React, { useEffect } from "react";
import { useImperativeHandle } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Navigation from "../../toolsComponents/Navigation";
import SimpleContainedError from "../../toolsComponents/SimpleContainedError";
import SimpleLoading from "../../toolsComponents/SimpleLoading";
import UnBienHeader from "./UnBienHeader";
import {
  performDelUnBien,
  performGetUnBien,
  performSyncUnBien,
  unBienDatasSelector,
  unBienStatusSelector,
  updateByField,
} from "./unBienSlice";

const UnBienGenericWithStore = React.forwardRef(
  (
    { children, activeMenu, activeSubMenu, formationCategories = "bien", id },
    ref
  ) => {
    useImperativeHandle(ref, () => {
      return {
        bien,
        handleChange,
        handleSubmit,
        handleAddDocument,
      };
    });

    // FOR MOCKUP
    const granted_modules = "ALL";

    const dispatch = useDispatch();
    const [cookies] = useCookies(["token", "email"]);

    const bien = useSelector(unBienDatasSelector);
    const { hasError, errorMessage, loading, lastAction } =
      useSelector(unBienStatusSelector);

    useEffect(() => {
      // Get current bien
      dispatch(performGetUnBien(cookies["token"], id));
    }, [dispatch, cookies, id]);

    const handleAddDocument = () => {
      return;
    };

    const handleChange = (e) => {
      dispatch(updateByField({ field: e.inputName, value: e.value }));
    };

    const handleSubmit = () => {
      dispatch(performSyncUnBien(cookies["token"], bien));
    };

    const handleDelete = () => {
      dispatch(performDelUnBien(cookies["token"], bien));
    };

    if (!loading && !hasError && lastAction === "DEL") {
      return <Redirect to="/patrimoine" />;
    } else {
      return (
        <>
          <Navigation
            granted_modules={granted_modules}
            formationCategories={formationCategories}
          />

          {hasError && (
            <SimpleContainedError>{errorMessage}</SimpleContainedError>
          )}

          {(!hasError || lastAction === "DEL") && (
            <div className="main-content">
              <UnBienHeader
                bien={bien}
                isActive={activeMenu}
                isActiveSubMenu={activeSubMenu}
                onDelete={handleDelete}
              />

              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-12">
                    {loading && <SimpleLoading />}

                    {!loading && children}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  }
);

export default UnBienGenericWithStore;
