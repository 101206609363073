import React from 'react'
import SimpleWarning from '../../../toolsComponents/SimpleWarning'
import UneEtudeValeursFoncieresForm from './UneEtudeValeursFoncieresForm'

const UneEtudeValeursFoncieresNoData = ({ etude, notEnoughtData = true, withButton = true, onChange, onSubmit }) => {

  const clearNomURL = () => {
    return etude.commune?.nom.replace(' ', '-').replace("'", '-').toLowerCase()
  }

  const clearCPURL = () => {
    return etude.commune?.cp.split('/')[0]
  }

  return (
    <>
      <div className="row">
        <div className="col">
          {!notEnoughtData &&
            <SimpleWarning>
              Malheureusement il n'est pas possible de calculer automatiquement de valeurs foncières pour cette ville. Les notaires de cette région ne fournissent pas les informations dans le système informatisé de l'INSEE.
            </SimpleWarning>
          }

          {notEnoughtData &&
            <SimpleWarning>
              Malheureusement il n'y a pas assez de transactions dans la base de l'INSEE pour cette ville. Il est donc impossible de calculer automatiquement les valeurs foncières.
            </SimpleWarning>
          }
        </div>
      </div>

      <div className="row">
        <div className="col" style={{textAlign:'center'}}>
          <p>Pour avoir une estimation du prix au mètre carré pour cette ville, <a href={`https://www.meilleursagents.com/prix-immobilier/${clearNomURL()}-${clearCPURL()}/`} target="_blank" rel="noreferrer">vous pouvez utiliser les données de Meilleurs Agents</a> et saisir les informations dans les champs ci-dessous.</p>
        </div>
      </div>

      <br />

      <UneEtudeValeursFoncieresForm
        etude={etude}
        withButton={withButton}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default UneEtudeValeursFoncieresNoData