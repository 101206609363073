import React from 'react'

const SelectVersatile = ({ label, options=[{title:"Bonjour",value:"bonjour"}], inputName, value, onChange }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  let listOptions = options.map((option) => {

    return <option value={option.value}>{option.title}</option>

  })

  return (
    <div className="form-group">
      <label htmlFor={inputName+'-select'}>
        {label}
      </label>

      <select className="form-control" name={inputName} id={inputName+'-select'} onChange={handleChange} value={value}>
        {listOptions}
      </select>
    </div>
  )
}

export default SelectVersatile