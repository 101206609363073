import React from 'react'
import Navigation from '../toolsComponents/Navigation'

const ChromeExtention = () => {

  return <>
    <Navigation formationCategories={"chrome extension"} />

    <div className="main-content">

      {/* <!-- HEADER --> */}
      <div className="pt-7 pb-8 bg-dark bg-ellipses">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">

              {/* <!-- Title --> */}
              <h1 className="display-3 text-center text-white">
                <img 
                  style={{width:'80px', verticalAlign:'middle', marginTop:'-10px'}}
                  src="https://www.freepnglogos.com/uploads/google-chrome-png-logo/google-chrome-logo-png-0.png" 
                  alt="chrome logo" />
                &nbsp;Chrome Extension
              </h1>

              {/* <!-- Text --> */}
              <p className="lead text-center text-muted">
                Récupère et intègre les annonces immobilères dans projetimmo.io
              </p>

            </div>
          </div> 
        </div>
      </div>

      {/* <!-- CONTENT --> */}
      <div className="container-fluid">
        <div className="row mt-n7">
          <div className="col-2"></div>
          
          <div className="col-8">

            {/* <!-- Card --> */}
            <div className="card">
              <div className="card-body">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                  <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img className="d-block w-100" src="/assets/img/chrome-extension/step1.png" alt="First slide" />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src="/assets/img/chrome-extension/step2.png" alt="Second slide" />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src="/assets/img/chrome-extension/step3.png" alt="Third slide" />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src="/assets/img/chrome-extension/step4.png" alt="Third slide" />
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Préc.</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Suiv.</span>
                  </a>
                </div>
                <br />

                {/* <!-- Title --> */}
                <h3 className="text-uppercase text-center text-muted my-4">
                  Présentation
                </h3>

                <p>Extension Chrome qui permet de récupérer des données de sites d'annonces et les importer dans votre compte projetimmo.io</p>
                <p>Projetimmo.io pour chrome est l'extension compagnon de votre outil projetimmo.io - l'accélérateur des investisseurs immobilier.</p>

                <p>Projetimmo.io accompagne les investisseurs tout au long du cycle d'investissement, de l'étude de marché, en passage par la recherche de financement et la gestion des travaux et de leurs patrimoine.</p>

                <p>Pour le moment, l'extension sert à importer les données des annonces immobilières du site leboncoin.fr & seloger.com directement dans votre dashboard projetimmo.io afin de pouvoir les analyser plus en profondeur, centraliser les données et gérer vos projets d'investissements.</p>

                {/* <!-- Button --> */}
                <a href="https://chrome.google.com/webstore/detail/projetimmoio/pbnnlncpognllidijpojigihcpbmilao" target="_blank" rel="noreferrer" className="btn btn-block btn-primary">
                  C'est parti !
                </a>

              </div>
            </div>

          </div>
          
          <div className="col-2"></div>
        </div> 
      </div>

    </div> 
  </>
}

export default ChromeExtention