import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { performGetUnProcess, registerDatasSelector, registerStatusSelector } from './registerSlice'
import Register2Dispatcher from './Register2Dispatcher'

const RegisterWithStore = () => {

  const { url } = useParams()

  const dispatch = useDispatch()
  const registerData = useSelector(registerDatasSelector)

  useEffect(() => {
    dispatch(performGetUnProcess(url))
  }, [])

  return <>

    <Register2Dispatcher 
      register_data={registerData}
    />
  </>
}

export default RegisterWithStore