import React from 'react'
import { useParams } from 'react-router'
import UneBanqueWithStore from './UneBanqueWithStore'
import UneBanqueOffresPretWithStore from './UneBanqueOffresPretWithStore'

const UneBanqueDispatcher = () => {
  const { id, page } = useParams()

  switch(page) {
    case undefined: 
      return <UneBanqueWithStore id={id} />
    case 'offres-de-pret':
      return <UneBanqueOffresPretWithStore id={id} />
    default:
      return <UneBanqueWithStore id={id} />
  }
}

export default UneBanqueDispatcher