import React from 'react'

const UnProjetHeader = ({ isActive, isActiveSubMenu }) => {

    return (
        <div>

            {/* <!-- HEADER --> */}
            <div className="header" style={{marginBottom:"0px"}}>

                {/* <!-- Image --> */}
                <img src="/assets/img/mockups/mes-projets-banniere-3.jpeg" className="header-img-top" alt="..." 
                    style={{height:'250px', objectFit:'cover'}} />

                <div className="container-fluid">

                    {/* <!-- Body --> */}
                    <div className="header-body mt-n5 mt-md-n6" style={{borderBottom:"0px"}}>
                            <div className="row align-items-end">
                                <div className="col-auto">

                                    {/* <!-- Avatar --> */}
                                    <div className="avatar avatar-xxl header-avatar-top">
                                        <img src="/assets/img/mockups/mes-projets-header-avatar-1.jpeg" alt="..." className="avatar-img rounded border border-4 border-body" />
                                    </div>

                                </div>

                                <div className="col mb-3 ml-n3 ml-md-n2">

                                    {/* <!-- Pretitle --> */}
                                    <h6 className="header-pretitle">
                                        Mon projet
                                    </h6>

                                    {/* <!-- Title --> */}
                                    <h1 className="header-title">
                                        Chalet en pin douglas à Florac Trois Rivières
                                    </h1>

                                </div>

                                
                                
                        </div> {/* <!-- / .row --> */}

                        <ul className="nav nav-tabs">
                        <li className="nav-item dropdown">
                                <a className={isActive === 'general' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown" href="#!" role="button" aria-haspopup="true" aria-expanded="false">Général</a>
                                <div className="dropdown-menu">
                                
                                <a className={isActive === 'general' && isActiveSubMenu === 'resume' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres">Résumé</a>
                                
                                <a className={isActiveSubMenu === 'photos' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/photos">Photos</a>
                                
                                <a className={isActiveSubMenu === 'contacts' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/contacts">Contacts</a>
                                
                                <a className={isActiveSubMenu === 'rdv' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/rdv">Rendez-vous</a>
                                
                                <a className={isActiveSubMenu === 'annexes' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/annexes">Annexes</a>
                                
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <a className={isActive === 'edm' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown" href="#!" role="button" aria-haspopup="true" aria-expanded="false">Etude de marché</a>
                                <div className="dropdown-menu">
                                
                                <a className={isActive === 'edm' && isActiveSubMenu === 'resume' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/etude-de-marche">Résumé</a>
                                
                                <a className={isActiveSubMenu === 'situation-geographique' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/situation-geographique">Situation géographique</a>
                                
                                <a className={isActiveSubMenu === 'situation-demographique' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/statistiques">Statistiques</a>
                                
                                {/* <a className={isActiveSubMenu === 'notation' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/notation" style={{textDecoration:"line-through"}}>Notation</a>
                                 */}
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <a className={isActive === 'financement' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown" href="#!" role="button" aria-haspopup="true" aria-expanded="false">Achat & financement</a>
                                <div className="dropdown-menu">
                                
                                <a className={isActive === 'financement' && isActiveSubMenu === 'resume' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/financement">Résumé</a>
                                
                                <a className={isActiveSubMenu === 'emprunteurs' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/emprunteurs">Emprunteurs supplémentaires</a>
                                
                                <a className={isActiveSubMenu === 'plan-financement' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/plan-financement">Plan de financement</a>
                                
                                <a className={isActiveSubMenu === 'offres-prets' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/offres-prets">Offres de prêts</a>
                                
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <a className={isActive === 'travaux' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-toggle="dropdown" href="#!" role="button" aria-haspopup="true" aria-expanded="false">Travaux</a>
                                <div className="dropdown-menu">
                                
                                <a className={isActive === 'travaux' && isActiveSubMenu === 'resume' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/travaux">Résumé</a>

                                <a className={isActiveSubMenu === 'simulationtravaux' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/simulationtravaux">Simulation de travaux</a>
                                
                                <a className={isActiveSubMenu === 'plans' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/plans">Plans</a>
                                
                                <a className={isActiveSubMenu === 'devis' ? 'dropdown-item active' : 'dropdown-item'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/devis">Devis</a>
                                
                                </div>
                            </li>
                            
                            {/* <li className="nav-item">
                                <a className={isActive === 'organisation' ? 'nav-link active' : 'nav-link'} href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/organisation">Organisation</a>
                            </li> */}
                        </ul>

                    </div> {/* <!-- / .header-body --> */}

                    

                </div>

                

            </div> {/* <!-- / .header --> */}

            

        </div>
    )
}

export default UnProjetHeader