import React, { useState } from 'react'
import PasswordInputForm from '../../../toolsComponents/forms/PasswordInputForm'

const Login = ({ onLogin, email, password, onEmailChanged, onPasswordChanged }) => {

  const [ emailIsValid, setEmailIsValid] = useState(true)
  const [ passwordIsValid, setPasswordIsValid] = useState(true)

  const handleEmailChanged = (e) => {
    onEmailChanged(e.target.value)
  }

  const handlePasswordChanged = (e) => {
    onPasswordChanged(e.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let emailOk = true
    let passwordOk = true

    // Errors management
    if( !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      emailOk = false
    }
    else if( email === '' ) {
      emailOk = false
    }

    if( password.length < 6 ) {
      passwordOk = false
    }

    setEmailIsValid(emailOk)
    setPasswordIsValid(passwordOk)

    if( emailOk && passwordOk ) {
        
      onLogin(email, password)
    }
  }

  return (
    <div>
      <img src="/LogoProjectImmoVectorized.svg" alt="Logo Projet Immo"/>
      {/* Heading */}
      <h1 className="display-4 text-center mb-3 mt-5">
          Se connecter
      </h1>

      {/* Sub-Heading */}
      <p className="text-muted text-center mb-5">
          Accédez à votre compagnon d'investissement immobilier
      </p>

      <form onSubmit={handleSubmit}>

        {/* Email field */}
        <div className="form-group">
          <label>Adresse email</label>

          <input 
            type="email" 
            className="form-control" placeholder="nom@domaine.com" 
            value={email}
            onChange={handleEmailChanged}
            tabIndex="1"
            />
        </div>

        {/* Email errors management */}
        {!emailIsValid &&
          <div className="alert alert-danger" role="alert">
              Format de l'email invalide
          </div>
        }

        {/* Password */}
        <PasswordInputForm
          label="Mot de passe"
          withMotDePasseOublie={true}
          value={password}
          placeholder="Entrer votre mot de passe" 
          onChange={handlePasswordChanged}
        />

        {/* Password error management */}
        {!passwordIsValid &&
          <div className="alert alert-danger" role="alert">
              Au moins 6 caractères
          </div>
        }

        {/* Submit button */}
        <button className="btn btn-lg btn-block btn-primary mb-3" tabIndex="3">
            Se connecter
        </button>

        {/* <p className="text-center">
          <a href="/register">
            Vous n'avez pas encore de compte ?
          </a>
        </p> */}
  
      </form>
    </div>
  )
}

export default Login