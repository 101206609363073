import React from 'react'

class CashflowETRenta extends React.Component {

  constructor(props){
    super(props);
    this.state = {

      mensualiteCredit: null,
      coutTotalReno: null,
      coutTotalProjet: null,


      revenusTotalLDNue:null,
      chargesTotalLDNue:null,
      cashFlowLDNue: null,
      rentaBruteLDNue: null,
      rentaNetteLDNue: null,
  

      revenusTotalLDMeuble: null,
      chargesTotalLDMeuble:null,
      cashFlowLDMeuble: null,
      rentaBruteLDMeuble: null,
      rentaNetteLDMeuble: null,

      revenusTotalLCD: null,
      chargesTotalLCD: null,
      cashFlowLCD: null,
      rentaBruteLCD: null,
      rentaNetteLCD: null,

    };
  }


  async componentDidUpdate(prevProps) {
 
    if (this.props.revenus !== prevProps.revenus) {
      await this.setState({
      revenusTotalLDNue: Number(this.props.revenus.revenusTotalTousLogementsLDNue),
      revenusTotalLDMeuble: Number(this.props.revenus.revenusTotalTousLogementsLDMeuble),
      revenusTotalLCD: Number(this.props.revenus.revenusTotalTousLogementsLCD),
      })
    }

    if (this.props.leCredit !== prevProps.leCredit) {
      await this.setState({
      mensualiteCredit: Number(this.props.leCredit.mensualite),
      })
    }

    if (this.props.charges !== prevProps.charges) {
      await this.setState({
        chargesTotalLDNue: Number(this.props.charges.totalChargesMensuellesLDNue),
        chargesTotalLDMeuble: Number(this.props.charges.totalChargesMensuellesLDMeuble),
        chargesTotalLCD: Number(this.props.charges.totalChargesMensuellesLCD),
      })
    }

    if (this.props.informationsGenerales !== prevProps.informationsGenerales) {
      await this.setState({
        coutTotalReno: Number(this.props.informationsGenerales.coutTotalReno),
        coutTotalProjet: Number(this.props.informationsGenerales.coutTotalProjet),
      })
    }

    if(this.props !== prevProps){
      this.setState({
        rentaBruteLDNue: Number(((this.state.revenusTotalLDNue*12 / this.state.coutTotalReno)*100).toFixed(2)),
        rentaNetteLDNue: Number(((this.state.revenusTotalLDNue*12-this.state.chargesTotalLDNue*12-this.state.mensualiteCredit*12)*100 / this.state.coutTotalReno).toFixed(2)),
        cashFlowLDNue: Number((this.state.revenusTotalLDNue-this.state.chargesTotalLDNue-this.state.mensualiteCredit).toFixed(2)),
      })
  
      this.setState({
        rentaBruteLDMeuble: Number(((this.state.revenusTotalLDMeuble*12 / this.state.coutTotalProjet)*100).toFixed(2)),
        rentaNetteLDMeuble: Number(((this.state.revenusTotalLDMeuble*12-this.state.chargesTotalLDMeuble*12-this.state.mensualiteCredit*12)*100 / this.state.coutTotalProjet).toFixed(2)),
        cashFlowLDMeuble: Number((this.state.revenusTotalLDMeuble-this.state.chargesTotalLDMeuble-this.state.mensualiteCredit).toFixed(2))
      })
  
      this.setState({
        rentaBruteLCD: Number(((this.state.revenusTotalLCD*12 / this.state.coutTotalProjet)*100).toFixed(2)),
        rentaNetteLCD: Number(((this.state.revenusTotalLCD*12-this.state.chargesTotalLCD*12-this.state.mensualiteCredit*12)*100 / this.state.coutTotalProjet).toFixed(2)),
        cashFlowLCD: Number((this.state.revenusTotalLCD-this.state.chargesTotalLCD-this.state.mensualiteCredit).toFixed(2))
      })
    }

  
}



render(){
    return (<>

<div className="col-12 mt-4">
                  <h2 style={{ textDecoration: "underline", fontWeight: "800"}}>
                    CASHFLOW ET RENTABILITÉ
                  </h2>
                </div>

  
                <div className="col-12">
                  <h3>
                    Location Longue Durée Nue:
                  </h3>
                </div>
                

                <div className="col-4">
                  <div className="form-group">
                    <label>Rentabilité Brute</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="rentaBruteLDNue"
                        value={this.state.rentaBruteLDNue}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Rentabilité Nette (avant impôts)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="rentaNetteLDNue"
                        value={this.state.rentaNetteLDNue}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Cashflow</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="cashFlowLDNue"
                        value={this.state.cashFlowLDNue}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <h3>
                    Location Longue Durée Meublée:
                  </h3>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Rentabilité Brute</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="rentaBruteLDMeuble"
                        value={this.state.rentaBruteLDMeuble}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Rentabilité Nette (avant impôts)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="rentaNetteLDMeuble"
                        value={this.state.rentaNetteLDMeuble}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Cashflow</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="cashFlowLDMeuble"
                        value={this.state.cashFlowLDMeuble}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <h3>
                    Location Courte Durée:
                  </h3>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Rentabilité Brute</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="rentaBruteLCD"
                        value={this.state.rentaBruteLCD}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Rentabilité Nette (avant impôts)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="rentaNetteLCD"
                        value={this.state.rentaNetteLCD}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Cashflow</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="cashFlowLCD"
                        value={this.state.cashFlowLCD}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
    </>)
    }

}


export default CashflowETRenta;