import React, { useState } from 'react'
import { useEffect } from 'react'
import UneEtudeValeursFoncieresHeader from '../../../features/uneEtude/ValeursFoncieres/UneEtudeValeursFoncieresHeader'
import { countTotalVentes } from '../../../features/uneEtude/ValeursFoncieres/uneEtudeValeursFoncieresTools'

const ComparatifProjetEtudeCardFourchette = ({ etude, annee, typeBien }) => {

  const [ label, setLabel ] = useState('')
  const [ totalVentes, setTotalVentes ] = useState(0)

  useEffect(() => {
    if(typeBien === 'appartements') setLabel('Appartement')
    else setLabel('Maison')

    setTotalVentes(countTotalVentes(etude.valeurs_foncieres[typeBien]))
  }, [typeBien, etude.valeurs_foncieres])

  return (
    <>
      <div className="row">
        <div className="col">
          {/** Header */}
          <UneEtudeValeursFoncieresHeader
            label={label}
            totalVentes={totalVentes}
            valeursFoncieres={etude.valeurs_foncieres[typeBien].filter(v => v.annee === annee)[0]}
            />
          </div>
      </div>
    </>
  )
}

export default ComparatifProjetEtudeCardFourchette