import React from 'react'
import DeleteModal from '../../toolsComponents/modals/DeleteModal'

const UneEtudeHeader = ({ etude, isActive, onNoteChange, onDelete }) => {

  const getClassName = (value) => {
    let result = ''

    result += isActive === value ? 'nav-link active ' : 'nav-link '
    result += etude && etude._id ? '' : 'inactive-link '

    return result
  }
  
  return (
    <>
      <DeleteModal onDelete={onDelete}>
        Voulez-vous vraiment supprimer cette étude de marché ?
      </DeleteModal>

      {/* <!-- HEADER --> */}
      <div className="header">
        <div className="container-fluid">
          {/* <!-- Body --> */}
          <div className="header-body">
            <div className="row align-items-center">
              <div className="col-auto">
                {/* <!-- Avatar --> */}
                <div className="avatar avatar-lg avatar-4by3">
                  <img src="/assets/img/mockups/map.svg" alt="..." className="avatar-img" style={{width:'85%'}} />
                </div>
              </div>

              <div className="col ml-n3 ml-md-n2">
                {/* <!-- Pretitle --> */}
                <h6 className="header-pretitle">
                  Détails
                </h6>

                {/* <!-- Title --> */}
                <h1 className="header-title">
                  {etude.nom_ville}
                </h1>
              </div>

              {/* <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                <SelectNotation value={etude.notation} inputName="notation" withoutMargin={true} emptyLabel="Note" onChange={handleNoteChange}/>
              </div>

              <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                {etude && etude._id &&
                  <p style={{paddingTop: '35px', paddingRight: '32px'}}>
                    <span className={getNoteColorClass()}>●</span>
                  </p>
                }
              </div> */}

              <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                {/* <!-- Button --> */}
                <a href="#!" className="btn btn-danger d-block d-md-inline-block lift" style={{marginTop: '24px'}} data-toggle="modal" data-target="#modal-delete">
                    <i className="fe fe-trash"></i> Supprimer
                </a>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col">
                {/* <!-- Nav --> */}
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <a href={`/etudes/${etude._id}`} className={getClassName('general')} >
                      Résumé
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`/etudes/${etude._id}/environnement`} className={getClassName('environnement')}>
                      Environnement
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`/etudes/${etude._id}/valeurs-foncieres`} className={getClassName('foncieres')}>
                      Valeurs foncières
                    </a>
                  </li>

                  {/* <li className="nav-item">
                    <a href={`/etudes/${etude._id}/meilleur-agents`} className={getClassName('agents')}>
                      Meilleurs agents
                    </a>
                  </li> */}

                  <li className="nav-item">
                    <a href={`/etudes/${etude._id}/logements-courtes-durees`} className={getClassName('logements')}>
                      Logements courtes durées
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`/etudes/${etude._id}/populations`} className={getClassName('populations')}>
                      Population
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`/etudes/${etude._id}/wikipedia`} className={getClassName('wikipedia')}>
                      Wikipedia
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`/etudes/${etude._id}/appreciations`} className={getClassName('appreciations')}>
                      Appréciations
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UneEtudeHeader