import React from 'react'
import Navigation from '../../../toolsComponents/Navigation'
import UneEtudeHeader from '../UneEtudeHeader'

const UneEtudeGeoportail = () => {
  return (
    <div>
      <Navigation granted_modules="ALL" />

      <div className="main-content">

        <UneEtudeHeader isActive="geoportail" />

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="row">
                  {/* <!-- FORMULAIRE --> */}
                  <div className="col-lg-4">
                    <form>
                      <h2>Transports</h2>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Distance d'une autoroute
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">km</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="1"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Distance d'une nationale
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">km</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="1"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Distance d'une départementale
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">km</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="1"/>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Aéroport
                        </label>

                        <select className="form-control" value="non">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Gare ferrovière
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Gare routière
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <h2>Infrastructures</h2>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Hopital
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Ecole supérieure
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="oui">Non</option>
                        </select>
                      </div>

                      <h2>Nuisances</h2>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Zone innondable
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Zone de bruits
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      {/* <!-- Sauvegarder --> */}
                      <div className="form-group">
                        <div className="float-right">
                          <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                        </div>
                      </div>
                    </form>
                  </div>
                
                  {/* <!-- IFRAME --> */}
                  <div className="col-lg-8">
                    <iframe title="Geoportail" width="100%" height="800" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" sandbox="allow-forms allow-scripts allow-same-origin" src="https://www.geoportail.gouv.fr/embed/visu.html?c=7.358493,48.079342&z=12&l0=ORTHOIMAGERY.ORTHOPHOTOS::GEOPORTAIL:OGC:WMTS(1)&l1=UTILITYANDGOVERNMENTALSERVICES.IGN.POI.ENSEIGNEMENTSUPERIEUR::GEOPORTAIL:OGC:WMS(1)&l2=UTILITYANDGOVERNMENTALSERVICES.IGN.POI.SANTE::GEOPORTAIL:OGC:WMS(1)&l3=UTILITYANDGOVERNMENTALSERVICES.IGN.POI.GARES::GEOPORTAIL:OGC:WMS(1)&permalink=yes" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  
    </div>
  )
}

export default UneEtudeGeoportail