import React from "react";
import Image from "../../../toolsComponents/images/Image";
import AddUnBienModal from "../../../toolsComponents/modals/AddUnBienModal";

const UnPatrimoineGeneral = ({ patrimoine, onAddBien }) => {
  const getAvatar = (bien) => {
    if (bien?.url_avatar) return bien?.url_avatar;
    else if (bien?.type === "NOM_PROPRE")
      return "/assets/img/mockups/patrimoine-nom-propre.png";
    else if (bien?.type === "SOCIETE")
      return "/assets/img/mockups/patrimoine-societe.jpeg";
    else if (bien?.type === "IMMEUBLE")
      return "/assets/img/avatars/biens/immeuble1.webp";
    else if (bien?.type === "LOGEMENT")
      return "/assets/img/avatars/biens/logement1.jpeg";
  };

  return (
    <>
      <AddUnBienModal onSubmit={onAddBien} />

      {/* <div className='row'>
      <div className='col-6'>
        <TransactionsCard
          transactions={patrimoine?.transactions}
        />
      </div>

      <div className='col-6'>
        <DocumentsCard
          documents={patrimoine?.documents}
        />
      </div>
    </div> */}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              {/* <!-- Title --> */}
              <h4 className="card-header-title">Mes biens</h4>

              <a href="#!" data-toggle="modal" data-target={`#add-bien`}>
                <i className="fe fe-plus"></i> Ajouter
              </a>
            </div>

            <div className="card-body">
              <div className="list-group list-group-flush my-n3">
                {patrimoine?.biens?.map((b) => {
                  const padding = b.level ? b.level * 50 : 0;

                  return (
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div
                          className="col-auto"
                          style={{ paddingLeft: padding + "px" }}
                        >
                          <a
                            href={`/bien/${b._id}`}
                            className="avatar avatar-4by3"
                          >
                            <Image
                              src={getAvatar(b)}
                              alt="..."
                              className="avatar-img rounded"
                            />
                          </a>
                        </div>
                        <div className="col ms-n2">
                          <h4 className="mb-1">
                            <a href={`/bien/${b._id}`}>{b.titre}</a>
                          </h4>

                          <p className="card-text small text-muted">
                            <span>{b.description}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnPatrimoineGeneral;
