import React from 'react';

const ModalsSimulation = () => {
return(
  <>

  {/* <!-- MODAL AJOUTER UN LOGEMENT--> */}
<div className="modal fade" id="ModalNewLogement" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Ajouter un logement</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    
                <form>
                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Nom du logemnt</label>
                        <input type="text" className="form-control" id="recipient-name" />
                    </div>

                </form>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                    <button type="button" className="btn btn-success">Ajouter</button>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- MODAL AJOUTER UNE DÉPENSE --> */}
    <div className="modal fade" id="exampleModalNewDepense" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Ajouter une dépense</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    
                <form>
                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Nom de la dépense</label>
                        <input type="text" className="form-control" id="recipient-name" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Bien visé</label>
                        <select class="form-control mb-3">
                            <option>Appartement 1</option>
                            <option>Appartement 2</option>
                            <option>Appartement 3</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Lot de travaux</label>
                        <select className="form-control">
                            <option value="0" select></option>
                            <option value="1">Toiture</option>
                            <option value="2" >Maçonnerie</option>
                            <option value="3">Plomberie</option>
                            <option value="4">Électricité</option>
                            <option value="5">Platrerie</option>
                            <option value="6">Menuiseries Exterieures</option>
                            <option value="7">Menuiseries intérieures</option>
                            <option value="8">Revêtements sols</option>
                            <option value="9">Revêtements murs</option>
                            <option value="10">Peinture</option>
                            <option value="12">Prestation de service (ex: architecte / maître d'œuvre)</option>
                            <option value="13">Divers</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Unité</label>
                        <select className="form-control">
                            <option value="0" select></option>
                            <option value="1" >m²</option>
                            <option value="2">cm</option>
                            <option value="3">Unité</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Quantité</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">[unité]</div>
                            </div>
                            <input type="number" className="form-control" value="0" />
                        </div>
                    </div>


                    <div className="form-group">
                        <label>Coût par [unité]</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">€ / [unité]</div>
                            </div>
                            <input type="number" className="form-control" value="0" />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Montant total de l'item</label>
                        <div className="input-group mb-2">
                        <div className="input-group-prepend">
                                <div className="input-group-text">€</div>
                            </div>
                            <input
                            type="text"
                            className="form-control"
                            name="coutTotalReno"
                            value="23000"
                            readOnly
                            />
                        </div>
                    </div>

                </form>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                    <button type="button" className="btn btn-success">Ajouter</button>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- MODAL MODIFIER UNE DÉPENSE --> */}
    <div className="modal fade" id="exampleModalEditDepense" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Modifier une dépense</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    
                <form>
                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Nom de la dépense</label>
                        <input type="text" className="form-control" id="recipient-name" value="Porte d'entrées"/>
                    </div>

                   
                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Bien visé</label>
                        <select class="form-control mb-3">
                            <option>Appartement 1</option>
                            <option>Appartement 2</option>
                            <option>Appartement 3</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Lot de travaux</label>
                        <select className="form-control">
                            <option value="0"></option>
                            <option value="1">Toiture</option>
                            <option value="2" >Maçonnerie</option>
                            <option value="3">Plomberie</option>
                            <option value="4">Électricité</option>
                            <option value="5">Platrerie</option>
                            <option value="6" selected="selected">Menuiseries Exterieures</option>
                            <option value="7">Menuiseries intérieures</option>
                            <option value="8">Revêtements sols</option>
                            <option value="9">Revêtements murs</option>
                            <option value="10">Peinture</option>
                            <option value="12">Prestation de service (ex: architecte / maître d'œuvre)</option>
                            <option value="13">Divers</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Unité</label>
                        <select className="form-control">
                            <option value="0"></option>
                            <option value="1" >m²</option>
                            <option value="2">cm</option>
                            <option value="3" selected="selected">Unité</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Quantité</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">Unité</div>
                            </div>
                            <input type="number" className="form-control" value="4" />
                        </div>
                    </div>


                    <div className="form-group">
                        <label>Coût par [unité]</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">€ / Unité</div>
                            </div>
                            <input type="number" className="form-control" value="900" />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Montant total de l'item</label>
                        <div className="input-group mb-2">
                        <div className="input-group-prepend">
                                <div className="input-group-text">€</div>
                            </div>
                            <input
                            type="text"
                            className="form-control"
                            name="coutTotalReno"
                            value="3 600"
                            readOnly
                            />
                        </div>
                    </div>

                </form>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                    <button type="button" className="btn btn-success">Modifier</button>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- MODAL SUPPRIMER UNE DÉPENSE --> */}
    <div className="modal fade" id="exampleModalDelDepense" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Supprimer une dépense</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    
                <p>Etes-vous sûr de vouloir supprimer la dépense : Placo</p>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                    <button type="button" className="btn btn-danger">Supprimer</button>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default ModalsSimulation;