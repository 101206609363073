import React from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm';
import helper from '../uneCalculatriceHelper'

/**
 * initialState -> initial data to feed this component
 * leRevenus -> data for fields dependancies
 * leCredit -> data for fields dependancies
 * onChange -> to manage when a field is changed
 */
class EvolutionTauxEndettement extends React.Component {

  constructor(props){
    super(props);
    this.state = this.props.initialState;
  }

  updateState() {
    const tmpState = {
      infosGenerales: this.props.infosGenerales,
      revenus: this.props.leRevenus,
      credit: this.props.leCredit,
      endettement: this.state
    }
    
    this.setState({
      tauxEndettementFutur: helper.performTauxEndettementFutur(tmpState),
      tauxEndettementActuel: helper.performTauxEndettementActuel(tmpState),
      revenusFonciersFutursMeuble: helper.performFonciersFutursMeuble(tmpState),
    });
  }

  handleChange = async (event) => {
   await this.setState({
      [event.inputName]:  event.value
    });

    this.updateState()

    if(typeof this.props.onChange === 'function') this.props.onChange(this.state);

  }

  componentDidMount() {
    this.updateState()
  }

async componentDidUpdate(prevProps) {
  if ((this.props.leRevenus !== prevProps.leRevenus) || (this.props.leCredit !== prevProps.leCredit) || (this.props.infosGenerales !== prevProps.infosGenerales)) {
    await this.setState(this.props.initialState)
    this.updateState()
  }
}


  render(){

    return (<>
    <div className='col-12'>
      <div className='row'>
        <div className='col-8'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>
                Autres revenus et crédits
              </h4>
            </div>

            <div className='card-body'>
              <div className='row'>
                <div className="col-6">
                  <InputForm
                    label={"Revenus du travail mensuel net"}
                    inputName="revenusMensuels"
                    type="number"
                    value={this.state.revenusMensuels}
                    onChange={this.handleChange}
                    placeholder="5000"
                    prepend={"€/mois"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Revenus fonciers actuels"}
                    type="number"
                    inputName="revenusFonciersActuels"
                    value={this.state.revenusFonciersActuels}
                    onChange={this.handleChange}
                    placeholder="130"
                    prepend={"€/mois"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Autre(s) prêt(s) (mensualités)"}
                    type="number"
                    inputName="autrePretsMensualite"
                    value={this.state.autrePretsMensualite}
                    onChange={this.handleChange}
                    placeholder="130"
                    prepend={"€/mois"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-4'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>
                Évolution du taux d'endettement
              </h4>
            </div>

            <div className='card-body'>
              <div className='row'>
                <div className="col-12">
                  <InputForm
                    label="Revenus fonciers futurs meublé (avec règles 70%)"
                    type="number"
                    inputName="revenusFonciersFutursMeuble"
                    value={this.state.revenusFonciersFutursMeuble}
                    readOnly
                    prepend={"€/mois"}
                    tooltip={"= revenus totaux de tous les logements meublées X 70%"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Taux endettement actuel"}
                    type="number"
                    inputName="tauxEndettementActuel"
                    value={this.state.tauxEndettementActuel}
                    readOnly
                    prepend={"%"}
                    tooltip={"= mensualités des autres prêts / (revenus mensuels + 70% des revenus fonciers actuels)"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Taux d'endettement futur"}
                    type="number"
                    inputName="tauxEndettementFutur"
                    value={this.state.tauxEndettementFutur}
                    readOnly
                    prepend={"%"}
                    tooltip={"= (mensualité des autres prêts + mensualité du crédit de ce projet) / (revenus mensuels + revenus fonciers actuels + revenus fonciers meublés de ce projet)"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)}
}


export default EvolutionTauxEndettement;