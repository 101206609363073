import React from 'react'

const UnProjetRentabiliteWizardMenu = ({ currentStep, onStep }) => {

  const getActive = (step) => {
    if( step === currentStep && currentStep === 6) return 'rentabilite-wizard-menu-item-finish-active'
    else if( step === currentStep ) return 'rentabilite-wizard-menu-item-active'
    else return ''
  }

  return <>
    <div className='row'>
      <div className={`col rentabilite-wizard-menu-item ${getActive(1)}`} onClick={() => onStep(1)}>
          <h4 className='text-uppercase'>Projet</h4>
          <h6 className="text-uppercase text-muted">
            Étape 1
          </h6>
      </div>

      <div className={`col rentabilite-wizard-menu-item ${getActive(2)}`} onClick={() => onStep(2)}>
        <h4 className='text-uppercase'>Crédit</h4>
        <h6 className="text-uppercase text-muted">
          Étape 2
        </h6>
      </div>

      <div className={`col rentabilite-wizard-menu-item ${getActive(3)}`} onClick={() => onStep(3)}>
        <h4 className='text-uppercase'>Revenus</h4>
        <h6 className="text-uppercase text-muted">
          Étape 3
        </h6>
      </div>

      <div className={`col rentabilite-wizard-menu-item ${getActive(4)}`} onClick={() => onStep(4)}>
        <h4 className='text-uppercase'>Charges</h4>
        <h6 className="text-uppercase text-muted">
          Étape 4
        </h6>
      </div>

      <div className={`col rentabilite-wizard-menu-item ${getActive(5)}`} onClick={() => onStep(5)}>
        <h4 className='text-uppercase'>Endettement</h4>
        <h6 className="text-uppercase text-muted">
          Étape 5
        </h6>
      </div>

      <div className={`col rentabilite-wizard-menu-item-finish ${getActive(6)}`} onClick={() => onStep(6)}>
        <h4 className='text-uppercase'>Terminé !</h4>
        <h6 className="text-uppercase text-muted">
          Génération de l'étude de rentabilité
        </h6>
      </div>
    </div>

    <hr style={{marginBottom: '36px', marginTop: '0px'}}/>
  </>
}

export default UnProjetRentabiliteWizardMenu