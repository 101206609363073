import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetRDV = () => {

    return (
        <div>

            <Navigation granted_modules="ALL" />

            <div className="main-content">

                {/* <!-- HEADER --> */}
                <UnProjetHeader isActive="general" isActiveSubMenu="rdv"/>

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                        <div id='wrap'>

                            <div id='calendar'></div>

                            <div style={{clear:'both'}}></div>

                        </div>

                        <br /> <br />

                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    )
}

export default UnProjetRDV