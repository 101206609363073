import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router'
import SimpleError from '../../../toolsComponents/SimpleError'
import SimpleLoading from '../../../toolsComponents/SimpleLoading'
import SimpleSuccess from '../../../toolsComponents/SimpleSucccess'
import NewPassword from './NewPassword'
import { performGetEmail, performNewPassword, newPasswordStatusSelector, newPasswordDatasSelector } from './newPasswordSlice'

const NewPasswordWithStore = () => {

  const [ cookies ] = useCookies(['token', 'email', 'granted_modules'])
  const { token } = useParams()
  const dispatch = useDispatch()
  const data = useSelector(newPasswordDatasSelector)
  const { loading, errorMessage, hasError, lastAction } = useSelector(newPasswordStatusSelector)

  useEffect(() => {
    // Get email from token
    dispatch(performGetEmail(token))
  }, [dispatch, token])


  const handleSubmit = (newPassword) => {
    dispatch(performNewPassword(token, newPassword))
  }

  return (
    <>
      <div className="row justify-content-center" style={{paddingTop: "64px"}} >
        <div className="col-12 col-md-5 col-xl-3 my-5">
            {!token && 
              <SimpleError withHeader={false}>
                Impossible d'accéder à cette page
              </SimpleError>
            }

            {/* Login form display */}
            {!cookies.token && !loading && !hasError && lastAction !== 'NEW_PASSWORD' &&
              <NewPassword 
                email={data.email}
                onSubmit={handleSubmit}
              />
            }

            {/* Loading display */}
            {!loading && !hasError && lastAction === 'NEW_PASSWORD' &&
              <SimpleSuccess link={{location: '/login', label: 'Vous pouvez maintenant vous connecter'}}>
                Votre mot de passe a été mis à jour avec succès
              </SimpleSuccess>
            }

            {/* Loading display */}
            {loading &&
              <SimpleLoading />
            }

            {/* Error display */}
            {hasError &&
              <SimpleError withHeader={false}>
                {errorMessage}
              </SimpleError>
            }

            {/* Dashboard display */}
            {cookies.token &&
              <Redirect to="/dashboard" />
            }
        </div>
      </div>
    </>
  )
}

export default NewPasswordWithStore