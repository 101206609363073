import React from 'react'
import ContactsCard from '../../toolsComponents/cards/ContactsCard'
import RDVsCard from '../../toolsComponents/cards/RDVsCard'
import InputForm from '../../toolsComponents/forms/InputForm'
import MultiSelectForm from '../../toolsComponents/forms/MultiSelectForm'
import SubmitForm from '../../toolsComponents/forms/SubmitForm'

const UnArtisan = ( { artisan } ) => {

  const allMetiers = [
    'Architecte',
    'Carreleur',
    'Couvreur',
    'Electricien',
    'Gros oeuvre',
    'Peintre',
    'Plombier'
  ]

  return (
    <div className="row">
      {/* <!-- FORMULAIRE --> */}
      <div className="col-8 col-xl-8">
        <form className="mb-4">
          {/* <!-- Nom de l'artisan --> */}
          <InputForm 
            label="Nom de l'artisan"
            inputName="nom"
            value={artisan.nom}
          />

          {/* <!-- Description --> */}
          <InputForm 
            label="Description"
            type='textarea'
            inputName="description"
            value={artisan.description}
          />

          <hr />

          {/* <!-- Adresse --> */}
          <InputForm 
            label="Adresse"
            inputName="adresse"
            value={artisan.adresse}
          />

          {/* <!-- Ville et code postal --> */}
          <div className="row">
            <div className="col-12 col-md-6">
              <InputForm 
                label="Code postal"
                inputName="code_postal"
                value={artisan.code_postal}
              />
            </div>

            <div className="col-12 col-md-6">
              <InputForm 
                label="Ville"
                inputName="nom"
                value={artisan.ville}
              />
            </div>
          </div> 

          {/* <!-- Téléphone et email --> */}
          <div className="row">
            <div className="col-12 col-md-6">
              <InputForm 
                label="Numéro de téléphone"
                type="phone"
                inputName="telephone"
                value={artisan.telephone}
              />
            </div>
            
            <div className="col-12 col-md-6">
              <InputForm 
                label="Adresse e-mail"
                inputName="email"
                value={artisan.email}
              />
            </div>

            {/* <!-- Corps de métier --> */}
            <div className="col-12 col-md-6">
              <MultiSelectForm 
                label="Corps de métiers"
                inputName="metiers"
                allValues={allMetiers}
                selectedValues={artisan.metiers}
              />
            </div>
          </div>

          <hr />

          {/* <!-- Sauvegarder --> */}
          <SubmitForm 
            label="Sauvegarder"
          />

          <br /><br />
        </form>
      </div>

      {/* CARDS*/}
      <div className="col-4 col-xl-4">
        {/* <!-- Contacts --> */}
        <ContactsCard 
          contacts={artisan.contacts}
        />   

        {/* <!-- Rendez-vous --> */}
        <RDVsCard
          rdvs={artisan.RDVs}
        />
      </div>
    </div>
  )
}

export default UnArtisan