import React from 'react'
import UnProfilFacturation from './UnProfilFacturation'
import UnProfilGenericWithStore from '../UnProfilGenericWithStore'
import { useSelector } from 'react-redux'
import { unProfilDatasSelector } from '../unProfilSlice'

const UnProfilFacturationWithStore = () => {

  const user = useSelector(unProfilDatasSelector)

  return <>
    <UnProfilGenericWithStore
      activeMenu="facturation"
    >
      <UnProfilFacturation
        user={user}
      />
    </UnProfilGenericWithStore>
  </>
}

export default UnProfilFacturationWithStore