import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";
import SelectVersatile from "../../../../toolsComponents/SelectVersatile";
import { getNewArray } from "../utils";

const lotMaconnerie = ({ maconnerie, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("maconnerie", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot Maçonnerie"
            id="lotMaconnerie"
            inputName="lotMaconnerie.isChecked"
            isChecked={maconnerie?.lotMaconnerie.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !maconnerie?.lotMaconnerie.isChecked ? "hide" : "sous-lot col-12"
            }
          >
            <Checkbox
              label="Reprise du niveau des sols"
              id="repriseNiveauSol"
              inputName="repriseNiveauSol.isChecked"
              isChecked={maconnerie?.repriseNiveauSol.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !maconnerie?.repriseNiveauSol.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 text-left"
              }
            >
              <div className="col-3">
                <SelectVersatile
                  label="Type de reprise"
                  options={[
                    { title: "Réagréage", value: "reagreage" },
                    {
                      title: "Redressement de plancher",
                      value: "redressementPlancher",
                    },
                  ]}
                  inputName="repriseNiveauSol.categorie"
                  value={maconnerie?.repriseNiveauSol.categorie}
                  onChange={handleChange}
                />
              </div>
              <div className="col-2">
                <InputForm
                  label="Surface"
                  inputName="repriseNiveauSol.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={maconnerie?.repriseNiveauSol.surface}
                  onChange={handleChange}
                />
              </div>
            </div>

            <Checkbox
              label="Création d'une chappe"
              id="creationChape"
              inputName="creationChape.isChecked"
              isChecked={maconnerie?.creationChape.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !maconnerie?.creationChape.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 text-left"
              }
            >
              <div className="col-2">
                <InputForm
                  label="Surface"
                  inputName="creationChape.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={maconnerie?.creationChape.surface}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <Checkbox
                  label="Reprise dimensions fenêtres"
                  id="repriseDimensionFenetres"
                  inputName="repriseDimensionFenetres.isChecked"
                  isChecked={maconnerie?.repriseDimensionFenetres.isChecked}
                  onChange={handleChange}
                />
              </div>
              <div
                className={
                  !maconnerie?.repriseDimensionFenetres.isChecked
                    ? "hide"
                    : "col-2 fs-6 ml-3 text-left"
                }
              >
                <InputForm
                  inputName="repriseDimensionFenetres.fenetres"
                  type="Number"
                  placeholder="4"
                  value={maconnerie?.repriseDimensionFenetres.fenetres.length}
                  onChange={({ inputName, value }) =>
                    handleChange({
                      inputName,
                      value: getNewArray({
                        value,
                        max: 50,
                        array: maconnerie.repriseDimensionFenetres.fenetres,
                        initialObject: {
                          nom: undefined,
                          largeurActuelle: undefined,
                          hauteurActuelle: undefined,
                          largeurFuture: undefined,
                          hauteurFuture: undefined,
                        },
                      }),
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div
            className={
              !maconnerie?.repriseDimensionFenetres.isChecked ? "hide" : ""
            }
          >
            <div className="row">
              {maconnerie?.repriseDimensionFenetres.fenetres.map((f, index) => {
                return (
                  <>
                    <div
                      className="col-4 sub-item text-left"
                      style={{ margin: "12px 12px 12px 12px" }}
                    >
                      <InputForm
                        label={`Nommez la fênetre n°${index + 1}`}
                        inputName={`repriseDimensionFenetres.fenetres.${index}.nom`}
                        type="Text"
                        placeholder="Fenêtre T3 salon gauche"
                        value={f.nom}
                        onChange={handleChange}
                      />
                      <br />
                      <p>Dimensions actuelles</p>
                      <div className="row">
                        <div className="col-6">
                          <InputForm
                            label="largeur"
                            type="Number"
                            prepend={"cm"}
                            placeholder="120"
                            value={f.largeurActuelle}
                            inputName={`repriseDimensionFenetres.fenetres.${index}.largeurActuelle`}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6">
                          <InputForm
                            label="hauteur"
                            type="Number"
                            prepend={"cm"}
                            placeholder="80"
                            value={f.hauteurActuelle}
                            inputName={`repriseDimensionFenetres.fenetres.${index}.hauteurActuelle`}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <br />
                      <p>Dimensions futures</p>
                      <div className="row">
                        <div className="col-6">
                          <InputForm
                            label="largeur"
                            type="Number"
                            prepend={"cm"}
                            placeholder="120"
                            value={f.largeurFuture}
                            inputName={`repriseDimensionFenetres.fenetres.${index}.largeurFuture`}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6">
                          <InputForm
                            label="hauteur"
                            type="Number"
                            prepend={"cm"}
                            placeholder="80"
                            value={f.hauteurFuture}
                            inputName={`repriseDimensionFenetres.fenetres.${index}.hauteurFuture`}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default lotMaconnerie;
