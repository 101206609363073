import React, { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SimpleContainedError from "../../../toolsComponents/SimpleContainedError";
import SimpleLoading from "../../../toolsComponents/SimpleLoading";
import {
  changePagination,
  filterByText,
  performAddDocument,
  performEditDocument,
  performGetUnBienDocuments,
  performDelDocument,
  sort,
  unBienDocumentsFilterSelector,
  unBienDocumentsPaginatedDatasSelector,
  unBienDocumentsPaginationSelector,
  unBienDocumentsStatusSelector,
  performSelect,
  unBienDocumentsSelectedDatasSelector,
} from "../unBienDocumentsSlice";
import UnBienGenericWithStore from "../UnBienGenericWithStore";
import UnBienDocuments from "./UnBienDocuments";

const UnBienDocumentsWithStore = ({ id }) => {
  const genericComponent = useRef(null);

  const { loading, hasError, errorMessage } = useSelector(
    unBienDocumentsStatusSelector
  );

  const paginatedDocuments = useSelector(unBienDocumentsPaginatedDatasSelector);

  const pagination = useSelector(unBienDocumentsPaginationSelector);
  const filter = useSelector(unBienDocumentsFilterSelector);

  const selectedDatas = useSelector(unBienDocumentsSelectedDatasSelector);

  const dispatch = useDispatch();
  const [cookies] = useCookies(["token", "email"]);

  useEffect(() => {
    // Get current bien
    dispatch(performGetUnBienDocuments(cookies["token"], id));
  }, [dispatch, cookies, id]);

  const handlePagination = (nbPerPage, currentPage) => {
    dispatch(
      changePagination({
        nbPerPage,
        currentPage,
      })
    );
  };

  const handleSearch = (
    value,
    fields = ["titre", "category", "subCategory"]
  ) => {
    dispatch(
      filterByText({
        value,
        fields,
      })
    );
  };

  const handleSort = (field) => {
    dispatch(sort(field));
  };

  const handleAddDocument = ({ titre, document, category, subCategory }) => {
    var formData = new FormData();

    formData.append("document", document, document.name);
    formData.append("id_bien", id);
    formData.append("titre", titre);
    formData.append("category", category);
    formData.append("subCategory", subCategory);

    dispatch(performAddDocument(cookies.token, formData));
  };
  const handleEditDocument = (document) => {
    dispatch(
      performEditDocument(cookies.token, {
        ...document,
        initialBienId: id,
      })
    );
  };

  const handleDeleteDocument = (document) => {
    dispatch(performDelDocument(cookies.token, document));
  };

  const handleSelect = ({ action, document }) => {
    dispatch(performSelect({ action, value: document }));
  };

  return (
    <>
      <UnBienGenericWithStore
        ref={genericComponent}
        activeMenu="documents"
        id={id}
      >
        {loading && <SimpleLoading />}

        {hasError && (
          <SimpleContainedError>{errorMessage}</SimpleContainedError>
        )}

        {!loading && !hasError && (
          <UnBienDocuments
            token={cookies["token"]}
            documents={paginatedDocuments}
            pagination={pagination}
            onPagination={handlePagination}
            onSearch={handleSearch}
            onSort={handleSort}
            filter={filter}
            onAddDocument={handleAddDocument}
            onEditDocument={handleEditDocument}
            onDeleteDocument={handleDeleteDocument}
            onSelect={handleSelect}
            selected={selectedDatas}
          />
        )}
      </UnBienGenericWithStore>
    </>
  );
};

export default UnBienDocumentsWithStore;
