import React, { useEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip, Legend } from 'recharts';

const CustomTooltip = ({ active, payload, label, unit='' }) => {

  if (active && payload && payload.length) {

    const bas = payload[0].value
    const moyen = bas + payload[1].value
    const haut = moyen + payload[2].value 

    return (
      <div className="custom-tooltip" style={{backgroundColor:'white', padding:'20px 20px 20px 20px', border: '1px solid #555555'}}>
          <p>{label}</p>
          <p style={{color: payload[2].fill}}>{`${payload[2].dataKey} : ${haut.toFixed(2)} ${unit}`}</p>
          <p style={{color: payload[1].fill}}>{`${payload[1].dataKey} : ${moyen.toFixed(2)}  ${unit}`}</p>
          <p style={{color: payload[0].fill}}>{`${payload[0].dataKey} : ${bas.toFixed(2)}  ${unit}`}</p>
      </div>
    )
  }

  return null;
}

const TripleBarChart = ({ data, bar3colors, Y3DataKeys, XDataKey, YUnit }) => {
  const cleanData = []

  useEffect(() => {
    
    for(const d of data) {
      const result = {}

      result[XDataKey] = d[XDataKey]

      const bas = d[Y3DataKeys[0]]
      const moyen = d[Y3DataKeys[1]]
      const haut = d[Y3DataKeys[2]]

      const y1 = ((moyen - bas) / 2) + bas
      const y2 = ((haut - moyen) / 2) + moyen

      result[Y3DataKeys[0]] = y1
      result[Y3DataKeys[1]] = y2 - y1
      result[Y3DataKeys[2]] = haut - y2

      cleanData.push(result)
    }
  }, [cleanData, XDataKey, Y3DataKeys, data])

  return (
    <>
      <ResponsiveContainer width="100%" height={240}>
      <BarChart
          width={500}
          height={300}
          data={cleanData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
        <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={XDataKey} />
          <YAxis unit={YUnit}/>
          <Tooltip content={<CustomTooltip unit={YUnit}/>} />
          <Legend />
          <Bar dataKey={Y3DataKeys[0]} stackId="a" fill={bar3colors[0]} />
          <Bar dataKey={Y3DataKeys[1]} stackId="a" fill={bar3colors[1]} />
          <Bar dataKey={Y3DataKeys[2]} stackId="a" fill={bar3colors[2]} />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default TripleBarChart