import React, { useEffect, useState } from 'react'
import ComparatifProjetEtude from '../../../toolsComponents/cards/ComparatifProjetEtudeCard/ComparatifProjetEtudeCard'
import PhotosCard from '../../../toolsComponents/cards/PhotosCard'
import RentabiliteCard from '../../../toolsComponents/cards/RentabiliteCard'
import InputForm from '../../../toolsComponents/forms/InputForm'
import SubmitForm from '../../../toolsComponents/forms/SubmitForm'
import SelectNotation from '../../../toolsComponents/SelectNotation'
import SelectTypeBien from '../../../toolsComponents/SelectTypeBien'
import SelectTypeTravaux from '../../../toolsComponents/SelectTypeTravaux'
import SelectVille from '../../../toolsComponents/SelectVille'
import SimpleWarning from '../../../toolsComponents/SimpleWarning'
import EtudeItem from '../../etudes/EtudeItem'
import { rentabiliteExists } from '../rentabilite/rentabiliteUtils'

const UnProjet = ({ projet, etude, onChange, onAddEtude, onSubmit }) => {

  const [ cleanVille, setCleanVille ] = useState('')
  const [ displayWarningVille, setDisplayWarningVille ] = useState(false)

  useEffect(() => {
    if(projet.commune) {
      const value = `${projet.commune.nom} (${projet.commune.cp}) - ${projet.commune.departement}`
      setCleanVille(value)
    }
    
  }, [projet])

  const getNotationClass = () => {
    if(!projet || !projet.notation || projet.notation === '' || parseInt(projet.notation) < 10 ) return 'text-danger'
    else if(parseInt(projet.notation) < 15 ) return 'text-warning'
    else return 'text-success'
  }

  const handleVilleChanged = (e) => {
    if(e !== '') {
      const ville = e.split(' ')[0]
      const cp = e.split(')')[0].split('(')[1].split('/')[0]

      onChange({inputName: 'ville', value: ville})
      onChange({inputName: 'code_postal', value: cp})

      setDisplayWarningVille(true)
    }
  }

  return (
  <div className="row">
    {/* <!-- FORMULAIRE --> */}
    <div className="col-6">
      <form className="mb-4">
        <div className="row">
          <div className="col-4">
            <SelectNotation 
              label="Notation globale du projet"
              inputName="notation"
              value={projet.notation}
              type="textarea"
              onChange={onChange}
            />
          </div>

          <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
              <p style={{paddingTop: '35px', paddingRight: '32px'}}>
                <span className={getNotationClass()}>●</span>
              </p>
          </div>
        </div>

        {/* <!-- Titre du bien --> */}
        <InputForm
          label="Titre"
          inputName="titre"
          value={projet.titre}
          onChange={onChange}
        />

        {/* Lien de l'annonce */}
        <InputForm
          label="Lien de l'annonce"
          link={["Voir l'annonce", projet.lien_annonce]}
          inputName="lien_annonce"
          readOnly={true}
          value={projet.lien_annonce}
        />

        <hr />

        {/* Surfaces du terrain et de l'intérieur */}
        <div className="row">
          {/* <div className="col-12 col-md-6">
            <InputForm 
              label="Surface du terrain"
              inputName="surfaceTerrain"
              value={projet.surface_terrain}
              prepend="m²"
            />
          </div> */}

          <div className="col-12 col-md-6">
            <InputForm 
              label="Surface habitable"
              inputName="surface_interieur"
              value={projet.surface_interieur}
              prepend="m²"
              type="number"
              onChange={onChange}
            />
          </div>
        </div>

        {/* Prix de départ et prix objectif */}
        <div className="row">
          <div className="col-12 col-md-4">
            <InputForm 
              label="Prix affiché"
              inputName="prix_depart"
              value={projet.prix_depart}
              prepend="€"
              type="number"
              onChange={onChange}
            />
          </div>

          {/* Prix négocié */}
          <div className="col-12 col-md-4">
            <InputForm 
              label="Prix négocié"
              inputName="prix_negocie"
              value={projet.prix_negocie}
              prepend="€"
              type="number"
              onChange={onChange}
            />
          </div>

          <div className="col-12 col-md-4">
            <InputForm 
              label="Prix souhaité"
              inputName="prix_objectif"
              value={projet.prix_objectif}
              prepend="€"
              type="number"
              onChange={onChange}
            />
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col">
            <SelectTypeBien
              inputName="type_bien"
              value={projet.type_bien}
              withNone={false}
              onChange={onChange}
            />
          </div>

          <div className="col">
            {/** Use projet['rentabilite.infos_generales.type_travaux'] syntaxe because type_travaux is inside nested objects */}
            {/* TODO : J'aime pas cette solution de || */}
            <SelectTypeTravaux 
              inputName="rentabilite.infos_generales.type_travaux"
              value={projet['rentabilite.infos_generales.type_travaux'] || projet.rentabilite?.infos_generales?.type_travaux}
              onChange={onChange}
            />
          </div>
        </div>

        <hr />

        {/* Comparatif */}
        {/** La commune n'existe pas */}
        {!projet.commune && 
          <SimpleWarning>
            Nous ne pouvons pas créer de comparatif entre ce projet et la ville car il nous est impossible de trouver cette ville. Veuillez sélectionner une ville correcte dans le champ Ville et sauvegarder.
          </SimpleWarning>
        }

        {/** La commune existe mais pas l'étude */}
        {projet.commune && !etude._id && 
          <SimpleWarning>
            <p>
              Nous ne pouvons pas créer de comparatif entre ce projet et la ville car vous n'avez pas d'étude de marché pour cette ville. Voulez-vous en créer une ?
            </p>
            <br />

            <p style={{textAlign:'center'}}>
              <button className='btn btn-primary' onClick={onAddEtude}>Créer l'étude de marché</button>
            </p>
          </SimpleWarning>
        }

        {/** Il n'y a pas de surface */}
        {(!projet.surface_interieur || parseFloat(projet.surface_interieur) <= 0) &&
          <SimpleWarning>
            Nous ne pouvons pas créer de comparatif entre ce projet et une étude de marché car aucune surface de habitable n'est renseignée.
          </SimpleWarning>
        }

        {/** L'étude existe et il y assez de vf */}
        {projet.commune && etude && etude._id && parseFloat(projet.surface_interieur) > 0 &&
          <ComparatifProjetEtude
            etude={etude}
            projet={projet}
          />
        }

        <hr />

        {/** Il n'y a pas de rentabilité */}
        {!rentabiliteExists(projet) &&
          <SimpleWarning>
            Ce projet n'a pas d'étude de rentabilité, <a href={`/projets/${projet._id}/rentabilite`}>vous pouvez la créer ici.</a>
          </SimpleWarning>
        }

        {/** Il y a la rentabilité */}
        {rentabiliteExists(projet) && 
          <RentabiliteCard
            projet={projet}
          />
        }

        <hr />

        {/* Description */}
        <div className="row">
          <div className="col-12">
            <InputForm 
              label="Description du bien"
              inputName="description"
              value={projet.description}
              type="textarea"
              onChange={onChange}
            />
          </div>
        </div>
        

        {/* Objectifs */}
        <div className="row">
          <div className="col-12">
            <InputForm 
              label="Objectif du projet"
              inputName="objectifs"
              value={projet.objectifs}
              type="textarea"
              onChange={onChange}
            />
          </div>
        </div>
        

        <hr />

        {/* <!-- Adresse du bien --> */}
        <div className="row">
          <div className="col-12">
            <InputForm 
              label="Adresse"
              inputName="adresse"
              value={projet.adresse}
              onChange={onChange}
            />
          </div>
        </div>

        {/* <!-- Ville et code postal de l'annonce --> */}
        {!projet.commune && 
          <>
            <div className="row">
            <div className="col-12 col-md-6">
                <InputForm 
                  label="Ville de l'annonce"
                  inputName="ville"
                  value={projet.ville}
                  onChange={onChange}
                  readOnly={true}
                />
              </div>

              <div className="col-12 col-md-6">
                <InputForm 
                  label="Code postal de l'annonce"
                  inputName="code_postal"
                  value={projet.code_postal}
                  onChange={onChange}
                  readOnly={true}
                />
              </div>
            </div>

            
            <div className="row">
              <div className="col">
                <SimpleWarning>
                  Il nous est impossible de trouver cette ville. Veuillez sélectionner une ville correcte dans le champ Ville et sauvegarder.
                </SimpleWarning>
              </div>
            </div>
          </>
        }

        {/* Select Ville */}
        <div className="row">
          <div className="col">
            <SelectVille 
              onChange={handleVilleChanged} 
              withFormGroup={true} 
              value={cleanVille} />
          </div>
        </div>
        
        {displayWarningVille &&
          <div className='row'>
            <div className='col'>
              <SimpleWarning>
                Veuillez sauvegarder afin de mettre à jour les informations de l'étude de marché
              </SimpleWarning>
            </div>
          </div>
        }

        {/* Pays */}
        {/* <SelectPays
          pays={projet.pays}
        /> */}

      </form>
    </div>

    {/* CARDS*/}
    <div className="col-6">
      {/* Photos */}
      <PhotosCard 
        photos={projet.photos}
        link={`/projets/${projet._id}/photos`}
      />

      {/* Etude de marché */}
      {etude?._id &&
        <EtudeItem 
          etude={etude} 
          isLarge={true}
        />
      }
    </div>

    {/* Sauvegarder */}
    <SubmitForm
      label="Sauvegarder"
      onSubmit={onSubmit}
    />
  </div>
  )
}

export default UnProjet