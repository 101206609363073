import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import helper from '../../features/uneCalculatrice/uneCalculatriceHelper'
import { convertInfosGeneralesReduxToComponent, convertCreditReduxToComponent, convertRevenusReduxToComponent, convertChargesReduxToComponent, convertEndettementReduxToComponent } from '../../features/unProjet/rentabilite/rentabiliteUtils'

const RentabiliteCard = ({ projet }) => {

  const getBadgeClassName = (value, th1, th2) => {
    if(value < th1) return 'badge badge-soft-danger'
    else if( value >= th1 && value < th2 ) return 'badge badge-soft-warning'
    else return 'badge badge-soft-success'
  }

  const [ coutTotalProjet, setCoutTotalProjet ] = useState(0)
  const [ mensualite, setMensualite ] = useState(0)

  const [ revenusLDNue, setRevenusLDNue ] = useState(0)
  const [ revenusLDMeuble, setRevenusLDMeuble ] = useState(0)
  const [ revenusLCD, setRevenusLCD ] = useState(0)

  const [ chargesLDNue, setChargesLDNue ] = useState(0)
  const [ chargesLDMeuble, setChargesLDMeuble ] = useState(0)
  const [ chargesLCD, setChargesLCD ] = useState(0)

  const [ cashflowLDNue, setCashflowLDNue ] = useState(0)
  const [ cashflowLDMeuble, setCashflowLDMeuble ] = useState(0)
  const [ cashflowLCD, setCashflowLCD ] = useState(0)

  const [ rentaBruteLDNue, setRentaBruteLDNue ] = useState(0)
  const [ rentaBruteLDMeuble, setRentaBruteLDMeuble ] = useState(0)
  const [ rentaBruteLCD, setRentaBruteLCD ] = useState(0)

  const [ rentaNetteLDNue, setRentaNetteLDNue ] = useState(0)
  const [ rentaNetteLDMeuble, setRentaNetteLDMeuble ] = useState(0)
  const [ rentaNetteLCD, setRentaNetteLCD ] = useState(0)

  useEffect(() => {
    if( projet && projet._id ) {

      const tmpState = {
        infosGenerales: convertInfosGeneralesReduxToComponent(projet),
        credit: convertCreditReduxToComponent(projet),
        revenus: convertRevenusReduxToComponent(projet),
        charges: convertChargesReduxToComponent(projet),
        endettement: convertEndettementReduxToComponent(projet)
      }

      setCoutTotalProjet(helper.performCoutTotalProjet(tmpState))
      setMensualite(helper.performMensualite(tmpState))

      setRevenusLDNue(helper.performRevenusTotalTousLogementsLDNue(tmpState))
      setRevenusLDMeuble(helper.performRevenusTotalTousLogementsLDMeuble(tmpState))
      setRevenusLCD(helper.performRevenusTotalTousLogementsLCD(tmpState))

      setChargesLDNue(helper.performTotalChargesMensuellesLDNue(tmpState))
      setChargesLDMeuble(helper.performTotalChargesMensuellesLDMeuble(tmpState))
      setChargesLCD(helper.performTotalChargesMensuellesLCD(tmpState))

      setCashflowLDNue(helper.performCashflowLDNue(tmpState))
      setCashflowLDMeuble(helper.performCashflowLDMeuble(tmpState))
      setCashflowLCD(helper.performCashflowLCD(tmpState))

      setRentaBruteLDNue(helper.performRentaBruteLDNue(tmpState))
      setRentaBruteLDMeuble(helper.performRentaBruteLDMeuble(tmpState))
      setRentaBruteLCD(helper.performRentaBruteLCD(tmpState))

      setRentaNetteLDNue(helper.performRentaNetteLDNue(tmpState))
      setRentaNetteLDMeuble(helper.performRentaNetteLDMeuble(tmpState))
      setRentaNetteLCD(helper.performRentaBruteLCD(tmpState))
    }
  }, [projet])

  return <>
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          Rentabilité
        </h4>

        <a href={`/projets/${projet._id}/rentabilite`} className="small">Editer</a>
      </div>
      <div className="card-body">

        <div className="list-group list-group-flush list-group-activity my-n3">
          {/* Information générales */}
          <div className="list-group-item">
            <div className="row">
              <div className="col-auto">

                {/* <!-- Avatar --> */}
                <div className="avatar avatar-sm">
                  <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                    <i className="fe fe-home"></i>
                  </div>
                </div>

              </div>
              <div className="col ml-n2">
                <h5 className="mb-1">
                  Information générales
                </h5>

                <div className='row'>
                  <div className='col-4'>
                    <p className="small text-gray-700 mb-0">
                      Coût total du projet : 
                    </p>
                  </div>

                  <div className='col' style={{textAlign:'center'}}>
                    <p className="small text-gray-700 mb-0">
                      <span className="badge badge-soft-primary"><strong>{coutTotalProjet} €</strong></span>
                    </p>
                  </div>
                  <div className='col'>
                    
                  </div>
                  <div className='col'>
                    
                  </div>
                </div>
              </div>
            </div> 
          </div>

          {/* Crédit */}
          <div className="list-group-item">
            <div className="row">
              <div className="col-auto">
                <div className="avatar avatar-sm">
                  <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                    <i className="fe fe-calendar"></i>
                  </div>
                </div>
              </div>
        
              <div className="col ml-n2">
                <h5 className="mb-1">
                  Crédit
                </h5>

                {/* <!-- Text --> */}
                <div className='row'>
                  <div className='col-4'>
                    <p className="small text-gray-700 mb-0">
                      Mensualités : 
                    </p>
                  </div>

                  <div className='col' style={{textAlign:'center'}}>
                    <p className="small text-gray-700 mb-0">
                      <span className="badge badge-soft-danger"><strong>{mensualite} €/mois</strong></span>
                    </p>
                  </div>
                  <div className='col'>
                    
                  </div>
                  <div className='col'>
                    
                  </div>
                </div>

              </div>
            </div> 
          </div>

          {/* Revenus */}
          <div className="list-group-item">
            <div className="row">
              <div className="col-auto">

                {/* <!-- Avatar --> */}
                <div className="avatar avatar-sm">
                  <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                    <i className="fe fe-check-circle"></i>
                  </div>
                </div>

              </div>
              <div className="col ml-n2">

                {/* <!-- Heading --> */}
                <h5 className="mb-1">
                  Revenus
                </h5>

                <div className='row'>
                  <div className='col-4'>
                    <p className="small text-gray-700 mb-0">
                      Location longue durée nue : 
                    </p>

                    <p className="small text-gray-700 mb-0">
                      Location longue durée meublée : 
                    </p>

                    <p className="small text-gray-700 mb-0">
                      Location courte durée : 
                    </p>
                  </div>

                  <div className='col' style={{textAlign:'center'}}>
                    <p className="small text-gray-700 mb-0">
                      <span className="badge badge-soft-success"><strong>{revenusLDNue} €/mois</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className="badge badge-soft-success"><strong>{revenusLDMeuble} €/mois</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className="badge badge-soft-success"><strong>{revenusLCD} €/mois</strong></span>
                    </p>
                  </div>
                  <div className='col'>
                    
                  </div>
                  <div className='col'>
                    
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* Charges */}
          <div className="list-group-item">
            <div className="row">
              <div className="col-auto">

                {/* <!-- Avatar --> */}
                <div className="avatar avatar-sm">
                  <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                    <i className="fe fe-shopping-cart"></i>
                  </div>
                </div>

              </div>
              <div className="col ml-n2">
                <h5 className="mb-1">
                  Charges
                </h5>

                <div className='row'>
                  <div className='col-4'>
                    <p className="small text-gray-700 mb-0">
                      Location longue durée nue : 
                    </p>

                    <p className="small text-gray-700 mb-0">
                      Location longue durée meublée :
                    </p>

                    <p className="small text-gray-700 mb-0">
                      Location courte durée : 
                    </p>
                  </div>

                  <div className='col' style={{textAlign:'center'}}>
                    <p className="small text-gray-700 mb-0">
                      <span className="badge badge-soft-danger"><strong>{chargesLDNue} €/mois</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className="badge badge-soft-danger"><strong>{chargesLDMeuble} €/mois</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className="badge badge-soft-danger"><strong>{chargesLCD} €/mois</strong></span>
                    </p>
                  </div>
                  <div className='col'>
                    
                  </div>
                  <div className='col'>
                    
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          
          {/* Cashflow */}
          <div className="list-group-item">
            <div className="row">
              <div className="col-auto">

                {/* <!-- Avatar --> */}
                <div className="avatar avatar-sm">
                  <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                    <i className="fe fe-trending-up"></i>
                  </div>
                </div>

              </div>
              <div className="col ml-n2">

                {/* <!-- Heading --> */}
                <h5 className="mb-1">
                  Cashflow & rentabilité
                </h5>

                <div className="row" style={{textAlign:'center'}}>
                  <div className="col-4">
                    
                  </div>

                  <div className="col">
                    <small className="text-muted">Cashflow</small>
                  </div>

                  <div className="col">
                    <small className="text-muted">Rentabilité brute</small>
                  </div>

                  <div className="col">
                    <small className="text-muted">Rentabilité nette</small>
                  </div>
                </div>

                <div className="row" style={{textAlign:'center'}}>
                  <div className="col-4"  style={{textAlign:'left'}}>
                    <p className="small text-gray-700 mb-0">
                      Location longue durée nue :
                    </p>

                    <p className="small text-gray-700 mb-0">
                      Location longue durée meublée :
                    </p>

                    <p className="small text-gray-700 mb-0">
                      Location courte durée :
                    </p>
                  </div>

                  <div className="col">
                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(cashflowLDNue, 0, 0)}><strong>{cashflowLDNue} €/mois</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(cashflowLDMeuble, 0, 0)}><strong>{cashflowLDMeuble} €/mois</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(cashflowLCD, 0, 0)}><strong>{cashflowLCD} €/mois</strong></span>
                    </p>
                  </div>

                  <div className="col">
                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(rentaBruteLDNue, 0, 3)}><strong>{rentaBruteLDNue} %</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(rentaBruteLDMeuble, 0, 5)}><strong>{rentaBruteLDMeuble} %</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(rentaBruteLCD, 0, 7)}><strong>{rentaBruteLCD} %</strong></span>
                    </p>
                  </div>

                  <div className="col">
                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(rentaNetteLDNue, 0, 5)}><strong>{rentaNetteLDNue} %</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(rentaNetteLDMeuble, 0, 8)}><strong>{rentaNetteLDMeuble} %</strong></span>
                    </p>

                    <p className="small text-gray-700 mb-0">
                      <span className={getBadgeClassName(rentaNetteLCD, 0, 12)}><strong>{rentaNetteLCD} %</strong></span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </>
}

export default RentabiliteCard