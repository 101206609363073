import React from 'react'
import InputForm from './../forms/InputForm'
import SimpleInfo from './../SimpleInfo'

const TransactionsCard = ({ transactions, isVoirPlus = true }) => {
  
  const displayMontant = (value) => {
    if(value >= 0)
      return <span class="badge badge-soft-success"><strong>{value} €</strong></span>
    else
      return <span class="badge badge-soft-danger"><strong>{value} €</strong></span>
  }

  return <>
    {/* <!-- Modal --> */}
    <div className="modal fade" id={`transaction`} tabindex="-1" role="dialog" aria-labelledby={`transaction`} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`transaction-label`}>Modifier la transaction</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <InputForm
                label="Intitulé"
                value="Internet fibre"
              />

              <InputForm
                label="Société / Logement"
                value="Appartement Marie-Antoinette"
              />

              <InputForm
                label="Date"
                value="06/04/2022"
              />

              <InputForm
                label="Montant"
                type="number"
                value={-39.99}
                prepend={"€"}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal">Sauvegarder</button>
            </div>
          </div>
        </div>
      </div>

    <div className="card">
      <div className="card-header">
        {/* <!-- Title --> */}
        <h4 className="card-header-title">
          Mes {isVoirPlus && <>dernières</>} transactions
        </h4>

        {isVoirPlus && <a href="/bien/sci-familiale/transactions" class="small">Voir plus</a>}
      </div>

      <div className="card-body">
        <div class="table-responsive mb-0" data-list="{&quot;valueNames&quot;: [&quot;goal-project&quot;, &quot;goal-status&quot;, &quot;goal-progress&quot;, &quot;goal-date&quot;]}">
          {(!transactions || transactions.length <= 0) &&
            <SimpleInfo>
              Il n'y a aucune transaction
            </SimpleInfo>
          }

          {(transactions && transactions.length > 0) &&
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a href="#" class="text-muted list-sort" data-sort="goal-project">
                      Intitulé
                    </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted list-sort" data-sort="goal-status">
                      Société / Logement
                    </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted list-sort" data-sort="goal-progress">
                      Date
                    </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted list-sort" data-sort="goal-date">
                      Montant
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody class="list">
                {transactions?.map((t) => {
                  return <tr>
                  <td class="goal-project">
                    <a href="" data-toggle="modal" data-target="#transaction">
                      {t.titre}
                    </a>
                  </td>

                  <td class="goal-project">
                    <a href={`/bien/${t.bien._id}`}>
                      <img src={t.bien.url_avatar} style={{width: '32px', height:'32px'}} class="avatar-img rounded-circle" alt="..." />
                      &nbsp;&nbsp;
                      {t.bien.titre}
                    </a>
                  </td>

                  <td class="goal-date">
                    <time datetime="2018-10-24">{t.date}</time>
                  </td>

                  <td class="goal-progress" style={{textAlign:'right'}}>
                    {displayMontant(t.montant)}
                  </td>
                </tr>
                })}              
              </tbody>
            </table>
          }
          
        </div>
      </div>
    </div>
  </>
}

export default TransactionsCard