import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Navigation from '../../toolsComponents/Navigation'
import UneCalculatriceRentabilite from './UneCalculatriceRentabilite'
import { performGetUneCalculatriceRentabilite, performSyncUneCalculatriceRentabilite, uneCalculatriceRentabiliteDatasSelector, uneCalculatriceRentabiliteStatusSelector, performDelUneCalculatriceRentabilite } from './uneCalculatriceRentabiliteSlice'
import SimpleError from '../../toolsComponents/SimpleError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'


const UneCalculatriceRentabiliteWithStore = () => {

  const dispatch = useDispatch()
  const [ cookies ] = useCookies()

  const { loading, hasError, errorMessage } = useSelector(uneCalculatriceRentabiliteStatusSelector)
  const calculatrice = useSelector(uneCalculatriceRentabiliteDatasSelector)

  useEffect(() => {
    dispatch(performGetUneCalculatriceRentabilite(cookies.token))
  }, [dispatch, cookies.token])

  const handleSubmit = (state) => {
    dispatch(performSyncUneCalculatriceRentabilite(cookies.token, state))
  }

  const handleDelete = () => {
    dispatch(performDelUneCalculatriceRentabilite(cookies.token))
  }

  return (
    <>
          {/* <!-- MODAL DELETE --> */}
          <div className="modal" tabindex="-1" role="dialog" id="modal-delete-etude">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <p>Voulez-vous vraiment supprimer les données de la calculatrice ?</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleDelete}><i className="fe fe-trash"></i> Oui</button>
            <button type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fe fe-x"></i>Non</button>
          </div>
        </div>
      </div>
    </div>
      <div>
        <Navigation granted_modules="ALL" formationCategories="calculatrice de rentabilité" />

        <div className="main-content">
          {/* <UneEtudeHeader isActive="rentabilite" /> */}
          <div>
            {/* <!-- HEADER --> */}
            <div className="header">
              <div className="container-fluid">
                {/* <!-- Body --> */}
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      {/* <!-- Avatar --> */}
                      <div className="avatar avatar-lg avatar-4by3">
                        <img src="/assets/img/mockups/rentabilite-avatar.png" alt="Avatar rentabilité" className="avatar-img rounded" />
                      </div>
                    </div>

                    <div className="col ml-n3 ml-md-n2">
                      {/* <!-- Title --> */}
                      <h1 className="header-title">
                        Calculatrice de rentabilité
                      </h1>
                    </div>
                    <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
              {/* <!-- Button --> */}
              <a href="/mockups/nouveau-projet" className="btn btn-danger d-block d-md-inline-block lift" style={{marginTop: '24px'}} data-toggle="modal" data-target="#modal-delete-etude">
                  <i className="fe fe-trash"></i> Réinitialiser
              </a>
            </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                {/* <!-- CALCULATRICE */}
                {hasError && !loading &&
                  <SimpleError withHeader={false}>
                    {errorMessage}
                  </SimpleError>
                }

                {loading && 
                  <SimpleLoading />
                }

                {!loading && !hasError && 
                  <UneCalculatriceRentabilite 
                    calculatrice={calculatrice} 
                    onSubmit={handleSubmit} 
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UneCalculatriceRentabiliteWithStore