import React from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm'
import SubmitForm from '../../../toolsComponents/forms/SubmitForm'
import SelectDistance from '../../../toolsComponents/SelectDistance'

const UneEtudeGeoPortail = ({ etude, onChange, onSubmit }) => {

  const zoom = 14

  const getCoords = () => {
    if(etude && etude.commune) {
      return etude.commune.coords.split(',')[1]+','+etude.commune.coords.split(',')[0]
    }

    return ''
  }

  return (
    <>
      <div className="row">
        {/* <!-- FORMULAIRE --> */}
        <div className="col-lg-4">
          <form>
            <h2>Transports</h2>

            <div className="row">
              <div className="col">
                <SelectDistance
                  label="Distance d'une autoroute"
                  inputName="distance_autoroute"
                  value={etude.distance_autoroute}
                  onChange={onChange}
                  />
              </div>
              
              <div className="col">
                <SelectDistance
                  label="Distance d'une nationale"
                  inputName="distance_nationale"
                  value={etude.distance_nationale}
                  onChange={onChange}
                  />
              </div>
              
              <div className="col">
                <SelectDistance
                  label="Distance d'une départementale"
                  inputName="distance_departementale"
                  value={etude.distance_departementale}
                  onChange={onChange}
                  />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <SelectDistance
                  label="Aéroport"
                  inputName="aeroport"
                  value={etude.aeroport}
                  onChange={onChange}
                />
              </div>

              <div className="col">
                <SelectDistance
                  label="Gare ferrovière"
                  inputName="gare_ferroviere"
                  value={etude.gare_ferroviere}
                  onChange={onChange}
                />
              </div>

              <div className="col">
                {/* <SelectDistance
                  label="Gare routière"
                  inputName="gare_routiere"
                  value={etude.gare_routiere}
                  onChange={onChange}
                /> */}
              </div>
            </div>

            <h2>Infrastructures</h2>

            <div className="row">
              <div className="col">
                <SelectDistance
                  label="Hôpital"
                  inputName="hopital"
                  value={etude.hopital}
                  onChange={onChange}
                />
              </div>

              <div className="col">
                <SelectDistance
                  label="Ecole supérieure"
                  inputName="ecole_superieure"
                  value={etude.ecole_superieure}
                  onChange={onChange}
                />
              </div>

              <div className="col">
                
              </div>
            </div>

            <h2>Nuisances</h2>

            <div className="row">
              <div className="col">
                <SelectDistance
                  label="Zone de bruits"
                  inputName="zone_bruits"
                  value={etude.zone_bruits}
                  onChange={onChange}
                />
              </div>

              <div className="col">
                {/* <SelectOuiNon
                  label="Zone inondable"
                  inputName="zone_inondable"
                  value={etude.zone_inondable}
                  onChange={onChange}
                /> */}
              </div>

              <div className="col">
                
              </div>
            </div>

            <h2>Quartiers</h2>

            <div className="row">
              <div className="col">
                <InputForm 
                  label="Premier quartier idéal"
                  inputName="quartier_ideal1"
                  value={etude.quartier_ideal1}
                  sideBadgeValue="+"
                  sideBadgeColor="success"
                  onChange={onChange}
                />
              </div>
              
              <div className="col">
                <InputForm 
                  label="Deuxième quartier idéal"
                  inputName="quartier_ideal2"
                  value={etude.quartier_ideal2}
                  sideBadgeValue="+"
                  sideBadgeColor="success"
                  onChange={onChange}
                />
              </div>
              
              <div className="col">
                <InputForm 
                  label="Troisième quartier idéal"
                  inputName="quartier_ideal3"
                  value={etude.quartier_ideal3}
                  sideBadgeValue="+"
                  sideBadgeColor="success"
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <InputForm 
                  label="Premier quartier à éviter"
                  inputName="quartier_eviter1"
                  value={etude.quartier_eviter1}
                  sideBadgeValue="-"
                  sideBadgeColor="danger"
                  onChange={onChange}
                />
              </div>
              
              <div className="col">
                <InputForm 
                  label="Deuxième quartier à éviter"
                  inputName="quartier_eviter2"
                  value={etude.quartier_eviter2}
                  sideBadgeValue="-"
                  sideBadgeColor="danger"
                  onChange={onChange}
                />
              </div>
              
              <div className="col">
                <InputForm 
                  label="Troisième quartier à éviter"
                  inputName="quartier_eviter3"
                  value={etude.quartier_eviter3}
                  sideBadgeValue="-"
                  sideBadgeColor="danger"
                  onChange={onChange}
                />
              </div>
            </div>

            {/* <!-- Sauvegarder --> */}
            <SubmitForm
              label="Sauvegarder"
              onSubmit={onSubmit}
            />
          </form>
        </div>
      
        {/* <!-- IFRAME --> */}
        <div className="col-lg-8">
          <iframe title="Geoportail" width="100%" height="800" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" sandbox="allow-forms allow-scripts allow-same-origin" src={`https://www.geoportail.gouv.fr/embed/visu.html?c=${getCoords()}&z=${zoom}&l0=ORTHOIMAGERY.ORTHOPHOTOS::GEOPORTAIL:OGC:WMTS(1)&l1=UTILITYANDGOVERNMENTALSERVICES.IGN.POI.SANTE::GEOPORTAIL:OGC:WMS(1)&l2=UTILITYANDGOVERNMENTALSERVICES.IGN.POI.GARES::GEOPORTAIL:OGC:WMS(1)&l3=TRANSPORTNETWORKS.ROADS::GEOPORTAIL:OGC:WMTS(1)&l4=TRANSPORTNETWORKS.RUNWAYS::GEOPORTAIL:OGC:WMTS(1)&l5=UTILITYANDGOVERNMENTALSERVICES.IGN.POI.ENSEIGNEMENTSUPERIEUR::GEOPORTAIL:OGC:WMS(1)&l6=DGAC-PEB_BDD_FXX_WM_WMS::GEOPORTAIL:OGC:WMS(0.6)&l7=GEOGRAPHICALNAMES.NAMES::GEOPORTAIL:OGC:WMTS(1)&permalink=yes`} allowfullscreen></iframe>
        </div>
      </div>
    </>
  )
}

export default UneEtudeGeoPortail