import React from 'react'
import LogementRevenus from './RevenusModules/LogementRevenus';

class Revenus extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      nombreLogements: null,
      nombreRotations:null,
      logementsData: [],
      revenusTotalTousLogementsLDNue:null,
      revenusTotalTousLogementsLDMeuble:null,
      revenusTotalTousLogementsLCD:null,
    };
  }

  logementMapping = (e) => {
    const newArray = [...this.state.logementsData]
    if(this.state.nombreLogements < this.state.logementsData.length){newArray.pop()}
    newArray[e.numerologementdansArray-1] = e
  
    let revenusTotauxLDNue = 0
    newArray.forEach(logement => revenusTotauxLDNue += logement.revenusTotauxLDNue)
  
    let revenusTotauxLDMeuble = 0
    newArray.forEach(logement => revenusTotauxLDMeuble += logement.revenusTotauxLDMeuble)
  
    let revenusTotauxLCD = 0
    newArray.forEach(logement => revenusTotauxLCD += logement.revenusTotauxLCD)

    let nombreRotations = 0
    newArray.forEach(logement => nombreRotations += logement.nombreRotationsMois)
  
    this.setState({logementsData: newArray, nombreRotations: nombreRotations, revenusTotalTousLogementsLDNue: revenusTotauxLDNue.toFixed(2), revenusTotalTousLogementsLDMeuble: revenusTotauxLDMeuble.toFixed(2), revenusTotalTousLogementsLCD: revenusTotauxLCD.toFixed(2)});
    if(typeof this.props.onChange === 'function') this.props.onChange(this.state);
  
  }
  
  logements = () => {
    let logement = []
    for(let i = 1; i <= this.state.nombreLogements; i++){
      logement.push(<LogementRevenus onChange={this.logementMapping} key={i} numerologement={i} />);
    }
    return logement;
  }

  
  handleChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const valueAsNumber = target.valueAsNumber;

    await this.setState({
      [name]: valueAsNumber || value
    });
  
  const newArray = [...this.state.logementsData]
  if(this.state.nombreLogements < this.state.logementsData.length){newArray.pop()}
  this.setState({logementsData: newArray});

  if(typeof this.props.onChange === 'function') this.props.onChange(this.state);
  }


  
render(){
                  return (<>

<div className="col-12 mt-4">
                  <h2 style={{ textDecoration: "underline", fontWeight: "800"}}>
                    REVENUS:
                  </h2>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Nombre de logements du projet finalisé</label>
                      <input
                        type="number"
                        className="form-control"
                        name="nombreLogements"
                        value={this.state.nombreLogements}
                        onChange={this.handleChange}
                        placeholder="ex: 3"
                        min="0"
                        max="50"
                      />
                  </div>
                  </div>

                                  
                  {this.logements().map(logementrevenu => logementrevenu)}
                  {this.state.nombreLogements > 0 ?
                  <div className="col-12">
                  <h3 style={{ textDecoration: "underline", fontWeight: "800"}}>
                    REVENUS TOTAUX (somme tous les logements)
                  </h3> 
                  <div className="row">
                    <div className="col-4">
                    <div className="form-group">
                      <label className="success">Revenus TOTAUX Longue Durée Nue:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="nombreLogements"
                          value={this.state.revenusTotalTousLogementsLDNue + "€/mois"}
                          readOnly
                        />
                    </div>
                    </div>

                    <div className="col-4">
                    <div className="form-group">
                      <label className="success">Revenus TOTAUX Longue Durée Meublée:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="nombreLogements"
                          value={this.state.revenusTotalTousLogementsLDMeuble + "€/mois"}
                          readOnly
                        />
                    </div>
                    </div>

                    <div className="col-4">
                    <div className="form-group">
                      <label className="success">Revenus TOTAUX Location Courte Durée:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="nombreLogements"
                          value={this.state.revenusTotalTousLogementsLCD + "€/mois"}
                          readOnly
                        />
                    </div>
                    </div>
                    </div>
                  </div> : ""}
    </>)

}}


export default Revenus;