import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import defined from '../../ressources/defined'
import Navigation from '../../toolsComponents/Navigation'
import SimpleError from '../../toolsComponents/SimpleError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'
import { loginDatasSelector } from '../auth/login/loginSlice'
import Tutoriels from './Tutoriels'
import { filterByText, tutorielsPaginatedDatasSelector, tutorielsStatusSelector, performGetTutoriels, tutorielsFilterSelector } from './tutorielsSlice'

const TutorielsWithStore = () => {

  const [ cookies ] = useCookies(['token', 'email'])
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(tutorielsStatusSelector)
  const tutoriels = useSelector(tutorielsPaginatedDatasSelector)
  const filter = useSelector(tutorielsFilterSelector)

  useEffect(() => {
    // Get tutoriels
    dispatch(performGetTutoriels(cookies.token))
  }, [dispatch, cookies.token])

  const handleSearch = (value) => {
    dispatch(filterByText({
      value,
      fields: ['title', 'categories']
    }))
  }

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      <div className="main-content bg-fixed-bottom" style={{backgroundImage:'url(/assets/img/illustrations/sticky.svg)'}}>

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-10">
              {/* <!-- Header --> */}
              <div className="header mt-md-5">
                <div className="header-body">
                  {/* <!-- Pretitle --> */}
                  <h6 className="header-pretitle">
                    Tutoriels
                  </h6>

                  {/* <!-- Title --> */}
                  <h1 className="header-title">
                    Devenez un pro de ProjetImmo.io !
                  </h1>
                </div>
              </div>

              {loading && 
                <SimpleLoading />
              }

              {hasError &&
                <SimpleError>
                  {errorMessage}
                </SimpleError>
              }

              {!loading && !hasError &&
                <Tutoriels 
                  tutoriels={tutoriels}
                  searchValue={filter.text.value}
                  onSearch={handleSearch}
                />
              }
              </div>
          </div>
        </div>
      </div> 
    </div>
  )
}

export default TutorielsWithStore