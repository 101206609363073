import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetTravaux = () => {


    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">
                
                <UnProjetHeader isActive="travaux" isActiveSubMenu="resume" />

                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-12">

                            <div className="row">

                                {/* <!-- Rendez-vous --> */}
                                <div className="col-4 col-xl-4">

                                    <div className="card">
                                            
                                        <div className="card-header">

                                            {/* <!-- Title --> */}
                                            <h4 className="card-header-title">
                                                Prochains rendez-vous artisans
                                            </h4>

                                            <a href="/mockups/mes-rendez-vous" className="small">Voir plus</a>

                                        </div>

                                        <div className="card-body">

                                            <div className="list-group list-group-flush my-n3">

                                                {/* Rendez-vous 1 */}
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">

                                                            {/* <!-- Avatar --> */}
                                                            <a href="/mockups/artisans/batir-48-SARL" className="avatar">
                                                            <img src="/assets/img/mockups/artisan-1-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                                            </a>

                                                        </div>
                                                        <div className="col ml-n2">

                                                            {/* <!-- Title --> */}
                                                            <h4 className="mb-1">
                                                            <a href="/mockups/mes-rendez-vous">Mardi 27 avril 2021 à 16h30</a>
                                                            </h4>

                                                            {/* <!-- Téléphone --> */}
                                                            <p className="card-text small">
                                                            <small>Vérifier les fondations du chalet à Florac Trois Rivières</small>
                                                            </p>

                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                {/* Rendez-vous 2 */}
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">

                                                            {/* <!-- Avatar --> */}
                                                            <a href="/mockups/artisans/batir-48-SARL" className="avatar">
                                                            <img src="/assets/img/mockups/artisan-3-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                                            </a>

                                                        </div>
                                                        <div className="col ml-n2">

                                                            {/* <!-- Title --> */}
                                                            <h4 className="mb-1">
                                                            <a href="/mockups/mes-rendez-vous">Mercredi 28 avril 2021 à 11h</a>
                                                            </h4>

                                                            {/* <!-- Téléphone --> */}
                                                            <p className="card-text small">
                                                            <small>Faire le devis pour la rénovation du toit</small>
                                                            </p>

                                                        </div>
 
                                                    </div>
                                                </div>
                                                
                                                {/* Rendez-vous 3 */}
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">

                                                            {/* <!-- Avatar --> */}
                                                            <a href="/mockups/artisans/batir-48-SARL" className="avatar">
                                                            <img src="/assets/img/mockups/artisan-5-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                                            </a>

                                                        </div>
                                                        <div className="col ml-n2">

                                                            {/* <!-- Title --> */}
                                                            <h4 className="mb-1">
                                                            <a href="/mockups/mes-rendez-vous">Mercredi 28 avril 2021 à 18h</a>
                                                            </h4>

                                                            {/* <!-- Téléphone --> */}
                                                            <p className="card-text small">
                                                            <small>Changer les dalles de la terrasse princiaple</small>
                                                            </p>

                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-12" style={{textAlign:'center'}}>

                                                            <a  href="/mockups/mes-rendez-vous">Ajouter un rendez-vous</a>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                        
                                    </div>
                                    
                                </div>

                                {/* Statistiques */}
                                <div className="col-4 col-xl-4">
                                    <div className="card">
                                            
                                            <div className="card-header">
    
                                                {/* <!-- Title --> */}
                                                <h4 className="card-header-title">
                                                    Statistiques
                                                </h4>

                                                <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/devis" className="small">Voir plus</a>
    
                                            </div>
    
                                            <div className="card-body">
    
                                            <div className="list-group list-group-flush my-n3">
    
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                    <div className="col">
    
                                                        <h5 className="mb-0">
                                                        Montant total des devis acceptés
                                                        </h5>
    
                                                    </div>
                                                    <div className="col-auto">
    
                                                        <small className="text-muted">
                                                        86 700 €
                                                        </small>
    
                                                    </div>
                                                    </div>
                                                </div>

                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                    <div className="col">
    
                                                        <h5 className="mb-0">
                                                        Nombre de devis acceptés
                                                        </h5>
    
                                                    </div>
                                                    <div className="col-auto">
    
                                                        <small className="text-muted">
                                                        2
                                                        </small>
    
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                    <div className="col">
    
                                                        <h5 className="mb-0">
                                                        Montant total des devis en attente
                                                        </h5>
    
                                                    </div>
                                                    <div className="col-auto">
    
                                                        <small className="text-muted">
                                                        40 000 €
                                                        </small>
    
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                    <div className="col">
    
                                                        <h5 className="mb-0">
                                                        Nombre de devis en attente
                                                        </h5>
    
                                                    </div>
                                                    <div className="col-auto">
    
                                                        <small className="text-muted">
                                                        2
                                                        </small>
    
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                    <div className="col">
    
                                                        <h5 className="mb-0">
                                                        Nombre de devis rejetés
                                                        </h5>
    
                                                    </div>
                                                    <div className="col-auto">
    
                                                        <small className="text-muted">
                                                        0
                                                        </small>
    
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                                
                                            </div>
                                            
                                        </div>
                                </div>
                            
                                {/* Photos */}
                                <div className="col-4 col-xl-4">
                                    <div className="card">
                                            
                                            <div className="card-header">
    
                                                {/* <!-- Title --> */}
                                                <h4 className="card-header-title">
                                                    Plans
                                                </h4>
    
                                                <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/plans" className="small">Voir plus</a>
    
                                            </div>
    
                                            <div className="card-body" style={{padding:'6px 24px 6px 24px'}}>
    
                                                <div className="list-group list-group-flush my-n3">
    
                                                    <div className="list-group-item">
                                                        <div className="row align-items-center">
                                                            
                                                            <div className="row">
                                                                <div className="col-12" style={{textAlign:'center'}}>
    
                                                                    <img 
                                                                    alt="..."
                                                                    className="rounded border-body"
                                                                    style={{maxWidth:"100%"}}
                                                                    src="/assets/img/mockups/projet-5/projet-5-plan-1.jpeg" 
                                                                    />
    
                                                                </div>
                                                            </div>
    
                                                            <div className="row">
                                                                <div className="col-6" style={[{textAlign:'center'}, {padding:'6px 3px 0px 12px'}]}>
    
                                                                    <img 
                                                                    alt="..."
                                                                    style={{maxWidth:"100%"}}
                                                                    className="rounded border-body"
                                                                    src="/assets/img/mockups/projet-5/projet-5-plan-4.jpeg" 
                                                                    />
    
                                                                </div>
    
                                                                <div className="col-6" style={[{textAlign:'center'}, {padding:'6px 12px 0px 3px'}]}>
    
                                                                    <img 
                                                                    alt="..."
                                                                    className="rounded border-body"
                                                                    style={{maxWidth:"100%"}}
                                                                    src="/assets/img/mockups/projet-5/projet-5-plan-6.jpeg" 
                                                                    />
    
                                                                </div>
                                                            </div>
    
                                                        </div>
                                                    </div>
    
                                                </div>
    
    
                                            </div>
                                            
                                        </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div> 
    )
}

export default UnProjetTravaux