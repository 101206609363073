import React from 'react'

const SelectTypeEmploi = ({ label="Type d'emploi", inputName="type_emploi", value, onChange }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <div className="form-group">
      <label htmlFor={inputName+'-select'}>
        {label}
      </label>

      <select className="form-control" name={inputName} id={inputName+'-select'} onChange={handleChange} value={value}>
        <option value="">---</option>
        <option value="Indépendant">Indépendant</option>
        <option value="Chef d'entreprise">Chef d'entreprise</option>
        <option value="Fonctionnaire de l'état">Fonctionnaire de l'état</option>
        <option value="Contrat de travail à durée indéterminée (CDI)">Contrat de travail à durée indéterminée (CDI)</option>
        <option value="Contrat à durée déterminée (CDD)">Contrat à durée déterminée (CDD)</option>
        <option value="Contrat de travail temporaire (Intérimaire)">Contrat de travail temporaire (Intérimaire)</option>
        <option value="Contrat de travail à temps partiel">Contrat de travail à temps partiel</option>
        <option value="Contrat de travail intermittent">Contrat de travail intermittent</option>
        <option value="Contrat saisonnier">Contrat saisonnier</option>
        <option value="hèque emploi-service universel (CESU)">Chèque emploi-service universel (CESU)</option>
        <option value="Chômage">Chômage</option>
        <option value="Longue maladie">Longue maladie</option>
        <option value="Revenu de solidarité active (RSA)">Revenu de solidarité active (RSA)</option>
      </select>
    </div>
  )
}

export default SelectTypeEmploi