import React, { useState } from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm'

const RessetPassword = ({ onSubmit }) => {

  const [ email, setEmail ] = useState('')

  const [ emailIsValid, setEmailIsValid] = useState(true)

  const handleEmailChanged = (e) => {
    setEmail(e.value)
  }

  const handleSubmit = (e) => {
      e.preventDefault()
      let emailOk = true

      // Errors management
      if( !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
        emailOk = false
      }
      else if( email === '' ) {
        emailOk = false
      }

      setEmailIsValid(emailOk)

      if( emailOk && typeof onSubmit === 'function' ) {
        onSubmit(email)
      }
  }

  return (
    <div>
      {/* Heading */}
      <h1 className="display-4 text-center mb-3">
          Mot de passe oublié ?
      </h1>

      {/* Sub-Heading */}
      <p className="text-muted text-center mb-5">
          Faites une demande de changement de mot de passe
      </p>

      <form onSubmit={handleSubmit}>

        {/* Email field */}
        <InputForm 
          label="Adresse email de votre compte"
          type="email"
          value={email}
          placeholder="nom@domaine.com"
          onChange={handleEmailChanged}
        />

        {/* Email errors management */}
        {!emailIsValid &&
          <div className="alert alert-danger" role="alert">
              Format de l'email invalide
          </div>
        }

        {/* Submit button */}
        <button className="btn btn-lg btn-block btn-primary mb-3" tabIndex="3" onSubmit={handleSubmit}>
            Demander un changement de mot de passe
        </button>
  
      </form>
    </div>
  )
}

export default RessetPassword