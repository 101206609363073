import React, { useEffect } from 'react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetSitGeo = () => {

    
    useEffect(() => {

        const map = L.map('map', {
            center : [44.31853509847598, 3.5952204173750015],
            zoom : 13,
            zoomControl : false
        })

        L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
            detectRetina: true,
            maxZoom: 20,
            maxNativeZoom: 17
        }).addTo(map)

        var greenIcon = L.icon({
            iconUrl: 'https://unpkg.com/leaflet@1.1.0/dist/images/marker-icon-2x.png',
            shadowUrl: '',
        
            iconSize:     [25, 40], // size of the icon
            shadowSize:   [50, 64], // size of the shadow
            iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
        });

        // LEGACY
        // eslint-disable-next-line
        var marker = L.marker([44.31853509847598, 3.5952204173750015], {icon: greenIcon}).addTo(map)
    })
    

    const pointsForts = 
`En pleine nature dans un cadre idylique`

    const lieuxInterets = 
`A Florac-Trois-Rivières on peut pratiquer les sports suivants : basket-ball, canoë de randonnée, course d'orientation - pédestre, equitation, saut d'obstacle, football, football en salle (futsal), handball, mini hand, handball de plage, aikido, aikibudo, budo, judo, jujitsu, taïso, escalade, escalade sur via ferrata, corda, pêche sportive à la mouche et au lancer, pétanque et jeu provencal, randonnée pédestre, planche à roulettes (skate), badminton, jeu de volant, tennis, tennis de table, tir à l'arc, vol à voile, volley-ball, volley-ball de plage (beach-volley), green-volley, activités de forme et de santé, escalade sur pah`

    const axesRoutiers = 
`Nationale n106
Proche autoroute A75`

    const gares = 
`Gare de Florac Trois Rivières`

    const aeroports = 
`Aéroport Le Puy Loudes
Aéroport de Nimes`


        return (
            <div>
                <Navigation granted_modules="ALL" />
    
                <div className="main-content">
                    
                    <UnProjetHeader isActive="edm" isActiveSubMenu="situation-geographique" />
    
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12">

                                {/* Map */}
                                <div className="row">
                                    <div className="col-12">
                                        <div id="map" style={{width:"1280px", height:"250px"}}></div>
                                    </div>
                                </div>

                                <br />
    

                                {/* <!-- FORMULAIRE --> */}
                                <div className="row">
                                    
                                    <div className="col-12">
    
                                        <form className="mb-4">
    
                                            {/* Prix au m2 à l'achat et à la location */}
                                            {/* <div className="row">
    
                                                <div className="col-12 col-md-6">
    
                                                    <div className="form-group">
                                                        <label>Prix à l'achat au m²</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">€</div>
                                                            </div>
                                                            <input type="number" className="form-control" value="1586" />
                                                        </div>
                                                    </div>
    
                                                </div>
    
                                                <div className="col-12 col-md-6">
    
                                                    <div className="form-group">
                                                        <label>Prix à la location au m²</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">€</div>
                                                            </div>
                                                            <input type="number" className="form-control" value="8.50" />
                                                        </div>
                                                    </div>
    
                                                </div>
    
    
                                            </div>
     */}
                                        
    
                                            {/* Points forts */}
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlTextarea1">Points forts</label>
                                                <textarea value={pointsForts}  className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                            </div>

                                            {/* Lieux d'intérêts */}
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlTextarea1">Lieux d'intérêts</label>
                                                <textarea value={lieuxInterets}  className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                            </div>

                                            {/* Axes routiers */}
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlTextarea1">Axes routiers</label>
                                                <textarea value={axesRoutiers}  className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                            </div>

                                            {/* Gares à proximité */}
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlTextarea1">Gare à proximités</label>
                                                <textarea value={gares}  className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                            </div>

                                            {/* Aéroports à proximité */}
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlTextarea1">Aéroports à proximité</label>
                                                <textarea value={aeroports}  className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                            </div>
    
                                            
    
                                            <div className="form-group">
                                                <div className="float-right">
                                                    <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                                                </div>
                                            </div>
                                            
    
                                        </form>
    
                                    </div>
                                
                                </div>
                            
                            </div>
                        </div>
                    </div>
    
                </div>
            </div> 
        )
}

export default UnProjetSitGeo