import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import UneEtudeHeader from '../UneEtudeHeader'
import Navigation from '../../../toolsComponents/Navigation'
import SimpleContainedError from '../../../toolsComponents/SimpleContainedError'
import { loginDatasSelector } from '../../auth/login/loginSlice'
import { performGetUneEtude, uneEtudeDatasSelector, uneEtudeStatusSelector, performDelUneEtude, updateByField, performSyncUneEtude } from '../uneEtudeSlice'
import SimpleLoading from '../../../toolsComponents/SimpleLoading'
import UneEtudeValeursFoncieres from './UneEtudeValeursFoncieres'

const UneEtudeValeursFoncieresWithStore = ({ id }) => {

  const dispatch = useDispatch()
  const { granted_modules } = useSelector(loginDatasSelector)
  const [ cookies ] = useCookies(['token', 'email'])
  const { hasError, errorMessage, loading, lastAction } = useSelector(uneEtudeStatusSelector)
  const etude = useSelector(uneEtudeDatasSelector)

  useEffect(() => {
      // Get une etude
      dispatch(performGetUneEtude(cookies.token, id))
  }, [dispatch, cookies.token, id])

  const handleChange = (e) => {
    dispatch(updateByField({ field: e.inputName, value: e.value }))
  }

  const handleSubmit = (e) => {
    dispatch(performSyncUneEtude(cookies.token, etude))
  }

  const handleDelete = () => {
    dispatch(performDelUneEtude(cookies.token, etude))
  }

  if(!loading && !hasError && lastAction === 'DEL') {
    return <Redirect to="/etudes" />
  }
  else {
    return (
      <div>
        <Navigation granted_modules={granted_modules} formationCategories="étude de marché"/>

        {hasError && 
          <SimpleContainedError>
            {errorMessage}
          </SimpleContainedError>
        }

        {!hasError &&
          <div className="main-content">
            <UneEtudeHeader 
              isActive="foncieres"
              etude={etude}
              onDelete={handleDelete}
            />
            
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12">
                  {loading &&
                    <SimpleLoading />
                  }

                  {/* 
                    Années 2020 avec des Infinity ? (14689, 76217, 79179), pareil Infinity dans maisons (27396)
                      => Quand je les traite en solo, pas d'infini ...

                    { appartements: { $elemMatch: { 'global.prix_bas': Infinity } } }
                    { maisons: { $elemMatch: { 'global.prix_bas': Infinity } } }
                      => Pour tester les Infinity
                    
                      {code_commune: '14689', nature_mutation: 'Vente'} => 1020 résultats
                      {code_commune: '14689', nature_mutation: 'Vente', code_type_local: '1'} => 356 résultats (maisons)
                      {code_commune: '14689', nature_mutation: 'Vente', code_type_local: '2'} => 83 résultats (appartements)
                        146890000A0649 id parcelle sans code type local => type sols

                     --> Me créer une petite boucle qui détecte les villes avec + de 50 ventes dans un domaine et non l'autre
                      { maisons: { $elemMatch: { 'global.nb_transactions': 0 } }, appartements: { $elemMatch: { 'global.nb_transactions': { $gt : 50 } } } }

                    Les différents cas : 
                    - il n'y a pas du tout de données dans DVF (ex : Colmar)
                      => OK - Affichage onglet valeur foncieres : On explique qu'il n'y a pas du tout de données pour cette ville car les départements envoient pas au notaire, blabla ...
                      => OK - Rentrer les données manuellement ? Oui avec le lien de Meilleurs Agents
                      => Affichage en mode liste sur la page mes études : Les données manuelles sont affichés en mode liste

                    - il manque 2021 (ex: Bourgoin-Jallieu, Mende (appartements)), les estimations principales sont à 0
                      => Affichage onglet valeur foncieres : ???
                      => Rentrer les données manuellement ? : ???
                      => Affichage en mode liste sur la page mes études : ???
                      
                    - Il y a eu des ventes de maisons et mais pas d'appartements (ex : Bouglainval (28052), Raon-sur-plaine (38553) ), donc le côté appartements (les infos les plus importantes pour les investisseurs) sont vides
                      => OK - Affichage onglet valeur foncieres : Dans le côté des appartements, expliquer qu'il n'y a pas assez de données pour calculer une estimation et mettre en haut de quoi saisir les nouvelles infos
                      => OK - Rentrer les données manuellement ? : Oui, au dessus des 2 tableaux
                      => Affichage en mode liste sur la page mes études : Ordre de préférence : manuel, appartements, maisons

                    - Il y a eu très peu de ventes, les chiffres ressemblent à rien : (ex : ABBEVILLE-LES-CONFLANS, 13 ventes de maisons en 5 ans, AMBERAC, 27 ventes en 5 ans)
                      => OK - Affichage onglet valeur foncieres : On affiche qu'il n'y a pas assez de données pour cette ville, il faut au moins 50 ventes pour afficher un type (maison ou appartement)
                      => OK - Rentrer les données manuellement ? Oui avec le lien de Meilleurs Agents
                      => Affichage en mode liste sur la page mes études : Les données manuelles sont affichés en mode liste
                  */}

                  {!loading && 
                    <UneEtudeValeursFoncieres
                      etude={etude}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default UneEtudeValeursFoncieresWithStore