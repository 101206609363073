import React from 'react'
import InputForm from '../../toolsComponents/forms/InputForm'

const UneOffrePret = ({ offrePret }) => {

  const tmpDate = new Date(offrePret.date_offre)
  const strDate = tmpDate.toLocaleDateString("fr-FR")

  return (
    <div>
      {/* <!-- Header --> */}
      <div className="header mt-md-5">

        <div className="header-body">
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="avatar avatar-lg avatar-4by3">
                <img src={offrePret.banque?.url_avatar} alt="Avatar de la banque" className="avatar-img rounded" />
              </div>
            </div>
            <div className="col ml-n3 ml-md-n2">

              {/* <!-- Pretitle --> */}
              <h6 className="header-pretitle">
                Offre de pret / {offrePret.banque?.nom}
              </h6>

              {/* <!-- Title --> */}
              <h1 className="header-title">  
                <span className={"item-score badge badge-soft-" + getBadge(offrePret.status)}> 
                  {offrePret.status?.toLowerCase()}
                </span>
                &nbsp; {offrePret.montant_pret} € pour &nbsp;
                <a href={'/mes-projets/'+offrePret.projet?._id}style={{color:'#2c7be5'}}> 
                  {offrePret.projet?.titre}
                </a>
              </h1>
            </div>
          </div>
        </div>

        <br /><br />

        {/* <!-- Main Card --> */}
        <div className="card">
          <div className="card-body">
            <div className="row">
              {/* <!-- FORMULAIRE --> */}
              <div className="col-12">
                <form className="mb-4">
                  <div className="row">
                    <div className="col-6">
                      {/* <!-- Date de l'offre --> */}
                      <InputForm 
                        label="Date de l'offre"
                        inputName="date_offre"
                        value={strDate}
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Status --> */}
                      <InputForm 
                        label="Status"
                        inputName="status"
                        value={offrePret.status}
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Montant du prêt --> */}
                      <InputForm 
                        label="Montant du prêt"
                        inputName="montant_pret"
                        value={offrePret.montant_pret}
                        prepend="€"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Durée du prêt --> */}
                      <InputForm 
                        label="Durée du prêt"
                        inputName="duree_pret"
                        value={offrePret.duree_pret}
                        prepend="ans"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Taux d'intêret --> */}
                      <InputForm 
                        label="Taux d'intêret"
                        inputName="taux_interet"
                        value={offrePret.taux_interet}
                        prepend="%"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Taux d'assurance --> */}
                      <InputForm 
                        label="Taux d'assurance"
                        inputName="taux_assurance"
                        value={offrePret.taux_assurance}
                        prepend="ans"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Type de taux --> */}
                      <InputForm 
                        label="Type de taux"
                        inputName="type_taux"
                        value={offrePret.type_taux}
                      />
                    </div>
                  </div>

                  <div className="row">

                    <div className="col-6">
                      {/* <!-- Mensualité --> */}
                      <InputForm 
                        label="Mensualité"
                        inputName="mensualite"
                        value={offrePret.mensualite}
                        prepend="€"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Coût des intérêts --> */}
                      <InputForm 
                        label="Coût des intérêts"
                        inputName="cout_interets"
                        value={offrePret.cout_interets}
                        prepend="€"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Coût de l'ssurance --> */}
                      <InputForm 
                        label="Coût de l'assurance"
                        inputName="cout_assurance"
                        value={offrePret.cout_assurance}
                        prepend="€"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Garantie --> */}
                      <InputForm 
                        label="Garantie"
                        inputName="garantie"
                        value={offrePret.garantie}
                        prepend="€"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Frais garantie --> */}
                      <InputForm 
                        label="Frais garantie"
                        inputName="frais_garantie"
                        value={offrePret.frais_garantie}
                        prepend="€"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- TAEG --> */}
                      <InputForm 
                        label="TAEG"
                        inputName="TAEG"
                        value={offrePret.TAEG}
                        prepend="%"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Différer --> */}
                      <InputForm 
                        label="Différer"
                        inputName="Différer"
                        value={offrePret.différer}
                        prepend="ans"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Frais de dossier --> */}
                      <InputForm 
                        label="Frais de dossier"
                        inputName="frais_dossier"
                        value={offrePret.frais_dossier}
                        prepend="€"
                      />
                    </div>

                    <div className="col-6">
                      {/* <!-- Autres frais --> */}
                      <InputForm 
                          label="Autres frais"
                          inputName="autre_frais"
                          value={offrePret.autre_frais}
                          prepend="€"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function getBadge(status) {
  switch(status) {
    case 'Accepté':
      return 'success'
    case 'En attente':
      return 'warning'
    case 'Refusé':
      return 'danger'
    default:
      return 'light'
  }
}

export default UneOffrePret