import React from 'react'

const UneFormation = ({ formation }) => {

  const getDifficultyStars = (difficulty) => {
    var result = []
    for(let i = 0; i < difficulty; i++) {
      result.push(<i className="fe fe-star text-warning active" style={{paddingRight:'2px'}}></i>)
    }

    return result
  }

  const getCategoriesLink = (cats) => {
    var result = []
    if(cats) {
      for(let i = 0; i < cats.length; i++) {
        result.push(<a href={'/formations/categories/'+cats[i]}>{cats[i]}</a>)
        if(i !== cats.length - 1) {
          result.push(<span>,&nbsp;</span>)
        }
      }
    }
    
    return result
  }

  return (
      <div>
      {/* <!-- Header --> */}
      <div className="header mt-md-5">
        <div className="header-body">
          {/* <!-- Pretitle --> */}
          <h6 className="header-pretitle">
            Formations {formation.main_module && `/ ${formation.main_module}`} {formation.sub_module && `/ ${formation.sub_module}`}
          </h6>

          {/* <!-- Title --> */}
          <h1 className="header-title">
            {formation.title}
          </h1>
        </div>

        {/* <!-- Main Card --> */}
        <div className="card">
          <div className="card-body text-center">
            <div className="row">
              <div className="col-12">
                <iframe width="1078" height="605" src={formation.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                <br /><br />

                <div className="row">
                  <div className="col-4">
                    <h4>Difficulté : {getDifficultyStars(formation.difficulty)}</h4>
                  </div>

                  <div className="col-4"></div>

                  <div className="col-4">
                    <h4>Présentateur : {formation.author}</h4>
                  </div>
                </div>
              </div>

              <div className="col-12">
                Tags : {getCategoriesLink(formation.categories)}
              </div>

              <div className="col-12" style={{textAlign:'left'}}>
                {(formation.description && formation.description !== '') &&
                  <p>
                    Contenu de la vidéo : <br />
                    {formation.description}
                  </p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UneFormation