import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { unProjetDatasSelector, updateByField } from '../unProjetSlice'
import UnProjetRentabilite from './UnProjetRentabilite'
import { dispatchChargesComponentDataToRedux, dispatchCreditComponentDataToRedux, dispatchEndettementComponentDataToRedux, dispatchInfosGeneralesComponentDataToRedux, dispatchRevenusComponentDataToRedux, rentabiliteExists } from './rentabiliteUtils'
import UnProjetGenericWithStore from '../UnProjetGenericWithStore'
import { useRef } from 'react'

const UnProjetRentabiliteWithStore = ({ id }) => {

  const dispatch = useDispatch()

  const projet = useSelector(unProjetDatasSelector)

  const [ redirectToWizard, setRedirectToWizard ] = useState(false)

  useEffect(() => {
    const checkIfRedirectToWizard = () => {
      if( projet && projet.rentabilite) {
        if(rentabiliteExists(projet)) {
            return false
        }
        else {
          return true
        }
      }
  
      return false
    }

    setRedirectToWizard(checkIfRedirectToWizard())
  }, [projet])

  const handleChangeInfosGenerales = (values) => {
    dispatchInfosGeneralesComponentDataToRedux({ dispatch, updateByField, values })
  }

  const handleChangeCredit = (values) => {
    dispatchCreditComponentDataToRedux({ dispatch, updateByField, values })
  }

  const handleChangeRevenus = (values) => {
    dispatchRevenusComponentDataToRedux({ dispatch, updateByField, values })
  }

  const handleChangeCharges = (values) => {
    dispatchChargesComponentDataToRedux({ dispatch, updateByField, values })
  }

  const handleChangeEndettement = (values) => {
    dispatchEndettementComponentDataToRedux({ dispatch, updateByField, values })
  }

  const genericComponent = useRef(null)
  
  if( projet && projet._id && redirectToWizard ) {
    return <Redirect push to={`/projets/${projet._id}/rentabilite/wizard`} />
  }
  else {
    return (
      <>
        <UnProjetGenericWithStore
          ref={genericComponent}
          id={id}
          activeMenu="rentabilite"
          formationCategories="calculatrice de rentabilité, projets de bien"
        >
          <UnProjetRentabilite
            projet={projet}
            onChangeInfosGenerales={handleChangeInfosGenerales}
            onChangeCredit={handleChangeCredit}
            onChangeRevenus={handleChangeRevenus}
            onChangeCharges={handleChangeCharges}
            onChangeEndettement={handleChangeEndettement}
            onSubmit={() => genericComponent.current.handleSubmit()}
          />
        </UnProjetGenericWithStore>
      </>
    )
  }
}

export default UnProjetRentabiliteWithStore