import React from 'react'

const UneEtudeWikipedia = ({ etude }) => {

  const capitalize = (value, separator) => {
    var separateWord = value.split(separator);
    for (var i = 0; i < separateWord.length; i++) {
       separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
       separateWord[i].substring(1);
    }
    return separateWord.join(separator);
 }

  const clearNomURL = (etude) => {
    if(!etude.commune) return ''
    let result = etude.commune.nom.replace(' ', '_').toLowerCase()
    result = capitalize(result, '_')
    result = capitalize(result, '-')
    result = capitalize(result, "'")
    result = result.replace("'", '%27')
    return result
  }



  return (
    <>
      <div className="row">
        <div className="col">
        <iframe
          src={`https://fr.wikipedia.org/wiki/${clearNomURL(etude)}`}
          title="Wikipedia" 
          frameborder="0" 
          scrolling="yes" 
          style={{ minWidth: '100%', minHeight:'500px', width:'100%', height:'82vh'}}
        ></iframe>
          
        </div>
      </div>
    </>
  )
}

export default UneEtudeWikipedia