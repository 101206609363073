import React from 'react'
import helper from '../uneCalculatriceHelper'
import InputForm from '../../../toolsComponents/forms/InputForm';


/**
 * initialState -> initial data to feed this component
 * informationsGenerales -> data for fields dependancies
 * leCredit -> data for fields dependancies
 * revenus -> data for fields dependancies
 * charges -> data for fields dependancies
 * onUpdate -> to manage when a field is changed
 */
class CashflowETRenta extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.props.initialState;
  }

  updateState() {
    const tmpState = {
      infosGenerales: this.props.informationsGenerales,
      credit: this.props.leCredit,
      revenus: this.props.revenus,
      charges: this.props.charges,
      cashflow: this.state
    }

    this.setState({
      rentaBruteLDNue: helper.performRentaBruteLDNue(tmpState),

      rentaNetteLDNue: helper.performRentaNetteLDNue(tmpState),

      cashFlowLDNue: helper.performCashflowLDNue(tmpState),

      rentaBruteLDMeuble: helper.performRentaBruteLDMeuble(tmpState),

      rentaNetteLDMeuble: helper.performRentaNetteLDMeuble(tmpState),

      cashFlowLDMeuble: helper.performCashflowLDMeuble(tmpState),

      rentaBruteLCD: helper.performRentaBruteLCD(tmpState),

      rentaNetteLCD: helper.performRentaNetteLCD(tmpState),

      cashFlowLCD: helper.performCashflowLCD(tmpState)
    })
  }

  componentDidMount() {
    this.updateState()
  }

  async componentDidUpdate(prevProps) {

    if (   this.props.revenus !== prevProps.revenus 
        || this.props.leCredit !== prevProps.leCredit
        || this.props.charges !== prevProps.charges 
        || this.props.informationsGenerales !== prevProps.informationsGenerales ) {
      
      await this.setState(this.props.initialState)
      this.updateState()
    }
  }

  handleSubmit = () => {
    if( typeof this.props.onSubmit ) this.props.onSubmit(this.state)
  }

  render() {
    return (<>
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">
                  Cashflow et rentabilité en location longue durée nue
                </h4>
              </div>

              <div className="card-body">
                <div className="col-12">
                  <InputForm
                    label="Rentabilité brute"
                    type="number"
                    inputName="rentaBruteLDNue"
                    value={this.state.rentaBruteLDNue}
                    readOnly
                    prepend="%"
                    tooltip={"= revenus annuels totaux en location longue durée nue / coût d'achat avec rénovation"}
                  />
                </div>

                <div className="col-12">
                  <InputForm
                    label="Rentabilité nette (avant impôts)"
                    type="number"
                    inputName="rentaNetteLDNue"
                    value={this.state.rentaNetteLDNue}
                    readOnly
                    prepend="%"
                    tooltip={"= (revenus annuels totaux en location longue durée nue - charges annuels en location longue durée nue - mensualités du crédit de ce projet) / coût achat et rénovation"}
                  />
                </div>

                <div className="col-12">
                  <InputForm
                    label="Cashflow"
                    type="number"
                    inputName="cashFlowLDNue"
                    value={this.state.cashFlowLDNue}
                    readOnly
                    prepend="€/mois"
                    tooltip={"= revenus mensuels en location longue durée nue - total mensuel des charges en location longue durée nue - mensualités du crédit de ce projet"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">
                  Cashflow et rentabilité en location longue durée meublé
                </h4>
              </div>

              <div className="card-body">
                <div className="col-12">
                  <InputForm
                    label="Rentabilité brute"
                    type="number"
                    inputName="rentaBruteLDMeuble"
                    value={this.state.rentaBruteLDMeuble}
                    readOnly
                    prepend="%"
                    tooltip={"= revenus annuels totaux en location longue durée meublé / coût total du projet"}
                  />
                </div>

                <div className="col-12">
                  <InputForm
                    label="Rentabilité nette (avant impôts)"
                    type="number"
                    inputName="rentaNetteLDNMeuble"
                    value={this.state.rentaNetteLDMeuble}
                    readOnly
                    prepend="%"
                    tooltip={"= (revenus annuels totaux en location longue durée meublée - charges annuels en location longue durée meublée - mensualités du crédit de ce projet) / coût total du projet"}
                  />
                </div>

                <div className="col-12">
                  <InputForm
                    label="Cashflow"
                    type="number"
                    inputName="cashFlowLDMeuble"
                    value={this.state.cashFlowLDMeuble}
                    readOnly
                    prepend="€/mois"
                    tooltip={"= revenus mensuels en location longue durée meublée - total mensuel des charges en location longue durée meublée - mensualités du crédit de ce projet"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">
                  Cashflow et rentabilité en location courte durée
                </h4>
              </div>

              <div className="card-body">
                <div className="col-12">
                  <InputForm
                    label="Rentabilité brute"
                    type="number"
                    inputName="rentaBruteLCD"
                    value={this.state.rentaBruteLCD}
                    readOnly
                    prepend="%"
                    tooltip={"= revenus annuels totaux en location courte durée / coût total du projet"}
                  />
                </div>

                <div className="col-12">
                  <InputForm
                    label="Rentabilité nette (avant impôts)"
                    type="number"
                    inputName="rentaNetteLCD"
                    value={this.state.rentaNetteLCD}
                    readOnly
                    prepend="%"
                    tooltip={"= (revenus annuels totaux en location courte durée - charges annuels en location courte durée meublée - mensualités du crédit de ce projet) / coût total du projet"}
                  />
                </div>

                <div className="col-12">
                  <InputForm
                    label="Cashflow"
                    type="number"
                    inputName="cashFlowLCD"
                    value={this.state.cashFlowLCD}
                    readOnly
                    prepend="€/mois"
                    tooltip={"= revenus mensuels en location courte durée - total mensuel des charges en location courte durée meublée - mensualités du crédit de ce projet"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      { !this.props.noButton &&
        <div className="col-12">
          {/* <!-- Submit button --> */}
          <div className="form-group">
            <div className="float-right">
              <button type="submit" className="btn btn-primary mb-2" onSubmit={this.handleSubmit} onClick={this.handleSubmit}>
                Sauvegarder
              </button>
            </div>
          </div>
        </div>
      }
    </>)
  }

}


export default CashflowETRenta;