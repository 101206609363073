import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../toolsComponents/Navigation'
import SimpleContainedError from '../../toolsComponents/SimpleContainedError'
import { loginDatasSelector } from '../auth/login/loginSlice'
import UneBanque from './UneBanque'
import UneBanqueHeader from './UneBanqueHeader'
import { performGetUneBanque, uneBanqueDatasSelector, uneBanqueStatusSelector } from './uneBanqueSlice'

const UneBanqueWithStore = ( { id } ) => {

  const dispatch = useDispatch()
  const { granted_modules } = useSelector(loginDatasSelector)
  const [ cookies ] = useCookies(['token', 'email'])
  const { hasError, errorMessage } = useSelector(uneBanqueStatusSelector)
  const banque = useSelector(uneBanqueDatasSelector)

  useEffect(() => {
      // Get une banque
      dispatch(performGetUneBanque(cookies.token, id))
  }, [dispatch, cookies.token, id])

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      {hasError && 
        <SimpleContainedError>
          {errorMessage}
        </SimpleContainedError>
      }

      {!hasError &&
        <div className="main-content">
            <UneBanqueHeader 
              banque={banque}
            />

            
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12">
                  <UneBanque banque={banque} />
                </div>
              </div>
            </div>
        </div>
      }
    </div>
  )
}

export default UneBanqueWithStore