import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import './SearchContainer.css'
import SearchBar from '../components/SearchBar'
import { Redirect } from 'react-router'

const SearchContainer = ({ placeHolder, defaultValue, onSearch, onSuggest }) => {
    
    const [ searchSuggest, setSearchSuggest ] = useState([])
    const [ inputValue, setInputValue ] = useState()
    const [ cookies ] = useCookies(['token', 'email'])

    const [ isTokenExpired, setIsTokenExpired ] = useState(false)

    useEffect(() => {
      setInputValue(defaultValue)
    }, [defaultValue])

    const cleanVille = (ville) => {
        return (ville.replace("(","").replace(")","").replace(" ", "%20").normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      }

    const searchFunction = async (e) => {
        setInputValue(e.target.value)

        const reqOptions = {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json',
              'auth-token' : cookies.token
          },
          body: JSON.stringify()
        }
  
        const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/communes/search/' + cleanVille(e.target.value), reqOptions)
        const json = await res.json()
        
        if(json.error && json.message === 'Token Expired') {
          setIsTokenExpired(true)
          return
        }

        const searchSuggestion = json.datas.map(commune => `${commune.nom} (${commune.cp.split('/')[0]}) - ${commune.departement}`)

        if( typeof onSuggest === 'function' ) onSuggest(searchSuggestion)
        
        setSearchSuggest(searchSuggestion)
    }

    const outPutFunc = (param) => {
        setInputValue(param)
        setSearchSuggest([])

        onSearch(param)
    }
    

    return(
      <>
        {isTokenExpired &&
          <Redirect to="/logout" />
        }

        {!isTokenExpired && 
          <SearchBar placeHolder={placeHolder} value={inputValue} outPut={outPutFunc} searchSuggestions={searchSuggest} searchFunction={searchFunction} />
        }
      </>
        
    )

}

export default SearchContainer;