import React from 'react'

const SelectTypeBienPatrimoine = ({inputName, value, withNone=true, onChange}) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <div className="form-group">
      <label htmlFor={'input'+inputName}>
        Type de bien
      </label>

      <select className="form-control" value={value} onChange={handleChange}>
        {withNone && <option value="">---</option>}
        <option value="SOCIETE">Société</option>
        <option value="IMMEUBLE">Immeuble</option>
        <option value="LOGEMENT">Logement</option>
        <option value="NOM_PROPRE">Nom propre</option>
      </select>
    </div>
  )
}

export default SelectTypeBienPatrimoine