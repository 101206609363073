import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../toolsComponents/Navigation'
import SimpleError from '../../toolsComponents/SimpleError'
import { loginDatasSelector } from '../auth/login/loginSlice'
import Banques from './Banques'
import { banquesFilterSelector, banquesPaginatedDatasSelector, banquesPaginationSelector, banquesStatusSelector, changePagination, filterByText, performGetBanques, sort } from './banquesSlice'

const BanquesWithStore = () => {

  const [ cookies ] = useCookies(['token', 'email'])
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(banquesStatusSelector)
  const banques = useSelector(banquesPaginatedDatasSelector)
  const pagination = useSelector(banquesPaginationSelector)
  const filter = useSelector(banquesFilterSelector)

  useEffect(() => {
    // Get banques
    dispatch(performGetBanques(cookies.token))
  }, [dispatch, cookies.token])

  const handleSearch = (value) => {
    dispatch(filterByText({
      value,
      fields: ['nom', 'ville', 'code_postal']
    }))
  }

  const handlePagination = (nbPerPage, currentPage) => {
    dispatch(changePagination({
        nbPerPage, currentPage
    }))
  }

  const handleSort = (field) => {
    dispatch(sort(field))
  }

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">

              {/* <!-- Header --> */}
              <div className="header">
                  <div className="header-body">
                    <div className="row align-items-center">
                        <div className="col">
                          {/* <!-- Pretitle --> */}
                          <h6 className="header-pretitle">
                            Vue Globale
                          </h6>

                          {/* <!-- Title --> */}
                          <h1 className="header-title text-truncate">
                            Banques
                          </h1>

                          </div>
                          <div className="col-auto">
                            {/* <!-- Buttons --> */}
                            <a href="/mockups/nouvel-artisan" className="btn btn-primary ml-2">
                              <i className="fe fe-plus"></i> Ajouter une banque
                            </a>
                        </div>
                    </div>
                  </div>
              </div>

              {hasError && 
                <SimpleError>
                  {errorMessage}
                </SimpleError>
              }

              {!hasError &&
                <Banques 
                  banques={banques}
                  loading={loading}
                  pagination={pagination}
                  filter={filter}
                  onSearch={handleSearch}
                  onPagination={handlePagination}
                  onSort={handleSort}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default BanquesWithStore