import React from 'react'

const UnArtisanHeader = ({ isActive, artisan }) => {

  return (
    <div>
    {/* <!-- HEADER --> */}
    <div className="header">
      <div className="container-fluid">
        {/* <!-- Header Body --> */}
        <div className="header-body">
          <div className="row align-items-center">
            {/* <!-- Avatar --> */}
            {artisan.url_avatar && 
              <div className="col-auto">
                <div className="avatar avatar-lg avatar-4by3">
                  <img src={artisan.url_avatar} alt="" className="avatar-img rounded" />
                </div>
              </div>
            }

            <div className="col ml-n3 ml-md-n2">
              {/* <!-- Pretitle --> */}
              <h6 className="header-pretitle">
                Détails
              </h6>

              {/* <!-- Title --> */}
              <h1 className="header-title">
                {artisan.nom}
              </h1>
            </div>
          </div> {/* <!-- / .row --> */}

          <div className="row align-items-center">
            <div className="col">
                {/* <!-- Nav --> */}
              <ul className="nav nav-tabs nav-overflow header-tabs">
                <li className="nav-item">
                  <a href={"/artisans/"+artisan._id} className={ !isActive ? 'nav-link active' : 'nav-link' } >
                    Général
                  </a>
                </li>
                
                <li className="nav-item">
                  <a href={"/artisans/"+artisan._id+"/devis"} className={ isActive === 'devis' ? 'nav-link active' : 'nav-link' }>
                    Devis
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> {/* <!-- / .header --></div> */}
  </div>
  )
}

export default UnArtisanHeader