import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../../toolsSlice/objectSliceHelper'

const URL = '/new-password/'
const URL_GET_EMAIL = '/email-by-password-token/'
const helper = new objectSliceHelper()

const newPasswordSlice = createSlice({

  name: 'newPassword',
  initialState: helper.getInitialState(),
  reducers: helper.reducers
})

export const performGetEmail = (token) => {
  return async dispatch => {
    try {
      dispatch(init('GET_EMAIL'))

      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify()
      }
  
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + URL_GET_EMAIL + token, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(success(json.datas))
      else dispatch(failure(json.message))
    }
    catch(e) {
      dispatch(failure(e.toString()))
    }
  }
}

export const performNewPassword = (token, new_password) => {
  return async dispatch => {
    try {
      dispatch(init('NEW_PASSWORD'))

      const reqOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token,
          new_password
        })
      }
  
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + URL, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(success(json.datas))
      else dispatch(failure(json.message))
    }
    catch(e) {
      dispatch(failure(e.toString()))
    }
  }
}

// Export action creators
const { init, success, failure } = newPasswordSlice.actions

// Export reducer
export default newPasswordSlice.reducer

// Selectors
export const newPasswordStatusSelector = (state) => state.newPassword.status
export const newPasswordDatasSelector = (state) => state.newPassword.datas