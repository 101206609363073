import React from 'react'
import { useState } from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm'
import SubmitForm from '../../../toolsComponents/forms/SubmitForm'
import SimpleError from '../../../toolsComponents/SimpleError'

const UneEtudeValeursFoncieresForm = ({ etude, withButton, onChange, onSubmit }) => {

  const [ isCroissant, setIsCroissant ] = useState(true)

  const handleSubmit = () => {

    // Si tous les champs de prix sont vides, on peut submit
    if(!etude.prix_achat_m2_bas && !etude.prix_achat_m2 && !etude.prix_achat_m2_haut) {
      onSubmit()
    }
    // Si tous les champs de prix sont croissants, on peut submit
    else if(   parseFloat(etude.prix_achat_m2_bas) <= parseFloat(etude.prix_achat_m2) 
            && parseFloat(etude.prix_achat_m2) <= parseFloat(etude.prix_achat_m2_haut)) {
      onSubmit()
    }
    // Afficher une erreur qu'il faut renseigner les 3 champs de prix, et de manière croissante
    else {
      setIsCroissant(false)
    }
  }

  return (
    <form>
      <h2>Tarifs</h2>

      <div className="row">
        <div className="col">
          <InputForm 
            label="Fourchette basse du prix d'achat au m²"
            inputName="prix_achat_m2_bas"
            value={etude.prix_achat_m2_bas}
            prepend="€ / m²"
            type="number"
            onChange={onChange}
          />
        </div>

        <div className="col">
          <InputForm 
            label="Prix d'achat moyen au m²"
            inputName="prix_achat_m2"
            value={etude.prix_achat_m2}
            prepend="€ / m²"
            type="number"
            onChange={onChange}
          />
        </div>

        <div className="col">
          <InputForm 
            label="Fourchette haute du prix d'achat au m²"
            inputName="prix_achat_m2_haut"
            value={etude.prix_achat_m2_haut}
            prepend="€ / m²"
            type="number"
            onChange={onChange}
          />
        </div>
      </div>

      {!isCroissant &&
        <div className="row">
          <div className="col">
            <SimpleError withHeader={false}>
              La fourchette basse, le prix moyen et la fourchette haute doivent être dans l'ordre croissant (ex: votre fouchette haute de peut pas être plus élevée que votre fourchette basse).
            </SimpleError>
          </div>
        </div>
      }

      <div className="row">
        <div className="col">
          <InputForm 
            label="Evolution il y a 1 an"
            inputName="prix_achat_m2_1an"
            value={etude.prix_achat_m2_1an}
            prepend="%"
            type="number"
            onChange={onChange}
          />
        </div>
        
        <div className="col">
          <InputForm 
            label="Evolution il y a 2 ans"
            inputName="prix_achat_m2_2ans"
            value={etude.prix_achat_m2_2ans}
            prepend="%"
            type="number"
            onChange={onChange}
          />
        </div>
        
        <div className="col">
        <InputForm 
            label="Evolution il y a 5 ans"
            inputName="prix_achat_m2_5ans"
            value={etude.prix_achat_m2_5ans}
            prepend="%"
            type="number"
            onChange={onChange}
          />
        </div>
      </div>

      {/* <!-- Sauvegarder --> */}
      {withButton &&
        <SubmitForm
          label="Sauvegarder"
          onSubmit={handleSubmit}
        />
      }
      
    </form> 
  )
}

export default UneEtudeValeursFoncieresForm