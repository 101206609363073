import React from 'react';
import SearchContainer from './containers/SearchContainer'

function SearchBarTool({searchFunc, defaultValue, onSuggest}) {

  return (
    <SearchContainer 
      onSearch={searchFunc} 
      onSuggest={onSuggest}
      defaultValue={defaultValue} 
      placeHolder="Rechercher une ville..." 
    />
  )

}

export default SearchBarTool;