import React from 'react'
import { useEffect } from 'react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import Navigation from '../../toolsComponents/Navigation'
import SelectPays from '../../toolsComponents/SelectPays'
import UnProjetHeader from './UnProjetHeader'

const UnProjetGeneral = () => {

    const description = 
`trés beau chalet typique de lozére
sur un terrain de 4700 m2 tout arboré avec beaucoup de charme.
a proximité de la rivière.
pour le chauffage :cheminée et radiateur électrique et clim au sous-sol.

rez-de-chaussée:
cuisine américaine ouverte sur le salon ,avec une réserve ainsi qu'un wc.
l'étage:
est constitué de 3 belles chambres, une salle de bain (douche a l'italienne)et d'un wc.
sous-sol:
appartement tout équipé, cuisine ,salon ,salle a manger ,salle de bain.
avec accés sur le jardin`

    useEffect(() => {

        const map = L.map('map', {
            center : [44.31853509847598, 3.5952204173750015],
            zoom : 14,
            zoomControl : false
        })

        L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
            detectRetina: true,
            maxZoom: 20,
            maxNativeZoom: 17
        }).addTo(map)

        var greenIcon = L.icon({
            iconUrl: 'https://unpkg.com/leaflet@1.1.0/dist/images/marker-icon-2x.png',
            shadowUrl: '',
        
            iconSize:     [25, 40], // size of the icon
            shadowSize:   [50, 64], // size of the shadow
            iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
        });

        // LEGACY
        // eslint-disable-next-line
        var marker = L.marker([44.31853509847598, 3.5952204173750015], {icon: greenIcon}).addTo(map)
    })



    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">
                
                <UnProjetHeader isActive="general" isActiveSubMenu="resume" />

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                            <div className="row">

                                {/* <!-- FORMULAIRE --> */}
                                <div className="col-8 col-xl-8">

                                    <form className="mb-4">

                                        {/* <!-- Titre du bien --> */}
                                        <div className="form-group">

                                            <label>
                                                Titre
                                            </label>

                                            <input type="text" className="form-control" value="Chalet en pin douglas à Florac Trois Rivières" />

                                        </div>

                                        {/* Lien de l'annonce */}
                                        <div className="form-group">

                                            <label>Lien de l'annonce (<a rel="noreferrer" target="_blank" href="https://www.leboncoin.fr/ventes_immobilieres/1881665726.htm?ac=558505705">Voir l'annonce</a>)</label>

                                            <input type="text" className="form-control" value="https://www.leboncoin.fr/ventes_immobilieres/1881665726.htm?ac=558505705" />

                                        </div>

                                        <hr />

                                        {/* Prix de départ et prix objectif */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix de départ</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="295000" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix objectif</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="260000" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Prix négocié */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix négocié</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <hr />

                                        {/* Surfaces du terrain et de l'intérieur */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Surface du terrain</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">m²</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="4700" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Surface intérieure</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">m²</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="180" />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <hr />

                                        {/* Description */}
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1">Description du bien</label>
                                            <textarea value={description} className="form-control" id="exampleFormControlTextarea1" rows="5" 
                                            >
                                                
                                            </textarea>
                                        </div>

                                        {/* Objectifs */}
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1">Objectif du projet</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                        </div>

                                        <hr />

                                        {/* <!-- Adresse du bien --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Adresse
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control" value=""/>

                                        </div>

                                        {/* <!-- Ville et code postal --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Code postal
                                                    </label>

                                                    <input type="text" className="form-control" value="48 400"/>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Ville
                                                    </label>

                                                    <input type="text" className="form-control" value="Florac Trois Rivières"/>

                                                </div>

                                            </div>
                                            </div> {/* <!-- / .row --> */}

                                        {/* Pays */}
                                        <SelectPays />

                                        <div className="form-group">
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                                            </div>
                                        </div>
                                        

                                    </form>

                                </div>
                            
                                {/* CARDS*/}
                                <div className="col-4 col-xl-4">

                                    {/* Photos */}
                                    <div className="card">
                                            
                                        <div className="card-header">

                                            {/* <!-- Title --> */}
                                            <h4 className="card-header-title">
                                                Photos
                                            </h4>

                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/photos" className="small">Voir plus</a>

                                        </div>

                                        <div className="card-body" style={{padding:'6px 24px 6px 24px'}}>

                                            <div className="list-group list-group-flush my-n3">

                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        
                                                        <div className="row">
                                                            <div className="col-12" style={{textAlign:'center'}}>

                                                                <img 
                                                                alt="..."
                                                                className="rounded border-body"
                                                                style={{maxWidth:"100%"}}
                                                                src="/assets/img/mockups/projet-5/projet-5-pic-1.jpeg" 
                                                                />

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                          {/* eslint-disable-next-line */}
                                                          <div className="col-6" style={{textAlign:'center'}, {padding:'6px 3px 0px 12px'}}>

                                                              <img 
                                                              alt="..."
                                                              style={{maxWidth:"100%"}}
                                                              className="rounded border-body"
                                                              src="/assets/img/mockups/projet-5/projet-5-pic-2.jpeg" 
                                                              />

                                                          </div>

                                                          {/* eslint-disable-next-line */}
                                                          <div className="col-6" style={{textAlign:'center'}, {padding:'6px 12px 0px 3px'}}>

                                                              <img 
                                                              alt="..."
                                                              className="rounded border-body"
                                                              style={{maxWidth:"100%"}}
                                                              src="/assets/img/mockups/projet-5/projet-5-pic-3.jpeg" 
                                                            />

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                        
                                    </div>

                                    {/* <!-- Plan --> */}
                                    <div className="card">
                                            
                                        <div className="card-header">

                                            {/* <!-- Title --> */}
                                            <h4 className="card-header-title">
                                                Localisation
                                            </h4>

                                        </div>

                                        <div className="card-body" style={{padding:"12px"}}>

                                            {/* eslint-disable-next-line */}
                                            <div id="map" style={{width:"1280px"}, {height:"250px"}}></div>
                                            
                                        </div>
                                            
                                        
                                    </div>


                                    {/* <!-- Critères --> */}
                                    <div className="card">
                                            
                                        <div className="card-header">

                                            {/* <!-- Title --> */}
                                            <h4 className="card-header-title">
                                                Infos supplémentaires de l'annonce
                                            </h4>

                                        </div>

                                        <div className="card-body">

                                        <div className="list-group list-group-flush my-n3">

                                            <div className="list-group-item">
                                                <div className="row align-items-center">
                                                <div className="col">

                                                    <h5 className="mb-0">
                                                    Type de bien
                                                    </h5>

                                                </div>
                                                <div className="col-auto">

                                                    <small className="text-muted">
                                                    Maison
                                                    </small>

                                                </div>
                                                </div>
                                            </div>
                                            
                                            <div className="list-group-item">
                                                <div className="row align-items-center">
                                                <div className="col">

                                                    <h5 className="mb-0">
                                                    Nombre de pièces
                                                    </h5>

                                                </div>
                                                <div className="col-auto">

                                                    <small className="text-muted">
                                                    8
                                                    </small>

                                                </div>
                                                </div>
                                            </div>
                                            
                                            <div className="list-group-item">
                                                <div className="row align-items-center">
                                                <div className="col">

                                                    <h5 className="mb-0">
                                                    Classe énergétique
                                                    </h5>

                                                </div>
                                                <div className="col-auto">

                                                    <small className="text-muted">
                                                    C
                                                    </small>

                                                </div>
                                                </div>
                                            </div>
                                            
                                            <div className="list-group-item">
                                                <div className="row align-items-center">
                                                <div className="col">

                                                    <h5 className="mb-0">
                                                    GES
                                                    </h5>

                                                </div>
                                                <div className="col-auto">

                                                    <small className="text-muted">
                                                    C
                                                    </small>

                                                </div>
                                                </div>
                                            </div>
                                            
                                            <div className="list-group-item">
                                                <div className="row align-items-center">
                                                <div className="col">

                                                    <h5 className="mb-0">
                                                    Prix au m²
                                                    </h5>

                                                </div>
                                                <div className="col-auto">

                                                    <small className="text-muted">
                                                    1 639 €
                                                    </small>

                                                </div>
                                                </div>
                                            </div>
                                            

                                        </div>
                                            
                                        </div>
                                        
                                    </div>

                                    
                                </div>


                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div> 
    )
}

export default UnProjetGeneral