import React from 'react'
import MainTable from '../../toolsComponents/tables/MainTable'

const Artisans = ({ artisans, loading, pagination, filter, onSearch, onPagination, onSort  }) => {

    const headers = [
        {
            display_name: 'Société', 
            field: 'nom', 
            sortable: true, 
            colSpan: 1
        },
        {
            display_name: 'Corps de metiers', 
            field: 'metiers', 
            sortable: true, 
            colSpan: 1
        },
        {
            display_name: 'Localisation', 
            field: 'ville', 
            sortable: true, 
            colSpan: 1
        },
        {
            display_name: 'Contact', 
            field: 'contact', 
            sortable: false, 
            colSpan: 1
        },
        {
            display_name: 'Téléphone', 
            field: 'telephone', 
            sortable: false, 
            colSpan: 2
        }
    ]

    const datas = []

    artisans.forEach((a) => {

        let result = [
            { // Société
                value: a.nom,
                url_avatar: a.url_avatar,
                url: "/artisans/" + a._id
            },
            { // Corps de métiers
                value: performMetiers(a.metiers).str,
                tooltip: performMetiers(a.metiers).tooltip
            },
            { // Localisation
                value: a.ville + " (" + a.code_postal + ")"
            },
            { // Contact
                value: performContact(a).nom,
                url_avatar: performContact(a).url_avatar,
                url: "/contacts/" + performContact(a)._id
            },
            { // Téléphone
                value: performContact(a).telephone,
                url: "tel:" + performContact(a).telephone
            },
        ]

        datas.push(result)
    })

    const handleHeadersSort = (field) => {

        onSort(field)
    }

    const handleSearch = (value) => {

        onSearch(value)
    }

    const handlePagination = (nbPerPage, page) => {

        onPagination(nbPerPage, page)
    }

    return (               
        <MainTable 
            headers={headers}
            datas={datas}
            loading={loading}
            pagination={pagination}
            filter={filter}
            onSort={handleHeadersSort}
            onPagination={handlePagination}
            onSearch={handleSearch}
        />               
    )
}

const performMetiers = (metiers) => {

    let metiers_str = ''
    let metiers_tooltip = ''

    if(metiers.length > 1) {

        metiers_str = metiers[0] + ', ...'
        
        for(var i in metiers) {

            if(parseInt(i) === metiers.length -1) {

                metiers_tooltip += metiers[i]
            }
            else {

                metiers_tooltip += metiers[i] + ', '
            }
        }
    }
    else {

        metiers_str = metiers[0] 
        metiers_tooltip = metiers[0] 
    }

    return { str: metiers_str, tooltip: metiers_tooltip }
}

const performContact = (artisan) => {

    // Gestion du contact
    let nom = ''
    let url_avatar = ''
    let telephone = ''
    let _id = ''

    if(artisan.contacts && artisan.contacts.length > 0) {

        nom = artisan.contacts[0].nom
        url_avatar = artisan.contacts[0].url_avatar
        telephone = artisan.contacts[0].telephone
        _id = artisan.contacts[0]._id
    }
    else {

        telephone = artisan.telephone
    }

    return {
        _id, nom, url_avatar, telephone
    }
}

export default Artisans