import React from 'react'

const WIPModal = () => {

  return (
    <>
      <div className="modal fade" id="WIPModal" tabindex="-1" role="dialog" aria-labelledby="WIPModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="WIPModalLabel">En cours de construction</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Cette fonctionnalité sera bientôt disponible :)
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" data-dismiss="modal">Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WIPModal