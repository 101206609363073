import React from "react";
import helper from "../uneCalculatriceHelper";
import InputForm from "../../../toolsComponents/forms/InputForm";

/**
 * initialState -> initial data to feed this component
 * infosGeneralesProjet -> data for fields dependancies
 * onChange -> to manage when a field is changed
 */
class LeCredit extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.state = this.props.initialState;
  }

  updateState() {
    this.apportTotal();
    this.totalCapitalEmprunté();
    this.mensualiteCredit();
  }

  handleChange = async (event) => {
    await this.setState({
      [event.inputName]: event.value,
    });

    this.updateState();

    if (typeof this.props.onChange === "function")
      this.props.onChange(this.state);
  };

  apportTotal = () => {
    const tmpState = {
      infosGenerales: this.props.infosGeneralesProjet,
      credit: this.state,
    };

    this.setState({
      apportTotal: helper.performApportTotal(tmpState),
    });
  };

  totalCapitalEmprunté = () => {
    const tmpState = {
      infosGenerales: this.props.infosGeneralesProjet,
      credit: this.state,
    };

    this.setState({
      capitalEmprunteTotal: helper.performTotalCapitalEmprunte(tmpState),
    });
  };

  mensualiteCredit = () => {
    const tmpState = {
      infosGenerales: this.props.infosGeneralesProjet,
      credit: this.state,
    };

    this.setState({
      mensualite: helper.performMensualite(tmpState),
    });
  };

  componentDidMount() {
    this.updateState();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.infosGeneralesProjet !== prevProps.infosGeneralesProjet ||
      this.props.initialState !== prevProps.initialState
    ) {
      await this.setState(this.props.initialState);
      this.updateState();
    }
  }

  render() {
    return (
      <>
        <div className="col-12">
          <div className="row">
            <div className="col-8">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Crédit</h4>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <InputForm
                        label={"Apport personnel"}
                        type="number"
                        inputName="apportPersonnel"
                        value={this.state.apportPersonnel}
                        onChange={this.handleChange}
                        placeholder="ex: 25000"
                        prepend={"€"}
                      />
                    </div>

                    <div className="col-6"></div>

                    <div className="col-6">
                      <InputForm
                        label={"Frais de garantie"}
                        type="number"
                        inputName="fraisGarantie"
                        value={this.state.fraisGarantie}
                        onChange={this.handleChange}
                        placeholder="ex: 500"
                        prepend={"€"}
                      />
                    </div>

                    <div className="col-6"></div>

                    <div className="col-6">
                      <InputForm
                        label="Frais de dossier"
                        type="number"
                        inputName="fraisDossier"
                        value={this.state.fraisDossier}
                        onChange={this.handleChange}
                        placeholder="ex: 750"
                        prepend={"€"}
                      />
                    </div>

                    <div className="col-6"></div>

                    <div className="col-3">
                      <InputForm
                        label={"Durée de différé"}
                        type="number"
                        inputName="dureeDiffere"
                        value={this.state.dureeDiffere}
                        onChange={this.handleChange}
                        placeholder="ex: 18"
                        prepend={"mois"}
                      />
                    </div>

                    <div className="col-3">
                      <InputForm
                        label={"Durée totale du crédit"}
                        type="number"
                        inputName="dureeTotaleCredit"
                        value={this.state.dureeTotaleCredit}
                        onChange={this.handleChange}
                        placeholder="ex: 240"
                        prepend={"mois"}
                      />
                    </div>

                    <div className="col-3">
                      <InputForm
                        label={"Taux hors assurance"}
                        type="number"
                        inputName="tauxHorsAssurance"
                        value={this.state.tauxHorsAssurance}
                        onChange={this.handleChange}
                        placeholder="ex: 1.15"
                        prepend={"%"}
                      />
                    </div>

                    <div className="col-3">
                      <InputForm
                        label={"Taux assurance"}
                        type="number"
                        inputName="tauxAssurance"
                        value={this.state.tauxAssurance}
                        onChange={this.handleChange}
                        placeholder="ex: 0.35"
                        prepend={"%"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Coûts du crédit</h4>
                </div>

                <div className="card-body">
                  <div className="col-12">
                    <InputForm
                      label={"Apport total"}
                      type="number"
                      className="form-control"
                      inputName="coutTotalProjet"
                      value={this.state.apportTotal}
                      readOnly
                      prepend={"€"}
                      tooltip={"= coût des meubles + apport personnel"}
                    />
                  </div>

                  <div className="col-12">
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>

                  <div className="col-12">
                    <InputForm
                      label="Total capital emprunté"
                      type="number"
                      inputName="capitalEmprunteTotal"
                      value={this.state.capitalEmprunteTotal}
                      readOnly
                      prepend={"€"}
                      tooltip={
                        "= coût total du projet + frais de garantie + frais de dossier - apport personnel"
                      }
                    />
                  </div>

                  <div className="col-12">
                    <InputForm
                      label={"Mensualités"}
                      type="number"
                      inputName="mensualite"
                      value={this.state.mensualite}
                      readOnly
                      prepend={"€/mois"}
                      tooltip={
                        "calcul de crédit en fonction du capital total à emprunter, du taux d'assurance, du taux hors assurance et de la durée totale du crédit"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LeCredit;
