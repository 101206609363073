import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import "remixicon/fonts/remixicon.css";
import { CookiesProvider, useCookies } from "react-cookie";
import { setAuth } from "./features/auth/login/loginSlice";
import LoginWithStore from "./features/auth/login/LoginWithStore";
import RegisterWithStore from "./features/auth/register2/RegisterWithStore";
import LogoutWithStore from "./features/auth/login/LogoutWithStore";
import store from "./store";
import Dashboard from "./mockups/Dashboard";
import MesProjets from "./mockups/MesProjets";
import Banques from "./mockups/Banques";
import Artisans from "./mockups/Artisans";
import MesRendezVous from "./mockups/MesRendezVous";
import Tutoriels from "./mockups/Tutoriels";
import MonProfil from "./mockups/monProfil/MonProfil";
import Settings from "./mockups/Settings";
import UnProjetGeneral from "./mockups/unProjet/UnProjetGeneral";
import UnProjetEDM from "./mockups/unProjet/UnProjetEDM";
import UnProjetFinancement from "./mockups/unProjet/UnProjetFinancement";
import UnProjetTravaux from "./mockups/unProjet/UnProjetTravaux";
import UnArtisan from "./mockups/unArtisan/UnArtisan";
import UneBanque from "./mockups/uneBanque/UneBanque";
import MonProfilFactures from "./mockups/monProfil/MonProfilFactures";
import MonProfilDetails from "./mockups/monProfil/MonProfilDetails";
import UneFacture from "./mockups/monProfil/UneFacture";
import NouvelArtisan from "./mockups/unArtisan/NouvelArtisan";
import NouvelleBanque from "./mockups/uneBanque/NouvelleBanque";
import UnArtisanDevis from "./mockups/unArtisan/UnArtisanDevis";
import UneBanqueOffres from "./mockups/uneBanque/UneBanqueOffres";
import UnProjetPhotos from "./mockups/unProjet/UnProjetPhotos";
import UnProjetAnnexes from "./mockups/unProjet/UnProjetAnnexes";
import UnProjetDevis from "./mockups/unProjet/UnProjetDevis";
import UnProjetPlans from "./mockups/unProjet/UnProjetPlans";
import UnProjetEmprunteurs from "./mockups/unProjet/UnProjetEmprunteurs";
import NouvelEmprunteur from "./mockups/unProjet/NouvelEmprunteur";
import UnEmprunteur from "./mockups/unProjet/UnEmprunteur";
import UnProjetSitGeo from "./mockups/unProjet/UnProjetSitGeo";
import UnProjetOffresPrets from "./mockups/unProjet/UnProjetOffresPrets";
import UneOffrePret from "./mockups/unProjet/UneOffrePret";
import NouvelleOffrePret from "./mockups/unProjet/NouvelleOffrePret";
import UnProjetPlanFinancement from "./mockups/unProjet/UnProjetPlanFinancement";
import UnProjetOrganisation from "./mockups/unProjet/UnProjetOrganisation";
import UnProjetContacts from "./mockups/unProjet/UnProjetContacts";
import NouveauContact from "./mockups/unProjet/NouveauContact";
import NouveauProjet from "./mockups/unProjet/NouveauProjet";
import UnProjetRDV from "./mockups/unProjet/UnProjetRDV";
import ArtisansWithStore from "./features/artisans/ArtisansWithStore";
import BanquesWithStore from "./features/banques/BanquesWithStore";
import UneBanqueDispatcher from "./features/uneBanque/UneBanqueDispatcher";
import Formations from "./mockups/Formations";
import FormationsWithStore from "./features/formations/FormationsWithStore";
import TutorielsWithStore from "./features/tutoriels/TutorielsWithStore";
import UnTutorielWithStore from "./features/unTutoriel/UnTutorielWithStore";
import UnProjetStatistiques from "./mockups/unProjet/UnProjetStatistiques";
import EnConstruction from "./toolsComponents/EnConstruction";
import UnArtisanDispatcher from "./features/unArtisan/UnArtisanDispatcher";
import UneFormationWithStore from "./features/uneFormation/UneFormationWithStore";
import UneOffrePretWithStore from "./features/uneOffrePret/UneOffrePretWithStore";
import ProjetsWithStore from "./features/projets/ProjetsWithStore";
import UnProjetDispatcher from "./features/unProjet/UnProjetDispatcher";
import MesEtudes from "./mockups/MesEtudes";
import UneEtude from "./mockups/uneEtude/UneEtude";
import UneEtudeRentabilite from "./mockups/uneEtude/rentabilite/UneEtudeRentabilite";
import UneEtudeAgents from "./mockups/uneEtude/agents/UneEtudeAgents";
import UneEtudeWikipedia from "./mockups/uneEtude/wikipedia/UneEtudeWikipedia";
import UneEtudeGeoportail from "./mockups/uneEtude/geoportail/UneEtudeGeoportail";
import RechercheVille from "./mockups/RechercheVille";
import RechercheVilleWithStore from "./features/rechercheVille/RechercheVilleWithStore";
import EtudesWithStore from "./features/etudes/EtudesWithStore";
import UneEtudeDispatcher from "./features/uneEtude/UneEtudeDispatcher";
import UnProfilDispatcher from "./features/unProfil/UnProfilDispatcher";
import ResetPasswordWithStore from "./features/auth/resetPassword/ResetPasswordWithStore";
import NewPasswordWithStore from "./features/auth/newPassword/NewPasswordWithStore";
import UneCalculatriceRentabiliteWithStore from "./features/uneCalculatrice/UneCalculatriceRentabiliteWithStore";
import ChromeExtention from "./pages/ChromeExtension.jsx";
import SuperHote from "./pages/SuperHote.jsx";
import UnPatrimoineDispatcher from "./features/unPatrimoine/UnPatrimoineDispatcher";
import UnBienDispatcher from "./features/unBien/UnBienDispatcher";
import UnProjetSimulationTravauxWithStore from "./features/unProjet/UnProjetSimulationTravaux/UnProjetSimulationTravauxWithStore";

function App() {
  const [cookies] = useCookies(["token", "email", "granted_modules"]);

  // This page need to be loged in to access it
  store.dispatch(
    setAuth({
      email: cookies.email,
      token: cookies.token,
      granted_modules: cookies.granted_modules,
    })
  );

  return (
    <Provider store={store}>
      <CookiesProvider>
        <Router>
          <Switch>
            {/**************************/}
            {/* ----- REAL PAGES ----- */}
            {/**************************/}
            <Route path="/login">
              <LoginWithStore />
            </Route>

            <Route path="/logout">
              <LogoutWithStore />
            </Route>

            <Route path="/register/:url">
              <RegisterWithStore />
            </Route>

            <Route path="/reset-password">
              <ResetPasswordWithStore />
            </Route>

            <Route path="/new-password/:token">
              <NewPasswordWithStore />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/dashboard">
              <>
                {/* <DashboardWithStore /> */}
                <RechercheVilleWithStore />
              </>
            </Route>

            <Route path="/chrome-extension">
              <ChromeExtention />
            </Route>

            <Route path="/superhote">
              <SuperHote />
            </Route>

            {/** PROJETS and UN PROJET */}
            {/* Need to be logged in : check condition in component */}

            <Route path="/projets/:id/simulationtravaux">
              <UnProjetSimulationTravauxWithStore />
            </Route>

            <Route path="/projets/:id/:page/:subPage">
              <UnProjetDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/projets/:id/:page">
              <UnProjetDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/projets/:id">
              <UnProjetDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/projets">
              <ProjetsWithStore />
            </Route>

            {/** ETUDES and UNE ETUDE */}

            {/* Need to be logged in : check condition in component */}
            <Route path="/etudes/:id/:page">
              <UneEtudeDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/etudes/:id">
              <UneEtudeDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/etudes">
              <EtudesWithStore />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/calculatrice-rentabilite">
              <UneCalculatriceRentabiliteWithStore />
            </Route>

            {/** ETUDES and UNE ETUDE and RECHERCHEVILLE */}
            <Route path="/recherche-ville">
              <RechercheVilleWithStore />
            </Route>

            {/** PROJETS and UN PROJET */}
            {/* Need to be logged in : check condition in component */}
            <Route path="/projets/:id/:page/:subPage">
              <UnProjetDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/projets/:id/:page">
              <UnProjetDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/projets/:id">
              <UnProjetDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/projets">
              <ProjetsWithStore />
            </Route>

            {/** ARTISANS and UN ARTISAN */}
            {/* Need to be logged in : check condition in component */}
            <Route path="/artisans/:id/:page">
              <UnArtisanDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/artisans/:id">
              <UnArtisanDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/artisans">
              <ArtisansWithStore />
            </Route>

            {/** BANQUES and UNE BANQUE */}
            {/* Need to be logged in : check condition in component */}
            <Route path="/banques/:id_banque/offres-de-pret/:id_offre_pret">
              <UneOffrePretWithStore />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/banques/:id/:page">
              <UneBanqueDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/banques/:id">
              <UneBanqueDispatcher />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/banques">
              <BanquesWithStore />
            </Route>

            {/** FORMATIONS and UNE FORMATION */}
            {/* Need to be logged in and have the right plan : check condition in component */}
            <Route path="/formations/:id">
              <UneFormationWithStore />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/formations">
              <FormationsWithStore />
            </Route>

            {/** TUTORIELS and UN TUTORIEL */}
            {/* Need to be logged in : check condition in component */}
            <Route path="/tutoriels/:id">
              <UnTutorielWithStore />
            </Route>

            {/* Need to be logged in : check condition in component */}
            <Route path="/tutoriels">
              <TutorielsWithStore />
            </Route>

            <Route path="/profil/:page">
              <UnProfilDispatcher />
            </Route>

            <Route path="/profil">
              <UnProfilDispatcher />
            </Route>

            {/**************************************/}
            {/* ----- WORK IN PROGRESS PAGES ----- */}
            {/**************************************/}
            <Route path="/mes-rendez-vous">
              <EnConstruction />
            </Route>

            <Route path="/mon-profil">
              <EnConstruction />
            </Route>

            <Route path="/settings">
              <EnConstruction />
            </Route>

            {/***********************/}
            {/* ----- MOCKUPS ----- */}
            {/***********************/}
            <Route path="/bien/:id/:page">
              <UnBienDispatcher />
            </Route>

            <Route path="/bien/:id">
              <UnBienDispatcher />
            </Route>

            <Route path="/patrimoine/:page">
              <UnPatrimoineDispatcher />
            </Route>

            <Route path="/patrimoine">
              <UnPatrimoineDispatcher />
            </Route>

            <Route path="/mockups/recherche-ville">
              <RechercheVille />
            </Route>

            <Route path="/mockups/mes-etudes/Colmar/geoportail">
              <UneEtudeGeoportail />
            </Route>

            <Route path="/mockups/mes-etudes/Colmar/wikipedia">
              <UneEtudeWikipedia />
            </Route>

            <Route path="/mockups/mes-etudes/Colmar/agents">
              <UneEtudeAgents />
            </Route>

            <Route path="/mockups/mes-etudes/Colmar/rentabilite">
              <UneEtudeRentabilite />
            </Route>

            <Route path="/mockups/mes-etudes/Colmar">
              <UneEtude />
            </Route>

            <Route path="/mockups/mes-etudes/">
              <MesEtudes />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/nouveau-contact">
              <NouveauContact />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/contacts">
              <UnProjetContacts />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/organisation">
              <UnProjetOrganisation />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/plan-financement">
              <UnProjetPlanFinancement />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/nouvelle-offre-pret">
              <NouvelleOffrePret />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/offres-prets/Credit-Mutuel">
              <UneOffrePret />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/offres-prets">
              <UnProjetOffresPrets />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/situation-geographique">
              <UnProjetSitGeo />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/statistiques">
              <UnProjetStatistiques />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/emprunteurs/Gaetan-BERTUIT">
              <UnEmprunteur />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/nouvel-emprunteur">
              <NouvelEmprunteur />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/emprunteurs">
              <UnProjetEmprunteurs />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/plans">
              <UnProjetPlans />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/devis">
              <UnProjetDevis />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/annexes">
              <UnProjetAnnexes />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/photos">
              <UnProjetPhotos />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/travaux">
              <UnProjetTravaux />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/financement">
              <UnProjetFinancement />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/etude-de-marche">
              <UnProjetEDM />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/rdv">
              <UnProjetRDV />
            </Route>

            <Route path="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres">
              <UnProjetGeneral />
            </Route>

            <Route path="/mockups/nouveau-projet">
              <NouveauProjet />
            </Route>

            <Route path="/mockups/mes-projets">
              <MesProjets />
            </Route>

            <Route path="/mockups/banques/Credit-Agricole-Du-Languedoc/offres-de-pret">
              <UneBanqueOffres />
            </Route>

            <Route path="/mockups/banques/Credit-Agricole-Du-Languedoc">
              <UneBanque />
            </Route>

            <Route path="/mockups/banques">
              <Banques />
            </Route>

            <Route path="/mockups/nouvelle-banque">
              <NouvelleBanque />
            </Route>

            <Route path="/mockups/artisans/batir-48-SARL/devis">
              <UnArtisanDevis />
            </Route>

            <Route path="/mockups/artisans/batir-48-SARL">
              <UnArtisan />
            </Route>

            <Route path="/mockups/nouvel-artisan">
              <NouvelArtisan />
            </Route>

            <Route path="/mockups/artisans">
              <Artisans />
            </Route>

            <Route path="/mockups/mes-rendez-vous">
              <MesRendezVous />
            </Route>

            <Route path="/mockups/tutoriels">
              <Tutoriels />
            </Route>

            <Route path="/mockups/formations">
              <Formations />
            </Route>

            <Route path="/mockups/mon-profil/details">
              <MonProfilDetails />
            </Route>

            <Route path="/mockups/mon-profil/factures/6520">
              <UneFacture />
            </Route>

            <Route path="/mockups/mon-profil/factures">
              <MonProfilFactures />
            </Route>

            <Route path="/mockups/mon-profil">
              <MonProfil />
            </Route>

            <Route path="/mockups/settings">
              <Settings />
            </Route>

            <Route path="/mockups">
              <Dashboard />
            </Route>

            <Route path="/">
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </Router>
      </CookiesProvider>
    </Provider>
  );
}

export default App;
