import React, { useState } from 'react'
import Navigation from '../toolsComponents/Navigation'
import SelectVille from '../toolsComponents/SelectVille'

const RechercheVille = () => {

  const [ mapsVille, setMapsVille ] = useState('')

  const handleVilleChanged = (value) => {
    setMapsVille(value)
  }

  return (
    <>
      <Navigation granted_modules="ALL" />

      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {/* <!-- HEADER --> */}
              <div className="header">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-lg avatar-4by3">
                          <img src="/assets/img/mockups/france.jpeg" alt="..." className="avatar-img rounded" />
                        </div>
                      </div>

                      <div className="col ml-n3 ml-md-n2">
                        {/* <!-- Pretitle --> */}
                        <h6 className="header-pretitle">
                          Analyse
                        </h6>

                        {/* <!-- Title --> */}
                        <h1 className="header-title">
                          Rechercher une ville idéale pour investir
                        </h1>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>

              {/* Search and toggle */}
              <div className="row mb-4">
                <div className="col">

                  <form>
                    <div className="input-group input-group-lg input-group-merge">

                      {/* <input type="text" className="form-control form-control-prepended" placeholder="Rechercher sur la carte" onChange={handleVilleChanged} value={mapsVille} /> */}

                      <SelectVille onChange={handleVilleChanged} withFormGroup={false} value={mapsVille}/>

                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fe fe-search"></span>
                        </div>
                      </div>

                    </div>
                  </form>

                </div>

                {/* <div className="col-auto">
                   <a href="/mockups/nouveau-projet" className="btn btn-primary d-block d-md-inline-block lift" style={{paddingTop: '13px', paddingBottom: '13px'}}>
                    <i className="fe fe-plus"></i> Analyser cette ville
                  </a>
                </div> */}
              </div>

              <div className="row">
                <div className="col">
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe title="Google Maps" width="100%" height="600" id="gmap_canvas" src={`https://maps.google.com/maps?q=${mapsVille}%20France&t=k&z=13&ie=UTF8&iwloc=&output=embed`} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </> 
  )
}

export default RechercheVille