import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import UnProfilGenericWithStore from '../UnProfilGenericWithStore'
import { unProfilStatusSelector } from '../unProfilSlice'
import UnProfilMotDePasse from './UnProfilMotDePasse'

const UnProfilMotDePasseWithStore = () => {

  const { errorMessage } = useSelector(unProfilStatusSelector)

  const genericComponent = useRef(null)

  return <>
    <UnProfilGenericWithStore
      ref={genericComponent}
      activeMenu="mot-de-passe"
    >
      <UnProfilMotDePasse 
        errorMessage={errorMessage}
        onSubmit={(e) => genericComponent.current.handlePasswordChange(e)} 
      />
    </UnProfilGenericWithStore>
  </>
}

export default UnProfilMotDePasseWithStore