import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import TabDevis from './TabDevis'
import UnArtisanHeader from './UnArtisanHeader'

const UnArtisanDevis = () => {

    return (
        <div>
            {/* <!-- MODAL AJOUTER UN DEVIS --> */}
            <div className="modal fade" id="exampleModalNewDevis" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ajouter un devis</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Nom du devis</label>
                                <input type="text" className="form-control" id="recipient-name" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Artisan</label>
                                <select className="form-control">
                                    <option value="0" select></option>
                                    <option value="1" >Bâtir 48 SARL</option>
                                    <option value="2">LC ARTISAN</option>
                                    <option value="3">Simon Fernand Et Fils SARL</option>
                                    <option value="4">Rodier S.A.S.</option>
                                    <option value="5">Vissac Romain</option>
                                    <option value="6">Idée-Déco</option>
                                    <option value="7">Jarrousse Anne</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Projet</label>
                                <select className="form-control">
                                    <option value="0"></option>
                                    <option value="1" selected>Chalet en pin douglas à Florac Trois Rivières</option>
                                    <option value="2">Appartement Chanac</option>
                                    <option value="3">Appartement 2 pièces à Mont Lozère et Goulet</option>
                                    <option value="4">Appartement 2 pièces de 30 m²</option>
                                    <option value="5">5 appartements au centre de Mende</option>
                                </select>
                            </div>

                            <div clas="form-group">
                                <label>Montant total</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">€</div>
                                    </div>
                                    <input type="number" className="form-control" value="0" />
                                </div>
                            </div>

                            <br />
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Fichier</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL MODIFIER UN DEVIS --> */}
            <div className="modal fade" id="exampleModalEditDevis" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifier un devis</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Nom du devis</label>
                                <input type="text" className="form-control" id="recipient-name" value="Réagencer les chambres"/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Artisan</label>
                                <select className="form-control">
                                    <option value="0"></option>
                                    <option value="1" selected>Bâtir 48 SARL</option>
                                    <option value="2">LC ARTISAN</option>
                                    <option value="3">Simon Fernand Et Fils SARL</option>
                                    <option value="4">Rodier S.A.S.</option>
                                    <option value="5">Vissac Romain</option>
                                    <option value="6">Idée-Déco</option>
                                    <option value="7">Jarrousse Anne</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Projet</label>
                                <select className="form-control">
                                    <option value="0"></option>
                                    <option value="1" selected>Chalet en pin douglas à Florac Trois Rivières</option>
                                    <option value="2">Appartement Chanac</option>
                                    <option value="3">Appartement 2 pièces à Mont Lozère et Goulet</option>
                                    <option value="4">Appartement 2 pièces de 30 m²</option>
                                    <option value="5">5 appartements au centre de Mende</option>
                                </select>
                            </div>

                            <div clas="form-group">
                                <label>Montant total</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">€</div>
                                    </div>
                                    <input type="number" className="form-control" value="25000" />
                                </div>
                            </div>

                            <br />
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Fichier</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL SUPPRIMER UN DEVIS --> */}
            <div className="modal fade" id="exampleModalDelDevis" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Supprimer un devis</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <p>Etes-vous sûr de vouloir supprimer le devis : Réagencer les chambres ?</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>


            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <UnArtisanHeader isActive="devis"/>

                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-12">

                            {/* Bouton ajouter un devis */}
                            <div className="row">
                                <div className="col"></div>

                                <div className="col-auto">
                                    <a href="#!" className="btn btn-primary d-block d-md-inline-block lift" data-toggle="modal" data-target="#exampleModalNewDevis">
                                        <i className="fe fe-plus"></i> Ajouter un devis
                                    </a>
                                </div>
                            </div>

                            <br />


                            <div className="row">

                                <TabDevis vue="artisan" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnArtisanDevis