import React from 'react'
import 'leaflet/dist/leaflet.css'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetStatistiques = () => {

        return (
            <div>
                <Navigation granted_modules="ALL" />
    
                <div className="main-content">
                    
                    <UnProjetHeader isActive="edm" isActiveSubMenu="situation-demographique" />
    
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12">

                                {/* Valeurs foncières */}
                                <div className="row">

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-header-title">Valeurs foncières des dernières ventes à Florac</h4>
                                                
                                            </div>

                                        <div className="card-body">
                                            
                                            <div className="row">
                                                <div className="col-3">
                                                    <iframe title="map" src="https://data.opendatasoft.com/explore/embed/dataset/buildingref-france-demande-de-valeurs-foncieres-geolocalisee-millesime@public/map/?disjunctive.date_mutation&disjunctive.nature_mutation&disjunctive.dep_code&disjunctive.type_local&disjunctive.code_nature_culture&disjunctive.nature_culture&disjunctive.code_nature_culture_speciale&disjunctive.nature_culture_speciale&disjunctive.dep_name&disjunctive.reg_code&disjunctive.reg_name&q=com_name%3Dflorac&location=16,44.32495,3.59377&basemap=jawg.streets&static=false&datasetcard=false&scrollWheelZoom=true" width="450" height="450" frameBorder="0"></iframe>
                                                </div>

                                                <div className="col-9">
                                                    <iframe title="graph" src="https://data.opendatasoft.com/explore/embed/dataset/buildingref-france-demande-de-valeurs-foncieres-geolocalisee-millesime@public/analyze/?disjunctive.date_mutation&disjunctive.nature_mutation&disjunctive.dep_code&disjunctive.type_local&disjunctive.code_nature_culture&disjunctive.nature_culture&disjunctive.code_nature_culture_speciale&disjunctive.nature_culture_speciale&disjunctive.dep_name&disjunctive.reg_code&disjunctive.reg_name&q=com_name%3Dflorac&location=16,44.32495,3.59377&basemap=jawg.streets&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImJ1aWxkaW5ncmVmLWZyYW5jZS1kZW1hbmRlLWRlLXZhbGV1cnMtZm9uY2llcmVzLWdlb2xvY2FsaXNlZS1taWxsZXNpbWVAcHVibGljIiwib3B0aW9ucyI6eyJkaXNqdW5jdGl2ZS5kYXRlX211dGF0aW9uIjp0cnVlLCJkaXNqdW5jdGl2ZS5uYXR1cmVfbXV0YXRpb24iOnRydWUsImRpc2p1bmN0aXZlLmRlcF9jb2RlIjp0cnVlLCJkaXNqdW5jdGl2ZS50eXBlX2xvY2FsIjp0cnVlLCJkaXNqdW5jdGl2ZS5jb2RlX25hdHVyZV9jdWx0dXJlIjp0cnVlLCJkaXNqdW5jdGl2ZS5uYXR1cmVfY3VsdHVyZSI6dHJ1ZSwiZGlzanVuY3RpdmUuY29kZV9uYXR1cmVfY3VsdHVyZV9zcGVjaWFsZSI6dHJ1ZSwiZGlzanVuY3RpdmUubmF0dXJlX2N1bHR1cmVfc3BlY2lhbGUiOnRydWUsImRpc2p1bmN0aXZlLmRlcF9uYW1lIjp0cnVlLCJkaXNqdW5jdGl2ZS5yZWdfY29kZSI6dHJ1ZSwiZGlzanVuY3RpdmUucmVnX25hbWUiOnRydWUsInEiOiJjb21fbmFtZT1mbG9yYWMifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6ImxpbmUiLCJmdW5jIjoiQVZHIiwieUF4aXMiOiJ2YWxldXJfZm9uY2llcmUiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjMTQyRTdCIn1dLCJ4QXhpcyI6ImRhdGVfbXV0YXRpb24iLCJtYXhwb2ludHMiOiIiLCJ0aW1lc2NhbGUiOiJ5ZWFyIiwic29ydCI6IiJ9XSwiZGlzcGxheUxlZ2VuZCI6dHJ1ZSwiYWxpZ25Nb250aCI6dHJ1ZX0%3D&static=false&datasetcard=false" width="980" height="450" frameBorder="0"></iframe>
                                                </div>
                                            </div>
                                            

                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Entreprises */}
                            <div className="row">

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">Entreprises situées à Florac</h4>
                                        
                                    </div>

                                <div className="card-body">
                                    
                                    <div className="row">
                                        <div className="col-3">
                                            <iframe title="map" src="https://public.opendatasoft.com/explore/embed/dataset/economicref-france-sirene-v3/map/?disjunctive.libellecommuneetablissement&disjunctive.etatadministratifetablissement&disjunctive.naturejuridiqueunitelegale&q=libellecommuneetablissement%3Dflorac&location=15,44.32425,3.59682&basemap=jawg.streets&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImVjb25vbWljcmVmLWZyYW5jZS1zaXJlbmUtdjMiLCJvcHRpb25zIjp7ImRpc2p1bmN0aXZlLmxpYmVsbGVjb21tdW5lZXRhYmxpc3NlbWVudCI6dHJ1ZSwiZGlzanVuY3RpdmUuZXRhdGFkbWluaXN0cmF0aWZldGFibGlzc2VtZW50Ijp0cnVlLCJkaXNqdW5jdGl2ZS5uYXR1cmVqdXJpZGlxdWV1bml0ZWxlZ2FsZSI6dHJ1ZSwicSI6ImxpYmVsbGVjb21tdW5lZXRhYmxpc3NlbWVudD1mbG9yYWMiLCJsb2NhdGlvbiI6IjIyLDQ0LjUxNzc3LDMuNDk5MjkiLCJiYXNlbWFwIjoiamF3Zy5zdHJlZXRzIn19LCJjaGFydHMiOlt7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJjb2x1bW4iLCJmdW5jIjoiQ09VTlQiLCJ5QXhpcyI6InRyYW5jaGVlZmZlY3RpZnNldGFibGlzc2VtZW50dHJpYWJsZSIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6IiNGRjUxNUEifV0sInhBeGlzIjoiY2F0ZWdvcmllZW50cmVwcmlzZSIsIm1heHBvaW50cyI6IiIsInRpbWVzY2FsZSI6IiIsInNvcnQiOiIifV0sImRpc3BsYXlMZWdlbmQiOnRydWUsImFsaWduTW9udGgiOnRydWUsInRpbWVzY2FsZSI6IiJ9&static=false&datasetcard=false&scrollWheelZoom=false" width="450" height="450" frameBorder="0"></iframe>
                                        </div>

                                        <div className="col-9">
                                            <iframe title="bar" src="https://public.opendatasoft.com/explore/embed/dataset/economicref-france-sirene-v3/analyze/?disjunctive.libellecommuneetablissement&disjunctive.etatadministratifetablissement&disjunctive.naturejuridiqueunitelegale&q=libellecommuneetablissement%3Dflorac&location=22,44.51777,3.49929&basemap=jawg.streets&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImVjb25vbWljcmVmLWZyYW5jZS1zaXJlbmUtdjMiLCJvcHRpb25zIjp7ImRpc2p1bmN0aXZlLmxpYmVsbGVjb21tdW5lZXRhYmxpc3NlbWVudCI6dHJ1ZSwiZGlzanVuY3RpdmUuZXRhdGFkbWluaXN0cmF0aWZldGFibGlzc2VtZW50Ijp0cnVlLCJkaXNqdW5jdGl2ZS5uYXR1cmVqdXJpZGlxdWV1bml0ZWxlZ2FsZSI6dHJ1ZSwicSI6ImxpYmVsbGVjb21tdW5lZXRhYmxpc3NlbWVudD1mbG9yYWMiLCJsb2NhdGlvbiI6IjIyLDQ0LjUxNzc3LDMuNDk5MjkiLCJiYXNlbWFwIjoiamF3Zy5zdHJlZXRzIn19LCJjaGFydHMiOlt7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJjb2x1bW4iLCJmdW5jIjoiQ09VTlQiLCJ5QXhpcyI6InRyYW5jaGVlZmZlY3RpZnNldGFibGlzc2VtZW50dHJpYWJsZSIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6IiNGRjUxNUEifV0sInhBeGlzIjoiY2F0ZWdvcmllZW50cmVwcmlzZSIsIm1heHBvaW50cyI6IiIsInRpbWVzY2FsZSI6IiIsInNvcnQiOiIifV0sImRpc3BsYXlMZWdlbmQiOnRydWUsImFsaWduTW9udGgiOnRydWUsInRpbWVzY2FsZSI6IiJ9&static=false&datasetcard=false" width="980" height="450" frameBorder="0"></iframe>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                            </div>

                            </div>


                            {/* Population */}
                            <div className="row">

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">Informations sur la population à Florac</h4>
                                        
                                    </div>

                                <div className="card-body">
                                    
                                    <div className="row">
                                        <div className="col-3">
                                            <iframe title="map" src="https://public.opendatasoft.com/explore/embed/dataset/demographyref-france-donnees-carroyees-200m-millesime/map/?disjunctive.com_arm_code&disjunctive.year&disjunctive.com_code&disjunctive.com_arm_name&disjunctive.com_name&disjunctive.epci_name&disjunctive.epci_code&disjunctive.dep_name&disjunctive.dep_code&disjunctive.reg_name&disjunctive.reg_code&q=com_name%3Dflorac&location=13,44.31875,3.62429&basemap=jawg.streets&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImRlbW9ncmFwaHlyZWYtZnJhbmNlLWRvbm5lZXMtY2Fycm95ZWVzLTIwMG0tbWlsbGVzaW1lIiwib3B0aW9ucyI6eyJkaXNqdW5jdGl2ZS5jb21fYXJtX2NvZGUiOnRydWUsImRpc2p1bmN0aXZlLnllYXIiOnRydWUsImRpc2p1bmN0aXZlLmNvbV9jb2RlIjp0cnVlLCJkaXNqdW5jdGl2ZS5jb21fYXJtX25hbWUiOnRydWUsImRpc2p1bmN0aXZlLmNvbV9uYW1lIjp0cnVlLCJkaXNqdW5jdGl2ZS5lcGNpX25hbWUiOnRydWUsImRpc2p1bmN0aXZlLmVwY2lfY29kZSI6dHJ1ZSwiZGlzanVuY3RpdmUuZGVwX25hbWUiOnRydWUsImRpc2p1bmN0aXZlLmRlcF9jb2RlIjp0cnVlLCJkaXNqdW5jdGl2ZS5yZWdfbmFtZSI6dHJ1ZSwiZGlzanVuY3RpdmUucmVnX2NvZGUiOnRydWUsInEiOiJjb21fbmFtZT1mbG9yYWMifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwbGluZSIsImZ1bmMiOiJNQVgiLCJ5QXhpcyI6InBvcF9jYXJyIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoiI0ZGNTE1QSJ9XSwieEF4aXMiOiJjb21fbmFtZSIsIm1heHBvaW50cyI6NTAsInNvcnQiOiIifV0sInRpbWVzY2FsZSI6IiIsImRpc3BsYXlMZWdlbmQiOnRydWUsImFsaWduTW9udGgiOnRydWV9&static=false&datasetcard=false&scrollWheelZoom=false" width="450" height="450" frameBorder="0"></iframe>
                                        </div>

                                        <div className="col-9">
                                            <iframe title="graph" src="https://public.opendatasoft.com/explore/embed/dataset/demographyref-france-pop-legale-commune-arrondissement-municipal-millesime/analyze/?disjunctive.reg_code&disjunctive.reg_name&disjunctive.dep_code&disjunctive.arrdep_code&disjunctive.com_arm_code&disjunctive.com_arm_name&disjunctive.epci_name&disjunctive.epci_code&disjunctive.dep_name&q=com_arm_name%3Dflorac&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImRlbW9ncmFwaHlyZWYtZnJhbmNlLXBvcC1sZWdhbGUtY29tbXVuZS1hcnJvbmRpc3NlbWVudC1tdW5pY2lwYWwtbWlsbGVzaW1lIiwib3B0aW9ucyI6eyJkaXNqdW5jdGl2ZS5yZWdfY29kZSI6dHJ1ZSwiZGlzanVuY3RpdmUucmVnX25hbWUiOnRydWUsImRpc2p1bmN0aXZlLmRlcF9jb2RlIjp0cnVlLCJkaXNqdW5jdGl2ZS5hcnJkZXBfY29kZSI6dHJ1ZSwiZGlzanVuY3RpdmUuY29tX2FybV9jb2RlIjp0cnVlLCJkaXNqdW5jdGl2ZS5jb21fYXJtX25hbWUiOnRydWUsImRpc2p1bmN0aXZlLmVwY2lfbmFtZSI6dHJ1ZSwiZGlzanVuY3RpdmUuZXBjaV9jb2RlIjp0cnVlLCJkaXNqdW5jdGl2ZS5kZXBfbmFtZSI6dHJ1ZSwicSI6ImNvbV9hcm1fbmFtZT1mbG9yYWMifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6ImxpbmUiLCJmdW5jIjoiTUFYIiwieUF4aXMiOiJjb21fYXJtX3BvcF9tdW4iLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjRkY1MTVBIn1dLCJ4QXhpcyI6Imdlb195ZWFyIiwibWF4cG9pbnRzIjpudWxsLCJ0aW1lc2NhbGUiOiJ5ZWFyIiwic29ydCI6IiJ9XSwiZGlzcGxheUxlZ2VuZCI6dHJ1ZSwiYWxpZ25Nb250aCI6dHJ1ZX0%3D&static=false&datasetcard=false" width="980" height="450" frameBorder="0"></iframe>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                            </div>

                            </div>

                            {/* Logements tourisques */}
                            <div className="row">

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">Logements touristiques à Florac</h4>
                                        
                                    </div>

                                <div className="card-body">
                                    
                                    <div className="row">
                                        <div className="col-3">
                                            <iframe title="map" src="https://public.opendatasoft.com/explore/embed/dataset/hebergements-classes/map/?refine.categorie=Tourisme&refine.nom_dep=LOZERE&location=13,44.31875,3.62429&basemap=jawg.streets&dataChart=eyJxdWVyaWVzIjpbeyJjaGFydHMiOlt7InR5cGUiOiJsaW5lIiwiZnVuYyI6IkNPVU5UIiwieUF4aXMiOiJub21icmVfZF9lbXBsYWNlbWVudHMiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjRkY1MTVBIn1dLCJ4QXhpcyI6InR5cG9sb2dpZV9ldGFibGlzc2VtZW50IiwibWF4cG9pbnRzIjoiIiwidGltZXNjYWxlIjoiIiwic29ydCI6IiIsInNlcmllc0JyZWFrZG93biI6IiIsImNvbmZpZyI6eyJkYXRhc2V0IjoiaGViZXJnZW1lbnRzLWNsYXNzZXMiLCJvcHRpb25zIjp7InEiOiJjb21tdW5lPWZsb3JhYyIsInJlZmluZS5jYXRlZ29yaWUiOiJUb3VyaXNtZSJ9fSwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlLCJ0aW1lc2NhbGUiOiIifQ%3D%3D&static=false&datasetcard=false&scrollWheelZoom=false" width="450" height="450" frameBorder="0"></iframe>
                                        </div>

                                        <div className="col-9">
                                        <iframe title="graph" src="https://public.opendatasoft.com/explore/embed/dataset/hebergements-classes/analyze/?refine.categorie=Tourisme&refine.nom_dep=LOZERE&q=commune%3Dflorac&location=20,44.32303,3.59093&basemap=jawg.streets&dataChart=eyJxdWVyaWVzIjpbeyJjaGFydHMiOlt7InR5cGUiOiJsaW5lIiwiZnVuYyI6IkNPVU5UIiwieUF4aXMiOiJub21icmVfZF9lbXBsYWNlbWVudHMiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjRkY1MTVBIn1dLCJ4QXhpcyI6InR5cG9sb2dpZV9ldGFibGlzc2VtZW50IiwibWF4cG9pbnRzIjoiIiwidGltZXNjYWxlIjoiIiwic29ydCI6IiIsInNlcmllc0JyZWFrZG93biI6IiIsImNvbmZpZyI6eyJkYXRhc2V0IjoiaGViZXJnZW1lbnRzLWNsYXNzZXMiLCJvcHRpb25zIjp7InEiOiJjb21tdW5lPWZsb3JhYyIsInJlZmluZS5jYXRlZ29yaWUiOiJUb3VyaXNtZSIsInJlZmluZS5ub21fZGVwIjoiTE9aRVJFIn19LCJzZXJpZXNCcmVha2Rvd25UaW1lc2NhbGUiOiIifV0sImRpc3BsYXlMZWdlbmQiOnRydWUsImFsaWduTW9udGgiOnRydWUsInRpbWVzY2FsZSI6IiJ9&static=false&datasetcard=false" width="980" height="450" frameBorder="0"></iframe>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                            </div>

                            </div>

                                <br />
    
                            </div>
                        </div>
                    </div>
    
                </div>
            </div> 
        )
}

export default UnProjetStatistiques