import React, { useEffect, useState } from 'react'
import Image from '../images/Image'

const PhotosCarousel = ({ photos, startId }) => {

  const [ currentId, setCurrentId ] = useState(0)

  useEffect(() => {
    setCurrentId(startId)
  }, [startId])

  const handleNext = () => {
    if(currentId + 1 >= photos.length)
      setCurrentId(0)
    else
      setCurrentId(currentId+1)
  }

  const handlePrec = () => {
    if(currentId - 1 < 0)
      setCurrentId(photos.length -1)
    else
      setCurrentId(currentId-1)
  }

  return (
    <>
      {photos.map(p => {
        if(p._id === photos[currentId]._id) {
          return (
            <>
              <Image className="d-block w-100" src={p.url} alt={p.nom} />
              <p style={{textAlign:'center'}} className="text-muted">{p.nom}</p>
            </>
          )
        }
        else {
          return <></>
        }
      })}

      <div className="row">
        <div className="col-2">
          <a className="btn btn-primary" href="#!" onClick={handlePrec}><i className="fe fe-arrow-left ml-1"></i> Prec.</a>
        </div>

        <div className="col-8">
          
        </div>

        <div className="col-2" style={{textAlign:'right'}}>
          <a className="btn btn-primary" href="#!" onClick={handleNext}>Suiv. <i className="fe fe-arrow-right ml-1"></i></a>
        </div>
      </div>
      
    </>
  )
}

export default PhotosCarousel