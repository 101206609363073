import { createSlice } from "@reduxjs/toolkit";
import arraySliceHelper from "../../toolsSlice/arraySliceHelper";
import objectSliceHelper from "../../toolsSlice/objectSliceHelper";

const helper = new arraySliceHelper(
  ["titre", "category", "subCategory"],
  "titre",
  "10",
  { asc: true }
);

const objectHelper = new objectSliceHelper();

const URL_DOCUMENT = "/biens-documents/";

const unBienDocumentsSlice = createSlice({
  name: "unBienDocuments",
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    unBienDocumentsInit: (state) => {
      helper.performInit(state);
    },

    // payload : array of documents
    unBienDocumentsSuccess: (state, { payload }) => {
      helper.performSuccess(state, payload);
    },

    // payload : errorMessage
    unBienDocumentsFailure: (state, { payload }) => {
      helper.performFailure(state, payload);
    },

    // payload : { value to search on everyfields, fields concerned }
    filterByText: (state, { payload }) => {
      helper.performChangeFilter(state, payload);
    },

    // payload : field
    sort: (state, { payload }) => {
      helper.performChangeSort(state, payload);
    },

    // payload : { nbPerPage, currentPage }
    changePagination: (state, { payload }) => {
      helper.performChangePagination(state, payload);
    },

    performSelect: (state, { payload }) => {
      const { action, value } = payload;
      helper.performSelect(state, action, value);
    },
  },
});

export const performGetUnBienDocuments = (token, id_bien) => {
  return async (dispatch) => {
    dispatch(unBienDocumentsInit("GET_DOCUMENTS"));

    try {
      const reqOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(),
      };

      const res = await fetch(
        process.env.REACT_APP_REST_API_LOCATION + URL_DOCUMENT + id_bien,
        reqOptions
      );
      const json = await res.json();

      if (res.status === 200 && !json.error) {
        const bienDocuments = json.datas;
        dispatch(unBienDocumentsSuccess(bienDocuments));
      } else {
        dispatch(unBienDocumentsFailure(json.message));
      }
    } catch (e) {
      dispatch(unBienDocumentsFailure(e.toString()));
    }
  };
};

export const performAddDocument = (token, documentFormData) => {
  return async (dispatch) => {
    try {
      dispatch(unBienDocumentsInit("ADD_DOCUMENT"));

      const res = await objectHelper.performFormDataAPICall(
        token,
        documentFormData,
        URL_DOCUMENT
      );
      const json = await res.json();

      if (res.status === 200 && !json.error)
        dispatch(unBienDocumentsSuccess(json.datas.documents));
      else dispatch(unBienDocumentsFailure(json.message));
    } catch (e) {
      dispatch(unBienDocumentsFailure(e.toString()));
    }
  };
};

export const performEditDocument = (token, document) => {
  return async (dispatch) => {
    try {
      dispatch(unBienDocumentsInit("SYNC_DOCUMENT"));

      const res = await objectHelper.performSyncAPICall(
        token,
        document,
        URL_DOCUMENT
      );
      const json = await res.json();

      if (res.status === 200 && !json.error)
        dispatch(unBienDocumentsSuccess(json.datas.documents));
      else dispatch(unBienDocumentsFailure(json.message));
    } catch (e) {
      dispatch(unBienDocumentsFailure(e.toString()));
    }
  };
};

export const performDelDocument = (token, document) => {
  return async (dispatch) => {
    try {
      dispatch(unBienDocumentsInit("DEL_DOCUMENT"));

      const res = await objectHelper.performDelAPICall(
        token,
        document,
        URL_DOCUMENT
      );
      const json = await res.json();

      if (res.status === 200 && !json.error)
        dispatch(unBienDocumentsSuccess(json.datas.documents));
      else dispatch(unBienDocumentsFailure(json.message));
    } catch (e) {
      dispatch(unBienDocumentsFailure(e.toString()));
    }
  };
};

// Export action creators
const { unBienDocumentsInit, unBienDocumentsSuccess, unBienDocumentsFailure } =
  unBienDocumentsSlice.actions;
export const {
  resetBienDocumentsInitialState,
  sort,
  filterByText,
  changePagination,
  performSelect,
} = unBienDocumentsSlice.actions;

// Export reducer
export default unBienDocumentsSlice.reducer;

// Selectors
export const unBienDocumentsStatusSelector = (state) =>
  state.unBienDocuments.status;
export const unBienDocumentsFilterSelector = (state) =>
  state.unBienDocuments.filter;
export const unBienDocumentsDatasSelector = (state) =>
  state.unBienDocuments.datas;
export const unBienDocumentsFilteredDatasSelector = (state) =>
  state.unBienDocuments.filteredDatas;
export const unBienDocumentsPaginatedDatasSelector = (state) =>
  state.unBienDocuments.paginatedDatas;
export const unBienDocumentsPaginationSelector = (state) =>
  state.unBienDocuments.pagination;
export const unBienDocumentsSelectedDatasSelector = (state) =>
  state.unBienDocuments.selectedDatas;
