export const getNewArray = ({ value, max, array, initialObject }) => {
  let nb = parseInt(value);

  if (nb < 0) nb = 0;
  else if (nb > max) nb = max;

  if (nb > array.length) {
    const newArray = [];
    for (let i = 0; i < nb - array.length; i++) {
      newArray.push(initialObject);
    }

    return array.concat(newArray);
  } else {
    return array.slice(0, nb);
  }
};

export const getChangedState = ({ payload, rootObject }) => {
  const { inputName, value } = payload;
  let newState = { ...rootObject };

  if (inputName.includes(".")) {
    const parts = inputName.split(".");
    newState[parts[0]][parts[1]] = value;
  } else {
    newState[inputName] = value;
  }
  return newState;
};
