import React, { useState } from 'react'

const PasswordInputForm = ({label, withMotDePasseOublie, value, inputName, placeholder, onChange}) => {

  const [ passwordType, setPasswordType ] = useState('password')

  const handlePasswordTypeChange = () => {
    if( passwordType === 'password' ) setPasswordType('text')
    else setPasswordType('password')
  }

  const handleChange = (e) => {
    if( typeof onChange === 'function' ) onChange({value: e.target.value, inputName})
  }

  return (
    <>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <label>{label}</label>
          </div>

          {withMotDePasseOublie &&
            <div className="col-auto">
              <a href="/reset-password" className="form-text small text-muted" tabIndex="4">
                Mot de passe oublié ?
              </a>
            </div>
          }
        </div>

        <div className="input-group input-group-merge">
          <input
            type={passwordType}
            className="form-control form-control-appended" 
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            tabIndex="2"
            name={inputName}
          />

          <div className="input-group-append" onClick={handlePasswordTypeChange}>
            <span className="input-group-text">
              <i className="fe fe-eye"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordInputForm