import React from 'react'
import SearchBarTool from './searchBarTool/SearchBarTool.jsx'

const SelectVille = ({ withFormGroup = true, value, onChange }) => {

  const handleVilleChanged = (e) => {
    onChange(e)
  }

  const getSearchBarTool = () => {
    return <SearchBarTool 
      searchFunc={handleVilleChanged} 
      defaultValue={value} 
      defaultSuggestArray={[]}
      searchText="Rechercher une ville"
    />
  }

  return (
    <>
      {withFormGroup &&
        <div className="form-group">
          <label htmlFor="exampleFormControlSelect1">
            Ville
          </label>
          {getSearchBarTool()}
        </div>
      }

      {!withFormGroup &&
        getSearchBarTool()
      }
    </>
  )
}

export default SelectVille