import React from 'react'

const UneEtudeHeader = ({ isActive }) => {

  return (
    <div>
      {/* <!-- HEADER --> */}
      <div className="header">
        <div className="container-fluid">
          {/* <!-- Body --> */}
          <div className="header-body">
            <div className="row align-items-center">
              <div className="col-auto">
                {/* <!-- Avatar --> */}
                <div className="avatar avatar-lg avatar-4by3">
                  <img src="/assets/img/mockups/colmar-avatar.png" alt="..." className="avatar-img rounded" />
                </div>
              </div>

              <div className="col ml-n3 ml-md-n2">
                {/* <!-- Pretitle --> */}
                <h6 className="header-pretitle">
                  Détails
                </h6>

                {/* <!-- Title --> */}
                <h1 className="header-title">
                  Colmar
                </h1>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col">
                {/* <!-- Nav --> */}
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <a href="/mockups/mes-etudes/Colmar" className={ isActive === 'general' ? 'nav-link active' : 'nav-link' } >
                      Général
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="/mockups/mes-etudes/Colmar/agents" className={ isActive === 'agents' ? 'nav-link active' : 'nav-link' }>
                      Meilleurs agents
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="/mockups/mes-etudes/Colmar/wikipedia" className={ isActive === 'wikipedia' ? 'nav-link active' : 'nav-link' }>
                      Wikipedia
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="/mockups/mes-etudes/Colmar/geoportail" className={ isActive === 'geoportail' ? 'nav-link active' : 'nav-link' }>
                      GeoPortail
                    </a>
                  </li>

                  {/* <li className="nav-item">
                    <a href="/mockups/mes-etudes/Colmar/rentabilite" className={ isActive === 'rentabilite' ? 'nav-link active' : 'nav-link' }>
                      Rentabilité
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UneEtudeHeader