import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import SelectPays from '../../toolsComponents/SelectPays'

const NouveauProjet = () => {

    return (
        <div>

            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        
                        <div className="col-12 col-lg-10 col-xl-8">
                            {/* <!-- HEADER --> */}
                            <div className="header mt-md-5">
                            <div className="header-body">
                                <div className="row align-items-center">
                                <div className="col">

                                    {/* <!-- Pretitle --> */}
                                    <h6 className="header-pretitle">
                                    Nouveau
                                    </h6>

                                    {/* <!-- Title --> */}
                                    <h1 className="header-title">
                                    Ajouter un projet immobilier
                                    </h1>

                                </div>
                                </div> {/* <!-- / .row --> */}
                            </div>
                            </div>
                        


                            <div className="row">

                                {/* <!-- FORMULAIRE --> */}
                                <div className="col-12">

                                    <form className="mb-4">

                                        {/* <!-- Titre du bien --> */}
                                        <div className="form-group">

                                            <label>
                                                Titre
                                            </label>

                                            <input type="text" className="form-control" value="" />

                                        </div>

                                        {/* Lien de l'annonce */}
                                        <div className="form-group">

                                            <label>
                                                Lien de l'annonce
                                            </label>

                                            <input type="text" className="form-control" value="" />

                                        </div>

                                        <hr />

                                        {/* Prix de départ et prix objectif */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix de départ</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix objectif</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Prix négocié */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix négocié</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <hr />

                                        {/* Surfaces du terrain et de l'intérieur */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Surface du terrain</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">m²</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Surface intérieure</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">m²</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <hr />

                                        {/* Description */}
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1">Description du bien</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" 
                                            >
                                                
                                            </textarea>
                                        </div>

                                        {/* Objectifs */}
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1">Objectif du projet</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                        </div>

                                        <hr />

                                        {/* <!-- Adresse du bien --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Adresse
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control" value=""/>

                                        </div>

                                        {/* <!-- Ville et code postal --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Code postal
                                                    </label>

                                                    <input type="text" className="form-control" value=""/>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Ville
                                                    </label>

                                                    <input type="text" className="form-control" value=""/>

                                                </div>

                                            </div>
                                            </div> {/* <!-- / .row --> */}

                                        {/* Pays */}
                                        <SelectPays />

                                        <div className="form-group">
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                                            </div>
                                        </div>
                                        

                                    </form>

                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NouveauProjet