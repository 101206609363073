import React from 'react'
import { useParams } from 'react-router'
import UnPatrimoineGeneralWithStore from './General/UnPatrimoineGeneralWithStore'

const UnPatrimoineDispatcher = () => {
  const { page } = useParams()

  switch(page) {
    case undefined: 
      return <UnPatrimoineGeneralWithStore />
    
    default:
      return <UnPatrimoineGeneralWithStore />
  }
}

export default UnPatrimoineDispatcher