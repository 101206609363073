import { createSlice } from '@reduxjs/toolkit'
import arraySliceHelper from '../../toolsSlice/arraySliceHelper'

const helper = new arraySliceHelper(['title', 'categories'], 'sub_module', "10")

const formationsSlice = createSlice({

  name: 'formations',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    resetInitialState: (state) => {
      helper.resetInitialState(state)
    },

    // no payload
    getFormationsInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of formations
    getFormationsSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getFormationsFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    },

    // payload : { value to search on everyfields, fields concerned }
    filterByText: (state, { payload }) => {
      helper.performChangeFilter(state, payload)
    },

    // payload : field
    sort: (state, { payload }) => {
      helper.performChangeSort(state, payload)
    },

    // payload : { nbPerPage, currentPage }
    changePagination: (state, { payload }) => {
      helper.performChangePagination(state, payload)
    }
  }
})

export const performGetFormations = (token) => {

  return async dispatch => {
    dispatch(getFormationsInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/videos_by_type/formation', reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const formations = json.datas
        dispatch(getFormationsSuccess(formations))
      }
      else {
        dispatch(getFormationsFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getFormationsFailure(e.toString()))
    }
  }
}

// Export action creators
const { getFormationsInit, getFormationsSuccess, getFormationsFailure } = formationsSlice.actions
export const { sort, filterByText, changePagination, resetInitialState } = formationsSlice.actions

// Export reducer
export default formationsSlice.reducer

// Selectors
export const formationsStatusSelector = (state) => state.formations.status
export const formationsFilterSelector = (state) => state.formations.filter
export const formationsDatasSelector = (state) => state.formations.datas
export const formationsFilteredDatasSelector = (state) => state.formations.filteredDatas
export const formationsPaginatedDatasSelector = (state) => state.formations.paginatedDatas
export const formationsPaginationSelector = (state) => state.formations.pagination