import React from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import RessetPassword from './ResetPassword'
import { performResetPassword, resetPasswordtatusSelector } from './resetPasswordSlice'
import SimpleLoading from '../../../toolsComponents/SimpleLoading'
import SimpleError from '../../../toolsComponents/SimpleError'
import SimpleSuccess from '../../../toolsComponents/SimpleSucccess'

const ResetPasswordWithStore = () => {

  const [ cookies ] = useCookies(['token', 'email', 'granted_modules'])

  const dispatch = useDispatch()
  const { hasError, errorMessage, loading, lastAction } = useSelector(resetPasswordtatusSelector)

  const handleSubmit = (email) => {
    dispatch(performResetPassword(email))
  }

  return (
    <>
      <div className="row justify-content-center" style={{paddingTop: "64px"}} >
        <div className="col-12 col-md-5 col-xl-3 my-5">
            {/* Login form display */}
            {!cookies.token && !loading && lastAction !== 'RESET_PASSWORD' &&
              <RessetPassword 
                onSubmit={handleSubmit}
              />
            }

            {/* Loading display */}
            {!loading && !hasError && lastAction === 'RESET_PASSWORD' &&
              <SimpleSuccess>
                Vous allez recevoir un email d'ici quelques minutes avec un lien pour réinitialiser votre mot de passe.
              </SimpleSuccess>
            }

            {/* Loading display */}
            {loading &&
              <SimpleLoading />
            }

            {/* Error display */}
            {hasError &&
              <SimpleError withHeader={false}>
                {errorMessage}
              </SimpleError>
            }

            {/* Dashboard display */}
            {cookies.token &&
              <Redirect to="/dashboard" />
            }
        </div>
      </div>
    </>
  )
}

export default ResetPasswordWithStore