const parseNumber = (v) => {
  if( v === undefined ) return 0
  if( v === null ) return 0
  if( isNaN(v) ) return 0
  if( v === 'NaN' ) return 0
  if( !isFinite(v)) return 0
  if( v === 'Infinity' ) return 0

  return Number(v)
}

const uneCalculatriceHelper = {

  sample: {
    infosGenerales: {
      "surface": 10,
      "fraisNotaires": 200,
      "coutTravaux": 1500,
      "coutTotalProjet": 3000,
      "coutTotalm2": 220,
      "coutTotalReno": 2200,
      "typedetravaux": "150",
      "coutAchat": 500,
      "fraisNotairesInputed": true,
      "coutMeubles": 800
    },
    credit: {
      "montantAFinance": 2000,
      "coutFraisGarantie": 15,
      "capitalEmprunteTotal": 2215,
      "mensualite": 11,
      "apportPersonnel": 200,
      "fraisDossier": 200,
      "fraisGarantie": 0.5,
      "dureeDiffere": 18,
      "dureeTotaleCredit": 240,
      "tauxHorsAssurance": 1.15,
      "tauxAssurance": 0.35
    },
    revenus: {
      "nombreLogements": 2,
      "nombreRotations": null,
      "logementsData": [
        {
          "numerologementdansArray": 1,
          "typelogement": "t1",
          "loyerNueHcEstime": 100,
          "loyerMeubleHcEstime": 300,
          "vacanceLocative": 3,
          "prixNuitLCD": 40,
          "fraisSupplementaires": 5,
          "nombreNuitsMoyen": 20,
          "nombreRotationsMois": 2,
          "revenusTotauxLDNue": 75,
          "revenusTotauxLDMeuble": 225,
          "revenusTotauxLCD": 810
        },
        {
          "numerologementdansArray": 2,
          "typelogement": "t1",
          "loyerNueHcEstime": 200,
          "loyerMeubleHcEstime": 400,
          "vacanceLocative": 3,
          "prixNuitLCD": 10,
          "fraisSupplementaires": 30,
          "nombreNuitsMoyen": 10,
          "nombreRotationsMois": 1,
          "revenusTotauxLDNue": 150,
          "revenusTotauxLDMeuble": 300,
          "revenusTotauxLCD": 130
        }
      ],
      "revenusTotalTousLogementsLDNue": 225,
      "revenusTotalTousLogementsLDMeuble": 525,
      "revenusTotalTousLogementsLCD": 940,
      "nombreRotationsMois": 3
    },
    charges: {
      "fraisPlateformesMensuels": 141,
      "fraisPlateformesAnnuels": 1692,
      "taxeFonciere": 1200,
      "totalChargesMensuellesLDNue": 438.58,
      "totalChargesMensuellesLDMeuble": 465.58,
      "totalChargesMensuellesLCD": 1149.33,
      "totalChargesAnnuellesLDNue": 5262.96,
      "totalChargesAnnuellesLDMeuble": 5586.96,
      "totalChargesAnnuellesLCD": 13791.96,
      "chargesCopro": 1200,
      "assurancePNO": 5,
      "assuranceLoyersImpayes": 2.5,
      "previsionTravaux": 1200,
      "expertComptable": 100,
      "fraisGerance": 6.5,
      "fraisDivers": 100,
      "tauxConciergerie": 20,
      "tauxCommissionPlateforme": 15,
      "fraisElectricite": 30,
      "fraisEau": 99,
      "fraisPersonnelMenage": 29,
      "fraisPressing": 15,
      "fraisInternet": 20,
      "fraisNetflixEtAbonnement": 49,
      "fraisProduitsPerissable": 15,
      "fraisLogiciels": 57
    },
    endettement: {
      "revenusFonciersFutursMeuble": 367.5,
      "revenusMensuels": 5000,
      "tauxEndettementFutur": 2.56,
      "tauxEndettementActuel": 2.55,
      "revenusFonciersActuels": 130,
      "autrePretsMensualite": 130
    }
  },
  
  /* * * * * * * * * */
  /* INFOS GENERALES */
  /* * * * * * * * * */

  performFraisNotaires: (state) => {
    return parseNumber((
      state.infosGenerales.coutAchat * 8 / 100
    ).toFixed(2))
  },

  // Dépend de : surface, typedetravaux
  performCoutsTravaux: (state) => {
    if( !state.infosGenerales.surface ) return 0

    return parseNumber(
        parseNumber(state.infosGenerales.surface)
      * parseNumber(state.infosGenerales.typedetravaux)
    )
  },

  // Dépend de : performCoutsTravaux, coutAchat, fraisNotaires
  performCoutsAchatRenovation: (state) => {
    if( !state.infosGenerales.surface || parseNumber(state.infosGenerales.surface) === 0 ) return 0
    
    return parseNumber(
      (
        performCoutsTravaux(state)
        + parseNumber(state.infosGenerales.coutAchat) 
        + parseNumber(state.infosGenerales.fraisNotaires)
      ).toFixed(2)
    )
  },

  // Dépend de : performCoutsAchatRenovation, surface
  performCoutsAchatRenovationM2: (state) => {
    if( !state.infosGenerales.surface || parseNumber(state.infosGenerales.surface) === 0 ) return 0

    return parseNumber(
      (
          performCoutsAchatRenovation(state)
        / parseNumber(state.infosGenerales.surface)
      ).toFixed(2)
    )
  },

  // Dépend de : performCoutsTravaux, coutAchat, coutMeubles, fraisNotaires
  performCoutTotalProjet: (state) => {
    return performCoutsAchatRenovation(state)
          + parseNumber(state.infosGenerales.coutMeubles)
  },

  /* * * * * * */
  /* LE CREDIT */
  /* * * * * * */
  // Dépent de coutMeubles et apportPersonnel
  performApportTotal: (state) => {
    return parseNumber((
        parseNumber(state.infosGenerales.coutMeubles)
      + parseNumber(state.credit.apportPersonnel)
    ).toFixed(2))
  },

  // Dépend de performCoutTotalProjet, performApportTotal, fraisDossier, fraisGarantie
  performTotalCapitalEmprunte: (state) => {
    return    performCoutTotalProjet(state)
            - performApportTotal(state)
            + parseNumber(state.credit.fraisGarantie)
            + parseNumber(state.credit.fraisDossier)
  },

  // Dépend de : tauxAssurance, tauxHorsAssurance, performTotalCapitalEmprunte, dureeTotaleCredit
  performMensualite: (state) => {

    const pourcentTauxTotal = parseNumber(state.credit.tauxAssurance) 
                              + parseNumber(state.credit.tauxHorsAssurance)
    
    const tauxTotalMensuel = pourcentTauxTotal / 100 / 12

    return parseNumber(((
        performTotalCapitalEmprunte(state) 
        * tauxTotalMensuel
      ) / (
        1 - Math.pow(1  + tauxTotalMensuel, - parseNumber(state.credit.dureeTotaleCredit))
      )
    ).toFixed())
  },

  /* * * * * */
  /* REVENUS */
  /* * * * * */

  // Dépend de : logementsData
  performRevenusTotalTousLogementsLDNue: (state) => {
    let value = 0
    if( state.revenus?.logementsData ) {
      state.revenus.logementsData.forEach(logement => value += parseNumber(logement.revenusTotauxLDNue))
    }

    return parseNumber(value.toFixed(2))
  },

  // Dépend de : logementsData
  performRevenusTotalTousLogementsLDMeuble: (state) => {
    let value = 0
    if( state.revenus?.logementsData ) {
      state.revenus.logementsData.forEach(logement => value += parseNumber(logement.revenusTotauxLDMeuble))
    }

    return parseNumber(value.toFixed(2))
  },

  // Dépend de : logementsData
  performRevenusTotalTousLogementsLCD: (state) => {
    let value = 0
    if( state.revenus?.logementsData ) {
      state.revenus.logementsData.forEach(logement => value += parseNumber(logement.revenusTotauxLCD))
    }

    return parseNumber(value.toFixed(2))
  },

  // Dépend de : logementsData
  performRevenusNombreRotationsMois: (state) => {
    let value = 0
    if( state.revenus?.logementsData ) {
      state.revenus.logementsData.forEach(logement => value += parseNumber(logement.nombreRotationsMois))
    }

    return parseNumber(parseNumber(value).toFixed(2))
  },

  /* * * * * */
  /* CHARGES */
  /* * * * * */

  // Dépend de : tauxCommissionPlateforme, performRevenusTotalTousLogementsLCD
  performFraisPlateformesMensuels: (state) => {
    if( !state.charges.tauxCommissionPlateforme ) return 0

    return parseNumber(
      (
        parseNumber(performRevenusTotalTousLogementsLCD(state))
        * parseNumber(state.charges.tauxCommissionPlateforme)
        / 100
      ).toFixed(2)
    )
  },

  // Dépend de : performFraisPlateformesMensuels
  performFraisPlateformesAnnuels: (state) => {

    return parseNumber((
      performFraisPlateformesMensuels(state) * 12
    ).toFixed(2))
  },

  // Dépend de : taxeFonciere, chargesCopro, assurancePNO, nombresLogements (revenus), performRevenusTotalTousLogementsLDNue, previsionTravaux, expertComptable, fraisGerance, fraisDivers, assuranceLoyersImpayes
  performTotalChargesMensuellesLDNue: (state) => {

    return parseNumber((
        parseNumber(state.charges.taxeFonciere) / 12 

      + parseNumber(state.charges.chargesCopro) / 12 

      + ( parseNumber(state.charges.assurancePNO )
        * parseNumber(state.revenus.nombreLogements) )

      + ( parseNumber(state.charges.assuranceLoyersImpayes)
          / 100 
          * performRevenusTotalTousLogementsLDNue(state) )

      + parseNumber(state.charges.previsionTravaux) / 12 

      + parseNumber(state.charges.expertComptable) / 12 

      + ( parseNumber(state.charges.fraisGerance)
        / 100 
        * performRevenusTotalTousLogementsLDNue(state) )

      + parseNumber(state.charges.fraisDivers)
    ).toFixed(2))
  },

  // Dépend de : performTotalChargesMensuellesLDNue
  performTotalChargesAnnuellesLDNue: (state) => {
    return parseNumber(
      (performTotalChargesMensuellesLDNue(state) * 12).toFixed(2)
    )
  },

  // Dépend de : taxeFonciere, chargesCopro, assurancePNO, nombreLogements (revenus), assuranceLoyersImpayes, performRevenusTotalTousLogementsLDMeuble, previsionTravaux, expertComptable, fraisGerance, fraisDivers
  performTotalChargesMensuellesLDMeuble: (state) => {
    return parseNumber((
        parseNumber(state.charges.taxeFonciere) / 12

      + parseNumber(state.charges.chargesCopro) / 12 
      
      + ( parseNumber(state.charges.assurancePNO)
          * parseNumber(state.revenus.nombreLogements) )
      
      + ( parseNumber(state.charges.assuranceLoyersImpayes)
        / 100 
        * performRevenusTotalTousLogementsLDMeuble(state) ) 
        
      + parseNumber(state.charges.previsionTravaux) / 12 
      
      + parseNumber(state.charges.expertComptable) / 12 
      
      + ( parseNumber(state.charges.fraisGerance)
          / 100 
          * performRevenusTotalTousLogementsLDMeuble(state) )
      
      + parseNumber(state.charges.fraisDivers)
    ).toFixed(2))
  },
  
  // Dépend de : performTotalChargesMensuellesLDMeuble
  performTotalChargesAnnuellesLDMeuble: (state) => {
    return parseNumber(
      (performTotalChargesMensuellesLDMeuble(state) * 12).toFixed(2)
    )
  },

  // Dépend de : 
  performTotalChargesMensuellesLCD: (state) => {

   return parseNumber((
        parseNumber(state.charges.taxeFonciere) / 12 
      
      + parseNumber(state.charges.chargesCopro) / 12 
      
      + ( parseNumber(state.charges.assurancePNO)
        * parseNumber(state.revenus.nombreLogements) )
      
      + parseNumber(state.charges.previsionTravaux) / 12 
      
      + parseNumber(state.charges.expertComptable) / 12 
      
      + parseNumber(state.charges.fraisDivers)
      
      + ( parseNumber(state.charges.tauxCommissionPlateforme)
          / 100 
          * performRevenusTotalTousLogementsLCD(state) )
      
      + ( parseNumber(state.charges.tauxConciergerie)
          / 100 
          * performRevenusTotalTousLogementsLCD(state) )
      
      + parseNumber(state.charges.fraisElectricite)
      
      + parseNumber(state.charges.fraisEau)
      
      + ( parseNumber(state.charges.fraisPersonnelMenage)
          * parseNumber(performRevenusNombreRotationsMois(state)) )
      
      + ( parseNumber(state.charges.fraisPressing)
          * parseNumber(performRevenusNombreRotationsMois(state)) )
      
      + parseNumber(state.charges.fraisProduitsPerissable)
      
      + parseNumber(state.charges.fraisInternet)
      
      + parseNumber(state.charges.fraisLogiciels)
      
      + parseNumber(state.charges.fraisNetflixEtAbonnement)
    ).toFixed(2))
  },

  // Dépend de : performTotalChargesMensuellesLCD
  performTotalChargesAnnuellesLCD: (state) => {

    return parseNumber(
      (performTotalChargesMensuellesLCD(state) * 12).toFixed(2)
    )
  },

  /* * * * * * * */
  /* ENDETTEMENT */
  /* * * * * * * */

  // Dépend de : performRevenusTotalTousLogementsLDMeuble
  performFonciersFutursMeuble: (state) => {

    return parseNumber((
      performRevenusTotalTousLogementsLDMeuble(state) * 0.7
    ).toFixed(2))
  },

  // Dépend de : autrePretsMensualite, revenusMensuels, revenusFonciersActuels
  performTauxEndettementActuel: (state) => {

    return parseNumber((
      parseNumber(state.endettement.autrePretsMensualite) 
      / (
          parseNumber(state.endettement.revenusMensuels) 
        + parseNumber(state.endettement.revenusFonciersActuels) 
          * 0.7
        ) * 100
    ).toFixed(2))
  },

  // Dépent de : autrePretsMensualite, revenusMensuels, revenusFonciersActuels, performFonciersFutursMeuble, performMensualite
  performTauxEndettementFutur: (state) => {

    return parseNumber((
      (
          parseNumber(state.endettement.autrePretsMensualite) 
        + performMensualite(state)
      ) / (
          parseNumber(state.endettement.revenusMensuels) 
        + parseNumber(state.endettement.revenusFonciersActuels) 
        + performFonciersFutursMeuble(state)
      ) * 100
    ).toFixed(2))
  },

  /* * * * * * */
  /*  CASHFLOW */
  /* * * * * * */

  // Dépend de performRevenusTotalTousLogementsLDNue, performCoutsAchatRenovation
  performRentaBruteLDNue: (state) => {
    return parseNumber((
      (
        performRevenusTotalTousLogementsLDNue(state) 
        * 12 
        / performCoutsAchatRenovation(state)
        * 100
      ).toFixed(2))
    )
  },

  // Dépend de : performRevenusTotalTousLogementsLDNue, performTotalChargesMensuellesLDNue, mensualite (credit), performCoutsAchatRenovation(state)
  performRentaNetteLDNue: (state) => {
    return parseNumber(
      (
        (   performRevenusTotalTousLogementsLDNue(state) * 12 
          - performTotalChargesMensuellesLDNue(state) * 12 
          - performMensualite(state) * 12
        ) 
        * 100 
        / performCoutsAchatRenovation(state)
      )
    .toFixed(2))
  },

  // Dépend de : performRevenusTotalTousLogementsLDNue, performTotalChargesMensuellesLDNue, mensualite (credit)
  performCashflowLDNue: (state) => {
    return parseNumber(
      (
          performRevenusTotalTousLogementsLDNue(state) 
        - performTotalChargesMensuellesLDNue(state)
        - performMensualite(state)
      ).toFixed(2))
  },

  // Dépend de : performRevenusTotalTousLogementsLDMeuble, performCoutTotalProjet
  performRentaBruteLDMeuble: (state) => {
    return parseNumber(
      (
          performRevenusTotalTousLogementsLDMeuble(state)
        * 12 
        / performCoutTotalProjet(state)
        * 100
      ).toFixed(2))
  },

  // Dépend de : performRevenusTotalTousLogementsLDMeuble, performTotalChargesMensuellesLDMeuble, mensualite (credit), performCoutTotalProjet
  performRentaNetteLDMeuble: (state) => {
    return parseNumber(
      (
        (
          performRevenusTotalTousLogementsLDMeuble(state) * 12 
          - performTotalChargesMensuellesLDMeuble(state) * 12 
          - performMensualite(state) * 12
        ) * 100 
        / performCoutTotalProjet(state)
    ).toFixed(2))
  },

  // Dépend de : performRevenusTotalTousLogementsLDMeuble, performTotalChargesMensuellesLDMeuble, mensualite (credit)
  performCashflowLDMeuble: (state) => {
    return parseNumber(
      (
        performRevenusTotalTousLogementsLDMeuble(state)
        - performTotalChargesMensuellesLDMeuble(state)
        - performMensualite(state)
      ).toFixed(2))
  },

  // Dépend de : performRevenusTotalTousLogementsLCD, 
  performRentaBruteLCD: (state) => {
    return parseNumber(
      (
          performRevenusTotalTousLogementsLCD(state)
        * 12 
        / performCoutTotalProjet(state) 
        * 100
        )
      .toFixed(2))
  },

  // Dépend de : performRevenusTotalTousLogementsLCD, performTotalChargesMensuellesLCD, mensualite (credit), performCoutTotalProjet
  performRentaNetteLCD: (state) => {
    return Number(
      (
        (
            performRevenusTotalTousLogementsLCD(state) * 12 
          - performTotalChargesMensuellesLCD(state) * 12
          - performMensualite(state) * 12
        ) 
        * 100 
        / performCoutTotalProjet(state)
      )
      .toFixed(2))
  },

  // Dépend de : performRevenusTotalTousLogementsLCD, performTotalChargesMensuellesLCD, mensualite (credit)
  performCashflowLCD: (state) => {
    return Number(
      (
          performRevenusTotalTousLogementsLCD(state)
        - performTotalChargesMensuellesLCD(state)
        - performMensualite(state)
      )
      .toFixed(2))
  }
}

const { performCoutsTravaux, performCoutTotalProjet, performApportTotal, performTotalCapitalEmprunte, performRevenusTotalTousLogementsLCD, performFraisPlateformesMensuels, performRevenusTotalTousLogementsLDNue, performTotalChargesMensuellesLDNue, performRevenusTotalTousLogementsLDMeuble, performTotalChargesMensuellesLDMeuble, performRevenusNombreRotationsMois, performTotalChargesMensuellesLCD, performFonciersFutursMeuble, performCoutsAchatRenovation, performMensualite } = uneCalculatriceHelper

export default uneCalculatriceHelper