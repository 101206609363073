import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../toolsSlice/objectSliceHelper'

const URL = '/etudes/'
const helper = new objectSliceHelper()

const uneEtudeSlice = createSlice({
  name: 'uneEtude',
  initialState: helper.getInitialState(),
  reducers: {
    ...helper.reducers,
    resetInitialState: state => {
      helper.resetInitialState(state, 'GET')
    }
  }
})

export const performGetUneEtude = (token, id) => {

  return async dispatch => {
    try {
      dispatch(getInit(helper.ACTIONS.GET))

      const res = await helper.performGetAPICall(token, id, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(getSuccess(json.datas))
      else dispatch(getFailure(json.message))
    }
    catch(e) {
      dispatch(getFailure(e.toString()))
    }
  }
}

export const performSyncUneEtude = (token, etude) => {
  return async dispatch => {
    try {
      dispatch(syncInit(helper.ACTIONS.SYNC))

      const res = await helper.performSyncAPICall(token, etude, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(syncSuccess(json.datas))
      else dispatch(syncFailure(json.message))
    }
    catch(e) {
      dispatch(syncFailure(e.toString()))
    }
  }
}

export const performAddUneEtude = (token, etude) => {
  return async dispatch => {
    try {
      dispatch(addInit(helper.ACTIONS.ADD))

      const res = await helper.performAddAPICall(token, etude, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(addSuccess(json.datas))
      else dispatch(addFailure(json.message))
    }
    catch(e) {
      dispatch(addFailure(e.toString()))
    }
  }
}

export const performDelUneEtude = (token, etude) => {
  return async dispatch => {
    try {
      dispatch(delInit(helper.ACTIONS.DEL))

      const res = await helper.performDelAPICall(token, etude, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(delSuccess())
      else dispatch(delFailure(json.message))
    }
    catch(e) {
      dispatch(delFailure(e.toString()))
    }
  }
}

// Export action creators
const { getInit, syncInit, addInit, delInit ,getSuccess, getFailure, syncSuccess, syncFailure, addSuccess, addFailure, delSuccess, delFailure } = uneEtudeSlice.actions
export const { updateByField, resetInitialState } = uneEtudeSlice.actions

// Export reducer
export default uneEtudeSlice.reducer

// Selectors
export const uneEtudeStatusSelector = (state) => state.uneEtude.status
export const uneEtudeDatasSelector = (state) => state.uneEtude.datas