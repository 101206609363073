import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";

const LotCuisine = ({ cuisine, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("cuisine", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot Cuisine"
            id="lotCuisine"
            inputName="lotCuisine.isChecked"
            isChecked={cuisine?.lotCuisine.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !cuisine?.lotCuisine.isChecked ? "hide" : "sous-lot row col-12"
            }
          >
            {/* 
      ————————————————————————————————————————————————————————————
      PREMIÈRE PARTIE OÙ ON DONNE LES DIMENSIONS DE LA CUISINE
      ————————————————————————————————————————————————————————————
      */}

            <div className="col-4 text-left">
              <InputForm
                label="Largeur Cuisine"
                inputName="largeur"
                type="Number"
                placeholder="180"
                prepend="cm"
                value={cuisine?.largeur}
                onChange={handleChange}
              />
            </div>
            <div className="col-4 text-left">
              <InputForm
                label="Hauteur Cuisine"
                inputName="hauteur"
                type="Number"
                placeholder="220"
                prepend="cm"
                value={cuisine?.hauteur}
                onChange={handleChange}
              />
            </div>
            <div className="col-4 text-left">
              <InputForm
                label="Profondeur Cuisine"
                inputName="profondeur"
                type="Number"
                placeholder="60"
                prepend="cm"
                value={cuisine?.profondeur}
                onChange={handleChange}
              />
            </div>
            {/* 
      ————————————————————————————————————————————————————————————
      DEUXIÈME PARTIE AVEC LES CHECKBOXS DES ITEMS À PRÉVOIR DANS LA CUISINE
      ————————————————————————————————————————————————————————————
      */}

            <div className="sous-lot col-12">
              <Checkbox
                label="Meubles Hauts"
                id="meublesHauts"
                inputName="meublesHauts"
                isChecked={cuisine?.meublesHauts}
                onChange={handleChange}
              />
            </div>

            <div className="sous-lot col-12">
              <Checkbox
                label="Meubles Bas"
                id="meublesBas"
                inputName="meublesBas"
                isChecked={cuisine?.meublesBas}
                onChange={handleChange}
              />
            </div>

            <div className="sous-lot col-12">
              <Checkbox
                label="Évier"
                id="evier"
                inputName="evier"
                isChecked={cuisine?.evier}
                onChange={handleChange}
              />
            </div>

            <div className="sous-lot col-12">
              <Checkbox
                label="Robinet"
                id="robinet"
                inputName="robinet"
                isChecked={cuisine?.robinet}
                onChange={handleChange}
              />
            </div>

            <div className="sous-lot d-flex row col-12">
              <div>
                <Checkbox
                  label="Électroménager Fixe"
                  id="electromenagerFixe"
                  inputName="electromenager.isChecked"
                  isChecked={cuisine?.electromenager.isChecked}
                  onChange={handleChange}
                />
              </div>
              <div
                className={
                  !cuisine?.electromenager.isChecked
                    ? "hide"
                    : "col-12 fs-6 ml-3 text-left"
                }
              >
                <div className="sous-lot col-12">
                  <Checkbox
                    label="Plaque"
                    id="plaque"
                    inputName="electromenager.plaque"
                    isChecked={cuisine?.electromenager.plaque}
                    onChange={handleChange}
                  />
                </div>
                <div className="sous-lot col-12">
                  <Checkbox
                    label="Hôte"
                    id="hote"
                    inputName="electromenager.hote"
                    isChecked={cuisine?.electromenager.hote}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotCuisine;
