import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import UneEtudeHeader from '../UneEtudeHeader'
import Navigation from '../../../toolsComponents/Navigation'
import SimpleContainedError from '../../../toolsComponents/SimpleContainedError'
import SimpleLoading from '../../../toolsComponents/SimpleLoading'
import { loginDatasSelector } from '../../auth/login/loginSlice'
import UneEtudeGeneral from './UneEtudeGeneral'
import { performGetUneEtude, uneEtudeDatasSelector, uneEtudeStatusSelector, updateByField, performSyncUneEtude, performDelUneEtude } from '../uneEtudeSlice'

const UneEtudeGeneralWithStore = ({ id }) => {

  const dispatch = useDispatch()
  const { granted_modules } = useSelector(loginDatasSelector)
  const [ cookies ] = useCookies(['token', 'email'])
  const { hasError, errorMessage, loading, lastAction } = useSelector(uneEtudeStatusSelector)
  const etude = useSelector(uneEtudeDatasSelector)

  useEffect(() => {
      // Get une etude
      dispatch(performGetUneEtude(cookies.token, id))
  }, [dispatch, cookies.token, id])

  const handleChange = (e) => {
    dispatch(updateByField({ field: e.inputName, value: e.value }))
  }

  const handleSubmit = () => {
    dispatch(performSyncUneEtude(cookies.token, etude))
  }

  const handleDelete = () => {
    dispatch(performDelUneEtude(cookies.token, etude))
  }

  const handleNoteChange = async (value) => {
    dispatch(updateByField({ field: 'notation', value: value }))
  }

  if(!loading && !hasError && lastAction === 'DEL') {
    return <Redirect to="/etudes" />
  }
  else {
    return (
      <div>
        <Navigation granted_modules={granted_modules} formationCategories="étude de marché"/>

        {hasError && 
          <SimpleContainedError>
            {errorMessage}
          </SimpleContainedError>
        }

        {!hasError &&
          <div className="main-content">
            <UneEtudeHeader 
              isActive="general"
              etude={etude}
              onDelete={handleDelete}
              onNoteChange={handleNoteChange}
            />

            
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12">
                  {loading &&
                    <SimpleLoading />
                  }

                  {!loading && 
                    <UneEtudeGeneral etude={etude} onChange={handleChange} onSubmit={handleSubmit} />
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default UneEtudeGeneralWithStore