import React, { useState } from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm'
import SubmitForm from '../../../toolsComponents/forms/SubmitForm'
import PasswordInputFormWithVerif from '../../../toolsComponents/forms/PasswordInputFormWithVerif'
import SimpleError from '../../../toolsComponents/SimpleError'
import PasswordInputForm from '../../../toolsComponents/forms/PasswordInputForm'

const UnProfilMotDePasse = ({ errorMessage, onSubmit }) => {

  const [ currentPassword, setCurrentPassword ] = useState('')

  const [ newPassword, setNewPassword ] = useState('')
  const [ newPasswordIsValid, setNewPasswordIsValid ] = useState(false)

  const [ confirmNewPassword, setConfirmNewPassword ] = useState('')
  const [ confirmNewPasswordIsValid, setConfirmNewPasswordIsValid ] = useState(false)

  const [ areFieldsChecked, setAreFieldsChecked ] = useState(false)

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.value)
  }

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.value)
    setNewPasswordIsValid(e.isPasswordOk)
  }

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.value)

    setConfirmNewPasswordIsValid(true)
    setAreFieldsChecked(false)

    if(newPassword !== e.value) {
      setConfirmNewPasswordIsValid(false)
    }
  }

  const handleSubmit = () => {
    setAreFieldsChecked(true)

    if( newPasswordIsValid && confirmNewPasswordIsValid ) {
      onSubmit({currentPassword, newPassword})
    }
  }

  return (
    <>
      <form>
        <h2 style={{textAlign:'center'}}>Changement de mot de passe</h2>
        <br />

        <div className="row">
          <div className="col-4"></div>

          <div className="col-4">
            <PasswordInputForm
              label="Mot de passe actuel"
              inputName="current_password"
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
            />
          </div>

          <div className="col-4"></div>
        </div>

        <div className="row">
          <div className="col-4"></div>

          <div className="col-4">
            <PasswordInputFormWithVerif
              label="Nouveau mot de passe"
              type="password"
              inputName="new_password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
          </div>

          <div className="col-4"></div>
        </div>

        <div className="row">
          <div className="col-4"></div>

          <div className="col-4">
            <PasswordInputForm
              label="Confirmation du nouveau mot de passe"
              inputName="confirm_new_password"
              value={confirmNewPassword}
              onChange={handleConfirmNewPasswordChange}
            />

            {areFieldsChecked && !confirmNewPasswordIsValid && 
              <>                
                <SimpleError withHeader={false}>
                  La confirmation du mot de passe doit correspondre au mot de passe
                </SimpleError>
              </>
            }

            {errorMessage && 
              <>                
                <SimpleError withHeader={false}>
                  {errorMessage}
                </SimpleError>
              </>
            }
            <SubmitForm
              label="Changer de mot de passe"
              onSubmit={handleSubmit}
              isSticky={false}
            />
        
          </div>

          <div className="col-4"></div>
        </div>

      </form>
    </>
  )
}

export default UnProfilMotDePasse