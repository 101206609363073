import React from "react";

const RegisterFinish = () => {
  const onSubmit = () => {
    window.location.replace("/login");
  };
  return (
    <>
      <div className="card">
        <div className="card-body">
          <h1 className="display-4 text-center mb-3">Inscription terminée</h1>

          <p className="text-muted text-center mb-5">
            Merci pour votre confiance en projetimmo.io !<br />
            <br />
            Vous allez dès à présent pouvoir profiter des fonctionnalités de la
            version beta du logiciel.
            <br />
            <br />
            Nous vous conseillons de regarder dès maintenant la vidéo de
            présentation du logiciel pour vous familiariser avec son
            utilisation.
          </p>

          <button
            className="btn btn-lg btn-block btn-primary mb-3"
            onClick={onSubmit}
          >
            Se connecter
          </button>
        </div>
      </div>
    </>
  );
};

export default RegisterFinish;
