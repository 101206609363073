import React from 'react'
import Navigation from '../toolsComponents/Navigation'

const SuperHote = () => {

  return <>
    <Navigation />

    <div className="main-content">

      {/* <!-- HEADER --> */}
      <div className="pt-7 pb-8 bg-dark bg-ellipses">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">

              {/* <!-- Title --> */}
              <h1 className="display-3 text-center text-white">
                <img 
                  style={{width:'350px', verticalAlign:'middle', marginTop:'-10px'}}
                  src="https://superhote.com/wp-content/uploads/2021/03/LogoVectorized-1.svg" 
                  alt="SuperHote Logo" />
                
              </h1>

              {/* <!-- Text --> */}
              <h2 className="lg text-center text-light" style={{fontSize:"32px", fontWeight:"Bold"}}>Vous êtes loueur en courte durée ?</h2>
              <p className="lead text-center text-muted">
               Boostez vos réservations & automatisez à 100% vos opérations à l'aide de notre partenaire SuperHote: l'outil préféré des loueurs en courte durée.
              </p>

            </div>
          </div> 
        </div>
      </div>

      {/* <!-- CONTENT --> */}
      <div className="container-fluid">
        <div className="row mt-n8">
          <div className="col-2"></div>
          
          <div className="col-8">

            {/* <!-- Card --> */}
            <div className="card">
              <div className="card-body">
                <div id="SuperHoteCarousel" className="carousel slide" data-ride="carousel" data-interval="2000">
                  <ol className="carousel-indicators">
                    <li data-target="#SuperHoteCarousel" data-slide-to="0" className="active"></li>
                    <li data-target="#SuperHoteCarousel" data-slide-to="1"></li>
                    <li data-target="#SuperHoteCarousel" data-slide-to="2"></li>
                    <li data-target="#SuperHoteCarousel" data-slide-to="3"></li>
                  </ol>
                  <div className="carousel-inner">
                  <div className="carousel-item">
                      <img className="d-block w-100" src="/assets/img/superhote/Tableaudebord.jpg" alt="Third slide" />
                    </div>
                    <div className="carousel-item active">
                      <img className="d-block w-100" src="/assets/img/superhote/messages.png" alt="First slide" />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src="/assets/img/superhote/bruit.png" alt="Second slide" />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src="/assets/img/superhote/paiements.png" alt="Third slide" />
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Préc.</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Suiv.</span>
                  </a>
                </div>
                <br />

                {/* <!-- Title --> */}
                <h3 className="text-uppercase text-center text-muted my-4">
                  Qu'est-ce que SuperHote ?
                </h3>
                <div className="text-center">
                <p>SuperHote, c'est <b>LE logiciel n°1 pour gérer et optimiser tes locations courte durée.</b></p>

                <p>Avec, non seulement être en mesure d'automatiser à 100% tes opérations pour gérer tes LCD (messages, gestion des prestataires & ménages, cautions, paiements, contrats, planning, etc...)</p>

                <p>Mais en plus, grâce à leur algorithme maison, tu vas pouvoir mieux te référencer sur les plateformes. (En gros, ils ont un script interne qui met à jour ton annonce toutes les minutes et permet de mieux te placer dans les résultats de recherches, donc autant te dire que c'est plus que sympa pour ton CA en fin de mois 😉)</p>
                <p>Sans compter toutes les astuces que Mathieu (le fondateur) te partage pour booster tes résas, ou devenir indépendant des plateformes (grâce à ton site, qu'ils peuvent créer pour toi aussi).</p>
                </div>
                
                <h3 className="text-uppercase text-center text-muted my-4">
                  Ton Cadeau 🎁
                </h3>

                <div className="text-center">
                <p>Quand on a parlé à Mathieu (le créateur du mouvement SuperHote) de projetimmo.io, il a eu la gentillesse d'offrir quelque chose d'ÉNORME.</p>
                <p>En fait, toutes les personnes de la communauté projetimmo.io qui n'utilisent pas encore SuperHote, peuvent découvrir l'outil <b>entièrement GRATUITEMENT... pendant 3 mois !</b> (en gros, c'est un cadeau de ~170€)</p>
                </div>

                <div className="text-center mt-5">
                <h2><u>ALORS SI ÇA T'INTÉRESSE ET QUE TU VEUX DÉCOUVRIR SUPERHOTE <span style={{fontWeight:"bold", color:"#335eea"}}>GRATUITEMENT</span>, CLIQUE-ICI:</u></h2>
                </div>
                {/* <!-- Button --> */}
                <div className="row">
                <div className="text-muted my-4 col-6 text-center">
                <h3>Pour tester l'outil:</h3><a href="https://register.superhote.com/go59tnhodp" target="_blank" rel="noreferrer" className="btn btn-block btn-lg btn-primary">
                  Je profite de 3 mois d'essai
                </a></div>
                <div className="text-muted my-4 col-6 text-center">
                <h3>Pour reserver une démo avec SuperHote:</h3><a href="https://formation.superhote.com/demo" target="_blank" rel="noreferrer" className="btn btn-block btn-lg" style={{background:"#d7062e", color:"white"}}>
                  Je réserve ma démo de l'outil
                </a></div>
                </div>

              </div>
            </div>

          </div>
          
          <div className="col-2"></div>
        </div> 
      </div>

    </div> 
  </>
}

export default SuperHote