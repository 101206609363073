import React from 'react'
import './SearchBar.css';
import SearchSuggestion from './SearchSuggestion'
import _ from 'lodash';

const SearchBar = ({ searchFunction, searchSuggestions, outPut, myBtn, placeHolder, value }) => {

  const handleFocus = () => {
    selectedFunc()

    document.getElementById('search-input').select()
  }

  const handleBlur = () => {
    selectedFunc()
  }

  const selectedFunc = () => {
    document.getElementById('search-bar').classList.toggle('selectedOn');
  }

  const changeInputOnSelection = (e) => {
    outPut(e.target.innerText);
  }

  return (
    <div className="search-form">
      <div className="search-bar" id="search-bar">

        <input autoComplete="off" placeholder={placeHolder} value={value} type="text" onFocus={handleFocus} onBlur={handleBlur} className="search-input" id='search-input' onChange={searchFunction} />

        {searchSuggestions.slice(0, 10).map((suggestion, i) => {
          return (<div className="search-suggestion"><SearchSuggestion onSelection={changeInputOnSelection} key={_.uniqueId()} suggested={suggestion} /></div>);
        })}
      </div>
      <div id="searchfield">
        {/* <div className="button-block">  
                {myBtn.map((btn) => {
                    return <Btn key={_.uniqueId()}  text={btn.text} onclickFunc={btn.onclickFunc}></Btn>
                })}
            </div> */}
      </div>
    </div>
  )
}

export default SearchBar;