import React from 'react'
import Navigation from '../toolsComponents/Navigation'

const MesProjets = () => {

    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">
                
            {/* <!-- HEADER --> */}
            <div className="header">
                <div className="container-fluid">

                {/* <!-- Body --> */}
                <div className="header-body">
                    <div className="row align-items-center">
                    <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-lg avatar-4by3">
                        <img src="/assets/img/mockups/mes-projets-header-avatar-2.jpeg" alt="..." className="avatar-img rounded" />
                        </div>

                    </div>
                    <div className="col ml-n3 ml-md-n2">

                        {/* <!-- Pretitle --> */}
                        <h6 className="header-pretitle">
                        Vue globale
                        </h6>

                        {/* <!-- Title --> */}
                        <h1 className="header-title">
                        Mes projets immobiliers
                        </h1>

                    </div>

                    <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">

                        {/* <!-- Button --> */}
                        <a href="/mockups/nouveau-projet" className="btn btn-primary d-block d-md-inline-block lift">
                            <i className="fe fe-plus"></i> Nouveau projet
                        </a>

                    </div>
                    
                    </div> {/* <!-- / .row --> */}

                </div>

                </div>
            </div> {/* <!-- / .header --></div> */}



            {/* <!-- CONTENT --> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        {/* <!-- SEARCH AND FILTER --> */}
                        <div className="card" id="contactsList">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">

                                        {/* <!-- SEARCH FORM --> */}
                                        <form>
                                        <div className="input-group input-group-flush">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fe fe-search"></i>
                                            </span>
                                            </div>
                                            <input className="list-search form-control" type="search" placeholder="Chercher ..." />
                                        </div>
                                        </form>

                                    </div>

                                    <div className="col-auto mr-n3">

                                        {/* <!-- Select --> */}
                                        <form>
                                            <select className="custom-select custom-select-sm form-control-flush" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                                                <option value="5" selected>5 par page</option>
                                                <option value="10">10 par page</option>
                                                <option value="*">Tous</option>
                                            </select>
                                        </form>

                                    </div>
                                    <div className="col-auto">

                                        {/* <!-- Dropdown --> */}
                                        <div className="dropdown">

                                            {/* <!-- Toggle --> */}
                                            <button className="btn btn-sm btn-white" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fe fe-sliders mr-1"></i> Filtres <span className="badge badge-primary ml-1 d-none">0</span>
                                            </button>

                                            {/* <!-- Menu --> */}
                                            <form className="dropdown-menu dropdown-menu-right dropdown-menu-card">
                                                <div className="card-header">

                                                {/* <!-- Title --> */}
                                                <h4 className="card-header-title">
                                                    Filtres
                                                </h4>

                                                {/* <!-- Link --> */}
                                                <button className="btn btn-sm btn-link text-reset d-none" type="reset">
                                                    <small>Réinitialiser les filtres</small>
                                                </button>

                                                </div>

                                                <div className="card-body">

                                                {/* <!-- List group --> */}
                                                <div className="list-group list-group-flush mt-n4 mb-4">
                                                    <div className="list-group-item">
                                                        <div className="row">
                                                            <div className="col">

                                                            {/* <!-- Text --> */}
                                                            <small>Type de bien</small>

                                                            </div>
                                                            <div className="col-auto">

                                                            {/* <!-- Select --> */}
                                                            <select className="custom-select custom-select-sm" name="item-title" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                                                                <option value="*" selected>Tous</option>
                                                                <option value="Appartement">Appartement</option>
                                                                <option value="Immeuble">Immeuble</option>
                                                                <option value="Maison">Maison</option>
                                                                <option value="Chalet">Chalet</option>
                                                                <option value="Villa">Villa</option>
                                                            </select>

                                                            </div>
                                                        </div> {/* <!-- / .row --> */}
                                                    </div>

                                                    <div className="list-group-item">
                                                        <div className="row">
                                                            <div className="col">

                                                                {/* <!-- Text --> */}
                                                                <small>Note</small>

                                                            </div>
                                                            <div className="col-auto">

                                                            {/* <!-- Select --> */}
                                                            <select className="custom-select custom-select-sm" name="item-score" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                                                                <option value="*" selected>Toutes</option>
                                                                <option value="1/20">1+</option>
                                                                <option value="2/20">2+</option>
                                                                <option value="3/20">3+</option>
                                                                <option value="4/20">4+</option>
                                                                <option value="5/20">5+</option>
                                                                <option value="6/20">6+</option>
                                                                <option value="7/20">7+</option>
                                                                <option value="8/20">8+</option>
                                                                <option value="9/20">9+</option>
                                                                <option value="10/20">10+</option>
                                                                <option value="11/20">11+</option>
                                                                <option value="12/20">12+</option>
                                                                <option value="13/20">13+</option>
                                                                <option value="14/20">14+</option>
                                                                <option value="15/20">15+</option>
                                                                <option value="16/20">16+</option>
                                                                <option value="17/20">17+</option>
                                                                <option value="18/20">18+</option>
                                                                <option value="19/20">19+</option>
                                                                <option value="20/20">20</option>
                                                            </select>

                                                            </div>
                                                        </div> {/* <!-- / .row --> */}
                                                    </div>
                                                </div>

                                                {/* <!-- Button --> */}
                                                <button className="btn btn-block btn-primary" type="submit">
                                                <i className="fe fe-filter"></i> Filtrer
                                                </button>

                                                </div>
                                            </form>

                                        </div>

                                    </div>
                                </div> {/* <!-- / .row --> */}
                            </div>
                        </div>

                        {/* <!-- PROJET 5  --> */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-auto">

                                        {/* <!-- Avatar --> */}
                                        <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres" className="avatar avatar-lg">
                                            <img src="/assets/img/mockups/projet-5-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                        </a>

                                    </div>
                                    <div className="col ml-n2">

                                        {/* <!-- Title --> */}
                                        <h4 className="mb-1">
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres">Chalet en pin douglas à Florac Trois Rivières</a>
                                        </h4>

                                        {/* <!-- Text --> */}
                                        <p className="card-text small text-muted mb-1">
                                            Trés beau chalet typique de lozére
                                        </p>

                                        {/* <!-- Status --> */}
                                        <p className="card-text small">
                                            <span className="text-success">●</span> 18/20
                                        </p>

                                    </div>
                                    
                                    
                                    <div className="col-auto">

                                        {/* <!-- Dropdown --> */}
                                        <div className="dropdown">
                                            <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" data-expanded="false">
                                                <i className="fe fe-more-vertical"></i>
                                            </a>

                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="#!" className="dropdown-item">
                                                <i className="fe fe-trash-2"></i> Supprimer
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div> {/* <!-- / .row --> */}
                            </div> {/* <!-- / .card-body --> */}
                        </div>

                        {/* <!-- PROJET 2  --> */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-auto">

                                        {/* <!-- Avatar --> */}
                                        <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres" className="avatar avatar-lg">
                                            <img src="/assets/img/mockups/projet-2-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                        </a>

                                    </div>
                                    <div className="col ml-n2">

                                        {/* <!-- Title --> */}
                                        <h4 className="mb-1">
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres">Appartement Chanac</a>
                                        </h4>

                                        {/* <!-- Text --> */}
                                        <p className="card-text small text-muted mb-1">
                                            Appartement dans une maison de village
                                        </p>

                                        {/* <!-- Status --> */}
                                        <p className="card-text small">
                                            <span className="text-success">●</span> 16/20
                                        </p>

                                    </div>
                                    
                                    
                                    <div className="col-auto">

                                        {/* <!-- Dropdown --> */}
                                        <div className="dropdown">
                                            <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" data-expanded="false">
                                                <i className="fe fe-more-vertical"></i>
                                            </a>

                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="#!" className="dropdown-item">
                                                <i className="fe fe-trash-2"></i> Supprimer
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div> {/* <!-- / .row --> */}
                            </div> {/* <!-- / .card-body --> */}
                        </div>

                        {/* <!-- PROJET 3  --> */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-auto">

                                        {/* <!-- Avatar --> */}
                                        <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres" className="avatar avatar-lg">
                                            <img src="/assets/img/mockups/projet-3-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                        </a>

                                    </div>
                                    <div className="col ml-n2">

                                        {/* <!-- Title --> */}
                                        <h4 className="mb-1">
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres">Appartement 2 pièces à Mont Lozère et Goulet</a>
                                        </h4>

                                        {/* <!-- Text --> */}
                                        <p className="card-text small text-muted mb-1">
                                            Appartement au premier étage d'un immeuble à proximité des thermes
                                        </p>

                                        {/* <!-- Status --> */}
                                        <p className="card-text small">
                                            <span className="text-success">●</span> 15/20
                                        </p>

                                    </div>
                                    
                                    
                                    <div className="col-auto">

                                        {/* <!-- Dropdown --> */}
                                        <div className="dropdown">
                                            <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" data-expanded="false">
                                                <i className="fe fe-more-vertical"></i>
                                            </a>

                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="#!" className="dropdown-item">
                                                <i className="fe fe-trash-2"></i> Supprimer
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div> {/* <!-- / .row --> */}
                            </div> {/* <!-- / .card-body --> */}
                        </div>

                        {/* <!-- PROJET 4  --> */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-auto">

                                        {/* <!-- Avatar --> */}
                                        <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres" className="avatar avatar-lg">
                                            <img src="/assets/img/mockups/projet-4-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                        </a>

                                    </div>
                                    <div className="col ml-n2">

                                        {/* <!-- Title --> */}
                                        <h4 className="mb-1">
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres">Appartement 2 pièces de 30 m²</a>
                                        </h4>

                                        {/* <!-- Text --> */}
                                        <p className="card-text small text-muted mb-1">
                                            A deux pas des termes et du Lot, appartement avec petite terrasse plein sud au dernier étage
                                        </p>

                                        {/* <!-- Status --> */}
                                        <p className="card-text small">
                                            <span className="text-warning">●</span> 13/20
                                        </p>

                                    </div>
                                    
                                    
                                    <div className="col-auto">

                                        {/* <!-- Dropdown --> */}
                                        <div className="dropdown">
                                            <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" data-expanded="false">
                                                <i className="fe fe-more-vertical"></i>
                                            </a>

                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="#!" className="dropdown-item">
                                                <i className="fe fe-trash-2"></i> Supprimer
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div> {/* <!-- / .row --> */}
                            </div> {/* <!-- / .card-body --> */}
                        </div>

                        {/* <!-- PROJET 1  --> */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-auto">

                                        {/* <!-- Avatar --> */}
                                        <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres" className="avatar avatar-lg">
                                            <img src="/assets/img/mockups/projet-1-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                        </a>

                                    </div>
                                    <div className="col ml-n2">

                                        {/* <!-- Title --> */}
                                        <h4 className="mb-1">
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres">5 appartements au centre de Mende</a>
                                        </h4>

                                        {/* <!-- Text --> */}
                                        <p className="card-text small text-muted mb-1">
                                            Situé en plein centre ville
                                        </p>

                                        {/* <!-- Status --> */}
                                        <p className="card-text small">
                                            <span className="text-danger">●</span> 8/20
                                        </p>

                                    </div>
                                    
                                    
                                    <div className="col-auto">

                                        {/* <!-- Dropdown --> */}
                                        <div className="dropdown">
                                            <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" data-expanded="false">
                                                <i className="fe fe-more-vertical"></i>
                                            </a>

                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="#!" className="dropdown-item">
                                                <i className="fe fe-trash-2"></i> Supprimer
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div> {/* <!-- / .row --> */}
                            </div> {/* <!-- / .card-body --> */}
                        </div>



                        {/* <!-- PAGINATION FOOTER --> */}
                        <div className="card" data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}' id="contactsList">

                            <div className="card-footer d-flex justify-content-between">

                                {/* <!-- Pagination (prev) --> */}
                                <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <a className="page-link pl-0 pr-4 border-right" href="#!">
                                    <i className="fe fe-arrow-left mr-1"></i> Préc.
                                    </a>
                                </li>
                                </ul>

                                {/* <!-- Pagination --> */}
                                <ul className="list-pagination pagination pagination-tabs card-pagination">
                                    <li className="active"><a className="page" href="#!" data-i="1" data-page="10">1</a></li>
                                    <li><a className="page" href="#!" data-i="2" data-page="10">2</a></li>
                                    <li><a className="page" href="#!" data-i="3" data-page="10">3</a></li>
                                </ul>

                                {/* <!-- Pagination (next) --> */}
                                <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <a className="page-link pl-4 pr-0 border-left" href="#!">
                                    Suiv. <i className="fe fe-arrow-right ml-1"></i>
                                    </a>
                                </li>
                                </ul>

                                {/* <!-- Alert --> */}
                                <div className="list-alert alert alert-dark alert-dismissible border fade" role="alert">

                                {/* <!-- Content --> */}
                                <div className="row align-items-center">
                                    <div className="col">

                                    {/* <!-- Checkbox --> */}
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="listAlertCheckbox" checked disabled />
                                        <label className="custom-control-label text-white" htmlFor="listAlertCheckbox"><span className="list-alert-count">0</span> deal(s)</label>
                                    </div>

                                    </div>
                                    <div className="col-auto mr-n3">

                                    {/* <!-- Button --> */}
                                    <button className="btn btn-sm btn-white-20">
                                        Edit
                                    </button>

                                    {/* <!-- Button --> */}
                                    <button className="btn btn-sm btn-white-20">
                                        Delete
                                    </button>

                                    </div>
                                </div> {/* <!-- / .row --> */}

                                {/* <!-- Close --> */}
                                <button type="button" className="list-alert-close close" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>

                                </div>

                            </div>

                        </div>

                    </div>
                    
                </div> {/* <!-- / .row --> */}
            </div>


            </div>
        </div>   
    )
}

export default MesProjets