import React from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { resetInitialState } from '../../tutoriels/tutorielsSlice'
import { logout } from './loginSlice'

const LogoutWithStore = () => {

  const removeCookie = useCookies(['token', 'email', 'granted_modules'])[2]
  const dispatch = useDispatch()

  removeCookie('token', { path: '/' })
  removeCookie('email', { path: '/' })
  removeCookie('granted_modules', { path: '/' })

  dispatch(resetInitialState())
  dispatch(logout())

  // Reset all states
  // Legacy to remember what I did in admin
  //dispatch(resetUsersInitialState())

  return (
    <Redirect to='/login'  />
  )
}

export default LogoutWithStore