import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetFinancement = () => {


    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">
                
                <UnProjetHeader isActive="financement" isActiveSubMenu="resume" />

                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-12">


                {/* Statistiques */}
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl">

                        <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                            <div className="col">

                                <h6 className="text-uppercase text-muted mb-2">
                                Prix négocié
                                </h6>

                                <span className="h2 mb-0">
                                260 000 €
                                </span>

                            </div>
                            <div className="col-auto">

                                <span className="h2 fe fe-check-circle text-muted mb-0"></span>

                            </div>
                            </div>

                        </div>
                        </div>

                    </div>
                    <div className="col-12 col-lg-6 col-xl">

                        <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                            <div className="col">

                                <h6 className="text-uppercase text-muted mb-2">
                                Montant total des travaux
                                </h6>

                                <span className="h2 mb-0">
                                86 700 €
                                </span>

                            </div>

                            <div className="col-auto">

                                <span className="h2 fe fe-layers text-muted mb-0"></span>

                            </div>
                            
                            </div> 

                        </div>
                        </div>

                    </div>
                    <div className="col-12 col-lg-6 col-xl">

                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                            <div className="col">

                                <h6 className="text-uppercase text-muted mb-2">
                                Montant total de l'opération
                                </h6>

                                <span className="h2 mb-0">
                                346 700 €
                                </span>

                            </div>
                            <div className="col-auto">

                                <span className="h2 fe fe-codepen text-muted mb-0"></span>

                            </div>
                            </div>

                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl">

                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                            <div className="col">

                                <h6 className="text-uppercase text-muted mb-2">
                                Prix de revient au m²
                                </h6>

                                <span className="h2 mb-0">
                                1 926,11 €
                                </span>

                            </div>

                            <div className="col-auto">

                                <span className="h2 fe fe-grid text-muted mb-0"></span>

                            </div>
                            
                            </div> 

                        </div>
                        </div>

                    </div>
                </div>


                <div className="row">
                <div className="col-4 col-xl-4">
                {/* <!-- Rendez-vous --> */}
                <div className="col-12">

                    <div className="card">
                            
                        <div className="card-header">

                            {/* <!-- Title --> */}
                            <h4 className="card-header-title">
                                Prochains rendez-vous bancaires
                            </h4>

                            <a href="/mockups/mes-rendez-vous" className="small">Voir plus</a>

                        </div>

                        <div className="card-body">

                            <div className="list-group list-group-flush my-n3">

                                {/* Rendez-vous 1 */}
                                <div className="list-group-item">
                                    <div className="row align-items-center">
                                        <div className="col-auto">

                                            {/* <!-- Avatar --> */}
                                            <a href="/mockups/banques/Credit-Agricole-Du-Languedoc" className="avatar">
                                            <img src="/assets/img/mockups/banque-5-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                            </a>

                                        </div>
                                        <div className="col ml-n2">

                                            {/* <!-- Title --> */}
                                            <h4 className="mb-1">
                                            <a href="/mockups/mes-rendez-vous">Mardi 27 avril 2021 à 16h30</a>
                                            </h4>

                                            {/* <!-- Téléphone --> */}
                                            <p className="card-text small">
                                            <small>Signer le prêt</small>
                                            </p>

                                        </div>

                                    </div>
                                </div>
                                
                                {/* Rendez-vous 2 */}
                                <div className="list-group-item">
                                    <div className="row align-items-center">
                                        <div className="col-auto">

                                            {/* <!-- Avatar --> */}
                                            <a href="/mockups/banques/Credit-Agricole-Du-Languedoc" className="avatar">
                                            <img src="/assets/img/mockups/banque-7-avatar.png" alt="..." className="avatar-img rounded-circle" />
                                            </a>

                                        </div>
                                        <div className="col ml-n2">

                                            {/* <!-- Title --> */}
                                            <h4 className="mb-1">
                                            <a href="/mockups/mes-rendez-vous">Mercredi 28 avril 2021 à 11h</a>
                                            </h4>

                                            {/* <!-- Téléphone --> */}
                                            <p className="card-text small">
                                            <small>Présentation du projet à la banque pop</small>
                                            </p>

                                        </div>

                                    </div>
                                </div>
                                
                                <div className="list-group-item">
                                    <div className="row align-items-center">
                                        <div className="col-12" style={{textAlign:'center'}}>

                                            <a  href="/mockups/mes-rendez-vous">Ajouter un rendez-vous</a>

                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>
                        
                    </div>

                    </div>

                {/* <!-- Emprunteurs supplémentaires --> */}
                <div className="col-12">

                    <div className="card">
                            
                        <div className="card-header">

                            {/* <!-- Title --> */}
                            <h4 className="card-header-title">
                                Emprunteurs supplémentaires
                            </h4>

                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/emprunteurs" className="small">Voir plus</a>

                        </div>

                        <div className="card-body">

                            <div className="list-group list-group-flush my-n3">

                                {/* Emprunteur 1 */}
                                <div className="list-group-item">
                                    <div className="row align-items-center">
                                        <div className="col-auto">

                                            {/* <!-- Avatar --> */}
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/emprunteurs/Gaetan-BERTUIT" className="avatar">
                                            <img src="/assets/img/mockups/gaetan-bertuit.jpeg" alt="..." className="avatar-img rounded-circle" />
                                            </a>

                                        </div>
                                        <div className="col ml-n2">

                                            {/* <!-- Title --> */}
                                            <h4 className="mb-1">
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/emprunteurs/Gaetan-BERTUIT">Gaëtan BERTUIT</a>
                                            </h4>

                                            {/* <!-- Téléphone --> */}
                                            <p className="card-text small">
                                            <small>gaetan.gertuit@capturetheattention.com</small>
                                            </p>

                                        </div>

                                        {/* <!-- Actions --> */}
                                        <div className="col-auto">

                                            {/* <!-- Dropdown --> */}
                                            <div className="dropdown">
                                                <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fe fe-more-vertical"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a href="#!" className="dropdown-item">
                                                    <i className="fe fe-edit-3"></i> Modifier
                                                    </a>
                                                    <a href="#!" className="dropdown-item">
                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>
                                
                                
                                <div className="list-group-item">
                                    <div className="row align-items-center">
                                        <div className="col-12" style={{textAlign:'center'}}>

                                            <a  href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/nouvel-emprunteur" >Ajouter un emprunteur supplémentaire</a>

                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>
                        
                    </div>

                    </div>


                </div>

                {/* <!-- Synthèse --> */}
                <div className="col-4 col-xl-4">
                <div className="card">
                                            
                    <div className="card-header">

                        {/* <!-- Title --> */}
                        <h4 className="card-header-title">
                            Etude rentabilité et synthèse
                        </h4>

                    </div>

                    <div className="card-body">

                    <div className="list-group list-group-flush my-n3">

                        <div className="list-group-item">
                            <div className="row align-items-center">
                            <div className="col">

                                <h5 className="mb-0">
                                Cout total de l'opération
                                </h5>

                            </div>
                            <div className="col-auto">

                                <small className="text-muted">
                                381 700 €
                                </small>

                            </div>
                            </div>
                        </div>
                        
                        <div className="list-group-item">
                            <div className="row align-items-center">
                            <div className="col">

                                <h5 className="mb-0">
                                Apport
                                </h5>

                            </div>
                            <div className="col-auto">

                                <small className="text-muted">
                                20 000
                                </small>

                            </div>
                            </div>
                        </div>
                        
                        <div className="list-group-item">
                            <div className="row align-items-center">
                            <div className="col">

                                <h5 className="mb-0">
                                Montant du prêt demandé
                                </h5>

                            </div>
                            <div className="col-auto">

                                <small className="text-muted">
                                295 000 €
                                </small>

                            </div>
                            </div>
                        </div>
                        
                        <div className="list-group-item">
                            <div className="row align-items-center">
                            <div className="col">

                                <h5 className="mb-0">
                                Mensualité
                                </h5>

                            </div>
                            <div className="col-auto">

                                <small className="text-muted">
                                1 461 €
                                </small>

                            </div>
                            </div>
                        </div>
                        
                        <div className="list-group-item">
                            <div className="row align-items-center">
                            <div className="col">

                                <h5 className="mb-0">
                                Charges
                                </h5>

                            </div>
                            <div className="col-auto">

                                <small className="text-muted">
                                100 €
                                </small>

                            </div>
                            </div>
                        </div>

                        <div className="list-group-item">
                            <div className="row align-items-center">
                            <div className="col">

                                <h5 className="mb-0">
                                Montant du loyer avec charges
                                </h5>

                            </div>
                            <div className="col-auto">

                                <small className="text-muted">
                                1300 €
                                </small>

                            </div>
                            </div>
                        </div>

                        <div className="list-group-item">
                            <div className="row align-items-center">
                            <div className="col">

                                <h5 className="mb-0">
                                Rendement brut
                                </h5>

                            </div>
                            <div className="col-auto">

                                <small className="text-muted">
                                161 €
                                </small>

                            </div>
                            </div>
                        </div>

                        <div className="list-group-item">
                            <div className="row align-items-center">
                            <div className="col">

                                <h5 className="mb-0">
                                Bénéfices mensuel (cashflow)
                                </h5>

                            </div>
                            <div className="col-auto">

                                <small className="text-muted">
                                161 €
                                </small>

                            </div>
                            </div>
                        </div>
                        

                    </div>
                        
                    </div>
                    
                </div>
                </div>

                <div className="col-4 col-xl-4">
                {/* <!-- Offres de prêts --> */}
                <div className="col-12">

                    <div className="card">
                            
                        <div className="card-header">

                            {/* <!-- Title --> */}
                            <h4 className="card-header-title">
                                Offres de prêts
                            </h4>

                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/offres-prets" className="small">Voir plus</a>

                        </div>

                        <div className="card-body">

                            <div className="list-group list-group-flush my-n3">

                                {/* Offres 1 */}
                                <div className="list-group-item">
                                    <div className="row align-items-center">
                                        <div className="col-auto">

                                            {/* <!-- Avatar --> */}
                                            <a href="/mockups/banques/Credit-Agricole-Du-Languedoc" className="avatar">
                                            <img src="/assets/img/mockups/banque-5-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                            </a>

                                        </div>
                                        <div className="col ml-n2">

                                            {/* <!-- Title --> */}
                                            <h4 className="mb-1">
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/offres-prets/Credit-Mutuel"><span className="item-score badge badge-soft-success">Accepté</span> Crédit Mutuel</a>
                                            </h4>

                                            {/* <!-- Téléphone --> */}
                                            <p className="card-text small">
                                            <small>295 000 €  (1,16%) 1 431 € /mois</small>
                                            </p>

                                        </div>

                                    </div>
                                </div>
                                
                                {/* Offres 2 */}
                                <div className="list-group-item">
                                    <div className="row align-items-center">
                                        <div className="col-auto">

                                            {/* <!-- Avatar --> */}
                                            <a href="/mockups/banques/Credit-Agricole-Du-Languedoc" className="avatar">
                                            <img src="/assets/img/mockups/banque-7-avatar.png" alt="..." className="avatar-img rounded-circle" />
                                            </a>

                                        </div>
                                        <div className="col ml-n2">

                                            {/* <!-- Title --> */}
                                            <h4 className="mb-1">
                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/offres-prets/Credit-Mutuel"><span className="item-score badge badge-soft-danger">Refusé</span> Banque Populaire du Sud</a>
                                            </h4>

                                            {/* <!-- Téléphone --> */}
                                            <p className="card-text small">
                                            <small>295 000 €  (1,23%) 1 471 € /mois</small>
                                            </p>

                                        </div>

                                    </div>
                                </div>
                                
                                
                                <div className="list-group-item">
                                    <div className="row align-items-center">
                                        <div className="col-12" style={{textAlign:'center'}}>

                                            <a  href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/nouvelle-offre-pret" >Ajouter une offre</a>

                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>
                        
                    </div>

                    </div>
                    
                </div>

                </div>

                        </div>
                    </div>
                </div>


            </div>
        </div> 
    )
}

export default UnProjetFinancement