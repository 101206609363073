import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";

const LotDemolition = ({ demolition, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("demolition", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot Démolition"
            id="lotDemolition"
            inputName="lotDemolition.isChecked"
            isChecked={demolition?.lotDemolition.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !demolition?.lotDemolition.isChecked
                ? "hide"
                : "sous-lot row col-12"
            }
          >
            <div className="col-2">
              <Checkbox
                label="Cloisons"
                id="demolitionCloisons"
                inputName="cloison.isChecked"
                isChecked={demolition?.cloison.isChecked}
                onChange={handleChange}
              />
              <div
                className={
                  !demolition?.cloison.isChecked
                    ? "hide"
                    : "row ml-4 mt-2 text-left"
                }
              >
                <InputForm
                  label="Surface"
                  inputName="cloison.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={demolition?.cloison.surface}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-2">
              <Checkbox
                label="Plafonds"
                id="demolitionPlafonds"
                inputName="plafond.isChecked"
                isChecked={demolition?.plafond.isChecked}
                onChange={handleChange}
              />
              <div
                className={
                  !demolition?.plafond.isChecked
                    ? "hide"
                    : "row ml-4 mt-2 text-left"
                }
              >
                <InputForm
                  label="Surface"
                  inputName="plafond.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={demolition?.plafond.surface}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-2">
              <Checkbox
                label="Cheminees"
                id="demolitionCheminees"
                inputName="chemine.isChecked"
                isChecked={demolition?.chemine.isChecked}
                onChange={handleChange}
              />
              <div
                className={
                  !demolition?.chemine.isChecked
                    ? "hide"
                    : "row ml-4 mt-2 text-left"
                }
              >
                <InputForm
                  label="Surface"
                  inputName="chemine.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={demolition?.chemine.surface}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-2">
              <Checkbox
                label="Escalier"
                id="demolitionEscalier"
                inputName="escalier.isChecked"
                isChecked={demolition?.escalier.isChecked}
                onChange={handleChange}
              />
              <div
                className={
                  !demolition?.escalier.isChecked
                    ? "hide"
                    : "row ml-4 mt-2 text-left"
                }
              >
                <InputForm
                  label="Surface"
                  inputName="escalier.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={demolition?.escalier.surface}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-3">
              <Checkbox
                label="Revêtement Sol"
                id="demolitionRevetementSol"
                inputName="revetementSol.isChecked"
                isChecked={demolition?.revetementSol.isChecked}
                onChange={handleChange}
              />
              <div
                className={
                  !demolition?.revetementSol.isChecked
                    ? "hide"
                    : "row ml-4 mt-2 text-left"
                }
              >
                <InputForm
                  label="Surface"
                  inputName="revetementSol.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={demolition?.revetementSol.surface}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotDemolition;
