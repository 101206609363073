import React from 'react'
import defined from '../../ressources/defined'

const FormationItem = ({ formation, onCategory }) => {

  const handleCategoriesClick = (category) => {
    onCategory(category)
  }

  const getDifficultyStars = (difficulty) => {
    var result = []
    for(let i = 0; i < difficulty; i++) {
      result.push(<i className="fe fe-star text-warning active" style={{paddingRight:'2px'}}></i>)
    }

    return result
  }

  const getCategoriesLink = (cats) => {
    var result = []
    for(let i = 0; i < cats.length; i++) {
      result.push(<a href={defined.ANCHOR} onClick={() => handleCategoriesClick(cats[i])}>{cats[i]}</a>)
      if(i !== cats.length - 1) {
          result.push(<span>,&nbsp;</span>)
      }
    }

    return result
  }

  return (
    <div className="col-12 col-md-6 col-xl-4">
      <div className="card">
        <a href={'/formations/'+formation._id}>
          <img src={formation.url_thumbnail} alt="..." className="card-img-top" />
        </a>

        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="mb-2 name">
                <a href={'/formations/'+formation._id}>{formation.title}</a>
              </h4>

              <p className="card-text small text-muted" style={{paddingTop:"6px"}}>
                {formation.author}
              </p>
            </div>
          </div> 
        </div>

        <div className="card-footer card-footer-boxed">
          <div className="row align-items-center">
            <div className="col">
              <div className="row align-items-center no-gutters">
                <div className="col-auto">
                  <div className="small mr-2">
                    Difficulté : &nbsp;&nbsp; 
                    {getDifficultyStars(formation.difficulty)}
                  </div>
                </div>
              </div> 
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col">
              <div className="row align-items-center no-gutters">
                <div className="col-auto">
                  <div className="small mr-2" style={{marginTop:"4px"}}>
                    {getCategoriesLink(formation.categories)}
                  </div>
                </div>
              </div> 
            </div>
          </div>  
        </div>
      </div>
    </div>
  )
}

export default FormationItem