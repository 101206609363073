class objectSliceHelper {
  /**
   * Create a new helper
   */
  // eslint-disable-next-line
  constructor() {}

  /**
   * List of actions
   */
  ACTIONS = {
    ADD: "ADD",
    DEL: "DEL",
    GET: "GET",
    SYNC: "SYNC",
  };

  /**
   * Return a new generic InitialState
   * @returns
   */
  getInitialState = () => {
    return {
      status: {
        loading: false,
        hasError: false,
        errorMessage: null,
        lastAction: null,
      },
      datas: {},
    };
  };

  /**
   * Reset a generic InitialState
   * @param {*} state
   */
  resetInitialState = (state, action) => {
    state.status = {
      loading: false,
      hasError: false,
      errorMessage: null,
      lastAction: action,
    };

    state.datas = {};
  };

  /**
   * To use in getObjectInit
   * @param {*} state
   */
  performInit = (state, action = this.ACTIONS.GET) => {
    this.resetInitialState(state, action);

    state.status.loading = true;
  };

  /**
   * Perform everything we need when datas arrive with success
   * @param {*} state
   * @param {*} payload
   */
  performSuccess = (state, payload) => {
    state.status.loading = false;
    state.status.hasError = false;
    state.status.errorMessage = null;

    state.datas = payload;
  };

  /**
   * Manage everything when there is a failure in API call
   * @param {*} state
   * @param {*} payload
   */
  performFailure = (state, payload) => {
    state.status.loading = false;
    state.status.hasError = true;
    state.status.errorMessage = payload;

    state.datas = [];
  };

  /**
   * Manage all generic reducers we need in a simple object slice
   */
  reducers = {
    /* INIT REDUCERS */

    // payload : helper.ACTIONS
    init: (state, { payload }) => {
      this.performInit(state, payload);
    },

    // optional payload : helper.ACTIONS
    getInit: (state, { payload = this.ACTIONS.GET }) => {
      this.performInit(state, payload);
    },

    // optional payload : helper.ACTIONS
    syncInit: (state, { payload = this.ACTIONS.SYNC }) => {
      this.performInit(state, payload);
    },

    // optional payload : helper.ACTIONS
    addInit: (state, { payload = this.ACTIONS.ADD }) => {
      this.performInit(state, payload);
    },

    // optional payload : helper.ACTIONS
    delInit: (state, { payload = this.ACTIONS.DEL }) => {
      this.performInit(state, payload);
    },

    /* SUCCESS REDUCERS */

    // payload : an object
    success: (state, { payload }) => {
      this.performSuccess(state, payload);
    },

    // payload : an object
    getSuccess: (state, { payload }) => {
      this.performSuccess(state, payload);
    },

    // payload : an object
    syncSuccess: (state, { payload }) => {
      this.performSuccess(state, payload);
    },

    // payload : an object
    addSuccess: (state, { payload }) => {
      this.performSuccess(state, payload);
    },

    // payload : an object
    delSuccess: (state, { payload }) => {
      this.performSuccess(state, payload);
    },

    /* FAILURE REDUCERS */

    // payload : errorMessage
    failure: (state, { payload }) => {
      this.performFailure(state, payload);
    },

    // payload : errorMessage
    getFailure: (state, { payload }) => {
      this.performFailure(state, payload);
    },

    // payload : errorMessage
    syncFailure: (state, { payload }) => {
      this.performFailure(state, payload);
    },

    // payload : errorMessage
    addFailure: (state, { payload }) => {
      this.performFailure(state, payload);
    },

    // payload : errorMessage
    delFailure: (state, { payload }) => {
      this.performFailure(state, payload);
    },

    /* OTHER REDUCERS */

    // payload : { field, value }
    updateByField: (state, { payload }) => {
      if (typeof state.datas[payload.field] === "number")
        state.datas[payload.field] = parseFloat(payload.value);
      else state.datas[payload.field] = payload.value;
    },
  };

  /**
   * Perform API call for GET request
   * @param {*} token
   * @param {*} id
   * @param {*} URL like /etudes/
   * @returns
   */
  performGetAPICall = async (token, id, URL) => {
    const reqOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(),
    };

    return await fetch(
      process.env.REACT_APP_REST_API_LOCATION + URL + id,
      reqOptions
    );
  };

  /**
   * Perform API call for PUT request
   * @param {*} token
   * @param {*} id
   * @param {*} URL like /etudes/
   * @returns
   */
  performSyncAPICall = async (token, object, URL) => {
    const reqOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(object),
    };

    return await fetch(
      process.env.REACT_APP_REST_API_LOCATION + URL,
      reqOptions
    );
  };

  /**
   * Perform API call for POST request
   * @param {*} token
   * @param {*} id
   * @param {*} URL like /etudes/
   * @returns
   */
  performAddAPICall = async (token, object, URL) => {
    const reqOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(object),
    };

    return await fetch(
      process.env.REACT_APP_REST_API_LOCATION + URL,
      reqOptions
    );
  };

  /**
   * Perform API call for POST request with form data
   * @param {*} token
   * @param {*} id
   * @param {*} URL like /etudes/
   * @returns
   */
  performFormDataAPICall = async (token, formData, URL) => {
    const reqOptions = {
      method: "POST",
      headers: {
        "auth-token": token,
      },
      body: formData,
    };

    return await fetch(
      process.env.REACT_APP_REST_API_LOCATION + URL,
      reqOptions
    );
  };

  /**
   * Perform API call for DEL request
   * @param {*} token
   * @param {*} id
   * @param {*} URL like /etudes/
   * @returns
   */
  performDelAPICall = async (token, object, URL) => {
    const reqOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({}),
    };

    return await fetch(
      process.env.REACT_APP_REST_API_LOCATION + URL + object._id,
      reqOptions
    );
  };
}

export default objectSliceHelper;
