import React from "react";

const DeleteModal = ({ children, myId = "modal-delete", onDelete }) => {
  return (
    <>
      <div className="modal" tabindex="-1" role="dialog" id={myId}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p>{children}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={onDelete}
              >
                <i className="fe fe-trash"></i> Oui
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                <i className="fe fe-x"></i>Non
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
