import React from 'react'

const SelectProjet = () => {

  return (
    <div className="form-group">
      <label htmlFor="exampleFormControlSelect1">
        Projet
      </label>

      <select className="form-control" id="exampleFormControlSelect1">
        <option selected>Chalet en pin douglas à Florac Trois Rivières</option>
        <option>Appartement Chanac</option>
        <option>Appartement 2 pièces à Mont Lozère et Goulet</option>
        <option>Appartement 2 pièces de 30 m²</option>
        <option>5 appartements au centre de Mende</option>
      </select>
    </div>
  )
}

export default SelectProjet