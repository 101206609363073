import React from 'react'
import ReactTooltip from 'react-tooltip';

/**
 * 
 * @param label Input label
 * @param type Input type (textarea, text, password, number, email)
 * @param inputName Name of input used in id and name
 * @param value Input value
 * @param prepend Value display before the input field
 * @param onChange Event triggered on input value changed
 * @returns input form in JSX
 */
const InputForm = ( { label, type='text', inputId, inputName, value, prepend, link, placeholder, sideBadgeValue, sideBadgeColor, onChange, readOnly=false, tooltip  } ) => {

  const handleChange = (e) => {
    if(onChange) onChange({
      value: e.target.value,
      inputName: inputName
    })
  }

  const displayTootlip = () => {
    return <>
    {tooltip &&
      <>
        <span data-tip data-for={'tooltip'+inputName} data-multiline={true}>
          <i className='fe fe-info'></i>
        </span>
        <ReactTooltip 
          id={'tooltip'+inputName} 
          place='top' 
          effect='solid' 
          type="info"
          multiline={true}
        >
          {tooltip}
        </ReactTooltip>
      </>
    }
  </>
  }

  return <>
    {type === 'textarea' &&
      <div className="form-group">
      {label &&  <label htmlFor={'input'+inputName} >
          {label} 
          &nbsp;{link && <a href={link[1]} target="_blank" rel="noreferrer">{link[0]}</a>} 
          &nbsp;{sideBadgeValue && <span className={'badge badge-soft-'+sideBadgeColor}><strong>{sideBadgeValue}</strong></span>}
          {displayTootlip()}
        </label>
      }

        <textarea 
          id={'input'+inputName}
          className="form-control" 
          name={inputName} 
          rows="4"
          value={value}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly}
          placeholder={placeholder}
        ></textarea>
      </div>
    }

    {type !== 'textarea' && type !== 'checkbox' && prepend &&
      <div className="form-group">
        {label && <label htmlFor={'input'+inputName} >
          {label} 
          &nbsp;{link && <a href={link[1]} target="_blank" rel="noreferrer">{link[0]}</a>} 
          &nbsp;{sideBadgeValue && <span className={'badge badge-soft-'+sideBadgeColor}><strong>{sideBadgeValue}</strong></span>}
          {displayTootlip()}
        </label>}
        
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <div className="input-group-text">{prepend}</div>
          </div>
          <input 
            id={'input'+inputName}
            type={type} 
            name={inputName} 
            className="form-control" 
            value={value} 
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={readOnly}
            onChange={handleChange} 
          />
        </div>
      </div>
    }

    {type !== 'textarea' && type !== 'checkbox' && !prepend &&
      <div className="form-group">
        {label && <label htmlFor={'input'+inputName} >
          {label} 
          &nbsp;{link && <a href={link[1]} target="_blank" rel="noreferrer">{link[0]}</a>} 
          &nbsp;{sideBadgeValue && <span className={'badge badge-soft-'+sideBadgeColor}><strong>{sideBadgeValue}</strong></span>}
          {displayTootlip()}
        </label>
        }
        <input 
          id={'input'+inputName}
          type={type} 
          name={inputName} 
          className="form-control" 
          value={value} 
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={readOnly}
          onChange={handleChange} 
          />
      </div>
    }

    {type === 'checkbox' &&
      <div className="form-group">


		<label class="toggle" for={inputId}>
			<input type="checkbox" class="toggle__input" id={inputId} name={inputName}/>
			<span class="toggle-track">
				<span class="toggle-indicator">
				
					<span class="checkMark">
						<svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
							<path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
						</svg>
					</span>
				</span>
			</span>
			{label}
		</label>

      </div>
    }

  </>
}

export default InputForm