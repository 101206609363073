import React, { useState } from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm'
import PasswordInputForm from '../../../toolsComponents/forms/PasswordInputForm'
import PasswordInputFormWithVerif from '../../../toolsComponents/forms/PasswordInputFormWithVerif'
import SimpleError from '../../../toolsComponents/SimpleError'

const NewPassword = ({ email, errorMessage, onSubmit }) => {


  const [ newPassword, setNewPassword ] = useState('')
  const [ isNewPasswordValid, setIsNewPasswordValid ] = useState(false)


  const [ confirmNewPassword, setConfirmNewPassword ] = useState('')
  const [ isConfirmNewPasswordValid, setIsConfirmNewPasswordValid ] = useState(false)

  const [ areFieldsChecked, setAreFieldsChecked ] = useState(false)

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.value)
    setIsNewPasswordValid(e.isPasswordOk)
  }

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.value)

    setIsConfirmNewPasswordValid(true)
    setAreFieldsChecked(false)

    if(newPassword !== e.value) {
      setIsConfirmNewPasswordValid(false)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setAreFieldsChecked(true)
    
    if( isNewPasswordValid && isConfirmNewPasswordValid ) {
      onSubmit(newPassword)
    }
  }

  return (
    <div>
      {/* Heading */}
      <h1 className="display-4 text-center mb-3">
          Nouveau mot de passe
      </h1>

      {/* Sub-Heading */}
      <p className="text-muted text-center mb-5">
          Vous avez demandé de changer de mot de passe
      </p>

      <form onSubmit={handleSubmit}>

        {/* Email field */}
        <InputForm 
          label="Adresse email de votre compte"
          type="email"
          value={email}
          placeholder="nom@domaine.com"
        />

        <PasswordInputFormWithVerif
          label="Nouveau mot de passe"
          type="password"
          inputName="new_password"
          value={newPassword}
          onChange={handleNewPasswordChange}
        />

        <PasswordInputForm
          label="Confirmation du nouveau mot de passe"
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
        />

        {areFieldsChecked && !isConfirmNewPasswordValid && 
          <>                
            <SimpleError withHeader={false}>
              La confirmation du mot de passe doit correspondre au mot de passe
            </SimpleError>
          </>
        }

        {errorMessage && 
          <>                
            <SimpleError withHeader={false}>
              {errorMessage} 
            </SimpleError>
          </>
        }

        {/* Submit button */}
        <button className="btn btn-lg btn-block btn-primary mb-3" tabIndex="3" onSubmit={handleSubmit}>
            Mettre à jour le mot de passe
        </button>
  
      </form>
    </div>
  )
}

export default NewPassword