import React from 'react'
import ContactsCard from '../../toolsComponents/cards/ContactsCard'
import RDVsCard from '../../toolsComponents/cards/RDVsCard'
import InputForm from '../../toolsComponents/forms/InputForm'
import SubmitForm from '../../toolsComponents/forms/SubmitForm'

const UneBanque = ( { banque } ) => {

  return (
    <div className="row">
      {/* <!-- FORMULAIRE --> */}
      <div className="col-8 col-xl-8">
        <form className="mb-4">
          {/* <!-- Nom de la banque --> */}
          <InputForm 
            label="Nom de la banque"
            inputName="nom"
            value={banque.nom}
          />

          {/* <!-- Description --> */}
          <InputForm 
            label="Description"
            type='textarea'
            inputName="description"
            value={banque.description}
          />

          <hr />

          {/* <!-- Adresse --> */}
          <InputForm 
            label="Adresse"
            inputName="adresse"
            value={banque.adresse}
          />

          {/* <!-- Ville et code postal --> */}
          <div className="row">
            <div className="col-12 col-md-6">
              <InputForm 
                label="Code postal"
                inputName="code_postal"
                value={banque.code_postal}
              />

            </div>
            <div className="col-12 col-md-6">
              <InputForm 
                label="Ville"
                inputName="nom"
                value={banque.ville}
              />
            </div>
          </div>

          {/* <!-- Téléphone et email --> */}
          <div className="row">
            {/* <!-- Numéro de téléphone --> */}
            <div className="col-12 col-md-6">
              <InputForm 
                label="Numéro de téléphone"
                type="phone"
                inputName="telephone"
                value={banque.telephone}
              />
            </div>
            
            {/* <!-- Adresse e-mail --> */}
            <div className="col-12 col-md-6"> 
              <InputForm 
                label="Adresse e-mail"
                inputName="email"
                value={banque.email}
              />
            </div>
          </div> {/* <!-- / .row --> */}

          <hr />

          <SubmitForm 
            label="Sauvegarder"
          />

          <br /><br />
        </form>
      </div>

      {/* CARDS*/}
      <div className="col-4 col-xl-4">
        {/* <!-- Contacts --> */}
        <ContactsCard 
          contacts={banque.contacts}
        />   

        {/* <!-- Rendez-vous --> */}
        <RDVsCard
          rdvs={banque.RDVs}
        />
      </div>
    </div>
  )
}

export default UneBanque