import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Navigation from '../../toolsComponents/Navigation'
import SimpleError from '../../toolsComponents/SimpleError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'
import { loginDatasSelector } from '../auth/login/loginSlice'
import UnTutoriel from './UnTutoriel'
import { performGetUnTutoriel, unTutorielDatasSelector, unTutorielStatusSelector } from './unTutorielSlice'

const UnTutorielWithStore = () => {

  const { granted_modules } = useSelector(loginDatasSelector)
  const { id } = useParams()
  const dispatch = useDispatch()
  const [ cookies ] = useCookies(['token', 'email'])
  const { loading, hasError, errorMessage } = useSelector(unTutorielStatusSelector)
  const tutoriel = useSelector(unTutorielDatasSelector)

  useEffect(() => {
      // Get tutoriel
      dispatch(performGetUnTutoriel(cookies.token, id))
  }, [dispatch, cookies.token, id])

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      <div className="main-content bg-fixed-bottom" style={{backgroundImage:'url(/assets/img/illustrations/sticky.svg)'}}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-10">
              {loading && 
                <div style={{paddingTop:"128px"}}>
                  <SimpleLoading />
                </div>
              }

              {hasError &&
                <SimpleError>
                  {errorMessage}
                </SimpleError>
              }

              {!loading && !hasError &&
                <div>
                  <UnTutoriel 
                    tutoriel={tutoriel}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div> 
    </div>
  )
}

export default UnTutorielWithStore