import React from 'react'
import SimpleAreaChart from '../../../toolsComponents/charts/SimpleAreaChart';
import SimpleWarning from '../../../toolsComponents/SimpleWarning'


const UneEtudePopulations = ({ etude }) => {

  const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const data = []
  let min = null
  let max = 0

  let habitants_2022 = ''
  let habitants_2021 = ''
  let habitants_2020 = ''
  let habitants_2017 = ''

  if(etude?.populations?.length > 0 ) {
    for(const pop of etude.populations) {
      if(min === null || pop.habitants < min ) min = pop.habitants
      if(pop.habitants > max ) max = pop.habitants

      data.push(pop)
    }

    try {
      habitants_2022 = etude.populations.filter(p => p.annee === 2022)[0].habitants
      habitants_2021 = etude.populations.filter(p => p.annee === 2021)[0].habitants
      habitants_2020 = etude.populations.filter(p => p.annee === 2020)[0].habitants
      habitants_2017 = etude.populations.filter(p => p.annee === 2017)[0].habitants
    }
    catch(e) {
      habitants_2022 = 0
      habitants_2021 = 0
      habitants_2020 = 0
      habitants_2017 = 0
    }
  }

  const getEvoPop = (actuelle, evo) => {
    const value = (((parseFloat(actuelle) - parseFloat(evo)) / (parseFloat(actuelle)))*100).toFixed(1)

    if(value > 0) return <span className={'badge badge-soft-success'}><strong>+{value}%</strong></span>
    else if( value > -1 && value <= 0 ) return <span className={'badge badge-soft-warning'}><strong>{value}%</strong></span>
    else return <span className={'badge badge-soft-danger'}><strong>{value}%</strong></span>
  }

  return (
    <>
      {(habitants_2022 === 0 && habitants_2021 === 0 && habitants_2020 === 0 && habitants_2017 === 0 ) ?
        <SimpleWarning>
          Il est impossible de calculer l'évolution de la popution ces dernières années. Cela signifie souvent que la ville n'existe plus ou qu'elle a été rattachée à une autre ville
        </SimpleWarning>
        :
        <div className="row">
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted mb-2">Evolution entre 2017 et 2022</h6>
                    
                    <span className="h2 mb-0">{getEvoPop(habitants_2022, habitants_2017)}</span>
                  </div>
                  
                  <div className="col-auto">
                    <span className="h2 fe fe-users text-muted mb-0"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted mb-2">Evolution entre 2020 et 2022</h6>
                    
                    <span className="h2 mb-0">{getEvoPop(habitants_2022, habitants_2020)}</span>
                  </div>
                  
                  <div className="col-auto">
                    <span className="h2 fe fe-trending-up text-muted mb-0"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted mb-2">Evolution entre 2021 et 2022</h6>
                    
                    <span className="h2 mb-0">{getEvoPop(habitants_2022, habitants_2021)}</span>
                  </div>
                  
                  <div className="col-auto">
                    <span className="h2 fe fe-trending-up text-muted mb-0"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted mb-2">Nombre d'habitants en 2022</h6>
                    
                    <span className="h2 mb-0">{numberWithSpaces(habitants_2022)}</span>
                  </div>
                  
                  <div className="col-auto">
                    <span className="h2 fe fe-users text-muted mb-0"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      

      <div className="row">
        <div className="col">
          <br />
          <h3>Nombres d'habitants entre 2009 et 2022</h3>

          <SimpleAreaChart 
            data={data}
            YDataKey="habitants"
            XDataKey="annee"
          />
        </div>
      </div>
    </>
  )
}

export default UneEtudePopulations