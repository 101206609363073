import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import MonProfilHeader from './MonProfilHeader'
import SelectNationnalite from '../../toolsComponents/SelectNationnalite'
import SelectSituationFamiliale from '../../toolsComponents/SelectSituationFamiliale'

const MonProfilDetails = () => {

    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">

                {/* <!-- HEADER --> */}
                <MonProfilHeader isActive="details" />

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                            {/* <!-- FORMULAIRE --> */}
                            <div className="col-12">

                                <form className="mb-4">

                                    {/* <!-- Date et lieu de naissance --> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Date de naissance
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="05/03/1992"/>

                                            </div>

                                            </div>
                                        <div className="col-12 col-md-6">

                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Lieu de naissance
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="Colmar"/>

                                            </div>

                                        </div>
                                    </div> {/* <!-- / .row --> */}

                                    {/* Nationnalité */}
                                    <SelectNationnalite />

                                    {/* Situation familiale */}
                                    <SelectSituationFamiliale />

                                    <hr />

                                    {/* <!-- Type d'emploi et employeur --> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Type d'emploi
                                                </label>

                                                {/* <!-- Input --> */}
                                                <select className="form-control">
                                                    <option selected>Entrepreneur</option>
                                                    <option>Fonctionnaire</option>
                                                    <option>Salarié</option>
                                                </select>

                                            </div>

                                            </div>
                                        <div className="col-12 col-md-6">

                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Employeur
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="ProjetImmo.io"/>

                                            </div>

                                        </div>
                                    </div> {/* <!-- / .row --> */}

                                    {/* <!-- Fonction --> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Fonction
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="CTO"/>

                                            </div>

                                            </div>
                                        
                                    </div> {/* <!-- / .row --> */}

                                    <hr />

                                    {/* <!-- Salaire et épargne --> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            {/* <!-- Start date --> */}
                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Salaire net
                                                </label>

                                                {/* <!-- Input --> */}
                                                <div className="input-group mb-2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">€</div>
                                                    </div>
                                                    <input type="number" className="form-control" value="0" />
                                                </div>

                                            </div>

                                            </div>
                                        <div className="col-12 col-md-6">

                                            {/* <!-- Start date --> */}
                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    &Eacute;pargne personnelle
                                                </label>

                                                {/* <!-- Input --> */}
                                                <div className="input-group mb-2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">€</div>
                                                    </div>
                                                    <input type="number" className="form-control" value="10000" />
                                                </div>

                                            </div>

                                        </div>
                                    </div> {/* <!-- / .row --> */}

                                    {/* <!-- Patrimoine --> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            {/* <!-- Start date --> */}
                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Patrimoine personnel
                                                </label>

                                                {/* <!-- Input --> */}
                                                <div className="input-group mb-2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">€</div>
                                                    </div>
                                                    <input type="number" className="form-control" value="0" />
                                                </div>

                                            </div>

                                            </div>
                                        
                                    </div> {/* <!-- / .row --> */}


                                    <div className="form-group">
                                        <div className="float-right">
                                            <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                                        </div>
                                    </div>

                                    <br /><br />
                                </form>
                                </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonProfilDetails