import React from 'react'

/**
 * 
 * @param {*} label 
 * @param {*} onSubmit 
 * @returns 
 */
const SubmitForm = ( { 
  label, 
  isSticky = true,
  icon = "save", 
  onSubmit,
  id,
  backgroundColor = "btn-primary",
} ) => {

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(e)
  }

  return (
    <div className="form-group">
      <div className="float-right">
        <button type="submit"
          id={id}
          className={isSticky ? `btn ${backgroundColor} mb-2 sticky-submit` : `btn ${backgroundColor} mb-2`} 
          onClick={handleSubmit} 
          onSubmit={handleSubmit}>
            {icon && <i className={`fe fe-${icon}`} style={{marginRight:"5px"}}></i>}  
            {label}
        </button>
      </div>
    </div>
  )
}

export default SubmitForm