import React from 'react'

const UnProjetRentabiliteFooter = ({ step, onStep, onSubmit }) => {

  const minStep = 1
  const maxStep = 6

  const handleNextStep = () => {
    let tmpStep = step +1
    if( tmpStep >= maxStep) tmpStep = maxStep
    onStep(tmpStep)
  }

  const handlePrevStep = () => {
    let tmpStep = step -1
    if( tmpStep <= minStep) tmpStep = minStep
    onStep(tmpStep)
  }

  return <>
    <div className="row align-items-center">
      {step > minStep &&
        <div className="col-auto">
          <button className="btn btn-lg btn-primary" onClick={handlePrevStep}>Précédent</button>
        </div>
      }


      {step < maxStep &&
        <div className="col text-center">
          <h6 className="text-uppercase text-muted mb-0">Étape {step} sur {maxStep - 1}</h6>
        </div>
      }

      {step >= maxStep &&
        <div className="col text-center">
          <h6 className="text-uppercase text-muted mb-0">Validez</h6>
        </div>
      }

      {step < maxStep &&
        <div className="col-auto">
          <button className="btn btn-lg btn-primary" onClick={handleNextStep}>Suivant</button>
        </div>
      }

      {step === maxStep &&
        <div className="col-auto">
          <button className="btn btn-lg btn-primary" onClick={onSubmit}>Terminer</button>
        </div>
      }
      </div>
  </>
}

export default UnProjetRentabiliteFooter