import React from 'react'

class EvolutionTauxEndettement extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      revenusMensuels: null,
      revenusFonciersActuels: null,
      revenusFonciersFutursMeuble: null,
      mensualiteLieeALinvestissement:null,
      autrePretsMensualite:null,
      tauxEndettementActuel:null,
      tauxEndettementFutur:null,
    };
  }

  handleChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const valueAsNumber = target.valueAsNumber;
    const name = target.name;

  
   await this.setState({
      [name]:  valueAsNumber || parseFloat(value)
    });

    const mensualite = this.state.mensualiteLieeALinvestissement;
    const tauxActuel = Number((Number(this.state.autrePretsMensualite))/Number(Number(this.state.revenusMensuels) + Number(this.state.revenusFonciersActuels)*0.7)*100).toFixed(2);
    const tauxFutur = Number((Number(this.state.autrePretsMensualite) + Number(mensualite)) / (Number(this.state.revenusMensuels) + Number(this.state.revenusFonciersActuels) + Number(this.state.revenusFonciersFutursMeuble))*100).toFixed(2);
    
    this.setState({
      tauxEndettementFutur: Number(tauxFutur),
    });
    
    
    this.setState({
      tauxEndettementActuel: Number(tauxActuel),
    });

    if(typeof this.props.onChange === 'function') this.props.onChange(this.state);

  }



async componentDidUpdate(prevProps) {
 
  if ((this.props.leRevenus !== prevProps.leRevenus) || (this.props.leCredit !== prevProps.leCredit)) {

    const revenusFonciersFutursMeuble = this.props.leRevenus.revenusTotalTousLogementsLDMeuble;

    await this.setState({
      revenusFonciersFutursMeuble: Number((revenusFonciersFutursMeuble * 0.7).toFixed(2)),
    });

    await this.setState({
      mensualiteLieeALinvestissement:this.props.leCredit.mensualite,
    })

    const mensualite = this.state.mensualiteLieeALinvestissement;
    const tauxActuel = Number((Number(this.state.autrePretsMensualite))/Number(Number(this.state.revenusMensuels) + Number(this.state.revenusFonciersActuels)*0.7)*100).toFixed(2);
    const tauxFutur = Number((Number(this.state.autrePretsMensualite) + Number(mensualite)) / (Number(this.state.revenusMensuels) + Number(this.state.revenusFonciersActuels) + Number(this.state.revenusFonciersFutursMeuble))*100).toFixed(2);
    
    this.setState({
      tauxEndettementFutur: Number(tauxFutur),
    });
    
    
    this.setState({
      tauxEndettementActuel: Number(tauxActuel),
    });

    
  
}
}


  render(){

    return (<>

<div className="col-12 mt-4">
                  <h2 style={{ textDecoration: "underline", fontWeight: "800"}}>
                    ÉVOLUTION DU TAUX D'ENDETTEMENT:
                  </h2>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Revenus Mensuel Net</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                      name="revenusMensuels"
                        type="number"
                        className="form-control"
                        value={this.state.revenusMensuels}
                        onChange={this.handleChange}
                        placeholder="5000"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Revenus Fonciers Actuels</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="revenusFonciersActuels"
                        value={this.state.revenusFonciersActuels}
                        onChange={this.handleChange}
                        placeholder="130"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Revenus Fonciers Futurs Meublé À l'Année (avec Règle 70%)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="revenusFonciersFutursMeuble"
                        value={this.state.revenusFonciersFutursMeuble}
                        readOnly
                        placeholder="130"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Autre(s) prêt(s) (mensualités)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="autrePretsMensualite"
                        value={this.state.autrePretsMensualite}
                        onChange={this.handleChange}
                        placeholder="130"
                      />
                    </div>
                  </div>
                </div>
                

                <div className="col-4">
                  <div className="form-group">
                    <label>Taux d'endettement actuel</label>
                    <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="tauxEndettementActuel"
                        value={this.state.tauxEndettementActuel}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Taux d'endettement futur</label>
                    <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="tauxEndettementFutur"
                        value={this.state.tauxEndettementFutur}
                        readOnly
                      />
                      {(this.state.tauxEndettementFutur > 33) ? <p style={{"color":"red"}}><b>ATTENTION:</b> Votre taux d'endettement futur est supérieur à 33%</p> : null}
                    </div>
                  </div>
                </div>


    </>)

}
}


export default EvolutionTauxEndettement;