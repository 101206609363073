// TODO : J'aime pas cette solution de || 
const chooseOr = (field1, field2) => {
  // Pour gérer le cas où on vide le champ manuellement juste après que la page soit chargée
  if(field1 === 0) return 0

  return field1 || field2
}

const cleanValues = (values) => {
  for(const [ key, value ] of Object.entries(values)) {
    if( !value || value === "" ) values[key] = 0
  }
}

// TOOL
export const rentabiliteExists = (projet) => {
  if( !projet || !projet.rentabilite ) return false

  return projet.rentabilite.infos_generales.frais_notaire
    ||  projet.rentabilite.credit
    ||  projet.rentabilite.revenus
    ||  projet.rentabilite.charges
    ||  projet.rentabilite.endettement
}

// INFOS GENERALES
export const convertInfosGeneralesReduxToComponent = (projet) => {
  return {
    surface:        projet.surface_interieur,
    coutAchat:      projet.prix_objectif || projet.prix_negocie || projet.prix_depart,
    fraisNotaires:  chooseOr(projet['rentabilite.infos_generales.frais_notaires'], projet.rentabilite?.infos_generales?.frais_notaires),
    typedetravaux:  chooseOr(projet['rentabilite.infos_generales.type_travaux'], projet.rentabilite?.infos_generales?.type_travaux),
    coutMeubles:    chooseOr(projet['rentabilite.infos_generales.cout_meubles'], projet.rentabilite?.infos_generales?.cout_meubles)
  }
}

export const dispatchInfosGeneralesComponentDataToRedux = ({ dispatch, updateByField, values }) => {

  cleanValues(values)

  dispatch(updateByField({ field: 'surface_interieur', value: values.surface }))
  dispatch(updateByField({ field: 'prix_objectif', value: values.coutAchat }))
  dispatch(updateByField({ field: 'rentabilite.infos_generales.frais_notaires', value: values.fraisNotaires }))
  dispatch(updateByField({ field: 'rentabilite.infos_generales.type_travaux', value: values.typedetravaux }))
  dispatch(updateByField({ field: 'rentabilite.infos_generales.cout_meubles', value: values.coutMeubles }))
}

// CREDIT
export const convertCreditReduxToComponent = (projet) => {
  return {
    apportPersonnel:    chooseOr(projet['rentabilite.credit.apport_personnel'], projet.rentabilite?.credit?.apport_personnel),
    fraisDossier:       chooseOr(projet['rentabilite.credit.frais_dossier'], projet.rentabilite?.credit?.frais_dossier),
    fraisGarantie:      chooseOr(projet['rentabilite.credit.frais_garantie'], projet.rentabilite?.credit?.frais_garantie),
    dureeDiffere:       chooseOr(projet['rentabilite.credit.duree_differe'], projet.rentabilite?.credit?.duree_differe),
    dureeTotaleCredit:  chooseOr(projet['rentabilite.credit.duree_totale_credit'], projet.rentabilite?.credit?.duree_totale_credit),
    tauxHorsAssurance:  chooseOr(projet['rentabilite.credit.taux_hors_assurrance'], projet.rentabilite?.credit?.taux_hors_assurrance),
    tauxAssurance:      chooseOr(projet['rentabilite.credit.taux_assurance'], projet.rentabilite?.credit?.taux_assurance)
  }
}

export const dispatchCreditComponentDataToRedux = ({ dispatch, updateByField, values }) => {
  
  cleanValues(values)

  dispatch(updateByField({ field: 'rentabilite.credit.apport_personnel', value: values.apportPersonnel }))
  dispatch(updateByField({ field: 'rentabilite.credit.frais_dossier', value: values.fraisDossier }))
  dispatch(updateByField({ field: 'rentabilite.credit.frais_garantie', value: values.fraisGarantie }))
  dispatch(updateByField({ field: 'rentabilite.credit.duree_differe', value: values.dureeDiffere }))
  dispatch(updateByField({ field: 'rentabilite.credit.duree_totale_credit', value: values.dureeTotaleCredit }))
  dispatch(updateByField({ field: 'rentabilite.credit.taux_hors_assurrance', value: values.tauxHorsAssurance }))
  dispatch(updateByField({ field: 'rentabilite.credit.taux_assurance', value: values.tauxAssurance }))
}

// REVENUS
export const convertRevenusReduxToComponent = (projet) => {
  let logements = []
  try {
    logements = JSON.parse( projet['rentabilite.revenus.logements'] || projet.rentabilite?.revenus?.logements )
  } catch(e) {}
  if( !Array.isArray(logements) ) logements = []

  let nombre_logements = projet['rentabilite.revenus.nombre_logements'] || projet.rentabilite?.revenus?.nombre_logements
  if( typeof nombre_logements !== 'number' ) nombre_logements = 0

  return {
    nombreLogements: nombre_logements,
    logementsData: logements,
  }
}

export const dispatchRevenusComponentDataToRedux = ({ dispatch, updateByField, values }) => {
  dispatch(updateByField({ field: 'rentabilite.revenus.nombre_logements', value: values.nombreLogements }))
  dispatch(updateByField({ field: 'rentabilite.revenus.logements', value: JSON.stringify(values.logementsData) }))
}

// CHARGES
export const convertChargesReduxToComponent = (projet) => {
  return {
    assurancePNO:                 chooseOr(projet['rentabilite.charges.assurance_pno'],projet.rentabilite?.charges?.assurance_pno),
    tauxCommissionPlateforme:     chooseOr(projet['rentabilite.charges.taux_parteformes'],projet.rentabilite?.charges?.taux_parteformes),
    fraisPressing:                chooseOr(projet['rentabilite.charges.pressing'],projet.rentabilite?.charges?.pressing),
    taxeFonciere:                 chooseOr(projet['rentabilite.charges.taxe_fonciere'],projet.rentabilite?.charges?.taxe_fonciere),
    chargesCopro:                 chooseOr(projet['rentabilite.charges.charges_copropriete'],projet.rentabilite?.charges?.charges_copropriete),
    assuranceLoyersImpayes:       chooseOr(projet['rentabilite.charges.taux_assurance_loyers_impayes'],projet.rentabilite?.charges?.taux_assurance_loyers_impayes),
    previsionTravaux:             chooseOr(projet['rentabilite.charges.prevision_travaux'],projet.rentabilite?.charges?.prevision_travaux),
    expertComptable:              chooseOr(projet['rentabilite.charges.expert_comptable'],projet.rentabilite?.charges?.expert_comptable),
    fraisGerance:                 chooseOr(projet['rentabilite.charges.taux_frais_gerance'],projet.rentabilite?.charges?.taux_frais_gerance),
    fraisDivers:                  chooseOr(projet['rentabilite.charges.autres_frais'],projet.rentabilite?.charges?.autres_frais),
    tauxConciergerie:             chooseOr(projet['rentabilite.charges.taux_concieregerie'],projet.rentabilite?.charges?.taux_concieregerie),
    fraisElectricite:             chooseOr(projet['rentabilite.charges.electricite'],projet.rentabilite?.charges?.electricite),
    fraisEau:                     chooseOr(projet['rentabilite.charges.eau'],projet.rentabilite?.charges?.eau),
    fraisPersonnelMenage:         chooseOr(projet['rentabilite.charges.frais_personnel_menage'],projet.rentabilite?.charges?.frais_personnel_menage),
    fraisInternet:                chooseOr(projet['rentabilite.charges.internet'],projet.rentabilite?.charges?.internet),
    fraisNetflixEtAbonnement:     chooseOr(projet['rentabilite.charges.netflix'],projet.rentabilite?.charges?.netflix),
    fraisProduitsPerissable:      chooseOr(projet['rentabilite.charges.produits_perrisables'],projet.rentabilite?.charges?.produits_perrisables),
    fraisLogiciels:               chooseOr(projet['rentabilite.charges.logiciels'],projet.rentabilite?.charges?.logiciels),
  }
}

export const dispatchChargesComponentDataToRedux = ({ dispatch, updateByField, values }) => {

  cleanValues(values)

  dispatch(updateByField({ field: 'rentabilite.charges.assurance_pno', value: values.assurancePNO }))
  dispatch(updateByField({ field: 'rentabilite.charges.taux_parteformes', value: values.tauxCommissionPlateforme }))
  dispatch(updateByField({ field: 'rentabilite.charges.pressing', value: values.fraisPressing }))
  dispatch(updateByField({ field: 'rentabilite.charges.taxe_fonciere', value: values.taxeFonciere }))
  dispatch(updateByField({ field: 'rentabilite.charges.charges_copropriete', value: values.chargesCopro }))
  dispatch(updateByField({ field: 'rentabilite.charges.taux_assurance_loyers_impayes', value: values.assuranceLoyersImpayes }))
  dispatch(updateByField({ field: 'rentabilite.charges.prevision_travaux', value: values.previsionTravaux }))
  dispatch(updateByField({ field: 'rentabilite.charges.expert_comptable', value: values.expertComptable }))
  dispatch(updateByField({ field: 'rentabilite.charges.taux_frais_gerance', value: values.fraisGerance }))
  dispatch(updateByField({ field: 'rentabilite.charges.autres_frais', value: values.fraisDivers }))
  dispatch(updateByField({ field: 'rentabilite.charges.taux_concieregerie', value: values.tauxConciergerie }))
  dispatch(updateByField({ field: 'rentabilite.charges.electricite', value: values.fraisElectricite }))
  dispatch(updateByField({ field: 'rentabilite.charges.eau', value: values.fraisEau }))
  dispatch(updateByField({ field: 'rentabilite.charges.frais_personnel_menage', value: values.fraisPersonnelMenage }))
  dispatch(updateByField({ field: 'rentabilite.charges.internet', value: values.fraisInternet }))
  dispatch(updateByField({ field: 'rentabilite.charges.netflix', value: values.fraisNetflixEtAbonnement }))
  dispatch(updateByField({ field: 'rentabilite.charges.produits_perrisables', value: values.fraisProduitsPerissable }))
  dispatch(updateByField({ field: 'rentabilite.charges.logiciels', value: values.fraisLogiciels }))
}

// ENDETTEMENT
export const convertEndettementReduxToComponent = (projet) => {
  return {
    revenusMensuels:        chooseOr(projet['rentabilite.endettement.revenus_mensuels_nets'],projet.rentabilite?.endettement?.revenus_mensuels_nets),
    revenusFonciersActuels: chooseOr(projet['rentabilite.endettement.revenus_fonciers_actuels'],projet.rentabilite?.endettement?.revenus_fonciers_actuels),
    autrePretsMensualite:   chooseOr(projet['rentabilite.endettement.mensualite_autres_prets'],projet.rentabilite?.endettement?.mensualite_autres_prets),
  }
}

export const dispatchEndettementComponentDataToRedux = ({ dispatch, updateByField, values }) => {
  cleanValues(values)

  dispatch(updateByField({ field: 'rentabilite.endettement.revenus_mensuels_nets', value: values.revenusMensuels }))
  dispatch(updateByField({ field: 'rentabilite.endettement.revenus_fonciers_actuels', value: values.revenusFonciersActuels }))
  dispatch(updateByField({ field: 'rentabilite.endettement.mensualite_autres_prets', value: values.autrePretsMensualite }))
}