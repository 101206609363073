import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import SelectBanque from '../../toolsComponents/SelectBanque'
import SelectProjet from '../../toolsComponents/SelectProjet'

const NouvelleOffrePret = () => {

    return (
        <div>

            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        
                        <div className="col-12 col-lg-10 col-xl-8">
                            {/* <!-- HEADER --> */}
                            <div className="header">

                                {/* <!-- Body --> */}
                                <div className="header-body">
                                    <div className="row align-items-center">

                                        <div className="col ml-n3 ml-md-n2">

                                            {/* <!-- Pretitle --> */}
                                            <h6 className="header-pretitle">
                                                Nouveau
                                            </h6>

                                            {/* <!-- Title --> */}
                                            <h1 className="header-title">
                                                Offre de prêt
                                            </h1>

                                        </div>
                                    
                                    </div> {/* <!-- / .row --> */}

                                </div>

                                
                                </div>

                            <div className="row">

                                {/* <!-- FORMULAIRE --> */}
                                <div className="col-12 col-xl-12">

                                    <form className="mb-4">

                                        {/* Banque */}
                                        <SelectBanque />

                                        {/* Projet */}
                                        <SelectProjet />

                                        {/* <!-- Date de l'offre --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Date de l'offre
                                                    </label>

                                                    <input type="text" className="form-control" value="" />

                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Status de l'offre
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <select className="form-control">
                                                        <option>Accepté</option>
                                                        <option selected>En attente</option>
                                                        <option>Refusé</option>
                                                    </select>

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        <hr />

                                        {/* <!-- Montant du prêt et durée --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Montant du prêt
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Durée du prêt
                                                    </label>

                                                    <input type="text" className="form-control" value="" />

                                                </div>

                                            </div>
                                            
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Taux d'intérêt et taux d'assurance --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Taux d'intérêt
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">%</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Taux d'assurance
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">%</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                                </div>
                                            
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Type de taux --> */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Type de taux d'intérêt
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <select className="form-control">
                                                        <option></option>
                                                        <option selected>Fixe</option>
                                                        <option>Variable</option>
                                                    </select>

                                                </div>

                                                </div>
                                            
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Mensualité et mensualité assurance --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Mensualité
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Dont mensualité assurance
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        <hr />

                                        {/* <!-- Cout des intérêts et de l'assurance --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Cout des intérêts
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Cout de l'asurrance
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Garantie et frais de garantie --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Garantie
                                                    </label>

                                                    <input type="text" className="form-control" value="" />

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Frais de garantie
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- TEAG et différé --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        TEAG estimé
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">%</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Différé
                                                    </label>

                                                    <input type="text" className="form-control" value="" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Frais de dossier et autres frais --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Frais de dossier bancaire
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Autre frais
                                                    </label>

                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="" />
                                                    </div>

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        <div className="form-group">
                                            <div className="float-right">
                                                {/* <button type="submit" className="btn btn-primary mb-2">Ajouter</button> */}
                                                <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/offres-prets" className="btn btn-primary ml-2">
                                                    Ajouter
                                                </a>
                                            </div>
                                        </div>

                                        <br /><br />
                                    </form>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NouvelleOffrePret