import React from 'react'

const SelectBanque = () => {

  return (
    <div className="form-group">
      <label htmlFor="exampleFormControlSelect1">
        Banque
      </label>

      <select className="form-control" id="exampleFormControlSelect1">
        <option>Crédit Agricole Du Languedoc</option>
        <option>Caisse D'Epargne Mende Arnault</option>
        <option>LCL Banque et Assurance</option>
        <option>Banque Postale</option>
        <option selected>Crédit Mutuel</option>
        <option>Société Générale</option>
        <option>Banque Populaire du Sud</option>
        <option>BNP Paribas</option>
      </select>
    </div>
  )
}

export default SelectBanque