import React from 'react'
import defined from '../../ressources/defined'
import ContactsCardItem from './ContactsCardItem'

const ContactsCard = ( { contacts } ) => {

  return (
    <div>
      {/* <!-- MODAL AJOUTER UN CONTACT --> */}
      <div className="modal fade" id="modalNewContact" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Ajouter un contact</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body"> 
              <form>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">Nom du contact</label>
                  <input type="text" className="form-control" id="recipient-name" />
                </div>

                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">Adresse email</label>
                  <input type="email" className="form-control" id="recipient-name" />
                </div>

                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">Numéro de téléphone</label>
                  <input type="phone" className="form-control" id="recipient-name" />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlFile1">Photo de profil</label>
                  <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                </div>
              </form>
            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                <button type="button" className="btn btn-success">Ajouter</button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- MODAL MODIFIER UN CONTACT --> */}
      <div className="modal fade" id="modalEditContact" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Modifier un contact</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">Nom du contact</label>
                  <input type="text" className="form-control" id="recipient-name" value="Guillaume COURTINES"/>
                </div>

                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">Adresse email</label>
                  <input type="email" className="form-control" id="recipient-name" value="g.courtines@creditagricole.com"/>
                </div>

                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">Numéro de téléphone</label>
                  <input type="phone" className="form-control" id="recipient-name" value="(+33) 4 99 53 52 41"/>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlFile1">Photo de profil</label>
                  <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="button" className="btn btn-success">Modifier</button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- MODAL SUPPRIMER UN CONTACT --> */}
      <div className="modal fade" id="modalDelContact" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Supprimer un contact</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <p>
                Etes-vous sûr de vouloir supprimer le contact : Guillaume COURTINES ?
              </p>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="button" className="btn btn-danger">Supprimer</button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
            {/* <!-- Title --> */}
            <h4 className="card-header-title">
                Contacts
            </h4>
        </div>

        <div className="card-body">
          <div className="list-group list-group-flush my-n3">
            {contacts && contacts.map((c, index) => {
              return <ContactsCardItem 
                key={index}
                contact={c}
              />
            })}
                                                            
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col-12" style={{textAlign:'center'}}>
                  <a  href={defined.ANCHOR} data-toggle="modal" data-target="#exampleModalNewContact">Ajouter un contact</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactsCard