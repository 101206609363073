import React, { useEffect, useState } from 'react'
import UnProjetPhotosCategory from './UnProjetPhotosCategory'

const UnProjetPhotos = ({ projet, onSubmitPhoto, onDeletePhoto, onSubmitCategorie }) => {

  const [ cleanPhotos, setCleanPhotos ] = useState([])

  useEffect(() => {
    if( !projet || !projet.photos ) return 

    const tmpCats = []

    for(const photo of projet.photos) {
      if( !tmpCats.includes(photo.categorie) ) tmpCats.push(photo.categorie)
    }

    const result = []
    for(const cat of tmpCats) {
      const p = { categorie: cat, photos: []}

      for(const photo of projet.photos) { 
        if( photo.categorie === cat ) {
          p.photos.push(photo)
        }
      }

      result.push(p)
    }

    setCleanPhotos(result)

  }, [projet])

  return (
    <>
      {cleanPhotos.map((c) => {
        return <UnProjetPhotosCategory 
          completeCategory={c} 
          onSubmitPhoto={onSubmitPhoto} 
          onDeletePhoto={onDeletePhoto}
          onSubmit={onSubmitCategorie}
        />
      })}
    </>
  )
}

export default UnProjetPhotos