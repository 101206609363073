import React from 'react'

class InformationsGenerales extends React.Component {

  constructor(props){
    super(props);
    this.state = {

      prixMoyenM2:2000,
      lcd: true,
      ldm: true, 
      ldnue: true,
      surface: null,
      coutAchat: null,
      fraisNotaires: 0,
      fraisNotairesInputed:false,
      typedetravaux:150,
      coutTravaux:0,
      coutMeubles:null,
      coutTotalm2: 0,
      coutTotalReno: 0,
      coutTotalProjet: 0,

    };
  }

  handleChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const valueAsNumber = target.valueAsNumber;
    const name = target.name;

  
   await this.setState({
      [name]:  valueAsNumber || value
    });


    if(name === "fraisNotaires"){
      this.state.fraisNotairesInputed = true;
    }

    if(!this.state.fraisNotairesInputed && name !== "fraisNotaires"){
    this.setState({
      fraisNotaires: (this.state.coutAchat * 7.5/100).toFixed(2),
    })
  }

    this.setState({
      coutTravaux: this.state.surface === null ? 0 : this.state.surface * this.state.typedetravaux,
    })

    this.setState({
      coutTotalProjet: Number(this.state.coutAchat) + Number(this.state.coutTravaux) + Number(this.state.fraisNotaires) + Number(this.state.coutMeubles),
    })

    this.setState({
      coutTotalm2: this.state.surface === null || this.state.surface === 0 ? 0 : Number(((Number(this.state.coutTravaux) + Number(this.state.coutAchat) + Number(this.state.fraisNotaires)) / (this.state.surface)).toFixed(2))
    })

    this.setState({
      coutTotalReno: this.state.surface === null || this.state.surface === 0 ? 0 : Number((this.state.coutTotalProjet - this.state.coutMeubles).toFixed(2))
    })



    if(typeof this.props.onChange === 'function') this.props.onChange(this.state)

  }

render(){
    return (<>

<div className="col-12">
                  <h2 style={{ textDecoration: "underline", fontWeight: "800"}}>
                    INFORMATIONS GÉNÉRALES DU PROJET ENVISAGÉ:
                  </h2>
                </div>

                {/* <div className="col-12">
                  <div className="form-group">
                    <p>Quel(s) mode d'exploitation envisagez-vous?</p>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="lcd"
                        name="lcd"
                        checked={this.state.lcd}
                        onChange={this.handleChange}
                      />
                      <label className="form-check-label" htmlFor="lcd">
                        Location Courte Durée
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="ldm"
                        name="ldm"
                        checked={this.state.ldm}
                        onChange={this.handleChange}
                      />
                      <label className="form-check-label" htmlFor="ldm">
                        Location Meublée Longue Durée
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="ldnue"
                        name="ldnue"
                        checked={this.state.ldnue}
                        onChange={this.handleChange}
                      />
                      <label className="form-check-label" htmlFor="ldnue">
                        Location Nue Longue Durée
                      </label>
                    </div>
                  </div>
                </div> */}
                <div className="col-4">
                  <div className="form-group">
                    <label>Surface du bâti</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">m²</div>
                      </div>
                      <input
                        name="surface"
                        type="number"
                        className="form-control"
                        value={this.state.surface}
                        onChange={this.handleChange}
                        placeholder="ex: 35"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Coût d'achat du projet</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€</div>
                      </div>
                      <input
                        
                        type="number"
                        className="form-control"
                        name="coutAchat"
                        value={this.state.coutAchat}
                        onChange={this.handleChange}
                        placeholder="ex: 110000"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Frais de notaires estimés</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisNotaires"
                        value={this.state.fraisNotaires}
                        onChange={this.handleChange}
                        placeholder="ex: 7000"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="typedetravaux">
                      Types de travaux à réaliser
                    </label>

                    <select
                      className="form-control"
                      id="typedetravaux"
                      name="typedetravaux"
                        value={this.state.typedetravaux}
                        onChange={this.handleChange}
                    >
                      <option value="0">Besoin de rien 0€/m²</option>
                      <option value="150">
                        Petits Rafraichissements 100-200€/m²
                      </option>
                      <option value="250">Petits Rafraichissements 200-300€/m²</option>
                      <option value="500">Petites Rénovations 400-600€/m²</option>
                      <option value="800">Grandes rénovations 700-900€/m²</option>
                      <option value="1000">Tout refaire de A à Z, Division, etc... 900€-1100/m²</option>
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Coût des travaux approximatif</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="coutTravaux"
                        value={(isNaN(this.state.coutTravaux) ? 0 : this.state.coutTravaux )+ " €"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Coût Total de Achat + Rénovation</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="coutTotalReno"
                        value={(isNaN(this.state.coutTotalReno) ? 0 : this.state.coutTotalReno) + " €"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Coût Total Achat + Rénovation / m²</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="coutTotalProjetParM2"
                        value={(isNaN(this.state.coutTotalm2) ? 0 : this.state.coutTotalm2) + " €/m²"}
                        readOnly
                      />
                    {/* {this.state.coutTotalm2 > this.state.prixMoyenM2 ? <p style={{"color":"red"}}><b>ATTENTION:</b> Le prix au m² est supérieur au prix au m² moyen de la ville que vous avez sélectionnée</p> : ""} */}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Coût des meubles</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="coutMeubles"
                        value={this.state.coutMeubles}
                        onChange={this.handleChange}
                        placeholder="ex: 110000"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Coût Total du Projet</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="coutTotalProjet"
                        value={(isNaN(this.state.coutTotalProjet) ? 0 : this.state.coutTotalProjet) + " €"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

    </>)
}
}


export default InformationsGenerales;