import React from 'react'

const UneBanqueHeader = ({ isActive, banque }) => {

  return (
    <div>
      {/* <!-- HEADER --> */}
      <div className="header">
        <div className="container-fluid">
          {/* <!-- Header Body --> */}
          <div className="header-body">
            <div className="row align-items-center">
              {/* <!-- Avatar --> */}
              {banque.url_avatar && 
                <div className="col-auto">
                    <div className="avatar avatar-lg avatar-4by3">
                        <img src={banque.url_avatar} alt="" className="avatar-img rounded" />
                    </div>
                </div>
              }

              <div className="col ml-n3 ml-md-n2">
                {/* <!-- Pretitle --> */}
                <h6 className="header-pretitle">
                  Détails
                </h6>

                {/* <!-- Title --> */}
                <h1 className="header-title">
                  {banque.nom}
                </h1>
              </div>
            </div> 
            
            <div className="row align-items-center">
              <div className="col">
                {/* <!-- Nav --> */}
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <a href={"/banques/"+banque._id} className={ !isActive ? 'nav-link active' : 'nav-link' } >
                      Général
                    </a>
                  </li>
                  
                  <li className="nav-item">
                    <a href={"/banques/"+banque._id+"/offres-de-pret"} className={ isActive === 'offres-de-pret' ? 'nav-link active' : 'nav-link' }>
                      Offres de prêt
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UneBanqueHeader