import React, { useRef } from 'react'
import Login from './Login'
import { useDispatch, useSelector } from 'react-redux'
import { performLogin, loginStatusSelector, loginDatasSelector } from './loginSlice'
import { useCookies } from 'react-cookie'
import { Redirect } from 'react-router'
import SimpleLoading from '../../../toolsComponents/SimpleLoading'
import { useState } from 'react'
import { useEffect } from 'react'

const LoginWithStore = () => {

  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(loginStatusSelector)
  const { token } = useSelector(loginDatasSelector)

  const [ email, setEmail ] = useState('')
  const [ password, setPasssword ] = useState('')

  // Manage cookies
  const [ cookies, setCookies ] = useCookies(['token', 'email', 'granted_modules'])

  const [ redirectTo, setRedirectTo ] = useState(null)

  // Handlers
  const handleLogin = (email, password) => {
    dispatch(performLogin({email, password}, (values) => {
      setCookies('token', values.token, { path: '/' })
      setCookies('email', values.email, { path: '/' })
      setCookies('granted_modules', values.granted_modules, { path: '/' })
    }))
  }

  useEffect(() => {
    if( cookies.granted_modules === 'NONE' )
      setRedirectTo('/profil')
    else if( cookies.granted_modules)
      setRedirectTo('/dashboard')
  }, [cookies])

  return (
    <div className="row justify-content-center" style={{paddingTop: "64px"}} >
      <div className="col-12 col-md-5 col-xl-3 my-5">
          {/* Login form display */}
          {(!token && !cookies.token && !loading) &&
            <Login 
              onLogin={handleLogin} 
              email={email}
              password={password}
              onEmailChanged={setEmail}
              onPasswordChanged={setPasssword}
            />
          }

          {/* Loading display */}
          {loading &&
            <SimpleLoading />
          }

          {/* Error display */}
          {hasError &&
            <div className="alert alert-danger" role="alert">
                {errorMessage}
            </div>
          }

          {/* Redirection */}
          {redirectTo &&
            <Redirect to={redirectTo} />
          }
      </div>
    </div>
  )
}

export default LoginWithStore