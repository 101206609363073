import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import ComparatifProjetEtudeCardFourchette from './ComparatifProjetEtudeCardFourchette'
import ComparatifProjetEtudeCardGauge from './ComparatifProjetEtudeCardGauge'
import { countTotalVentes } from '../../../features/uneEtude/ValeursFoncieres/uneEtudeValeursFoncieresTools'
import SimpleWarning from '../../SimpleWarning'
import defined from '../../../ressources/defined'
import InputForm from '../../forms/InputForm'


const ComparatifProjetEtude = ({ projet, etude }) => {

  const [ isManual, setIsManual ] = useState(false)

  const [ typeBien, setTypeBien ] = useState('appartements')
  const [ annee, setAnnee ] = useState(2021)
  const [ ventesAnnee, setVentesAnnee ] = useState(0)
  const [ totalVentes, setTotalVentes ] = useState(0)

  useEffect(() => {
    const tmpAnnee = etude.valeurs_foncieres[typeBien].filter(v => v.annee === 2021)[0].global.nb_transactions >= defined.YEAR_SALES_THRESHOLD ? 2021 : 2020
    setAnnee(tmpAnnee)

    setVentesAnnee(etude.valeurs_foncieres[typeBien].filter(v => v.annee === tmpAnnee)[0].global.nb_transactions)
    setTotalVentes(countTotalVentes(etude.valeurs_foncieres[typeBien]))

    setIsManual(ventesAnnee < defined.YEAR_SALES_THRESHOLD && etude.prix_achat_m2 && etude.prix_achat_m2_bas && etude.prix_achat_m2_haut)
  }, [etude.valeurs_foncieres, typeBien, etude.prix_achat_m2, etude.prix_achat_m2_bas, etude.prix_achat_m2_haut, ventesAnnee])

  useEffect(() => {
    if(projet.type_bien === 'maison') setTypeBien('maisons')
    else setTypeBien('appartements')
  }, [projet.type_bien])

  return (
    <>
      <div className="card">
        <div className="card-header">
          {/* <!-- Title --> */}
          <h4 className="card-header-title">
            Comparaison du bien par rapport au marché
          </h4>
        </div>

        <div className="card-body">
          {/* Il n'y a pas de valeurs foncières du tout pour cette ville */}
          {(!etude.valeurs_foncieres || totalVentes === 0) &&
            <SimpleWarning>
              Malheureusement il n'est pas possible de calculer automatiquement de valeurs foncières pour cette ville. Les notaires de cette région ne fournissent pas les informations dans le système informatisé de l'INSEE.
            </SimpleWarning>
          } 

          {/* Il n'y a pas assez de valeurs foncières cette année */}
          {etude.valeurs_foncieres && totalVentes > 0 && ventesAnnee < defined.YEAR_SALES_THRESHOLD &&
            <SimpleWarning>
              Malheureusement il n'y a pas assez de transactions dans la base de l'INSEE pour cette ville. Il est donc impossible de comparer automatiquement les valeurs foncières.
            </SimpleWarning>
          }

          {/* Il n'y a pas assez de valeurs foncières et les champs manuel ne sont pas tous renseignés */}
          {ventesAnnee < defined.YEAR_SALES_THRESHOLD && (!etude.prix_achat_m2 || !etude.prix_achat_m2_bas || !etude.prix_achat_m2_haut) &&
            <SimpleWarning>
              Pour néanmoins avoir une comparaison de prix, vous pouvez <a href={`/etudes/${etude._id}/valeurs-foncieres`}>renseigner manuellement les valeurs foncières dans l'étude de marché.</a>
            </SimpleWarning>
          }
      
          {/** On a toutes les infos */}
          {((etude.valeurs_foncieres && totalVentes > 0 && ventesAnnee >= defined.YEAR_SALES_THRESHOLD) || isManual) &&
            <>
              {/** Affiche de manière statique les valeurs manuelle */}
              {isManual &&
                <>
                <div className="row">
                  <div className="col">
                    <InputForm 
                      label="Prix bas d'achat au m²"
                      inputName="prix_achat_m2_bas"
                      value={etude.prix_achat_m2_bas}
                      prepend="€ / m²"
                      type="number"
                      readOnly={true}
                    />
                  </div>
          
                  <div className="col">
                    <InputForm 
                      label="Prix d'achat moyen au m²"
                      inputName="prix_achat_m2"
                      value={etude.prix_achat_m2}
                      prepend="€ / m²"
                      type="number"
                      readOnly={true}
                    />
                  </div>
          
                  <div className="col">
                    <InputForm 
                      label="Prix haut d'achat au m²"
                      inputName="prix_achat_m2_haut"
                      value={etude.prix_achat_m2_haut}
                      prepend="€ / m²"
                      type="number"
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col' style={{textAlign:'center'}}>
                    <a href={`/etudes/${etude._id}/valeurs-foncieres`}>Vous pouvez mettre à jour les manuellement les valeurs foncières dans l'étude de marché.</a>
                    <br/>
                  </div>
                </div>
                </>
              }

              {/** Affiche les fourchettes et la moyenne */}
              {!isManual &&
                <ComparatifProjetEtudeCardFourchette
                  etude={etude}
                  annee={annee}
                  typeBien={typeBien}
                />
              }

              <br /><br />

              <div className='row'>
                <div className='col-4'>
                  <ComparatifProjetEtudeCardGauge
                    label="Prix affiché"
                    etude={etude}
                    surface={projet.surface_interieur}
                    prix={projet.prix_depart}
                    typeBien={typeBien}
                    prixTravaux={projet['rentabilite.infos_generales.type_travaux']}
                    annee={annee}
                    isManual={isManual}
                  />
                </div>

                <div className='col-4'>
                  <ComparatifProjetEtudeCardGauge
                    label="Prix négocié"
                    etude={etude}
                    surface={projet.surface_interieur}
                    prix={projet.prix_negocie}
                    typeBien={typeBien}
                    prixTravaux={projet['rentabilite.infos_generales.type_travaux']}
                    annee={annee}
                    isManual={isManual}
                  />
                </div>

                <div className='col-4'>
                  <ComparatifProjetEtudeCardGauge
                    label="Prix souhaité"
                    etude={etude}
                    surface={projet.surface_interieur}
                    prix={projet.prix_objectif}
                    typeBien={typeBien}
                    prixTravaux={projet['rentabilite.infos_generales.type_travaux']}
                    annee={annee}
                    isManual={isManual}
                  />
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col">
                  <h5 className='text-muted' style={{textAlign:'center'}}><em>Pourcentages par rapport au prix moyen</em></h5>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default ComparatifProjetEtude