import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import UnBienGenericWithStore from '../UnBienGenericWithStore'
import { unBienDatasSelector } from '../unBienSlice'
import UnBienGeneral from './UnBienGeneral'

const UnBienGeneralWithStore = ({ id }) => {

  const genericComponent = useRef(null)

  const bien = useSelector(unBienDatasSelector)

  return <>
    <UnBienGenericWithStore
        ref={genericComponent}
        activeMenu="general"
        id={id}
      >
        <UnBienGeneral 
          bien={bien}
          onChange={(e) => genericComponent.current.handleChange(e)}
          onSubmit={() => genericComponent.current.handleSubmit()}
        />
      </UnBienGenericWithStore>
  </>  
}

export default UnBienGeneralWithStore