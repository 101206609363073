import React from 'react'

const Checkbox = ({ label, inputName, chevron=false, isChecked, onChange }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: !isChecked, inputName})
  }

  return (
    <label class="checkboxcontainer">{label}
      <input type="checkbox" id={inputName} name={inputName} checked={isChecked} onClick={handleChange}/>
      <span class="checkmark"></span>
      {chevron && <span class="chevron fe fe-chevron-right font-weight-bold ml-2"></span>}
    </label>
  )
}

export default Checkbox