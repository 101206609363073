import React from "react";
import { useState } from "react";
import AddUnDocumentModal from "../../../toolsComponents/modals/AddUnDocumentModal";
import DeleteModal from "../../../toolsComponents/modals/DeleteModal";
import EditUnDocumentModal from "../../../toolsComponents/modals/EditUnDocumentModal";
import SimpleLoading from "../../../toolsComponents/SimpleLoading";
import MainTable from "../../../toolsComponents/tables/MainTable";

const UnBienDocuments = ({
  token,
  documents,
  pagination,
  filter,
  onPagination,
  onSearch,
  onSort,
  onAddDocument,
  onEditDocument,
  onDeleteDocument,
  onSelect,
  selected,
}) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleSelectDocument = (doc) => {
    setSelectedDocument(doc);
  };

  const handleMultiSelect = (checked, document) => {
    const action = checked ? "ADD" : "DEL";
    onSelect({ action, document });
    setIsAllSelected(false);
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setIsAllSelected(checked);
    const action = checked ? "ALL" : "EMPTY";
    onSelect({ action });
  };

  const handleSearch = (value, fields) => {
    onSearch(value, fields);
    setIsAllSelected(false);
  };

  const getMultiDownloadLabel = () => {
    if (selected?.length > 1)
      return `Télécharger les ${selected.length} documents sélectionnés`;
    else if (selected?.length === 1)
      return `Télécharger le document sélectionné`;
    else return "Aucun fichier sélectionné";
  };

  const handleMultiDownload = async () => {
    if (selected?.length > 0) {
      setDownloadLoading(true);

      const file_ids = [];
      for (const file of selected) {
        file_ids.push(file.url.split("/")[2]);
      }

      const reqOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        // body: JSON.stringify({"file_ids": ["633d73c384881f36fcc48c12", "639206edefe7c706823852c7"]}),
        body: JSON.stringify({
          file_ids,
        }),
      };

      const res = await fetch(
        process.env.REACT_APP_REST_API_LOCATION + "/files/multi-download",
        reqOptions
      );

      const blob = await res.blob();

      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Date.now()}.zip`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);

      setDownloadLoading(false);
    }
  };

  const datas = [];

  documents?.forEach((d) => {
    datas.push([
      {
        // Select box
        value: (
          <input
            type="checkbox"
            onChange={(e) => handleMultiSelect(e.target.checked, d)}
            checked={selected.includes(d)}
          />
        ),
      },
      {
        // Intitulé
        value: (
          <a
            href="#!"
            data-toggle="modal"
            data-target="#modal-edit-document"
            onClick={() => handleSelectDocument(d)}
          >
            <i className="fe fe-edit-2"></i>&nbsp;&nbsp;
            {d.titre}
          </a>
        ),
      },
      {
        // Bien
        value: d.bien_titre,
      },
      {
        // Catégory
        value: (
          <a href="#!" onClick={() => handleSearch(d.category, ["category"])}>
            <i className="fe fe-search"></i>&nbsp;&nbsp;
            {d.category}
          </a>
        ),
      },
      {
        // Sous Catégory
        value: (
          <a
            href="#!"
            onClick={() => handleSearch(d.subCategory, ["subCategory"])}
          >
            <i className="fe fe-search"></i>&nbsp;&nbsp;
            {d.subCategory}
          </a>
        ),
      },
      {
        // Télécharger
        value: (
          <>
            <i className="fe fe-download"></i>&nbsp;Télécharger
          </>
        ),
        url: `${process.env.REACT_APP_REST_API_LOCATION}${d.url}${token}`,
      },
      {
        // Supprimer
        value: (
          <a
            href="#!"
            data-toggle="modal"
            data-target="#modal-del-document"
            onClick={() => handleSelectDocument(d)}
          >
            <i className="fe fe-trash"></i>
          </a>
        ),
      },
    ]);
  });

  const headers = [
    {
      display_name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={isAllSelected}
        />
      ),
      field: "select",
      sortable: false,
      colSpan: 1,
    },
    {
      display_name: "Intitulé",
      field: "titre",
      sortable: true,
      colSpan: 1,
    },
    {
      display_name: "Bien",
      field: "bien_titre",
      sortable: true,
      colSpan: 1,
    },
    {
      display_name: "Catégorie",
      field: "category",
      sortable: true,
      colSpan: 1,
    },
    {
      display_name: "Sous-Catégorie",
      field: "subCategory",
      sortable: true,
      colSpan: 1,
    },
    {
      display_name: "Télécharger",
      field: "_id",
      sortable: false,
      colSpan: 2,
    },
    {
      display_name: "",
      field: "",
      sortable: false,
      colSpan: 1,
    },
  ];

  return (
    <>
      {downloadLoading && <SimpleLoading />}

      {!downloadLoading && (
        <>
          <AddUnDocumentModal onSubmit={onAddDocument} />

          <EditUnDocumentModal
            document={selectedDocument}
            onSubmit={onEditDocument}
          />

          <DeleteModal
            onDelete={() => onDeleteDocument(selectedDocument)}
            myId="modal-del-document"
          >
            <p>
              Êtes-vous sûr de vouloir supprimer définitivement ce document ?
              <br />
              <strong>{selectedDocument?.titre}</strong> (
              {selectedDocument?.bien_titre})
            </p>
          </DeleteModal>

          <div style={{ textAlign: "right" }}>
            <button
              class={`btn d-block d-md-inline-block lift ${
                selected?.length > 0 ? "btn-primary" : "btn-secondary"
              }`}
              style={{ marginBottom: "24px" }}
              onClick={handleMultiDownload}
            >
              <i class="fe fe-download"></i>&nbsp;
              {getMultiDownloadLabel()}
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="#!"
              class="btn btn-primary d-block d-md-inline-block lift"
              data-toggle="modal"
              data-target="#modal-add-document"
              style={{ marginBottom: "24px" }}
            >
              <i class="fe fe-file-plus"></i> Ajouter un document
            </a>
          </div>

          <MainTable
            headers={headers}
            datas={datas}
            loading={false}
            pagination={pagination}
            filter={filter}
            isDeletable={false}
            // withMutiSelect={true}
            onSort={onSort}
            onPagination={onPagination}
            onSearch={handleSearch}
          />
        </>
      )}
    </>
  );
};

export default UnBienDocuments;
