import React from 'react'

const ContactsCardItem = ( { contact } ) => {

  return (
    <div className="list-group-item">
      <div className="row align-items-center">
        {/* <!-- Avatar --> */}
        {contact.url_avatar &&
        <div className="col-auto">
          <span className="avatar">
          <img src={contact.url_avatar} alt="" className="avatar-img rounded-circle" />
          </span>
        </div>
        }

        <div className="col ml-n2">
          {/* <!-- Title --> */}
          <h4 className="mb-1">
            <span>{contact.nom}</span>
          </h4>

          {/* <!-- Email --> */}
          <p className="card-text small" style={{marginBottom:"0px"}}>
            <a href={"mailto:" + contact.email}>{contact.email}</a>
          </p>

          {/* <!-- Téléphone --> */}
          <p className="card-text small">
            <a href={"tel:" + contact.telephone}>{contact.telephone}</a>
          </p>
        </div>

        {/* <!-- Actions --> */}
        <div className="col-auto">
          {/* <!-- Dropdown --> */}
          <div className="dropdown">
              <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fe fe-more-vertical"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#modalEditContact">
                  <i className="fe fe-edit-3"></i> Modifier
                </a>
                  <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#modalDelContact">
                <i className="fe fe-trash-2"></i> Supprimer
                </a>
              </div>
          </div>
        </div>
      </div>
    </div>           
  )
}

export default ContactsCardItem