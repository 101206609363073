import React from "react";
import SimpleInfo from "../../../toolsComponents/SimpleInfo";
import SubmitForm from "../../../toolsComponents/forms/SubmitForm";

const DashboardSimulation = ({ id_projet, token, onGoToEdit }) => {
  return (
    <>
      <div className="row mx-5">
        <div className="col-12">
          <SimpleInfo>Votre descriptif est prêt à être généré !</SimpleInfo>
        </div>

        <div className="col-12 d-flex flex-row">
          <div className="mr-3">
            <a
              href={`${process.env.REACT_APP_REST_API_LOCATION}/generateurDescriptif/${id_projet}/${token}`}
              className="btn btn-outline-success mb-2"
            >
              <i className="fe fe-download" style={{ marginRight: "5px" }}></i>
              Télécharger mon descriptif
            </a>
          </div>

          <div>
            <SubmitForm
              id="edit-descriptif"
              label="Modifier les informations"
              isSticky={false}
              icon="edit"
              onSubmit={onGoToEdit}
              backgroundColor="btn-outline-primary"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSimulation;
