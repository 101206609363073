import React, { useState } from 'react'
import UnePhotoCard from '../../../toolsComponents/cards/UnePhotoCard'
import InputForm from '../../../toolsComponents/forms/InputForm'
import PhotosModal from '../../../toolsComponents/overlays/PhotosModal'

const UnProjetPhotosCategory = ({ completeCategory, onSubmitPhoto, onDeletePhoto, onSubmit }) => {

  const [ nom, setNom ] = useState(completeCategory.categorie)
  const [ clickedIndex, setClickedIndex ] = useState(0)

  const handleChangeNom = (e) => {
    setNom(e.value)
  }

  const handleSubmit = () => {
    onSubmit({
      oldValue: completeCategory.categorie,
      newValue: nom
    })
  }

  const handleClickPhoto = (photo) => {
    setClickedIndex(completeCategory.photos.findIndex(p => p._id === photo._id))
  }

  const getCleanNom = () => {
    let tmpNom = completeCategory.categorie
    tmpNom = tmpNom.replace(/ /g, '-')
    tmpNom = tmpNom.replace(/'/g, '')
    tmpNom = tmpNom.replace(/"/g, '')
    tmpNom = tmpNom.replace(/!/g, '')
    tmpNom = tmpNom.replace(/\?/g, '')
    tmpNom = tmpNom.replace(/./g, '')

    return tmpNom
  }

  return <>
    <PhotosModal
      cleanId={getCleanNom()}
      title={completeCategory.categorie}
      photos={completeCategory.photos}
      startId={clickedIndex}
    />

    {/* <!-- Edit Modal --> */}
    <div className="modal fade" id={`edit-cat-${getCleanNom()}`} tabindex="-1" role="dialog" aria-labelledby={`edit-cat-${getCleanNom()}`} aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`edit-cat-${getCleanNom()}-label`}>Modifier</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <InputForm
              label="Nom de la catégorie"
              inputName="nom"
              value={nom}
              onChange={handleChangeNom}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit} data-dismiss="modal">Sauvegarder</button>
          </div>
        </div>
      </div>
    </div>

    {/* Titre éxtérieur */}
    <div className="row">
      <div className="col">
        <h4>{completeCategory.categorie}</h4>
      </div>

      <div className="col-auto">
        <a href="#!" data-toggle="modal" data-target={`#edit-cat-${getCleanNom()}`}><i className="fe fe-edit-2"></i></a>  
        &nbsp;&nbsp;
        <a href="#!" data-toggle="modal" data-target="#WIPModal"><i className="fe fe-plus"></i></a>
      </div>
    </div>

    <div className="row">
      {/* PHOTO */}
      {completeCategory.photos.map((p) => {
        return <>
          <div className="col-12 col-md-6 col-xl-4">
            <UnePhotoCard
              photo={p}
              modalId={'modal-'+getCleanNom()}
              onSubmit={onSubmitPhoto}
              onDelete={onDeletePhoto}
              onClick={handleClickPhoto}
            />
          </div>
        </>
      })}
    </div>
  </>
}

export default UnProjetPhotosCategory