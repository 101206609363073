import React from 'react'
import PaginationBar from '../bars/PaginationBar'
import SearchBar from '../bars/SearchBar'
import SimpleEmptyTable from '../SimpleEmptyTable'
import LargeRow from './LargeRow'

const LargeTable = ({ datas, pagination, filter, emptyMessage, onDelete, onSearch, onPagination }) => {

  const handleDelete = (value) => {
    onDelete(value)
  }

  const handleOnSearch = (value) => {
    onSearch(value)
  }

  const handleOnNbPerPage = (value) => {
    onPagination(value, 1)
  }

  const handlePageChange = (page) => {
    onPagination(pagination.nbPerPage, page)
  }

  if(filter.text.value === '' && datas && datas.length === 0) {
    return <SimpleEmptyTable message={emptyMessage} />
  }
  else {
    return (
      <div className="tab-content">
        <div className="tab-pane fade show active" role="tabpanel">
          {/* <!-- Body Card containing the table, the search bar and pagination nav --> */}
          <div className="card" >
            {/* Searchbar and number of lines by page */}
            <SearchBar 
              nbPerPage={pagination.nbPerPage}
              searchValue={filter.text.value}
              onSearch={handleOnSearch}
              onNbPerPage={handleOnNbPerPage}
            />
          </div>

          {/* <!-- Map Projets Item --> */}
          {datas.map((r, index) => {
            return <LargeRow 
              key={index}
              data={r}
              deletable={true}
              onDelete={handleDelete}
            />
          })}

          <div className="card" >
            {/* Pagination */}
            <PaginationBar
              pagination={pagination}
              onPageChange={handlePageChange}                    
            />
          </div>
        </div>
      </div>
    )
  }
}

export default LargeTable