import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import GaugeChart from 'react-gauge-chart'
import defined from '../../../ressources/defined'
import SimpleWarning from '../../SimpleWarning'

const ComparatifProjetEtudeCardGauge = ({etude, surface, prix, typeBien, prixTravaux, annee, label, isManual}) => {

  const needleColor = '#d9dcde'
  const needleBaseColor = '#5f6263'

  const [ basse, setBasse ] = useState(0)
  const [ moyenne, setMoyenne ] = useState(0)
  const [ haute, setHaute ] = useState(0)

  const [ percentGauge, setPercentGauge ] = useState(0)
  const [ color ] = useState(defined.COLOR_BLACK)

  useEffect(() => {
    if(isManual) {
      setBasse(etude.prix_achat_m2_bas)
      setMoyenne(etude.prix_achat_m2)
      setHaute(etude.prix_achat_m2_haut)
    }
    else {
      setBasse(etude.valeurs_foncieres[typeBien].filter(v => v.annee === annee)[0].global.prix_bas)
      setMoyenne(etude.valeurs_foncieres[typeBien].filter(v => v.annee === annee)[0].global.prix_moyen)
      setHaute(etude.valeurs_foncieres[typeBien].filter(v => v.annee === annee)[0].global.prix_haut)
    }
  }, [annee, etude.valeurs_foncieres, typeBien, isManual, etude.prix_achat_m2_bas, etude.prix_achat_m2, etude.prix_achat_m2_haut])

  useEffect(() => {
    const prixAvecTravaux = getPrixAvecTravaux()
    const pourcentage = getPourcentage(prix)

    if(prixAvecTravaux < basse) {
      if(pourcentage < -40) setPercentGauge(0.05)
      else if(pourcentage < -25) setPercentGauge(0.15)
      else setPercentGauge(0.25)
    }
    else if(prixAvecTravaux > haute) {
      if(pourcentage > 40) setPercentGauge(0.95)
      else if(pourcentage > 25) setPercentGauge(0.85)
      else setPercentGauge(0.75)
    }
    else {
      if(pourcentage < -10) setPercentGauge(0.42)
      else if(pourcentage > 10) setPercentGauge(0.58)
      else setPercentGauge(0.50)
    }
  }, [prix, prixTravaux, surface, basse, haute])

  const getPourcentage = () => {
    return Math.round((getPrixAvecTravaux() * 100) / moyenne - 100)
  }

  const getPrix = () => {
    return ((prix / surface) + parseFloat(prixTravaux ? prixTravaux : 0)).toFixed(2)
  }

  const getPrixAvecTravaux = () => {
    return getPrix()
  }

  const getPourcentageDelta = () => {
    const result = getPourcentage()

    return  result > 0 ? '+' + result + '%' : result + '%'
  }

  return (
    <>
      <h5 style={{textAlign:'center'}}>{label}</h5>

      {(!prix || parseFloat(prix) <= 0) &&
        <SimpleWarning>
          Vous devez renseigner un prix pour cette catégorie.
        </SimpleWarning>
      }

      {parseFloat(prix) > 0 &&
        <> 
          <GaugeChart 
            id="gauge-chart1" 
            percent={percentGauge}
            textColor={color}
            formatTextValue={getPourcentageDelta}
            colors={[defined.COLOR_GREEN, defined.COLOR_ORANGE, defined.COLOR_RED]}
            needleColor={needleColor}
            needleBaseColor={needleBaseColor}
            animDelay={100}
            animateDuration={1500}
          />
          <p className='text-muted' style={{textAlign:'center'}}>
            Prix au m² avec les travaux <br/> 
            <span style={{color: color}}><strong>{getPrixAvecTravaux()} €/m²</strong></span>
          </p>
        </>
      }
    </>
  )
}

export default ComparatifProjetEtudeCardGauge