import { createSlice } from '@reduxjs/toolkit'
import arraySliceHelper from '../../toolsSlice/arraySliceHelper'

const helper = new arraySliceHelper(['montant_pret, taux_interet, taux_assurance, duree, mensualite, status'], 'montant_pret', "10")

const uneBanqueOffresPretSlice = createSlice({

  name: 'uneBanqueOffresPret',
  initialState: helper.getInitialState(),
  reducers: {
      // no payload
      getUneBanqueOffresPretInit: (state) => {
        helper.performInit(state)
      },

      // payload : array of users
      getUneBanqueOffresPretSuccess: (state, {payload}) => {
        helper.performSuccess(state, payload)
      },

      // payload : errorMessage
      getUneBanqueOffresPretFailure: (state, { payload }) => {
        helper.performFailure(state, payload)
      },

      // payload : { value to search on everyfields, fields concerned }
      filterByText: (state, { payload }) => {
        helper.performChangeFilter(state, payload)
      },

      // payload : field
      sort: (state, { payload }) => {
        helper.performChangeSort(state, payload)
      },

      // payload : { nbPerPage, currentPage }
      changePagination: (state, { payload }) => {
        helper.performChangePagination(state, payload)
      }
  }
})

export const performGetUneBanqueOffresPret = (token, id_banque) => {

  return async dispatch => {
    dispatch(getUneBanqueOffresPretInit())

    try {
      const reqOptions = {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json',
              'auth-token' : token
          },
          body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/offres_pret_by_id_banque/' + id_banque, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const offresPret = json.datas
        dispatch(getUneBanqueOffresPretSuccess(offresPret))
      }
      else {
        dispatch(getUneBanqueOffresPretFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getUneBanqueOffresPretFailure(e.toString()))
    }
  }
}

// Export action creators
const { getUneBanqueOffresPretInit, getUneBanqueOffresPretSuccess, getUneBanqueOffresPretFailure } = uneBanqueOffresPretSlice.actions
export const { sort, filterByText, changePagination } = uneBanqueOffresPretSlice.actions

// Export reducer
export default uneBanqueOffresPretSlice.reducer

// Selectors
export const uneBanqueOffresPretStatusSelector = (state) => state.uneBanqueOffresPret.status
export const uneBanqueOffresPretDatasSelector = (state) => state.uneBanqueOffresPret.datas
export const uneBanqueOffresPretFilteredDatasSelector = (state) => state.uneBanqueOffresPret.filteredDatas
export const uneBanqueOffresPretPaginatedDatasSelector = (state) => state.uneBanqueOffresPret.paginatedDatas
export const uneBanqueOffresPretPaginationSelector = (state) => state.uneBanqueOffresPret.pagination
export const uneBanqueOffresPretFilterSelector = (state) => state.uneBanqueOffresPret.filter