import React from 'react'
import LogementRevenus from './RevenusModules/LogementRevenus';
import helper from '../uneCalculatriceHelper'
import ReactTooltip from 'react-tooltip';


/**
 * initialState -> initial data to feed this component
 * onChange -> to manage when a field is changed
 */
class Revenus extends React.Component {
  
  constructor(props){
    super(props);
    this.state = this.props.initialState
  }

  logementMapping = async (e) => {
    // Copy state of revenus components
    const newArray = [...this.state.logementsData]
    
    // Update (replace) data for the current renevus component
    newArray[e.numerologementdansArray-1] = e

    // Check if we need to delete some revenus components
    if(this.state.nombreLogements < this.state.logementsData.length + 1) {

      // Count how many data component we need to delete
      const difflogements = this.state.logementsData.length - this.state.nombreLogements;
      
      // Loop to delete the right number of data component
      for(let i = 0; i < difflogements; i++){
        newArray.pop()
      }
    }

    // When we have 1 logement and write for example 5, only the logement 1 and 5 will be created
    // We need to create logement 2, 3, and 4, otherwise they will be undefined 
    for(let i = 0; i < newArray.length; i++) {
      if(newArray[i] === undefined) {
        newArray[i] = {
          numerologementdansArray: i+1,
          typelogement:"t1",
          loyerNueHcEstime:null,
          loyerMeubleHcEstime:null,
          vacanceLocative: null,
          prixNuitLCD: null,
          fraisSupplementaires: null,
          nombreNuitsMoyen: null,
          nombreRotationsMois: null,
          revenusTotauxLDNue: 0,
          revenusTotauxLDMeuble: 0,
          revenusTotauxLCD: 0
        }
      }
    }

    // Perform total
    const tmpState = { revenus: { logementsData: newArray } }
  
    this.setState({
      logementsData: newArray,

      nombreRotationsMois: helper.performRevenusNombreRotationsMois(tmpState), 

      revenusTotalTousLogementsLDNue: helper.performRevenusTotalTousLogementsLDNue(tmpState), 

      revenusTotalTousLogementsLDMeuble: helper.performRevenusTotalTousLogementsLDMeuble(tmpState),
       
      revenusTotalTousLogementsLCD: helper.performRevenusTotalTousLogementsLCD(tmpState)
    });
    
    // Trigger onChange event with the state inside
    if(typeof this.props.onChange === 'function') this.props.onChange(this.state);
  }
  
  logements = () => {
    let logement = []
    for(let i = 1; i <= this.state.nombreLogements; i++){
      logement.push(<LogementRevenus initalState={this.state.logementsData[i-1]} onChange={this.logementMapping} key={i} numerologement={i} />);
    }
    return logement;
  }

  
  handleChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let valueAsNumber = target.valueAsNumber;

  if((name === "nombreLogements" && valueAsNumber > 50)){ valueAsNumber = 50; }

    await this.setState({
      [name]: valueAsNumber || value
    });
  
  const newArray = [...this.state.logementsData]
    const difflogements = this.state.logementsData.length - this.state.nombreLogements;
    for(let i=0; i<(difflogements); i++){
    newArray.pop()
    }
  this.setState({logementsData: newArray});

  if(typeof this.props.onChange === 'function') this.props.onChange(this.state);
  }


  
  render(){
    return (<>
      <div className='col-12'>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
  
                    <h6 className="text-uppercase text-muted mb-2">
                      Nombre de logements du projet finalisé
                    </h6>
  
                    <span className="h2 mb-0">
                      <div className="input-group mb-2">
                        <input
                          type="number"
                          className="form-control"
                          name="nombreLogements"
                          value={this.state.nombreLogements}
                          onChange={this.handleChange}
                          placeholder="ex: 3"
                          min="0"
                          max="50"
                        />
                      </div> 
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-muted mb-2">
                      REVENUS TOTAUX BRUTS LONGUE DURÉE NUE 
                      <span data-tip data-for={'tooltip-revenusTotalTousLogementsLDNue'} data-multiline={true}>
                        <i className='fe fe-info'></i>
                      </span>
                      <ReactTooltip 
                        id={'tooltip-revenusTotalTousLogementsLDNue'} 
                        place='top' 
                        effect='solid' 
                        type="info"
                        multiline={true}
                      >
                        = somme de tous les revenus en location longue durée nue
                      </ReactTooltip>
                    </h6>
  
                    <span className="h2 mb-0">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            €/mois
                          </div>
                        </div>
                        
                        <input
                          type="text"
                          className="form-control"
                          name="revenusTotalTousLogementsLDNue"
                          value={this.state.revenusTotalTousLogementsLDNue}
                          readOnly
                        />
                      </div>  
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-muted mb-2">
                      REVENUS TOTAUX BRUTS LONGUE DURÉE MEUBLÉE
                      <span data-tip data-for={'tooltip-revenusTotalTousLogementsLDMeuble'} data-multiline={true}>
                        <i className='fe fe-info'></i>
                      </span>
                      <ReactTooltip 
                        id={'tooltip-revenusTotalTousLogementsLDMeuble'} 
                        place='top' 
                        effect='solid' 
                        type="info"
                        multiline={true}
                      >
                        = somme de tous les revenus en location longue durée meublée
                      </ReactTooltip>
                    </h6>
  
                    <span className="h2 mb-0">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            €/mois
                          </div>
                        </div>
                        
                        <input
                          type="text"
                          className="form-control"
                          name="revenusTotalTousLogementsLDMeuble"
                          value={this.state.revenusTotalTousLogementsLDMeuble}
                          readOnly
                        />
                      </div>  
                    </span>
                  </div>
                </div> 
              </div>
            </div>
          </div>
  
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-muted mb-2">
                      REVENUS TOTAUX BRUTS LOCATION COURTE DURÉE
                      <span data-tip data-for={'tooltip-revenusTotalTousLogementsLCD'} data-multiline={true}>
                        <i className='fe fe-info'></i>
                      </span>
                      <ReactTooltip 
                        id={'tooltip-revenusTotalTousLogementsLCD'} 
                        place='top' 
                        effect='solid' 
                        type="info"
                        multiline={true}
                      >
                        = somme de tous les revenus en location courte durée
                      </ReactTooltip>
                    </h6>
  
                    <span className="h2 mb-0">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            €/mois
                          </div>
                        </div>
                        
                        <input
                          type="text"
                          className="form-control"
                          name="revenusTotalTousLogementsLCD"
                          value={this.state.revenusTotalTousLogementsLCD}
                          readOnly
                        />
                      </div> 
                    </span>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className='col-12'>
        {this.logements().map(logementrevenu => logementrevenu)}
      </div>      
    </>)
  
  }}

export default Revenus;