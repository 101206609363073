import React, { useState } from 'react'
import PasswordInputForm from './PasswordInputForm'

const PasswordInputFormWithVerif = ({ label, inputName, onChange}) => {

  const [ password, setPasssword ] = useState('')

  const [ passwordLengthIsOk, setPasswordLengthIsOk ] = useState(false)
  const [ passwordNumberIsOk, setPasswordNumberIsOk ] = useState(false)
  const [ passwordUpperIsOk, setPasswordUpperIsOk ] = useState(false)
  const [ passwordSpecialIsOk, setPasswordSpecialIsOk ] = useState(false)

  const handleChange = (e) => {
    setPasssword(e.value)
    
    let isPasswordOk = true
        
    setPasswordLengthIsOk(true)
    setPasswordNumberIsOk(true)
    setPasswordUpperIsOk(true)
    setPasswordSpecialIsOk(true)

    // Mot de passe inférieure à 8 caractères ?
    if( e.value.length < 8 ) {
      isPasswordOk = false
      setPasswordLengthIsOk(false)
    }

    // Mot de passe sans chiffre ?
    if( !e.value.match(/[0-9]/g) ) {
      isPasswordOk = false
      setPasswordNumberIsOk(false)
    }

    // Mot de passe sans majuscule ?
    if( !e.value.match(/[A-Z]/g) ) {
      isPasswordOk = false
      setPasswordUpperIsOk(false)
    }

    if( !e.value.match(/[$&+,:;=?@#|'<>.^*()%!-]/g) ) {
      isPasswordOk = false
      setPasswordSpecialIsOk(false)
    }
    
    onChange({ ...e, isPasswordOk })
  }

  return (
    <>
      {/* <InputForm 
        label={label}
        type="password"
        inputName={inputName}
        value={password}
        onChange={handleChange}
      /> */}

      <PasswordInputForm
        label={label}
        inputName={inputName}
        value={password}
        onChange={handleChange}
      />

      <div className="alert alert-light" role="alert">
        <ul className="ul-password-check">
          <li className={passwordLengthIsOk ? 'li-ok' : 'li-ko'}>&nbsp;&nbsp;Au moins 8 caractères</li>
          <li className={passwordNumberIsOk ? 'li-ok' : 'li-ko'}>&nbsp;&nbsp;Contient un chiffre</li>
          <li className={passwordUpperIsOk ? 'li-ok' : 'li-ko'}>&nbsp;&nbsp;Contient une majuscule</li>
          <li className={passwordSpecialIsOk ? 'li-ok' : 'li-ko'}>&nbsp;&nbsp;Contient un caractère spécial</li>
        </ul>
      </div>
    </>
  )
}

export default PasswordInputFormWithVerif