import React from 'react'
import { useParams } from 'react-router'
import UnProjetWithStore from './general/UnProjetWithStore'
import UnProjetPhotosWithStore from './photos/UnProjetPhotosWithStore'
import UnProjetRentabiliteWithStore from './rentabilite/UnProjetRentabiliteWithStore'
import UnProjetRentabiliteWizardWithStore from './rentabilite/wizard/UnProjetRentabiliteWizardWithStore'


const UnProjetDispatcher = () => {
  const { id, page, subPage } = useParams()

  switch(page) {
    case undefined:
      return <UnProjetWithStore id={id} />
      
    case 'photos':
      return <UnProjetPhotosWithStore id={id} />

    case 'rentabilite': 
      switch(subPage) {
        case 'wizard':
          return <UnProjetRentabiliteWizardWithStore id={id} />
        default: 
          return <UnProjetRentabiliteWithStore id={id} />
      }

    default:
      return <UnProjetWithStore id={id} />
  }
}

export default UnProjetDispatcher