import React from "react";
import InformationsGenerales from "./SubModules/InformationsGenerales";
import LeCredit from "./SubModules/LeCredit";
import EvolutionTauxEndettement from './SubModules/EvolutionTauxEndettement';
import Revenus from "./SubModules/Revenus";
import Charges from "./SubModules/Charges";
import CashflowETRenta from "./SubModules/CashflowETRenta";
import { useEffect } from "react";
import { useState } from "react";
import SubmitForm from "../../toolsComponents/forms/SubmitForm";
import RentabiliteHorizontalCard from "../../toolsComponents/cards/RentabiliteHorizontalCard";
import { convertChargesReduxToComponent, convertCreditReduxToComponent, convertEndettementReduxToComponent, convertInfosGeneralesReduxToComponent, convertRevenusReduxToComponent } from "../unProjet/rentabilite/rentabiliteUtils";

/**
 * Props :
 * calculatrice -> All the data to feed the calculatrice
 * onSubmit -> When clicking on Sauvegarder 
 */
const UneCalculatriceRentabilite = ({ 
  calculatrice, 
  projet,
  onChangeInfosGenerales, 
  onChangeCredit,
  onChangeRevenus,
  onChangeCharges,
  onChangeEndettement,
  onSubmit }) => {

  const [ state, setState ] = useState({
    infosGenerales: {
      prixMoyenM2: 0,
      lcd: true,
      ldm: true,
      ldnue: true,
      surface: 0,
      coutAchat: 0,
      fraisNotaires: 0,
      fraisNotairesInputed: false,
      typedetravaux: 0,
      coutTravaux: 0,
      coutMeubles: 0,
      coutTotalm2: 0,
      coutTotalReno: 0,
      coutTotalProjet: 0,
    },

    credit: {
      apportPersonnel: 0,
      fraisGarantie: 0,
      fraisDossier: 0,
      dureeDiffere: 0,
      dureeTotaleCredit: 0,
      tauxHorsAssurance: 0,
      tauxAssurance: 0,
      montantAFinance: 0,
      coutFraisGarantie: 0,
      capitalEmprunteTotal:0,
      mensualite:0,
    },

    endettement: {
      revenusMensuels: 0,
      revenusFonciersActuels: 0,
      revenusFonciersFutursMeuble: 0,
      mensualiteLieeALinvestissement:0,
      autrePretsMensualite:0,
      tauxEndettementActuel:0,
      tauxEndettementFutur:0,
    },

    revenus: {
      nombreLogements: 0,
      nombreRotations:0,
      logementsData: [],
      revenusTotalTousLogementsLDNue:0,
      revenusTotalTousLogementsLDMeuble:0,
      revenusTotalTousLogementsLCD:0,
    },

    charges: {
      nombreLot:0,
      nombreRotations:0,
      revenusTotauxLDNue:0,
      revenusTotauxLDMeuble: 0,
      revenusTotauxLCD: 0,
      taxeFonciere: 0,
      chargesCopro: 0,
      assurancePNO: 0,
      assuranceLoyersImpayes: 0,
      previsionTravaux: 0,
      expertComptable: 0,
      fraisGerance: 0,
      fraisDivers:0,
      tauxConciergerie: 0,
      tauxCommissionPlateforme: 0,
      fraisPlateformesMensuels: 0,
      fraisPlateformesAnnuels: 0,
      fraisElectricite: 0,
      fraisEau: 0,
      fraisPersonnelMenage: 0,
      fraisPressing: 0,
      fraisInternet: 0,
      fraisNetflixEtAbonnement: 0,
      fraisLogiciels:0,
      fraisProduitsPerissable: 0,
      totalChargesMensuellesLDNue: 0,
      totalChargesMensuellesLDMeuble: 0,
      totalChargesMensuellesLCD: 0,
      totalChargesAnnuellesLDNue: 0,
      totalChargesAnnuellesLDMeuble: 0,
      totalChargesAnnuellesLCD: 0,
    },

    cashflow: {

      mensualiteCredit: 0,
      coutTotalReno: 0,
      coutTotalProjet: 0,


      revenusTotalLDNue:0,
      chargesTotalLDNue:0,
      cashFlowLDNue: 0,
      rentaBruteLDNue: 0,
      rentaNetteLDNue: 0,
  

      revenusTotalLDMeuble: 0,
      chargesTotalLDMeuble:0,
      cashFlowLDMeuble: 0,
      rentaBruteLDMeuble: 0,
      rentaNetteLDMeuble: 0,

      revenusTotalLCD: 0,
      chargesTotalLCD: 0,
      cashFlowLCD: 0,
      rentaBruteLCD: 0,
      rentaNetteLCD: 0,

    }
  })

  const STEPS = {
    INFOS_GENERALES: 1,
    CREDIT: 2,
    REVENUS: 3,
    CHARGES: 4,
    ENDETTEMENT: 5,
    TERMINE: 6
  }

  const [ step, setStep ] = useState(1)

  useEffect(() => {
    if( calculatrice && calculatrice.infosGenerales) {      
      setState({
        infosGenerales: calculatrice.infosGenerales,
        credit: calculatrice.credit,
        endettement: calculatrice.endettement,
        revenus: calculatrice.revenus,
        charges: calculatrice.charges,
        cashflow: calculatrice.cashflow
      })
    }
    else if( projet && projet._id ) {
      setState({
        infosGenerales: convertInfosGeneralesReduxToComponent(projet),
        credit: convertCreditReduxToComponent(projet),
        endettement: convertEndettementReduxToComponent(projet),
        revenus: convertRevenusReduxToComponent(projet),
        charges: convertChargesReduxToComponent(projet),
        cashflow: {}
      })
    }
  }, [calculatrice, projet])

  const handleChangeInformationsGenerales = (e) => {
    setState({
      ...state,
      infosGenerales: e
    })

    if( typeof onChangeInfosGenerales === 'function' ) onChangeInfosGenerales(e)
  }

  const handleChangeCredit = (e) => {
    setState({
      ...state,
      credit: e
    })

    if( typeof onChangeCredit === 'function' ) onChangeCredit(e)
  }

  const handleChangeEvolutionTauxEndettement = (e) => {
    setState({
      ...state,
      endettement: e
    })

    if( typeof onChangeEndettement === 'function' ) onChangeEndettement(e)
  }

  const handleChangeRevenus = (e) => {
    setState({
      ...state,
      revenus: e
    })

    if( typeof onChangeRevenus === 'function' ) onChangeRevenus(e)
  }

  const handleChangeCharges = (e) => {
    setState({
      ...state,
      charges: e
    })

    if( typeof onChangeCharges === 'function' ) onChangeCharges(e)
  }


  const handleSubmit = () => {
    if( typeof onSubmit === 'function' ) onSubmit(state)
  }

  const handleStep = (step) => {
    setStep(step)
  }

  return (
    <>
      <div className='col-12'>
        <RentabiliteHorizontalCard
          calculatrice={state}
          currentStep={step}
          onStep={handleStep}
        />

        <div className='row'>
          {step === STEPS.INFOS_GENERALES &&
            <InformationsGenerales 
              initialState={state.infosGenerales}
              onChange={handleChangeInformationsGenerales}
            />
          }

          {step === STEPS.CREDIT &&
            <LeCredit 
              initialState={state.credit}
              infosGeneralesProjet={state.infosGenerales}
              onChange={handleChangeCredit}
            />
          }

          {step === STEPS.REVENUS &&
            <Revenus 
              initialState={state.revenus}
              onChange={handleChangeRevenus}
            />
          }

          {step === STEPS.CHARGES &&
            <Charges 
              initialState={state.charges}
              revenus={state.revenus}
              onChange={handleChangeCharges}
            />
          }

          {step === STEPS.ENDETTEMENT &&
            <EvolutionTauxEndettement 
              initialState={state.endettement}
              infosGenerales={state.infosGenerales}
              leRevenus={state.revenus}
              leCredit={state.credit}
              onChange={handleChangeEvolutionTauxEndettement}
            />
          }

          {step === STEPS.TERMINE &&
            <CashflowETRenta 
              initialState={{}}
              informationsGenerales={state.infosGenerales}
              revenus={state.revenus}
              leCredit={state.credit}
              charges={state.charges}
              noButton={true}
            />
          }
        </div>
      </div>

      <div className='col-12'>
        <SubmitForm
          label="Sauvegarder"
          onSubmit={handleSubmit}
        />
      </div>
    </>
  )
}

export default UneCalculatriceRentabilite;
