import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetPhotos = () => {

    return (
        <div>
            {/* <!-- MODAL AJOUTER UNE PHOTO --> */}
            <div className="modal fade" id="exampleModalNewPhoto" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ajouter une photo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Titre de la photo</label>
                                <input type="text" className="form-control" id="recipient-name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Catégorie</label>
                                <select className="form-control">
                                    <option value="1" selected>Extérieur</option>
                                    <option value="2">Cuisine</option>
                                    <option value="3">Espace de vie</option>
                                    <option value="4">Salle de bain</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Photo</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL MODIFIER UNE PHOTO --> */}
            <div className="modal fade" id="exampleModalEditPhoto" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifier une photo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Titre de la photo</label>
                                <input type="text" className="form-control" id="recipient-name" value="Vue globale du chalet" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Catégorie</label>
                                <select className="form-control">
                                    <option value="1" selected>Extérieur</option>
                                    <option value="2">Cuisine</option>
                                    <option value="3">Espace de vie</option>
                                    <option value="4">Salle de bain</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Photo</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Modifier</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL SUPPRIMER UNE PHOTO --> */}
            <div className="modal fade" id="exampleModalDelPhoto" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Supprimer une photo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <p>Etes-vous sûr de vouloir supprimer la photo : Vue globale du chalet ?</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL AJOUTER UNE CATEGORIE --> */}
            <div className="modal fade" id="exampleModalNewCat" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ajouter une catégorie</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Nom de la catégorie</label>
                                <input type="text" className="form-control" id="recipient-name" value="" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL MODIFIER UNE CATEGORIE --> */}
            <div className="modal fade" id="exampleModalEditCat" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifier une catégorie</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Nom de la catégorie</label>
                                <input type="text" className="form-control" id="recipient-name" value="Extérieur" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Modifier</button>
                        </div>
                    </div>
                </div>
            </div>


            <Navigation granted_modules="ALL" />

            <div className="main-content">
                
                <UnProjetHeader isActive="general" isActiveSubMenu="photos" />

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                            <div className="row">

                                <div className="col">

                                </div>

                                <div className="col-auto">

                                <a href="#!" className="btn btn-primary d-block d-md-inline-block lift" data-toggle="modal" data-target="#exampleModalNewCat">
                                    <i className="fe fe-plus"></i> Ajouter une catégorie
                                </a>

                                &nbsp;&nbsp;

                                <a href="#!" className="btn btn-primary d-block d-md-inline-block lift" data-toggle="modal" data-target="#exampleModalNewPhoto">
                                    <i className="fe fe-plus"></i> Ajouter une photo
                                </a>

                                </div>

                            </div>

                            <br />

                            {/* Titre éxtérieur */}
                            <div className="row">

                                <div className="col">

                                    <h4>Extérieur</h4>

                                </div>

                                <div className="col-auto">

                                    <a href="#!" data-toggle="modal" data-target="#exampleModalEditCat"><i className="fe fe-edit-2"></i></a>  
                                    &nbsp;&nbsp;
                                    <a href="#!" data-toggle="modal" data-target="#exampleModalNewPhoto"><i className="fe fe-plus"></i></a>

                                </div>

                            </div>

                            <hr />

                            <div className="row">

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!">
                                            <img 
                                                src="/assets/img/mockups/projet-5/projet-5-pic-1.jpeg" 
                                                alt="..." 
                                                className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body h-100">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Vue globale du chalet</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!">
                                            <img src="/assets/img/mockups/projet-5/projet-5-pic-2.jpeg" alt="..." className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Façade et terrasse</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item"  data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!">
                                            <img src="/assets/img/mockups/projet-5/projet-5-pic-5.jpeg" alt="..." className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Vue globale du chalet</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!">
                                            <img src="/assets/img/mockups/projet-5/projet-5-pic-10.jpeg" alt="..." className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Terrasse vu du toit</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!">
                                            <img src="/assets/img/mockups/projet-5/projet-5-pic-9.jpeg" alt="..." className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Vue globale du chalet</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>


                            </div>


                            {/* Titre cuisine */}
                            <div className="row">

                                <div className="col">

                                    <h4>Cuisine</h4>

                                </div>

                                <div className="col-auto">

                                    <a href="#!" data-toggle="modal" data-target="#exampleModalEditCat"><i className="fe fe-edit-2"></i></a>  
                                    &nbsp;&nbsp;
                                    <a href="#!" data-toggle="modal" data-target="#exampleModalNewPhoto"><i className="fe fe-plus"></i></a>

                                </div>

                            </div>

                            <hr />

                            <div className="row">

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!">
                                            <img src="/assets/img/mockups/projet-5/projet-5-pic-3.jpeg" alt="..." className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Cuisine principale</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                            <img src="/assets/img/mockups/projet-5/projet-5-pic-7.jpeg" alt="..." className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Cuisine secondaire</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>


                            </div>


                            {/* Titre Espace de vie */}
                            <div className="row">

                                <div className="col">

                                    <h4>Espace de vie</h4>

                                </div>

                                <div className="col-auto">

                                    <a href="#!" data-toggle="modal" data-target="#exampleModalEditCat"><i className="fe fe-edit-2"></i></a>  
                                    &nbsp;&nbsp;
                                    <a href="#!" data-toggle="modal" data-target="#exampleModalNewPhoto"><i className="fe fe-plus"></i></a>

                                </div>

                            </div>

                            <hr />

                            <div className="row">

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!">
                                            <img src="/assets/img/mockups/projet-5/projet-5-pic-4.jpeg" alt="..." className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Salon et cheminé</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>

                                {/* PHOTO */}
                                <div className="col-12 col-md-6 col-xl-4">

                                    <div className="card">

                                        {/* <!-- Image --> */}
                                        <a href="#!">
                                            <img src="/assets/img/mockups/projet-5/projet-5-pic-6.jpeg" alt="..." className="card-img-top" />
                                        </a>

                                        {/* <!-- Body --> */}
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col">

                                                    {/* <!-- Title --> */}
                                                    <h4 className="mb-2 name">
                                                        <a href="#!">Salle à manger</a>
                                                    </h4>

                                                </div>
                                                <div className="col-auto">

                                                    {/* <!-- Dropdown --> */}
                                                    <div className="dropdown">
                                                        <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> {/* <!-- / .row --> */}
                                        </div>

                    
                                        </div>

                                </div>


                            </div>


                            
                            {/* Titre Salle de bain */}
                            <div className="row">

                                <div className="col">

                                    <h4>Salle de bain</h4>

                                </div>

                                <div className="col-auto">

                                    <a href="#!" data-toggle="modal" data-target="#exampleModalEditCat"><i className="fe fe-edit-2"></i></a>  
                                    &nbsp;&nbsp;
                                    <a href="#!" data-toggle="modal" data-target="#exampleModalNewPhoto"><i className="fe fe-plus"></i></a>

                                </div>

                            </div>

                            <hr />

                        <div className="row">


                            {/* PHOTO */}
                            <div className="col-12 col-md-6 col-xl-4">

                                <div className="card">

                                    {/* <!-- Image --> */}
                                    <a href="#!">
                                        <img src="/assets/img/mockups/projet-5/projet-5-pic-8.jpeg" alt="..." className="card-img-top" />
                                    </a>

                                    {/* <!-- Body --> */}
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col">

                                                {/* <!-- Title --> */}
                                                <h4 className="mb-2 name">
                                                    <a href="#!">Salle de bain</a>
                                                </h4>

                                            </div>
                                            <div className="col-auto">

                                                {/* <!-- Dropdown --> */}
                                                <div className="dropdown">
                                                    <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                    <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditPhoto">
                                                        <i className="fe fe-edit-3"></i> Modifier
                                                    </a>
                                                    <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelPhoto">
                                                        <i className="fe fe-trash-2"></i> Supprimer
                                                    </a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}
                                    </div>


                                    </div>

                                </div>

                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div> 
    )
}

export default UnProjetPhotos