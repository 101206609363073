import React from 'react'

const UnArtisanHeader = ({ isActive }) => {

    return (
<div className="header">
<div className="container-fluid">

{/* <!-- Body --> */}
<div className="header-body">
    <div className="row align-items-center">

        <div className="col-auto">

            {/* <!-- Avatar --> */}
            <div className="avatar avatar-lg avatar-4by3">
                <img src="/assets/img/mockups/artisan-1-avatar.jpeg" alt="..." className="avatar-img rounded" />
            </div>

        </div>
        <div className="col ml-n3 ml-md-n2">

            {/* <!-- Pretitle --> */}
            <h6 className="header-pretitle">
                Artisan
            </h6>

            {/* <!-- Title --> */}
            <h1 className="header-title">
                Bâtir 48 SARL
            </h1>

        </div>
    
    </div> {/* <!-- / .row --> */}

    <div className="row align-items-center">
        <div className="col">

            {/* <!-- Nav --> */}
            <ul className="nav nav-tabs nav-overflow header-tabs">
                <li className="nav-item">
                    <a href="/mockups/artisans/batir-48-SARL" className={ isActive === 'general' ? 'nav-link active' : 'nav-link' } >
                    Général
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/mockups/artisans/batir-48-SARL/devis" className={ isActive === 'devis' ? 'nav-link active' : 'nav-link' }>
                    Devis
                    </a>
                </li>
            </ul>

        </div>
    </div>

</div>

</div>
</div>


    )
}

export default UnArtisanHeader