import React from "react";
import { numberWithSpaces } from "../../../ressources/tools";
import { getIndiceConfiance } from "./uneEtudeValeursFoncieresTools";

const UneEtudeValeursFoncieresHeader = ({
  label,
  totalVentes,
  valeursFoncieres,
}) => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div>
          <h3>{label}</h3>
          <img src="/assets/img/mockups/building.svg" alt="building" />
        </div>

        <br />

        <div className="row">
          <div className="col">
            <br />
            <span style={{ fontSize: "1.2em", color: "#27ae60" }}>
              {numberWithSpaces(valeursFoncieres?.global?.prix_bas)} €/m²
            </span>
            <br />
            <span style={{ fontSize: "0.9em" }} className="text-muted">
              Fourchette basse <br />
              en {valeursFoncieres?.annee}
            </span>
          </div>

          <div className="col">
            <span style={{ fontSize: "1.7em", color: "#335eea" }}>
              <strong>
                {numberWithSpaces(valeursFoncieres?.global?.prix_moyen)} €/m²
              </strong>
            </span>
            <br />
            <span style={{ fontSize: "0.9em" }} className="text-muted">
              Prix moyen en {valeursFoncieres?.annee}
            </span>
          </div>

          <div className="col">
            <br />
            <span style={{ fontSize: "1.2em", color: "#c0392b" }}>
              {numberWithSpaces(valeursFoncieres?.global?.prix_haut)} €/m²
            </span>
            <br />
            <span style={{ fontSize: "0.9em" }} className="text-muted">
              Fourchette haute <br />
              en {valeursFoncieres?.annee}
            </span>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col">
            Indice de confiance : {getIndiceConfiance(totalVentes)}
          </div>
        </div>
      </div>
    </>
  );
};

export default UneEtudeValeursFoncieresHeader;
