import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { performGetTutorielsByTypeAndCategories, tutorielsPaginatedDatasSelector, tutorielsStatusSelector } from '../../../features/tutoriels/tutorielsSlice';
import defined from '../../../ressources/defined';
import FormationOverlayItem from './FormationOverlayItem';

const FormationsOverlayWithStore = ({ current_category }) => {

  /* Open when someone clicks on the span element */
  const openNav = () => {
    document.getElementById("video-overlay").style.right = "0px";
    //document.getElementById("overlay-button").style.display = "none"
  }
  
  /* Close when someone clicks on the "x" symbol inside the overlay */
  const closeNav = () => {
    document.getElementById("video-overlay").style.right = "-300px";
    //document.getElementById("overlay-button").style.display = "block"
  }

  const [ cookies ] = useCookies(['token', 'email'])
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(tutorielsStatusSelector)
  const tutoriels = useSelector(tutorielsPaginatedDatasSelector)

  useEffect(() => {
    // Get formations
    dispatch(performGetTutorielsByTypeAndCategories(cookies.token, 'tutoriel', current_category))
  }, [dispatch, cookies.token, current_category])
  

  // MANAGE LOGOUT WHEN NOT CONNECTED OR TOKEN EXPIRED
  // If the user is not logged in then redirect to login page
  if(!cookies.token || (!loading && hasError && errorMessage === defined.TOKEN_EXPIRED)) {
    return <Redirect to="/logout" />
  }
  else {
    return (
      <div>
        <div id="overlay-button" onClick={openNav} style={{borderTopLeftRadius:"32px",borderBottomLeftRadius:"32px",border: "1px solid rgb(41, 80, 255)"}}>
          <img src="/assets/img/formationillustration.svg" 
            alt="Formations" 
            height="162px"
            style={{
                paddingTop:"24px", 
                paddingBottom:"24px",
                paddingRight:"1px",
                paddingLeft:"1px"
            }}
          />
        </div>
  
        {/* <!-- The overlay --> */}
        <div id="video-overlay" className="overlay">
          <h2 className="overlay-title">Formations</h2>
  
          {/* <!-- Button to close the overlay navigation --> */}
          <a href={defined.ANCHOR} className="closebtn" onClick={closeNav}>&times;</a>
  
          {/* <!-- Overlay content --> */}
          <div className="overlay-content">
            {tutoriels.map(v => {
              return <FormationOverlayItem video={v}/>
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default FormationsOverlayWithStore