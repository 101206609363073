import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetAnnexes = () => {

    return (
        <div>

            {/* <!-- MODAL AJOUTER UN ANNEXE --> */}
            <div className="modal fade" id="exampleModalNewAnnexe" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ajouter un annexe</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Titre de l'annexe</label>
                                <input type="text" className="form-control" id="recipient-name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Fichier</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL MODIFIER UN ANNEXE --> */}
            <div className="modal fade" id="exampleModalEditAnnexe" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifier un annexe</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Titre de la photo</label>
                                <input type="text" className="form-control" id="recipient-name" value="Compromis de vente" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Fichier</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Modifier</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- MODAL SUPPRIMER UN ANNEXE --> */}
            <div className="modal fade" id="exampleModalDelAnnexe" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Supprimer un annexe</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <p>Etes-vous sûr de vouloir supprimer l'annexe : Compromis de vente ?</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>


            <Navigation granted_modules="ALL" />

            <div className="main-content">

                {/* <!-- HEADER --> */}
                <UnProjetHeader isActive="general" isActiveSubMenu="annexes"/>

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                        <div className="row">
                            <div className="col"></div>

                            <div className="col-auto">
                                <a href="#!" className="btn btn-primary d-block d-md-inline-block lift" data-toggle="modal" data-target="#exampleModalNewAnnexe">
                                    <i className="fe fe-plus"></i> Ajouter un annexe
                                </a>
                            </div>
                        </div>

                        <br />

                        <div className="card" data-list="{&quot;valueNames&quot;: [&quot;orders-order&quot;, &quot;orders-product&quot;, &quot;orders-date&quot;, &quot;orders-total&quot;, &quot;orders-status&quot;, &quot;orders-method&quot;]}">
                            <div className="card-header">

                                {/* <!-- Search --> */}
                                <form>
                                <div className="input-group input-group-flush">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fe fe-search"></i>
                                    </span>
                                    </div>
                                    <input className="form-control list-search" type="search" placeholder="Chercher ..." />
                                </div>
                                </form>


                            </div>

                            <div className="table-responsive">
                                <table className="table table-sm table-hover table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th>
                                            <a className="list-sort text-muted" data-sort="item-name" href="#!">Titre</a>
                                            </th>
                                            <th>
                                            <a className="list-sort text-muted" data-sort="item-industry" href="#!">Nom du fichier</a>
                                            </th>
                                            <th colspan="2">
                                            <a className="list-sort text-muted" data-sort="item-industry" href="#!">Fichier</a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list font-size-base">
                                        
                                        {/* <!-- ANNEXE 1 --> */}
                                        <tr>
                                            
                                            <td>
                                                Compromis de vente
                                            </td>

                                            <td>
                                                <a href="#!">
                                                    compromis_vente.pdf
                                                </a>
                                            </td>

                                            <td>
                                                <a href="#!">
                                                    <i className="fe fe-download"></i> Télécharger
                                                </a>
                                            </td>


                                            <td className="text-right">
                                                {/* <!-- Dropdown --> */}
                                                <div className="dropdown">
                                                    <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditAnnexe">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelAnnexe">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                        {/* <!-- ANNEXE 2 --> */}
                                        <tr>
                                            
                                            <td>
                                                Offre de prêt Crédit Agricole
                                            </td>

                                            <td>
                                                <a href="#!">
                                                    CA_offre_de_pret.pdf
                                                </a>
                                            </td>

                                            <td>
                                                <a href="#!">
                                                    <i className="fe fe-download"></i> Télécharger
                                                </a>
                                            </td>


                                            <td className="text-right">
                                                {/* <!-- Dropdown --> */}
                                                <div className="dropdown">
                                                    <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditAnnexe">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelAnnexe">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                        {/* <!-- ANNEXE 3 --> */}
                                        <tr>
                                            
                                            <td>
                                                Acte notarié
                                            </td>

                                            <td>
                                                <a href="#!">
                                                    acte_vente.pdf
                                                </a>
                                            </td>

                                            <td>
                                                <a href="#!">
                                                    <i className="fe fe-download"></i> Télécharger
                                                </a>
                                            </td>


                                            <td className="text-right">
                                                {/* <!-- Dropdown --> */}
                                                <div className="dropdown">
                                                    <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditAnnexe">
                                                            <i className="fe fe-edit-3"></i> Modifier
                                                        </a>
                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelAnnexe">
                                                            <i className="fe fe-trash-2"></i> Supprimer
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        

                                    </tbody>
                                </table>
                            </div>

                            <div className="card-footer d-flex justify-content-between">
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <a className="page-link pl-0 pr-4 border-right" href="#!">
                                        <i className="fe fe-arrow-left mr-1"></i> Préc.
                                    </a>
                                </li>
                            </ul>
                            
                            <ul className="list-pagination pagination pagination-tabs card-pagination">
                                <li className="active">
                                    <a className="page" href="#!" data-i="1" data-page="10">1</a>
                                </li>
                            </ul>
                            
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <a className="page-link pl-4 pr-0 border-left" href="#!">
                                        Suiv. <i className="fe fe-arrow-right ml-1"></i>
                                    </a>
                                </li>
                            </ul>
                            
                        </div>

                        </div>

                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    )
}

export default UnProjetAnnexes