import React from 'react'
import { useParams } from 'react-router'
import UneEtudeGeneralWithStore from './General/UneEtudeGeneralWithStore'
import UneEtudeMeilleurAgentsWithStore from './MeilleurAgents/UneEtudeMeilleurAgentsWithStore'
import UneEtudeWikipediaWithStore from './Wikipedia/UneEtudeWikipediaWithStore'
import UneEtudeGeoPortailWithStore from './Environnement/UneEtudeGeoEnvironnementStore'
import UneEtudeLogementsTouristiquesWithStore from './LogementsTouristiques/UneEtudeLogementsTouristiquesWithStore'
import UneEtudeAppreciationsWithStore from './Appreciations/UneEtudeAppreciationsWithStore'
import UneEtudePopulationsWithStore from './Populations/UneEtudePopulationsWithStore'
import UneEtudeValeursFoncieresWithStore from './ValeursFoncieres/UneEtudeValeursFoncieresWithStore'

const UneBanqueDispatcher = () => {
  const { id, page } = useParams()

  switch(page) {
    case undefined: 
      return <UneEtudeGeneralWithStore id={id} />
    case 'environnement':
      return <UneEtudeGeoPortailWithStore id={id} />
    case 'valeurs-foncieres':
      return <UneEtudeValeursFoncieresWithStore id={id} />
    case 'meilleur-agents':
      return <UneEtudeMeilleurAgentsWithStore id={id} />
    case 'wikipedia':
      return <UneEtudeWikipediaWithStore id={id} />
    case 'populations':
      return <UneEtudePopulationsWithStore id={id} />
    case 'logements-courtes-durees':
      return <UneEtudeLogementsTouristiquesWithStore id={id} />
    case 'appreciations':
      return <UneEtudeAppreciationsWithStore id={id} />
    default:
      return <UneEtudeGeneralWithStore id={id} />
  }
}

export default UneBanqueDispatcher