import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";
import SelectVersatile from "../../../../toolsComponents/SelectVersatile";
import { getNewArray } from "../utils";

const LotElectricite = ({ electricite, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("electricite", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot Électricité"
            id="lotElectricite"
            inputName="lotElectricite.isChecked"
            isChecked={electricite?.lotElectricite.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !electricite?.lotElectricite.isChecked
                ? "hide"
                : "sous-lot col-12"
            }
          >
            <div className="sous-lot col-12">
              <Checkbox
                label="Tableau Électrique"
                id="tableauElectrique"
                inputName="tableauElectrique.isChecked"
                isChecked={electricite?.tableauElectrique.isChecked}
                onChange={handleChange}
              />
            </div>

            <div className="d-flex">
              <div className="sous-lot d-flex col-4">
                <div>
                  <Checkbox
                    label="Interrupteurs"
                    id="interrupteurs"
                    inputName="interrupteurs.isChecked"
                    isChecked={electricite?.interrupteurs.isChecked}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    !electricite?.interrupteurs.isChecked
                      ? "hide"
                      : "col-3 fs-6 ml-3 text-left"
                  }
                >
                  <InputForm
                    inputName="interrupteurs.nombreInterrupteurs"
                    type="Number"
                    placeholder="7"
                    value={electricite?.interrupteurs.nombreInterrupteurs}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="sous-lot d-flex col-4">
                <div>
                  <Checkbox
                    label="Prises RJ45"
                    id="prisesRJ45"
                    inputName="prisesRJ45.isChecked"
                    isChecked={electricite?.prisesRJ45.isChecked}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    !electricite?.prisesRJ45.isChecked
                      ? "hide"
                      : "col-3 fs-6 ml-3 text-left"
                  }
                >
                  <InputForm
                    inputName="prisesRJ45.nombrePrisesRJ45"
                    type="Number"
                    placeholder="4"
                    value={electricite?.prisesRJ45.nombrePrisesRJ45}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="sous-lot d-flex col-4">
                <div>
                  <Checkbox
                    label="Sorties Lumières"
                    id="sortiesLumieres"
                    inputName="sortiesLumieres.isChecked"
                    isChecked={electricite?.sortiesLumieres.isChecked}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    !electricite?.sortiesLumieres.isChecked
                      ? "hide"
                      : "col-3 fs-6 ml-3 text-left"
                  }
                >
                  <InputForm
                    inputName="sortiesLumieres.nombreSortiesLumieres"
                    type="Number"
                    placeholder="8"
                    value={electricite?.sortiesLumieres.nombreSortiesLumieres}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="sous-lot d-flex col-4">
                <div>
                  <Checkbox
                    label="Prises d’antennes"
                    id="prisesAntennes"
                    inputName="prisesAntennes.isChecked"
                    isChecked={electricite?.prisesAntennes.isChecked}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    !electricite?.prisesAntennes.isChecked
                      ? "hide"
                      : "col-3 fs-6 ml-3 text-left"
                  }
                >
                  <InputForm
                    inputName="prisesAntennes.nombrePrisesAntennes"
                    type="Number"
                    placeholder="7"
                    value={electricite?.prisesAntennes.nombrePrisesAntennes}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="sous-lot d-flex col-6">
                <div>
                  <Checkbox
                    label="Alimentations spéciales"
                    id="alimentationsSpeciales"
                    inputName="alimentationsSpeciales.isChecked"
                    isChecked={electricite?.alimentationsSpeciales.isChecked}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    !electricite?.alimentationsSpeciales.isChecked
                      ? "hide"
                      : "col-2 fs-6 ml-3 text-left"
                  }
                >
                  <InputForm
                    inputName="alimentationsSpeciales.nombreAlimentationsSpeciales"
                    type="Number"
                    placeholder="4"
                    value={
                      electricite?.alimentationsSpeciales
                        .nombreAlimentationsSpeciales
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="sous-lot d-flex col-6">
              <div>
                <Checkbox
                  label="Radiateur Électriques"
                  id="radiateursElectriques"
                  inputName="radiateursElectriques.isChecked"
                  isChecked={electricite?.radiateursElectriques.isChecked}
                  onChange={handleChange}
                />
              </div>
              <div
                className={
                  !electricite?.radiateursElectriques.isChecked
                    ? "hide"
                    : "col-2 fs-6 ml-3 text-left"
                }
              >
                <InputForm
                  inputName="radiateursElectriques.radiateurs"
                  type="Number"
                  placeholder="4"
                  value={electricite?.radiateursElectriques.radiateurs.length}
                  onChange={({ inputName, value }) =>
                    handleChange({
                      inputName,
                      value: getNewArray({
                        value,
                        max: 50,
                        array: electricite.radiateursElectriques.radiateurs,
                        initialObject: {
                          categorie: "condesateurElectrique",
                          puissance: undefined,
                        },
                      }),
                    })
                  }
                />
              </div>
            </div>

            <div
              className={
                !electricite?.radiateursElectriques.isChecked ? "hide" : "row"
              }
            >
              {electricite?.radiateursElectriques.radiateurs.map((r, index) => {
                return (
                  <div
                    className="col-4 d-flex flex-column text-left ml-3 sub-item"
                    style={{ marginBottom: "12px" }}
                  >
                    <p className="inline-block ml-5 mb-1">
                      Radiateur n°{index + 1}
                    </p>
                    <div
                      className={
                        !true ? "hide" : "row col-12 text-left ml-3 mt-0"
                      }
                    >
                      <div className="col-8">
                        <SelectVersatile
                          label="Type de radiateur"
                          options={[
                            {
                              title: "Condensateur Électrique",
                              value: "condesateurElectrique",
                            },
                            {
                              title: "Sèche Serviette",
                              value: "secheServiette",
                            },
                          ]}
                          inputName={`radiateursElectriques.radiateurs.${index}.categorie`}
                          value={r.categorie}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-4">
                        <InputForm
                          label="Puissance"
                          inputName={`radiateursElectriques.radiateurs.${index}.puissance`}
                          type="Number"
                          placeholder="80"
                          prepend="W"
                          value={r.puissance}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotElectricite;
