import React from "react";
import defined from "../../../ressources/defined";
import TripleBarChart from "../../../toolsComponents/charts/TripleBarChart";
import TripleLineChart from "../../../toolsComponents/charts/TripleLineChart";
import { numberWithSpaces } from "../../../ressources/tools";
import UneEtudeValeursFoncieresHeader from "./UneEtudeValeursFoncieresHeader";
import SimpleWarning from "../../../toolsComponents/SimpleWarning";

const UneEtudeValeursFoncieresAppartements = ({ etude }) => {
  let dataRepartition = [];

  let dvf_last_year = null;
  const dataEvo = [];
  let totalVentes = 0;

  if (
    etude &&
    etude.valeurs_foncieres &&
    etude.valeurs_foncieres.appartements
  ) {
    dvf_last_year = etude.valeurs_foncieres.appartements.filter(
      (v) => v?.annee === 2022
    )[0];
    if (dvf_last_year?.global?.nb_transactions < defined.YEAR_SALES_THRESHOLD) {
      dvf_last_year = etude.valeurs_foncieres.appartements.filter(
        (v) => v?.annee === 2021
      )[0];
    }

    dataRepartition = [
      {
        name: `inf. 40m² (${dvf_last_year?.inf40.nb_transactions} ventes)`,
        bas: dvf_last_year?.inf40.prix_bas,
        moyen: dvf_last_year?.inf40.prix_moyen,
        haut: dvf_last_year?.inf40.prix_haut,
      },
      {
        name: `entre 40 et 80m² (${dvf_last_year?.inf80.nb_transactions} ventes)`,
        bas: dvf_last_year?.inf80.prix_bas,
        moyen: dvf_last_year?.inf80.prix_moyen,
        haut: dvf_last_year?.inf80.prix_haut,
      },
      {
        name: `sup. 80m² (${dvf_last_year?.sup.nb_transactions} ventes)`,
        bas: dvf_last_year?.sup.prix_bas,
        moyen: dvf_last_year?.sup.prix_moyen,
        haut: dvf_last_year?.sup.prix_haut,
      },
    ];

    for (const v of etude.valeurs_foncieres.appartements) {
      if (
        v.annee === 2022 &&
        v.global.nb_transactions < defined.YEAR_SALES_THRESHOLD
      )
        continue;

      totalVentes += v.global.nb_transactions;

      dataEvo.push({
        name: `${v.annee} (${v.global.nb_transactions})`,
        bas: v.global.prix_bas,
        moyen: v.global.prix_moyen,
        haut: v.global.prix_haut,
      });
    }
  }

  if (totalVentes < defined.SALES_THRESHOLD) {
    return (
      <SimpleWarning>
        Malheureusement il n'y a{" "}
        <strong>
          pas assez de ventes d'appartements ({totalVentes}) dans la base de
          l'INSEE pour cette ville
        </strong>
        . Il est donc impossible de calculer automatiquement les valeurs
        foncières.
      </SimpleWarning>
    );
  }

  if (totalVentes >= defined.SALES_THRESHOLD) {
    return (
      <>
        <div className="card">
          <div className="card-body" style={{ textAlign: "center" }}>
            <div className="mb-3">
              {/** Header */}
              <UneEtudeValeursFoncieresHeader
                label="Appartements"
                totalVentes={totalVentes}
                valeursFoncieres={dvf_last_year}
              />

              <br />

              <div className="row">
                <div className="col">
                  <a
                    href={defined.ANCHOR}
                    data-toggle="modal"
                    data-target="#moreInfosVF"
                  >
                    Comment interprêter ces chiffres ?
                  </a>
                </div>
              </div>
              <br />

              <div className="row">
                <h3>
                  Evolution des prix au m² entre 2016 et {dvf_last_year?.annee}
                </h3>

                <TripleLineChart
                  data={dataEvo}
                  XDataKey="name"
                  Y3DataKeys={["bas", "moyen", "haut"]}
                  YUnit="€"
                  line3colors={["#27ae60", "#335eea", "#c0392b"]}
                />
              </div>
              <br />

              <p>
                <em>Entre parenthèses, le nombre de ventes par an.</em>
              </p>
              <br />

              <div className="row">
                <h3>Répartition des prix au m² en {dvf_last_year?.annee}</h3>

                <TripleBarChart
                  data={dataRepartition}
                  XDataKey="name"
                  Y3DataKeys={["bas", "moyen", "haut"]}
                  YUnit="€"
                  bar3colors={["#27ae60", "#335eea", "#c0392b"]}
                />
              </div>
              <br />

              <div className="row">
                <div className="col">
                  <p>
                    <i className="fe fe-info"></i> Informations
                    <br />
                    <span className="text-muted">
                      Inférieur à 40m² correspond en général à un studio ou un 2
                      pièces.
                      <br />
                      Entre 40 et 80m² correspond en général à un 3 ou 4 pièces.
                      <br />
                      Au dessus de 80m² correspond en général à un 4 pièces et
                      +.
                    </span>
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p style={{ fontSize: "0.8em", marginBottom: "0px" }}>
                    <em>
                      Estimations basées sur {numberWithSpaces(totalVentes)}{" "}
                      ventes entre 2016 et {dvf_last_year?.annee}
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default UneEtudeValeursFoncieresAppartements;
