import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import SelectNationnalite from '../../toolsComponents/SelectNationnalite'
import SelectPays from '../../toolsComponents/SelectPays'

const NouvelEmprunteur = () => {

    return (
        <div>

            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        
                        <div className="col-12 col-lg-10 col-xl-8">
                            {/* <!-- HEADER --> */}
                            <div className="header mt-md-5">
                            <div className="header-body">
                                <div className="row align-items-center">
                                <div className="col">

                                    {/* <!-- Pretitle --> */}
                                    <h6 className="header-pretitle">
                                    Nouveau
                                    </h6>

                                    {/* <!-- Title --> */}
                                    <h1 className="header-title">
                                    Ajouter un emprunteur supplémentaire
                                    </h1>

                                </div>
                                </div> {/* <!-- / .row --> */}
                            </div>
                            </div>
                        


                            <div className="row">

                                {/* <!-- FORMULAIRE --> */}
                                <div className="col-12 col-xl-12">

                                    <form className="mb-4">

                                        {/* <!-- Prénom et nom --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Prénom
                                                    </label>

                                                    <input type="text" className="form-control" />

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Nom
                                                    </label>

                                                    <input type="text" className="form-control" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Adresse email et téléphone --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Adresse email
                                                    </label>

                                                    <input type="text" className="form-control" />

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Téléphone
                                                    </label>

                                                    <input type="text" className="form-control" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        <hr />

                                        {/* <!-- Adresse de l'artisan --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Adresse
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control"/>

                                        </div>

                                        {/* <!-- Ville et code postal --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Code postal
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" />

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Ville
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* Pays */}
                                        <SelectPays />

                                        <hr />

                                        {/* <!-- Date et lieu de naissance --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Date de naissance
                                                    </label>

                                                    <input type="text" className="form-control" />

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    <label>
                                                        Lieu de naissance
                                                    </label>

                                                    <input type="text" className="form-control" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* Nationnalité */}
                                        <SelectNationnalite />

                                        <hr />

                                        {/* <!-- Type d'emploi et employeur --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Type d'emploi
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <select className="form-control">
                                                        <option selected></option>
                                                        <option >Entrepreneur</option>
                                                        <option>Fonctionnaire</option>
                                                        <option>Salarié</option>
                                                    </select>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Employeur
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" value=""/>

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Fonction --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Fonction
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" value=""/>

                                                </div>

                                                </div>
                                            
                                        </div> {/* <!-- / .row --> */}

                                        <hr />

                                        {/* <!-- Salaire et épargne --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Salaire net
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="0" />
                                                    </div>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        &Eacute;pargne personnelle
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="0" />
                                                    </div>

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Patrimoine --> */}
                                        <div className="row">
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Patrimoine personnel
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="0" />
                                                    </div>

                                                </div>

                                                </div>
                                            
                                        </div> {/* <!-- / .row --> */}


                                        <div className="form-group">
                                            <div className="float-right">
                                                {/* <button type="submit" className="btn btn-primary mb-2">Ajouter</button> */}
                                                <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/emprunteurs/Gaetan-BERTUIT" className="btn btn-primary ml-2">
                                                    Ajouter
                                                </a>
                                            </div>
                                        </div>

                                        <br /><br />
                                    </form>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NouvelEmprunteur