import React from 'react'
import { useState } from 'react'
import InputForm from '../../../../toolsComponents/forms/InputForm'
import PasswordInputForm from '../../../../toolsComponents/forms/PasswordInputForm'
import SimpleError from '../../../../toolsComponents/SimpleError'

const RegisterInfosLogin = ({ payment_process, onSubmit, onLogin }) => {

  const [ prenom, setPrenom ] = useState('')
  const [ telephone, setTelephone ] = useState('') 
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')

  const [ errorPrenom, setErrorPrenom ] = useState(null)
  const [ errorTelephone, setErrorTelephone ] = useState(null)
  const [ errorEmail, setErrorEmail ] = useState(null)
  const [ errorPassword, setErrorPassword ] = useState(null)

  const clearErrors = () => {
    setErrorPrenom(null)
    setErrorTelephone(null)
    setErrorEmail(null)
    setErrorPassword(null)
  }

  const handleTelephone = (e) => {
    let cleaned = ('' + e.value).replace(/\D/g, '');

    if( cleaned.length === 1 && cleaned !== '0' ) {
      setTelephone('0' + cleaned)
    }
    else if( cleaned.length <= 2 ) {
      setTelephone(cleaned.substring(0, 2))
    }
    else if( cleaned.length <= 4 ) {
      setTelephone(cleaned.substring(0, 2) + ' ' + cleaned.substring(2, 4))
    }
    else if( cleaned.length <= 6 ) {
      setTelephone(cleaned.substring(0, 2) + ' ' + cleaned.substring(2, 4) + ' ' + cleaned.substring(4, 6))
    }
    else if( cleaned.length <= 8 ) {
      setTelephone(cleaned.substring(0, 2) + ' ' + cleaned.substring(2, 4) + ' ' + cleaned.substring(4, 6) + ' ' + cleaned.substring(6, 8))
    }
    else {
      setTelephone(cleaned.substring(0, 2) + ' ' + cleaned.substring(2, 4) + ' ' + cleaned.substring(4, 6) + ' ' + cleaned.substring(6, 8) + ' ' + cleaned.substring(8, 10))
    }
  }

  const passwordIsValid = () => {  
    // Mot de passe inférieure à 8 caractères ?
    if( password.length < 8 ) {
      return false
    }
    // Mot de passe sans chiffre ?
    else if( !password.match(/[0-9]/g) ) {
      return false
    }
    // Mot de passe sans majuscule ?
    else if( !password.match(/[A-Z]/g) ) {
      return false
    }
    // Mot de passe sans caractères spéciaux
    else if( !password.match(/[$&+,:;=?@#|'<>.^*()%!-]/g) ) {
      return false
    }
    else {
      return true
    }
  }

  const handleSubmit = () => {
    let isFormValid = true
    clearErrors()

    if( prenom.length < 2 ) {
      isFormValid = false
      setErrorPrenom('Le prénom doit au moins faire 2 caractères')
    }

    if( telephone.length < 14 ) {
      isFormValid = false
      setErrorTelephone("Le numéro de téléphone n'a pas le bon format")
    }

    if( !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      isFormValid = false
      setErrorEmail("L'adresse email n'a pas le bon format")
    }

    if( !passwordIsValid() ) {
      isFormValid = false
      setErrorPassword(<>
        <p>Le mot doit avoir :</p>
          <ul>
            <li>&nbsp;&nbsp;Au moins 8 caractères</li>
            <li>&nbsp;&nbsp;Un chiffre</li>
            <li>&nbsp;&nbsp;Une majuscule</li>
            <li>&nbsp;&nbsp;Un caractère spécial</li>
          </ul>
        </>
      )
    }

    if(isFormValid) {
      onSubmit({
        prenom,
        telephone: telephone.replaceAll(' ', ''),
        email,
        password
      })
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h1 className="display-4 text-center mb-3">
            Bienvenue !
          </h1>
          
          <p className="text-muted text-center mb-5">
            Créez votre compte et accédez à projetimmo.io 
            {payment_process?.jours_essai > 0 &&
              <>
                <br /><strong>GRATUITEMENT pendant {payment_process?.jours_essai} jours</strong> !
              </>
            }
          </p>

          <InputForm
            label="Prénom"
            placeholder={"Votre prénom"}
            type='text'
            value={prenom}
            inputName="prenom"
            onChange={(e) => setPrenom(e.value)}
          />

          {errorPrenom &&
            <SimpleError withHeader={false}>
              {errorPrenom}
            </SimpleError>
          }

          <InputForm
            label="Numéro de téléphone"
            placeholder={"06 55 55 55 55"}
            type={"text"}
            value={telephone}
            inputName="telephone"
            onChange={handleTelephone}
          />

          {errorTelephone &&
            <SimpleError withHeader={false}>
              {errorTelephone}
            </SimpleError>
          }

          <InputForm
            label="Votre meilleure adresse e-mail"
            placeholder={"nom@exemple.com"}
            type='email'
            value={email}
            inputName="email"
            onChange={(e) => setEmail(e.value)}
          />

          {errorEmail &&
            <SimpleError withHeader={false}>
              {errorEmail}
            </SimpleError>
          }

          <PasswordInputForm
            label={"Mot de passe"}
            placeholder={"Votre de mot passe"}
            value={password}
            inputName="password"
            onChange={(e) => setPassword(e.value)}
          />

          {errorPassword &&
            <SimpleError withHeader={false}>
              {errorPassword}
            </SimpleError>
          }

          <button className="btn btn-lg btn-block btn-primary mb-3" onClick={handleSubmit}>
            S'inscrire
          </button>

          {/* <div className="text-center">
            <small className="text-muted text-center">
              Vous avez déjà un compte ? <a href="#!" onClick={onLogin}>Connectez-vous</a>.
            </small>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default RegisterInfosLogin