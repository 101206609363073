import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../toolsComponents/Navigation'
import SimpleContainedError from '../../toolsComponents/SimpleContainedError'
import { loginDatasSelector } from '../auth/login/loginSlice'
import UneBanqueHeader from './UneBanqueHeader'
import UneBanqueOffresPret from './UneBanqueOffresPret'
import { performGetUneBanqueOffresPret, uneBanqueOffresPretFilterSelector, uneBanqueOffresPretPaginatedDatasSelector, uneBanqueOffresPretPaginationSelector, uneBanqueOffresPretStatusSelector } from './uneBanqueOffresPretSlice'
import { performGetUneBanque, uneBanqueDatasSelector } from './uneBanqueSlice'

const UneBanqueOffresPretWithStore = ( { id } ) => {

  const [ cookies ] = useCookies(['token', 'email'])
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(uneBanqueOffresPretStatusSelector)
  const offresPret = useSelector(uneBanqueOffresPretPaginatedDatasSelector)
  const pagination = useSelector(uneBanqueOffresPretPaginationSelector)
  const filter = useSelector(uneBanqueOffresPretFilterSelector)

  const banque = useSelector(uneBanqueDatasSelector)

  useEffect(() => {
    // Get offres pret
    dispatch(performGetUneBanqueOffresPret(cookies.token, id))

    // Get banque
    dispatch(performGetUneBanque(cookies.token, id))
  }, [dispatch, cookies.token, id])

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      {hasError && 
        <SimpleContainedError>
          {errorMessage}
        </SimpleContainedError>
      }

      {!hasError &&
        <div className="main-content">
          <UneBanqueHeader 
            isActive="offres-de-pret"
            banque={banque}
          />

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <UneBanqueOffresPret 
                  banque={banque}
                  offres_pret={offresPret} 
                  loading={loading}
                  pagination={pagination}
                  filter={filter}
                  />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default UneBanqueOffresPretWithStore