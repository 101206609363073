import React, {useEffect, useState} from 'react';
import defined from '../../../ressources/defined'

const RegisterFlow = ({step}) => {

  const [ flowStep, setFlowStep ] = useState(1)

  useEffect(() => {

  if(step === defined.REGISTER_STEPS.LOGIN) {

   setFlowStep(1);

  }
  else if(step === defined.REGISTER_STEPS.FACTURATION) {
    setFlowStep(2);
  }
  else if(step === defined.REGISTER_STEPS.CHECKOUT) {
    setFlowStep(3);
  }

  else if(step === defined.REGISTER_STEPS.FINISH){
    setFlowStep(4);
  }

  }, [step])
  

  return (
    <div className="signup-progress-container">

      <div className="signup-progress-step">
        <div className="avatar avatar-sm">
          <div className={`avatar-title fs-lg rounded-circle ${flowStep > 1 ? 'signup-process-step-done' : 'signup-process-step-doing'}`}>
             <i className="fe fe-user-plus"></i>
          </div>
        </div>
        <div className={`signup-progress-step-title' ${flowStep > 1 ? 'signup-process-step-title-done' : 'signup-process-step-title-doing' }`}>Inscription</div>
      </div>

      <div className={`signup-progress-stepline ${flowStep > 1 ? 'signup-process-step-done' : 'signup-process-step-todo'}`}></div>


      <div className="signup-progress-step">
        <div className="avatar avatar-sm">
          <div className={`avatar-title fs-lg rounded-circle ${flowStep < 3 ? 'signup-process-step-todo' : flowStep === 3 ? 'signup-process-step-doing': 'signup-process-step-done'}`}>
             <i className="fe fe-award"></i>
          </div>
          </div>
          <div className={`signup-progress-step-title' ${flowStep > 3 ? 'signup-process-step-title-done' : flowStep === 3 ? 'signup-process-step-title-doing' : 'signup-process-step-title-todo' }`}>Offre</div>
      </div>

      <div className={`signup-progress-stepline ${flowStep > 3 ? 'signup-process-step-done' : 'signup-process-step-todo'}`}></div>

      <div className="signup-progress-step">
        <div className="avatar avatar-sm">
          <div className={`avatar-title fs-lg rounded-circle ${flowStep < 4 ? 'signup-process-step-todo' : flowStep === 4 ? 'signup-process-step-done' : 'signup-process-step-done'}`}>
             <i className="fe fe-check-circle"></i>
          </div>
        </div>
        <div className={`signup-progress-step-title' ${flowStep === 4 ? 'signup-process-step-title-done' : 'signup-process-step-title-todo' }`}>Terminé !</div>
      </div>
    </div>
  )
}

export default RegisterFlow