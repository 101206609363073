import React from 'react'
import UneCalculatriceRentabilite from '../../uneCalculatrice/UneCalculatriceRentabilite'

const UnProjetRentabilite = ({ projet, onChangeInfosGenerales, onChangeCredit, onChangeRevenus, onChangeCharges, onChangeEndettement, onSubmit }) => {

  return (
    <>
      <UneCalculatriceRentabilite
        projet={projet}
        onChangeInfosGenerales={onChangeInfosGenerales}
        onChangeCredit={onChangeCredit}
        onChangeRevenus={onChangeRevenus}
        onChangeCharges={onChangeCharges}
        onChangeEndettement={onChangeEndettement}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default UnProjetRentabilite