import React from 'react'
import Navigation from '../../../toolsComponents/Navigation'
import UneEtudeHeader from '../UneEtudeHeader'

const UneEtudeAgents = () => {
  return (
    <div>
      <Navigation granted_modules="ALL" />

      <div className="main-content">

        <UneEtudeHeader isActive="agents" />

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col">
                    <a href="https://www.meilleursagents.com/prix-immobilier/colmar-68000/" target="_blank" rel="noreferrer">
                      Afficher la carte des prix de Colmar dans meilleurs agents.
                    </a>
                  </div>
                </div>

                <br /><br />

                <div className="row">
                  {/* <!-- FORMULAIRE --> */}
                  <div className="col-12">
                    <form>
                      <h2>Tarifs</h2>

                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">
                          Prix d'achat moyen au m² pour un appartement
                        </label>

                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">m²</div>
                          </div>

                          <input type="number" className="form-control" id="recipient-name" value="1958"/>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Evolution il y a 1 an
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">%</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="3.4"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Evolution il y a 2 ans
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">%</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="1.8"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Evolution il y a 5 ans
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">%</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="8.8"/>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h2>Quartiers</h2>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Premier quartier idéal
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Centre"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Deuxième quartier idéal
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Gare"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Troisième quartier idéal
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Maraichers"/>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Premier quartier à éviter
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Ouest"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Deuxième quartier à éviter
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Nord"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Troisième quartier à éviter
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value=""/>
                          </div>
                        </div>
                      </div>

                      {/* <!-- Sauvegarder --> */}
                      <div className="form-group">
                        <div className="float-right">
                          <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  
    </div>
  )
}

export default UneEtudeAgents