const defined = {
    TOKEN_EXPIRED:          'Token Expired',
    INVALID_AUTH_LEVEL:     'Invalid auth level',
    ANCHOR:                 '#',
    SALES_THRESHOLD:        30,
    YEAR_SALES_THRESHOLD:   5,

    COLOR_GREEN:            '#27ae60',
    COLOR_GREEN_SOFT:       '#ccf7e5',
    COLOR_BLUE:             '#335eea',
    COLOR_BLUE_SOFT:        '#d5e5fa',
    COLOR_RED:              '#c0392b',
    COLOR_GREY:             '#6e84a3',
    COLOR_GREY_SOFT:        '#e2e6ed',
    COLOR_ORANGE:           '#e67e22',
    COLOR_BLACK:            '#000000',

    REGISTER_STEPS: {
      LOGIN:                'LOGIN',
      FACTURATION:          'FACTURATION',
      CHECKOUT:             'CHECKOUT',
      FINISH:               'FINISH'
    }
}

export default defined