import React, { useState } from "react";
import SelectDocumentCategory from "../SelectDocumentCategory";
import SimpleError from "../SimpleError";
import InputForm from "../forms/InputForm";
import { useEffect } from "react";

const EditUnDocumentModal = ({ document, onSubmit }) => {
  const [titre, setTitre] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [isTitreError, setTitreError] = useState(false);
  const [isCategorieError, setCategorieError] = useState(false);

  const [isDismissable, setIsDismissable] = useState(false);

  useEffect(() => {
    setTitre(document?.titre);
    setCategory(document?.category);
    setSubCategory(document?.subCategory);
  }, [document]);

  const checkIfDismissable = (t, c, s) => {
    if (t !== "" && c !== "" && s !== "") setIsDismissable(true);
    else setIsDismissable(false);
  };

  const handleChangeTitre = (e) => {
    setTitre(e.value);

    if (e.value !== "") setTitreError(false);
    else setTitreError(true);

    checkIfDismissable(e.value, category, subCategory);
  };

  const handleChangeCategory = (e) => {
    setCategory(e.categorie);
    setSubCategory(e.subCategorie);

    if (e.categorie !== "" && e.subCategorie !== "") setCategorieError(false);
    else setCategorieError(true);

    checkIfDismissable(titre, e.categorie, e.subCategorie);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let submit = true;

    if (titre === "") {
      setTitreError(true);
      submit = false;
    }

    if (category === "") {
      setCategorieError(true);
      submit = false;
    }

    if (submit)
      onSubmit({
        _id: document._id,
        titre,
        category,
        subCategory,
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id={`modal-edit-document`}
        tabindex="-1"
        role="dialog"
        aria-labelledby={`modal-edit-document`}
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`edit-document-bien-label`}>
                Editer les propriétés d'un document
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <InputForm
                label={"Titre"}
                inputName={"titre"}
                value={titre}
                onChange={handleChangeTitre}
              />

              {isTitreError && (
                <SimpleError withHeader={false}>
                  Le titre est requis
                </SimpleError>
              )}

              <SelectDocumentCategory
                inputName={"category"}
                categorie={category}
                subCategorie={subCategory}
                onChange={handleChangeCategory}
              />

              {isCategorieError && (
                <SimpleError withHeader={false}>
                  La catégorie et sous catégorie sont requises
                </SimpleError>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-dismiss={isDismissable ? "modal" : ""}
                onClick={handleSubmit}
              >
                Mettre à jour
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUnDocumentModal;
