import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";
import SelectVersatile from "../../../../toolsComponents/SelectVersatile";
import { getNewArray } from "../utils";

const LotMenuiseriesInterieures = ({ menuiserieInt, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("menuiserieInt", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot Menuiseries Intérieures"
            id="lotMenuiseriesInterieures"
            inputName="lotMenuiserieInt.isChecked"
            isChecked={menuiserieInt?.lotMenuiserieInt.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !menuiserieInt?.lotMenuiserieInt.isChecked
                ? "hide"
                : "sous-lot col-12"
            }
          >
            <Checkbox
              label="Porte Palière"
              id="portePaliere"
              inputName="portePaliere.isChecked"
              isChecked={menuiserieInt?.portePaliere.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !menuiserieInt?.portePaliere.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 text-left"
              }
            >
              <div className="col-4">
                <SelectVersatile
                  label="Sens d'ouverture"
                  options={[
                    { title: "Gauche", value: "gauche" },
                    { title: "Droite", value: "droite" },
                  ]}
                  inputName="portePaliere.sens"
                  value={menuiserieInt?.portePaliere.sens}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Largeur de passage"
                  inputName="portePaliere.largeur"
                  type="Number"
                  placeholder="80"
                  prepend="cm"
                  value={menuiserieInt?.portePaliere.largeur}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Hauteur"
                  inputName="portePaliere.hauteur"
                  type="Number"
                  placeholder="220"
                  prepend="cm"
                  value={menuiserieInt?.portePaliere.hauteur}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <Checkbox
                  label="Portes Intérieures"
                  id="portesInterieures"
                  inputName="portesInt.isChecked"
                  isChecked={menuiserieInt?.portesInt.isChecked}
                  onChange={handleChange}
                />
              </div>
              <div
                className={
                  !menuiserieInt?.portesInt.isChecked
                    ? "hide"
                    : "col-3 fs-6 ml-3 text-left"
                }
              >
                <InputForm
                  inputName="portesInt.portes"
                  type="Number"
                  placeholder="4"
                  value={menuiserieInt?.portesInt.portes.length}
                  onChange={({ inputName, value }) =>
                    handleChange({
                      inputName,
                      value: getNewArray({
                        value,
                        max: 50,
                        array: menuiserieInt.portesInt.portes,
                        initialObject: {
                          nom: undefined,
                          categorie: "normale",
                          sens: "gauche",
                          largeur: undefined,
                          hauteur: undefined,
                        },
                      }),
                    })
                  }
                />
              </div>
            </div>

            <div
              className={!menuiserieInt?.portesInt.isChecked ? "hide" : "row"}
            >
              {menuiserieInt?.portesInt.portes.map((p, index) => {
                return (
                  <>
                    <div
                      className="col-5 text-left sub-item"
                      style={{ margin: "12px" }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <InputForm
                            label={`Nommez la porte intérieure n°${index + 1}`}
                            inputName={`portesInt.portes.${index}.nom`}
                            type="Text"
                            placeholder="Porte salle de bain"
                            value={p.nom}
                            onChange={handleChange}
                          />
                          <br />
                        </div>

                        <div className="col-6">
                          <SelectVersatile
                            label="Type"
                            options={[
                              { title: "Normale", value: "normale" },
                              {
                                title: "Coulissante en applique",
                                value: "coulissante",
                              },
                              { title: "À galandage", value: "galandage" },
                            ]}
                            inputName={`portesInt.portes.${index}.categorie`}
                            value={p.categorie}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6">
                          <SelectVersatile
                            label="Sens d'ouverture"
                            options={[
                              { title: "Gauche", value: "gauche" },
                              { title: "Droite", value: "droite" },
                            ]}
                            inputName={`portesInt.portes.${index}.sens`}
                            value={p.sens}
                            onChange={handleChange}
                          />
                          <br />
                        </div>
                        <div className="col-6">
                          <InputForm
                            label="Largeur de passage"
                            inputName={`portesInt.portes.${index}.largeur`}
                            type="Number"
                            placeholder="100"
                            prepend="cm"
                            value={p.largeur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-6">
                          <InputForm
                            label="Hauteur"
                            inputName={`portesInt.portes.${index}.hauteur`}
                            type="Number"
                            placeholder="150"
                            prepend="cm"
                            value={p.hauteur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotMenuiseriesInterieures;
