import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UneEtudeHeader from './UneEtudeHeader'

const UneEtude = () => {

  return (
    <div>
      <Navigation granted_modules="ALL" />

      <div className="main-content">

        <UneEtudeHeader isActive="general" />

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="row">
                  {/* <!-- FORMULAIRE --> */}
                  <div className="col-12">
                    <form>
                      {/* TODO : A changer en liste déroulante readonly */}
                      {/* <!-- Ville --> */}
                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">
                          Ville à étudier
                        </label>

                        <input type="text" className="form-control" id="recipient-name" value="Colmar"/>
                      </div>

                      <h2>Tarifs</h2>

                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">
                          Prix d'achat moyen au m² pour un appartement
                        </label>

                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">€ / m²</div>
                          </div>

                          <input type="number" className="form-control" id="recipient-name" value="1958"/>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Evolution il y a 1 an
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">%</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="3.4"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Evolution il y a 2 ans
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">%</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="1.8"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Evolution il y a 5 ans
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">%</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="8.8"/>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h2>Démographie</h2>

                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">
                          Population actuelle
                        </label>

                        <input type="number" className="form-control" id="recipient-name" value="68703"/>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Population il y a 5 ans
                            </label>

                            <input type="number" className="form-control" id="recipient-name" value="69889"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Population il y a 10 ans
                            </label>

                            <input type="number" className="form-control" id="recipient-name" value="67409"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Population il y a 20 ans
                            </label>

                            <input type="number" className="form-control" id="recipient-name" value="63498"/>
                          </div>
                        </div>
                      </div>

                      <h2>Quartiers</h2>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Premier quartier idéal
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Centre"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Deuxième quartier idéal
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Gare"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Troisième quartier idéal
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Maraichers"/>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Premier quartier à éviter
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Ouest"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Deuxième quartier à éviter
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value="Nord"/>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Troisième quartier à éviter
                            </label>

                            <input type="text" className="form-control" id="recipient-name" value=""/>
                          </div>
                        </div>
                      </div>

                      <h2>Transports</h2>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Distance d'une autoroute
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">km</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="1"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Distance d'une nationale
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">km</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="1"/>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">
                              Distance d'une départementale
                            </label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">km</div>
                              </div>

                              <input type="number" className="form-control" id="recipient-name" value="1"/>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Aéroport
                        </label>

                        <select className="form-control" value="non">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Gare ferrovière
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Gare routière
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <h2>Infrastructures</h2>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Hopital
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Ecole supérieure
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <h2>Nuisances</h2>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Zone innondable
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="non">Non</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="typedetravaux">
                          Zone de bruits
                        </label>

                        <select className="form-control" value="oui">
                          <option value="">---</option>
                          <option value="oui">Oui</option>
                          <option value="oui">Non</option>
                        </select>
                      </div>
                      
                      {/* Notes */}
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">
                          Notes
                        </label>

                        <textarea value="Plus belle ville au monde" className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                      </div>

                      {/* <!-- Sauvegarder --> */}
                      <div className="form-group">
                        <div className="float-right">
                          <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  
    </div>
  )
}

export default UneEtude