import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../toolsSlice/objectSliceHelper'

const URL = '/videos/'
const helper = new objectSliceHelper()

const unTutorielSlice = createSlice({

  name: 'unTutoriel',
  initialState: helper.getInitialState(),
  reducers: helper.reducers
})

export const performGetUnTutoriel = (token, id) => {
  return async dispatch => {
    try {
      dispatch(getInit())

      const res = await helper.performGetAPICall(token, id, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(getSuccess(json.datas))
      else dispatch(getFailure(json.message))
    }
    catch(e) {
      dispatch(getFailure(e.toString()))
    }
  }
}

// Export action creators
const { getInit, getSuccess, getFailure } = unTutorielSlice.actions

// Export reducer
export default unTutorielSlice.reducer

// Selectors
export const unTutorielStatusSelector = (state) => state.unTutoriel.status
export const unTutorielDatasSelector = (state) => state.unTutoriel.datas