import React from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import Navigation from '../../toolsComponents/Navigation'
import { loginDatasSelector } from '../auth/login/loginSlice'
import { performAddUneEtude, uneEtudeDatasSelector, uneEtudeStatusSelector } from '../uneEtude/uneEtudeSlice'
import RechercheVille from './RechercheVille'

const RechercheVilleWithStore = () => {

  const [ cookies ] = useCookies(['token', 'email', 'granted_modules'])
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage, lastAction } = useSelector(uneEtudeStatusSelector)
  const etude = useSelector(uneEtudeDatasSelector)

  const handleAdd = (nom_ville) => {
    if( nom_ville && nom_ville !== '') {
      dispatch(performAddUneEtude(cookies.token, {nom_ville}))
    }
  }

  if(!loading && !hasError && lastAction === 'ADD') {
    return <Redirect to={'/etudes/' + etude._id + '/environnement'} />
  }
  else {
    return (
      <>
      <Navigation granted_modules={granted_modules} formationCategories="rechercher une ville" />

      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {/* <!-- HEADER --> */}
              <div className="header">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-lg avatar-4by3">
                          <img src="/assets/img/mockups/france.jpeg" alt="Avatar France" className="avatar-img rounded" />
                        </div>
                      </div>

                      <div className="col ml-n3 ml-md-n2">
                        {/* <!-- Pretitle --> */}
                        <h6 className="header-pretitle">
                          Analyse
                        </h6>

                        {/* <!-- Title --> */}
                        <h1 className="header-title">
                          Rechercher une ville idéale pour investir
                        </h1>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            
              <RechercheVille onAdd={handleAdd} errorMessage={errorMessage} />
            </div>
          </div>
        </div>
      </div>
    </> 
    )
  }
}

export default RechercheVilleWithStore