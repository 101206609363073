import { createSlice } from "@reduxjs/toolkit";


export const registerCheckoutSlice = createSlice({
  name: 'registerCheckout',
  initialState: {
    status: {
      loading: false,
      hasError: false,
      errorMessage: ''
    },
    datas: {
      payment_intent_client_secret: '',
      setup_intent_client_secret: ''
    }
  },
  reducers: {
    /**
     * Register reducers
     */
    // no payload
    registerCheckoutInit: (state) => {
      state.status.loading = true

      state.datas.payment_intent_client_secret = ''
      state.datas.setup_intent_client_secret = ''
    },
    // payload : id_user
    registerCheckoutSuccess: (state, action) => {
      state.status.loading = false
      state.status.hasError = false

      state.datas = action.payload
    },
    // payload : errorMessage
    registerCheckoutFailure: (state, action) => {
      state.status.loading = false
      state.status.hasError = true

      state.status.errorMessage = action.payload
      state.datas.payment_intent_client_secret = ''
      state.datas.setup_intent_client_secret = ''
    }
  }
})

// Async thunks 
export const  performRegisterCheckout = ({email, id_offre}) => {

  return async dispatch =>  {
    dispatch(registerCheckoutInit())

    try {
      const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({email, id_offre})
      }

      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/checkout', reqOptions)
      const data = await res.json()

      if( !data.error ) {
        dispatch(registerCheckoutSuccess(data.datas))
      }
      else {
        dispatch(registerCheckoutFailure(data.message))
      }
    }
    catch(e) {
      dispatch(registerCheckoutFailure(e.toString()))
    }
  }
}

// Export action creators
const { registerCheckoutInit, registerCheckoutSuccess, registerCheckoutFailure } = registerCheckoutSlice.actions

// Export reducer
export default registerCheckoutSlice.reducer

// Selectors
export const registerCheckoutStatusSelector = (state) => state.registerCheckout.status
export const registerCheckoutDatasSelector = (state) => state.registerCheckout.datas

