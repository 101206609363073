import React from 'react'
import Navigation from '../toolsComponents/Navigation'

const Formations = () => {


    return (
        <div>
            {/* <!-- Visionner une vidéo --> */}
            <div className="modal fade" id="exampleModalVideo" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Comment poser les fondations de son investissement immobilier ?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/mSNog7Cbpfg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>


            <Navigation granted_modules="ALL" />

            <div className="main-content bg-fixed-bottom" style={{backgroundImage:'url(/assets/img/illustrations/sticky.svg)'}}>

                <div className="container-fluid">
                    <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-10">

                        {/* <!-- Header --> */}
                        <div className="header mt-md-5">
                            <div className="header-body">

                                {/* <!-- Pretitle --> */}
                                <h6 className="header-pretitle">
                                Formations
                                </h6>

                                {/* <!-- Title --> */}
                                <h1 className="header-title">
                                Apprenez à investir dans l'immobilier grâce à la Méthode T.H.R™
                                </h1>

                            </div>
                        </div>

                        {/* <!-- Main Card --> */}
                        <div className="card">

                            <div className="card-body text-center">
                                <div className="row">

                                    {/* Video 1 */}
                                    <div className="col-12 col-md-6 col-xl-4">
                                        
                                        <div className="card">

                                            <a href="#!" data-toggle="modal" data-target="#exampleModalVideo">
                                                <img src="/assets/img/mockups/video1.png" alt="..." className="card-img-top" />
                                            </a>

                                            <div className="card-body">
                                                <div className="row align-items-center">

                                                    <div className="col">

                                                        <h4 className="mb-2 name">
                                                            <a href="#!" data-toggle="modal" data-target="#exampleModalVideo">Comment poser les fondations de son investissement immobilier ?</a>
                                                        </h4>

                                                        <p className="card-text small text-muted" style={{paddingTop:"6px"}}>
                                                            Alex Ferreira
                                                        </p>

                                                    </div>
                                                    
                                                </div> 
                                            </div>

                                            <div className="card-footer card-footer-boxed">
                                                <div className="row align-items-center">

                                                    <div className="col">
                                                        <div className="row align-items-center no-gutters">

                                                            <div className="col-auto">

                                                                <div className="small mr-2">
                                                                    Difficulté : &nbsp;&nbsp; 
                                                                    <i className="fe fe-star text-warning active"></i>
                                                                </div>

                                                            </div>

                                                        </div> 
                                                    </div>
                                                    
                                                </div> 
                                            </div>
                                            
                                        </div>

                                    </div>

                                    {/* Video 2 */}
                                    <div className="col-12 col-md-6 col-xl-4">
                                        
                                        <div className="card">

                                            <a href="project-overview.html">
                                                <img src="/assets/img/mockups/video2.png" alt="..." className="card-img-top" />
                                            </a>

                                            <div className="card-body">
                                                <div className="row align-items-center">

                                                    <div className="col">

                                                        <h4 className="mb-2 name">
                                                            <a href="project-overview.html" >Comment réaliser une étude de marché dans l'immobilier ?</a>
                                                        </h4>

                                                        <p className="card-text small text-muted" style={{paddingTop:"6px"}}>
                                                            Alex Ferreira
                                                        </p>

                                                    </div>
                                                    
                                                </div> 
                                            </div>

                                            <div className="card-footer card-footer-boxed">
                                                <div className="row align-items-center">

                                                    <div className="col">
                                                        <div className="row align-items-center no-gutters">

                                                            <div className="col-auto">

                                                                <div className="small mr-2">
                                                                    Difficulté : &nbsp;&nbsp; 
                                                                    <i className="fe fe-star text-warning active"></i>
                                                                </div>

                                                            </div>

                                                        </div> 
                                                    </div>
                                                    
                                                </div> 
                                            </div>
                                            
                                        </div>

                                    </div>

                                    {/* Video 3 */}
                                    <div className="col-12 col-md-6 col-xl-4">
                                        
                                        <div className="card">

                                            <a href="project-overview.html">
                                                <img src="/assets/img/mockups/video3.png" alt="..." className="card-img-top" />
                                            </a>

                                            <div className="card-body">
                                                <div className="row align-items-center">

                                                    <div className="col">

                                                        <h4 className="mb-2 name">
                                                            <a href="project-overview.html" >Comment réagir face à la peur de l'investissement ?</a>
                                                        </h4>

                                                        <p className="card-text small text-muted" style={{paddingTop:"6px"}}>
                                                            Alex Ferreira
                                                        </p>

                                                    </div>
                                                    
                                                </div> 
                                            </div>

                                            <div className="card-footer card-footer-boxed">
                                                <div className="row align-items-center">

                                                    <div className="col">
                                                        <div className="row align-items-center no-gutters">

                                                            <div className="col-auto">

                                                                <div className="small mr-2">
                                                                    Difficulté : &nbsp;&nbsp; 
                                                                    <i className="fe fe-star text-warning active"></i>&nbsp;
                                                                    <i className="fe fe-star text-warning active"></i>
                                                                </div>

                                                            </div>

                                                        </div> 
                                                    </div>
                                                    
                                                </div> 
                                            </div>
                                            
                                        </div>

                                    </div>
                                      
                                    {/* Video 4 */}
                                    <div className="col-12 col-md-6 col-xl-4">
                                        
                                        <div className="card">

                                            <a href="project-overview.html">
                                                <img src="/assets/img/mockups/video4.png" alt="..." className="card-img-top" />
                                            </a>

                                            <div className="card-body">
                                                <div className="row align-items-center">

                                                    <div className="col">

                                                        <h4 className="mb-2 name">
                                                            <a href="project-overview.html" >Comment trouver les bons artisans du bâtiment ?</a>
                                                        </h4>

                                                        <p className="card-text small text-muted" style={{paddingTop:"6px"}}>
                                                            Alex Ferreira
                                                        </p>

                                                    </div>
                                                    
                                                </div> 
                                            </div>

                                            <div className="card-footer card-footer-boxed">
                                                <div className="row align-items-center">

                                                    <div className="col">
                                                        <div className="row align-items-center no-gutters">

                                                            <div className="col-auto">

                                                                <div className="small mr-2">
                                                                    Difficulté : &nbsp;&nbsp; 
                                                                    <i className="fe fe-star text-warning active"></i>&nbsp;
                                                                    <i className="fe fe-star text-warning active"></i>&nbsp;
                                                                    <i className="fe fe-star text-warning active"></i>
                                                                </div>

                                                            </div>

                                                        </div> 
                                                    </div>
                                                    
                                                </div> 
                                            </div>
                                            
                                        </div>

                                    </div>
                                     
                                    {/* Video 5 */}
                                    <div className="col-12 col-md-6 col-xl-4">
                                        
                                        <div className="card">

                                            <a href="project-overview.html">
                                                <img src="/assets/img/mockups/video5.png" alt="..." className="card-img-top" />
                                            </a>

                                            <div className="card-body">
                                                <div className="row align-items-center">

                                                    <div className="col">

                                                        <h4 className="mb-2 name">
                                                            <a href="project-overview.html" >Comment réaliser un grand investissement sans moyen ?</a>
                                                        </h4>

                                                        <p className="card-text small text-muted" style={{paddingTop:"6px"}}>
                                                            Alex Ferreira
                                                        </p>

                                                    </div>
                                                    
                                                </div> 
                                            </div>

                                            <div className="card-footer card-footer-boxed">
                                                <div className="row align-items-center">

                                                    <div className="col">
                                                        <div className="row align-items-center no-gutters">

                                                            <div className="col-auto">

                                                                <div className="small mr-2">
                                                                    Difficulté : &nbsp;&nbsp; 
                                                                    <i className="fe fe-star text-warning active"></i>&nbsp;
                                                                    <i className="fe fe-star text-warning active"></i>&nbsp;
                                                                    <i className="fe fe-star text-warning active"></i>&nbsp;
                                                                    <i className="fe fe-star text-warning active"></i>&nbsp;
                                                                    <i className="fe fe-star text-warning active"></i>
                                                                </div>

                                                            </div>

                                                        </div> 
                                                    </div>
                                                    
                                                </div> 
                                            </div>
                                            
                                        </div>

                                    </div>
                                    
                                </div> {/* <!-- / .row --> */}
                            </div>
                            
                        </div>

                        
                    </div>
                    </div>

                </div> {/* <!-- / .main-content --> */}
                    
            </div> 
        </div>
    )
}

export default Formations