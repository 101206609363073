import React from 'react'

const SelectTypePiece = ({ label="Type de pièce", inputName="type_piece", value, onChange }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <div className="form-group">
      <label htmlFor={inputName+'-select'}>
        {label}
      </label>

      <select className="form-control" name={inputName} id={inputName+'-select'} onChange={handleChange} value={value}>
        <option value="">---</option>
        <option value="salon">Salon</option>
        <option value="sejourcuisine">Séjour/Cuisine</option>
        <option value="cuisine">Cuisine</option>
        <option value="chambre">Chambre</option>
        <option value="salledeau">Salle d'eau</option>
        <option value="toilettes">Toilettes</option>
        <option value="autre">Autre</option>
      </select>
    </div>
  )
}

export default SelectTypePiece