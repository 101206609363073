import { createSlice } from '@reduxjs/toolkit'
import arraySliceHelper from '../../toolsSlice/arraySliceHelper'

const helper = new arraySliceHelper(['titre', 'description', 'ville'], 'notation', "5", {asc: false})

const projetsSlice = createSlice({

  name: 'projets',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    getProjetsInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of users
    getProjetsSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getProjetsFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    },

    // payload : { value to search on everyfields, fields concerned }
    filterByText: (state, { payload }) => {
      helper.performChangeFilter(state, payload)
    },

    // payload : field
    sort: (state, { payload }) => {
      helper.performChangeSort(state, payload)
    },

    // payload : { nbPerPage, currentPage }
    changePagination: (state, { payload }) => {
      helper.performChangePagination(state, payload)
    },

    // payload: { _id }
    deleteUnProjet: (state, { payload }) => {
      helper.performDeleteOne(state, payload)
    },

    // payload: nothing
    deleteUnProjetSuccess: (state) => {
      state.status.loading = false
      state.status.hasError = false
      state.status.errorMessage = null
    },

    // payload : errorMessage
    deleteUnProjetFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    },

  }
})

export const performGetProjets = (token) => {

  return async dispatch => {
    dispatch(getProjetsInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/projets?preview=1', reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const projets = json.datas
        dispatch(getProjetsSuccess(projets))
      }
      else {
        dispatch(getProjetsFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getProjetsFailure(e.toString()))
    }
  }
}

export const performDelUnProjet = (token, _id) => {

  return async dispatch => {
    dispatch(deleteUnProjet(_id))

    try {
      const reqOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/projets/' +_id, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        dispatch(deleteUnProjetSuccess())
      }
      else {
        dispatch(deleteUnProjetFailure(json.message))
      }
    }
    catch(e) {
      dispatch(deleteUnProjetFailure(e.toString()))
    }
  }
}

// Export action creators
const { getProjetsInit, getProjetsSuccess, getProjetsFailure, deleteUnProjetSuccess, deleteUnProjetFailure, deleteUnProjet } = projetsSlice.actions
export const { resetProjetsInitialState, sort, filterByText, changePagination } = projetsSlice.actions

// Export reducer
export default projetsSlice.reducer

// Selectors
export const projetsStatusSelector = (state) => state.projets.status
export const projetsFilterSelector = (state) => state.projets.filter
export const projetsDatasSelector = (state) => state.projets.datas
export const projetsFilteredDatasSelector = (state) => state.projets.filteredDatas
export const projetsPaginatedDatasSelector = (state) => state.projets.paginatedDatas
export const projetsPaginationSelector = (state) => state.projets.pagination