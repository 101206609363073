import React, { useEffect } from "react"
import { useImperativeHandle } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import Navigation from "../../toolsComponents/Navigation"
import SimpleContainedError from "../../toolsComponents/SimpleContainedError"
import SimpleLoading from "../../toolsComponents/SimpleLoading"
import UnPatrimoineHeader from "./UnPatrimoineHeader"
import { performGetUnPatrimoine, unPatrimoineDatasSelector, unPatrimoineStatusSelector } from "./unPatrimoineSlice"

const UnPatrimoineGenericWithStore = React.forwardRef(({ 
  children, 
  activeMenu, 
  activeSubMenu,
  formationCategories = "patrimoine"
  }, 
  ref) => {

  useImperativeHandle(ref, () => {
    return {
      patrimoine,
      handleAddDocument
    }
  })

  // FOR MOCKUP
  const granted_modules = "ALL"

  const dispatch = useDispatch()
  const [ cookies ] = useCookies(['token', 'email'])

  const patrimoine = useSelector(unPatrimoineDatasSelector)
  const { hasError, errorMessage, loading, lastAction } = useSelector(unPatrimoineStatusSelector)

  useEffect(() => {
    // Get un patrimoine
    dispatch(performGetUnPatrimoine(cookies.token))
  }, [dispatch, cookies.token])


  const handleAddDocument = () => {
    return
  }

  return <>
    <Navigation granted_modules={granted_modules} formationCategories={formationCategories}/>

    {hasError && 
      <SimpleContainedError>
        {errorMessage}
      </SimpleContainedError>
    }

    {!hasError &&
      <div className="main-content">
          <UnPatrimoineHeader 
            patrimoine={patrimoine}
            isActive={activeMenu}
            isActiveSubMenu={activeSubMenu}
          />

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                {loading &&
                  <SimpleLoading />
                }

                {!loading &&
                  children
                }
              </div>
            </div>
          </div>
      </div>
    }
  </>  
})

export default UnPatrimoineGenericWithStore