import React from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import UnProjetGenericWithStore from '../UnProjetGenericWithStore'
import { unProjetDatasSelector, performSyncUnProjet } from '../unProjetSlice'
import UnProjetPhotos from './UnProjetPhotos'

const UnProjetPhotosWithStore = ({ id }) => {

  const dispatch = useDispatch()
  const [ cookies ] = useCookies(['token', 'email'])

  const projet = useSelector(unProjetDatasSelector)

  const handleSubmitPhoto = (photo) => {
    const tmpProjet = JSON.parse(JSON.stringify(projet))
    const index = tmpProjet.photos.findIndex((p) => p._id === photo._id)
    tmpProjet.photos[index] = photo

    dispatch(performSyncUnProjet(cookies.token, tmpProjet))
  }

  // https://v.seloger.com/s/width/800/visuels/0/5/8/o/058oiexor8gr8kixlbugop9iliw0e0r2vfsersw00.jpg
  const handleDeletePhoto = (photo) => {
    const tmpProjet = JSON.parse(JSON.stringify(projet))
    tmpProjet.photos = projet.photos.filter(p => p._id !== photo._id)

    dispatch(performSyncUnProjet(cookies.token, tmpProjet))
  }

  const handleSubmitCategorie = ({oldValue, newValue}) => {
    const tmpProjet = JSON.parse(JSON.stringify(projet))
    for(const photo of tmpProjet.photos) {
      if( photo.categorie === oldValue ) 
        photo.categorie = newValue
    }

    dispatch(performSyncUnProjet(cookies.token, tmpProjet))
  }

  return (
    <>
      <UnProjetGenericWithStore
        id={id}
        activeMenu="general"
        activeSubMenu="photos"
      >
        <UnProjetPhotos
          projet={projet}
          onSubmitPhoto={handleSubmitPhoto}
          onDeletePhoto={handleDeletePhoto}
          onSubmitCategorie={handleSubmitCategorie}
        />
      </UnProjetGenericWithStore>
    </>
  )
}

export default UnProjetPhotosWithStore