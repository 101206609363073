import React from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const SimpleBarChart = ({ data, barColors, XDataKey, YDataKey }) => {

  if(!barColors) {
    barColors = []
    for(const d of data) {
      barColors.push("#335eea")
    }
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={240}>
        <BarChart width={400} height={200} data={data}>
          <Bar dataKey={YDataKey} label={{ position: 'top'}} >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
            ))}
          </Bar>

          <XAxis dataKey={XDataKey} />
          <YAxis />
          
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default SimpleBarChart