import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../toolsSlice/objectSliceHelper'

const helper = new objectSliceHelper()

const uneBanqueSlice = createSlice({

  name: 'uneBanque',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    getUneBanqueInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of users
    getUneBanqueSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getUneBanqueFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    }
  }
})

export const performGetUneBanque = (token, id) => {

  return async dispatch => {
    dispatch(getUneBanqueInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }

      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/banques/' + id, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const banque = json.datas
        dispatch(getUneBanqueSuccess(banque))
      }
      else {
        dispatch(getUneBanqueFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getUneBanqueFailure(e.toString()))
    }
  }
}

// Export action creators
const { getUneBanqueInit, getUneBanqueSuccess, getUneBanqueFailure } = uneBanqueSlice.actions

// Export reducer
export default uneBanqueSlice.reducer

// Selectors
export const uneBanqueStatusSelector = (state) => state.uneBanque.status
export const uneBanqueDatasSelector = (state) => state.uneBanque.datas