import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import SelectPays from '../../toolsComponents/SelectPays'
import MonProfilHeader from './MonProfilHeader'

const MonProfil = () => {


    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">

                {/* <!-- HEADER --> */}
                <MonProfilHeader isActive="general" />

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                            {/* <!-- FORMULAIRE --> */}
                            <div className="col-12">

                                <form className="mb-4">

                                    {/* <!-- Prénom et nom --> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            {/* <!-- Start date --> */}
                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Prénom
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="Nicolas"/>

                                            </div>

                                            </div>
                                        <div className="col-12 col-md-6">

                                            {/* <!-- Start date --> */}
                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Nom
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="SURREAUX"/>

                                            </div>

                                        </div>
                                    </div> {/* <!-- / .row --> */}

                                    {/* <!-- Email et mot de passe --> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Adresse email
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="surhodev@gmail.com"/>

                                            </div>

                                            </div>
                                        <div className="col-12 col-md-6">

                                            <div className="form-group">

                                                <br />

                                                {/* <!-- Input --> */}
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-success form-control"
                                                    style={{marginTop: "7px"}}
                                                >Changer de mot de passe</button>

                                            </div>

                                        </div>
                                    </div> {/* <!-- / .row --> */}

                                    {/* <!-- Téléphone --> */}
                                    <div className="row">

                                        {/* <!-- Numéro de téléphone --> */}
                                        <div className="col-12 col-md-6">

                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Numéro de téléphone
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="(+ 33) 6 66 49 17 65" />

                                            </div>

                                            </div>
                                        
                                        
                                    </div> {/* <!-- / .row --> */}

                                    <hr />

                                    {/* <!-- Adresse --> */}
                                    <div className="form-group">

                                        {/* <!-- Label  --> */}
                                        <label>
                                            Adresse
                                        </label>

                                        {/* <!-- Input --> */}
                                        <input type="text" className="form-control" value="29 Rue des Alouettes"/>

                                    </div>

                                    {/* <!-- Ville et code postal --> */}
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            {/* <!-- Start date --> */}
                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Code postal
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="48 000"/>

                                            </div>

                                            </div>
                                        <div className="col-12 col-md-6">

                                            {/* <!-- Start date --> */}
                                            <div className="form-group">

                                                {/* <!-- Label --> */}
                                                <label>
                                                    Ville
                                                </label>

                                                {/* <!-- Input --> */}
                                                <input type="text" className="form-control" value="Mende"/>

                                            </div>

                                        </div>
                                    </div> {/* <!-- / .row --> */}

                                    {/* <!-- Pays --> */}
                                    <SelectPays />

                                    <div className="form-group">
                                        <div className="float-right">
                                            <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                                        </div>
                                    </div>

                                    <br /><br />
                                </form>
                                </div>


                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default MonProfil