import React from 'react'

const MultiSelectForm = ({ label, inputName, allValues, selectedValues }) => {

  return (
    <div className="form-group">
      {/* <!-- Label  --> */}
      <label>
        {label}
      </label>

      {/* <!-- Checkboxes --> */}
      {allValues.map((v, index) => {
        const isSelected = selectedValues ? selectedValues.includes(v) : false

        return (
          <div className="form-check" key={index}>
            <input className="form-check-input" name={inputName} type="checkbox" value={v} id={`checkbox-${v}`} checked={isSelected}/>
            <label className="form-check-label" htmlFor={`checkbox-${v}`}>
              {v}
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default MultiSelectForm