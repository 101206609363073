import React from 'react'
import Navigation from '../../../toolsComponents/Navigation'
import UneEtudeHeader from '../UneEtudeHeader'

const UneEtudeWikipedia = () => {
  return (
    <>
      <Navigation granted_modules="ALL" />

      <div className="main-content">

        <UneEtudeHeader isActive="wikipedia" />

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row">
                {/* <!-- FORMULAIRE --> */}
                <div className="col-lg-4">
                  <form>
                    <h2>Démographie</h2>

                    <div className="form-group">
                      <label htmlFor="recipient-name" className="col-form-label">
                        Population actuelle
                      </label>

                      <input type="number" className="form-control" id="recipient-name" value="68703"/>
                    </div>

                    <div className="form-group">
                      <label htmlFor="recipient-name" className="col-form-label">
                        Population il y a 5 ans
                      </label>

                      <input type="number" className="form-control" id="recipient-name" value="69889"/>
                    </div>
                  
                    <div className="form-group">
                      <label htmlFor="recipient-name" className="col-form-label">
                        Population il y a 10 ans
                      </label>

                      <input type="number" className="form-control" id="recipient-name" value="67409"/>
                    </div>
                  
                    <div className="form-group">
                      <label htmlFor="recipient-name" className="col-form-label">
                        Population il y a 20 ans
                      </label>

                      <input type="number" className="form-control" id="recipient-name" value="63498"/>
                    </div>

                    {/* <!-- Sauvegarder --> */}
                    <div className="form-group">
                      <div className="float-right">
                        <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                      </div>
                    </div>
                  </form>
                </div>
              
                <div className="col-lg-8">
                  <iframe title="Wikipedia" width="100%" height="500" id="gmap_canvas" src="https://fr.wikipedia.org/wiki/Colmar" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UneEtudeWikipedia