import 'leaflet/dist/leaflet.css'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetEDM = () => {

    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">
                
                <UnProjetHeader isActive="edm"/>

                <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12">

                                {/* Statistiques */}
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl">

                                        <div className="card">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                            <div className="col">

                                                <h6 className="text-uppercase text-muted mb-2">
                                                Montant achat au m2
                                                </h6>

                                                <span className="h2 mb-0">
                                                1 586 €
                                                </span>

                                            </div>
                                            <div className="col-auto">

                                                <span className="h2 fe fe-home text-muted mb-0"></span>

                                            </div>
                                            </div>

                                        </div>
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-6 col-xl">

                                        <div className="card">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                            <div className="col">

                                                <h6 className="text-uppercase text-muted mb-2">
                                                Montant location au m2
                                                </h6>

                                                <span className="h2 mb-0">
                                                15,60 €
                                                </span>

                                            </div>

                                            <div className="col-auto">

                                                <span className="h2 fe fe-grid text-muted mb-0"></span>

                                            </div>
                                            
                                            </div> 

                                        </div>
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-6 col-xl">

                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                            <div className="col">

                                                <h6 className="text-uppercase text-muted mb-2">
                                                Population à Florac en 2020
                                                </h6>

                                                <span className="h2 mb-0">
                                                2 072
                                                </span>

                                            </div>
                                            <div className="col-auto">

                                                <span className="h2 fe fe-users text-muted mb-0"></span>

                                            </div>
                                            </div>

                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl">

                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                            <div className="col">

                                                <h6 className="text-uppercase text-muted mb-2">
                                                Valeur foncière moyenne à Florac
                                                </h6>

                                                <span className="h2 mb-0">
                                                74 870,27 €
                                                </span>

                                            </div>

                                            <div className="col-auto">

                                                <span className="h2 fe fe-map text-muted mb-0"></span>

                                            </div>
                                            
                                            </div> 

                                        </div>
                                        </div>

                                    </div>
                                </div>

                                {/* Notation */}
                                <div className="row">

                                    <div className="col-12">

                                    <div className="card">
                                            
                                            <div className="card-header">
    
                                                {/* <!-- Title --> */}
                                                <h4 className="card-header-title">
                                                    Notation automatique du bien à partir des critères de la THR
                                                </h4>
    
                                            </div>
    
                                            <div className="card-body" style={{padding:"12px"}}>
    
    
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                    <th scope="col">Critère</th>
                                                    <th scope="col"></th>
                                                    <th scope="col">Appréciation</th>
                                                    <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    <td style={{width: "27%"}}>Montant achat au m2</td>
                                                    <td>
                                                        <div className="progress">
                                                            
                                                            <div className="progress-bar bg-success" role="progressbar" style={{width: "95%"}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                                            
                                                        </div>
                                                    </td>
                                                    <td style={{width: "10%", textAlign:"right"}}>1 586 €</td>
                                                    <td style={{width: "5%"}}><a href="#!"><i className="fe fe-help-circle"></i></a></td>
                                                    </tr>

                                                    <tr>
                                                    <td>Évolution de la population</td>
                                                    <td>
                                                        <div className="progress">
                                                            
                                                            <div className="progress-bar bg-warning" role="progressbar" style={{width: "60%"}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                            
                                                        </div>
                                                    </td>
                                                    <td style={{width: "10%", textAlign:"right"}}>Plutôt stable</td>
                                                    <td style={{width: "5%"}}><a href="#!"><i className="fe fe-help-circle"></i></a></td>
                                                    </tr>

                                                    <tr>
                                                    <td>Présence d'entreprise</td>
                                                    <td>
                                                        <div className="progress">
                                                            
                                                            <div className="progress-bar bg-success" role="progressbar" style={{width: "75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                            
                                                        </div>
                                                    </td>
                                                    <td style={{width: "10%", textAlign:"right"}}>615</td>
                                                    <td style={{width: "5%"}}><a href="#!"><i className="fe fe-help-circle"></i></a></td>
                                                    </tr>

                                                    <tr>
                                                    <td>Présence de logements touristiques</td>
                                                    <td>
                                                        <div className="progress">
                                                            
                                                            <div className="progress-bar bg-danger" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            
                                                        </div>
                                                    </td>
                                                    <td style={{width: "10%", textAlign:"right"}}>3</td>
                                                    <td style={{width: "5%"}}><a href="#!"><i className="fe fe-help-circle"></i></a></td>
                                                    </tr>

                                                    <tr>
                                                    <td>Proximité d'axes routiers, gares ou aéroports</td>
                                                    <td>
                                                        <div className="progress">
                                                            
                                                            <div className="progress-bar bg-warning" role="progressbar" style={{width: "55%"}} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                                            
                                                        </div>
                                                    </td>
                                                    <td style={{width: "10%", textAlign:"right"}}>Moyennement</td>
                                                    <td style={{width: "5%"}}><a href="#!"><i className="fe fe-help-circle"></i></a></td>
                                                    </tr>

                                                    <tr>
                                                    <th scope="row">Total</th>
                                                    <td>
                                                        <div className="progress">
                                                            
                                                            <div className="progress-bar bg-success" role="progressbar" style={{width: "85%"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                                            
                                                        </div>
                                                    </td>
                                                    <td style={{width: "10%", textAlign:"right"}}>18/20</td>
                                                    <td style={{width: "5%"}}><a href="#!"><i className="fe fe-help-circle"></i></a></td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                                
                                            </div>
                                                
                                            
                                        </div>

                                    </div>
                                

                                </div>

                            </div>
                        </div>
                    </div>
    

            </div>
        </div> 
    )
}

export default UnProjetEDM