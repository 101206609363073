import React from 'react'
import LargeTable from '../../toolsComponents/tables/LargeTable'

const Projets = ({ projets, pagination, filter, onSearch, onPagination, onDelete  }) => {

  const datas = []

  // (id, url, url_img, title, sub_title, rating)
  projets.forEach((p) => {
    datas.push({
      id: p._id,
      url:  "/projets/" + p._id,
      url_img: p.url_avatar,
      title: p.titre,
      headline: `${p.ville} (${p.code_postal})`,
      sub_title: `${p.description})`,
      rating: p.notation
    })
  })

  const handleDelete = (value) => {
    onDelete(value)
  }

  const handleSearch = (value) => {
    onSearch(value)
  }

  const handlePagination = (nbPerPage, page) => {
    onPagination(nbPerPage, page)
  }

  return (               
    <LargeTable 
      datas={datas}
      pagination={pagination}
      filter={filter}
      emptyMessage="Aucun projet à afficher. Veuillez utiliser l'extension Chrome ou cliquez sur un nouveau projet pour en ajouter un."
      onPagination={handlePagination}
      onSearch={handleSearch}
      onDelete={handleDelete}
    />               
  )
}

export default Projets