import { createSlice } from '@reduxjs/toolkit'
import arraySliceHelper from '../../toolsSlice/arraySliceHelper'

const helper = new arraySliceHelper(['nom', 'code_postal', 'ville'], 'nom', "10")

const banquesSlice = createSlice({
  name: 'banques',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    getBanquesInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of users
    getBanquesSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getBanquesFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    },

    // payload : { value to search on everyfields, fields concerned }
    filterByText: (state, { payload }) => {
      helper.performChangeFilter(state, payload)
    },

    // payload : field
    sort: (state, { payload }) => {
      helper.performChangeSort(state, payload)
    },

    // payload : { nbPerPage, currentPage }
    changePagination: (state, { payload }) => {
      helper.performChangePagination(state, payload)
    }
  }
})

export const performGetBanques = (token) => {

  return async dispatch => {
    dispatch(getBanquesInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/banques', reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const banques = json.datas
        dispatch(getBanquesSuccess(banques))
      }
      else {
        dispatch(getBanquesFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getBanquesFailure(e.toString()))
    }
  }
}

// Export action creators
const { getBanquesInit, getBanquesSuccess, getBanquesFailure } = banquesSlice.actions
export const { sort, filterByText, changePagination } = banquesSlice.actions

// Export reducer
export default banquesSlice.reducer

// Selectors
export const banquesStatusSelector = (state) => state.banques.status
export const banquesFilterSelector = (state) => state.banques.filter
export const banquesDatasSelector = (state) => state.banques.datas
export const banquesFilteredDatasSelector = (state) => state.banques.filteredDatas
export const banquesPaginatedDatasSelector = (state) => state.banques.paginatedDatas
export const banquesPaginationSelector = (state) => state.banques.pagination