import { createSlice } from '@reduxjs/toolkit'
import arraySliceHelper from '../../toolsSlice/arraySliceHelper'

const helper = new arraySliceHelper(['title', 'categories'], 'title', "*")

const tutorielsSlice = createSlice({

  name: 'tutoriels',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    resetInitialState: (state) => {
      helper.resetInitialState(state)
    },

    // no payload
    getTutorielsInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of Tutoriels
    getTutorielsSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getTutorielsFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    },

    // payload : { value to search on everyfields, fields concerned }
    filterByText: (state, { payload }) => {
      helper.performChangeFilter(state, payload)
    },

    // payload : field
    sort: (state, { payload }) => {
      helper.performChangeSort(state, payload)
    },

    // payload : { nbPerPage, currentPage }
    changePagination: (state, { payload }) => {
      helper.performChangePagination(state, payload)
    }
  }
})

export const performGetTutoriels = (token) => {

  return async dispatch => {
    dispatch(getTutorielsInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/videos_by_type/tutoriel', reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const tutoriels = json.datas
        dispatch(getTutorielsSuccess(tutoriels))
      }
      else {
        dispatch(getTutorielsFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getTutorielsFailure(e.toString()))
    }
  }
}

export const performGetTutorielsByTypeAndCategories = (token, type, categories = '') => {

  return async dispatch => {
    dispatch(getTutorielsInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + `/videos_by_type/${type}/${categories}`, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const tutoriels = json.datas
        dispatch(getTutorielsSuccess(tutoriels))
      }
      else {
        dispatch(getTutorielsFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getTutorielsFailure(e.toString()))
    }
  }
}

// Export action creators
const { getTutorielsInit, getTutorielsSuccess, getTutorielsFailure } = tutorielsSlice.actions
export const { sort, filterByText, changePagination, resetInitialState } = tutorielsSlice.actions

// Export reducer
export default tutorielsSlice.reducer

// Selectors
export const tutorielsStatusSelector = (state) => state.tutoriels.status
export const tutorielsFilterSelector = (state) => state.tutoriels.filter
export const tutorielsDatasSelector = (state) => state.tutoriels.datas
export const tutorielsFilteredDatasSelector = (state) => state.tutoriels.filteredDatas
export const tutorielsPaginatedDatasSelector = (state) => state.tutoriels.paginatedDatas
export const tutorielsPaginationSelector = (state) => state.tutoriels.pagination