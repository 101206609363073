import React, { useState } from 'react'
import defined from '../../../ressources/defined'
import Vimeo from '@u-wave/react-vimeo';

const FormationOverlayItem = ({ video }) => {

  const getDifficultyStars = (difficulty) => {

    var result = []
    for (let i = 0; i < difficulty; i++) {
      result.push(<i className="fe fe-star text-warning active" style={{ paddingRight: '2px' }}></i>)
    }

    return result
  }
  const [pausedState, setPausedState] = useState(false);

  const handleFocus = () => {
    if (!pausedState) setPausedState(true)
  }

  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id={`tutoVideoModal${video._id}`} tabindex="-1" role="dialog" aria-labelledby={`tutoVideoModal${video._id}Label`} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id={`tutoVideoModal${video._id}Label`}>{video.title}</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setPausedState(true) }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Vimeo
                video={video.vimeo_id}
                responsive="true"
                paused={pausedState}
                onPause={() => { setPausedState(true) }}
                onPlay={() => { setPausedState(false) }}
              />
              <br /><br />
              <div className="row">
                <div className="col">
                  Créée par {video.author}
                </div>
                <div className="col">
                  Difficulté : {getDifficultyStars(video.difficulty)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Video item */}
      <div style={{ marginBottom: "24px" }} onFocusCapture={handleFocus}>
        <a href={defined.ANCHOR} data-toggle="modal" data-target={`#tutoVideoModal${video._id}`}>
          <img alt="Thumbnail" src={video.url_thumbnail} style={{ width: '250px' }} />
        </a>

        <h4 className="card-header-title" style={{ marginTop: "4px" }}>
          <a href={defined.ANCHOR} data-toggle="modal" data-target={`#tutoVideoModal${video._id}`}>
            {video.title}
          </a>
        </h4>
      </div>
    </>
  )
}

export default FormationOverlayItem