import React, { useEffect, useState } from 'react'
import { convertInfosGeneralesReduxToComponent, convertCreditReduxToComponent, convertRevenusReduxToComponent, convertChargesReduxToComponent, convertEndettementReduxToComponent } from '../../features/unProjet/rentabilite/rentabiliteUtils'
import helper from '../../features/uneCalculatrice/uneCalculatriceHelper'


const RentabiliteHorizontalCard = ({ projet, calculatrice, currentStep, onStep }) => {

  const getActive = (step) => {
    if( step === currentStep ) return 'rentabilite-menu-item-active'
    else return ''
  }

  const getBadgeClassName = (value, th1, th2) => {
    if(value < th1) return 'badge badge-soft-danger'
    else if( value >= th1 && value < th2 ) return 'badge badge-soft-warning'
    else return 'badge badge-soft-success'
  }

  const [ coutAvecReno, setCoutAvecReno ] = useState(0)
  const [ coutAvecRenoM2, setCoutAvecRenoM2 ] = useState(0)
  const [ coutTotalProjet, setCoutTotalProjet ] = useState(0)

  const [ apportTotal, setApportTotal ] = useState(0)
  const [ capitalEmprunter, setCapitalEmprunter ] = useState(0)
  const [ mensualite, setMensualite ] = useState(0)

  const [ revenusLDNue, setRevenusLDNue ] = useState(0)
  const [ revenusLDMeuble, setRevenusLDMeuble ] = useState(0)
  const [ revenusLCD, setRevenusLCD ] = useState(0)

  const [ chargesLDNue, setChargesLDNue ] = useState(0)
  const [ chargesLDMeuble, setChargesLDMeuble ] = useState(0)
  const [ chargesLCD, setChargesLCD ] = useState(0)

  const [ revenusMeuble, setRevenusMeuble ] = useState(0)
  const [ endettementActuel, setEndettementActuel ] = useState(0)
  const [ endettementFutur, setEndettementFutur ] = useState(0)

  const [ cashflowLDNue, setCashflowLDNue ] = useState(0)
  const [ cashflowLDMeuble, setCashflowLDMeuble ] = useState(0)
  const [ cashflowLCD, setCashflowLCD ] = useState(0)

  const updateComponent = (tmpState) => {
    setCoutTotalProjet(helper.performCoutTotalProjet(tmpState))
    setCoutAvecReno(helper.performCoutsAchatRenovation(tmpState))
    setCoutAvecRenoM2(helper.performCoutsAchatRenovationM2(tmpState))

    setApportTotal(helper.performApportTotal(tmpState))
    setCapitalEmprunter(helper.performTotalCapitalEmprunte(tmpState))
    setMensualite(helper.performMensualite(tmpState))

    setRevenusLDNue(helper.performRevenusTotalTousLogementsLDNue(tmpState))
    setRevenusLDMeuble(helper.performRevenusTotalTousLogementsLDMeuble(tmpState))
    setRevenusLCD(helper.performRevenusTotalTousLogementsLCD(tmpState))

    setChargesLDNue(helper.performTotalChargesMensuellesLDNue(tmpState))
    setChargesLDMeuble(helper.performTotalChargesMensuellesLDMeuble(tmpState))
    setChargesLCD(helper.performTotalChargesMensuellesLCD(tmpState))

    setRevenusMeuble(helper.performFonciersFutursMeuble(tmpState))
    setEndettementActuel(helper.performTauxEndettementActuel(tmpState))
    setEndettementFutur(helper.performTauxEndettementFutur(tmpState))

    setCashflowLDNue(helper.performCashflowLDNue(tmpState))
    setCashflowLDMeuble(helper.performCashflowLDMeuble(tmpState))
    setCashflowLCD(helper.performCashflowLCD(tmpState))
  }

  useEffect(() => {
    if( projet && projet._id ) {
      updateComponent({
        infosGenerales: convertInfosGeneralesReduxToComponent(projet),
        credit: convertCreditReduxToComponent(projet),
        revenus: convertRevenusReduxToComponent(projet),
        charges: convertChargesReduxToComponent(projet),
        endettement: convertEndettementReduxToComponent(projet)
      })
    }
    else if( calculatrice ) {
      updateComponent(calculatrice)
    }
  }, [projet, calculatrice])

  return <>
    <div className='row'>
      <div className='col'>
        <div className='card' style={{cursor:'pointer'}} onClick={() => onStep(1)}>
          <div className={`card-header rentabilite-menu-item ${getActive(1)}`}>
            <h4 className='card-header-title text-uppercase' style={{}}>
              Projet
            </h4>
          </div>

          <div className='card-body'>
            <p className='small text-gray-700 mb-0'>
              Coût avec réno :  <span className="badge badge-soft-primary"><strong>{coutAvecReno} €</strong></span><br />
              Côut au m² : <span className="badge badge-soft-primary"><strong>{coutAvecRenoM2} €/m²</strong></span><br />
              Coût total : <span className="badge badge-soft-primary"><strong>{coutTotalProjet} €</strong></span>
            </p>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='card' style={{cursor:'pointer'}} onClick={() => onStep(2)}>
          <div className={`card-header rentabilite-menu-item ${getActive(2)}`}>
            <h4 className='card-header-title text-uppercase'>
              Crédit
            </h4>
          </div>

          <div className='card-body'>
            <p className='small text-gray-700 mb-0'>
              Apport total : <span className="badge badge-soft-danger"><strong>{apportTotal} €</strong></span><br />
              Capital emprunté : <span className="badge badge-soft-danger"><strong>{capitalEmprunter} €</strong></span><br />
              Mensualités : <span className="badge badge-soft-danger"><strong>{mensualite} €/mois</strong></span>
            </p>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='card' style={{cursor:'pointer'}} onClick={() => onStep(3)}>
          <div className={`card-header rentabilite-menu-item ${getActive(3)}`}>
            <h4 className='card-header-title text-uppercase'>
              Revenus
            </h4>
          </div>

          <div className='card-body'>
            <p className='small text-gray-700 mb-0'>
              LD nue : <span className="badge badge-soft-success"><strong>{revenusLDNue} €/mois</strong></span><br />
              LD meublé : <span className="badge badge-soft-success"><strong>{revenusLDMeuble} €/mois</strong></span><br />
              LCD : <span className="badge badge-soft-success"><strong>{revenusLCD} €/mois</strong></span>
            </p>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='card' style={{cursor:'pointer'}} onClick={() => onStep(4)}>
          <div className={`card-header rentabilite-menu-item ${getActive(4)}`}>
            <h4 className='card-header-title text-uppercase'>
              Charges
            </h4>
          </div>

          <div className='card-body'>
            <p className='small text-gray-700 mb-0'>
              LD nue : <span className="badge badge-soft-danger"><strong>{chargesLDNue} €/mois</strong></span><br />
              LD meublé : <span className="badge badge-soft-danger"><strong>{chargesLDMeuble} €/mois</strong></span><br />
              LCD : <span className="badge badge-soft-danger"><strong>{chargesLCD} €/mois</strong></span>
            </p>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='card' style={{cursor:'pointer'}} onClick={() => onStep(5)}>
          <div className={`card-header rentabilite-menu-item ${getActive(5)}`}>
            <h4 className='card-header-title text-uppercase'>
              Evolution endettement
            </h4>
          </div>

          <div className='card-body'>
            <p className='small text-gray-700 mb-0'>
              Revenus meublés : <span className="badge badge-soft-success"><strong>{revenusMeuble} €/mois</strong></span><br />
              Endettement actuel : <span className={getBadgeClassName(-setEndettementActuel, -33, -20)}><strong>{endettementActuel} %</strong></span><br />
              Endettement futur : <span className={getBadgeClassName(-endettementFutur, -33, -20)}><strong>{endettementFutur} %</strong></span>
            </p>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='card' style={{cursor:'pointer'}} onClick={() => onStep(6)}>
          <div className={`card-header rentabilite-menu-item ${getActive(6)}`}>
            <h4 className='card-header-title text-uppercase'>
              Cashflow
            </h4>
          </div>

          <div className='card-body'>
            <p className='small text-gray-700 mb-0'>
              LD nue : <span className={getBadgeClassName(cashflowLDNue, 0, 0)}><strong>{cashflowLDNue} €/mois</strong></span><br />
              LD meublé : <span className={getBadgeClassName(cashflowLDMeuble, 0, 0)}><strong>{cashflowLDMeuble} €/mois</strong></span><br />
              LCD : <span className={getBadgeClassName(cashflowLCD, 0, 0)}><strong>{cashflowLCD} €/mois</strong></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default RentabiliteHorizontalCard