import React from 'react'

const UneBanqueHeader = ({ isActive }) => {

    return (
        <div>
        {/* <!-- HEADER --> */}
        <div className="header">
            <div className="container-fluid">

            {/* <!-- Body --> */}
            <div className="header-body">
                <div className="row align-items-center">
                <div className="col-auto">

                    {/* <!-- Avatar --> */}
                    <div className="avatar avatar-lg avatar-4by3">
                    <img src="/assets/img/mockups/banque-1-avatar.png" alt="..." className="avatar-img rounded" />
                    </div>

                </div>
                <div className="col ml-n3 ml-md-n2">

                    {/* <!-- Pretitle --> */}
                    <h6 className="header-pretitle">
                    Détails
                    </h6>

                    {/* <!-- Title --> */}
                    <h1 className="header-title">
                    Crédit Agricole Du Languedoc
                    </h1>

                </div>
                
                </div> {/* <!-- / .row --> */}

                <div className="row align-items-center">
                    <div className="col">

                        {/* <!-- Nav --> */}
                        <ul className="nav nav-tabs nav-overflow header-tabs">
                            <li className="nav-item">
                                <a href="/mockups/banques/Credit-Agricole-Du-Languedoc" className={ isActive === 'general' ? 'nav-link active' : 'nav-link' } >
                                Général
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/mockups/banques/Credit-Agricole-Du-Languedoc/offres-de-pret" className={ isActive === 'offres' ? 'nav-link active' : 'nav-link' }>
                                Offres de prêt
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>

            </div>

            </div>
        </div> {/* <!-- / .header --></div> */}
    </div>

    )
}

export default UneBanqueHeader