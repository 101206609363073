import { createSlice } from "@reduxjs/toolkit";
import objectSliceHelper from "../../toolsSlice/objectSliceHelper";

const URL = "/calculatrice/";
const helper = new objectSliceHelper();

const uneCalculatriceRentabiliteSlice = createSlice({
  name: "uneCalculatriceRentabilite",
  initialState: helper.getInitialState(),
  reducers: helper.reducers,
});

export const performGetUneCalculatriceRentabilite = (token) => {
  return async (dispatch) => {
    try {
      dispatch(getInit(helper.ACTIONS.GET));

      const reqOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(),
      };

      const res = await fetch(
        process.env.REACT_APP_REST_API_LOCATION + URL,
        reqOptions
      );
      const json = await res.json();

      if (res.status === 200 && !json.error) {
        if (json.datas === null) dispatch(getSuccess(json.datas));
        else {
          const result = {
            infosGenerales: JSON.parse(
              json.datas.informationsGenerales
                ? json.datas.informationsGenerales
                : "{}"
            ),
            credit: JSON.parse(
              json.datas.leCredit ? json.datas.leCredit : "{}"
            ),
            cashflow: JSON.parse(
              json.datas.CashflowETRenta ? json.datas.CashflowETRenta : "{}"
            ),
            charges: JSON.parse(json.datas.charges ? json.datas.charges : "{}"),
            endettement: JSON.parse(
              json.datas.evolutionTauxEndettement
                ? json.datas.evolutionTauxEndettement
                : "{}"
            ),
            revenus: JSON.parse(json.datas.revenus ? json.datas.revenus : "{}"),
          };
          dispatch(getSuccess(result));
        }
      } else dispatch(getFailure(json.message));
    } catch (e) {
      dispatch(getFailure(e.toString()));
    }
  };
};

export const performSyncUneCalculatriceRentabilite = (token, calculatrice) => {
  return async (dispatch) => {
    try {
      dispatch(syncInit(helper.ACTIONS.SYNC));

      const res = await helper.performSyncAPICall(
        token,
        {
          informationsGenerales: calculatrice.infosGenerales,
          leCredit: calculatrice.credit,
          CashflowETRenta: calculatrice.cashflow,
          charges: calculatrice.charges,
          evolutionTauxEndettement: calculatrice.endettement,
          revenus: calculatrice.revenus,
        },
        URL
      );
      const json = await res.json();

      if (res.status === 200 && !json.error) {
        if (json.datas === null) dispatch(syncSuccess(json.datas));
        else {
          const result = {
            infosGenerales: JSON.parse(
              json.datas.informationsGenerales
                ? json.datas.informationsGenerales
                : "{}"
            ),
            credit: JSON.parse(
              json.datas.leCredit ? json.datas.leCredit : "{}"
            ),
            cashflow: JSON.parse(
              json.datas.CashflowETRenta ? json.datas.CashflowETRenta : "{}"
            ),
            charges: JSON.parse(json.datas.charges ? json.datas.charges : "{}"),
            endettement: JSON.parse(
              json.datas.evolutionTauxEndettement
                ? json.datas.evolutionTauxEndettement
                : "{}"
            ),
            revenus: JSON.parse(json.datas.revenus ? json.datas.revenus : "{}"),
          };
          dispatch(syncSuccess(result));
        }
      } else dispatch(syncFailure(json.message));
    } catch (e) {
      dispatch(syncFailure(e.toString()));
    }
  };
};

export const performDelUneCalculatriceRentabilite = (token) => {
  return async (dispatch) => {
    try {
      dispatch(delInit(helper.ACTIONS.DEL));

      const res = await helper.performSyncAPICall(
        token,
        {
          informationsGenerales: {
            prixMoyenM2: 2000,
            lcd: true,
            ldm: true,
            ldnue: true,
            surface: 0,
            coutAchat: 0,
            fraisNotaires: 0,
            fraisNotairesInputed: false,
            typedetravaux: 150,
            coutTravaux: 0,
            coutMeubles: 0,
            coutTotalm2: 0,
            coutTotalReno: 0,
            coutTotalProjet: 0,
          },

          leCredit: {
            apportPersonnel: 0,
            fraisGarantie: 0,
            fraisDossier: 0,
            dureeDiffere: 0,
            dureeTotaleCredit: 0,
            tauxHorsAssurance: 0,
            tauxAssurance: 0,
            montantAFinance: 0,
            coutFraisGarantie: 0,
            capitalEmprunteTotal: 0,
            mensualite: 0,
          },

          evolutionTauxEndettement: {
            revenusMensuels: 0,
            revenusFonciersActuels: 0,
            revenusFonciersFutursMeuble: 0,
            mensualiteLieeALinvestissement: 0,
            autrePretsMensualite: 0,
            tauxEndettementActuel: 0,
            tauxEndettementFutur: 0,
          },

          revenus: {
            nombreLogements: 0,
            nombreRotations: 0,
            logementsData: [],
            revenusTotalTousLogementsLDNue: 0,
            revenusTotalTousLogementsLDMeuble: 0,
            revenusTotalTousLogementsLCD: 0,
          },

          charges: {
            nombreLot: 0,
            nombreRotations: 0,
            revenusTotauxLDNue: 0,
            revenusTotauxLDMeuble: 0,
            revenusTotauxLCD: 0,
            taxeFonciere: 0,
            chargesCopro: 0,
            assurancePNO: 0,
            assuranceLoyersImpayes: 0,
            previsionTravaux: 0,
            expertComptable: 0,
            fraisGerance: 0,
            fraisDivers: 0,
            tauxConciergerie: 0,
            tauxCommissionPlateforme: 0,
            fraisPlateformesMensuels: 0,
            fraisPlateformesAnnuels: 0,
            fraisElectricite: 0,
            fraisEau: 0,
            fraisPersonnelMenage: 0,
            fraisPressing: 0,
            fraisInternet: 0,
            fraisNetflixEtAbonnement: 0,
            fraisLogiciels: 0,
            fraisProduitsPerissable: 0,
            totalChargesMensuellesLDNue: 0,
            totalChargesMensuellesLDMeuble: 0,
            totalChargesMensuellesLCD: 0,
            totalChargesAnnuellesLDNue: 0,
            totalChargesAnnuellesLDMeuble: 0,
            totalChargesAnnuellesLCD: 0,
          },

          CashflowETRenta: {
            mensualiteCredit: 0,
            coutTotalReno: 0,
            coutTotalProjet: 0,

            revenusTotalLDNue: 0,
            chargesTotalLDNue: 0,
            cashFlowLDNue: 0,
            rentaBruteLDNue: 0,
            rentaNetteLDNue: 0,

            revenusTotalLDMeuble: 0,
            chargesTotalLDMeuble: 0,
            cashFlowLDMeuble: 0,
            rentaBruteLDMeuble: 0,
            rentaNetteLDMeuble: 0,

            revenusTotalLCD: 0,
            chargesTotalLCD: 0,
            cashFlowLCD: 0,
            rentaBruteLCD: 0,
            rentaNetteLCD: 0,
          },
        },
        URL
      );
      const json = await res.json();

      if (res.status === 200 && !json.error) {
        if (json.datas === null) dispatch(delSuccess(json.datas));
        else {
          const result = {
            infosGenerales: JSON.parse(json.datas.informationsGenerales),
            credit: JSON.parse(json.datas.leCredit),
            cashflow: JSON.parse(json.datas.CashflowETRenta),
            charges: JSON.parse(json.datas.charges),
            endettement: JSON.parse(json.datas.evolutionTauxEndettement),
            revenus: JSON.parse(json.datas.revenus),
          };
          dispatch(delSuccess(result));
        }
      } else dispatch(delFailure(json.message));
    } catch (e) {
      dispatch(delFailure(e.toString()));
    }
  };
};

// Export action creators
const {
  getInit,
  syncInit,
  getSuccess,
  getFailure,
  syncSuccess,
  syncFailure,
  delInit,
  delSuccess,
  delFailure,
} = uneCalculatriceRentabiliteSlice.actions;
export const { updateByField } = uneCalculatriceRentabiliteSlice.actions;

// Export reducer
export default uneCalculatriceRentabiliteSlice.reducer;

// Selectors
export const uneCalculatriceRentabiliteStatusSelector = (state) =>
  state.uneCalculatriceRentabilite.status;
export const uneCalculatriceRentabiliteDatasSelector = (state) =>
  state.uneCalculatriceRentabilite.datas;
