import React from 'react'
import defined from '../../ressources/defined'

const PaginationBar = ({ pagination, onPageChange }) => {

  let pages = []
  for(var i = 1; i <= pagination.totalPage; i++) {
    pages.push({
      nb: i,
      active: i === pagination.currentPage
    })
  }

  const handlePageClick = (page) => {
    onPageChange(page)
  }

  const handlePageNextClick = () => {
    var result = pagination.currentPage + 1
    
    if(result > pagination.totalPage) result = pagination.totalPage

    onPageChange(result)
  }

  const handlePagePrevClick = () => {
    var result = pagination.currentPage - 1

    if(result < 1)result = 1

    onPageChange(result)
  }

  return (
    <div className="card-footer d-flex justify-content-between">
      {/* <!-- Pagination (prev) --> */}
      <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
        <li className="page-item">
          <a href={defined.ANCHOR} className="page-link pl-0 pr-4 border-right" style={{cursor:"pointer"}} onClick={handlePagePrevClick}>
            <i className="fe fe-arrow-left mr-1"></i> Préc.
          </a>
        </li>
      </ul>

      {/* <!-- Page numbers --> */}
      <ul className="list-pagination pagination pagination-tabs card-pagination">
        {pages.map((p, index) => {
          return (
            <li className={p.active ? 'active' : ''} key={index}>
              <a href={defined.ANCHOR} className="page" onClick={ () =>handlePageClick(p.nb) } style={{cursor:"pointer"}}>
                {p.nb}
              </a>
            </li>
          )
        })}
      </ul>

      {/* <!-- Pagination (next) --> */}
      <ul className="list-pagination-next pagination pagination-tabs card-pagination">
        <li className="page-item">
          <a href={defined.ANCHOR} className="page-link pl-4 pr-0 border-left" style={{cursor:"pointer"}} onClick={handlePageNextClick}>
            Suiv. <i className="fe fe-arrow-right ml-1"></i>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default PaginationBar