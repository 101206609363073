import defined from "../ressources/defined";
import FormationsOverlayWithStore from "./overlays/formation/FormationsOverlayWithStore";
import WIPModal from "./overlays/WIPModal";

const Navigation = ({ granted_modules, formationCategories }) => {
  return (
    <>
      {/* <!-- Modal --> */}
      <WIPModal />

      <nav
        className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
        id="sidebar"
      >
        <FormationsOverlayWithStore current_category={formationCategories} />

        <div className="container-fluid">
          {/* <!-- Brand --> */}
          <a className="navbar-brand" href="/">
            <img
              src="/LogoProjectImmoVectorized.svg"
              className="navbar-brand-img 
            mx-auto"
              alt="..."
            />
          </a>

          {/* <!-- Collapse --> */}
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            {/* <!-- MAIN NAVIGATION --> */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/patrimoine" role="button">
                  <i className="fe fe-pie-chart"></i> Mon patrimoine
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/recherche-ville" role="button">
                  <i className="fe fe-search"></i> Rechercher une ville
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/etudes" role="button">
                  <i className="fe fe-book"></i> Mes études de marché
                </a>
              </li>

              {/* NAVIGATION RECHERCHE DE BIEN */}
              {/** /recherche-biens */}
              {/* <li className="nav-item">
                <a className="nav-link nav-link-disabled" href="#!" role="button" data-toggle="modal" data-target="#WIPModal">
                  <i className="fe fe-zoom-in"></i> Recherche de biens
                </a>
              </li> */}

              {/* /mes-projets */}
              <li className="nav-item">
                <a className="nav-link" href="/projets" role="button">
                  <i className="fe fe-home"></i> Mes projets de bien
                </a>
              </li>

              {/* <li className="nav-item">
                <a
                  className="nav-link nav-link-disabled"
                  href="#!"
                  role="button"
                  data-toggle="modal"
                  data-target="#WIPModal"
                >
                  <i className="fe fe-settings"></i> Mes travaux
                </a>
              </li> */}

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/calculatrice-rentabilite"
                  role="button"
                >
                  <i className="fe fe-sliders"></i> Calculer la rentabilité
                </a>
              </li>
            </ul>

            {/* <!-- Divider --> */}
            <hr className="navbar-divider my-3" />

            <h6 className="navbar-heading">Utilitaires</h6>

            <ul className="navbar-nav mb-md-4 navbar-custom">
              <li className="nav-item">
                <a className="nav-link" href="/chrome-extension">
                  <i className="fe fe-chrome"></i> Extension Chrome
                </a>
              </li>

              {/* <li className="nav-item">
                <a className="nav-link" href="https://sharing.clickup.com/g/h/6-134656573-7/354e1e00baf8b45" target="_blank" rel="noreferrer">
                  <i className="fe fe-map"></i> Notre Roadmap
                </a>
              </li> */}

              <li className="nav-item">
                {/* <a className="nav-link" href="#sidebarPages" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPages"> */}
                {/* <a
                  className="nav-link nav-link-disabled"
                  href="#!"
                  role="button"
                  data-toggle="modal"
                  data-target="#WIPModal"
                >
                  <i className="fe fe-users"></i> Mes interlocuteurs
                </a> */}

                {/* <div className="collapse hide" id="sidebarPages">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <a href="/artisans" className="nav-link ">
                        Artisans
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="/banques" className="nav-link ">
                        Banques
                      </a>
                    </li>
                  </ul>
                </div> */}
              </li>

              {/* <li className="nav-item">
                <a
                  className="nav-link nav-link-disabled"
                  href="#!"
                  role="button"
                  data-toggle="modal"
                  data-target="#WIPModal"
                >
                  <i className="fe fe-calendar"></i> Mes rendez-vous
                </a>
              </li> */}
            </ul>

            {/* <!-- Divider --> */}
            <hr className="navbar-divider my-3" />

            <h6 className="navbar-heading">Partenaires</h6>

            <ul className="navbar-nav mb-md-4 navbar-custom">
              <li className="nav-item">
                <a className="nav-link" href="/superhote" role="button">
                  <img
                    src="/assets/img/partenaires/superhote.svg"
                    alt="iconsh"
                    style={{
                      width: "18px",
                      marginRight: "12px",
                      borderRadius: "3px",
                    }}
                  />{" "}
                  SuperHote
                </a>
              </li>
            </ul>

            {/* <!-- Divider --> */}
            <hr className="navbar-divider my-3" />

            <h6 className="navbar-heading">Apprentissage</h6>

            <ul className="navbar-nav mb-md-4">
              <li className="nav-item">
                <a className="nav-link" href="/tutoriels" role="button">
                  <i className="fe fe-clipboard"></i> Tutoriels
                </a>
              </li>

              {/* <li className="nav-item">
                <a className="nav-link" href="/formations" role="button" >
                  <i className="fe fe-award"></i> Formations
                </a>
              </li> */}
            </ul>

            {/* <!-- Push content down --> */}
            <div className="mt-auto"></div>

            {/* <!-- User (md) --> */}
            <div className="navbar-user d-none d-md-flex" id="sidebarUser">
              {/* <!-- Icon --> */}
              {/* <a href="#sidebarModalActivity" className="navbar-user-link" data-toggle="modal">
                <span className="icon">
                  <i className="fe fe-bell"></i>
                </span>
              </a> */}

              {/* <!-- Dropup --> */}
              <div className="dropup">
                {/* <!-- Toggle --> */}
                <a
                  href={defined.ANCHOR}
                  id="sidebarIconCopy"
                  className="dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="avatar avatar-sm avatar-online">
                    <img
                      src="/assets/img/mockups/profil.png"
                      className="avatar-img rounded-circle"
                      alt="..."
                    />
                  </div>
                </a>

                {/* <!-- Menu --> */}
                <div
                  className="dropdown-menu"
                  aria-labelledby="sidebarIconCopy"
                >
                  <a href="/profil" className="dropdown-item">
                    Mon profil
                  </a>
                  {/* <a href="/settings" className="dropdown-item">Paramètres</a> */}
                  <hr className="dropdown-divider" />
                  <a href="/logout" className="dropdown-item">
                    Se déconnecter
                  </a>
                </div>
              </div>

              {/* <!-- Icon --> */}
              {/* <a href="#sidebarModalSearch" className="navbar-user-link" data-toggle="modal">
                <span className="icon">
                  <i className="fe fe-search"></i>
                </span>
              </a> */}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
