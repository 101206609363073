import React from 'react'
import SearchBar from '../../toolsComponents/bars/SearchBar'
import TutorielItem from './TutorielItem'

const Formations = ({ tutoriels, searchValue, onSearch }) => {

  const handleOnSearch = (value) => {
    onSearch(value)
  }

  const handleOnCategory = (category) => {
    onSearch(category)
  }

  return (
    <div>
      {/* <!-- Main Card --> */}
      <div className="card">
        {/* Searchbar and number of lines by page */}
        <SearchBar 
          withNbPerPage={false}
          onSearch={handleOnSearch}
          searchValue={searchValue}
        />

        <div className="card-body text-center">
          <div className="row">
            {tutoriels.map((t, index) => {
              return <TutorielItem 
                key={index}
                tutoriel={t}
                onCategory={handleOnCategory}
              />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Formations