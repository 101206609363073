import React from 'react'
import { useParams } from 'react-router'
import UnProfilGeneralWithStore from './General/UnProfilGeneralWithStore'
import UnProfilMotDePasseWithStore from './MotDePasse/UnProfilMotDePasseWithStore'
import UnProfilPaiementsWithStore from './Facturation/UnProfilFacturationWithStore'

const UnProfilDispatcher = () => {
  const { page } = useParams()

  switch(page) {
    case undefined: 
      return <UnProfilGeneralWithStore />
    case 'mot-de-passe':
      return <UnProfilMotDePasseWithStore />
    case 'facturation':
      return <UnProfilPaiementsWithStore />
    default:
      return <UnProfilGeneralWithStore />
  }
}

export default UnProfilDispatcher