import React from 'react'
import Image from '../images/Image'
import SimpleEmptyTable from '../SimpleEmptyTable'

const PhotosCard = ({ photos, link }) => {

  // Manage photos dynamicly
  return (
    <div className="card">
      <div className="card-header">
        {/* <!-- Title --> */}
        <h4 className="card-header-title">
          Photos
        </h4>

        <a href={link} className="small">Voir plus</a> 
      </div>

      <div className="card-body" style={{padding:'6px 24px 6px 24px'}}>
        <div className="list-group list-group-flush my-n3">
          <div className="list-group-item">
            <div className="row align-items-center">
              {photos && photos.length === 0 &&
                <SimpleEmptyTable
                  message="Il n'y a aucune photo. Cliquez sur voir plus pour en ajouter."
                />
              }

              {photos && photos[0] && 
                <div className="row">
                    <div className="col-12" style={{textAlign:'center'}}>
                      <Image 
                        alt={photos[0].nom}
                        className="rounded border-body"
                        style={{maxWidth:"100%"}}
                        src={photos[0].url}
                      />
                    </div>
                </div>
              }

              
              <div className="row">
                {photos && photos[1] && 
                  <div className="col-6" style={{textAlign:'center', padding:'6px 3px 0px 12px'}}>
                    <Image 
                      alt={photos[1].nom}
                      style={{maxWidth:"100%"}}
                      className="rounded border-body"
                      src={photos[1].url}
                    />
                  </div>
                }

                {photos && photos[2] &&
                  <div className="col-6" style={{textAlign:'center', padding:'6px 12px 0px 3px'}}>
                    <Image 
                      alt={photos[2].nom}
                      className="rounded border-body"
                      style={{maxWidth:"100%"}}
                      src={photos[2].url}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotosCard