import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../toolsSlice/objectSliceHelper'

const URL = '/current_user/'
const helper = new objectSliceHelper()

const unProfilSlice = createSlice({
  name: 'unProfil',
  initialState: helper.getInitialState(),
  reducers: helper.reducers
})

export const performGetUnProfil = (token) => {

  return async dispatch => {
    try {
      dispatch(getInit())

      const res = await helper.performGetAPICall(token, '', URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(getSuccess(json.datas))
      else dispatch(getFailure(json.message))
    }
    catch(e) {
      dispatch(getFailure(e.toString()))
    }
  }
}

export const performSyncUnProfil = (token, user) => {
  return async dispatch => {
    try {
      dispatch(syncInit())

      const res = await helper.performSyncAPICall(token, user, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(syncSuccess(json.datas))
      else dispatch(syncFailure(json.message))
    }
    catch(e) {
      dispatch(syncFailure(e.toString()))
    }
  }
}

export const performChangePassword = (token, current_password, new_password) => {
  return async dispatch => {
    try {
      dispatch(init('CHANGE_PASSWORD'))

      const reqOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify({ current_password, new_password })
      }
  
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/change-password', reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(success(json.datas))
      else dispatch(failure(json.message))
    }
    catch(e) {
      dispatch(failure(e.toString()))
    }
  }
}

// Export action creators
const { init, success, failure, getInit, syncInit,getSuccess, getFailure, syncSuccess, syncFailure } = unProfilSlice.actions
export const { updateByField } = unProfilSlice.actions

// Export reducer
export default unProfilSlice.reducer

// Selectors
export const unProfilStatusSelector = (state) => state.unProfil.status
export const unProfilDatasSelector = (state) => state.unProfil.datas