import React, { useEffect } from "react";
import { useImperativeHandle } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Navigation from "../../toolsComponents/Navigation";
import SimpleContainedError from "../../toolsComponents/SimpleContainedError";
import SimpleLoading from "../../toolsComponents/SimpleLoading";
import { loginDatasSelector } from "../auth/login/loginSlice";
import {
  performAddUneEtude,
  uneEtudeStatusSelector,
  uneEtudeDatasSelector,
  performGetUneEtude,
  resetInitialState,
} from "../uneEtude/uneEtudeSlice";
import UnProjetHeader from "./UnProjetHeader";
import { unProjetSimulationTravauxStatusSelector } from "./UnProjetSimulationTravaux/unProjetSimulationTravauxSlice";
import {
  performDelUnProjet,
  performGetUnProjet,
  performSyncUnProjet,
  unProjetDatasSelector,
  unProjetStatusSelector,
  updateByField,
} from "./unProjetSlice";

const UnProjetGenericWithStore = React.forwardRef(
  (
    {
      children,
      id,
      activeMenu,
      activeSubMenu,
      formationCategories = "projets de bien",
    },
    ref
  ) => {
    useImperativeHandle(ref, () => {
      return {
        projet,
        etude,
        handleChange,
        handleAddEtude,
        handleSubmit,
      };
    });

    const dispatch = useDispatch();
    const [cookies] = useCookies(["token", "email"]);

    const { granted_modules } = useSelector(loginDatasSelector);

    const { hasError, errorMessage, loading, lastAction } = useSelector(
      unProjetStatusSelector
    );

    const projet = useSelector(unProjetDatasSelector);
    const etude = useSelector(uneEtudeDatasSelector);

    const newEtudeStatus = useSelector(uneEtudeStatusSelector);
    const newEtude = useSelector(uneEtudeDatasSelector);

    const descriptifStatus = useSelector(
      unProjetSimulationTravauxStatusSelector
    );

    useEffect(() => {
      // Get un projet
      dispatch(performGetUnProjet(cookies.token, id));
    }, [dispatch, cookies.token, id]);

    useEffect(() => {
      // Empty etude to be sure that the etude is up to date on syncing a new ville
      dispatch(resetInitialState());
      // Get une etude
      if (projet.etude) {
        dispatch(performGetUneEtude(cookies.token, projet.etude._id));
      }
    }, [dispatch, projet?.etude, cookies.token]);

    const handleAddEtude = () => {
      dispatch(
        performAddUneEtude(cookies.token, {
          nom_ville: `${projet.commune.nom} (${projet.commune.cp}) - ${projet.commune.departement}`,
        })
      );
    };

    const handleDelete = () => {
      dispatch(performDelUnProjet(cookies.token, projet));
    };

    const handleChange = (e) => {
      dispatch(updateByField({ field: e.inputName, value: e.value }));
    };

    const handleSubmit = () => {
      dispatch(performSyncUnProjet(cookies.token, projet));
    };

    if (!loading && !hasError && lastAction === "DEL") {
      return <Redirect to="/projets" />;
    } else if (
      !newEtudeStatus.loading &&
      !newEtudeStatus.hasError &&
      newEtudeStatus.lastAction === "ADD"
    ) {
      return <Redirect push to={"/etudes/" + newEtude._id} />;
    } else {
      return (
        <>
          <Navigation
            granted_modules={granted_modules}
            formationCategories={formationCategories}
          />

          {(hasError || descriptifStatus.hasError) && (
            <SimpleContainedError>
              {errorMessage || descriptifStatus.errorMessage}
            </SimpleContainedError>
          )}

          {(!hasError || !descriptifStatus.hasError) && (
            <div className="main-content">
              <UnProjetHeader
                projet={projet}
                isActive={activeMenu}
                isActiveSubMenu={activeSubMenu}
                onAddEtude={handleAddEtude}
                onDelete={handleDelete}
              />

              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-12">
                    {(loading ||
                      newEtudeStatus.loading ||
                      descriptifStatus.loading) && <SimpleLoading />}

                    {!loading &&
                      !newEtudeStatus.loading &&
                      !descriptifStatus.loading &&
                      children}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  }
);

export default UnProjetGenericWithStore;
