import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetEmprunteurs = () => {

    return (
        <div>

            {/* <!-- MODAL SUPPRIMER UN EMPRUNTEUR --> */}
            <div className="modal fade" id="exampleModalDelEmprunteur" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Supprimer un emprunteur</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <p>Etes-vous sûr de vouloir supprimer cet emprunteur : Gaëtan BERTUIT ?</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>


            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <UnProjetHeader isActive="financement" isActiveSubMenu="emprunteurs"/>

                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-12">

                        {/* Bouton ajouter un emprunteur */}
                        <div className="row">
                            <div className="col"></div>

                            <div className="col-auto">
                                <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/nouvel-emprunteur" className="btn btn-primary d-block d-md-inline-block lift">
                                    <i className="fe fe-plus"></i> Ajouter un emprunteur supplémentaire
                                </a>
                            </div>
                        </div>

                        <br />

                        {/* Liste des devis */}
                        <div className="row">

                            <div className="col-12">
        <div className="tab-content">
        <div className="tab-pane fade show active" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">

            {/* <!-- Card --> */}
            <div className="card" data-list='{"valueNames": ["item-name", "item-industry", "item-location", "item-owner", "item-created"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}' id="companiesList">
                <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">

                    {/* <!-- Form --> */}
                    <form>
                        <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                            <i className="fe fe-search"></i>
                            </span>
                        </div>
                        <input className="list-search form-control" type="search" placeholder="Chercher ..." />
                        </div>
                    </form>

                    </div>
                    <div className="col-auto mr-n3">

                    {/* <!-- Select --> */}
                    <form>
                        <select className="custom-select custom-select-sm form-control-flush" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                        <option value="5">5 par page</option>
                        <option value="10" selected>10 par page</option>
                        <option value="*">Tous</option>
                        </select>
                    </form>

                    </div>
                    
                </div> {/* <!-- / .row --> */}
                </div>
                <div className="table-responsive">
                <table className="table table-sm table-hover table-nowrap card-table">
                    <thead>
                        <tr>
                            <th>
                            <a className="list-sort text-muted" data-sort="item-name" href="#!">Nom</a>
                            </th>

                            <th>
                            <a className="list-sort text-muted" data-sort="item-location" href="#!">Salaire net mensuel</a>
                            </th>

                            <th>
                            <a className="list-sort text-muted" data-sort="item-industry" href="#!">Epargne personnel</a>
                            </th>
                           
                            <th colspan="2">
                            <a className="list-sort text-muted" data-sort="item-owner" href="#!">Patrimoine personnel</a>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody className="list font-size-base">

                        {/* <!-- DEVIS 1 --> */}
                        <tr>

                            <td>
                                <div className="avatar avatar-xs align-middle mr-2">
                                    <img className="avatar-img rounded-circle" src="/assets/img/mockups/gaetan-bertuit.jpeg" alt="..." />
                                </div> 
                                <a className="item-name text-reset" href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/emprunteurs/Gaetan-BERTUIT">Gaëtan BERTUIT</a>
                            </td>

                            <td>
                                <span className="item-location">2 500 000 €</span>
                            </td>

                            <td>
                                1 200 000 000 €
                            </td>

                            <td  className="item-phone">
                                26 000 000 000 €
                            </td>

                            <td className="text-right">
                                {/* <!-- Dropdown --> */}
                                <div className="dropdown">
                                    <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe fe-more-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelEmprunteur">
                                            <i className="fe fe-trash-2"></i> Supprimer
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
                </div>
                <div className="card-footer d-flex justify-content-between">

                {/* <!-- Pagination (prev) --> */}
                <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                    <li className="page-item">
                    <a className="page-link pl-0 pr-4 border-right" href="#!">
                        <i className="fe fe-arrow-left mr-1"></i> Préc.
                    </a>
                    </li>
                </ul>

                {/* <!-- Pagination --> */}
                <ul className="list-pagination pagination pagination-tabs card-pagination">
                    <li className="active"><a className="page" href="#!" data-i="1" data-page="10">1</a></li>
                </ul>

                {/* <!-- Pagination (next) --> */}
                <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                    <li className="page-item">
                    <a className="page-link pl-4 pr-0 border-left" href="#!">
                        Suiv. <i className="fe fe-arrow-right ml-1"></i>
                    </a>
                    </li>
                </ul>

                {/* <!-- Alert --> */}
                <div className="list-alert alert alert-dark alert-dismissible border fade" role="alert">

                    {/* <!-- Content --> */}
                    <div className="row align-items-center">
                    <div className="col">

                        {/* <!-- Checkbox --> */}
                        <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="cardAlertCheckbox" checked disabled/>
                        <label className="custom-control-label text-white" htmlFor="cardAlertCheckbox"><span className="list-alert-count">0</span> deal(s)</label>
                        </div>

                    </div>
                    <div className="col-auto mr-n3">

                        {/* <!-- Button --> */}
                        <button className="btn btn-sm btn-white-20">
                        Edit
                        </button>

                        {/* <!-- Button --> */}
                        <button className="btn btn-sm btn-white-20">
                        Delete
                        </button>

                    </div>
                    </div> {/* <!-- / .row --> */}

                    {/* <!-- Close --> */}
                    <button type="button" className="list-alert-close close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>

                </div>

                </div>
            </div>

        </div>
    </div>
    </div>


                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnProjetEmprunteurs