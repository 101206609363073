import React from 'react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import UnProjetGenericWithStore from '../../UnProjetGenericWithStore'
import { unProjetDatasSelector, unProjetStatusSelector, updateByField } from '../../unProjetSlice'
import { dispatchChargesComponentDataToRedux, dispatchCreditComponentDataToRedux, dispatchEndettementComponentDataToRedux, dispatchInfosGeneralesComponentDataToRedux, dispatchRevenusComponentDataToRedux } from '../rentabiliteUtils'
import UnProjetRentabiliteWizard from './UnProjetRentabiliteWizard'

const UnProjetRentabiliteWizardWithStore = ({ id }) => {

  const dispatch = useDispatch()
  const projet = useSelector(unProjetDatasSelector)
  const { hasError, loading, lastAction } = useSelector(unProjetStatusSelector)
  const genericComponent = useRef(null)

  const handleChangeInfosGenerales = (values) => {
    dispatchInfosGeneralesComponentDataToRedux({ dispatch, updateByField, values })
  }

  const handleChangeCredit = (values) => {
    dispatchCreditComponentDataToRedux({ dispatch, updateByField, values })
  }

  const handleChangeRevenus = (values) => {
    dispatchRevenusComponentDataToRedux({ dispatch, updateByField, values })
  }

  const handleChangeCharges = (values) => {
    dispatchChargesComponentDataToRedux({ dispatch, updateByField, values })
  }

  const handleChangeEndettement = (values) => {
    dispatchEndettementComponentDataToRedux({ dispatch, updateByField, values })
  }

  if( !loading && !hasError && lastAction === "SYNC" ) {
    return <Redirect to={`/projets/${projet._id}/rentabilite`} />
  }
  else {
    return (
      <>
        <UnProjetGenericWithStore
          ref={genericComponent}
          id={id}
          activeMenu="rentabilite"
        >
          <UnProjetRentabiliteWizard
            projet={projet}
            onChangeInfosGenerales={handleChangeInfosGenerales}
            onChangeCredit={handleChangeCredit}
            onChangeRevenus={handleChangeRevenus}
            onChangeCharges={handleChangeCharges}
            onChangeEndettement={handleChangeEndettement}
            onSubmit={() => genericComponent.current.handleSubmit()}
          />
          <br/>
        </UnProjetGenericWithStore>
      </>
    )
  }
}

export default UnProjetRentabiliteWizardWithStore