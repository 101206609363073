import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import SimpleError from '../../../../toolsComponents/SimpleError'
import SimpleLoading from '../../../../toolsComponents/SimpleLoading'
import { performRegisterInfosLogin, registerStatusSelector } from '../registerSlice'
import RegisterInfosLogin from './RegisterInfosLogin'

const RegisterInfosLoginWithStore = ({ payment_process, onNext }) => {

  const dispatch = useDispatch()
  const { loading, hasError, errorMessage, lastAction } = useSelector(registerStatusSelector)

  const handleSubmit = ({ prenom, telephone, email, password }) => {
    dispatch(performRegisterInfosLogin({
      paymentProcessUrl: payment_process.url,
      prenom,
      telephone,
      email,
      password
    }))
  }

  useEffect(() => {
    if( !loading && !hasError && lastAction === 'POST_INFOS_LOGIN' ) {
      onNext()
    }
  }, [loading, hasError, lastAction])

  return <>
    {loading &&
      <SimpleLoading />
    }

    {!loading && hasError &&
      <SimpleError withHeader={false}>
        {errorMessage}
      </SimpleError>
    }

    {!loading && 
      <RegisterInfosLogin
        payment_process={payment_process}
        onSubmit={handleSubmit}
      />
    }
  </>
}

export default RegisterInfosLoginWithStore