import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";
import SelectVersatile from "../../../../toolsComponents/SelectVersatile";

const LotIsolation = ({ isolation, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("isolation", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot Isolation"
            id="lotIsolation"
            inputName="lotIsolation.isChecked"
            isChecked={isolation?.lotIsolation.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !isolation?.lotIsolation.isChecked ? "hide" : "sous-lot col-12"
            }
          >
            <Checkbox
              label="Isolation Plafonds"
              id="isolationPlafonds"
              inputName="plafond.isChecked"
              isChecked={isolation?.plafond.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !isolation?.plafond.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 mb-4 text-left"
              }
            >
              <div className="col-4">
                <SelectVersatile
                  label="Type d'isolation Plafonds"
                  options={[
                    { title: "Laine de Roche", value: "laineDeRoche" },
                    { title: "Laine de verre", value: "laineDeVerre" },
                  ]}
                  inputName="plafond.categorie"
                  value={isolation?.plafond.categorie}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Surface Plafonds"
                  inputName="plafond.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={isolation?.plafond.surface}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Épaisseur Isolation Plafonds"
                  inputName="plafond.epaisseur"
                  type="Number"
                  placeholder="12"
                  prepend="cm"
                  value={isolation?.plafond.epaisseur}
                  onChange={handleChange}
                />
              </div>
            </div>

            <Checkbox
              label="Isolation Murale"
              id="isolationMurale"
              inputName="mur.isChecked"
              isChecked={isolation?.mur.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !isolation?.mur.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 mb-4 text-left"
              }
            >
              <div className="col-4">
                <SelectVersatile
                  label="Type d'isolation Murale"
                  options={[
                    { title: "Laine de Roche", value: "laineDeRoche" },
                    { title: "Laine de verre", value: "laineDeVerre" },
                  ]}
                  inputName="mur.categorie"
                  value={isolation?.mur.categorie}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Surface Murale"
                  inputName="mur.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={isolation?.mur.surface}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Épaisseur Isolation Murale"
                  inputName="mur.epaisseur"
                  type="Number"
                  placeholder="12"
                  prepend="cm"
                  value={isolation?.mur.epaisseur}
                  onChange={handleChange}
                />
              </div>
            </div>

            <Checkbox
              label="Isolation Sol"
              id="isolationSol"
              inputName="sol.isChecked"
              isChecked={isolation?.sol.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !isolation?.sol.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 mb-4 text-left"
              }
            >
              <div className="col-4">
                <SelectVersatile
                  label="Type d'isolation Sol"
                  options={[
                    { title: "Laine de Roche", value: "laineDeRoche" },
                    { title: "Laine de verre", value: "laineDeVerre" },
                  ]}
                  inputName="sol.categorie"
                  value={isolation?.sol.categorie}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Surface Sol"
                  inputName="sol.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={isolation?.sol.surface}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Épaisseur Isolation Sol"
                  inputName="sol.epaisseur"
                  type="Number"
                  placeholder="12"
                  prepend="cm"
                  value={isolation?.sol.epaisseur}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotIsolation;
