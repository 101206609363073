import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";
import SelectVersatile from "../../../../toolsComponents/SelectVersatile";

const LotPlomberie = ({ plomberie, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("plomberie", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot plomberie"
            id="lotPlomberie"
            inputName="lotPlomberie.isChecked"
            isChecked={plomberie?.lotPlomberie.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !plomberie?.lotPlomberie.isChecked ? "hide" : "sous-lot col-12"
            }
          >
            <div className="sous-lot col-12">
              <Checkbox
                label="Alimentation Générale"
                id="alimentationGenerale"
                inputName="alimentationGenerale"
                isChecked={plomberie?.alimentationGenerale}
                onChange={handleChange}
              />
            </div>

            <div className="sous-lot col-12">
              <Checkbox
                label="Sanitaires"
                id="sanitaires"
                inputName="sanitaires"
                isChecked={plomberie?.sanitaires}
                onChange={handleChange}
              />
              <div
                className={!plomberie?.sanitaires ? "hide" : "sous-lot col-12"}
              >
                <Checkbox
                  label="Meuble Vasque"
                  id="meubleVasque"
                  inputName="meubleVasque.isChecked"
                  isChecked={plomberie?.meubleVasque.isChecked}
                  onChange={handleChange}
                />

                <div
                  className={
                    !plomberie?.meubleVasque.isChecked
                      ? "hide"
                      : "row ml-4 mt-2 text-left"
                  }
                >
                  <div className="col-2">
                    <InputForm
                      label="Hauteur"
                      inputName="meubleVasque.hauteurMeubleVasque"
                      type="Number"
                      placeholder="90"
                      prepend="cm"
                      onChange={handleChange}
                      value={plomberie?.meubleVasque.hauteurMeubleVasque}
                    />
                  </div>
                  <div className="col-2">
                    <InputForm
                      label="Largeur"
                      inputName="meubleVasque.largeurMeubleVasque"
                      type="Number"
                      placeholder="60"
                      prepend="cm"
                      onChange={handleChange}
                      value={plomberie?.meubleVasque.largeurMeubleVasque}
                    />
                  </div>
                  <div className="col-2">
                    <InputForm
                      label="Profondeur"
                      inputName="meubleVasque.profondeurMeubleVasque"
                      type="Number"
                      placeholder="40"
                      prepend="cm"
                      onChange={handleChange}
                      value={plomberie?.meubleVasque.profondeurMeubleVasque}
                    />
                  </div>
                </div>

                <Checkbox
                  label="WC"
                  id="wc"
                  inputName="wc.isChecked"
                  isChecked={plomberie?.wc.isChecked}
                  onChange={handleChange}
                />
                <div
                  className={
                    !plomberie?.wc.isChecked
                      ? "hide"
                      : "row ml-4 mt-2 text-left"
                  }
                >
                  <div className="col-3">
                    <SelectVersatile
                      label="Type de WC"
                      options={[
                        { title: "Courte", value: "courte" },
                        { title: "Normale", value: "normale" },
                        { title: "Encastrée", value: "encastree" },
                      ]}
                      inputName="wc.typewc"
                      value={plomberie?.wc.typewc}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <Checkbox
                  label="Ballon d’eau chaude"
                  id="ballonDeauChaude"
                  inputName="ballonDeauChaude.isChecked"
                  isChecked={plomberie?.ballonDeauChaude.isChecked}
                  onChange={handleChange}
                />
                <div
                  className={
                    !plomberie?.ballonDeauChaude.isChecked
                      ? "hide"
                      : "row ml-4 mt-2 text-left"
                  }
                >
                  <div className="col-3">
                    <SelectVersatile
                      label="Type de ballon d'eau chaude"
                      options={[
                        { title: "Normal", value: "normal" },
                        {
                          title: "Extra Plat & Booster",
                          value: "extraplatbooster",
                        },
                      ]}
                      inputName="ballonDeauChaude.typeBallon"
                      value={plomberie?.ballonDeauChaude.typeBallon}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-2">
                    <InputForm
                      label="Volume"
                      inputName="ballonDeauChaude.volumeBallon"
                      type="Number"
                      placeholder="80"
                      prepend="L"
                      value={plomberie?.ballonDeauChaude.volumeBallon}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <Checkbox
                  label="Receveur de douche"
                  id="receveurDeDouche"
                  inputName="receveurDeDouche.isChecked"
                  isChecked={plomberie?.receveurDeDouche.isChecked}
                  onChange={handleChange}
                />
                <div
                  className={
                    !plomberie?.receveurDeDouche.isChecked
                      ? "hide"
                      : "row ml-4 mt-2 text-left"
                  }
                >
                  <div className="col-2">
                    <InputForm
                      label="Largeur"
                      inputName="receveurDeDouche.largeurReceveurDeDouche"
                      type="Number"
                      placeholder="120"
                      prepend="cm"
                      onChange={handleChange}
                      value={
                        plomberie?.receveurDeDouche.largeurReceveurDeDouche
                      }
                    />
                  </div>
                  <div className="col-2">
                    <InputForm
                      label="Longueur"
                      inputName="receveurDeDouche.longeurReceveurDeDouche"
                      type="Number"
                      placeholder="80"
                      prepend="cm"
                      onChange={handleChange}
                      value={
                        plomberie?.receveurDeDouche.longeurReceveurDeDouche
                      }
                    />
                  </div>
                </div>

                <Checkbox
                  label="Paroie de douche"
                  id="paroieDeDouche"
                  inputName="paroieDeDouche.isChecked"
                  isChecked={plomberie?.paroieDeDouche.isChecked}
                  onChange={handleChange}
                />
                <div
                  className={
                    !plomberie?.paroieDeDouche.isChecked
                      ? "hide"
                      : "row ml-4 mt-2 text-left"
                  }
                >
                  <div className="col-4">
                    <SelectVersatile
                      label="Type de paroie de douche"
                      options={[
                        { title: "À l'italienne", value: "italienne" },
                        { title: "Coulissante", value: "coulissante" },
                        { title: "Portes", value: "portes" },
                      ]}
                      inputName="paroieDeDouche.typeParoie"
                      value={plomberie?.paroieDeDouche.typeParoie}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <Checkbox
                  label="Robinetterie"
                  id="robinetterie"
                  inputName="robinetterie"
                  isChecked={plomberie?.robinetterie}
                  onChange={handleChange}
                />

                <Checkbox
                  label="Baignoire"
                  id="baignoire"
                  inputName="baignoire.isChecked"
                  isChecked={plomberie?.baignoire.isChecked}
                  onChange={handleChange}
                />
                <div
                  className={
                    !plomberie?.baignoire.isChecked
                      ? "hide"
                      : "row ml-4 mt-2 text-left"
                  }
                >
                  <div className="col-2">
                    <InputForm
                      label="Largeur"
                      inputName="baignoire.largeurBaignoire"
                      type="Number"
                      placeholder="190"
                      prepend="cm"
                      onChange={handleChange}
                      value={plomberie?.baignoire.largeurBaignoire}
                    />
                  </div>
                  <div className="col-2">
                    <InputForm
                      label="Longueur"
                      inputName="baignoire.longueurBaignoire"
                      type="Number"
                      placeholder="80"
                      prepend="cm"
                      onChange={handleChange}
                      value={plomberie?.baignoire.longueurBaignoire}
                    />
                  </div>
                </div>

                <Checkbox
                  label="Changement chaudière à gaz"
                  id="changementChaudiereGaz"
                  inputName="changementChaudiereGaz"
                  isChecked={plomberie?.changementChaudiereGaz}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotPlomberie;
