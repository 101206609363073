import React from 'react';
import InputForm from '../../../toolsComponents/forms/InputForm';
import SubmitForm from '../../../toolsComponents/forms/SubmitForm';

const InfoProjet = ({handleNext, nbLogements = 1, onNbLogementsChange}) => {

  return (<>
    <div className="generateurDescriptifStepContainer container-xl text-center d-flex flex-column justify-content-center" >
      <h1  style={{fontSize:"3rem"}}>Générateur de Descriptif</h1>
      
      <p style={{fontSize:"1.5rem"}}> 
            Bienvenue dans le générateur de descriptif ! 😄 Afin de pouvoir générer votre descriptif & simuler le coût approximatif de vos travaux, nous avons besoin de quelques informations  
      </p>

      <hr style={{borderTop: "1px solid rgba(134, 154, 184, 0.5)", width: "50px"}}/>

      <h2 className="mt-5">Combien souhaitez-vous avoir de logement dans votre bien une fois les travaux terminés ?</h2>
            
      <div className="ml-auto mr-auto">
        <InputForm 
          label="Nombre de logement(s) prévu(s):" 
          inputName="nbLogement" 
          type="Number" 
          placeholder="3" 
          value={nbLogements} 
          onChange={onNbLogementsChange}
        />
      </div>

      {/* <h2 className="mt-5 ">En dehors des travaux à l’intérieur des logements, souhaitez-vous réaliser d’autres travaux ? (Cocher si applicable)</h2> */}

      <div className="row container">
        {/* <div className="col-xl-6">
          <InputForm label="Rénovation parties communes" inputName="renovpartiescommunes" inputId="renovpartiescommunes" type="checkbox"/>
        </div>

        <div className="col-xl-6">
          <InputForm label="Rénovation gros œuvre (reprise sous œuvre, toiture….) " inputName="renovgrosoeuvres" inputId="renovgrosoeuvres" type="checkbox"/>
        </div>

        <div className="col-12">
          <InputForm label="Rénovation extérieures (menuiseries, ravalement, toiture...)" inputName="renovexterieur" inputId="renovautrexterieur" type="checkbox"/>
        </div> */}

        <div className="col-12 d-flex flex-direction-row justify-content-end">
          <SubmitForm  label="Suivant" 
            isSticky={false}
            icon = "" 
            onSubmit={handleNext}
            id="nextButton" /> 
        </div>
      </div>
    </div>
  </>)
}

export default InfoProjet