import React from 'react'

const UnProfilFacturation = ({ user }) => {
  const getCleanAmount = (amount) => {
    return (amount / 100)
  }

  const getCleantDate = (date) => {
    const tmpDate = date.split('T')[0].split('-')
    return `${tmpDate[2]}/${tmpDate[1]}/${tmpDate[0]}`
  }

  const getClassStatus = (status) => {
    switch(status) {
      case 'success': 
        return 'badge badge-soft-success'
      case 'succeeded': 
        return 'badge badge-soft-success'
      case 'trialing': 
        return 'badge badge-soft-success'
      case 'pending':
        return 'badge badge-soft-warning'
      default:
        return 'badge badge-soft-danger'
    }
  }

  return (
    <>
      {/* <h1>Vos factures</h1> */}

      <div className="card">
        <table className="table table-sm table-hover">
          <thead className="thead-light">
            <tr>
              <th scope="col"></th>
              <th scope="col">Date</th>
              <th scope="col">Intitulé</th>
              <th scope="col">Montant</th>
              <th scope="col">Etat</th>
            </tr>
          </thead>
          <tbody>
            {user && user.stripe && user.stripe.payments &&
              user.stripe.payments.map(p => {
                return (
                  <tr>
                    <td style={{width:'24px'}}><i className="fe fe-credit-card"></i></td>
                    <td style={{width:'150px'}}>{getCleantDate(p.date)}</td>
                    <td>{p.description}</td>
                    <td style={{width:'150px'}}>{getCleanAmount(p.amount)} €</td>
                    <td style={{width:'150px'}}><span className={getClassStatus(p.intent_status)}>{p.intent_status}</span></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default UnProfilFacturation