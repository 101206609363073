import React from 'react'
import Navigation from '../../toolsComponents/Navigation'

const UneFacture = () => {

    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">

      

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8">

            {/* <!-- Header --> */}
            <div className="header mt-md-5">
              <div className="header-body">
                <div className="row align-items-center">
                  <div className="col">

                    {/* <!-- Pretitle --> */}
                    <h6 className="header-pretitle">
                      Détails
                    </h6>

                    {/* <!-- Title --> */}
                    <h1 className="header-title">
                      Facture n° #6520
                    </h1>

                  </div>
                  <div className="col-auto">

                    {/* <!-- Buttons --> */}
                    <a href="#!" className="btn btn-white lift">
                      Télécharger en PDF
                    </a>

                  </div>
                </div> {/* <!-- / .row --> */}
              </div>
            </div>

            {/* <!-- Content --> */}
            <div className="card card-body p-5">
              <div className="row">
                <div className="col text-right">

                  {/* <!-- Badge --> */}
                  <div className="badge badge-success">
                    Payée
                  </div>

                </div>
              </div> {/* <!-- / .row --> */}
              <div className="row">
                <div className="col text-center">

                  {/* <!-- Logo --> */}
                  <img src="/LogoProjectImmoVectorized.svg" alt="..." className="img-fluid mb-4" style={{maxWidth: "16rem"}} />

                  {/* <!-- Title --> */}
                  <h2 className="mb-2">
                    Facture de ProjetImmo.io
                  </h2>

                  {/* <!-- Text --> */}
                  <p className="text-muted mb-6">
                    Facture #6520
                  </p>

                </div>
              </div> {/* <!-- / .row --> */}
              <div className="row">
                <div className="col-12 col-md-6">

                  {/* <!-- Heading --> */}
                  <h6 className="text-uppercase text-muted">
                    Facture de 
                  </h6>

                  {/* <!-- Text --> */}
                  <p className="text-muted mb-4">
                    <strong className="text-body">Gaëtan BERTUIT</strong> <br />
                    CEO de ProjetImmo.io <br />
                    123 Happy Walk Way <br />
                    San Francisco, CA
                  </p>


                </div>
                <div className="col-12 col-md-6 text-md-right">

                  {/* <!-- Heading --> */}
                  <h6 className="text-uppercase text-muted">
                    A l'intention de
                  </h6>

                  {/* <!-- Text --> */}
                  <p className="text-muted mb-4">
                    <strong className="text-body">Nicolas SURREAUX</strong> <br />
                    3 rue Pfeffel <br />
                    68 000 Colmar
                  </p>

                  {/* <!-- Heading --> */}
                  <h6 className="text-uppercase text-muted">
                    Date d'émission
                  </h6>

                  {/* <!-- Text --> */}
                  <p className="mb-4">
                    <time datetime="2018-04-23">22 avril 2021</time>
                  </p>

                </div>
              </div> {/* <!-- / .row --> */}
              <div className="row">
                <div className="col-12">

                  {/* <!-- Table --> */}
                  <div className="table-responsive">
                    <table className="table my-4">
                      <thead>
                        <tr>
                          <th className="px-0 bg-transparent border-top-0">
                            <span className="h6">Intitulé</span>
                          </th>
                          <th className="px-0 bg-transparent border-top-0">
                            <span className="h6">Qté</span>
                          </th>
                          <th className="px-0 bg-transparent border-top-0 text-right">
                            <span className="h6">Montant</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        {/* Ligne de facturation abonnement */}
                        <tr>
                          <td className="px-0">
                            Abonnement mensuel
                          </td>
                          <td className="px-0">
                            1
                          </td>
                          <td className="px-0 text-right">
                            14,16 €
                          </td>
                        </tr>

                        {/* Ligne de TVA */}
                        <tr>
                          <td className="px-0">
                            TVA 20%
                          </td>
                          <td className="px-0">
                            
                          </td>
                          <td className="px-0 text-right">
                            2,83 €
                          </td>
                        </tr>
                        
                        {/* Ligne totale */}
                        <tr>
                          <td className="px-0 border-top border-top-2">
                            <strong>Montant total</strong>
                          </td>
                          <td colspan="2" className="px-0 text-right border-top border-top-2">
                            <span className="h3">
                              16,99 €
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <hr className="my-5" />

                  {/* <!-- Title --> */}
                  <h6 className="text-uppercase">
                    Notes
                  </h6>

                  {/* <!-- Text --> */}
                  <p className="text-muted mb-0">
                    Nous aimons vraiment notre solution et s'il y a n'importe quoi que nous pouvons faire, faites-le nous savoir ! 
                  </p>

                </div>
              </div> {/* <!-- / .row --> */}
            </div>

          </div>
        </div> {/* <!-- / .row --> */}
      </div>

    </div>
        </div>
    )
}

export default UneFacture