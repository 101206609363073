import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import DeleteModal from "../modals/DeleteModal";
import SimpleInfo from "../SimpleInfo";

const DocumentsCard = ({
  id_bien,
  documents,
  isVoirPlus = true,
  withCategory = false,
  withActions = false,
  onDelete,
  onEdit,
}) => {
  const [cookies] = useCookies(["token", "email"]);

  const [sortedDocuments, setSortedDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    if (documents)
      // -1 : 1 => pour le sens inverse d'ajout
      setSortedDocuments(
        [...documents].sort((a, b) => (a._id > b._id ? -1 : 1))
      );
  }, [setSortedDocuments, documents]);

  const handleClickDel = (document) => {
    setSelectedDocument(document);
  };

  return (
    <>
      <DeleteModal
        onDelete={() => onDelete(selectedDocument)}
        myId="modal-del-document"
      >
        <p>
          Êtes-vous sûr de vouloir supprimer définitivement ce document ?
          <br />
          <strong>{selectedDocument?.titre}</strong> (
          {selectedDocument?.bien_titre})
        </p>
      </DeleteModal>

      <div className="card">
        <div className="card-header">
          {/* <!-- Title --> */}
          <h4 className="card-header-title">
            Mes {isVoirPlus && <>5 derniers</>} documents
          </h4>

          {isVoirPlus && (
            <a href={`/bien/${id_bien}/documents`} class="small">
              Voir plus
            </a>
          )}
        </div>

        <div className="card-body">
          <div
            class="table-responsive mb-0"
            data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            {(!documents || documents.length <= 0) && (
              <SimpleInfo>Il n'y a aucun document</SimpleInfo>
            )}

            {documents && documents.length > 0 && (
              <table class="table table-sm table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <a href="#!" class="text-muted list-sort">
                        Intitulé
                      </a>
                    </th>
                    {/* <th>
                    <a href="#" class="text-muted list-sort" data-sort="goal-status">
                      Société / Logement
                    </a>
                  </th> */}
                    <th>
                      <a href="#!" class="text-muted list-sort">
                        Bien
                      </a>
                    </th>
                    {withCategory && (
                      <>
                        <th>
                          <a href="#!" class="text-muted list-sort">
                            Categorie
                          </a>
                        </th>
                        <th>
                          <a href="#!" class="text-muted list-sort">
                            Sous-categorie
                          </a>
                        </th>
                      </>
                    )}
                    <th>
                      <a href="#!" class="text-muted list-sort">
                        Télécharger
                      </a>
                    </th>

                    {withActions && <th></th>}
                  </tr>
                </thead>
                <tbody class="list">
                  {sortedDocuments.slice(0, 5).map((d) => {
                    return (
                      <tr>
                        {withActions && (
                          <td class="goal-project">
                            <a
                              href="#!"
                              data-toggle="modal"
                              data-target="#modal-edit-document"
                              onClick={() => onEdit(d)}
                            >
                              <i className="fe fe-edit-2"></i>&nbsp;&nbsp;
                              {d.titre}
                            </a>
                          </td>
                        )}

                        {!withActions && (
                          <td class="goal-project">{d.titre}</td>
                        )}

                        <td class="goal-project">
                          <a href={`/bien/${d.bien_id}`}>{d.bien_titre}</a>
                        </td>

                        {withCategory && (
                          <>
                            <td class="goal-project">{d.category}</td>

                            <td class="goal-project">{d.subCategory}</td>
                          </>
                        )}

                        {/* <td class="goal-project">
                      <a href={`${d.url}/${cookies['token']}`}>
                        <img src={d.bien.url_avatar} style={{width: '32px', height:'32px'}} class="avatar-img rounded-circle" alt="..." />
                        &nbsp;&nbsp;
                        {d.titre}
                      </a>
                    </td> */}

                        {/* <td class="goal-date">
                      <time datetime="2018-10-24">{d.date}</time>
                    </td> */}

                        <td class="goal-progress">
                          <a
                            href={`${process.env.REACT_APP_REST_API_LOCATION}${d.url}${cookies["token"]}`}
                          >
                            <i className="fe fe-download"></i>
                            &nbsp;Télécharger
                          </a>
                        </td>

                        {withActions && (
                          <td class="goal-project">
                            <a
                              href="#!"
                              data-toggle="modal"
                              data-target="#modal-del-document"
                              onClick={() => handleClickDel(d)}
                            >
                              <i className="fe fe-trash"></i>
                            </a>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsCard;
