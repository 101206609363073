import React from 'react'
import Navigation from '../toolsComponents/Navigation'

const Banques = () => {


    return (
        <div>
            {/* <!-- MODAL SUPPRIMER UNE BANQUE --> */}
            <div className="modal fade" id="exampleModalDelBanque" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Supprimer une banque</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <p>Etes-vous sûr de vouloir supprimer la banque : Crédit Agricole Du Languedoc ?</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>



            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                            {/* <!-- Header --> */}
                            <div className="header">
                                <div className="header-body">

                                    <div className="row align-items-center">
                                        <div className="col">

                                        {/* <!-- Pretitle --> */}
                                        <h6 className="header-pretitle">
                                            Vue Globale
                                        </h6>

                                        {/* <!-- Title --> */}
                                        <h1 className="header-title text-truncate">
                                            Banques
                                        </h1>

                                        </div>
                                        <div className="col-auto">

                                        {/* <!-- Buttons --> */}
                                        <a href="/mockups/nouvelle-banque" className="btn btn-primary ml-2">
                                        <i className="fe fe-plus"></i> Ajouter une banque
                                        </a>

                                        </div>
                                    </div> {/* <!-- / .row --> */}
                                    
                                </div>
                            </div>

                            {/* <!-- Tab content --> */}
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">

                                    {/* <!-- Card --> */}
                                    <div className="card" data-list='{"valueNames": ["item-name", "item-industry", "item-location", "item-owner", "item-created"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}' id="companiesList">
                                        <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col">

                                            {/* <!-- Form --> */}
                                            <form>
                                                <div className="input-group input-group-flush">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                    <i className="fe fe-search"></i>
                                                    </span>
                                                </div>
                                                <input className="list-search form-control" type="search" placeholder="Chercher ..." />
                                                </div>
                                            </form>

                                            </div>
                                            <div className="col-auto mr-n3">

                                            {/* <!-- Select --> */}
                                            <form>
                                                <select className="custom-select custom-select-sm form-control-flush" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                                                <option value="5">5 par page</option>
                                                <option value="10" selected>10 par page</option>
                                                <option value="*">Tous</option>
                                                </select>
                                            </form>

                                            </div>
                                            
                                        </div> {/* <!-- / .row --> */}
                                        </div>
                                        <div className="table-responsive">
                                        <table className="table table-sm table-hover table-nowrap card-table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                    <a className="list-sort text-muted" data-sort="item-name" href="#!">Nom</a>
                                                    </th>
                                                    <th>
                                                    <a className="list-sort text-muted" data-sort="item-location" href="#!">Localisation</a>
                                                    </th>
                                                    <th>
                                                    <a className="list-sort text-muted" data-sort="item-owner" href="#!">Contact</a>
                                                    </th>
                                                    <th colspan="2">
                                                    <a className="list-sort text-muted" data-sort="item-created" href="#!">Téléphone</a>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="list font-size-base">
                                                
                                                {/* <!-- BANQUE 1 --> */}
                                                <tr>
                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/mockups/banque-1-avatar.png" alt="..." />
                                                        </div> 
                                                        <a className="item-name text-reset" href="/mockups/banques/Credit-Agricole-Du-Languedoc">Crédit Agricole Du Languedoc</a>
                                                    </td>

                                                    <td>
                                                        <span className="item-location">Mende (48 000)</span>
                                                    </td>

                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                                                        </div>
                                                        <span>Guillaume COURTINES</span>
                                                    </td>

                                                    <td  className="item-phone">
                                                        <a href="tel:+33655555555">(+33) 4 99 53 52 41</a>
                                                    </td>

                                                    <td className="text-right">
                                                        {/* <!-- Dropdown --> */}
                                                        <div className="dropdown">
                                                            <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelBanque">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                                {/* <!-- BANQUE 2 --> */}
                                                <tr>
                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/mockups/banque-2-avatar.jpeg" alt="..." />
                                                        </div> 
                                                        <a className="item-name text-reset" href="/mockups/banques/Credit-Agricole-Du-Languedoc">Caisse D'Epargne Mende Arnault</a>
                                                    </td>

                                                    <td>
                                                        <span className="item-location">Mende (48 000)</span>
                                                    </td>

                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                                                        </div>
                                                        <span>Arnaud VILLAIN</span>
                                                    </td>

                                                    <td  className="item-phone">
                                                        <a href="+655555555">(+33) 4 30 11 00 95</a>
                                                    </td>

                                                    <td className="text-right">
                                                        {/* <!-- Dropdown --> */}
                                                        <div className="dropdown">
                                                            <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelBanque">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                                {/* <!-- BANQUE 3 --> */}
                                                <tr>
                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/mockups/banque-3-avatar.png" alt="..." />
                                                        </div> 
                                                        <a className="item-name text-reset" href="/mockups/banques/Credit-Agricole-Du-Languedoc">LCL Banque et Assurance</a>
                                                    </td>

                                                    <td>
                                                        <span className="item-location">Mende (48 000)</span>
                                                    </td>

                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                                                        </div>
                                                        <span>Katell MORVAN</span>
                                                    </td>

                                                    <td  className="item-phone">
                                                        <a href="+655555555">(+33) 4 66 65 74 01</a>
                                                    </td>

                                                    <td className="text-right">
                                                        {/* <!-- Dropdown --> */}
                                                        <div className="dropdown">
                                                            <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelBanque">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                                {/* <!-- BANQUE 4 --> */}
                                                <tr>
                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/mockups/banque-4-avatar.png" alt="..." />
                                                        </div> 
                                                        <a className="item-name text-reset" href="/mockups/banques/Credit-Agricole-Du-Languedoc">Banque Postale</a>
                                                    </td>

                                                    <td>
                                                        <span className="item-location">Mende (48 000)</span>
                                                    </td>

                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/avatars/profiles/avatar-4.jpg" alt="..." />
                                                        </div>
                                                        <span>Benoit BACOMPTE</span>
                                                    </td>

                                                    <td  className="item-phone">
                                                        <a href="tel:+33655555555">(+33) 4 66 89 33 41</a>
                                                    </td>

                                                    <td className="text-right">
                                                        {/* <!-- Dropdown --> */}
                                                        <div className="dropdown">
                                                            <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelBanque">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                                {/* <!-- BANQUE 5 --> */}
                                                <tr>
                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/mockups/banque-5-avatar.jpeg" alt="..." />
                                                        </div> 
                                                        <a className="item-name text-reset" href="/mockups/banques/Credit-Agricole-Du-Languedoc">Crédit Mutuel</a>
                                                    </td>

                                                    <td>
                                                        <span className="item-location">Mende (48 000)</span>
                                                    </td>

                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/avatars/profiles/avatar-5.jpg" alt="..." />
                                                        </div>
                                                        <span>Jean TARDITS</span>
                                                    </td>

                                                    <td  className="item-phone">
                                                        <a href="#!">(+33) 4 66 65 60 79</a>
                                                    </td>

                                                    <td className="text-right">
                                                        {/* <!-- Dropdown --> */}
                                                        <div className="dropdown">
                                                            <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelBanque">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                                {/* <!-- BANQUE 6 --> */}
                                                <tr>
                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/mockups/banque-6-avatar.png" alt="..." />
                                                        </div> 
                                                        <a className="item-name text-reset" href="/mockups/banques/Credit-Agricole-Du-Languedoc">Société Générale</a>
                                                    </td>

                                                    <td>
                                                        <span className="item-location">Mende (48 000)</span>
                                                    </td>

                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/avatars/profiles/avatar-6.jpg" alt="..." />
                                                        </div>
                                                        <span>Tien-Loc NGUYEN</span>
                                                    </td>

                                                    <td  className="item-phone">
                                                        <a href="#!">(+33) 4 66 49 33 78</a>
                                                    </td>

                                                    <td className="text-right">
                                                        {/* <!-- Dropdown --> */}
                                                        <div className="dropdown">
                                                            <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelBanque">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                                {/* <!-- BANQUE 7 --> */}
                                                <tr>
                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/mockups/banque-7-avatar.png" alt="..." />
                                                        </div> 
                                                        <a className="item-name text-reset" href="/mockups/banques/Credit-Agricole-Du-Languedoc">Banque Populaire du Sud</a>
                                                    </td>

                                                    <td>
                                                        <span className="item-location">Mende (48 000)</span>
                                                    </td>

                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/avatars/profiles/avatar-7.jpg" alt="..." />
                                                        </div>
                                                        <span>Julien CASONI</span>
                                                    </td>

                                                    <td  className="item-phone">
                                                        <a href="#!">(+33) 4 66 31 00 77</a>
                                                    </td>

                                                    <td className="text-right">
                                                        {/* <!-- Dropdown --> */}
                                                        <div className="dropdown">
                                                            <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelBanque">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                                {/* <!-- BANQUE 8 --> */}
                                                <tr>
                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/mockups/banque-8-avatar.jpeg" alt="..." />
                                                        </div> 
                                                        <a className="item-name text-reset" href="/mockups/banques/Credit-Agricole-Du-Languedoc">BNP Paribas</a>
                                                    </td>

                                                    <td>
                                                        <span className="item-location">Mende (48 000)</span>
                                                    </td>

                                                    <td>
                                                        <div className="avatar avatar-xs align-middle mr-2">
                                                            <img className="avatar-img rounded-circle" src="/assets/img/avatars/profiles/avatar-8.jpg" alt="..." />
                                                        </div>
                                                        <span>Joseph VALLOT</span>
                                                    </td>

                                                    <td  className="item-phone">
                                                        <a href="#!">(+33) 4 66 71 25 90</a>
                                                    </td>

                                                    <td className="text-right">
                                                        {/* <!-- Dropdown --> */}
                                                        <div className="dropdown">
                                                            <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelBanque">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        </div>
                                        <div className="card-footer d-flex justify-content-between">

                                        {/* <!-- Pagination (prev) --> */}
                                        <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                            <li className="page-item">
                                            <a className="page-link pl-0 pr-4 border-right" href="#!">
                                                <i className="fe fe-arrow-left mr-1"></i> Préc.
                                            </a>
                                            </li>
                                        </ul>

                                        {/* <!-- Pagination --> */}
                                        <ul className="list-pagination pagination pagination-tabs card-pagination">
                                            <li className="active"><a className="page" href="#!" data-i="1" data-page="10">1</a></li>
                                        </ul>

                                        {/* <!-- Pagination (next) --> */}
                                        <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                            <li className="page-item">
                                            <a className="page-link pl-4 pr-0 border-left" href="#!">
                                                Suiv. <i className="fe fe-arrow-right ml-1"></i>
                                            </a>
                                            </li>
                                        </ul>

                                        {/* <!-- Alert --> */}
                                        <div className="list-alert alert alert-dark alert-dismissible border fade" role="alert">

                                            {/* <!-- Content --> */}
                                            <div className="row align-items-center">
                                            <div className="col">

                                                {/* <!-- Checkbox --> */}
                                                <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="cardAlertCheckbox" checked disabled/>
                                                <label className="custom-control-label text-white" htmlFor="cardAlertCheckbox"><span className="list-alert-count">0</span> deal(s)</label>
                                                </div>

                                            </div>
                                            <div className="col-auto mr-n3">

                                                {/* <!-- Button --> */}
                                                <button className="btn btn-sm btn-white-20">
                                                Edit
                                                </button>

                                                {/* <!-- Button --> */}
                                                <button className="btn btn-sm btn-white-20">
                                                Delete
                                                </button>

                                            </div>
                                            </div> {/* <!-- / .row --> */}

                                            {/* <!-- Close --> */}
                                            <button type="button" className="list-alert-close close" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                            </button>

                                        </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div> {/* <!-- / .row --> */}
                    
                </div>

            </div> {/* <!-- / .main-content --> */}

        </div> 
    )
}

export default Banques