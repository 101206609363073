import React from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm'
import SubmitForm from '../../../toolsComponents/forms/SubmitForm'
import SelectPays from '../../../toolsComponents/SelectPays'
import SelectSituationFamiliale from '../../../toolsComponents/SelectSituationFamiliale'
import SelectTypeEmploi from '../../../toolsComponents/SelectTypeEmploi'

const UnProfilGeneral = ({ user, onChange, onSubmit }) => {

  return (
    <>
      <form>
        <h2>Identité</h2>

        <div className="row">
          <div className="col">
            <InputForm
              label="Prénom"
              inputName="prenom"
              value={user.prenom}
              onChange={onChange}
            />
          </div>

          <div className="col">
            <InputForm
              label="Nom"
              inputName="nom"
              value={user.nom}
              onChange={onChange}
            />
          </div>
        </div>

        <InputForm
          label="Adresse e-mail"
          inputName="email"
          type="email"
          value={user.email}
          readOnly={true}
        />

        <InputForm
          label="Adresse"
          inputName="adresse"
          value={user.adresse}
          onChange={onChange}
        />

        <div className="row">
          <div className="col-3">
            <InputForm
              label="Code postal"
              inputName="code_postal"
              value={user.code_postal}
              onChange={onChange}
            />
          </div>

          <div className="col-6">
            <InputForm
              label="Ville"
              inputName="ville"
              value={user.ville}
              onChange={onChange}
            />
          </div>

          <div className="col-3">
            <SelectPays 
              pays={user.pays}
              inputName="pays"
              onChange={onChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <InputForm
              label="Numéro de téléphone"
              inputName="telephone"
              value={user.telephone}
              type="phone"
              onChange={onChange}
            />
          </div>
        </div>

        <br />
        <h2>Etat civil</h2>

        <div className="row">
          <div className="col-3">
            <InputForm
              label="Date de naissance"
              inputName="date_naissance"
              value={user.date_naissance}
              type="date"
              onChange={onChange}
            />
          </div>

          <div className="col-6">
            <InputForm
              label="Lieu de naissance"
              inputName="lieu_naissance"
              value={user.lieu_naissance}
              onChange={onChange}
            />
          </div>

          <div className="col-3">
            <SelectPays 
              label="Nationalité"
              pays={user.nationalite}
              inputName="nationalite"
              onChange={onChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <SelectSituationFamiliale 
              inputName="situation_familiale"
              value={user.situation_familiale}
              onChange={onChange}
            />
          </div>
        </div>
        <br />

        <h2>Situation financière</h2>

        <div className="row">
          <div className="col">
            <SelectTypeEmploi
              inputName="type_emploi"
              value={user.type_emploi}
              onChange={onChange}
            />
          </div>

          <div className="col">
            <InputForm
              label="Employeur"
              inputName="employeur"
              value={user.employeur}
              onChange={onChange}
            />
          </div>
        </div>

        <InputForm
          label="Fonction dans l'entreprise"
          inputName="fonction"
          value={user.fonction}
          onChange={onChange}
        />

        <div className="row">
          <div className="col">
            <InputForm
              label="Revenus nets"
              inputName="salaire_net"
              value={user.salaire_net}
              type="number"
              prepend="€/mois"
              onChange={onChange}
            />
          </div>

          <div className="col">
            <InputForm
              label="Epargne personnelle"
              inputName="epargne_personnelle"
              value={user.epargne_personnelle}
              type="number"
              prepend="€"
              onChange={onChange}
            />
          </div>

          <div className="col">
            <InputForm
              label="Patrimoine personnel"
              inputName="patrimoine_personnel"
              value={user.patrimoine_personnel}
              type="number"
              prepend="€"
              onChange={onChange}
            />
          </div>
        </div>

        <SubmitForm
          label="Sauvegarder"
          onSubmit={onSubmit}
        />
      </form>
      <br /><br />
    </>
  )
}

export default UnProfilGeneral