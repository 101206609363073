import React from "react";
import defined from "../../ressources/defined";

const EtudeItemPrix = ({ etude }) => {
  const getBadgeClass = (value, threshold, isThreshUp) => {
    if (threshold === null) return "badge badge-soft-success";

    if (isThreshUp && value > threshold) return "badge badge-soft-warning";
    else if (!isThreshUp && value < threshold)
      return "badge badge-soft-warning";
    else return "badge badge-soft-success";
  };

  const getSign = (value) => {
    if (value < 0) return "";
    else return "+";
  };

  const displayBadge = (
    value,
    unit,
    thresh,
    suffix,
    withSign = true,
    isThreshUp = true
  ) => {
    if (isValueNotSet(value)) return getNA();

    return (
      <>
        <span className={getBadgeClass(value, thresh, isThreshUp)}>
          <strong>
            {withSign && getSign(value)}
            {value}
            {unit}
          </strong>{" "}
          {suffix}
        </span>
        &nbsp;
      </>
    );
  };

  const isValueNotSet = (value) => {
    return !value || value === null || value === "" || isNaN(value);
  };

  const getNA = () => {
    return <em style={{ color: "red" }}>N/A&nbsp;</em>;
  };

  const displayValue = (value, suffix) => {
    if (isValueNotSet(value)) return getNA();

    return <strong>{`${value} ${suffix}`}</strong>;
  };

  const displayEvoVF = (value, suffix) => {
    const number = value > 0 ? "+" + value : value;

    if (value <= -1)
      return (
        <>
          <span className={"badge badge-soft-danger"}>
            <strong>{number}%</strong>&nbsp;{suffix}
          </span>
          &nbsp;
        </>
      );
    else if (value > -1 && value <= 0)
      return (
        <>
          <span className={"badge badge-soft-warning"}>
            <strong>{number}%</strong>&nbsp;{suffix}
          </span>
          &nbsp;
        </>
      );
    else
      return (
        <>
          <span className={"badge badge-soft-success"}>
            <strong>{number}%</strong>&nbsp;{suffix}
          </span>
          &nbsp;
        </>
      );
  };

  const getEvoVF = (cur_vf, annee_vf) => {
    if (cur_vf && annee_vf) {
      return (
        ((parseFloat(cur_vf) - parseFloat(annee_vf)) / parseFloat(cur_vf)) *
        100
      ).toFixed(1);
    }
  };

  const countTotalVentesAppartements = () => {
    let result = 0;

    if (
      etude &&
      etude.valeurs_foncieres &&
      etude.valeurs_foncieres.appartements
    ) {
      for (const v of etude.valeurs_foncieres.appartements) {
        result += v.global.nb_transactions;
      }
    }

    return result;
  };

  const countTotalVentesMaisons = () => {
    let result = 0;

    if (etude && etude.valeurs_foncieres && etude.valeurs_foncieres.maisons) {
      for (const v of etude.valeurs_foncieres.maisons) {
        result += v.global.nb_transactions;
      }
    }

    return result;
  };

  const displayFrom2022 = (ventes) => {
    const vf2022 = ventes.filter((v) => v.annee === 2022)[0].global.prix_moyen;
    const vf2021 = ventes.filter((v) => v.annee === 2021)[0].global.prix_moyen;
    const vf2020 = ventes.filter((v) => v.annee === 2020)[0].global.prix_moyen;
    const vf2018 = ventes.filter((v) => v.annee === 2016)[0].global.prix_moyen;

    return (
      <>
        Prix d'achat moyen au m² <em>en 2022</em> :{" "}
        {displayValue(vf2022, "€ / m²")}
        <br />
        Evolution : &nbsp;
        {displayEvoVF(getEvoVF(vf2022, vf2021), "en 1 an")}
        {displayEvoVF(getEvoVF(vf2022, vf2020), "en 2 ans")}
        {displayEvoVF(getEvoVF(vf2022, vf2018), "en 5 ans")}
        <br />
      </>
    );
  };

  const displayFrom2021 = (ventes) => {
    const vf2021 = ventes.filter((v) => v.annee === 2021)[0].global.prix_moyen;
    const vf2020 = ventes.filter((v) => v.annee === 2020)[0].global.prix_moyen;
    const vf2019 = ventes.filter((v) => v.annee === 2019)[0].global.prix_moyen;
    const vf2016 = ventes.filter((v) => v.annee === 2016)[0].global.prix_moyen;

    return (
      <>
        Prix d'achat moyen au m² <em>en 2021</em> :{" "}
        {displayValue(vf2021, "€ / m²")}
        <br />
        Evolution : &nbsp;
        {displayEvoVF(getEvoVF(vf2021, vf2020), "en 1 an")}
        {displayEvoVF(getEvoVF(vf2021, vf2019), "en 2 ans")}
        {displayEvoVF(getEvoVF(vf2021, vf2016), "en 5 ans")}
        <br />
      </>
    );
  };

  const displayVentesAppartements = () => {
    // Il y a des ventes en 2022
    if (
      etude.valeurs_foncieres.appartements.filter((v) => v.annee === 2022)
        .length > 0 &&
      etude.valeurs_foncieres.appartements.filter((v) => v.annee === 2022)[0]
        .global.nb_transactions >= defined.YEAR_SALES_THRESHOLD
    ) {
      return displayFrom2022(etude.valeurs_foncieres.appartements);
    }
    // Il n'y a en a pas
    else {
      return displayFrom2021(etude.valeurs_foncieres.appartements);
    }
  };

  const displayVentesMaisons = () => {
    // Il y a des ventes en 2022
    if (
      etude.valeurs_foncieres.maisons.filter((v) => v.annee === 2022).length >
        0 &&
      etude.valeurs_foncieres.maisons.filter((v) => v.annee === 2022)[0].global
        .nb_transactions >= defined.YEAR_SALES_THRESHOLD
    ) {
      return displayFrom2022(etude.valeurs_foncieres.maisons);
    }
    // Il n'y a en a pas
    else {
      return displayFrom2021(etude.valeurs_foncieres.maisons);
    }
  };

  const displayManuelle = () => {
    return (
      <>
        <span style={{ textDecoration: "underline" }}>Saisie manuelle</span>
        <br />
        Prix d'achat moyen au m² : {displayValue(etude.prix_achat_m2, "€ / m²")}
        <br />
        Evolution : &nbsp;
        {displayBadge(etude.prix_achat_m2_1an, "%", 5, "en 1 an")}
        {displayBadge(etude.prix_achat_m2_2ans, "%", 5, "en 2 ans")}
        {displayBadge(etude.prix_achat_m2_5ans, "%", 5, "en 5 ans")}
      </>
    );
  };

  /**
   - S'il y a des données saisies manuellement, je les affiche en indiquant que ce sont des données manuelles
   
   - Pour chaque typologie (maison, appartement) :
      - S'il n'y a pas assez de données, mettre un petit message comme quoi il n'y a pas assez de données
      - S'il n'y a pas 2020, faire l'évolution entre 2016 et 2020 (1, 2, 4ans)
      - Si tout va bien, affichage normal
  */

  if (
    etude &&
    countTotalVentesMaisons() < defined.YEAR_SALES_THRESHOLD &&
    countTotalVentesAppartements() < defined.YEAR_SALES_THRESHOLD
  ) {
    return (
      <>
        <p className="mb-3">
          <strong>Valeurs foncières</strong> <br />
          <em style={{ color: "orange" }}>
            Il n'est pas possible de calculer automatiquement de valeurs
            foncières pour cette ville.
          </em>
          <br />
          {displayManuelle()}
        </p>
      </>
    );
  } else {
    return (
      <>
        <p className="mb-3">
          <strong>Valeurs foncières</strong> <br />
          {(countTotalVentesAppartements() <= defined.SALES_THRESHOLD ||
            countTotalVentesMaisons() <= defined.SALES_THRESHOLD) && (
            <>
              {displayManuelle()}
              <br />
              <br />
            </>
          )}
          <span style={{ textDecoration: "underline" }}>Appartements</span>
          <br />
          {countTotalVentesAppartements() > defined.SALES_THRESHOLD ? (
            <>
              {displayVentesAppartements()}
              <br />
            </>
          ) : (
            <>
              <em style={{ color: "orange" }}>
                Il n'y a pas assez de ventes d'appartements dans la base de
                l'INSEE pour cette ville.
              </em>
              <br />
              <br />
            </>
          )}
          <span style={{ textDecoration: "underline" }}>Maisons</span>
          <br />
          {countTotalVentesMaisons() > defined.SALES_THRESHOLD ? (
            displayVentesMaisons()
          ) : (
            <>
              <em style={{ color: "orange" }}>
                Il n'y a pas assez de ventes de maisons dans la base de l'INSEE
                pour cette ville.
              </em>
              <br />
            </>
          )}
        </p>
      </>
    );
  }
};

export default EtudeItemPrix;
