import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../../toolsSlice/objectSliceHelper'

const URL = '/reset-password/'
const helper = new objectSliceHelper()

const resetPasswordSlice = createSlice({

  name: 'resetPassword',
  initialState: helper.getInitialState(),
  reducers: helper.reducers
})

export const performResetPassword = (email) => {
  return async dispatch => {
    try {
      dispatch(init('RESET_PASSWORD'))

      const reqOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email
        })
      }
  
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + URL, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(success(json.datas))
      else dispatch(failure(json.message))
    }
    catch(e) {
      dispatch(failure(e.toString()))
    }
  }
}

// Export action creators
const { init, success, failure } = resetPasswordSlice.actions

// Export reducer
export default resetPasswordSlice.reducer

// Selectors
export const resetPasswordtatusSelector = (state) => state.resetPassword.status
export const resetPasswordDatasSelector = (state) => state.resetPassword.datas