import React from 'react'
import UnProfilGeneral from './UnProfilGeneral'
import UnProfilGenericWithStore from '../UnProfilGenericWithStore'
import { useSelector } from 'react-redux'
import { unProfilDatasSelector } from '../unProfilSlice'
import { useRef } from 'react'

const UnProfilGeneralWithStore = () => {

  const user = useSelector(unProfilDatasSelector)

  const genericComponent = useRef(null)

  return <>
    <UnProfilGenericWithStore
      ref={genericComponent}
      activeMenu="general"
    >
      <UnProfilGeneral 
        user={user} 
        onChange={(e) => genericComponent.current.handleChange(e)} 
        onSubmit={() => genericComponent.current.handleSubmit()} />
    </UnProfilGenericWithStore>
  </>
}

export default UnProfilGeneralWithStore