import { createSlice } from '@reduxjs/toolkit'
import arraySliceHelper from '../../toolsSlice/arraySliceHelper'

const helper = new arraySliceHelper(['nom_ville'], 'notation', "6", {asc: false})

const etudesSlice = createSlice({
  name: 'etudes',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    getEtudesInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of users
    getEtudesSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getEtudesFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    },

    // payload : { value to search on everyfields, fields concerned }
    filterByText: (state, { payload }) => {
      helper.performChangeFilter(state, payload)
    },

    // payload : field
    sort: (state, { payload }) => {
      helper.performChangeSort(state, payload)
    },

    // payload : { nbPerPage, currentPage }
    changePagination: (state, { payload }) => {
      helper.performChangePagination(state, payload)
    }
  }
})

export const performGetEtudes = (token) => {

  return async dispatch => {
    dispatch(getEtudesInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/etudes', reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const etudes = json.datas
        dispatch(getEtudesSuccess(etudes))
      }
      else {
        dispatch(getEtudesFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getEtudesFailure(e.toString()))
    }
  }
}

// Export action creators
const { getEtudesInit, getEtudesSuccess, getEtudesFailure } = etudesSlice.actions
export const { sort, filterByText, changePagination } = etudesSlice.actions

// Export reducer
export default etudesSlice.reducer

// Selectors
export const etudesStatusSelector = (state) => state.etudes.status
export const etudesFilterSelector = (state) => state.etudes.filter
export const etudesDatasSelector = (state) => state.etudes.datas
export const etudesFilteredDatasSelector = (state) => state.etudes.filteredDatas
export const etudesPaginatedDatasSelector = (state) => state.etudes.paginatedDatas
export const etudesPaginationSelector = (state) => state.etudes.pagination