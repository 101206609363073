export const getIndiceConfiance = (totalVentes) => {

  if(totalVentes < 300) {
    return <><span style={{color:'#335eea'}}>●</span><span className="text-muted">●●●</span></>
  }
  else if(totalVentes < 1000) {
    return <><span style={{color:'#335eea'}}>●●</span><span className="text-muted">●●</span></>
  }
  else if(totalVentes < 3000) {
    return <><span style={{color:'#335eea'}}>●●●</span><span className="text-muted">●</span></>
  }
  else {
    return <span style={{color:'#335eea'}}>●●●●</span>
  }
}

export const countTotalVentes = (dvf) => {
  if(!dvf) return 0

  let result = 0

  for(const vf of dvf) {
    result += vf.global.nb_transactions
  }

  return result
}