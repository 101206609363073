import React from 'react'

const SelectTypeLogement = ({ label="Type de logement", inputName="type_logement", value, onChange }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <div className="form-group">
      <label htmlFor={inputName+'-select'}>
        {label}
      </label>

      <select className="form-control" name={inputName} id={inputName+'-select'} onChange={handleChange} value={value}>
        <option value="">---</option>
        <option value="t1">T1</option>
        <option value="t2">T2</option>
        <option value="t3">T3</option>
        <option value="t4">T4</option>
        <option value="t5">T5</option>
        <option value="t6">T6</option>
        <option value="autre">Autre</option>
      </select>
    </div>
  )
}

export default SelectTypeLogement