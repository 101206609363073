import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../toolsSlice/objectSliceHelper'

const helper = new objectSliceHelper()

const unArtisanSlice = createSlice({

  name: 'unArtisan',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    getUnArtisanInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of users
    getUnArtisanSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getUnArtisanFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    }
  }
})

export const performGetUnArtisan = (token, id) => {

  return async dispatch => {
    dispatch(getUnArtisanInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }

      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/artisans/' + id, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const artisan = json.datas
        dispatch(getUnArtisanSuccess(artisan))
      }
      else {
        dispatch(getUnArtisanFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getUnArtisanFailure(e.toString()))
    }
  }
}

// Export action creators
const { getUnArtisanInit, getUnArtisanSuccess, getUnArtisanFailure } = unArtisanSlice.actions

// Export reducer
export default unArtisanSlice.reducer

// Selectors
export const unArtisanStatusSelector = (state) => state.unArtisan.status
export const unArtisanDatasSelector = (state) => state.unArtisan.datas