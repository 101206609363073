import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import defined from '../../ressources/defined'
import Navigation from '../../toolsComponents/Navigation'
import SimpleError from '../../toolsComponents/SimpleError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'
import { loginDatasSelector } from '../auth/login/loginSlice'
import Formations from './Formations'
import { filterByText, formationsFilterSelector, formationsPaginatedDatasSelector, formationsStatusSelector, performGetFormations } from './formationsSlice'

const FormationsWithStore = () => {

  const [ cookies ] = useCookies(['token', 'email'])
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(formationsStatusSelector)
  const formations = useSelector(formationsPaginatedDatasSelector)
  const filter = useSelector(formationsFilterSelector)

  useEffect(() => {
    // Get formations
    dispatch(performGetFormations(cookies.token))
  }, [dispatch, cookies.token])

  const handleSearch = (value) => {
    dispatch(filterByText({
        value,
        fields: ['title', 'categories', 'main_module', 'sub_module']
    }))
  }

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      <div className="main-content bg-fixed-bottom" style={{backgroundImage:'url(/assets/img/illustrations/sticky.svg)'}}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-10">
              {/* <!-- Header --> */}
              <div className="header mt-md-5">
                <div className="header-body">
                  {/* <!-- Pretitle --> */}
                  <h6 className="header-pretitle">
                    Formations
                  </h6>

                  {/* <!-- Title --> */}
                  <h1 className="header-title">
                    Apprenez à investir dans l'immobilier grâce à la Méthode T.H.R™
                  </h1>
                </div>
              </div>

              {loading && 
                <SimpleLoading />
              }

              {(hasError && errorMessage === defined.INVALID_AUTH_LEVEL) &&
                <div>
                    <h1>Tu souhaites accéder aux formations d'Alex Ferreira directement intégrées dans ProjetImmo.io ?</h1>
                    <img src="/assets/img/mockups/offre-formations-1.png" alt="offre formation" style={{maxWidth:"100%"}}/>
                </div>
              }

              {(hasError && errorMessage !== defined.INVALID_AUTH_LEVEL) &&
                <SimpleError>
                  {errorMessage}
                </SimpleError>
              }

              {!loading && !hasError &&
                <Formations 
                  formations={formations}
                  onSearch={handleSearch}
                  searchValue={filter.text.value}
                />
              }
            </div>
          </div>
        </div>
      </div> 
    </div>
  )
}

export default FormationsWithStore