import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../toolsComponents/Navigation'
import SimpleError from '../../toolsComponents/SimpleError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'
import { loginDatasSelector } from '../auth/login/loginSlice'
import Projets from './Projets'
import { projetsPaginatedDatasSelector, projetsStatusSelector, projetsPaginationSelector, filterByText, performGetProjets, changePagination, sort, projetsFilterSelector, performDelUnProjet } from './projetsSlice'

const ProjetsWithStore = () => {

  const [ cookies ] = useCookies(['token', 'email'])
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(projetsStatusSelector)
  const projets = useSelector(projetsPaginatedDatasSelector)
  const pagination = useSelector(projetsPaginationSelector)
  const filter = useSelector(projetsFilterSelector)

  useEffect(() => {
      // Get projects
      dispatch(performGetProjets(cookies.token))

  }, [dispatch, cookies.token])

  const handleSearch = (value) => {
    dispatch(filterByText({
      value,
      fields: ['titre', 'description', 'ville']
    }))
  }

  const handlePagination = (nbPerPage, currentPage) => {
    dispatch(changePagination({
      nbPerPage, currentPage
    }))
  }

  const handleSort = (field) => {
    dispatch(sort(field))
  }

  const handleDelete = (value) => {
    dispatch(performDelUnProjet(cookies.token, value))
  }

  return (
    <div>
      <Navigation granted_modules={granted_modules} formationCategories="projets de bien"/>

      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {/* <!-- Header --> */}
              <div className="header">
                <div className="container-fluid">
                  {/* <!-- Body --> */}
                  <div className="header-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-lg avatar-4by3">
                          <img src="/assets/img/mockups/mes-projets-header-avatar-2.jpeg" alt="..." className="avatar-img rounded" />
                        </div>
                      </div>

                      <div className="col ml-n3 ml-md-n2">
                        {/* <!-- Pretitle --> */}
                        <h6 className="header-pretitle">
                          Vue globale
                        </h6>

                        {/* <!-- Title --> */}
                        <h1 className="header-title">
                          Mes projets immobiliers
                        </h1>
                      </div>

                      <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                        {/* <!-- Button --> */}
                        <a className="btn btn-primary d-block d-md-inline-block lift" href="#!" role="button" data-toggle="modal" data-target="#WIPModal">
                            <i className="fe fe-plus"></i> Nouveau projet
                        </a>
                      </div>
                    </div> 
                  </div>
                </div>
              </div> 

              {loading && 
                <SimpleLoading />
              }

              {!loading && hasError && 
                <SimpleError>
                  {errorMessage}
                </SimpleError>
              }

              {!loading && !hasError &&
                <Projets 
                  projets={projets}
                  pagination={pagination}
                  filter={filter}
                  onSearch={handleSearch}
                  onPagination={handlePagination}
                  onSort={handleSort}
                  onDelete={handleDelete}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ProjetsWithStore