import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import UnBienGenericWithStore from '../UnBienGenericWithStore'
import { unBienDatasSelector } from '../unBienSlice'
import UnBienTransactions from './UnBienTransactions'

const UnBienTransactionsWithStore = ({ id }) => {

  const genericComponent = useRef(null)

  const bien = useSelector(unBienDatasSelector)

  return <>
    <UnBienGenericWithStore
      ref={genericComponent}
      activeMenu="transactions"
      id={id}
      >
        <UnBienTransactions 
          bien={bien}
        />
      </UnBienGenericWithStore>
  </>  
}

export default UnBienTransactionsWithStore