import React from 'react'
import RDVsCardItem from './RDVsCardItem'

const RDVsCard = ( { rdvs } ) => {

  return (
    <div className="card">                            
      <div className="card-header">
        {/* <!-- Title --> */}
        <h4 className="card-header-title">
          Prochains rendez-vous
        </h4>

        <a href="/mes-rendez-vous" className="small">
          Voir plus
        </a>
      </div>

      <div className="card-body">
        <div className="list-group list-group-flush my-n3">
          {rdvs && rdvs.map((r, index) => {
            return <RDVsCardItem 
              key={index}
              rdv={r} 
            />
          })}
          
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-12" style={{textAlign:'center'}}>
                <a  href="/mes-rendez-vous">Ajouter un rendez-vous</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>                         
  )
}

export default RDVsCard