import React, { useState } from "react";
import InputForm from "../../../toolsComponents/forms/InputForm";
import SubmitForm from "../../../toolsComponents/forms/SubmitForm";
import SelectTypePiece from "../../../toolsComponents/SelectTypePiece";
import LotPlomberie from "./InfoLogementComponents/LotPlomberie.jsx";
import LotElectricite from "./InfoLogementComponents/LotElectricite.jsx";
import LotMaconnerie from "./InfoLogementComponents/LotMaconnerie.jsx";
import LotDemolition from "./InfoLogementComponents/LotDemolition.jsx";
import LotMenuiseriesInterieures from "./InfoLogementComponents/LotMenuiseriesInterieures.jsx";
import LotRevetementsSol from "./InfoLogementComponents/LotRevetementsSol.jsx";
import LotRevetementsMur from "./InfoLogementComponents/LotRevetementsMur.jsx";
import LotIsolation from "./InfoLogementComponents/LotIsolation.jsx";
import LotCloisonsPlafonds from "./InfoLogementComponents/LotCloisonsPlafonds.jsx";
import LotCuisine from "./InfoLogementComponents/LotCuisine.jsx";
import { useEffect } from "react";
import { getNewArray } from "./utils";

// getNewArray({
//   value: value,
//   max: 10,
//   initialObject: {
//     surface: 9,
//     categorie: undefined,
//   },
//   array: logement.pieces,
// })

const InfoLogement = ({
  logements,
  onNext,
  onPrevious,
  onChangeLot,
  onChange,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [logement, setLogement] = useState(null);

  useEffect(() => {
    setLogement(logements[currentIndex]);
  }, [logements, currentIndex]);

  const handleNext = () => {
    if (currentIndex + 1 >= logements.length) {
      setCurrentIndex(0);
      onNext();
    } else {
      setCurrentIndex(currentIndex + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    if (currentIndex - 1 < 0) {
      setCurrentIndex(0);
      onPrevious();
    } else {
      setCurrentIndex(currentIndex - 1);
      window.scrollTo(0, 0);
    }
  };

  const onChangeIndex = (index) => {
    setCurrentIndex(index);
  };

  const handleChange = (lot, inputName, value) => {
    onChange(currentIndex, lot, inputName, value);
  };

  return (
    <>
      <div className="mx-5">
        <div
          className="generateurDescriptifHeader col-12 d-flex flex-row flex-wrap"
          style={{ gap: "24px" }}
        >
          {logements.map((l, index) => {
            if (index < currentIndex)
              return (
                <div
                  class="generateurDescriptifStepHeader done"
                  onClick={() => onChangeIndex(index)}
                >
                  Logement n°{index + 1}
                </div>
              );
            else if (index === currentIndex)
              return (
                <div
                  class="generateurDescriptifStepHeader selected"
                  onClick={() => onChangeIndex(index)}
                >
                  Logement n°{index + 1}
                </div>
              );
            else
              return (
                <div
                  class="generateurDescriptifStepHeader"
                  onClick={() => onChangeIndex(index)}
                >
                  Logement n°{index + 1}
                </div>
              );
          })}
        </div>
      </div>

      <div className="generateurDescriptifStepContainer mx-5 text-center d-flex flex-column justify-content-center">
        {/* 
      ————————————————————————————————————————————————————————————
      INTRO
      ————————————————————————————————————————————————————————————
      */}
        <div className="row">
          <div className="col-6 text-left">
            <InputForm
              label="Nommez le logement n°1"
              inputName="nom"
              type="Text"
              placeholder="T3 rez-de-chaussée gauche"
              value={logement?.global.nom}
              onChange={({ inputName, value }) =>
                handleChange("global", inputName, value)
              }
            />
          </div>

          <div className="col-6">
            <div className="ml-auto mr-auto">
              <InputForm
                label="Combien de pièces seront dans le logement ?"
                inputName="pieces"
                type="Number"
                placeholder="4"
                value={logement?.structure.pieces.length}
                onChange={({ inputName, value }) =>
                  handleChange(
                    "structure",
                    inputName,
                    getNewArray({
                      value,
                      max: 10,
                      array: logement.structure.pieces,
                      initialObject: {
                        surface: 9,
                        categorie: undefined,
                      },
                    })
                  )
                }
              />
            </div>
          </div>
        </div>

        {/* 
      ————————————————————————————————————————————————————————————
      PIECES
      ————————————————————————————————————————————————————————————
      */}
        <div className="d-flex flex-direction-column flex-wrap mb-3">
          {logement?.structure.pieces.map((p, index) => {
            return (
              <>
                <div className="piece mr-3 text-left">
                  <label className="text-left mb-3">PIÈCE N°{index + 1}</label>
                  <div className="d-flex flex-wrap mb-n4 justify-content-start">
                    <div class="mr-3">
                      <InputForm
                        label="Surface de la pièce ?"
                        inputName={`pieces.${index}.surface`}
                        type="Number"
                        placeholder="18"
                        prepend="m²"
                        value={p.surface}
                        onChange={({ inputName, value }) =>
                          handleChange("structure", inputName, value)
                        }
                      />
                    </div>
                    <div>
                      <SelectTypePiece
                        inputName={`pieces.${index}.categorie`}
                        value={p.categorie}
                        onChange={({ inputName, value }) =>
                          handleChange("structure", inputName, value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <LotPlomberie plomberie={logement?.plomberie} onChange={handleChange} />

        <LotElectricite
          electricite={logement?.electricite}
          onChange={handleChange}
        />

        <LotMaconnerie
          maconnerie={logement?.maconnerie}
          onChange={handleChange}
        />

        <LotDemolition
          onChange={handleChange}
          demolition={logement?.demolition}
        />

        <LotMenuiseriesInterieures
          onChange={handleChange}
          menuiserieInt={logement?.menuiserieInt}
        />

        <LotRevetementsSol
          onChange={handleChange}
          revetementsSol={logement?.revetementsSol}
        />

        <LotRevetementsMur
          onChange={handleChange}
          revetementsMur={logement?.revetementsMur}
        />

        <LotIsolation onChange={handleChange} isolation={logement?.isolation} />

        <LotCloisonsPlafonds
          onChange={handleChange}
          cloisonsPlafonds={logement?.cloisonsPlafonds}
        />

        <LotCuisine onChange={handleChange} cuisine={logement?.cuisine} />

        {/* 
      ————————————————————————————————————————————————————————————
      BUTTONS
      ————————————————————————————————————————————————————————————
      */}
        <div className="row mt-4">
          <div className="col-6 d-flex flex-direction-row justify-content-start align-items-center">
            <SubmitForm
              label="Précédent"
              isSticky={false}
              icon=""
              onSubmit={handlePrevious}
              id="previousButton"
            />
          </div>

          <div className="col-6 d-flex flex-direction-row justify-content-end">
            <SubmitForm
              label="Suivant"
              isSticky={false}
              icon=""
              onSubmit={handleNext}
              id="nextButton"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoLogement;
