import { createSlice } from '@reduxjs/toolkit'
import arraySliceHelper from '../../toolsSlice/arraySliceHelper'

const helper = new arraySliceHelper(['montant_pret, taux_interet, taux_assurance, duree, mensualite, status'], 'montant_pret', "10")

const unArtisanDevisSlice = createSlice({

  name: 'unArtisanDevis',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    getUnArtisanDevisInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of users
    getUnArtisanDevisSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getUnArtisanDevisFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    },

    // payload : { value to search on everyfields, fields concerned }
    filterByText: (state, { payload }) => {
      helper.performChangeFilter(state, payload)
    },

    // payload : field
    sort: (state, { payload }) => {
      helper.performChangeSort(state, payload)
    },

    // payload : { nbPerPage, currentPage }
    changePagination: (state, { payload }) => {
      helper.performChangePagination(state, payload)
    }
  }
})

export const performGetUnArtisanDevis = (token, id_artisan) => {

  return async dispatch => {
    dispatch(getUnArtisanDevisInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }
      
      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/devis_by_artisan_id/' + id_artisan, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const devis = json.datas
        dispatch(getUnArtisanDevisSuccess(devis))
      }
      else {
        dispatch(getUnArtisanDevisFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getUnArtisanDevisFailure(e.toString()))
    }
  }
}

// Export action creators
const { getUnArtisanDevisInit, getUnArtisanDevisSuccess, getUnArtisanDevisFailure } = unArtisanDevisSlice.actions
export const { sort, filterByText, changePagination } = unArtisanDevisSlice.actions

// Export reducer
export default unArtisanDevisSlice.reducer

// Selectors
export const unArtisanDevisStatusSelector = (state) => state.unArtisanDevis.status
export const unArtisanDevisDatasSelector = (state) => state.unArtisanDevis.datas
export const unArtisanDevisFilteredDatasSelector = (state) => state.unArtisanDevis.filteredDatas
export const unArtisanDevisPaginatedDatasSelector = (state) => state.unArtisanDevis.paginatedDatas
export const unArtisanDevisPaginationSelector = (state) => state.unArtisanDevis.pagination
export const unArtisanDevisFilterSelector = (state) => state.unArtisanDevis.filter