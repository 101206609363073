import React from 'react'

const SelectSocieteParente = ({inputName, value, values, withNone=true, onChange}) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <div className="form-group">
      <label htmlFor={'input'+inputName}>
        Société parente
      </label>

      <select id={'input'+inputName} className="form-control" value={value} onChange={handleChange}>
        {withNone && <option value="">---</option>}
        {values?.map((v) => <option value={v._id}>{v.titre}</option>)}
      </select>
    </div>
  )
}

export default SelectSocieteParente