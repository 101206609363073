class arraySliceHelper {
  /**
   * Create a new helper
   * @param {*} filterFields
   * @param {*} sortField
   * @param {*} nbPerPage
   */
  constructor(filterFields, sortField, nbPerPage, options = { asc: true }) {
    this.filterFields = filterFields;
    this.sortField = sortField;
    this.nbPerPage = nbPerPage;
    this.asc = options.asc;
  }

  /**
   * Return a new generic InitialState
   * @returns
   */
  getInitialState = () => {
    return {
      status: {
        loading: false,
        hasError: false,
        errorMessage: null,
      },
      filter: {
        text: {
          value: "",
          fields: this.filterFields,
        },
      },
      sort: {
        field: this.sortField,
        asc: this.asc,
      },
      pagination: {
        nbPerPage: this.nbPerPage,
        currentPage: 1,
        totalPage: 1,
      },
      selectedDatas: [],
      filteredDatas: [],
      paginatedDatas: [],
      datas: [],
    };
  };

  /**
   * Reset a generic InitialState
   * @param {*} state
   */
  resetInitialState = (state) => {
    state.status = {
      loading: false,
      hasError: false,
      errorMessage: null,
    };

    state.filter = {
      text: {
        value: "",
        fields: this.filterFields,
      },
    };

    state.sort = {
      field: this.sortField,
      asc: this.asc,
    };

    state.pagination = {
      nbPerPage: this.nbPerPage,
      currentPage: 1,
      totalPage: 1,
    };

    state.selectedDatas = [];
    state.filteredDatas = [];
    state.paginatedDatas = [];
    state.datas = [];
  };

  /**
   * To use in getObjectInit
   * @param {*} state
   */
  performInit = (state) => {
    this.resetInitialState(state);

    state.status.loading = true;
  };

  /**
   * Perform everything we need when datas arrive with success
   * @param {*} state
   * @param {*} payload
   */
  performSuccess = (state, payload) => {
    state.status.loading = false;
    state.status.hasError = false;
    state.status.errorMessage = null;

    state.datas = payload;
    state.filteredDatas = payload;

    this.performSort(state);

    this.performTotalPage(state);

    this.performPaginatedData(state);

    state.selectedDatas = [];
  };

  /**
   * Manage everything when there is a failure in API call
   * @param {*} state
   * @param {*} payload
   */
  performFailure = (state, payload) => {
    state.status.loading = false;
    state.status.hasError = true;
    state.status.errorMessage = payload;

    state.datas = [];
    state.filteredDatas = [];
    state.paginatedDatas = [];
    state.selectedDatas = [];
  };

  /**
   * Manage to delete one element in data local state
   * @param {*} state
   * @param {*} payload
   */
  performDeleteOne = (state, payload) => {
    state.datas = state.datas.filter((d) => d._id !== payload);

    this.filter(state);

    this.performTotalPage(state);
    this.performPaginatedData(state);
    //MAYBE CHANGE SELECTED DATA ???
    state.selectedDatas = [];
  };

  /**
   * Manage filter for the reducer
   * @param {*} state
   * @param {*} payload
   */
  performChangeFilter = (state, payload) => {
    state.filter.text = payload;
    state.pagination.currentPage = 1;
    this.filter(state);

    this.performTotalPage(state);
    this.performPaginatedData(state);
    state.selectedDatas = [];
  };

  /**
   * Manage everything to sort datas into filteredDatas with sort infos
   * @param {*} state
   * @param {*} payload
   */
  performChangeSort = (state, payload) => {
    state.sort.asc = state.sort.field === payload ? !state.sort.asc : true;
    state.sort.field = payload;
    this.performSort(state);

    this.performPaginatedData(state);
  };

  /**
   * Manage everything for the pagination
   * @param {*} state
   * @param {*} payload
   */
  performChangePagination = (state, payload) => {
    state.pagination.nbPerPage = payload.nbPerPage;
    state.pagination.currentPage = payload.currentPage;

    this.performTotalPage(state);
    this.performPaginatedData(state);
  };

  /**
   * Compare 2 elements by the field
   * @param {*} field
   * @param {*} a
   * @param {*} b
   * @returns
   */
  compare = (field, a, b) => {
    if (a[field] < b[field]) return -1;
    if (a[field] > b[field]) return 1;
    return 0;
  };

  /**
   * Sort datas and filteredDatas with the state.sort infos
   * @param {*} state
   */
  performSort = (state) => {
    let order = state.sort.asc ? 1 : -1;

    state.datas.sort((a, b) => this.compare(state.sort.field, a, b) * order);
    state.filteredDatas.sort(
      (a, b) => this.compare(state.sort.field, a, b) * order
    );
  };

  /**
   * Calculate the number total of pages with state.pagination infos
   * @param {*} state
   */
  performTotalPage = (state) => {
    if (state.pagination.nbPerPage === "*") state.pagination.totalPage = 1;
    else
      state.pagination.totalPage = Math.ceil(
        state.filteredDatas.length / parseInt(state.pagination.nbPerPage)
      );
  };

  /**
   * Perform the pagination of the data in paginatedDatas, using pagination infos
   * @param {*} state
   */
  performPaginatedData = (state) => {
    if (state.pagination.nbPerPage === "*") {
      state.paginatedDatas = state.filteredDatas;
    } else {
      let result = [];
      let startPos =
        state.pagination.currentPage * state.pagination.nbPerPage -
        state.pagination.nbPerPage;
      let endPos =
        state.pagination.currentPage * state.pagination.nbPerPage - 1;

      for (var i = startPos; i <= endPos; i++) {
        if (state.filteredDatas[i]) result.push(state.filteredDatas[i]);
      }

      state.paginatedDatas = result;
    }
  };

  /**
   * Filter the datas into filteredDatas using filter infos, on multiple fields
   * @param {*} state
   */
  filter = (state) => {
    // Filter by text on multiple fields
    let tmpText = [];

    if (state.filter.value !== "") {
      for (var i in state.datas) {
        const item = state.datas[i];
        let includesOk = false;

        for (const [key, value] of Object.entries(item)) {
          if (
            state.filter.text.fields.includes(key) &&
            value
              .toString()
              .toUpperCase()
              .includes(state.filter.text.value.toUpperCase())
          ) {
            includesOk = true;
          }
        }

        if (includesOk) tmpText.push(item);
      }
    }

    state.filteredDatas = tmpText;
  };

  performSelect = (state, action, value) => {
    if (action === "ADD") {
      state.selectedDatas.push(value);
    } else if (action === "DEL") {
      state.selectedDatas = state.selectedDatas.filter(
        (s) => JSON.stringify(s) !== JSON.stringify(value)
      );
    } else if (action === "ALL") {
      state.selectedDatas = [...state.filteredDatas];
    } else if (action === "EMPTY") {
      state.selectedDatas = [];
    }
  };
}

export default arraySliceHelper;
