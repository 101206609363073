import React from 'react'

const SelectTypeTravaux = ({ inputName, value, onChange }) => {

  const handleChange = (e) => {
    // We send target for retro compatibilty
    if(onChange) onChange({value: e.target.value, inputName, target: e.target})
  }

  return (
    <>
      <div className="form-group">
        <label htmlFor={'input'+inputName}>
          Types de travaux à réaliser
        </label>

        <select
          className="form-control"
          id={'input'+inputName}
          name={inputName}
          value={value}
          onChange={handleChange}
        >
          <option value="0">Besoin de rien 0€/m²</option>
          <option value="150">Petits Rafraichissements 100-200€/m²</option>
          <option value="250">Petits Rafraichissements 200-300€/m²</option>
          <option value="500">Petites Rénovations 400-600€/m²</option>
          <option value="800">Grandes rénovations 700-900€/m²</option>
          <option value="1000">Tout refaire de A à Z, Division, etc... 900€-1100/m²</option>
        </select>
      </div>
    </>
  )
}

export default SelectTypeTravaux