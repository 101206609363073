import React from "react";
import DocumentsCard from "../../../toolsComponents/cards/DocumentsCard";
import TransactionsCard from "../../../toolsComponents/cards/TransactionsCard";
import InputForm from "../../../toolsComponents/forms/InputForm";
import SubmitForm from "../../../toolsComponents/forms/SubmitForm";
import Image from "../../../toolsComponents/images/Image";
import SelectSocieteParente from "../../../toolsComponents/SelectSocieteParente";
import SelectTypeBienPatrimoine from "../../../toolsComponents/SelectTypeBienPatrimoine";
import SimpleInfo from "../../../toolsComponents/SimpleInfo";

const UnBienGeneral = ({ bien, onChange, onSubmit }) => {
  const getAvatar = (bien) => {
    if (bien?.url_avatar) return bien?.url_avatar;
    else if (bien?.type === "NOM_PROPRE")
      return "/assets/img/mockups/patrimoine-nom-propre.png";
    else if (bien?.type === "SOCIETE")
      return "/assets/img/mockups/patrimoine-societe.jpeg";
    else if (bien?.type === "IMMEUBLE")
      return "/assets/img/avatars/biens/immeuble1.webp";
    else if (bien?.type === "LOGEMENT")
      return "/assets/img/avatars/biens/logement1.jpeg";
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          {bien?.type !== "NOM_PROPRE" && (
            <>
              <SelectSocieteParente
                values={bien?.all_societes}
                value={bien?.id_bien_parent}
                inputName="id_bien_parent"
                onChange={onChange}
              />

              <SelectTypeBienPatrimoine
                inputName={"type"}
                value={bien?.type}
                onChange={onChange}
              />
            </>
          )}

          {bien?.type !== "SOCIETE" && (
            <InputForm
              label={"Titre"}
              type="text"
              value={bien?.titre}
              inputName="titre"
              onChange={onChange}
            />
          )}

          {bien?.type === "SOCIETE" && (
            <>
              <InputForm
                label="Dénomination sociale"
                type="text"
                value={bien?.titre}
                inputName="titre"
                onChange={onChange}
              />

              <div className="row">
                <div className="col-9">
                  <InputForm
                    label="Type de société"
                    type="text"
                    value={bien?.type_societe}
                    inputName="type_societe"
                    onChange={onChange}
                  />
                </div>

                <div className="col-3">
                  <InputForm
                    label="Détention"
                    type="number"
                    value={bien?.part}
                    prepend={"%"}
                    inputName="part"
                    onChange={onChange}
                  />
                </div>
              </div>

              <InputForm
                label="SIRET"
                type="text"
                value={bien?.SIRET}
                inputName="SIRET"
                onChange={onChange}
              />

              <hr />
            </>
          )}

          <InputForm
            label="Adresse"
            type="text"
            value={bien?.adresse}
            inputName="adresse"
            onChange={onChange}
          />

          <div className="row">
            <div className="col-3">
              <InputForm
                label="Code postal"
                type="text"
                value={bien?.code_postal}
                inputName="code_postal"
                onChange={onChange}
              />
            </div>

            <div className="col-9">
              <InputForm
                label="Ville"
                type="text"
                value={bien?.ville}
                inputName="ville"
                onChange={onChange}
              />
            </div>
          </div>

          <InputForm
            label="Pays"
            type="text"
            value={bien?.pays}
            inputName="pays"
            onChange={onChange}
          />

          <SubmitForm label="Sauvegarder" onSubmit={onSubmit} />
        </div>

        <div className="col-6">
          <div className="row">
            {(bien.type === "SOCIETE" ||
              bien.type === "NOM_PROPRE" ||
              bien.type === "IMMEUBLE") && (
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    {/* <!-- Title --> */}
                    <h4 className="card-header-title">
                      Les biens dans cette société
                    </h4>
                  </div>

                  <div className="card-body">
                    {(!bien.biens_enfants ||
                      bien.biens_enfants.length <= 0) && (
                      <SimpleInfo>
                        Il n'y a pas de bien dans cette société
                      </SimpleInfo>
                    )}

                    <div
                      className="list-group list-group-flush my-n3"
                      style={{ paddingTop: "12px", paddingBottom: "18px" }}
                    >
                      {bien.biens_enfants?.map((e) => {
                        const padding = e.level ? e.level * 50 : 0;
                        return (
                          <>
                            <div className="row align-items-center">
                              <div
                                className="col-auto"
                                style={{ paddingLeft: padding + "px" }}
                              >
                                <a
                                  href={`/bien/${e._id}`}
                                  class="avatar avatar-4by3"
                                >
                                  <Image
                                    src={getAvatar(e)}
                                    alt="..."
                                    className="avatar-img rounded"
                                  />
                                </a>
                              </div>

                              <div className="col ms-n2">
                                <h4 className="mb-1">
                                  <a href={`/bien/${e._id}`}>{e.titre}</a>
                                </h4>

                                <p className="card-text small text-muted">
                                  <span>{e.description}</span>
                                </p>
                              </div>
                            </div>

                            <br />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className='col-12'>
            <TransactionsCard 
              transactions={bien.transactions} 
            />
          </div> */}

            <div className="col-12">
              <DocumentsCard
                isSCI={true}
                documents={bien.documents}
                id_bien={bien._id}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default UnBienGeneral;
