import React from 'react'

const SelectNationnalite = () => {

  return (
    <div className="form-group">
      <label htmlFor="exampleFormControlSelect1">
        Nationnalité
      </label>
      
      <select className="form-control" id="exampleFormControlSelect1">
        <option>Canadienne</option>
        <option selected>Française</option>
        <option>Portugaise</option>
      </select>
    </div>
  )
}

export default SelectNationnalite