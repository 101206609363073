import React from 'react'

const MonProfilHeader = ({ isActive }) => {

    return (
        <div className="header">
        <div className="container-fluid">

        {/* <!-- Body --> */}
        <div className="header-body">
            <div className="row align-items-center">

                <div className="col-auto">

                    {/* <!-- Avatar --> */}
                    <div className="avatar avatar-xxl header-avatar-top">
                    <img 
                        src="/assets/img/mockups/profil-avatar.jpg" 
                        alt="..." 
                        className="avatar-img rounded-circle border border-4 border-body" 
                        />
                    </div>

                </div>
                <div className="col ml-n3 ml-md-n2">

                    {/* <!-- Pretitle --> */}
                    <h6 className="header-pretitle">
                        Détails
                    </h6>

                    {/* <!-- Title --> */}
                    <h1 className="header-title">
                        Mon profil
                    </h1>

                </div>
            
            </div> {/* <!-- / .row --> */}

            <div className="row align-items-center">
                <div className="col">

                    {/* <!-- Nav --> */}
                    <ul className="nav nav-tabs nav-overflow header-tabs">
                        <li className="nav-item">
                            <a href="/mockups/mon-profil" className={ isActive === 'general' ? 'nav-link active' : 'nav-link' } >
                            Général
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/mockups/mon-profil/details" className={ isActive === 'details' ? 'nav-link active' : 'nav-link' }>
                            Détails
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/mockups/mon-profil/factures" className={ isActive === 'factures' ? 'nav-link active' : 'nav-link' }>
                            Factures
                            </a>
                        </li>
                    </ul>

                </div>
            </div>

        </div>

        </div>
    </div>

    )
}

export default MonProfilHeader