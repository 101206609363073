import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../toolsSlice/objectSliceHelper'

const URL = '/projets/'
const helper = new objectSliceHelper()

const unProjetSlice = createSlice({
  name: 'unProjet',
  initialState: helper.getInitialState(),
  reducers: helper.reducers
})

export const performGetUnProjet = (token, id) => {

  return async dispatch => {
    try {
      dispatch(getInit(helper.ACTIONS.GET))

      const res = await helper.performGetAPICall(token, id, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(getSuccess(json.datas))
      else dispatch(getFailure(json.message))
    }
    catch(e) {
      dispatch(getFailure(e.toString()))
    }
  }
}

export const performSyncUnProjet = (token, projet) => {
  return async dispatch => {
    try {
      dispatch(syncInit(helper.ACTIONS.SYNC))

      const res = await helper.performSyncAPICall(token, projet, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(syncSuccess(json.datas))
      else dispatch(syncFailure(json.message))
    }
    catch(e) {
      dispatch(syncFailure(e.toString()))
    }
  }
}

export const performAddUnProjet = (token, projet) => {
  return async dispatch => {
    try {
      dispatch(addInit(helper.ACTIONS.ADD))

      const res = await helper.performAddAPICall(token, projet, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(addSuccess(json.datas))
      else dispatch(addFailure(json.message))
    }
    catch(e) {
      dispatch(addFailure(e.toString()))
    }
  }
}

export const performDelUnProjet = (token, projet) => {
  return async dispatch => {
    try {
      dispatch(delInit(helper.ACTIONS.DEL))

      const res = await helper.performDelAPICall(token, projet, URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(delSuccess())
      else dispatch(delFailure(json.message))
    }
    catch(e) {
      dispatch(delFailure(e.toString()))
    }
  }
}

// Export action creators
const { getInit, syncInit, addInit, delInit, getSuccess, getFailure, syncSuccess, syncFailure, addSuccess, addFailure, delSuccess, delFailure } = unProjetSlice.actions
export const { updateByField } = unProjetSlice.actions

// Export reducer
export default unProjetSlice.reducer

// Selectors
export const unProjetStatusSelector = (state) => state.unProjet.status
export const unProjetDatasSelector = (state) => state.unProjet.datas