import React from 'react'

const RDVsCardItem = ( { rdv } ) => {

  const tmpDate = new Date(rdv.date_debut)
  const strDate = tmpDate.toLocaleDateString("fr-FR")

  return (
    <div className="list-group-item">
      <div className="row align-items-center">
        {/* <!-- Avatar --> */}
        {rdv.url_avatar &&
        <div className="col-auto">
          <a href={'/mes-rendez-vous/' + rdv._id} className="avatar">
            <img src={rdv.url_avatar} alt="" className="avatar-img rounded-circle" />
          </a>
        </div>
        }

        <div className="col ml-n2">
          {/* <!-- Title --> */}
          <a href={`/mes-projets/${rdv.id_projet}`}>
            <span>{rdv.projet_titre}</span>
          </a>

          {/* <!-- Title --> */}
          <h5 className="mb-1">
            <span>{strDate}</span>
          </h5>

          {/* <!-- Téléphone --> */}
          <p className="card-text small">
            <small>{rdv.titre}</small>
          </p>
        </div>
      </div>
    </div>      
  )
}

export default RDVsCardItem