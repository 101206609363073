import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Navigation from '../../toolsComponents/Navigation'
import SimpleEmptyTable from '../../toolsComponents/SimpleEmptyTable'
import SimpleError from '../../toolsComponents/SimpleError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'
import Etudes from './Etudes'
import { filterByText, changePagination, etudesPaginatedDatasSelector, etudesPaginationSelector, etudesStatusSelector, etudesFilterSelector, performGetEtudes } from './etudesSlice'

const EtudesWithStore = () => {

  const [ cookies ] = useCookies(['token', 'email'])
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(etudesStatusSelector)
  const etudes = useSelector(etudesPaginatedDatasSelector)
  const pagination = useSelector(etudesPaginationSelector)
  const filter = useSelector(etudesFilterSelector)

  useEffect(() => {
    // Get etues
    dispatch(performGetEtudes(cookies.token))
  }, [dispatch, cookies.token])

  const handleSearch = (value) => {
    dispatch(filterByText({
      value,
      fields: ['nom_ville']
    }))
  }

  const handlePagination = (nbPerPage, currentPage) => {
    dispatch(changePagination({
        nbPerPage, currentPage
    }))
  }

  return (
    <div>
      <Navigation granted_modules="ALL" formationCategories="étude de marché" />

      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              {/* <!-- HEADER --> */}
              <div className="header">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-lg avatar-4by3">
                          <img src="/assets/img/mockups/mes-etudes-avatar.jpeg" alt="..." className="avatar-img rounded" />
                        </div>
                      </div>

                      <div className="col ml-n3 ml-md-n2">
                        {/* <!-- Pretitle --> */}
                        <h6 className="header-pretitle">
                          Vue globale
                        </h6>

                        {/* <!-- Title --> */}
                        <h1 className="header-title">
                          Mes études de marché
                        </h1>
                      </div>

                      <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                        {/* <!-- Button --> */}
                        <a href="/recherche-ville" className="btn btn-primary d-block d-md-inline-block lift">
                          <i className="fe fe-plus"></i> Rechercher une nouvelle ville
                        </a>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>

              {loading && 
                <SimpleLoading />
              }

              {!loading && hasError && 
                <SimpleError>
                  {errorMessage}
                </SimpleError>
              }

              {!loading && !hasError && filter.text.value === '' && etudes.length === 0 &&
                <SimpleEmptyTable message='Aucune étude à afficher. Cliquez sur le bouton "Rechercher une nouvelle ville" pour en ajouter une.' />
              }
              
              {!loading && !hasError && (filter.text.value !== '' || etudes.length > 0) &&
                <Etudes 
                  etudes={etudes} 
                  pagination={pagination} 
                  onSearch={handleSearch} 
                  onPagination={handlePagination} 
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default EtudesWithStore