import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../toolsSlice/objectSliceHelper'

const helper = new objectSliceHelper()

const uneOffrePretSlice = createSlice({

  name: 'uneOffrePret',
  initialState: helper.getInitialState(),
  reducers: {
    // no payload
    getUneOffrePretInit: (state) => {
      helper.performInit(state)
    },

    // payload : array of formations
    getUneOffrePretSuccess: (state, {payload}) => {
      helper.performSuccess(state, payload)
    },

    // payload : errorMessage
    getUneOffrePretFailure: (state, { payload }) => {
      helper.performFailure(state, payload)
    }
  }
})

export const performGetUneOffrePret = (token, id) => {

  return async dispatch => {
    dispatch(getUneOffrePretInit())

    try {
      const reqOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'auth-token' : token
        },
        body: JSON.stringify()
      }

      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/offres_pret/' + id, reqOptions)
      const json = await res.json()

      if( res.status === 200 && !json.error ) {
        const offrePret = json.datas
        dispatch(getUneOffrePretSuccess(offrePret))
      }
      else {
        dispatch(getUneOffrePretFailure(json.message))
      }
    }
    catch(e) {
      dispatch(getUneOffrePretFailure(e.toString()))
    }
  }
}

// Export action creators
const { getUneOffrePretInit, getUneOffrePretSuccess, getUneOffrePretFailure } = uneOffrePretSlice.actions

// Export reducer
export default uneOffrePretSlice.reducer

// Selectors
export const uneOffrePretStatusSelector = (state) => state.uneOffrePret.status
export const uneOffrePretDatasSelector = (state) => state.uneOffrePret.datas