import React from 'react'
import helper from '../uneCalculatriceHelper'
import InputForm from '../../../toolsComponents/forms/InputForm'

/**
 * initialState -> initial data to feed this component
 * revenus -> data for fields dependancies
 * onChange -> to manage when a field is changed
 */
class Charges extends React.Component {

  constructor(props){
    super(props);
    this.state = this.props.initialState
  }

  async updateState() {
    const tmpState = {
      revenus: this.props.revenus,
      charges: this.state
    }
    
    await this.setState({
      fraisPlateformesMensuels: helper.performFraisPlateformesMensuels(tmpState), 
      fraisPlateformesAnnuels: helper.performFraisPlateformesAnnuels(tmpState),
      totalChargesMensuellesLDNue: helper.performTotalChargesMensuellesLDNue(tmpState),
      totalChargesMensuellesLDMeuble: helper.performTotalChargesMensuellesLDMeuble(tmpState),
      totalChargesMensuellesLCD: helper.performTotalChargesMensuellesLCD(tmpState),
      totalChargesAnnuellesLDNue: helper.performTotalChargesAnnuellesLDNue(tmpState),
      totalChargesAnnuellesLDMeuble: helper.performTotalChargesAnnuellesLDMeuble(tmpState),
      totalChargesAnnuellesLCD: helper.performTotalChargesAnnuellesLCD(tmpState),
    })
  }

  handleChange = async (event) => {
    await this.setState({
      [event.inputName]:  event.value
    });

    this.updateState()

    if(typeof this.props.onChange === 'function') this.props.onChange(this.state)
  }

  componentDidMount() {
    this.updateState()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.revenus !== prevProps.revenus) {
      await this.setState(this.props.initialState)
      this.updateState()
    }
  }

  render(){
    return (<>
    <div className='col-12'>
      <div className='row'>
        <div className='col-8'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>
                Charges pour tous les logements
              </h4>
            </div>

            <div className='card-body'>
              <div className='row'>
                <div className="col-6">
                  <InputForm
                    label={"Taxe foncière (annuelle)"}
                    type="number"
                    inputName="taxeFonciere"
                    value={this.state.taxeFonciere}
                    onChange={this.handleChange}
                    placeholder="ex: 1200"
                    prepend={"€/an"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Charges de copropriété"}
                    type="number"
                    inputName="chargesCopro"
                    value={this.state.chargesCopro}
                    onChange={this.handleChange}
                    placeholder="ex: 500"
                    prepend={"€/an"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label="Assurance PNO"
                    type="number"
                    inputName="assurancePNO"
                    value={this.state.assurancePNO}
                    onChange={this.handleChange}
                    placeholder="ex: 8"
                    prepend={"€/lot/mois"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label="Taux assurance loyers impayés (longue durée)"
                    type="number"
                    inputName="assuranceLoyersImpayes"
                    value={this.state.assuranceLoyersImpayes}
                    onChange={this.handleChange}
                    placeholder="ex: 2.5"
                    prepend={"%"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Prévisions travaux"}
                    type="number"
                    inputName="previsionTravaux"
                    value={this.state.previsionTravaux}
                    onChange={this.handleChange}
                    placeholder="ex: 1000"
                    prepend={"€/an"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Expert-comptable"}
                    type="number"
                    inputName="expertComptable"
                    value={this.state.expertComptable}
                    onChange={this.handleChange}
                    placeholder="ex: 1000"
                    prepend={"€/an"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label="Taux frais de gérance"
                    type="number"
                    inputName="fraisGerance"
                    value={this.state.fraisGerance}
                    onChange={this.handleChange}
                    placeholder="6.5"
                    prepend={"%"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Autres frais divers"}
                    type="number"
                    inputName="fraisDivers"
                    value={this.state.fraisDivers}
                    onChange={this.handleChange}
                    placeholder="ex: 99"
                    prepend={"€/mois"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-4'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>
                Prévision des charges en longues durées
              </h4>
            </div>

            <div className='card-body'>
              <div className="col-12">
                <InputForm
                  label="Total charges mensuellement en longue durée nue"
                  type="number"
                  inputName="totalChargesMensuellesLDNue"
                  value={this.state.totalChargesMensuellesLDNue}
                  readOnly
                  prepend={"€/mois"}
                  tooltip={"= taxe foncière / 12 + charges de copropriété / 12 + assurance PNO X nombre de logements + % assurance loyers impayés sur les revenus en location courte durée nue + prévisions travaux / 12 + expert comptable / 12 + % frais de gérance sur les revenus en location longue durée nue"}
                />
              </div>

              <div className="col-12">
                <InputForm
                  label={"Total charges annuelles en longue durée nue"}
                  type="number"
                  inputName="totalChargesAnnuellesLDNue"
                  value={this.state.totalChargesAnnuellesLDNue}
                  readOnly
                  prepend={"€/an"}
                  tooltip={"= total des charges mensuelles en location longue durée nue X 12"}
                />
              </div>

              <div className="col-12">
                <InputForm
                  label={"Total charges mensuelles en longue durée meublée"}
                  type="number"
                  inputName="totalChargesMensuellesLDMeuble"
                  value={this.state.totalChargesMensuellesLDMeuble}
                  readOnly
                  prepend={"€/mois"}
                  tooltip={"= taxe foncière / 12 + charges de copropriété / 12 + assurance PNO X nombre de logements + % assurance loyers impayés sur les revenus en location courte durée meublée + prévisions travaux / 12 + expert comptable / 12 + % frais de gérance sur les revenus en location longue durée meuublée"}
                />
              </div>

              <div className="col-12">
                <InputForm
                  label={"Total charges annuelles en longue durée meublée"}
                  type="number"
                  inputName="totalChargesAnnuellesLDMeuble"
                  value={this.state.totalChargesAnnuellesLDMeuble}
                  readOnly
                  prepend={"€/an"}
                  tooltip={"= total des charges mensuelles en location longue durée meublée X 12"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-8'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>
                Charges pour tous les logements de la location en courte durée
              </h4>
            </div>

            <div className='card-body'>
              <div className='row'>
                <div className="col-6">
                  <InputForm
                    label="% conciergerie"
                    type="number"
                    inputName="tauxConciergerie"
                    value={this.state.tauxConciergerie}
                    onChange={this.handleChange}
                    placeholder="ex: 20"
                    prepend={"%"}
                  />
                </div>
              

                <div className="col-6">
                  <InputForm
                    label={"Taux de commission moyen des plateformes"}
                    type="number"
                    inputName="tauxCommissionPlateforme"
                    value={this.state.tauxCommissionPlateforme}
                    onChange={this.handleChange}
                    placeholder="ex: 15"
                    prepend={"%"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label="Électricité mensuelle"
                    type="number"
                    inputName="fraisElectricite"
                    value={this.state.fraisElectricite}
                    onChange={this.handleChange}
                    placeholder="ex: 30"
                    prepend={"€/mois"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label="Eau mensuelle"
                    type="number"
                    inputName="fraisEau"
                    value={this.state.fraisEau}
                    onChange={this.handleChange}
                    placeholder="ex: 99"
                    prepend={"€/mois"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Frais moyen de personnel par ménage"}
                    type="number"
                      inputName="fraisPersonnelMenage"
                      value={this.state.fraisPersonnelMenage}
                      onChange={this.handleChange}
                      placeholder="ex: 29"
                      prepend={"€/ménage"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Pressing par ménage"}
                    type="number"
                    inputName="fraisPressing"
                    value={this.state.fraisPressing}
                    onChange={this.handleChange}
                    placeholder="ex: 15"
                    prepend={"€/rotation"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Internet"}
                    type="number"
                    inputName="fraisInternet"
                    value={this.state.fraisInternet}
                    onChange={this.handleChange}
                    placeholder="ex: 20"
                    prepend={"€/mois"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Netflix et autres services de streaming"}
                    type="number"
                    inputName="fraisNetflixEtAbonnement"
                    value={this.state.fraisNetflixEtAbonnement}
                    onChange={this.handleChange}
                    placeholder="ex: 49"
                    prepend={"€/mois"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Produits périssables (shampoing, café etc...)"}
                    type="number"
                    inputName="fraisProduitsPerissable"
                    value={this.state.fraisProduitsPerissable}
                    onChange={this.handleChange}
                    placeholder="ex: 15"
                    prepend={"€/mois"}
                  />
                </div>

                <div className="col-6">
                  <InputForm
                    label={"Logiciels (ex: SuperHote)"}
                    type="number"
                    inputName="fraisLogiciels"
                    value={this.state.fraisLogiciels}
                    onChange={this.handleChange}
                    placeholder="ex: 57"
                    prepend={"€/mois"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-4'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-header-title'>
                Prévision des charges en courte durée
              </h4>
            </div>

            <div className='card-body'>
              <div className="col-12">
                <InputForm
                  label={"Frais plateforme mensuels"}
                  type="number"
                  inputName="fraisPlateformesMensuels"
                  value={this.state.fraisPlateformesMensuels}
                  readOnly
                  prepend={"€/mois"}
                  tooltip={"= % du taux de commission des plateformes par rapport aux revenus mensuels totaux de la location courte durée"}
                />
              </div>
  
              <div className="col-12">
                <InputForm
                  label={"Frais plateforme annuels"}
                  type="number"
                  inputName="fraisPlateformesAnnuels"
                  value={this.state.fraisPlateformesAnnuels}
                  readOnly
                  prepend={"€/an"}
                  tooltip={"= frais plateforme mensuels X 12"}
                />
              </div>

              <div className="col-12">
                <InputForm
                  label={"Total charges mensuelles en location courte durée"}
                  type="number"
                  inputName="totalChargesMensuellesLCD"
                  value={this.state.totalChargesMensuellesLCD}
                  readOnly
                  prepend={"€/mois"}
                  tooltip={"= taxe foncière / 12 + charges de copropriété / 12 + assurance PNO X nbre de logements + prévisions travaux / 12 + expert comptable / 12 + frais divers + % commission des plateformes sur les revenus en courte durée + % frais de conciergerie sur les revenus en courte durée + frais électricité + frais eau + frais par ménage X nbre de rotations + frais pressing X nbre rotations + frais produits perissables + frais internet + frais logiciels + frais Netflix et autrs plateforme de streaming"}
                />
              </div>

              <div className="col-12">
                <InputForm
                  label={"Total charges annuelles en location courte durée"}
                  type="number"
                  inputName="totalChargesAnnuellesLCD"
                  value={this.state.totalChargesAnnuellesLCD}
                  readOnly
                  prepend={"€/an"}
                  tooltip={"= total charges mensuelles en location courte durée X 12"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>     
  </>)
  }

}


export default Charges;