import React from 'react'

const SimpleLoading = () => {

  return (
    <div style={{textAlign:"center"}}>
      <div className="spinner-border text-info" role="status">
        <span className="sr-only">Chargement ...</span>
      </div>
    </div>
  )
}

export default SimpleLoading