import React from 'react'

const SelectPays = ({ label = "Pays", pays, inputName="pays", onChange }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <div className="form-group">
      <label htmlFor="select-pays">
        {label}
      </label>
      
      <select className="form-control" id="select-pays" name={inputName} value={pays} onChange={handleChange}>
        <option value="">---</option>
        <option value="Afghanistan">Afghanistan</option>
        <option value="Afrique du Sud">Afrique du Sud</option>
        <option value="Albanie">Albanie</option>
        <option value="Algérie">Algérie</option>
        <option value="Allemagne">Allemagne</option>
        <option value="Andorre">Andorre</option>
        <option value="Angola">Angola</option>
        <option value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
        <option value="Arabie saoudite">Arabie saoudite</option>
        <option value="Argentine">Argentine</option>
        <option value="Arménie">Arménie</option>
        <option value="Australie">Australie</option>
        <option value="Autriche">Autriche</option>
        <option value="Azerbaïdjan">Azerbaïdjan</option>
        <option value="Bahamas">Bahamas</option>
        <option value="Bahreïn">Bahreïn</option>
        <option value="Bangladesh">Bangladesh</option>
        <option value="Barbade">Barbade</option>
        <option value="Biélorussie">Biélorussie</option>
        <option value="Belgique">Belgique</option>
        <option value="Belize">Belize</option>
        <option value="Bénin">Bénin</option>
        <option value="Bhoutan">Bhoutan</option>
        <option value="Bolivie">Bolivie</option>
        <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
        <option value="Botswana">Botswana</option>
        <option value="Brésil">Brésil</option>
        <option value="Brunei">Brunei</option>
        <option value="Bulgarie">Bulgarie</option>
        <option value="Burkina Faso">Burkina Faso</option>
        <option value="Burundi">Burundi</option>
        <option value="Cambodge">Cambodge</option>
        <option value="Cameroun">Cameroun</option>
        <option value="Canada">Canada</option>
        <option value="Cap-Vert">Cap-Vert</option>
        <option value="République centrafricaine">République centrafricaine</option>
        <option value="Chili">Chili</option>
        <option value="Chine">Chine</option>
        <option value="Chypre">Chypre</option>
        <option value="Colombie">Colombie</option>
        <option value="Comores">Comores</option>
        <option value="République du Congo">République du Congo</option>
        <option value="République démocratique du Congo">République démocratique du Congo</option>
        <option value="Corée du Nord">Corée du Nord</option>
        <option value="Corée du Sud">Corée du Sud</option>
        <option value="Costa Rica">Costa Rica</option>
        <option value="Côte d'Ivoire">Côte d'Ivoire</option>
        <option value="Croatie">Croatie</option>
        <option value="Cuba">Cuba</option>
        <option value="Danemark">Danemark</option>
        <option value="Djibouti">Djibouti</option>
        <option value="République dominicaine">République dominicaine</option>
        <option value="Dominique">Dominique</option>
        <option value="Égypte">Égypte</option>
        <option value="Émirats arabes unis">Émirats arabes unis</option>
        <option value="Équateur">Équateur</option>
        <option value="Érythrée">Érythrée</option>
        <option value="Espagne">Espagne</option>
        <option value="Eswatini">Eswatini</option>
        <option value="Estonie">Estonie</option>
        <option value="États-Unis">États-Unis</option>
        <option value="Éthiopie">Éthiopie</option>
        <option value="Fidji">Fidji</option>
        <option value="Finlande">Finlande</option>
        <option value="France">France</option>
        <option value="Gabon">Gabon</option>
        <option value="Gambie">Gambie</option>
        <option value="Géorgie">Géorgie</option>
        <option value="Ghana">Ghana</option>
        <option value="Grèce">Grèce</option>
        <option value="Grenade">Grenade</option>
        <option value="Guatemala">Guatemala</option>
        <option value="Guinée">Guinée</option>
        <option value="Guinée-Bissau">Guinée-Bissau</option>
        <option value="Guinée équatoriale">Guinée équatoriale</option>
        <option value="Guyana">Guyana</option>
        <option value="Haïti">Haïti</option>
        <option value="Honduras">Honduras</option>
        <option value="Hongrie">Hongrie</option>
        <option value="Inde">Inde</option>
        <option value="Indonésie">Indonésie</option>
        <option value="Irak">Irak</option>
        <option value="Iran">Iran</option>
        <option value="Irlande">Irlande</option>
        <option value="Islande">Islande</option>
        <option value="Israël">Israël</option>
        <option value="Italie">Italie</option>
        <option value="Jamaïque">Jamaïque</option>
        <option value="Japon">Japon</option>
        <option value="Jordanie">Jordanie</option>
        <option value="Kazakhstan">Kazakhstan</option>
        <option value="Kenya">Kenya</option>
        <option value="Kirghizistan">Kirghizistan</option>
        <option value="Kiribati">Kiribati</option>
        <option value="Koweït">Koweït</option>
        <option value="Laos">Laos</option>
        <option value="Lesotho">Lesotho</option>
        <option value="Lettonie">Lettonie</option>
        <option value="Liban">Liban</option>
        <option value="Liberia">Liberia</option>
        <option value="Libye">Libye</option>
        <option value="Liechtenstein">Liechtenstein</option>
        <option value="Lituanie">Lituanie</option>
        <option value="Luxembourg">Luxembourg</option>
        <option value="Macédoine du Nord">Macédoine du Nord</option>
        <option value="Madagascar">Madagascar</option>
        <option value="Malaisie">Malaisie</option>
        <option value="Malawi">Malawi</option>
        <option value="Maldives">Maldives</option>
        <option value="Mali">Mali</option>
        <option value="Malte">Malte</option>
        <option value="Maroc">Maroc</option>
        <option value="Îles Marshall">Îles Marshall</option>
        <option value="Maurice">Maurice</option>
        <option value="Mauritanie">Mauritanie</option>
        <option value="Mexique">Mexique</option>
        <option value="États fédérés de Micronésie">États fédérés de Micronésie</option>
        <option value="Moldavie">Moldavie</option>
        <option value="Monaco">Monaco</option>
        <option value="Mongolie">Mongolie</option>
        <option value="Monténégro">Monténégro</option>
        <option value="Mozambique">Mozambique</option>
        <option value="Birmanie">Birmanie</option>
        <option value="Namibie">Namibie</option>
        <option value="Nauru">Nauru</option>
        <option value="Népal">Népal</option>
        <option value="Nicaragua">Nicaragua</option>
        <option value="Niger">Niger</option>
        <option value="Nigeria">Nigeria</option>
        <option value="Norvège">Norvège</option>
        <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
        <option value="Oman">Oman</option>
        <option value="Ouganda">Ouganda</option>
        <option value="Ouzbékistan">Ouzbékistan</option>
        <option value="Pakistan">Pakistan</option>
        <option value="Palaos">Palaos</option>
        <option value="Palestine">Palestine</option>
        <option value="Panama">Panama</option>
        <option value="Papouasie-Nouvelle-Guinée">Papouasie-Nouvelle-Guinée</option>
        <option value="Paraguay">Paraguay</option>
        <option value="Pays-Bas">Pays-Bas</option>
        <option value="Pérou">Pérou</option>
        <option value="Philippines">Philippines</option>
        <option value="Pologne">Pologne</option>
        <option value="Portugal">Portugal</option>
        <option value="Qatar">Qatar</option>
        <option value="Russie">Russie</option>
        <option value="Roumanie">Roumanie</option>
        <option value="Royaume-Uni">Royaume-Uni</option>
        <option value="Rwanda">Rwanda</option>
        <option value="Sainte-Lucie">Sainte-Lucie</option>
        <option value="Saint-Christophe-et-Niévès">Saint-Christophe-et-Niévès</option>
        <option value="Saint-Marin">Saint-Marin</option>
        <option value="Saint-Vincent-et-les-Grenadines">Saint-Vincent-et-les-Grenadines</option>
        <option value="Îles Salomon">Îles Salomon</option>
        <option value="Salvador">Salvador</option>
        <option value="Samoa">Samoa</option>
        <option value="Sao Tomé-et-Principe">Sao Tomé-et-Principe</option>
        <option value="Sénégal">Sénégal</option>
        <option value="Serbie">Serbie</option>
        <option value="Seychelles">Seychelles</option>
        <option value="Sierra Leone">Sierra Leone</option>
        <option value="Singapour">Singapour</option>
        <option value="Slovaquie">Slovaquie</option>
        <option value="Slovénie">Slovénie</option>
        <option value="Somalie">Somalie</option>
        <option value="Soudan">Soudan</option>
        <option value="Soudan du Sud">Soudan du Sud</option>
        <option value="Sri Lanka">Sri Lanka</option>
        <option value="Suède">Suède</option>
        <option value="Suisse">Suisse</option>
        <option value="Suriname">Suriname</option>
        <option value="Syrie">Syrie</option>
        <option value="Tadjikistan">Tadjikistan</option>
        <option value="Tanzanie">Tanzanie</option>
        <option value="Tchad">Tchad</option>
        <option value="Tchéquie">Tchéquie</option>
        <option value="Thaïlande">Thaïlande</option>
        <option value="Timor oriental">Timor oriental</option>
        <option value="Togo">Togo</option>
        <option value="Tonga">Tonga</option>
        <option value="Trinité-et-Tobago">Trinité-et-Tobago</option>
        <option value="Tunisie">Tunisie</option>
        <option value="Turkménistan">Turkménistan</option>
        <option value="Turquie">Turquie</option>
        <option value="Tuvalu">Tuvalu</option>
        <option value="Ukraine">Ukraine</option>
        <option value="Uruguay">Uruguay</option>
        <option value="Vanuatu">Vanuatu</option>
        <option value="Venezuela">Venezuela</option>
        <option value="Viêt Nam">Viêt Nam</option>
        <option value="Yémen">Yémen</option>
        <option value="Zambie">Zambie</option>
        <option value="Zimbabwe">Zimbabwe</option>
      </select>
  </div>
  )
}

export default SelectPays