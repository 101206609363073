import React from "react";
import TableRow from "./TableRow";
import SearchBar from "./../bars/SearchBar";
import PaginationBar from "../bars/PaginationBar";
import SimpleEmptyTable from "../SimpleEmptyTable";
import SimpleLoading from "../SimpleLoading";
import defined from "../../ressources/defined";

/**
 *
 * @param headers [ { display_name, field, sortable, colSpan } ]
 * @param datas [ { value, tooltip, url_avatar, url } ]
 * @param loading true | false
 * @param pagination { nbPerPage, totalPage, currentPage }
 * @param onSort(field)
 * @param onSearch(valueSearched)
 * @param onPagination(nbPerPage, pageNumber)
 * @param withMutiSelect true | false
 * @returns A full main table
 */

const MainTable = ({
  headers,
  datas,
  loading,
  pagination,
  filter,
  isDeletable = true,
  onSort,
  onSearch,
  onPagination,
  withMutiSelect = false,
}) => {
  const handleClick = (field) => {
    onSort(field);
  };

  const handleOnSearch = (value) => {
    onSearch(value);
  };

  const handleOnNbPerPage = (value) => {
    onPagination(value, 1);
  };

  const handlePageChange = (page) => {
    onPagination(pagination.nbPerPage, page);
  };

  if (loading) {
    return <SimpleLoading />;
  } else if (filter.text.value === "" && datas && datas.length === 0) {
    return <SimpleEmptyTable />;
  } else {
    return (
      <div className="tab-content">
        <div className="tab-pane fade show active" role="tabpanel">
          {/* <!-- Body Card containing the table, the search bar and pagination nav --> */}
          <div className="card">
            {/* Searchbar and number of lines by page */}
            <SearchBar
              nbPerPage={pagination.nbPerPage}
              searchValue={filter.text.value}
              onSearch={handleOnSearch}
              onNbPerPage={handleOnNbPerPage}
            />

            {/* Table */}
            <div className="table-responsive">
              <table className="table table-sm table-hover table-nowrap card-table">
                {/* Table Header */}
                <thead>
                  <tr>
                    {withMutiSelect && (
                      <th>
                        <input type="checkbox" />
                      </th>
                    )}
                    {headers.map((h, index) => {
                      if (h.sortable) {
                        return (
                          <th colSpan={h.colSpan ? h.colSpan : 1} key={index}>
                            <a
                              href={defined.ANCHOR}
                              className="list-sort text-muted"
                              data-sort={h.field}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(h.field)}
                            >
                              {h.display_name}
                            </a>
                          </th>
                        );
                      } else {
                        return (
                          <th colSpan={h.colSpan ? h.colSpan : 1} key={index}>
                            <a
                              href={defined.ANCHOR}
                              className="list-sort text-muted"
                            >
                              {h.display_name}
                            </a>
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>

                {/* Table body */}
                <tbody className="list font-size-base">
                  {/* <!-- Map Artisan Item --> */}
                  {datas &&
                    datas?.map((r, index) => {
                      return (
                        <TableRow
                          key={index}
                          datas={r}
                          deletable={isDeletable}
                          withSelect={withMutiSelect}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <PaginationBar
              pagination={pagination}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default MainTable;
