import React from 'react'

const SimpleEmptyTable = ({ message = 'Aucune ligne à afficher' }) => {

  return (
    <div className="alert alert-info" role="alert" style={{textAlign:"center", width:"100%"}}>
      {message}
    </div>
  )
}

export default SimpleEmptyTable