import React from 'react'

const SimpleError = ({ children, withHeader = true }) => {

  return (
    <div className="alert alert-danger" role="alert">
      
      {withHeader && 
        <p>Impossible d'afficher cette page :</p>
      }

      <p style={{marginBottom: '0px'}}><strong>{children}</strong></p>
    </div>
  )
}

export default SimpleError