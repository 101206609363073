import React from 'react'
import Image from '../../toolsComponents/images/Image'

const UnPatrimoineHeader = ({ isActive, isActiveSubMenu, patrimoine }) => {

  const getClassName = (value, isDropdown = true) => {
    let result = ''

    result += isActive === value ? 'nav-link active ' : 'nav-link  '
    result += patrimoine && patrimoine._id ? '' : 'inactive-link '
    result += isDropdown ? 'dropdown-toggle ' : ''

    return result
  }
  
  return (
    <div>
      {/* <!-- HEADER --> */}
      <div className="header">
        <div className="container-fluid">
          {/* <!-- Body --> */}
          <div className="header-body">
            <div className="row align-items-center">
              <div className="col-auto">
                {/* <!-- Avatar --> */}
                <div className="avatar avatar-lg avatar-4by3">
                  <img src="/assets/img/mockups/mon-patrimoine-header-avatar-1.webp" alt="..." className="avatar-img rounded" />
                </div>
              </div>

              <div className="col ml-n3 ml-md-n2">
                {/* <!-- Pretitle --> */}
                <h6 className="header-pretitle">
                  Vue globale
                </h6>

                {/* <!-- Title --> */}
                <h1 className="header-title">
                  Mon patrimoine immobilier
                </h1>
              </div>
            </div> 
          </div>
        </div>
      </div> 
    </div>
  )
}

export default UnPatrimoineHeader