import React, { useState } from "react";
import InfoProjet from "./InfoProjet";
import InfoLogement from "./InfoLogement";
import DashboardSimulation from "./DashboardSimulation";
import ModalsSimulation from "./ModalsSimulation";

const UnProjetSimulationTravaux = ({
  id_projet,
  token,
  logements,
  onChangeNbLogements,
  onChange,
  onSave,
}) => {
  const STEPS = {
    PROJET: 1,
    LOGEMENT: 2,
    // PARTIES_COMMUNES: 3,
    // REVO_EXT: 4,
    // MENUISERIES_EXT: 5,
    DASHBOARD: 3,
  };

  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
    onSave();
  };

  const handlePrevious = () => {
    setStep(step - 1);
    onSave();
  };

  const handleGotoEdit = () => {
    setStep(STEPS.PROJET);
  };

  const handleNbLogementsChange = ({ value }) => {
    onChangeNbLogements(value);
  };

  return (
    <>
      <ModalsSimulation />

      {step === STEPS.PROJET && (
        <InfoProjet
          handleNext={handleNext}
          nbLogements={logements.length}
          onNbLogementsChange={handleNbLogementsChange}
        />
      )}

      {step === STEPS.LOGEMENT && (
        <InfoLogement
          logements={logements}
          onNext={handleNext}
          onPrevious={handlePrevious}
          onChange={onChange}
        />
      )}

      {/* {step === STEPS.PARTIES_COMMUNES && <InfoPartiesCommunes handleNext={handleNext} handlePrevious={handlePrevious}/>}

      {step === STEPS.REVO_EXT && <InfoRenovationExterieures handleNext={handleNext} handlePrevious={handlePrevious}/>}

      {step === STEPS.MENUISERIES_EXT && <InfoMenuiseriesExterieures handleNext={handleNext} handlePrevious={handlePrevious}/>} */}

      {step === STEPS.DASHBOARD && (
        <DashboardSimulation
          onGoToEdit={handleGotoEdit}
          id_projet={id_projet}
          token={token}
        />
      )}
    </>
  );
};

export default UnProjetSimulationTravaux;
