import React from 'react'
import './SearchSuggestion.css'



const SearchSuggestion = ({suggested, onSelection}) => {
    return(<div tabIndex="0" className="suggestion" onClick={onSelection}>{suggested}</div>
    )
}

export default SearchSuggestion;