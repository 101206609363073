import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import TabOffres from './TabOffres'
import UneBanqueHeader from './UneBanqueHeader'

const UneBanqueOffres = () => {

    return (
        <div>

            {/* <!-- MODAL SUPPRIMER UNE OFFRE --> */}
            <div className="modal fade" id="exampleModalDelOffre" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Supprimer une offre</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <p>Etes-vous sûr de vouloir supprimer l'offre pour : 5 appartements au centre de Mende ?</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>


            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <UneBanqueHeader isActive="offres"/>

                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-12">

                            {/* Bouton ajouter une offre */}
                            <div className="row">
                                <div className="col"></div>

                                <div className="col-auto">
                                    <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres/nouvelle-offre-pret" className="btn btn-primary d-block d-md-inline-block lift">
                                        <i className="fe fe-plus"></i> Ajouter une offre
                                    </a>
                                </div>
                            </div>

                            <br />

                            <div className="row">

                                <TabOffres />

                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UneBanqueOffres