import React from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import UnBienDocumentsWithStore from "./documents/UnBienDocumentsWithStore";
import UnBienGeneralWithStore from "./general/UnBienGeneralWithStore";
import UnBienTransactionsWithStore from "./transactions/UnBienTransactionsWithStore";

const UnBienDispatcher = () => {
  const { id, page } = useParams();

  if (!id) return <Redirect to="/patrimoine" />;

  switch (page) {
    case undefined:
      return <UnBienGeneralWithStore id={id} />;

    case "transactions":
      return <UnBienTransactionsWithStore id={id} />;

    case "documents":
      return <UnBienDocumentsWithStore id={id} />;

    default:
      return <UnBienGeneralWithStore id={id} />;
  }
};

export default UnBienDispatcher;
