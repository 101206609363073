import React from 'react'
import UnProjet from './UnProjet'
import UnProjetGenericWithStore from '../UnProjetGenericWithStore'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { unProjetDatasSelector } from '../unProjetSlice'
import { uneEtudeDatasSelector } from '../../uneEtude/uneEtudeSlice'

const UnProjetWithStore = ({ id }) => {

  const projet = useSelector(unProjetDatasSelector)
  const etude = useSelector(uneEtudeDatasSelector)

  const genericComponent = useRef(null)

  return (
    <>
      <UnProjetGenericWithStore
        ref={genericComponent}
        id={id}
        activeMenu="general"
        activeSubMenu="resume"
      >
        <UnProjet 
          projet={projet} 
          etude={etude}
          onChange={(e) => genericComponent.current.handleChange(e)} 
          onAddEtude={() => genericComponent.current.handleAddEtude()}
          onSubmit={() => genericComponent.current.handleSubmit()} 
        />
      </UnProjetGenericWithStore>
    </>
  )
}

export default UnProjetWithStore