import React from "react";
import defined from "../../ressources/defined";

/**
 *
 * @param datas [ { value, url_avatar, url, tooltip } ]
 * @param deletable add a dropdown at the end of each line with a delete option
 * @returns 1 line for a table
 */
const TableRow = ({ datas, withSelect, deletable }) => {
  return (
    <tr>
      {withSelect && (
        <td>
          <input type="checkbox" />
        </td>
      )}
      {datas.map((d, index) => {
        return (
          <td key={index}>
            <a href={d.url} title={d.tooltip}>
              {d.url_avatar && (
                <div className="avatar avatar-xs align-middle mr-2">
                  <img
                    className="avatar-img rounded-circle"
                    src={d.url_avatar}
                    alt=""
                  />
                </div>
              )}

              {d.badge && (
                <span className={"item-score badge badge-soft-" + d.badge}>
                  <span className="text-reset">
                    {d.icon && <i className={"fe fe-" + d.icon}></i>} {d.value}
                  </span>
                </span>
              )}

              {!d.badge && (
                <span className="text-reset">
                  {d.icon && <i className={"fe fe-" + d.icon}></i>} {d.value}
                </span>
              )}
            </a>
          </td>
        );
      })}

      {deletable && (
        <td>
          <div className="dropdown">
            <a
              className="dropdown-ellipses dropdown-toggle"
              href={defined.ANCHOR}
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fe fe-more-vertical"></i>
            </a>

            <div className="dropdown-menu dropdown-menu-right">
              <a href={defined.ANCHOR} className="dropdown-item">
                <i className="fe fe-trash-2"></i> Supprimer
              </a>
            </div>
          </div>
        </td>
      )}
    </tr>
  );
};

export default TableRow;
