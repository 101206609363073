import React from 'react'
import TransactionsCard from '../../../toolsComponents/cards/TransactionsCard'

const UnBienTransactions = ({ bien }) => {

  return <>
    <div className="row">
      <div className="col-12 col-lg-6 col-xl">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">Cashflow du mois en cours</h6>
                
                <span className="h2 mb-0"><span className="badge badge-soft-success"><strong>+ 955,87 €</strong></span></span>
              </div>
              
              <div className="col-auto">
                <span className="h2 fe fe-activity text-muted mb-0"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6 col-xl">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">Cashflow du dernier mois</h6>
                
                <span className="h2 mb-0"><span className="badge badge-soft-success"><strong>+ 486,23 €</strong></span></span>
              </div>
              
              <div className="col-auto">
                <span className="h2 fe fe-trending-up text-muted mb-0"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6 col-xl">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">Cashflow de l'année en cours</h6>
                
                <span className="h2 mb-0"><span className="badge badge-soft-danger"><strong>- 148,47 €</strong></span></span>
              </div>
              
              <div className="col-auto">
                <span className="h2 fe fe-bar-chart text-muted mb-0"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6 col-xl">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">Cashflow de l'année dernière</h6>
                
                <span className="h2 mb-0"><span className="badge badge-soft-success"><strong>+ 7 945,66 €</strong></span></span>
              </div>
              
              <div className="col-auto">
                <span className="h2 fe fe-check-circle text-muted mb-0"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TransactionsCard
      transactions={bien?.transactions}
      isVoirPlus={false}
    />
  </>
}

export default UnBienTransactions