import React from 'react'
import defined from '../../ressources/defined'
import MainTable from '../../toolsComponents/tables/MainTable'

const UneBanqueOffresPret = ( { banque, offres_pret, loading, pagination, filter, onSort, onSearch, onPagination, onAjouterOffre } ) => {

  const headers = [
    {
      display_name: 'Projet', 
      field: 'projet_titre', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Montant du prêt', 
      field: 'montant_pret', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: "Taux d'intérêt", 
      field: 'taux_interet', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: "Taux d'assurance", 
      field: 'taux_assurance', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Durée', 
      field: 'duree_pret', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Mensualité', 
      field: 'mensualite', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Status', 
      field: 'status', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Documents', 
      field: '', 
      sortable: false, 
      colSpan: 2
    }
  ]

  const datas = []

  offres_pret.forEach((o) => {
    let result = [
      { // Projet
        value: o.projet_titre,
        url_avatar: o.projet_url_avatar,
        url: "/mes-projets/" + o.projet_id
      },
      { // Montant du prêt
        value: o.montant_pret + ' €',
        url: '/banques/'+banque._id+'/offres-de-pret/'+o._id
      },
      { // Taux d'intérêt
        value: o.taux_interet + '%',
        url: '/banques/'+banque._id+'/offres-de-pret/'+o._id
      },
      { // Taux d'assurance
        value: o.taux_assurance + '%',
        url: '/banques/'+banque._id+'/offres-de-pret/'+o._id
      },
      { // Durée
        value: o.duree_pret + ' ans',
        url: '/banques/'+banque._id+'/offres-de-pret/'+o._id
      },
      { // Mensualité
        value: o.mensualite + ' €',
        url: '/banques/'+banque._id+'/offres-de-pret/'+o._id
      },
      { // Status
        value: o.status,
        badge: getBadge(o.status)
      },
      { // Documents
        value: 'Télécharger',
        icon: 'download',
        url: "/jesaispas"
      }
    ]

    datas.push(result)
  })

  const handleHeadersSort = (field) => {
    onSort(field)
  }

  const handleSearch = (value) => {
    onSearch(value)
  }

  const handlePagination = (nbPerPage, page) => {
    onPagination(nbPerPage, page)
  }

  const handleAjouterOffreClick = () => {
    onAjouterOffre()
  }


  return (
    <div>
      {/* <!-- MODAL SUPPRIMER UNE OFFRE --> */}
      <div className="modal fade" id="exampleModalDelOffre" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Supprimer une offre</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <p>
                Etes-vous sûr de vouloir supprimer l'offre pour : 5 appartements au centre de Mende ?
              </p>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="button" className="btn btn-danger">Supprimer</button>
            </div>
          </div>
        </div>
      </div>

      {/* Bouton ajouter une offre */}
      <div className="row">
        <div className="col"></div>

        <div className="col-auto">
            <a href={defined.ANCHOR} onClick={handleAjouterOffreClick} className="btn btn-primary d-block d-md-inline-block lift">
                <i className="fe fe-plus"></i> Ajouter une offre
            </a>
        </div>
      </div>

      <br />

      <div className="row">
        <div className="col-12">
          <MainTable
            headers={headers}
            datas={datas}
            loading={loading}
            pagination={pagination}
            onSort={handleHeadersSort}
            onPagination={handlePagination}
            onSearch={handleSearch}
            filter={filter}
          />
        </div>
      </div>
    </div>
  )
}

function getBadge(status) {
  switch(status) {

    case 'Accepté':
      return 'success'
    case 'En attente':
      return 'warning'
    case 'Refusé':
      return 'danger'
    default:
      return 'light'
  }
}

export default UneBanqueOffresPret