import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import {
  performRegisterCheckout,
  registerCheckoutDatasSelector,
  registerCheckoutStatusSelector,
} from "./registerCheckoutSlice";
import { useSelector } from "react-redux";
import SimpleLoading from "../../../../toolsComponents/SimpleLoading";
import SimpleError from "../../../../toolsComponents/SimpleError";
import defined from "../../../../ressources/defined";
import RegisterOffreItem from "./RegisterOffreItem";

const RegisterCheckout = ({ email, payment_process, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const checkoutData = useSelector(registerCheckoutDatasSelector);
  const checkoutStatus = useSelector(registerCheckoutStatusSelector);

  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [stripeError, setStripeError] = useState("");

  const [confirmPaymentStatus, setConfirmPaymentStatus] = useState("idle");

  const [currentOffreId, setCurrentOffreId] = useState(
    payment_process.offres[0]._id
  );

  useEffect(() => {
    if (
      checkoutData.payment_intent_client_secret !== "" ||
      checkoutData.setup_intent_client_secret !== ""
    ) {
      setConfirmPaymentStatus("pending");

      if (checkoutData.payment_intent_client_secret) {
        stripe
          .confirmCardPayment(checkoutData.payment_intent_client_secret, {
            payment_method: paymentMethodId,
          })
          .then(manageConfirmCard);
      } else if (checkoutData.setup_intent_client_secret) {
        stripe
          .confirmCardSetup(checkoutData.setup_intent_client_secret, {
            payment_method: paymentMethodId,
          })
          .then(manageConfirmCard);
      }
    }
  }, [
    stripe,
    checkoutData.payment_intent_client_secret,
    checkoutData.setup_intent_client_secret,
    paymentMethodId,
  ]);

  const manageConfirmCard = (result) => {
    // Handle result.error or result.paymentIntent
    setConfirmPaymentStatus("idle");

    if (!result.error) {
      onSuccess();
    } else {
      setStripeError(result.error.message);
    }
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) setStripeError(error.message);
    else {
      dispatch(performRegisterCheckout({ email, id_offre: currentOffreId }));
      setPaymentMethodId(paymentMethod.id);
    }
  };

  const handleOffreClick = (id_offre) => {
    setCurrentOffreId(id_offre);
  };

  return (
    <>
      {(checkoutStatus.loading || confirmPaymentStatus === "pending") && (
        <SimpleLoading />
      )}

      {stripeError !== "" &&
        !checkoutStatus.loading &&
        confirmPaymentStatus !== "pending" && (
          <SimpleError withHeader={false}>{stripeError}</SimpleError>
        )}

      {!checkoutStatus.loading && confirmPaymentStatus !== "pending" && (
        <>
          {payment_process.offres.map((offre) => {
            return (
              <RegisterOffreItem
                offre={offre}
                isActive={offre._id === currentOffreId}
                onClick={handleOffreClick}
              />
            );
          })}

          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />

                <hr style={{ marginBottom: "24px", marginTop: "30px" }} />

                {payment_process.jours_essai > 0 && (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h1
                        className="header-title"
                        style={{ fontSize: "3em", color: defined.COLOR_GREEN }}
                      >
                        0 € AUJOURD'HUI
                      </h1>
                      <h1
                        className="header-pretitle"
                        style={{ fontSize: "1.7em" }}
                      >
                        ESSAI GRATUIT DE {payment_process.jours_essai} JOURS
                      </h1>
                    </div>
                  </>
                )}

                <div>
                  <img
                    src="/assets/img/payment-methods/checkout-secure.jpeg"
                    alt="payment secure"
                    style={{ width: "100%" }}
                  />
                </div>

                <button
                  type="submit"
                  disabled={!stripe}
                  className="btn btn-success"
                  style={{ width: "100%" }}
                >
                  <i className="fe fe-send"></i>&nbsp;&nbsp;
                  <strong>Je fais décoller mes investissements</strong>
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RegisterCheckout;
