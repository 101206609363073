import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../toolsComponents/Navigation'
import SimpleContainedError from '../../toolsComponents/SimpleContainedError'
import { loginDatasSelector } from '../auth/login/loginSlice'
import UnArtisan from './UnArtisan'
import UnArtisanHeader from './UnArtisanHeader'
import { performGetUnArtisan, unArtisanDatasSelector, unArtisanStatusSelector } from './unArtisanSlice'

const UnArtisanWithStore = ( { id } ) => {

  const dispatch = useDispatch()
  const { granted_modules } = useSelector(loginDatasSelector)
  const [ cookies ] = useCookies(['token', 'email'])
  const { hasError, errorMessage } = useSelector(unArtisanStatusSelector)
  const artisan = useSelector(unArtisanDatasSelector)

  useEffect(() => {
    // Get artisan
    dispatch(performGetUnArtisan(cookies.token, id))
  }, [dispatch, cookies.token, id])

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      {hasError && 
        <SimpleContainedError>
          {errorMessage}
        </SimpleContainedError>
      }

      {!hasError &&
        <div className="main-content">
          <UnArtisanHeader
            artisan={artisan}
          />

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <UnArtisan artisan={artisan} />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default UnArtisanWithStore