import React from 'react'

const SearchBar = ({ searchValue, nbPerPage, withNbPerPage, onSearch, onNbPerPage }) => {

  const handleSearchChanged = (e) => {
    onSearch(e.target.value)
  }

  const hangleNbPerPageChanged = (e) => {
    onNbPerPage(e.target.value)
  }

  return (
    <div className="card-header">
      <div className="row align-items-center">
        {/* <!-- Search form --> */}
        <div className="col">
          <form>
            <div className="input-group input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fe fe-search"></i>
                </span>
              </div>

              <input 
                className="list-search form-control" 
                type="search" 
                placeholder="Chercher ..." 
                onChange={handleSearchChanged}
                value={searchValue}
              />
            </div>
          </form>
        </div>

        {/* <!-- Select the number of lines by page --> */}
        {withNbPerPage &&
          <div className="col-auto mr-n3">
            <form>
              <select 
                className="custom-select custom-select-sm form-control-flush" data-toggle="select" value={nbPerPage} onChange={hangleNbPerPageChanged}>
                  <option value="2">2 par page</option>
                  <option value="5">5 par page</option>
                  <option value="10">10 par page</option>
                  <option value="*">Tous</option>
              </select>
            </form>
          </div>
        }
      </div>
    </div>
  )
}

export default SearchBar