import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetPlanFinancement = () => {

    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">
                
                <UnProjetHeader isActive="financement" isActiveSubMenu="plan-financement" />

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                            <div className="row">

                                {/* <!-- FORMULAIRE --> */}
                                <div className="col-12">

                                    <form className="mb-4">

                                        {/* Prix de départ et prix négocié */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix de base</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="295000" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix négocié</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="260000" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Frais de notaire et frais de dossier */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Frais de notaire</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="28600" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Frais de dossier</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="750" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Frais de garantie bancaire */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Frais de garantie bancaire</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="2800" />
                                                    </div>
                                                </div>

                                            </div>



                                        </div>

                                        {/* Montant total des travaux et montant total de l'opération */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Montant total des travaux</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="86700" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Montant total de l'opération</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="378850" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Prix eu m2 et plus value potentielle */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Prix de revient au m²</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="2104.72" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Plus value potentielle</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="116600" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Apport et montant du prêt demandé */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Apport</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="83850" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Montant du prêt demandé</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="260000" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Durée prêt et taux fixe hors assurance */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Durée du prêt</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">ans</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="20" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Taux fixe hors assurance</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">%</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="1.16" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Taux assurance et mensualité prêt */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Taux assurance</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">%</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="0.34" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Mansualité du prêt</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="1288" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        <hr />

                                        <br /><br />
                                        <h2 style={{textAlign:'center'}}>Charges</h2>

                                        {/* Mensualité du prêt et taxe */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Mensualité du prêt</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="1288" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Taxes</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="120" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {/* Charges diverses et total */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Charges diverses</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="132" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Total</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="2409" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        <hr />

                                        <br /><br />
                                        <h2 style={{textAlign:'center'}}>Revenus fonciers</h2>

                                        {/* Location */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Location mensuelle</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="2800" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Charges mensuelle</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="35" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        <hr />

                                        <br /><br />
                                        <h2 style={{textAlign:'center'}}>Bénéfices mensuels</h2>

                                        {/* Totaux */}
                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Total revenus mensuels</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="2800" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Total charges mensuelle</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="2564" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        <div className="row">

                                            <div className="col-12 col-md-6">

                                                <div className="form-group">
                                                    <label>Total mensuel</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">€</div>
                                                        </div>
                                                        <input type="number" className="form-control" value="236" readOnly={true}/>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>




                                        <div className="form-group">
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                                            </div>
                                        </div>
                                        

                                    </form>

                                </div>
                            
                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div> 
    )
}

export default UnProjetPlanFinancement