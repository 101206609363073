import React from 'react'

class Charges extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      nombreLot:null,
      nombreRotations:null,
      revenusTotauxLDNue:null,
      revenusTotauxLDMeuble: null,
      revenusTotauxLCD: null,
      taxeFonciere: null,
      chargesCopro: null,
      assurancePNO: null,
      assuranceLoyersImpayes: null,
      previsionTravaux: null,
      expertComptable: null,
      fraisGerance: null,
      fraisDivers:null,
      tauxConciergerie: null,
      tauxCommissionPlateforme: null,
      fraisPlateformesMensuels: null,
      fraisPlateformesAnnuels: null,
      fraisElectricite: null,
      fraisEau: null,
      fraisPersonnelMenage: null,
      fraisPressing: null,
      fraisInternet: null,
      fraisNetflixEtAbonnement: null,
      fraisLogiciels:null,
      fraisProduitsPerissable: null,
      totalChargesMensuellesLDNue: null,
      totalChargesMensuellesLDMeuble: null,
      totalChargesMensuellesLCD: null,
      totalChargesAnnuellesLDNue: null,
      totalChargesAnnuellesLDMeuble: null,
      totalChargesAnnuellesLCD: null,

    };
  }

  handleChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const valueAsNumber = target.valueAsNumber;
    const name = target.name;

  
   await this.setState({
      [name]:  valueAsNumber || parseFloat(value)
    });

    
    this.setState({
      fraisPlateformesMensuels: Number(Number((this.state.tauxCommissionPlateforme === 0 || this.state.tauxCommissionPlateforme === null ? 0 : this.state.revenusTotauxLCD * this.state.tauxCommissionPlateforme/100)).toFixed(2)), 
      fraisPlateformesAnnuels: Number(Number((this.state.tauxCommissionPlateforme === 0 || this.state.tauxCommissionPlateforme === null ? 0 : (this.state.revenusTotauxLCD * this.state.tauxCommissionPlateforme/100))*12).toFixed(2)),
      totalChargesMensuellesLDNue:Number(this.state.taxeFonciere/12 + this.state.chargesCopro/12 + this.state.assurancePNO * this.state.nombreLot + this.state.assuranceLoyersImpayes/100 * this.state.revenusTotauxLDNue + this.state.previsionTravaux/12 + this.state.expertComptable/12 + this.state.fraisGerance/100 * this.state.revenusTotauxLDNue + this.state.fraisDivers).toFixed(2),
      totalChargesMensuellesLDMeuble:Number(this.state.taxeFonciere/12 + this.state.chargesCopro/12 + this.state.assurancePNO * this.state.nombreLot + this.state.assuranceLoyersImpayes/100 * this.state.revenusTotauxLDMeuble + this.state.previsionTravaux/12 + this.state.expertComptable/12 + this.state.fraisGerance/100 * this.state.revenusTotauxLDMeuble + this.state.fraisDivers).toFixed(2),
      totalChargesMensuellesLCD:Number(this.state.taxeFonciere/12 + this.state.chargesCopro/12 + this.state.assurancePNO * this.state.nombreLot + this.state.previsionTravaux/12 + this.state.expertComptable/12 + this.state.fraisDivers + this.state.tauxCommissionPlateforme/100 * this.state.revenusTotauxLCD + this.state.tauxConciergerie/100 * this.state.revenusTotauxLCD + this.state.fraisElectricite + this.state.fraisEau + this.state.fraisPersonnelMenage * this.state.nombreRotations + this.state.fraisPressing * this.state.nombreRotations + this.state.fraisProduitsPerissable + this.state.fraisInternet + this.state.fraisLogiciels + this.state.fraisNetflixEtAbonnement).toFixed(2),
      totalChargesAnnuellesLDNue:(Number(this.state.taxeFonciere/12 + this.state.chargesCopro/12 + this.state.assurancePNO * this.state.nombreLot + this.state.assuranceLoyersImpayes/100 * this.state.revenusTotauxLDNue + this.state.previsionTravaux/12 + this.state.expertComptable/12 + this.state.fraisGerance/100 * this.state.revenusTotauxLDNue + this.state.fraisDivers)*12).toFixed(2),
      totalChargesAnnuellesLDMeuble:(Number(this.state.taxeFonciere/12 + this.state.chargesCopro/12 + this.state.assurancePNO * this.state.nombreLot + this.state.assuranceLoyersImpayes/100 * this.state.revenusTotauxLDMeuble + this.state.previsionTravaux/12 + this.state.expertComptable/12 + this.state.fraisGerance/100 * this.state.revenusTotauxLDMeuble + this.state.fraisDivers)*12).toFixed(2),
      totalChargesAnnuellesLCD:(Number(this.state.taxeFonciere/12 + this.state.chargesCopro/12 + this.state.assurancePNO * this.state.nombreLot + this.state.previsionTravaux/12 + this.state.expertComptable/12 + this.state.fraisDivers + this.state.tauxCommissionPlateforme/100 * this.state.revenusTotauxLCD + this.state.tauxConciergerie/100 * this.state.revenusTotauxLCD + this.state.fraisElectricite + this.state.fraisEau + this.state.fraisPersonnelMenage * this.state.nombreRotations + this.state.fraisPressing * this.state.nombreRotations + this.state.fraisProduitsPerissable + this.state.fraisInternet + this.state.fraisLogiciels + this.state.fraisNetflixEtAbonnement)*12).toFixed(2),
    })
  

    if(typeof this.props.onChange === 'function') this.props.onChange(this.state)

  }

  componentDidUpdate(prevProps) {
 
    if (this.props.revenus !== prevProps.revenus) {
      this.setState({
        nombreLot: Number(this.props.revenus.nombreLogements),
        nombreRotations: Number(this.props.revenus.nombreRotations),
        revenusTotauxLDNue: Number(this.props.revenus.revenusTotalTousLogementsLDNue),
        revenusTotauxLDMeuble: Number(this.props.revenus.revenusTotalTousLogementsLDMeuble),
        revenusTotauxLCD: Number(this.props.revenus.revenusTotalTousLogementsLCD),
    })
    
    
  }
}

  render(){
    return (<>

<div className="col-12 mt-4">
                  <h2 style={{ textDecoration: "underline", fontWeight: "800"}}>
                    CHARGES:
                  </h2>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Taxe foncière (annuelle)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/an</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="taxeFonciere"
                        value={this.state.taxeFonciere}
                        onChange={this.handleChange}
                        placeholder="ex: 1200"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Charges de copropriété</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/an</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="chargesCopro"
                        value={this.state.chargesCopro}
                        onChange={this.handleChange}
                        placeholder="ex: 500"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Assurance PNO</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/lot/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="assurancePNO"
                        value={this.state.assurancePNO}
                        onChange={this.handleChange}
                        placeholder="ex: 8"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Taux assurance loyers impayés (longue durée)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="assuranceLoyersImpayes"
                        value={this.state.assuranceLoyersImpayes}
                        onChange={this.handleChange}
                        placeholder="ex: 2.5"
                      />
                    </div>
                  </div>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Prévisions Travaux</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/an</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="previsionTravaux"
                        value={this.state.previsionTravaux}
                        onChange={this.handleChange}
                        placeholder="ex: 1000"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Expert-comptable</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/an</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="expertComptable"
                        value={this.state.expertComptable}
                        onChange={this.handleChange}
                        placeholder="ex: 1000"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Taux frais de gérance</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisGerance"
                        value={this.state.fraisGerance}
                        onChange={this.handleChange}
                        placeholder="6.5"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Autres frais divers</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisProduitsPerissable"
                        value={this.state.fraisProduitsPerissable}
                        onChange={this.handleChange}
                        placeholder="ex: 99"
                      />
                    </div>
                  </div>
                </div>



                <div className="col-12">
                  <h3>
                    ——————————<br/>
                    Autre frais liés à la Location Courte Durée
                  </h3>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>% Conciergerie</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="tauxConciergerie"
                        value={this.state.tauxConciergerie}
                        onChange={this.handleChange}
                        placeholder="ex: 20"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Taux de commission moyen des plateformes</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">%</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="tauxCommissionPlateforme"
                        value={this.state.tauxCommissionPlateforme}
                        onChange={this.handleChange}
                        placeholder="ex: 15"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Frais plateforme mensuels (calculé)</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="fraisPlateformesMensuels"
                        value={this.state.fraisPlateformesMensuels === null ? null : this.state.fraisPlateformesMensuels + "€/mois"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Frais plateforme annuels (calculé)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/an</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="fraisPlateformesAnnuels"
                        value={this.state.fraisPlateformesAnnuels === null ? null : this.state.fraisPlateformesAnnuels+ "€/an"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Électricité</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisElectricite"
                        value={this.state.fraisElectricite}
                        onChange={this.handleChange}
                        placeholder="ex: 30"
                      />
                    </div>
                  </div>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Eau</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisEau"
                        value={this.state.fraisEau}
                        onChange={this.handleChange}
                        placeholder="ex: 99"
                      />
                    </div>
                  </div>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Frais Personnel de ménage moyen</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/ménage</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisPersonnelMenage"
                        value={this.state.fraisPersonnelMenage}
                        onChange={this.handleChange}
                        placeholder="ex: 29"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Pressing</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/rotation</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisPressing"
                        value={this.state.fraisPressing}
                        onChange={this.handleChange}
                        placeholder="ex: 15"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Internet</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisInternet"
                        value={this.state.fraisInternet}
                        onChange={this.handleChange}
                        placeholder="ex: 20"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Netflix et autres abonnements</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisNetflixEtAbonnement"
                        value={this.state.fraisNetflixEtAbonnement}
                        onChange={this.handleChange}
                        placeholder="ex: 49"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Produits Périssables (shampoing, café etc...)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisDivers"
                        value={this.state.fraisDivers}
                        onChange={this.handleChange}
                        placeholder="ex: 15"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Logiciels (ex: SuperHote)</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="fraisLogiciels"
                        value={this.state.fraisLogiciels}
                        onChange={this.handleChange}
                        placeholder="ex: 57"
                      />
                    </div>
                  </div>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Total Charges Mensuelles Longue Durée Nue</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="totalChargesMensuellesLDNue"
                        value={this.state.totalChargesMensuellesLDNue}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Total Charges Mensuelles Longue Durée Meublée</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="totalChargesMensuellesLDMeuble"
                        value={this.state.totalChargesMensuellesLDMeuble}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Total Charges Mensuelles Location Courte Durée</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="totalChargesMensuellesLCD"
                        value={this.state.totalChargesMensuellesLCD}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                
                <div className="col-4">
                  <div className="form-group">
                    <label>Total Charges Annuelles Longue Durée Nue</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="totalChargesAnnuellesLDNue"
                        value={this.state.totalChargesAnnuellesLDNue}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Total Charges Annuelles Longue Durée Meublée</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="totalChargesAnnuellesLDMeuble"
                        value={this.state.totalChargesAnnuellesLDMeuble}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Total Charges Annuelles Location Courte Durée</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="totalChargesAnnuellesLCD"
                        value={this.state.totalChargesAnnuellesLCD}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
    </>)
    }

}


export default Charges;