import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";
import SelectVersatile from "../../../../toolsComponents/SelectVersatile";
import { getNewArray } from "../utils";

const LotRevetementsMur = ({ revetementsMur, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("revetementsMur", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot Revêtements de Mur"
            id="lotRevetementsMur"
            inputName="lotRevetementsMur.isChecked"
            isChecked={revetementsMur?.lotRevetementsMur.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !revetementsMur?.lotRevetementsMur.isChecked
                ? "hide"
                : "col-5 fs-6 ml-3 text-left"
            }
          >
            <InputForm
              label="Nombre de revêtements de mur différents"
              inputName="revetements"
              type="Number"
              placeholder="2"
              value={revetementsMur?.revetements.length}
              onChange={({ inputName, value }) =>
                handleChange({
                  inputName,
                  value: getNewArray({
                    value,
                    max: 50,
                    array: revetementsMur.revetements,
                    initialObject: {
                      categorie: "peinture",
                      aspect: undefined,
                      localisation: undefined,
                      surface: undefined,
                    },
                  }),
                })
              }
            />
            <br />
          </div>
        </div>
        <div
          className={
            !revetementsMur?.lotRevetementsMur.isChecked ? "hide" : "col-12"
          }
        >
          <div className="col-12 text-left sub-item">
            {revetementsMur?.revetements.map((r, index) => {
              return (
                <>
                  <div className="row" key={index}>
                    <div className="col-3">
                      <SelectVersatile
                        label="Type"
                        options={[
                          { title: "Peinture", value: "peinture" },
                          { title: "Faillance", value: "faillance" },
                        ]}
                        inputName={`revetements.${index}.categorie`}
                        value={r.categorie}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-3">
                      <InputForm
                        label="Aspect"
                        inputName={`revetements.${index}.aspect`}
                        type="Text"
                        placeholder="Couleur bois clair"
                        value={r.aspect}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-3">
                      <InputForm
                        label="Localisation"
                        inputName={`revetements.${index}.localisation`}
                        type="Text"
                        placeholder="Cuisine, Séjour & Salle de bain"
                        value={r.localisation}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-3">
                      <InputForm
                        label="Surface"
                        inputName={`revetements.${index}.surface`}
                        type="Number"
                        placeholder="150"
                        prepend="m²"
                        value={r.surface}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LotRevetementsMur;
