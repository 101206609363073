import React from 'react'

/**
 * 
 * @param label Input label
 * @param inputName Name of input used in id and name
 * @param value Input value
 * @param onChange Event triggered on input value changed
 * @returns input form in JSX
 */
const SelectDistance = ({ label, inputName, value, onChange  }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <>
      <div className="form-group">
        <label htmlFor={'input'+inputName}>
          {label}
        </label>

        <select className="form-control" value={value} onChange={handleChange}>
          <option value="">---</option>
          <option value="Dans la ville">Dans la ville</option>
          <option value="Proche">Proche</option>
          <option value="Loin">Loin</option>
          <option value="Très loin">Très loin</option>
        </select>
      </div>
    </>
  )
}

export default SelectDistance