import React from 'react'
import Navigation from '../../toolsComponents/Navigation'

const NouvelArtisan = () => {

    return (
        <div>

            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        
                        <div className="col-12 col-lg-10 col-xl-8">
                            {/* <!-- HEADER --> */}
                            <div className="header mt-md-5">
                            <div className="header-body">
                                <div className="row align-items-center">
                                <div className="col">

                                    {/* <!-- Pretitle --> */}
                                    <h6 className="header-pretitle">
                                    Nouveau
                                    </h6>

                                    {/* <!-- Title --> */}
                                    <h1 className="header-title">
                                    Ajouter un nouvel artisan
                                    </h1>

                                </div>
                                </div> {/* <!-- / .row --> */}
                            </div>
                            </div>
                        


                            <div className="row">

                                {/* <!-- FORMULAIRE --> */}
                                <div className="col-12 col-xl-12">

                                    <form className="mb-4">

                                        {/* <!-- Nom de l'artisan --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Nom de l'artisan
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control" />

                                        </div>

                                        {/* <!-- Description --> */}
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1">Description</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                        </div>

                                        <hr />

                                        {/* <!-- Adresse de l'artisan --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Adresse
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control"/>

                                        </div>

                                         {/* <!-- Ville et code postal --> */}
                                         <div className="row">
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Code postal
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" />

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Ville
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Téléphone et email --> */}
                                        <div className="row">

                                            {/* <!-- Numéro de téléphone --> */}
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Numéro de téléphone
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control"  />

                                                </div>

                                                </div>
                                            
                                            {/* <!-- Adresse e-mail --> */}
                                            <div className="col-12 col-md-6">
                                            
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Adresse e-mail
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        <hr />

                                        {/* <!-- Corps de métier --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Corps de métier
                                            </label>

                                            {/* <!-- Checkboxes --> */}
                                                
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                <label className="form-check-label" htmlFor="defaultCheck1">
                                                    Architecte
                                                </label>
                                            </div>


                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                                <label className="form-check-label" htmlFor="defaultCheck2">
                                                    Carreleur
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck3" />
                                                <label className="form-check-label" htmlFor="defaultCheck3">
                                                    Couvreur
                                                </label>
                                            </div>



                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck4" />
                                                <label className="form-check-label" htmlFor="defaultCheck4">
                                                    Electricien
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck5" />
                                                <label className="form-check-label" htmlFor="defaultCheck5">
                                                    Gros oeuvre
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck5" />
                                                <label className="form-check-label" htmlFor="defaultCheck5">
                                                    Peintre
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck7" />
                                                <label className="form-check-label" htmlFor="defaultCheck7">
                                                    Plombier
                                                </label>
                                            </div>

                                        </div>


                                        <div className="form-group">
                                            <div className="float-right">
                                                {/* <button type="submit" className="btn btn-primary mb-2">Ajouter</button> */}
                                                <a href="/mockups/artisans/batir-48-SARL" className="btn btn-primary ml-2">
                                                    Ajouter
                                                </a>
                                            </div>
                                        </div>

                                        <br /><br />
                                    </form>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NouvelArtisan