import React from "react";
import { useEffect } from "react";
import { useState } from "react";

/**
 *
 * @param inputName Name of input used in id and name
 * @param value Input value
 * @param onChange Event triggered on input value changed
 * @returns input form in JSX
 */
const SelectDocumentCategory = ({
  inputName,
  categorie,
  subCategorie,
  onChange,
}) => {
  const categories = [
    {
      categorie: "Achat du bien",
      subs: [
        "Offres d'achats",
        "Compromis de vente",
        "Acte authentique",
        "Divers diagnostiques",
        "Dossier bancaire",
        "Offre de prêts",
        "Autre",
      ],
    },
    {
      categorie: "Assurances",
      subs: ["Propriétaire non occupant", "Assurance de prêt", "Autre"],
    },
    {
      categorie: "Factures",
      subs: ["Factures d'achat", "Factures de ventes", "Autre"],
    },
    {
      categorie: "Banque",
      subs: [
        "Relevés bancaires",
        "Contrat de prêt",
        "Tableau amortissement",
        "Autre",
      ],
    },
    {
      categorie: "Impots",
      subs: [
        "Taxe foncière",
        "Taxe d'habitation",
        "CFE",
        "Déclaration de TVA",
        "Déclaration de revenus",
        "URSSAF",
        "Autre",
      ],
    },
    {
      categorie: "Syndic de copropriété",
      subs: [
        "Appel des charges trimestriel",
        "Relevé de charges annuelles",
        "Convocation assemblée générale",
        "Procès verbal assemblée générale",
        "Règlement de copropriété",
        "Plan de la copropriété",
        "Autre",
      ],
    },
    {
      categorie: "Gestion courante",
      subs: ["Baux", "Contrat de prestations", "Autre"],
    },
  ];

  const [currentCat, setCurrentCat] = useState(categorie);
  const [currentSubCat, setCurrentSubCat] = useState(subCategorie);

  const [currentSubs, setCurrentSubs] = useState([]);

  useEffect(() => {
    setCurrentSubs(
      categories.filter((c) => c.categorie === currentCat)[0]?.subs
    );
  }, [currentCat]);

  useEffect(() => {
    setCurrentCat(categorie);
    setCurrentSubCat(subCategorie);
  }, [categorie, subCategorie]);

  const handleChangeCategorie = (e) => {
    if (onChange)
      onChange({
        categorie: e.target.value,
        subCategorie: currentSubCat,
        inputName,
      });
    setCurrentCat(e.target.value);
  };

  const handleChangeSub = (e) => {
    if (onChange)
      onChange({
        categorie: currentCat,
        subCategorie: e.target.value,
        inputName,
      });
    setCurrentSubCat(e.target.value);
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor={"input" + inputName}>Catégorie</label>

        <select
          className="form-control"
          value={currentCat}
          onChange={handleChangeCategorie}
        >
          <option value="">---</option>
          {categories.map((c) => {
            return <option value={c.categorie}>{c.categorie}</option>;
          })}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor={"input-sub" + inputName}>Sous-catégorie</label>

        <select
          className="form-control"
          value={currentSubCat}
          onChange={handleChangeSub}
        >
          <option value="">---</option>
          {currentSubs?.map((c) => {
            return <option value={c}>{c}</option>;
          })}
        </select>
      </div>
    </>
  );
};

export default SelectDocumentCategory;
