import React from 'react'
import MainTable from '../../toolsComponents/tables/MainTable'

const Banques = ({ banques, loading, pagination, filter, onSearch, onPagination, onSort  }) => {

  const headers = [
    {
      display_name: 'Banque', 
      field: 'nom', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Localisation', 
      field: 'ville', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Contact', 
      field: 'contact', 
      sortable: false, 
      colSpan: 1
    },
    {
      display_name: 'Téléphone', 
      field: 'telephone', 
      sortable: false, 
      colSpan: 2
    }
  ]

  const datas = []

  banques.forEach((b) => {
    let result = [
      { // Banque
          value: b.nom,
          url_avatar: b.url_avatar,
          url: "/banques/" + b._id
      },
      { // Localisation
          value: b.ville + " (" + b.code_postal + ")"
      },
      { // Contact
          value: performContact(b).nom,
          url_avatar: performContact(b).url_avatar,
          url: "/contacts/" + performContact(b)._id
      },
      { // Téléphone
          value: performContact(b).telephone,
          url: "tel:" + performContact(b).telephone
      }
    ]

    datas.push(result)
  })

  const handleHeadersSort = (field) => {
    onSort(field)
  }

  const handleSearch = (value) => {
    onSearch(value)
  }

  const handlePagination = (nbPerPage, page) => {
    onPagination(nbPerPage, page)
  }

  return (               
    <MainTable 
      headers={headers}
      datas={datas}
      loading={loading}
      pagination={pagination}
      filter={filter}
      onSort={handleHeadersSort}
      onPagination={handlePagination}
      onSearch={handleSearch}
    />               
  )
}

const performContact = (banque) => {
  // Gestion du contact
  let _id = ''
  let nom = ''
  let url_avatar = ''
  let telephone = ''

  if(banque.contacts && banque.contacts.length > 0) {
    _id = banque.contacts[0]._id
    nom = banque.contacts[0].nom
    url_avatar = banque.contacts[0].url_avatar
    telephone = banque.contacts[0].telephone
  }
  else {
    telephone = banque.telephone
  }

  return {
    _id, nom, url_avatar, telephone
  }
}

export default Banques