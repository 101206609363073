import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { useState } from 'react'
import RegisterCheckout from './checkout/RegisterCheckout'
import RegisterFinish from './finish/RegisterFinish'
import RegisterFlow from './RegisterFlow'
import defined from '../../../ressources/defined';
import RegisterInfosLoginWithStore from './infosLogin/RegisterInfosLoginWithStore'

const Register2Dispatcher = ({ register_data }) => {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)


  const [ step, setStep ] = useState(defined.REGISTER_STEPS.LOGIN)

  const handleSubmitInfosLogin = () => {
    setStep(defined.REGISTER_STEPS.CHECKOUT)
  }

  const handleSuccessCheckout = () => {
    setStep(defined.REGISTER_STEPS.FINISH)
  }

  return <>
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5 align-self-center">
          <div style={{marginTop: '24px',marginBottom:'24px', textAlign:'center'}}>
            <img src="/LogoProjectImmoVectorized.svg" alt="Logo Projet Immo" style={{width: '50%'}}/>
          </div>

          <RegisterFlow step={step}/>

          <br />

          {step === defined.REGISTER_STEPS.LOGIN &&
            <RegisterInfosLoginWithStore
              payment_process={register_data?.process}
              onNext={handleSubmitInfosLogin}
            />
          }

          {step === defined.REGISTER_STEPS.CHECKOUT &&
            <Elements stripe={stripePromise}>
              <RegisterCheckout 
                email={register_data?.infosLogin?.email}
                payment_process={register_data?.process}
                onSuccess={handleSuccessCheckout}
              />
            </Elements>
          }

          {step === defined.REGISTER_STEPS.FINISH &&
            <RegisterFinish />
          }
        </div>
      </div>
    </div>
  </>
}

export default Register2Dispatcher