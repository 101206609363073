import React from 'react'
import { XAxis, YAxis, ResponsiveContainer, LineChart, CartesianGrid, Tooltip, Legend, Line } from 'recharts'

const TripleLineChart = ({ data, XDataKey, Y3DataKeys, line3colors, YUnit='' }) => {

  const CustomTooltip = ({ active, payload, label, unit }) => {

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor:'white', padding:'20px 20px 20px 20px', border: '1px solid #555555'}}>
            <p>{label}</p>
            <p style={{color: payload[2].color}}>{`${payload[2].dataKey} : ${payload[2].value.toFixed(2)} ${unit}`}</p>
            <p style={{color: payload[1].color}}>{`${payload[1].dataKey} : ${payload[1].value.toFixed(2)}  ${unit}`}</p>
            <p style={{color: payload[0].color}}>{`${payload[0].dataKey} : ${payload[0].value.toFixed(2)}  ${unit}`}</p>
        </div>
      )
    }
  
    return null;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={XDataKey} />
          <YAxis unit={YUnit}/>
          <Tooltip content={<CustomTooltip unit={YUnit}/>} />
          <Legend />
          <Line type="monotone" dataKey={Y3DataKeys[0]} stroke={line3colors[0]} />
          <Line type="monotone" dataKey={Y3DataKeys[1]} stroke={line3colors[1]} />
          <Line type="monotone" dataKey={Y3DataKeys[2]} stroke={line3colors[2]} />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

export default TripleLineChart
