import React, { useRef } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { performAddUnBien, unBienDatasSelector, unBienStatusSelector } from '../../unBien/unBienSlice'
import UnPatrimoineGenericWithStore from '../UnPatrimoineGenericWithStore'
import { unPatrimoineDatasSelector } from '../unPatrimoineSlice'
import UnPatrimoineGeneral from './UnPatrimoineGeneral'

const UnPatrimoineGeneralWithStore = () => {

  // const patrimoine = {
  //   biens: [
  //     {
  //       _id: 1,
  //       titre: 'Nom propre',
  //       id_bien_parent: null,
  //       url_avatar: '/assets/img/mockups/patrimoine-nom-propre.png',
  //       description: "Détenu à 100%",
  //       type: 'NOM_PROPRE',
  //       adresse: '28 Boulevard des Capucines',
  //       code_postal: '75009',
  //       ville: 'Paris',
  //       pays: 'France'
  //     },
  //     {
  //       _id: 2,
  //       titre: 'Résidence principale',
  //       id_bien_parent: 1,
  //       url_avatar: '/assets/img/mockups/patrimoine-rp.webp',
  //       description: 'Appartement Paris 16',
  //       type: 'LOGEMENT',
  //       adresse: '28 Boulevard des Capucines',
  //       code_postal: '75009',
  //       ville: 'Paris',
  //       pays: 'France',
  //     },
  //     {
  //       _id: 3,
  //       titre: 'SCI Familiale',
  //       id_bien_parent: null,
  //       url_avatar: '/assets/img/mockups/patrimoine-societe.jpeg',
  //       description: 'Détenu à 60 %',
  //       type: 'SOCIETE',
  //       adresse: '230 avenue de Rivoli',
  //       code_postal: '75001',
  //       ville: 'Paris',
  //       pays: 'France',
  //       type_societe: 'SCI',
  //       part: 60,
  //       SIRET: '123 568 941 00056',
  //     },
  //     {
  //       _id: 4,
  //       titre: 'Appartement Marie Antoinette à Versaille',
  //       id_bien_parent: 3,
  //       url_avatar: '/assets/img/mockups/patrimoine-marie.webp',
  //       description: 'Location Courte Durée',
  //       type: 'LOGEMENT',
  //       adresse: 'Place d\'Armes',
  //       code_postal: '78000',
  //       ville: 'Versailles',
  //       pays: 'France',
  //     },
  //     {
  //       _id: 5,
  //       titre: 'Appartement Au Bois à Bois d\'Arcy',
  //       id_bien_parent: 3,
  //       url_avatar: '/assets/img/mockups/patrimoine-bois.webp',
  //       description: 'Location Longue Durée Nue',
  //       type: 'LOGEMENT',
  //       adresse: '5 rue Jacques Cartier',
  //       code_postal: '78390',
  //       ville: 'Bois d\'Arcy',
  //       pays: 'France',
  //     }
  //   ],
  //   transactions: [
  //     {
  //       _id: 1,
  //       titre: 'Internet fibre',
  //       bien: {
  //         _id: 4,
  //         titre: 'Appartement Marie-Antoinette',
  //         url_avatar: '/assets/img/mockups/patrimoine-marie.webp'
  //       },
  //       date: '06/04/2022',
  //       montant: -39.99
  //     },
  //     {
  //       _id: 2,
  //       titre: 'AirBnB',
  //       bien: {
  //         _id: 4,
  //         titre: 'Appartement Marie-Antoinette',
  //         url_avatar: '/assets/img/mockups/patrimoine-marie.webp'
  //       },
  //       date: '05/04/2022',
  //       montant: 356.86
  //     },
  //     {
  //       _id: 3,
  //       titre: 'Salaire',
  //       bien: {
  //         _id: 1,
  //         titre: 'Nom propre',
  //         url_avatar: '/assets/img/mockups/patrimoine-nom-propre.png'
  //       },
  //       date: '03/04/2022',
  //       montant: 3500
  //     },
  //     {
  //       _id: 4,
  //       titre: 'Comptable',
  //       bien: {
  //         _id: 3,
  //         titre: 'SCI Familiale',
  //         url_avatar: '/assets/img/mockups/patrimoine-societe.jpeg'
  //       },
  //       date: '02/04/2022',
  //       montant: -250
  //     },
  //     {
  //       _id: 5,
  //       titre: 'Loyer',
  //       bien: {
  //         _id: 5,
  //         titre: 'Appartement Au Bois',
  //         url_avatar: '/assets/img/mockups/patrimoine-bois.webp'
  //       },
  //       date: '01/04/2022',
  //       montant: 900
  //     }
  //   ],
  //   documents: [
  //     {
  //       _id: 1,
  //       titre: 'Facture Internet Fibre',
  //       bien: {
  //         _id: 4,
  //         titre: 'Appartement Marie-Antoinette',
  //         url_avatar: '/assets/img/mockups/patrimoine-marie.webp'
  //       },
  //       date: '06/04/2022',
  //       lien: '#!'
  //     },
  //     {
  //       _id: 2,
  //       titre: 'Fiche de salaire',
  //       bien: {
  //         _id: 1,
  //         titre: 'Nom propre',
  //         url_avatar: '/assets/img/mockups/patrimoine-nom-propre.png'
  //       },
  //       date: '03/04/2022',
  //       lien: '#!'
  //     },
  //     {
  //       _id: 3,
  //       titre: 'Facture comptable',
  //       bien: {
  //         _id: 3,
  //         titre: 'SCI Familiale',
  //         url_avatar: '/assets/img/mockups/patrimoine-societe.jpeg'
  //       },
  //       date: '02/04/2022',
  //       lien: '#!'
  //     },
  //     {
  //       _id: 4,
  //       titre: 'Quitance de loyer',
  //       bien: {
  //         _id: 5,
  //         titre: 'Appartement au bois',
  //         url_avatar: '/assets/img/mockups/patrimoine-bois.webp'
  //       },
  //       date: '01/04/2022',
  //       lien: '#!'
  //     },
  //     {
  //       _id: 5,
  //       titre: 'Facture nouvelle télé',
  //       bien: {
  //         _id: 4,
  //         titre: 'Appartement Marie-Antoinette',
  //         url_avatar: '/assets/img/mockups/patrimoine-marie.webp'
  //       },
  //       date: '30/03/2022',
  //       lien: '#!'
  //     }
  //   ]
  // } 

  const dispatch = useDispatch()
  const [ cookies ] = useCookies(['token', 'email'])

  const patrimoine = useSelector(unPatrimoineDatasSelector)

  const genericComponent = useRef(null)

  const handleAddBien = ( bien ) => {
    dispatch(performAddUnBien(cookies['token'], bien))
  }

  const { hasError, errorMessage, loading, lastAction } = useSelector(unBienStatusSelector)
  const newBien = useSelector(unBienDatasSelector)

  return (
    <>
      { !hasError && !loading && lastAction === 'ADD' && 
        <Redirect to={`/bien/${newBien?._id}`} push={true} />
      }

      <UnPatrimoineGenericWithStore
        ref={genericComponent}
        activeMenu="general"
        activeSubMenu="resume"
      >
        <UnPatrimoineGeneral 
          patrimoine={patrimoine}
          onChange={(e) => genericComponent.current.handleChange(e)} 
          onAddEtude={() => genericComponent.current.handleAddEtude()}
          onSubmit={() => genericComponent.current.handleSubmit()} 
          onAddBien={handleAddBien}
        />
      </UnPatrimoineGenericWithStore>
    </>
  )
}

export default UnPatrimoineGeneralWithStore