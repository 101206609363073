import React from "react";
import InputForm from "../../../../toolsComponents/forms/InputForm";
import Checkbox from "../../../../toolsComponents/Checkbox";

const LotCloisonsPlafonds = ({ cloisonsPlafonds, onChange }) => {
  const handleChange = ({ inputName, value }) => {
    onChange("cloisonsPlafonds", inputName, value);
  };

  return (
    <>
      <div class="row">
        <div class="lot col-12">
          <Checkbox
            label="Lot Cloisons & Plafonds"
            id="lotCloisonsPlafonds"
            inputName="lotCloisonsPlafonds.isChecked"
            isChecked={cloisonsPlafonds?.lotCloisonsPlafonds.isChecked}
            chevron={true}
            onChange={handleChange}
          />

          <div
            className={
              !cloisonsPlafonds?.lotCloisonsPlafonds.isChecked
                ? "hide"
                : "sous-lot col-12"
            }
          >
            <Checkbox
              label="Cloisons Simples"
              id="cloisonsSimples"
              inputName="cloisonsSimples.isChecked"
              isChecked={cloisonsPlafonds?.cloisonsSimples.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !cloisonsPlafonds?.cloisonsSimples.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 mb-4 text-left"
              }
            >
              <div className="col-4">
                <InputForm
                  label="Surface Cloisons Simples"
                  inputName="cloisonsSimples.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={cloisonsPlafonds?.cloisonsSimples.surface}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Épaisseur Cloisons Simples"
                  inputName="cloisonsSimples.epaisseur"
                  type="Number"
                  placeholder="13"
                  prepend="cm"
                  value={cloisonsPlafonds?.cloisonsSimples.epaisseur}
                  onChange={handleChange}
                />
              </div>
            </div>

            <Checkbox
              label="Cloisons Doubles (Séparation de logements)"
              id="cloisonsDoubles"
              inputName="cloisonsDoubles.isChecked"
              isChecked={cloisonsPlafonds?.cloisonsDoubles.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !cloisonsPlafonds?.cloisonsDoubles.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 mb-4 text-left"
              }
            >
              <div className="col-4">
                <InputForm
                  label="Surface Cloisons Doubles"
                  inputName="cloisonsDoubles.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={cloisonsPlafonds?.cloisonsDoubles.surface}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Épaisseur Cloisons Doubles"
                  inputName="cloisonsDoubles.epaisseur"
                  type="Number"
                  placeholder="25"
                  prepend="cm"
                  value={cloisonsPlafonds?.cloisonsDoubles.epaisseur}
                  onChange={handleChange}
                />
              </div>
            </div>

            <Checkbox
              label="Plafonds"
              id="plafonds"
              inputName="plafonds.isChecked"
              isChecked={cloisonsPlafonds?.plafonds.isChecked}
              onChange={handleChange}
            />
            <div
              className={
                !cloisonsPlafonds?.plafonds.isChecked
                  ? "hide"
                  : "row ml-4 mt-2 mb-4 text-left"
              }
            >
              <div className="col-4">
                <InputForm
                  label="Surface Plafonds"
                  inputName="plafonds.surface"
                  type="Number"
                  placeholder="80"
                  prepend="m²"
                  value={cloisonsPlafonds?.plafonds.surface}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <InputForm
                  label="Épaisseur Plafonds"
                  inputName="plafonds.epaisseur"
                  type="Number"
                  placeholder="13"
                  prepend="cm"
                  value={cloisonsPlafonds?.plafonds.epaisseur}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotCloisonsPlafonds;
