import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import Navigation from '../../toolsComponents/Navigation'
import UnArtisanHeader from './UnArtisanHeader'
import UnArtisanDevis from './UnArtisanDevis'
import { performGetUnArtisanDevis, unArtisanDevisFilterSelector, unArtisanDevisPaginatedDatasSelector, unArtisanDevisPaginationSelector, unArtisanDevisStatusSelector } from './unArtisanDevisSlice'
import { performGetUnArtisan, unArtisanDatasSelector } from './unArtisanSlice'
import { loginDatasSelector } from '../auth/login/loginSlice'
import { isTokenValid } from '../auth/login/loginTools'
import SimpleContainedError from '../../toolsComponents/SimpleContainedError'

const UnArtisanDevisWithStore = ( { id } ) => {

  const [ cookies ] = useCookies(['token', 'email'])
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const { loading, hasError, errorMessage } = useSelector(unArtisanDevisStatusSelector)
  const devis = useSelector(unArtisanDevisPaginatedDatasSelector)
  const pagination = useSelector(unArtisanDevisPaginationSelector)
  const filter = useSelector(unArtisanDevisFilterSelector)

  const artisan = useSelector(unArtisanDatasSelector)

  useEffect(() => {
    // Get offres pret
    dispatch(performGetUnArtisanDevis(cookies.token, id))

    // Get artisan
    dispatch(performGetUnArtisan(cookies.token, id))
  }, [dispatch, cookies.token, id])

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      {hasError && 
        <SimpleContainedError>
          {errorMessage}
        </SimpleContainedError>
      }

      {!hasError &&
        <div className="main-content">
          <UnArtisanHeader 
            isActive="devis"
            artisan={artisan}
          />
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <UnArtisanDevis 
                  artisan={artisan}
                  devis={devis} 
                  pagination={pagination}
                  filter={filter}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default UnArtisanDevisWithStore