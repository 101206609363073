import React from 'react'
import SimpleBarChart from '../../../toolsComponents/charts/SimpleBarChart';

const UneEtudeLogementsTouristiques = ({ etude }) => {

  const zoom = 13
  const lat = etude.commune?.coords?.split(',')[0]
  const lon = etude.commune?.coords?.split(',')[1]

  const barColors = [ "#00d97e", "#335eea", "#f1c40f", "#2ecc71",  "#9b59b6"]
  const dataVille = [
    {
      name: 'Camping',
      value: etude.logements_touristiques?.ville?.campings
    },
    {
      name: 'Hôtels',
      value: etude.logements_touristiques?.ville?.hotels
    }
  ]

  const dataRayon = [
    {
      name: 'Camping',
      value: etude.logements_touristiques?.rayon?.campings
    },
    {
      name: 'Hôtels',
      value: etude.logements_touristiques?.rayon?.hotels
    }
  ]

  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <h3>Nombre de logements touristiques dans la ville</h3>

          <SimpleBarChart 
            data={dataVille}
            XDataKey="name"
            YDataKey="value"
            barColors={barColors}
          />

          <h3>Nombre de logements touristiques dans un rayon de 20km</h3>

          <SimpleBarChart 
            data={dataRayon}
            XDataKey="name"
            YDataKey="value"
            barColors={barColors}
          />
        </div>
      
        <div className="col-lg-8">
        <iframe title="Carte des logements touristiques" width="100%" height="500px" frameborder="0" allowfullscreen src={`//umap.openstreetmap.fr/fr/map/liste-des-hebergements-touristiques_670932?scaleControl=true&miniMap=true&scrollWheelZoom=true&zoomControl=true&allowEdit=false&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=undefined&captionBar=false#${zoom}/${lat}/${lon}`}></iframe><p></p>
        </div>
      </div>
            
    </>
  )
}

export default UneEtudeLogementsTouristiques