import React from 'react'

const SimpleSuccess = ({ children, link }) => {

  return (
    <div className="alert alert-success" role="alert" style={{maxWidth: '1024px', margin: 'auto'}}>
      <p style={{marginBottom: '0px', textAlign:'center'}}><strong>{children}</strong></p>

      {link && 
        <> 
          <br />
          <p style={{textAlign:'center'}}>
            <a href={link.location}>{link.label}</a>
          </p>
        </>
      }
    </div>
  )
}

export default SimpleSuccess