import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import SearchBar from '../../toolsComponents/bars/SearchBar'
import FormationItem from './FormationItem'

const Formations = ({ formations, searchValue, onSearch }) => {

  const [ modules, setModules ] = useState([])
  
  const handleOnSearch = (value) => {
    onSearch(value)
  }

  const handleOnCategory = (category) => {
    onSearch(category)
  }

  const getModules = (videos, field) => {
    const modules = []
    videos.forEach(f => {
      let exists = false
      for(var i in modules) {
        if(modules[i].title === f[field]) {
          exists = true
          modules[i].videos.push(f)
        }
      }

      if(!exists) {
        modules.push({
          title: f[field],
          videos: [f]
        })
      }
    })

    return modules
  }

  const displayOneLevel = (m, level, index) => {
    return (
      <div className="row" key={index}>
        <div className="col-12">
          {level === 'main' &&
            <div className="header mt-md-5">
              <div className="header-body" style={{paddingTop:"6px"}}>
                <h1 className="header-title">
                  {m.title}
                </h1>
              </div>
            </div>
          }

          {level === 'sub' &&
            <h3 style={{paddingBottom: "24px", marginBottom:"24px", borderBottom:"1px solid #e3ebf6", textAlign:'left'}}>
              {m.title}
            </h3>
          }
        </div>

        {m.videos.map((v, index )=> {
          return (
            <FormationItem
              key={index}
              formation={v}
              onCategory={handleOnCategory}
            />
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    const result = []
    const tmpModules = getModules(formations, 'main_module')

    tmpModules.forEach(m => {
      const tmpSubModules = getModules(m.videos, 'sub_module')

      tmpSubModules.forEach(sm => {
        if(!sm.title) {
            result.push(m)
        }
        else {
          result.push({
            title: m.title, 
            modules: [sm]
          })
        }
      })
    })

    setModules(result)
      
  }, [formations])

  return (
    <div>
      {/* <!-- Main Card --> */}
      <div className="card">
        {/* Searchbar and number of lines by page */}
        <SearchBar 
          withNbPerPage={false}
          onSearch={handleOnSearch}
          searchValue={searchValue}
        />

        <div className="card-body text-center" style={{paddingTop:"0px"}}>
          {modules.map((m, index) => {
              if(m.videos) {
                return displayOneLevel(m, 'main', index)
              }
              else {
                return (
                  <div key={index}>
                    <div className="row">
                      <div className="col-12">
                        <div className="header mt-md-5">
                          <div className="header-body" style={{border:"0px", paddingBottom:"0px", paddingTop:"6px"}}>
                            <h1 className="header-title">
                              {m.title}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    {m.modules.map((sm, index) => {
                      return displayOneLevel(sm, 'sub', index)
                    })}
                  </div>
                )
              }
          })}
        </div>
      </div>
    </div>
  )
}

export default Formations