import React from "react";
import EtudeItemPrix from "./EtudeItemPrix";

const EtudeItem = ({ isLarge, etude }) => {
  const actual_pop =
    etude?.populations?.filter((p) => p.annee === 2022).length > 0
      ? etude?.populations?.filter((p) => p.annee === 2022)[0].habitants
      : "";

  const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const displayArray = (array) => {
    const result = [];
    for (const a of array) {
      if (a && a !== null && a !== "") {
        result.push(a);
      }
    }

    if (result.length === 0) return getNA();

    return result.join(", ");
  };

  const displayDistance = (value) => {
    if (value === "Dans la ville")
      return (
        <span className={"badge badge-soft-success"}>
          <strong>{value}</strong>
        </span>
      );
    else if (value === "Proche")
      return (
        <span className={"badge badge-soft-success"}>
          <strong>{value}</strong>
        </span>
      );
    else if (value === "Loin")
      return (
        <span className={"badge badge-soft-warning"}>
          <strong>{value}</strong>
        </span>
      );
    else if (value === "Très loin")
      return (
        <span className={"badge badge-soft-danger"}>
          <strong>{value}</strong>
        </span>
      );
    else return getNA();
  };

  const displayEvoPop = (value, suffix) => {
    const number = value > 0 ? "+" + value : value;

    if (value <= -1)
      return (
        <>
          <span className={"badge badge-soft-danger"}>
            <strong>{number}%</strong>&nbsp;{suffix}
          </span>
          &nbsp;
        </>
      );
    else if (value > -1 && value <= 0)
      return (
        <>
          <span className={"badge badge-soft-warning"}>
            <strong>{number}%</strong>&nbsp;{suffix}
          </span>
          &nbsp;
        </>
      );
    else
      return (
        <>
          <span className={"badge badge-soft-success"}>
            <strong>{number}%</strong>&nbsp;{suffix}
          </span>
          &nbsp;
        </>
      );
  };

  const getEvoPop = (annee) => {
    const cur_habitants = etude?.populations?.filter((p) => p.annee === 2022);
    const annee_habitants = etude?.populations?.filter(
      (p) => p.annee === annee
    );

    if (cur_habitants.length > 0 && annee_habitants.length > 0) {
      return (
        ((cur_habitants[0].habitants -
          parseFloat(annee_habitants[0].habitants)) /
          cur_habitants[0].habitants) *
        100
      ).toFixed(1);
    }
  };

  const displayWithBr = (value) => {
    if (!value) return getNA();

    const lines = value.split("\n");
    let result = [];
    lines.forEach((l) => {
      result.push(<span>{l}</span>);
      result.push(<br />);
    });

    return result;
  };

  const getNotationClass = () => {
    if (
      !etude ||
      !etude.notation ||
      etude.notation === "" ||
      parseInt(etude.notation) < 10
    )
      return "text-danger";
    else if (parseInt(etude.notation) < 15) return "text-warning";
    else return "text-success";
  };

  const getNA = () => {
    return <em style={{ color: "red" }}>N/A&nbsp;</em>;
  };

  const displayCamping = (value) => {
    const str = value > 1 ? "campings" : "camping";

    return (
      <span style={{ color: "#00d97e" }}>
        {value} {str}
      </span>
    );
  };

  const displayHotel = (value) => {
    const str = value > 1 ? "hôtels" : "hôtel";

    return (
      <span style={{ color: "#335eea" }}>
        {value} {str}
      </span>
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-3">
          <div className="row align-items-center">
            {/* <div className="col-auto">
              <a href="#!" className="avatar">
                <img src="/assets/img/mockups/colmar-avatar.png" alt="..." className="avatar-img rounded-circle" />
              </a>
            </div>
              */}
            <div className="col ml-n2">
              <h4 className="mb-4 text-center">
                <a style={{ fontSize: "1.3em" }} href={`/etudes/${etude._id}`}>
                  {etude.nom_ville}
                  &nbsp;&nbsp;&nbsp;
                  <span style={{ fontSize: "0.8em" }}>
                    ({etude.notation ? etude.notation : 0}/20){" "}
                    <span className={getNotationClass()}>●</span>
                  </span>
                </a>
              </h4>
            </div>
            {/* <div className="col-auto">

              <div className="dropdown">
                <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fe fe-more-vertical"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <span onClick={handleRemove} className="dropdown-item" style={{cursor:'pointer'}}>
                    <i className="fe fe-trash"></i> Supprimer
                  </span>
                </div>
              </div>

            </div> */}
          </div>
        </div>

        <EtudeItemPrix etude={etude} />

        <p className="mb-3" style={{ display: isLarge ? "block" : "none" }}>
          <strong>Démographie</strong> <br />
          Population actuelle :{" "}
          {
            <>
              <strong>{numberWithSpaces(actual_pop)}</strong> habitants
            </>
          }
          <br />
          Evolution : &nbsp;
          {displayEvoPop(getEvoPop(2022), "en 1 an")}
          {displayEvoPop(getEvoPop(2021), "en 2 ans")}
          {displayEvoPop(getEvoPop(2018), "en 5 ans")}
        </p>

        <p className="mb-3" style={{ display: isLarge ? "block" : "none" }}>
          <strong>Quartiers ideaux</strong>
          <br />
          <p>
            {displayArray([
              etude.quartier_ideal1,
              etude.quartier_ideal2,
              etude.quartier_ideal3,
            ])}
          </p>
          <strong>Quartiers à éviter</strong>
          <br />
          <p>
            {displayArray([
              etude.quartier_eviter1,
              etude.quartier_eviter2,
              etude.quartier_eviter3,
            ])}
          </p>
        </p>

        <p className="mb-3" style={{ display: isLarge ? "block" : "none" }}>
          <strong>Logements courtes durées</strong> <br />
          Dans la ville :{" "}
          {displayCamping(
            etude.logements_touristiques?.ville?.campings
          )} et {displayHotel(etude.logements_touristiques?.ville?.hotels)}
          <br />
          Dans un rayon de 20km :{" "}
          {displayCamping(
            etude.logements_touristiques?.rayon?.campings
          )} et {displayHotel(etude.logements_touristiques?.rayon?.hotels)}
        </p>

        <p className="mb-3" style={{ display: isLarge ? "block" : "none" }}>
          <strong>Transports</strong>
          <br />
          <div className="row">
            <div className="col-8">
              Distance d'une autoroute :{" "}
              {displayDistance(etude.distance_autoroute)}
              <br />
              Distance d'une nationale :{" "}
              {displayDistance(etude.distance_nationale)}
              <br />
              Distance d'une départementale :{" "}
              {displayDistance(etude.distance_departementale)}
            </div>

            <div className="col-4">
              Aéroport : {displayDistance(etude.aeroport, "success", "warning")}
              <br />
              Gare ferrovière :{" "}
              {displayDistance(etude.gare_ferroviere, "success", "warning")}
              <br />
              {/* Gare routière : {displayOuiNonBadge(etude.gare_routiere, 'success', 'warning')} */}
            </div>
          </div>
        </p>

        <p className="mb-3" style={{ display: isLarge ? "block" : "none" }}>
          <strong>Infrastructures</strong>
          <br />
          Hôpital : {displayDistance(etude.hopital, "success", "warning")}
          <br />
          &Eacute;cole supérieure :{" "}
          {displayDistance(etude.ecole_superieure, "success", "warning")}
        </p>

        <p className="mb-3" style={{ display: isLarge ? "block" : "none" }}>
          <strong>Nuisances</strong>
          <br />
          {/* Zone innondable : {displayOuiNonBadge(etude.zone_inondable, 'danger', 'success')}<br /> */}
          Zone de bruits :{" "}
          {displayDistance(etude.zone_bruits, "danger", "success")}
        </p>

        <p className="mb-3">
          <strong>Points positifs</strong>
          <br />
          {displayWithBr(etude.points_positifs)}
        </p>

        <p className="mb-3">
          <strong>Points négatifs</strong>
          <br />
          {displayWithBr(etude.points_negatifs)}
        </p>
      </div>
    </div>
  );
};

export default EtudeItem;
