import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetDevis = () => {

    return (
        <div>

            {/* <!-- MODAL AJOUTER UN DEVIS --> */}
            <div className="modal fade" id="exampleModalNewDevis" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ajouter un devis</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Nom du devis</label>
                                <input type="text" className="form-control" id="recipient-name" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Artisan</label>
                                <select className="form-control">
                                    <option value="0" select></option>
                                    <option value="1" >Bâtir 48 SARL</option>
                                    <option value="2">LC ARTISAN</option>
                                    <option value="3">Simon Fernand Et Fils SARL</option>
                                    <option value="4">Rodier S.A.S.</option>
                                    <option value="5">Vissac Romain</option>
                                    <option value="6">Idée-Déco</option>
                                    <option value="7">Jarrousse Anne</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Projet</label>
                                <select className="form-control">
                                    <option value="0"></option>
                                    <option value="1" selected>Chalet en pin douglas à Florac Trois Rivières</option>
                                    <option value="2">Appartement Chanac</option>
                                    <option value="3">Appartement 2 pièces à Mont Lozère et Goulet</option>
                                    <option value="4">Appartement 2 pièces de 30 m²</option>
                                    <option value="5">5 appartements au centre de Mende</option>
                                </select>
                            </div>

                            <div clas="form-group">
                                <label>Montant total</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">€</div>
                                    </div>
                                    <input type="number" className="form-control" value="0" />
                                </div>
                            </div>

                            <br />
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Fichier</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL MODIFIER UN DEVIS --> */}
            <div className="modal fade" id="exampleModalEditDevis" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifier un devis</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Nom du devis</label>
                                <input type="text" className="form-control" id="recipient-name" value="Réagencer les chambres"/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Artisan</label>
                                <select className="form-control">
                                    <option value="0"></option>
                                    <option value="1" selected>Bâtir 48 SARL</option>
                                    <option value="2">LC ARTISAN</option>
                                    <option value="3">Simon Fernand Et Fils SARL</option>
                                    <option value="4">Rodier S.A.S.</option>
                                    <option value="5">Vissac Romain</option>
                                    <option value="6">Idée-Déco</option>
                                    <option value="7">Jarrousse Anne</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Projet</label>
                                <select className="form-control">
                                    <option value="0"></option>
                                    <option value="1" selected>Chalet en pin douglas à Florac Trois Rivières</option>
                                    <option value="2">Appartement Chanac</option>
                                    <option value="3">Appartement 2 pièces à Mont Lozère et Goulet</option>
                                    <option value="4">Appartement 2 pièces de 30 m²</option>
                                    <option value="5">5 appartements au centre de Mende</option>
                                </select>
                            </div>

                            <div clas="form-group">
                                <label>Montant total</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">€</div>
                                    </div>
                                    <input type="number" className="form-control" value="25000" />
                                </div>
                            </div>

                            <br />
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Fichier</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL SUPPRIMER UN DEVIS --> */}
            <div className="modal fade" id="exampleModalDelDevis" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Supprimer un devis</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <p>Etes-vous sûr de vouloir supprimer le devis : Réagencer les chambres ?</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>


            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <UnProjetHeader isActive="travaux" isActiveSubMenu="devis"/>

                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-12">

                        {/* Statistiques */}
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl">

                                <div className="card">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                    <div className="col">

                                        <h6 className="text-uppercase text-muted mb-2">
                                        Montant total devis acceptés
                                        </h6>

                                        <span className="h2 mb-0">
                                        86 700 €
                                        </span>

                                    </div>
                                    <div className="col-auto">

                                        <span className="h2 fe fe-check-circle text-muted mb-0"></span>

                                    </div>
                                    </div>

                                </div>
                                </div>

                            </div>
                            <div className="col-12 col-lg-6 col-xl">

                                <div className="card">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                    <div className="col">

                                        <h6 className="text-uppercase text-muted mb-2">
                                        Nombre total de devis acceptés
                                        </h6>

                                        <span className="h2 mb-0">
                                        2
                                        </span>

                                    </div>
                                    
                                    </div> 

                                </div>
                                </div>

                            </div>
                            <div className="col-12 col-lg-6 col-xl">

                            <div className="card">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                    <div className="col">

                                        <h6 className="text-uppercase text-muted mb-2">
                                        Montant total devis en attente
                                        </h6>

                                        <span className="h2 mb-0">
                                        40 000 €
                                        </span>

                                    </div>
                                    <div className="col-auto">

                                        <span className="h2 fe fe-clock text-muted mb-0"></span>

                                    </div>
                                    </div>

                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-xl">

                            <div className="card">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                    <div className="col">

                                        <h6 className="text-uppercase text-muted mb-2">
                                        Nombre total de devis en attente
                                        </h6>

                                        <span className="h2 mb-0">
                                        2
                                        </span>

                                    </div>
                                    
                                    </div> 

                                </div>
                                </div>

                            </div>
                            </div>

                        {/* Bouton ajouter un devis */}
                        <div className="row">
                            <div className="col"></div>

                            <div className="col-auto">
                                <a href="#!" className="btn btn-primary d-block d-md-inline-block lift" data-toggle="modal" data-target="#exampleModalNewDevis">
                                    <i className="fe fe-plus"></i> Ajouter un devis
                                </a>
                            </div>
                        </div>

                        <br />

                        {/* Liste des devis */}
                        <div className="row">

                            <div className="col-12">
        <div className="tab-content">
        <div className="tab-pane fade show active" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">

            {/* <!-- Card --> */}
            <div className="card" data-list='{"valueNames": ["item-name", "item-industry", "item-location", "item-owner", "item-created"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}' id="companiesList">
                <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">

                    {/* <!-- Form --> */}
                    <form>
                        <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                            <i className="fe fe-search"></i>
                            </span>
                        </div>
                        <input className="list-search form-control" type="search" placeholder="Chercher ..." />
                        </div>
                    </form>

                    </div>
                    <div className="col-auto mr-n3">

                    {/* <!-- Select --> */}
                    <form>
                        <select className="custom-select custom-select-sm form-control-flush" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                        <option value="5">5 par page</option>
                        <option value="10" selected>10 par page</option>
                        <option value="*">Tous</option>
                        </select>
                    </form>

                    </div>
                    
                </div> {/* <!-- / .row --> */}
                </div>
                <div className="table-responsive">
                <table className="table table-sm table-hover table-nowrap card-table">
                    <thead>
                        <tr>
                            <th>
                            <a className="list-sort text-muted" data-sort="item-name" href="#!">Lot</a>
                            </th>
                            <th>
                            <a className="list-sort text-muted" data-sort="item-industry" href="#!">Artisan</a>
                            </th>
                            <th>
                            <a className="list-sort text-muted" data-sort="item-location" href="#!">Montant total</a>
                            </th>
                            <th>
                            <a className="list-sort text-muted" data-sort="item-owner" href="#!">Status</a>
                            </th>
                            <th colspan="2">
                            <a className="list-sort text-muted" data-sort="item-created" href="#!">Fichier</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="list font-size-base">

                        {/* <!-- DEVIS 3 --> */}
                        <tr>
                            
                            <td>
                                <span className="item-name text-reset">Lasurer tout le chalet</span>
                            </td>

                            <td>
                                <div className="avatar avatar-xs align-middle mr-2">
                                    <img className="avatar-img rounded-circle" src="/assets/img/mockups/artisan-6-avatar.jpeg" alt="..." />
                                </div> 
                                <a className="item-name text-reset" href="/mockups/artisans/batir-48-SARL">Idée-Déco</a>
                            </td>

                            <td>
                                <span className="item-location">83 000 €</span>
                            </td>

                            <td>
                                <span className="item-score badge badge-soft-success">Accepté</span>
                            </td>

                            <td  className="item-phone">
                                <a href="#!"><i className="fe fe-download"></i> Télécharger</a>
                            </td>

                            <td className="text-right">
                                {/* <!-- Dropdown --> */}
                                <div className="dropdown">
                                    <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe fe-more-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditDevis">
                                            <i className="fe fe-edit-3"></i> Modifier
                                        </a>
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelDevis">
                                            <i className="fe fe-trash-2"></i> Supprimer
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                        {/* <!-- DEVIS 4 --> */}
                        <tr>
                            
                            <td>
                                <span className="item-name text-reset">Enlever la mousse</span>
                            </td>

                            <td>
                                <div className="avatar avatar-xs align-middle mr-2">
                                    <img className="avatar-img rounded-circle" src="/assets/img/mockups/artisan-3-avatar.jpeg" alt="..." />
                                </div> 
                                <a className="item-name text-reset" href="/mockups/artisans/batir-48-SARL">Simon Fernand Et Fils SARL</a>
                            </td>

                            <td>
                                <span className="item-location">3 700 €</span>
                            </td>

                            <td>
                                <span className="item-score badge badge-soft-success">Accepté</span>
                            </td>

                            <td  className="item-phone">
                                <a href="#!"><i className="fe fe-download"></i> Télécharger</a>
                            </td>

                            <td className="text-right">
                                {/* <!-- Dropdown --> */}
                                <div className="dropdown">
                                    <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe fe-more-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditDevis">
                                            <i className="fe fe-edit-3"></i> Modifier
                                        </a>
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelDevis">
                                            <i className="fe fe-trash-2"></i> Supprimer
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                        
                        {/* <!-- DEVIS 1 --> */}
                        <tr>
                            
                            <td>
                                <span className="item-name text-reset">Réagencer les chambres</span>
                            </td>

                            <td>
                                <div className="avatar avatar-xs align-middle mr-2">
                                    <img className="avatar-img rounded-circle" src="/assets/img/mockups/artisan-1-avatar.jpeg" alt="..." />
                                </div> 
                                <a className="item-name text-reset" href="/mockups/artisans/batir-48-SARL">Bâtir 48 SARL</a>
                            </td>

                            <td>
                                <span className="item-location">25 000 €</span>
                            </td>

                            <td>
                                <span className="item-score badge badge-soft-warning">En attente</span>
                            </td>

                            <td  className="item-phone">
                                <a href="#!"><i className="fe fe-download"></i> Télécharger</a>
                            </td>

                            <td className="text-right">
                                {/* <!-- Dropdown --> */}
                                <div className="dropdown">
                                    <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe fe-more-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditDevis">
                                            <i className="fe fe-edit-3"></i> Modifier
                                        </a>
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelDevis">
                                            <i className="fe fe-trash-2"></i> Supprimer
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                        {/* <!-- DEVIS 2 --> */}
                        <tr>
                            
                            <td>
                                <span className="item-name text-reset">Adapter le plan électrique au nouveau réagencement</span>
                            </td>

                            <td>
                                <div className="avatar avatar-xs align-middle mr-2">
                                    <img className="avatar-img rounded-circle" src="/assets/img/mockups/artisan-4-avatar.jpeg" alt="..." />
                                </div> 
                                <a className="item-name text-reset" href="/mockups/artisans/batir-48-SARL">Rodier S.A.S.</a>
                            </td>

                            <td>
                                <span className="item-location">15 000 €</span>
                            </td>

                            <td>
                                <span className="item-score badge badge-soft-warning">En attente</span>
                            </td>

                            <td  className="item-phone">
                                <a href="#!"><i className="fe fe-download"></i> Télécharger</a>
                            </td>

                            <td className="text-right">
                                {/* <!-- Dropdown --> */}
                                <div className="dropdown">
                                    <a className="dropdown-ellipses dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fe fe-more-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditDevis">
                                            <i className="fe fe-edit-3"></i> Modifier
                                        </a>
                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelDevis">
                                            <i className="fe fe-trash-2"></i> Supprimer
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                        

                    </tbody>
                </table>
                </div>
                <div className="card-footer d-flex justify-content-between">

                {/* <!-- Pagination (prev) --> */}
                <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                    <li className="page-item">
                    <a className="page-link pl-0 pr-4 border-right" href="#!">
                        <i className="fe fe-arrow-left mr-1"></i> Préc.
                    </a>
                    </li>
                </ul>

                {/* <!-- Pagination --> */}
                <ul className="list-pagination pagination pagination-tabs card-pagination">
                    <li className="active"><a className="page" href="#!" data-i="1" data-page="10">1</a></li>
                </ul>

                {/* <!-- Pagination (next) --> */}
                <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                    <li className="page-item">
                    <a className="page-link pl-4 pr-0 border-left" href="#!">
                        Suiv. <i className="fe fe-arrow-right ml-1"></i>
                    </a>
                    </li>
                </ul>

                {/* <!-- Alert --> */}
                <div className="list-alert alert alert-dark alert-dismissible border fade" role="alert">

                    {/* <!-- Content --> */}
                    <div className="row align-items-center">
                    <div className="col">

                        {/* <!-- Checkbox --> */}
                        <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="cardAlertCheckbox" checked disabled/>
                        <label className="custom-control-label text-white" htmlFor="cardAlertCheckbox"><span className="list-alert-count">0</span> deal(s)</label>
                        </div>

                    </div>
                    <div className="col-auto mr-n3">

                        {/* <!-- Button --> */}
                        <button className="btn btn-sm btn-white-20">
                        Edit
                        </button>

                        {/* <!-- Button --> */}
                        <button className="btn btn-sm btn-white-20">
                        Delete
                        </button>

                    </div>
                    </div> {/* <!-- / .row --> */}

                    {/* <!-- Close --> */}
                    <button type="button" className="list-alert-close close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>

                </div>

                </div>
            </div>

        </div>
    </div>
    </div>


                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnProjetDevis