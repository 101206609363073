import React from 'react'
import tools from '../../toolsComponents/tools'

const UnProfilHeader = ({ isActive }) => {

  const objOk = {_id: 'ok'}

  return (
    <>
      {/* <!-- HEADER --> */}
      <div className="header">
        <div className="container-fluid">
          {/* <!-- Body --> */}
          <div className="header-body">
            <div className="row align-items-center">
              <div className="col-auto">
                {/* <!-- Avatar --> */}
                <div className="avatar avatar-lg avatar-4by3">
                  <img 
                    src="/assets/img/mockups/profil.png" 
                    alt="Profil" 
                    className="avatar-img rounded-circle border border-4 border-body" 
                    style={{width:'75px', height:'75px'}} 
                    />
                </div>
              </div>

              <div className="col ml-n3 ml-md-n2">
                {/* <!-- Pretitle --> */}
                <h6 className="header-pretitle">
                  Détails
                </h6>

                {/* <!-- Title --> */}
                <h1 className="header-title">
                  Mon profil
                </h1>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col">
                {/* <!-- Nav --> */}
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <a href={`/profil`} className={tools.header.getLinkClassName('general', isActive, objOk)} >
                      Résumé
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`/profil/mot-de-passe`} className={tools.header.getLinkClassName('mot-de-passe', isActive, objOk)}>
                      Mot de passe
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`/profil/facturation`} className={tools.header.getLinkClassName('facturation', isActive, objOk)}>
                      Facturation
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnProfilHeader