import React from 'react'
import SimpleError from './SimpleError'

const SimpleContainedError = ({ children }) => {

  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <br />
            <SimpleError>
              {children}
            </SimpleError>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleContainedError