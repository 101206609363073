import React from "react";
import Navigation from "../../../toolsComponents/Navigation";
import InformationsGenerales from "./SubModules/InformationsGenerales";
import LeCredit from "./SubModules/LeCredit";
import EvolutionTauxEndettement from './SubModules/EvolutionTauxEndettement';
import Revenus from "./SubModules/Revenus";
import Charges from "./SubModules/Charges";
import CashflowETRenta from "./SubModules/CashflowETRenta";

class UneEtudeRentabilite extends React.Component {
  constructor(props){
    super(props);
    this.state = {

        informationsGenerales: {
          prixMoyenM2:2000,
          lcd: true,
          ldm: true, 
          ldnue: true,
          surface: null,
          coutAchat: null,
          fraisNotaires: 0,
          fraisNotairesInputed:false,
          typedetravaux:150,
          coutTravaux:0,
          coutMeubles:null,
          coutTotalm2: 0,
          coutTotalReno: 0,
          coutTotalProjet: 0,
        },
                
        leCredit: {
          apportPersonnel: null,
          fraisGarantie: null,
          fraisDossier: null,
          dureeDiffere: null,
          dureeTotaleCredit: null,
          tauxHorsAssurance: null,
          tauxAssurance: null,
          montantAFinance: null,
          coutFraisGarantie: null,
          capitalEmprunteTotal:null,
          mensualite:null,
        },

        evolutionTauxEndettement: {
          revenusMensuels: null,
          revenusFonciersActuels: null,
          revenusFonciersFutursMeuble: null,
          mensualiteLieeALinvestissement:null,
          autrePretsMensualite:null,
          tauxEndettementActuel:null,
          tauxEndettementFutur:null,
        },

        revenus: {
          nombreLogements: null,
          logementsData: [],
          revenusTotalTousLogementsLDNue:null,
          revenusTotalTousLogementsLDMeuble:null,
          revenusTotalTousLogementsLCD:null,
        },

        charges: {
          nombreLot:null,
          revenusTotauxLDNue:null,
          revenusTotauxLDMeuble: null,
          revenusTotauxLCD: null,
          taxeFonciere: null,
          chargesCopro: null,
          assurancePNO: null,
          assuranceLoyersImpayes: null,
          previsionTravaux: null,
          expertComptable: null,
          fraisGerance: null,
          fraisDivers:null,
          tauxConciergerie: null,
          tauxCommissionPlateforme: null,
          fraisPlateformesMensuels: null,
          fraisPlateformesAnnuels: null,
          fraisElectricite: null,
          fraisEau: null,
          fraisPersonnelMenage: null,
          fraisPressing: null,
          fraisInternet: null,
          fraisNetflixEtAbonnement: null,
          fraisLogiciels:null,
          fraisProduitsPerissable: null,
          totalChargesMensuellesLDNue: null,
          totalChargesMensuellesLDMeuble: null,
          totalChargesMensuellesLCD: null,
          totalChargesAnnuellesLDNue: null,
          totalChargesAnnuellesLDMeuble: null,
          totalChargesAnnuellesLCD: null,
        }, 

        CashflowETRenta: {

        }
      }
    }

  handleChangeInformationsGenerales = (e) => {
    this.setState({informationsGenerales:e});
  }

  handleChangeCredit = (e) => {
    this.setState({leCredit:e});
  }


  handleChangeEvolutionTauxEndettement = (e) => {
    this.setState({evolutionTauxEndettement:e});
  }

  handleRevenus = (e) => {
    this.setState({revenus:e})
  }

  handleCharges = (e) => {
    this.setState({charges:e})
  }

  handleChangeCashflowETRenta = (e) => {
    this.setState({CashflowETRenta: e})
  }

  
  
  render() {
    return (
      <div>
        <Navigation granted_modules="ALL" />

        <div className="main-content">
          {/* <UneEtudeHeader isActive="rentabilite" /> */}
          <div>
            {/* <!-- HEADER --> */}
            <div className="header">
              <div className="container-fluid">
                {/* <!-- Body --> */}
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      {/* <!-- Avatar --> */}
                      <div className="avatar avatar-lg avatar-4by3">
                        <img src="/assets/img/mockups/rentabilite-avatar.png" alt="Avatar rentabilité" className="avatar-img rounded" />
                      </div>
                    </div>

                    <div className="col ml-n3 ml-md-n2">
                      {/* <!-- Title --> */}
                      <h1 className="header-title">
                        Calculatrice de rentabilité
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="row">
                  {/* <!-- CALCULATRICE */}

                  <InformationsGenerales onChange={this.handleChangeInformationsGenerales}/>
                  <LeCredit onChange={this.handleChangeCredit} infosGeneralesProjet={this.state.informationsGenerales}/>
                  <Revenus onChange={this.handleRevenus}/>
                  <Charges onChange={this.handleCharges} revenus={this.state.revenus}/>
                  <EvolutionTauxEndettement onChange={this.handleChangeEvolutionTauxEndettement} leCredit={this.state.leCredit} leRevenus={this.state.revenus}/>
                  <CashflowETRenta onChange={this.handleChangeCashflowETRenta} informationsGenerales={this.state.informationsGenerales} leCredit={this.state.leCredit} revenus={this.state.revenus} charges={this.state.charges}/>
                  
                  <div className="col-12">
                    {/* <!-- Submit button --> */}
                    <div className="form-group">
                      <div className="float-right">
                        <button type="submit" className="btn btn-primary mb-2">
                          Sauvegarder
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UneEtudeRentabilite;
