import { createSlice } from "@reduxjs/toolkit";
import objectSliceHelper from "../../toolsSlice/objectSliceHelper";

const URL = "/biens/";
const URL_DOCUMENT = "/biens-documents/";
const helper = new objectSliceHelper();

const unBienSlice = createSlice({
  name: "unBien",
  initialState: helper.getInitialState(),
  reducers: helper.reducers,
});

export const performGetUnBien = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(getInit(helper.ACTIONS.GET));

      const res = await helper.performGetAPICall(token, id, URL);
      const json = await res.json();

      if (res.status === 200 && !json.error) dispatch(getSuccess(json.datas));
      else dispatch(getFailure(json.message));
    } catch (e) {
      dispatch(getFailure(e.toString()));
    }
  };
};

export const performSyncUnBien = (token, bien) => {
  return async (dispatch) => {
    try {
      dispatch(syncInit(helper.ACTIONS.SYNC));

      const res = await helper.performSyncAPICall(token, bien, URL);
      const json = await res.json();

      if (res.status === 200 && !json.error) dispatch(syncSuccess(json.datas));
      else dispatch(syncFailure(json.message));
    } catch (e) {
      dispatch(syncFailure(e.toString()));
    }
  };
};

export const performAddUnBien = (token, bien) => {
  return async (dispatch) => {
    try {
      dispatch(addInit(helper.ACTIONS.ADD));

      const res = await helper.performAddAPICall(token, bien, URL);
      const json = await res.json();

      if (res.status === 200 && !json.error) dispatch(addSuccess(json.datas));
      else dispatch(addFailure(json.message));
    } catch (e) {
      dispatch(addFailure(e.toString()));
    }
  };
};

export const performDelUnBien = (token, bien) => {
  return async (dispatch) => {
    try {
      dispatch(delInit(helper.ACTIONS.DEL));

      const res = await helper.performDelAPICall(token, bien, URL);
      const json = await res.json();

      if (res.status === 200 && !json.error) dispatch(delSuccess());
      else dispatch(delFailure(json.message));
    } catch (e) {
      dispatch(delFailure(e.toString()));
    }
  };
};

// Export action creators
const {
  getInit,
  syncInit,
  addInit,
  delInit,
  getSuccess,
  getFailure,
  syncSuccess,
  syncFailure,
  addSuccess,
  addFailure,
  delSuccess,
  delFailure,
} = unBienSlice.actions;
export const { updateByField } = unBienSlice.actions;

// Export reducer
export default unBienSlice.reducer;

// Selectors
export const unBienStatusSelector = (state) => state.unBien.status;
export const unBienDatasSelector = (state) => state.unBien.datas;
