import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../toolsSlice/objectSliceHelper'

const URL = '/patrimoine/'
const helper = new objectSliceHelper()

const unPatrimoineSlice = createSlice({
  name: 'unPatrimoine',
  initialState: helper.getInitialState(),
  reducers: helper.reducers
})

export const performGetUnPatrimoine = (token) => {

  return async dispatch => {
    try {
      dispatch(getInit(helper.ACTIONS.GET))

      const res = await helper.performGetAPICall(token, '', URL)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(getSuccess(json.datas))
      else dispatch(getFailure(json.message))
    }
    catch(e) {
      dispatch(getFailure(e.toString()))
    }
  }
}

// export const performSyncUnPatrimoine = (token, projet) => {
//   return async dispatch => {
//     try {
//       dispatch(syncInit(helper.ACTIONS.SYNC))

//       const res = await helper.performSyncAPICall(token, projet, URL)
//       const json = await res.json()

//       if( res.status === 200 && !json.error ) dispatch(syncSuccess(json.datas))
//       else dispatch(syncFailure(json.message))
//     }
//     catch(e) {
//       dispatch(syncFailure(e.toString()))
//     }
//   }
// }

// export const performAddUnPatrimoine = (token, bien) => {
//   return async dispatch => {
//     try {
//       dispatch(addInit(helper.ACTIONS.ADD))

//       const res = await helper.performAddAPICall(token, bien, URL)
//       const json = await res.json()

//       if( res.status === 200 && !json.error ) dispatch(addSuccess(json.datas))
//       else dispatch(addFailure(json.message))
//     }
//     catch(e) {
//       dispatch(addFailure(e.toString()))
//     }
//   }
// }

// export const performDelUnPatrimoine = (token, projet) => {
//   return async dispatch => {
//     try {
//       dispatch(delInit(helper.ACTIONS.DEL))

//       const res = await helper.performDelAPICall(token, projet, URL)
//       const json = await res.json()

//       if( res.status === 200 && !json.error ) dispatch(delSuccess())
//       else dispatch(delFailure(json.message))
//     }
//     catch(e) {
//       dispatch(delFailure(e.toString()))
//     }
//   }
// }

// Export action creators
const { getInit, getSuccess, getFailure, addInit, addSuccess, addFailure } = unPatrimoineSlice.actions
export const { updateByField } = unPatrimoineSlice.actions

// Export reducer
export default unPatrimoineSlice.reducer

// Selectors
export const unPatrimoineStatusSelector = (state) => state.unPatrimoine.status
export const unPatrimoineDatasSelector = (state) => state.unPatrimoine.datas