import React from 'react'

class LogementRevenus extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      numerologementdansArray: this.props.numerologement,
      typelogement:null,
      loyerNueHcEstime:null,
      loyerMeubleHcEstime:null,
      vacanceLocative: null,
      prixNuitLCD: null,
      fraisSupplementaires: null,
      nombreNuitsMoyen: null,
      nombreRotationsMois: null,
      revenusTotauxLDNue: 0,
      revenusTotauxLDMeuble: 0,
      revenusTotauxLCD: 0,
    };
  }

  handleChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const valueAsNumber = target.valueAsNumber;

    await this.setState({
      [name]: valueAsNumber || value
    });

    this.setState({revenusTotauxLDNue: parseFloat((this.state.loyerNueHcEstime * (1-(this.state.vacanceLocative)/12)).toFixed(2))})

    this.setState({revenusTotauxLDMeuble: parseFloat((this.state.loyerMeubleHcEstime * (1-(this.state.vacanceLocative)/12)).toFixed(2))})

    this.setState({revenusTotauxLCD: parseFloat((this.state.prixNuitLCD * this.state.nombreNuitsMoyen + this.state.fraisSupplementaires * this.state.nombreRotationsMois))})

  if(typeof this.props.onChange === 'function') this.props.onChange(this.state);

  }

  componentDidMount(){
    this.props.onChange(this.state);
  }
  
render(){
  return (<>


                
                <div className="col-12">
                  <h3>
                    Logement n°{this.props.numerologement}
                  </h3>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="typedelogement1">
                      Type de logement
                    </label>

                    <select
                      className="form-control"
                      id="typelogement"
                      name="typelogement"
                        value={this.state.typelogement}
                        onChange={this.handleChange}
                    >
                      <option value="t1">T1</option>
                      <option value="t2">
                        T2
                      </option>
                      <option value="t3">T3</option>
                      <option value="t4">T4</option>
                      <option value="t5">T5</option>
                      <option value="t6">T6</option>
                      <option value="autre">Autre</option>
                    </select>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Loyer Longue Durée Nue HC Estimé</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        name="loyerNueHcEstime"
                        type="number"
                        className="form-control"
                        value={this.state.loyerNueHcEstime}
                        onChange={this.handleChange}
                        placeholder="ex: 400"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Loyer Longue Durée Meublé HC Estimé</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        name="loyerMeubleHcEstime"
                        type="number"
                        className="form-control"
                        value={this.state.loyerMeubleHcEstime}
                        onChange={this.handleChange}
                        placeholder="ex: 600"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Vacance locative pour la longue durée</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Nb de mois / an</div>
                      </div>
                      <input
                        name="vacanceLocative"
                        type="number"
                        className="form-control"
                        value={this.state.vacanceLocative}
                        onChange={this.handleChange}
                        placeholder="ex: 2"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Prix Estimé LCD par nuit</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/nuit</div>
                      </div>
                      <input
                        name="prixNuitLCD"
                        type="number"
                        className="form-control"
                        value={this.state.prixNuitLCD}
                        onChange={this.handleChange}
                        placeholder="ex: 59"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Frais supplémentaires facturés LCD</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/séjour (ex: ménage)</div>
                      </div>
                      <input
                        name="fraisSupplementaires"
                        type="number"
                        className="form-control"
                        value={this.state.fraisSupplementaires}
                        onChange={this.handleChange}
                        placeholder="ex: 29"
                      />
                    </div>
                  </div>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Nombre de nuits moyennes louées par mois</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">nuits</div>
                      </div>
                      <input
                        name="nombreNuitsMoyen"
                        type="number"
                        className="form-control"
                        value={this.state.nombreNuitsMoyen}
                        onChange={this.handleChange}
                        placeholder="ex: 21"
                      />
                    </div>
                  </div>
                </div>


                <div className="col-4">
                  <div className="form-group">
                    <label>Nombre de rotations moyennes / mois</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Rotations</div>
                      </div>
                      <input
                        name="nombreRotationsMois"
                        type="number"
                        className="form-control"
                        value={this.state.nombreRotationsMois}
                        onChange={this.handleChange}
                        placeholder="ex: 9"
                      />
                    </div>
                  </div>
                </div>


                <div className="col-12">
                  <h3>
                    REVENUS TOTAUX:
                  </h3>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Revenus Totaux Estimé Longue Durée Nue Logement n°{this.props.numerologement}</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        name="revenusTotauxLDNue"
                        type="number"
                        className="form-control"
                        value={this.state.revenusTotauxLDNue}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Revenus Totaux Estimé Longue Meublée Logement n°{this.props.numerologement}</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        name="revenusTotauxLDMeuble"
                        type="number"
                        className="form-control"
                        value={this.state.revenusTotauxLDMeuble}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Revenus Totaux Estimé Courte Durée Logement n°{this.props.numerologement}</label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€/mois</div>
                      </div>
                      <input
                        name="revenusTotauxLCD"
                        type="number"
                        className="form-control"
                        value={this.state.revenusTotauxLCD}
                        readOnly
                      />
                    </div>
                  </div>
                </div>



    </>)

}}


export default LogementRevenus;