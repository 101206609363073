import React from 'react'
import { useParams } from 'react-router'
import UnArtisanDevisWithStore from './UnArtisanDevisWithStore'
import UnArtisanWithStore from './UnArtisanWithStore'

const UnArtisanDispatcher = () => {
  const { id, page } = useParams()

  switch(page) {
    case undefined:
      return <UnArtisanWithStore id={id} />
    case 'devis':
      return <UnArtisanDevisWithStore id={id} />
    default: 
      return <UnArtisanWithStore id={id} />
  }
}

export default UnArtisanDispatcher