import React, { useState } from 'react'
import { useImperativeHandle } from 'react'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Navigation from '../../toolsComponents/Navigation'
import SimpleContainedError from '../../toolsComponents/SimpleContainedError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'
import SimpleSuccess from '../../toolsComponents/SimpleSucccess'
import SimpleWarning from '../../toolsComponents/SimpleWarning'
import { loginDatasSelector } from '../auth/login/loginSlice'
import UnProfilHeader from './UnProfilHeader'
import { performGetUnProfil, unProfilDatasSelector, unProfilStatusSelector, updateByField, performSyncUnProfil, performChangePassword } from './unProfilSlice'

const UnProfilGenericWithStore = React.forwardRef(({ 
  children, 
  activeMenu, 
  }, 
  ref) => {

  useImperativeHandle(ref, () => {
    return {
      handleChange,
      handleSubmit,
      handlePasswordChange
    }
  })

  const dispatch = useDispatch()
  const { granted_modules } = useSelector(loginDatasSelector)
  const [ cookies ] = useCookies(['token', 'email'])
  const { hasError, errorMessage, loading, lastAction } = useSelector(unProfilStatusSelector)

  const user = useSelector(unProfilDatasSelector)

  const [ isTimeout, setIsTimeout ] = useState(false)

  useEffect(() => {
    dispatch(performGetUnProfil(cookies.token))
  }, [dispatch, cookies.token])

  const handleChange = (e) => {
    dispatch(updateByField({ field: e.inputName, value: e.value }))
  }

  const handleSubmit = () => {
    dispatch(performSyncUnProfil(cookies.token, user))
  }

  const handlePasswordChange = (e) => {
    dispatch(performChangePassword(cookies.token, e.currentPassword, e.newPassword))
  }

  if(isTimeout) {
    return <Redirect to="/logout" />
  }
  else if(!loading && !hasError && lastAction === 'CHANGE_PASSWORD') {
    window.setTimeout(() => {
      setIsTimeout(true)
    }, 2000)

    return (
      <>
        <br />
        <SimpleSuccess>
          Votre mot de passe a été mis à jour. <a href="/logout">Vous allez être déconnecté d'ici 3 secondes.</a>
        </SimpleSuccess>
      </>
    )
  }
  else {
    return (
      <div>
        {granted_modules !== 'NONE' &&
          <Navigation granted_modules={granted_modules} />
        }

        <div className="main-content" style={{margin: '70px', marginTop: '0px'}}>
          <UnProfilHeader 
            isActive={activeMenu}
          />

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">

                {hasError && lastAction !== 'CHANGE_PASSWORD' &&
                  <SimpleContainedError>
                    {errorMessage}
                  </SimpleContainedError>
                }

                {loading &&
                  <SimpleLoading />
                }

                {granted_modules === 'NONE' &&
                  <SimpleWarning>
                    Vous n'avez plus d'offre en cours pour accéder à projetimmo.com. En cas d'erreur de paiement, veuillez <a href="/profil/facturation">mettre à jour votre moyen de paiement dans l'onglet facturation</a>, sinon veuillez souscrire à une nouvelle offre.
                  </SimpleWarning>
                }

                {!loading && 
                  children
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
})

export default UnProfilGenericWithStore