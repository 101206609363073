import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import MonProfilHeader from './MonProfilHeader'

const MonProfilFactures = () => {

    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content">

                {/* <!-- HEADER --> */}
                <MonProfilHeader isActive="factures" />

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                        <div className="card" data-list="{&quot;valueNames&quot;: [&quot;orders-order&quot;, &quot;orders-product&quot;, &quot;orders-date&quot;, &quot;orders-total&quot;, &quot;orders-status&quot;, &quot;orders-method&quot;]}">
                        <div className="card-header">

                            {/* <!-- Search --> */}
                            <form>
                            <div className="input-group input-group-flush">
                                <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="fe fe-search"></i>
                                </span>
                                </div>
                                <input className="form-control list-search" type="search" placeholder="Chercher ..." />
                            </div>
                            </form>


                        </div>
                        <div className="table-responsive">
                            <table className="table table-sm table-nowrap card-table">
                            <thead>
                                <tr>
                                <th>
                                    <a href="#!" className="text-muted list-sort" data-sort="orders-order">
                                    N° de facture
                                    </a>
                                </th>
                                <th>
                                    <a href="#!" className="text-muted list-sort" data-sort="orders-product">
                                    Article
                                    </a>
                                </th>
                                <th>
                                    <a href="#!" className="text-muted list-sort" data-sort="orders-date">
                                    Date
                                    </a>
                                </th>
                                <th>
                                    <a href="#!" className="text-muted list-sort" data-sort="orders-total">
                                    Total
                                    </a>
                                </th>
                                <th>
                                    <a href="#!" className="text-muted list-sort" data-sort="orders-status">
                                    Etat
                                    </a>
                                </th>
                                <th colspan="2">
                                    <a href="#!" className="text-muted list-sort" data-sort="orders-method">
                                    Moyen de paiement
                                    </a>
                                </th>
                                </tr>
                            </thead>
                            <tbody className="list">
                                <tr>
                                
                                <td className="orders-order">
                                    <a href="/mockups/mon-profil/factures/6520">#6520</a>
                                </td>
                                <td className="orders-product">
                                    Abonnement mensuel
                                </td>
                                <td className="orders-date">

                                    {/* <!-- Time --> */}
                                    <time datetime="2018-07-30">22/04/2021</time>

                                </td>
                                <td className="orders-total">
                                    16,99 €
                                </td>
                                <td className="orders-status">

                                    {/* <!-- Badge --> */}
                                    <div className="badge badge-soft-warning">
                                    En attente
                                    </div>

                                </td>
                                <td className="orders-method">
                                    Mastercard
                                </td>
                                
                                </tr>
                                
                                <tr>
                                
                                <td className="orders-order">
                                    <a href="/mockups/mon-profil/factures/6520">#6521</a>
                                </td>
                                <td className="orders-product">
                                    Abonnement mensuel
                                </td>
                                <td className="orders-date">

                                    {/* <!-- Time --> */}
                                    <time datetime="2018-07-30">22/03/2021</time>

                                </td>
                                <td className="orders-total">
                                    16,99 €
                                </td>
                                <td className="orders-status">

                                    {/* <!-- Badge --> */}
                                    <div className="badge badge-soft-success">
                                    Accepté
                                    </div>

                                </td>
                                <td className="orders-method">
                                    Mastercard
                                </td>
                                
                                </tr>
                                
                                <tr>
                                
                                <td className="orders-order">
                                    <a href="/mockups/mon-profil/factures/6520">#6522</a>
                                </td>
                                <td className="orders-product">
                                    Abonnement mensuel
                                </td>
                                <td className="orders-date">

                                    {/* <!-- Time --> */}
                                    <time datetime="2018-07-28">22/02/2021</time>

                                </td>
                                <td className="orders-total">
                                    16,99 €
                                </td>
                                <td className="orders-sratus">

                                    {/* <!-- Badge --> */}
                                    <div className="badge badge-soft-success">
                                    Accepté
                                    </div>

                                </td>
                                <td className="orders-method">
                                    Mastercard
                                </td>
                                
                                </tr>
                                
                                <tr>
                                
                                <td className="orders-order">
                                    <a href="/mockups/mon-profil/factures/6520">#6523</a>
                                </td>
                                <td className="orders-product">
                                    Abonnement mensuel
                                </td>
                                <td className="orders-date">

                                    {/* <!-- Time --> */}
                                    <time datetime="2018-07-27">20/02/2021</time>

                                </td>
                                <td className="orders-total">
                                    16,99 €
                                </td>
                                <td className="orders-status">

                                    {/* <!-- Badge --> */}
                                    <div className="badge badge-soft-danger">
                                    Refusé
                                    </div>

                                </td>
                                <td className="orders-method">
                                    Mastercard
                                </td>
                                
                                </tr>
                                
                                <tr>
                                
                                <td className="orders-order">
                                    <a href="/mockups/mon-profil/factures/6520">#6524</a>
                                </td>
                                <td className="orders-product">
                                    Abonnement mensuel
                                </td>
                                <td className="orders-date">

                                    {/* <!-- Time --> */}
                                    <time datetime="2018-07-25">22/01/2021</time>

                                </td>
                                <td className="orders-total">
                                    16,99 €
                                </td>
                                <td className="orders-status">

                                    {/* <!-- Badge --> */}
                                    <div className="badge badge-soft-success">
                                    Accepté
                                    </div>

                                </td>
                                <td className="orders-method">
                                    Mastercard
                                </td>
                                
                                </tr>
                                
                                <tr>
                                
                                <td className="orders-order">
                                    <a href="/mockups/mon-profil/factures/6520">#6525</a>
                                </td>
                                <td className="orders-product">
                                    Abonnement mensuel
                                </td>
                                <td className="orders-date">

                                    {/* <!-- Time --> */}
                                    <time datetime="2018-07-22">20/12/2020</time>

                                </td>
                                <td className="orders-total">
                                    16,99 €
                                </td>
                                <td className="orders-status">

                                    {/* <!-- Badge --> */}
                                    <div className="badge badge-soft-success">
                                    Accepté
                                    </div>

                                </td>
                                <td className="orders-method">
                                    Mastercard
                                </td>
                                
                                </tr>
                                
                                <tr>
                                
                                <td className="orders-order">
                                    <a href="/mockups/mon-profil/factures/6520">#6526</a>
                                </td>
                                <td className="orders-product">
                                    Abonnement mensuel
                                </td>
                                <td className="orders-date">

                                    {/* <!-- Time --> */}
                                    <time datetime="2018-07-22">20/11/2020</time>

                                </td>
                                <td className="orders-total">
                                    16,99 €
                                </td>
                                <td className="orders-status">

                                    {/* <!-- Badge --> */}
                                    <div className="badge badge-soft-success">
                                    Accepté
                                    </div>

                                </td>
                                <td className="orders-method">
                                    Mastercard
                                </td>
                                
                                </tr>
                                
                                <tr>
                                
                                <td className="orders-order">
                                    <a href="/mockups/mon-profil/factures/6520">#6527</a>
                                </td>
                                <td className="orders-product">
                                    Abonnement mensuel
                                </td>
                                <td className="orders-date">

                                    {/* <!-- Time --> */}
                                    <time datetime="2018-07-21">20/10/2020</time>

                                </td>
                                <td className="orders-total">
                                    16,99 €
                                </td>
                                <td className="orders-sratus">

                                    {/* <!-- Badge --> */}
                                    <div className="badge badge-soft-success">
                                    Accepté
                                    </div>

                                </td>
                                <td className="orders-method">
                                    Mastercard
                                </td>
                                
                                </tr>
                                
                            </tbody>
                            </table>
                        </div>
                        </div>

                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    )
}

export default MonProfilFactures