import { createSlice } from '@reduxjs/toolkit'
import arraySliceHelper from '../../toolsSlice/arraySliceHelper'

const helper = new arraySliceHelper(['nom', 'metiers', 'code_postal', 'ville'], 'nom', "10")

const artisansSlice = createSlice({

    name: 'artisans',
    initialState: helper.getInitialState(),
    reducers: {
        // no payload
        getArtisansInit: (state) => {

            helper.performInit(state)
        },

        // payload : array of users
        getArtisansSuccess: (state, {payload}) => {
            
            helper.performSuccess(state, payload)
        },

        // payload : errorMessage
        getArtisansFailure: (state, { payload }) => {

            helper.performFailure(state, payload)
        },

        // payload : { value to search on everyfields, fields concerned }
        filterByText: (state, { payload }) => {

            helper.performChangeFilter(state, payload)
        },

        // payload : field
        sort: (state, { payload }) => {

            helper.performChangeSort(state, payload)
        },

        // payload : { nbPerPage, currentPage }
        changePagination: (state, { payload }) => {

            helper.performChangePagination(state, payload)
        }
    }
})

export const performGetArtisans = (token) => {

  return async dispatch => {
      dispatch(getArtisansInit())

      try {
        const reqOptions = {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json',
              'auth-token' : token
          },
          body: JSON.stringify()
        }
        
        const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/artisans', reqOptions)
        const json = await res.json()

        if( res.status === 200 && !json.error ) {
            const artisans = json.datas
            dispatch(getArtisansSuccess(artisans))
        }
        else {
          dispatch(getArtisansFailure(json.message))
        }
      }
      catch(e) {
        dispatch(getArtisansFailure(e.toString()))
      }
  }
}

// Export action creators
const { getArtisansInit, getArtisansSuccess, getArtisansFailure } = artisansSlice.actions
export const { resetArtisansInitialState, sort, sortByNom, sortByMetiers, sortByVille, filterByText, changePagination } = artisansSlice.actions

// Export reducer
export default artisansSlice.reducer

// Selectors
export const artisansStatusSelector = (state) => state.artisans.status
export const artisansFilterSelector = (state) => state.artisans.filter
export const artisansDatasSelector = (state) => state.artisans.datas
export const artisansFilteredDatasSelector = (state) => state.artisans.filteredDatas
export const artisansPaginatedDatasSelector = (state) => state.artisans.paginatedDatas
export const artisansPaginationSelector = (state) => state.artisans.pagination