import { createSlice } from "@reduxjs/toolkit";
import objectSliceHelper from "../../../toolsSlice/objectSliceHelper";
import { getNewArray } from "./utils";

const URL = "/descriptifs/";
const URL_GENERATE = "/generateurDescriptif/";
const helper = new objectSliceHelper();

const initialLogement = {
  global: {
    nom: "Logement",
  },
  structure: {
    pieces: [
      {
        surface: 9,
        categorie: undefined,
      },
      {
        surface: 9,
        categorie: undefined,
      },
    ],
  },
  plomberie: {
    lotPlomberie: { isChecked: false },
    alimentationGenerale: false,
    sanitaires: false,
    meubleVasque: {
      isChecked: false,
      hauteurMeubleVasque: undefined,
      largeurMeubleVasque: undefined,
      profondeurMeubleVasque: undefined,
    },
    wc: {
      isChecked: false,
      typewc: "courte",
    },
    ballonDeauChaude: {
      isChecked: false,
      typeBallon: "normal",
      volumeBallon: undefined,
    },
    receveurDeDouche: {
      isChecked: false,
      largeurReceveurDeDouche: undefined,
      longeurReceveurDeDouche: undefined,
    },
    paroieDeDouche: {
      isChecked: false,
      typeParoie: "italienne",
    },
    robinetterie: false,
    baignoire: {
      isChecked: false,
      largeurBaignoire: undefined,
      longeurBaignoire: undefined,
    },
    changementChaudiereGaz: false,
  },
  electricite: {
    lotElectricite: { isChecked: false },
    tableauElectrique: { isChecked: false },
    interrupteurs: {
      isChecked: false,
      nombreInterrupteurs: undefined,
    },
    prisesRJ45: {
      isChecked: false,
      nombrePrisesRJ45: undefined,
    },
    sortiesLumieres: {
      isChecked: false,
      nombreSortiesLumieres: undefined,
    },
    prisesAntennes: {
      isChecked: false,
      nombrePrisesAntennes: undefined,
    },
    alimentationsSpeciales: {
      isChecked: false,
      nombreAlimentationsSpeciales: false,
    },
    radiateursElectriques: {
      isChecked: false,
      radiateurs: [
        {
          categorie: "condesateurElectrique",
          puissance: undefined,
        },
        {
          categorie: "condesateurElectrique",
          puissance: undefined,
        },
      ],
    },
  },
  maconnerie: {
    lotMaconnerie: { isChecked: false },
    repriseNiveauSol: {
      isChecked: false,
      categorie: undefined,
      surface: undefined,
    },
    creationChape: {
      isChecked: false,
      surface: undefined,
    },
    repriseDimensionFenetres: {
      isChecked: false,
      fenetres: [
        {
          nom: undefined,
          largeurActuelle: undefined,
          hauteurActuelle: undefined,
          largeurFuture: undefined,
          hauteurFuture: undefined,
        },
        {
          nom: undefined,
          largeurActuelle: undefined,
          hauteurActuelle: undefined,
          largeurFuture: undefined,
          hauteurFuture: undefined,
        },
      ],
    },
  },
  demolition: {
    lotDemolition: { isChecked: false },
    cloison: {
      isChecked: false,
      surface: undefined,
    },
    plafond: {
      isChecked: false,
      surface: undefined,
    },
    chemine: {
      isChecked: false,
      surface: undefined,
    },
    escalier: {
      isChecked: false,
      surface: undefined,
    },
    revetementSol: {
      isChecked: false,
      surface: undefined,
    },
  },
  menuiserieInt: {
    lotMenuiserieInt: { isChecked: false },
    portePaliere: {
      isChecked: false,
      sens: "gauche",
      largeur: 83,
      hauteur: 220,
    },
    portesInt: {
      isChecked: false,
      portes: [
        {
          nom: "",
          categorie: "normale",
          sens: "gauche",
          largeur: "83",
          hauteur: 220,
        },
        {
          nom: "",
          categorie: "normale",
          sens: "gauche",
          largeur: "83",
          hauteur: 220,
        },
      ],
    },
  },
  revetementsSol: {
    lotRevetementsSol: {
      isChecked: false,
    },
    revetements: [
      {
        categorie: "parquetFlotant",
        aspect: undefined,
        localisation: undefined,
        surface: undefined,
      },
    ],
  },
  revetementsMur: {
    lotRevetementsMur: {
      isChecked: false,
    },
    revetements: [
      {
        categorie: "peinture",
        aspect: undefined,
        localisation: undefined,
        surface: undefined,
      },
    ],
  },
  isolation: {
    lotIsolation: { isChecked: false },
    plafond: {
      isChecked: false,
      categorie: "laineDeRoche",
      surface: undefined,
      epaisseur: undefined,
    },
    mur: {
      isChecked: false,
      categorie: "laineDeRoche",
      surface: undefined,
      epaisseur: undefined,
    },
    sol: {
      isChecked: false,
      categorie: "laineDeRoche",
      surface: undefined,
      epaisseur: undefined,
    },
  },
  cloisonsPlafonds: {
    lotCloisonsPlafonds: { isChecked: false },
    cloisonsSimples: {
      isChecked: false,
      surface: undefined,
      epaisseur: undefined,
    },
    cloisonsDoubles: {
      isChecked: false,
      surface: undefined,
      epaisseur: undefined,
    },
    plafonds: {
      isChecked: false,
      surface: undefined,
      epaisseur: undefined,
    },
  },
  cuisine: {
    lotCuisine: { isChecked: false },
    largeur: undefined,
    hauteur: undefined,
    profondeur: undefined,
    meublesHauts: false,
    meublesBas: false,
    evier: false,
    robinet: false,
    electromenager: {
      isChecked: false,
      plaque: false,
      hote: false,
    },
  },
};

const unProjetSimulationTravauxSlice = createSlice({
  name: "unProjetSimulationTravaux",
  initialState: {
    ...helper.getInitialState(),
    datas: {
      logements: [initialLogement],
    },
  },
  reducers: {
    ...helper.reducers,
    editLogements: (state, { payload: { index, lot, inputName, value } }) => {
      const parts = inputName.split(".");

      if (parts.length === 1)
        state.datas.logements[index][lot][parts[0]] = value;
      else if (parts.length === 2)
        state.datas.logements[index][lot][parts[0]][parts[1]] = value;
      else if (parts.length === 3)
        state.datas.logements[index][lot][parts[0]][parts[1]][parts[2]] = value;
      else if (parts.length === 4)
        state.datas.logements[index][lot][parts[0]][parts[1]][parts[2]][
          parts[3]
        ] = value;
    },
    changeLogements: (state, { payload }) => {
      state.datas.logements = getNewArray({
        value: payload,
        max: 10,
        initialObject: initialLogement,
        array: state.datas.logements,
      });
    },
  },
});

export const performGetUnDescriptif = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(getInit(helper.ACTIONS.GET));
      const res = await helper.performGetAPICall(token, id, URL);
      const json = await res.json();

      if (res.status === 200 && !json.error) {
        const descriptif = json.datas;
        if (descriptif.logements.length === 0)
          descriptif.logements = [initialLogement];

        dispatch(getSuccess(descriptif));
      } else dispatch(getFailure(json.message));
    } catch (e) {
      dispatch(getFailure(e.toString()));
    }
  };
};

export const performSyncUnDescriptif = (token, descriptif) => {
  return async (dispatch) => {
    try {
      const res = await helper.performSyncAPICall(token, descriptif, URL);
      const json = await res.json();

      if (res.status === 200 && !json.error) dispatch(syncSuccess(json.datas));
      else dispatch(syncFailure(json.message));
    } catch (e) {
      dispatch(syncFailure(e.toString()));
    }
  };
};

// Export action creators
const { getInit, getSuccess, getFailure, syncInit, syncSuccess, syncFailure } =
  unProjetSimulationTravauxSlice.actions;

export const { editLogements, changeLogements } =
  unProjetSimulationTravauxSlice.actions;

// Export reducer
export default unProjetSimulationTravauxSlice.reducer;

// Selectors
export const unProjetSimulationTravauxStatusSelector = (state) =>
  state.unProjetSimulationTravaux.status;
export const unProjetSimulationTravauxDatasSelector = (state) =>
  state.unProjetSimulationTravaux.datas;
