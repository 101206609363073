import React from 'react'

const SelectTypeBien = ({inputName, value, withNone=true, onChange}) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <div className="form-group">
      <label htmlFor={'input'+inputName}>
        Type de bien
      </label>

      <select className="form-control" value={value} onChange={handleChange}>
        {withNone && <option value="">---</option>}
        <option value="appartement">Appartement</option>
        <option value="maison">Maison</option>
      </select>
    </div>
  )
}

export default SelectTypeBien