import React, { useState } from 'react'
import EtudeItem from './EtudeItem'
import PaginationBar from '../../toolsComponents/bars/PaginationBar'

const Etudes = ({ etudes, pagination, onSearch, onPagination }) => {

  const [ isLarge, setIsLarge ] = useState(true)

  const handleLargeDisplay = () => {
    setIsLarge(true)
  }

  const handleSmallDisplay = () => {
    setIsLarge(false)
  }

  const handleSearchChanged = (e) => {
    onSearch(e.target.value)
  }

  const handlePageChange = (e) => {
    onPagination(6, e)
  }

  return (
    <>
      {/* Search and toggle */}
      <div className="row mb-4">
        <div className="col">

          <form>
            <div className="input-group input-group-lg input-group-merge">

              <input type="text" className="form-control form-control-prepended list-search" placeholder="Rechercher" onChange={handleSearchChanged} />

              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span className="fe fe-search"></span>
                </div>
              </div>

            </div>
          </form>

        </div>
        <div className="col-auto">

          <div className="nav btn-group" role="tablist">
            <button className="btn btn-lg btn-white active" data-toggle="tab" data-target="#tabPaneOne" role="tab" aria-controls="tabPaneOne" aria-selected="true" onClick={handleLargeDisplay}>
              <span className="fe fe-grid"></span>
            </button>
            <button className="btn btn-lg btn-white" data-toggle="tab" data-target="#tabPaneTwo" role="tab" aria-controls="tabPaneTwo" aria-selected="false"  onClick={handleSmallDisplay}>
              <span className="fe fe-list"></span>
            </button>
          </div> 

        </div>
      </div>

      {/* CARDS */}
      <div className="row">
        {etudes.map(e => { return (
          <div className="col-6">
            <EtudeItem key={e._id} isLarge={isLarge} etude={e} />
          </div>
        ) })}
      </div>

      {/* Pagination */}
      <div className="card">
        <PaginationBar
          pagination={pagination}
          onPageChange={handlePageChange}             
        />
      </div>
    </> 
  )
}

export default Etudes