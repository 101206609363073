import React from 'react'
import Image from '../../toolsComponents/images/Image'

const UnProjetHeader = ({ isActive, isActiveSubMenu, projet, onAddEtude, onDelete }) => {

  const getClassName = (value, isDropdown = true) => {
    let result = ''

    result += isActive === value ? 'nav-link active ' : 'nav-link  '
    result += projet && projet?._id ? '' : 'inactive-link '
    result += isDropdown ? 'dropdown-toggle ' : ''

    return result
  }
  
  return (
    <div>
      {/* <!-- MODAL DELETE --> */}
      <div className="modal" tabindex="-1" role="dialog" id="modal-delete-projet">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p>Voulez-vous vraiment supprimer ce projet ?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={onDelete}><i className="fe fe-trash"></i> Oui</button>
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fe fe-x"></i> Non</button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- MODAL VILLE INTROUVABLE --> */}
      <div className="modal" tabindex="-1" role="dialog" id="modal-ville-introuvable">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p>Il nous est impossible de trouver la ville {projet?.ville} ({projet?.code_postal}). Veuillez sélectionner une ville correcte dans le champ ville et sauvegarder.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fe fe-x"></i> Fermer</button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- MODAL ETUDE INTROUVABLE --> */}
      <div className="modal" tabindex="-1" role="dialog" id="modal-etude-introuvable">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <p>Vous n'avez pas d'étude de marché pour {projet?.ville} ({projet?.code_postal}). Voulez-vous en créer une ?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={onAddEtude}><i className="fe fe-plus"></i> Oui</button>
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fe fe-x"></i> Non</button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- HEADER --> */}
      <div className="header" style={{marginBottom:"0px"}}>
        {/* <!-- Image --> */}
        <Image 
          src={projet?.url_banniere} 
          className="header-img-top" alt="..." 
          style={{height:'250px', objectFit:'cover'}} 
        />

        <div className="container-fluid">
          {/* <!-- Body --> */}
          <div className="header-body mt-n5 mt-md-n6" style={{borderBottom:"0px"}}>
            <div className="row align-items-end">
              <div className="col-auto">
                {/* <!-- Avatar --> */}
                <div className="avatar avatar-xxl header-avatar-top">
                  <Image 
                    src={projet?.url_avatar} 
                    alt="Avatar" 
                    className="avatar-img rounded border border-4 border-body" 
                  />
                </div>
              </div>

              <div className="col mb-3 ml-n3 ml-md-n2" id="title-projet">
                {/* <!-- Pretitle --> */}
                <h6 className="header-pretitle">
                  Mon projet / {isActive} / {isActiveSubMenu}
                </h6>

                {/* <!-- Title --> */}
                <h1 className="header-title">
                  {projet?.titre}
                </h1>
              </div>  

              <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                {/* <!-- Button --> */}
                <a href="#!" className="btn btn-danger d-block d-md-inline-block lift" style={{marginTop: '24px'}} data-toggle="modal" data-target="#modal-delete-projet">
                    <i className="fe fe-trash"></i> Supprimer
                </a>
              </div>
            </div> {/* <!-- / .row --> */}

            <ul className="nav nav-tabs">
              <li className="nav-item dropdown">
                <a className={getClassName('general')} data-toggle="dropdown" href="#!" role="button" aria-haspopup="true" aria-expanded="false">Général</a>
                
                <div className="dropdown-menu">
                  <a className={isActive === 'general' && isActiveSubMenu === 'resume' ? 'dropdown-item active' : 'dropdown-item'} href={`/projets/${projet?._id}`}>Résumé</a>
                  
                  <a className={isActiveSubMenu === 'photos' ? 'dropdown-item active' : 'dropdown-item'} href={`/projets/${projet?._id}/photos`}>Photos</a>
                  
                  <a className={isActiveSubMenu === 'contacts' ? 'dropdown-item active' : 'dropdown-item nav-link-disabled'} href="#!" role="button" data-toggle="modal" data-target="#WIPModal">Contacts</a>
                  
                  <a className={isActiveSubMenu === 'rdvs' ? 'dropdown-item active' : 'dropdown-item nav-link-disabled'} href="#!" role="button" data-toggle="modal" data-target="#WIPModal">Rendez-vous</a>
                  
                  <a className={isActiveSubMenu === 'annexes' ? 'dropdown-item active' : 'dropdown-item nav-link-disabled'} href="#!" role="button" data-toggle="modal" data-target="#WIPModal">Annexes</a>
                </div>
              </li>

              {/* La ville est introuvable dans nos Commune */}
              {projet && !projet?.etude && !projet?.commune &&
                <li className="nav-item">
                  <a className="nav-link" href="#!" role="button" data-toggle="modal" data-target="#modal-ville-introuvable">Etude de marché</a>
                </li>
              }

              {/* La ville existe dans Commune mais l'utilisateur n'a pas d'étude de marché pour cette ville */}
              {projet && projet?.commune && !projet?.etude &&
                <li className="nav-item">
                  <a className="nav-link" href="#!" role="button" data-toggle="modal" data-target="#modal-etude-introuvable">Etude de marché</a>
                </li>
              }

              {/* La ville existe dans commune et l'utilisateur a une étude de marché pour cette ville*/}
              {projet && projet?.etude && projet?.etude._id &&
                <li className="nav-item dropdown">
                  <a className={getClassName('edm')} data-toggle="dropdown" href="#!" role="button" aria-haspopup="true" aria-expanded="false">Étude de marché</a>

                  <div className="dropdown-menu">
                    <a className={isActive === 'edm' && isActiveSubMenu === 'resume' ? 'dropdown-item active' : 'dropdown-item'} href={`/etudes/${projet?.etude._id}`}>Résumé</a>
                    
                    <a className={isActive === 'edm' && isActiveSubMenu === 'environnement' ? 'dropdown-item active' : 'dropdown-item'} href={`/etudes/${projet?.etude._id}/environnement`}>Environnement</a>

                    <a className={isActive === 'edm' && isActiveSubMenu === 'valeurs-foncieres' ? 'dropdown-item active' : 'dropdown-item'} href={`/etudes/${projet?.etude._id}/valeurs-foncieres`}>Valeurs foncières</a>

                    <a className={isActive === 'edm' && isActiveSubMenu === 'logements-courtes-durees' ? 'dropdown-item active' : 'dropdown-item'} href={`/etudes/${projet?.etude._id}/logements-courtes-durees`}>Logements courtes durées</a>

                    <a className={isActive === 'edm' && isActiveSubMenu === 'population' ? 'dropdown-item active' : 'dropdown-item'} href={`/etudes/${projet?.etude._id}/populations`}>Population</a>

                    <a className={isActive === 'edm' && isActiveSubMenu === 'wikipedia' ? 'dropdown-item active' : 'dropdown-item'} href={`/etudes/${projet?.etude._id}/wikipedia`}>Wikipedia</a>

                    <a className={isActive === 'edm' && isActiveSubMenu === 'appreciations' ? 'dropdown-item active' : 'dropdown-item'} href={`/etudes/${projet?.etude._id}/appreciations`}>Appréciations</a>
                    
                  </div>
                </li>
              }

              {/* La ville existe dans commune et l'utilisateur a une étude de marché pour cette ville*/}
              {projet &&
                <li className="nav-item">
                  <a className={getClassName('rentabilite', false)} href={`/projets/${projet?._id}/rentabilite/`} role="button" style={{marginTop: '2px'}}>Etude de rentabilité</a>
                </li>
              }

              {projet &&
                <li className="nav-item">
                  <a className={getClassName('travaux', false)} href={`/projets/${projet?._id}/simulationtravaux/`} role="button" style={{marginTop: '2px'}}>Simulation des travaux</a>
                </li>
              }

              {/* <li className="nav-item dropdown">
              <a className="nav-link nav-link-disabled" href="#!" role="button" data-toggle="modal" data-target="#WIPModal">Travaux</a>
                
                <div className="dropdown-menu">
                  <a className={isActive === 'travaux' && isActiveSubMenu === 'resume' ? 'dropdown-item active' : 'dropdown-item'} href="/mes-projets/60ab9cd7cc0cbd68e45a105b/travaux">Résumé</a>
                  
                  <a className={isActiveSubMenu === 'plans' ? 'dropdown-item active' : 'dropdown-item'} href="/mes-projets/60ab9cd7cc0cbd68e45a105b/plans">Plans</a>
                  
                  <a className={isActiveSubMenu === 'devis' ? 'dropdown-item active' : 'dropdown-item'} href="/mes-projets/60ab9cd7cc0cbd68e45a105b/devis">Devis</a>
                </div>
              </li> */}

              <li className="nav-item dropdown">
                <a className="nav-link nav-link-disabled" href="#!" role="button" data-toggle="modal" data-target="#WIPModal">Achat & financement</a>
                
                {/* <div className="dropdown-menu">
                  <a className={isActive === 'financement' && isActiveSubMenu === 'resume' ? 'dropdown-item active' : 'dropdown-item'} href="/mes-projets/60ab9cd7cc0cbd68e45a105b/financement">Résumé</a>
                  
                  <a className={isActiveSubMenu === 'emprunteurs' ? 'dropdown-item active' : 'dropdown-item'} href="/mes-projets/60ab9cd7cc0cbd68e45a105b/emprunteurs">Emprunteurs supplémentaires</a>
                  
                  <a className={isActiveSubMenu === 'plan-financement' ? 'dropdown-item active' : 'dropdown-item'} href="/mes-projets/60ab9cd7cc0cbd68e45a105b/plan-financement">Plan de financement</a>
                  
                  <a className={isActiveSubMenu === 'offres-pret' ? 'dropdown-item active' : 'dropdown-item'} href="/mes-projets/60ab9cd7cc0cbd68e45a105b/offres-pret">Offres de prêt</a>
                </div> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnProjetHeader