import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Navigation from '../../toolsComponents/Navigation'
import SimpleError from '../../toolsComponents/SimpleError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'
import { loginDatasSelector } from '../auth/login/loginSlice'
import UneOffrePret from './UneOffrePret'
import { performGetUneOffrePret, uneOffrePretDatasSelector, uneOffrePretStatusSelector } from './uneOffrePretSlice'

const UneOffrePretWithStore = ( { id } ) => {

  const { id_offre_pret } = useParams()
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const [ cookies ] = useCookies(['token', 'email'])
  const { loading, hasError, errorMessage } = useSelector(uneOffrePretStatusSelector)
  const offrePret = useSelector(uneOffrePretDatasSelector)

  useEffect(() => {
      // Get une offre pret
      dispatch(performGetUneOffrePret(cookies.token, id_offre_pret))
  }, [dispatch, cookies.token, id_offre_pret])

  return (
    <div>
      <Navigation granted_modules={granted_modules} />

      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-10">
              {loading && 
                <div style={{paddingTop:"128px"}}>
                  <SimpleLoading />
                </div>
              }

              {hasError &&
                <div>
                  <br />
                  <SimpleError>
                    {errorMessage}
                  </SimpleError>
                </div>
              }

              {!loading && !hasError &&
                <div>
                  <UneOffrePret 
                    offrePret={offrePret}
                  />
                </div>
              }
            </div>
          </div>
        </div> 
      </div> 
    </div>
  )
}

export default UneOffrePretWithStore