import React from "react";
import Image from "../../toolsComponents/images/Image";
import DeleteModal from "../../toolsComponents/modals/DeleteModal";

const UnBienHeader = ({ isActive, bien, onDelete }) => {
  const getAvatar = (bien) => {
    if (bien?.url_avatar) return bien?.url_avatar;
    else if (bien?.type === "NOM_PROPRE")
      return "/assets/img/mockups/patrimoine-nom-propre.png";
    else if (bien?.type === "SOCIETE")
      return "/assets/img/mockups/patrimoine-societe.jpeg";
    else if (bien?.type === "IMMEUBLE")
      return "/assets/img/avatars/biens/immeuble1.webp";
    else if (bien?.type === "LOGEMENT")
      return "/assets/img/avatars/biens/logement1.jpeg";
  };

  const getClassName = (value) => {
    let result = "";

    result += isActive === value ? "nav-link active " : "nav-link ";
    result += bien && bien._id ? "" : "inactive-link ";

    return result;
  };

  return (
    <>
      <DeleteModal onDelete={onDelete}>
        Voulez-vous vraiment supprimer ce bien de votre patrimoine ?
      </DeleteModal>

      {/* <!-- HEADER --> */}
      <div className="header">
        <div className="container-fluid">
          {/* <!-- Body --> */}
          <div className="header-body">
            <div className="row align-items-center">
              <div className="col-auto">
                {/* <!-- Avatar --> */}
                <div className="avatar avatar-lg avatar-4by3">
                  <Image
                    src={getAvatar(bien)}
                    alt="..."
                    className="avatar-img rounded"
                  />
                </div>
              </div>

              <div className="col ml-n3 ml-md-n2">
                {/* <!-- Pretitle --> */}
                <h6 className="header-pretitle">Détails</h6>

                {/* <!-- Title --> */}
                <h1 className="header-title">{bien?.titre}</h1>
              </div>

              {bien?.type !== "NOM_PROPRE" && (
                <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                  {/* <!-- Button --> */}
                  <a
                    href="#!"
                    className="btn btn-danger d-block d-md-inline-block lift"
                    style={{ marginTop: "24px" }}
                    data-toggle="modal"
                    data-target="#modal-delete"
                  >
                    <i className="fe fe-trash"></i> Supprimer
                  </a>
                </div>
              )}
            </div>

            <div className="row align-items-center">
              <div className="col">
                {/* <!-- Nav --> */}
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  <li className="nav-item">
                    <a
                      href={`/bien/${bien?._id}`}
                      className={getClassName("general")}
                    >
                      Résumé
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="#!" className={"nav-link nav-link-disabled"}>
                      Transactions
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      href={`/bien/${bien?._id}/documents`}
                      className={getClassName("documents")}
                    >
                      Documents
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnBienHeader;
