import React from 'react'
import { useEffect, useState } from 'react'
import defined from '../../../../ressources/defined'

const RegisterOffreItem = ({ offre, isActive, onClick }) => {

  const [ prixMensuel, setPrixMensuel ] = useState("")
  const [ prixAnnuel, setPrixAnnuel ] = useState("")

  useEffect(() => {
    if( offre.visuel.recurrence === "mensuel" ) {
      setPrixMensuel(offre.visuel.montant.toFixed(2))
      setPrixAnnuel((offre.visuel.montant * 12).toFixed(2))
    }
    else if( offre.visuel.recurrence === "annuel" ) {
      setPrixMensuel((offre.visuel.montant / 12).toFixed(2))
      setPrixAnnuel(offre.visuel.montant.toFixed(2))
    }
  })

  return <>
    <div className={`card card-checkout-plan ${isActive ? 'card-checkout-plan-active' : ''}`} onClick={() => onClick(offre._id)}>
      <div className='card-body'>
        <div className='row'>
          <div className='col-1 vertical-center'>
            <i className={`fe ${isActive ? 'fe-check-circle' : 'fe-circle'}`} style={{color: isActive ? defined.COLOR_GREEN : defined.COLOR_GREY, fontSize:"1.6em"}}></i>
          </div>

          <div className='col-6 vertical-center'>
            <div style={{textAlign:"center"}}>
              <h5 className='header-pretitle'>ABONNEMENT</h5>
              <h1 className='header-title text-uppercase'>{offre.visuel.recurrence}</h1>
            </div>
          </div>

          <div className='col-5' style={{textAlign:'right'}}>
            {offre.visuel.economie && 
              <div className='badge badge-soft-success'>ÉCONOMISEZ {offre.visuel.economie}</div>
            }
            <h1 className='header-title' style={{marginTop: '6px', marginBottom:'6px'}}>{prixMensuel} €/mois</h1>
            <h5 className='header-pretitle'>{prixAnnuel} €/an</h5>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default RegisterOffreItem