import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import defined from '../../ressources/defined'
import Navigation from '../../toolsComponents/Navigation'
import SimpleError from '../../toolsComponents/SimpleError'
import SimpleLoading from '../../toolsComponents/SimpleLoading'
import { loginDatasSelector } from '../auth/login/loginSlice'
import UneFormation from './UneFormation'
import { performGetUneFormation, uneFormationDatasSelector, uneFormationStatusSelector } from './uneFormationSlice'

const UneFormationWithStore = () => {

  const { id } = useParams()
  const { granted_modules } = useSelector(loginDatasSelector)
  const dispatch = useDispatch()
  const [ cookies ] = useCookies(['token', 'email'])
  const { loading, hasError, errorMessage } = useSelector(uneFormationStatusSelector)
  const formation = useSelector(uneFormationDatasSelector)


  useEffect(() => {
      // Get une formation
      dispatch(performGetUneFormation(cookies.token, id))
  }, [dispatch, cookies.token, id])

  if(hasError && errorMessage === defined.INVALID_AUTH_LEVEL) {
    return <SimpleError>
      Vous n'avez pas la bonne offre
    </SimpleError>
  }
  else {
    return (
      <div>
        <Navigation granted_modules={granted_modules} />

        <div className="main-content bg-fixed-bottom" style={{backgroundImage:'url(/assets/img/illustrations/sticky.svg)'}}>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-10">
                {loading && 
                  <div style={{paddingTop:"128px"}}>
                    <SimpleLoading />
                  </div>
                }

                {hasError &&
                  <SimpleError>
                    {errorMessage}
                  </SimpleError>
                }

                {!loading && !hasError &&
                  <div>
                    <UneFormation 
                      formation={formation}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div> 
      </div>
    )
  }
}

export default UneFormationWithStore