const tools = {
  header: {
    getLinkClassName: (value, isActive, obj) => {
      let result = ''
      result += isActive === value ? 'nav-link active ' : 'nav-link '
      result += obj && obj._id ? '' : 'inactive-link '
      return result
    }
  }
}

export default tools