import React from 'react'
import Image from '../images/Image'

/**
 * 
 * @param data (id, url, url_img, title, sub_title, rating)
 * @param deletable display delete button
 * @param onDelete triggered on delete
 * @returns 
 */
const LargeRow = ({ data, deletable, onDelete }) => {

  const getNotationClass = () => {
    if(!data || !data.rating || data.rating === '' || parseInt(data.rating) < 10 ) return 'text-danger'
    else if(parseInt(data.rating) < 15 ) return 'text-warning'
    else return 'text-success'
  }

  return (
    <div className="card mb-3">
      <div className="card-body">
          <div className="row align-items-center">
            {/* <!-- Avatar --> */}
            {data?.url?.length > 0 &&
              <div className="col-auto">
                <a href={data?.url} className="avatar avatar-lg">
                  <Image src={data?.url_img} alt="..." className="avatar-img rounded-circle" />
                </a>
              </div>
            }

            <div className="col ml-n2">
              {/* <!-- Title --> */}
              {data?.title?.length > 0 &&
                <h4 className="mb-1">
                  <a href={data?.url}>{data?.title}</a>
                </h4>
              }

              {/* <!-- Headline --> */}
              {data?.headline?.length > 0 &&
                <p className="card-text small text-muted mb-1">
                  <em>{data?.headline}</em>
                </p>
              }

              {/* <!-- Sub title --> */}
              {data?.sub_title?.length > 0 &&
                <p className="card-text small text-muted mb-1">
                  {data?.sub_title.length > 300 ? data.sub_title.substring(0, 299) + ' ...' : data.sub_title}
                </p>
              }

              {/* <!-- Status --> */}
              <p className="card-text small">
                <span className={getNotationClass()}>●</span> {data?.rating}/20
              </p>
            </div>
              
            {deletable &&
              <div className="col-auto">
                {/* <!-- Dropdown --> */}
                <div className="dropdown">
                  <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" data-expanded="false">
                    <i className="fe fe-more-vertical"></i>
                  </a>

                  <div className="dropdown-menu dropdown-menu-right">
                    <a href="#!" className="dropdown-item" onClick={() => onDelete(data?.id)}>
                      <i className="fe fe-trash-2"></i> Supprimer
                    </a>
                  </div>
                </div>
              </div>
            }
          </div>
      </div> 
    </div>
  )
}

export default LargeRow