import React from 'react'

/**
 * 
 * @param label Input label
 * @param inputName Name of input used in id and name
 * @param value Input value
 * @param onChange Event triggered on input value changed
 * @returns input form in JSX
 */
const SelectNotation = ({ label, inputName, value, emptyLabel = '---', withoutMargin = false, onChange  }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  const getOptions = () => {
    return (
      <select className="form-control" value={value} onChange={handleChange} style={{marginTop: withoutMargin ? '23px' : '0px'}}>
        <option value="">{emptyLabel}</option>
        <option value="0">0/20</option>
        <option value="1">1/20</option>
        <option value="2">2/20</option>
        <option value="3">3/20</option>
        <option value="4">4/20</option>
        <option value="5">5/20</option>
        <option value="6">6/20</option>
        <option value="7">7/20</option>
        <option value="8">8/20</option>
        <option value="9">9/20</option>
        <option value="10">10/20</option>
        <option value="11">11/20</option>
        <option value="12">12/20</option>
        <option value="13">13/20</option>
        <option value="14">14/20</option>
        <option value="15">15/20</option>
        <option value="16">16/20</option>
        <option value="17">17/20</option>
        <option value="18">18/20</option>
        <option value="19">19/20</option>
        <option value="20">20/20</option>
      </select>
    )
  }

  return (
    <>
      <div className="form-group" style={{marginBottom: withoutMargin ? '0px' : '22px'}}>
        {label &&
          <label htmlFor={'input'+inputName}>
            {label}
          </label>
        }
        
        {getOptions()}
      </div>
    </>
  )
}

export default SelectNotation