import React from 'react'

const SimpleWarning = ({ children }) => {

  return (
    <div className="alert alert-warning" role="alert">
      <p style={{marginBottom: '0px'}}>{children}</p>
    </div>
  )
}

export default SimpleWarning