import React, {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie'

/** Token is require */
const Image = ({ src, alt = "", className = "", style = {} }) => {

  const [ url, setUrl ] = useState('')
  const [ newStyle, setNewStyle ] = useState({})
  const [ loadingImageStyle, setLoadingImageStyle ] = useState({display:'block'})
  const [ loadingClassName, setLoadingClassname ] = useState('')
  const [ cookies ] = useCookies(['token'])

  useEffect(() => {
    const regex_url = /^\/files\/[a-zA-Z0-9]+\//
    if( src && src.match(regex_url) ) {
      setUrl(process.env.REACT_APP_REST_API_LOCATION + src + cookies.token)
    }
    else {
      setUrl(src)
    }

    const tmpStyle = JSON.parse(JSON.stringify(style))
    tmpStyle.display = 'none'
    setNewStyle(tmpStyle)
    
    const tmpLoadingImageStyle = JSON.parse(JSON.stringify(style))
    tmpLoadingImageStyle.display = 'block'
    setLoadingImageStyle(tmpLoadingImageStyle)

    setLoadingClassname(className)

  }, [src, cookies.token])
  
  const handleLoad = () => {
    const tmpStyle = JSON.parse(JSON.stringify(style))
    tmpStyle.display = 'block'
    setNewStyle(tmpStyle)

    const tmpLoadingImageStyle = JSON.parse(JSON.stringify(style))
    tmpLoadingImageStyle.display = 'none'
    setLoadingImageStyle(tmpLoadingImageStyle)

    setLoadingImageStyle({display: 'none'})

    setLoadingClassname('')
  }

  return (
    <> 
      <img src="/assets/img/tools/preload2.gif" alt="loading" className={loadingClassName} style={loadingImageStyle} />
      <img 
        src={url}
        alt={alt}
        className={className}
        style={newStyle}
        onLoad={handleLoad}
      />
    </>
  )
}

export default Image