import { createSlice } from '@reduxjs/toolkit'
import objectSliceHelper from '../../../toolsSlice/objectSliceHelper'

const URL_process = '/payment-processes/'
const URL_register = '/register'
const URL_register_adresse = '/register-address'
const helper = new objectSliceHelper()

const registerSlice = createSlice({
  name: 'register',
  initialState: {
    ...helper.getInitialState(),
    datas: {
      process: null,
      infosLogin: null,
      infosFacturation: null
    }
  },
  reducers: {
    ...helper.reducers,

    getProcessSuccess: (state, { payload }) => {
      state.status.loading = false
      state.status.hasError = false
      state.status.errorMessage = null

      state.datas.process = payload
    },

    registerInit: (state, { payload }) => {
      state.status.loading = true
      state.status.hasError = false
      state.status.errorMessage = null
      state.status.lastAction = payload
    },

    registerSuccess: (state, { payload }) => {
      state.status.loading = false
      state.status.hasError = false
      state.status.errorMessage = null

      state.datas.infosLogin = payload
    },

    registerFacturationSuccess: (state, { payload }) => {
      state.status.loading = false
      state.status.hasError = false
      state.status.errorMessage = null

      state.datas.infosFacturation = payload
    },

    registerFailure: (state, { payload }) => {
      state.status.loading = false
      state.status.hasError = true
      state.status.errorMessage = payload
    }
  }
})

export const performGetUnProcess = (url) => {

  return async dispatch => {
    try {
      dispatch(init('GET_PROCESS'))

      const res = await helper.performGetAPICall(null, url, URL_process)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(getProcessSuccess(json.datas))
      else dispatch(getFailure(json.message))
    }
    catch(e) {
      dispatch(getFailure(e.toString()))
    }
  }
}

export const performRegisterInfosLogin = ({ paymentProcessUrl, prenom, telephone, email, password }) => {

  return async dispatch => {
    try {
      dispatch(registerInit('POST_INFOS_LOGIN'))

      const res = await helper.performAddAPICall(null, {
        paymentProcessUrl,
        prenom,
        telephone,
        email,
        password
      }, URL_register)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(registerSuccess(
        {
          ...json.datas,
          email,
          password,
          prenom,
          telephone
        }))
      else dispatch(registerFailure(json.message))
    }
    catch(e) {
      dispatch(registerFailure(e.toString()))
    }
  }
}

export const performRegisterInfosFacturation = ({ email, password, prenom, nom, adresse, code_postal, ville, pays }) => {

  return async dispatch => {
    try {
      dispatch(registerInit('POST_INFOS_FACTURATION'))

      const res = await helper.performAddAPICall(null, {
        email,
        password,
        prenom,
        nom,
        adresse,
        code_postal,
        ville,
        pays
      }, URL_register_adresse)
      const json = await res.json()

      if( res.status === 200 && !json.error ) dispatch(registerFacturationSuccess({
        ...json.datas,
        prenom,
        nom,
        adresse,
        code_postal,
        ville,
        pays
      }))
      else dispatch(registerFailure(json.message))
    }
    catch(e) {
      dispatch(registerFailure(e.toString()))
    }
  }
}

// Export action creators
const { init, registerInit, getProcessSuccess, registerSuccess, registerFacturationSuccess, getFailure, registerFailure } = registerSlice.actions

// Export reducer
export default registerSlice.reducer

// Selectors
export const registerStatusSelector = (state) => state.register.status
export const registerDatasSelector = (state) => state.register.datas