import { createSlice } from "@reduxjs/toolkit";


export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    status: {
      loading: false,
      hasError: false,
      errorMessage: ''
    },
    datas: {
      email: null,
      token: null,
      granted_modules: ''
    }
  },
  reducers: {
    /**
     * Login reducers
     */
    // no payload
    resetLoginInitialState: (state) => {
      performResetInitialState(state)
    },
    // no payload
    loginInit: (state) => {
      // Reset all states
      // Legacy to remember what I did in admin
      //resetUsersInitialState(state)

      state.status.loading = true
      state.status.hasError = false
      state.status.errorMessage = ''
    },
    // payload : { token, email }
    loginSuccess: (state, action) => {
      state.status.loading = false
      state.status.hasError = false

      state.datas.token = action.payload.token
      state.datas.email = action.payload.email
      state.datas.granted_modules = action.payload.granted_modules
    },
    // payload : errorMessage
    loginFailure: (state, action) => {
      state.status.loading = false
      state.status.hasError = true

      state.status.errorMessage = action.payload
      state.datas.email = ''
    },
    // no payload
    logout: (state) => {
      performResetInitialState(state)
    },
    // payload : { token, email }
    setAuth: (state, action) => {
      state.datas.token = action.payload.token
      state.datas.email = action.payload.email
      state.datas.granted_modules = action.payload.granted_modules
    }
  }
})

// Async thunks 
export const  performLogin = ({email, password}, successCallback) => {

  return async dispatch =>  {

    dispatch(loginInit())

    try {
      const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({email, password})
      }

      const res = await fetch(process.env.REACT_APP_REST_API_LOCATION + '/login', reqOptions)
      const data = await res.json()

      if( res.status === 200 ) {
        const { token, granted_modules } = data
        dispatch(loginSuccess({ token, email, granted_modules }))
        successCallback({ token, email, granted_modules })
      }
      else {
        dispatch(loginFailure(data.message))
      }
    }
    catch(e) {
      dispatch(loginFailure(e.toString()))
    }
  }
}

const performResetInitialState = (state) => {

  state.status = {
    loading: false,
    hasError: false,
    errorMessage: ''
  }

  state.datas = {
    email: null,
    token: null,
    granted_modules: ''
  }
}

// Export action creators
const { loginInit, loginSuccess, loginFailure } = loginSlice.actions

export const { resetLoginInitialState, logout, setAuth } = loginSlice.actions

// Export reducer
export default loginSlice.reducer

// Selectors
export const loginStatusSelector = (state) => state.login.status
export const loginDatasSelector = (state) => state.login.datas

