import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import SimpleError from "../../../toolsComponents/SimpleError";
import SimpleLoading from "../../../toolsComponents/SimpleLoading";
import UnProjetGenericWithStore from "../UnProjetGenericWithStore";
import UnProjetSimulationTravaux from "./UnProjetSimulationTravaux";
import {
  performGetUnDescriptif,
  editLogements,
  unProjetSimulationTravauxDatasSelector,
  changeLogements,
  performSyncUnDescriptif,
  performGenerate,
} from "./unProjetSimulationTravauxSlice";

const UnProjetSimulationTravauxWithStore = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["token", "email"]);

  const descriptif = useSelector(unProjetSimulationTravauxDatasSelector);

  const logements = useSelector(
    unProjetSimulationTravauxDatasSelector
  )?.logements;

  useEffect(() => {
    //TODO : link with real data
    dispatch(performGetUnDescriptif(cookies.token, id));
  }, [dispatch, cookies.token, id]);

  const handleChange = (index, lot, inputName, value) => {
    dispatch(editLogements({ index, lot, inputName, value }));
  };

  const handleChangeNbLogements = (nb) => {
    dispatch(changeLogements(nb));
  };

  const handleSave = () => {
    dispatch(performSyncUnDescriptif(cookies.token, descriptif));
  };

  return (
    <>
      <UnProjetGenericWithStore id={id} activeMenu="travaux" activeSubMenu="">
        <UnProjetSimulationTravaux
          id_projet={descriptif.id_projet}
          token={cookies.token}
          logements={logements}
          onChangeNbLogements={handleChangeNbLogements}
          onChange={handleChange}
          onSave={handleSave}
        />
        <br />
      </UnProjetGenericWithStore>
    </>
  );
};

export default UnProjetSimulationTravauxWithStore;
