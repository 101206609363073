import React, { useState } from 'react'
import SubmitForm from '../../toolsComponents/forms/SubmitForm'
import SelectVille from '../../toolsComponents/SelectVille'
import SimpleError from '../../toolsComponents/SimpleError'

const RechercheVille = ({ onAdd, errorMessage }) => {

  const [ mapsVille, setMapsVille ] = useState('')
  const [ clearError, setClearError ] = useState(true)

  const handleVilleChanged = (value) => {
    setMapsVille(value)
    setClearError(true)
  }

  const cleanVille = (ville) => {
    return (ville.replace("(","").replace(")","").replace(" ", "%20").normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
  }

  const handleSubmit = () => {
    onAdd(mapsVille)
    setClearError(false)
  }

  return (
    <>
      {/* Search and toggle */}
      <div className="row mb-4">
        <div className="col">
          <div className="input-group input-group-lg input-group-merge" id="searchbar-ville">
            <SelectVille onChange={handleVilleChanged} withFormGroup={false} value={mapsVille}/>
          </div>
        </div>

        <div className="col-auto" style={{marginTop:'4px'}}>
          <SubmitForm label="Cette ville m'intéresse, je souhaite l'étudier" isSticky={false} icon={false} onSubmit={handleSubmit} />
        </div>
      </div>

      {errorMessage && !clearError &&
      <div className="row">
        <div className="col">
          <SimpleError withHeader={false}>
            {errorMessage}
          </SimpleError>
        </div>
      </div>
      }

      <div className="row">
        <div className="col">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe title="Google Maps" width="100%" height="600" id="gmap_canvas" src={`https://maps.google.com/maps?q=${cleanVille(mapsVille)}%20France&t=k&z=13&ie=UTF8&iwloc=&output=embed`} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RechercheVille