import React, { useEffect } from 'react'
import { useState } from 'react'
import { convertChargesReduxToComponent, convertCreditReduxToComponent, convertEndettementReduxToComponent, convertInfosGeneralesReduxToComponent, convertRevenusReduxToComponent } from '../rentabiliteUtils'
import UnProjetRentabiliteWizardMenu from './UnProjetRentabilieWizardMenu'
import LeCredit from '../../../uneCalculatrice/SubModules/LeCredit'
import InformationsGenerales from '../../../uneCalculatrice/SubModules/InformationsGenerales'
import Revenus from '../../../uneCalculatrice/SubModules/Revenus'
import Charges from '../../../uneCalculatrice/SubModules/Charges'
import EvolutionTauxEndettement from '../../../uneCalculatrice/SubModules/EvolutionTauxEndettement'
import CashflowETRenta from '../../../uneCalculatrice/SubModules/CashflowETRenta'
import UnProjetRentabiliteFooter from './UnProjetRentabiliteWizardFooter'
import SimpleInfo from '../../../../toolsComponents/SimpleInfo'


const UnProjetRentabiliteWizard = ({ projet, onChangeInfosGenerales, onChangeCredit, onChangeRevenus, onChangeCharges, onChangeEndettement, onSubmit }) => {

  const STEPS = {
    INFOS_GENERALES: 1,
    CREDIT: 2,
    REVENUS: 3,
    CHARGES: 4,
    ENDETTEMENT: 5,
    TERMINE: 6
  }

  const [ step, setStep ] = useState(1)

  const [ infosGenerales, setInfosGenerales ] = useState({})
  const [ credit, setCredit ] = useState({})
  const [ revenus, setRevenus ] = useState({})
  const [ charges, setCharges ] = useState({})
  const [ endettement, setEndettement ] = useState({})

  useEffect(() => {
    if( projet && projet._id ) {
      setInfosGenerales(convertInfosGeneralesReduxToComponent(projet))
      setCredit(convertCreditReduxToComponent(projet))
      setRevenus(convertRevenusReduxToComponent(projet))
      setCharges(convertChargesReduxToComponent(projet))
      setEndettement(convertEndettementReduxToComponent(projet))
    }
  }, [projet])

  const handleStep = (step) => {
    setStep(step)
  }

  return <>
    <div className='col-12'>
      <SimpleInfo>
        Il n'y a encore aucune étude de rentabilité pour ce projet. Veuillez suivre les étapes afin d'avoir les résultats les plus précis possibles.
      </SimpleInfo>
    </div>

    <div className="col-12">

      <UnProjetRentabiliteWizardMenu 
        currentStep={step} 
        onStep={handleStep}
      /> 

      <div className='row'>
        {step === STEPS.INFOS_GENERALES &&
          <InformationsGenerales 
            initialState={infosGenerales}
            onChange={onChangeInfosGenerales}
          />
        }

        {step === STEPS.CREDIT &&
          <LeCredit 
            initialState={credit}
            infosGeneralesProjet={infosGenerales}
            onChange={onChangeCredit}
          />
        }

        {step === STEPS.REVENUS &&
          <Revenus 
            initialState={revenus}
            onChange={onChangeRevenus}
          />
        }

        {step === STEPS.CHARGES &&
          <Charges 
            initialState={charges}
            revenus={revenus}
            onChange={onChangeCharges}
          />
        }

        {step === STEPS.ENDETTEMENT &&
          <EvolutionTauxEndettement 
            initialState={endettement}
            infosGenerales={infosGenerales}
            leRevenus={revenus}
            leCredit={credit}
            onChange={onChangeEndettement}
          />
        }

        {step === STEPS.TERMINE &&
          <CashflowETRenta 
            initialState={{}}
            informationsGenerales={infosGenerales}
            revenus={revenus}
            leCredit={credit}
            charges={charges}
            noButton={true}
          />
        }
      </div>

      <hr className="my-5" />

      <UnProjetRentabiliteFooter 
        step={step}
        onStep={handleStep}
        onSubmit={onSubmit}
      />

    </div>
  </>
}

export default UnProjetRentabiliteWizard