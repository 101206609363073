import React from 'react'
import defined from '../../ressources/defined'
import MainTable from '../../toolsComponents/tables/MainTable'

const UnArtisanDevis = ( { artisan, devis, loading, pagination, filter, onSort, onSearch, onPagination, onAjouterDevis } ) => {

  const headers = [
    {
      display_name: 'Projet', 
      field: 'projet_titre', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Lot', 
      field: 'lot', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: "Montant total", 
      field: 'montant_total', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: "Status", 
      field: 'status', 
      sortable: true, 
      colSpan: 1
    },
    {
      display_name: 'Documents', 
      field: '', 
      sortable: false, 
      colSpan: 2
    }
  ]

  const datas = []

  devis.forEach((d) => {
    let result = [
      { // Projet
        value: d.projet_titre,
        url_avatar: d.projet_url_avatar,
        url: "/mes-projets/" + d.projet_id
      },
      { // Lot
        value: d.lot,
        url: '/artisan/'+artisan._id+'/devis/'+d._id
      },
      { // Montant total
        value: d.montant_total + ' €',
        url: '/artisan/'+artisan._id+'/devis/'+d._id
      },
      { // Status
        value: d.status,
        badge: getBadge(d.status)
      },
      { // Documents
        value: 'Télécharger',
        icon: 'download',
        url: "/jesaispas"
      }
    ]
    datas.push(result)
  })

  const handleHeadersSort = (field) => {
    onSort(field)
  }

  const handleSearch = (value) => {
    onSearch(value)
  }

  const handlePagination = (nbPerPage, page) => {
    onPagination(nbPerPage, page)
  }

  const handleAjouterDevisClick = () => {
    onAjouterDevis()
  }

  return (
    <div>
      {/* <!-- MODAL SUPPRIMER UN DEVIS --> */}
      <div className="modal fade" id="exampleModalDelOffre" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Supprimer une offre</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Etes-vous sûr de vouloir supprimer le devis pour : ... ?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="button" className="btn btn-danger">Supprimer</button>
            </div>
          </div>
        </div>
      </div>

      {/* Bouton ajouter un devis */}
      <div className="row">
        <div className="col">
        </div>

        <div className="col-auto">
          <a href={defined.ANCHOR} onClick={handleAjouterDevisClick} className="btn btn-primary d-block d-md-inline-block lift">
            <i className="fe fe-plus"></i> Ajouter un devis
          </a>
        </div>
      </div>

      <br />

      <div className="row">
        <div className="col-12">
          <MainTable
            headers={headers}
            datas={datas}
            loading={loading}
            pagination={pagination}
            onSort={handleHeadersSort}
            onPagination={handlePagination}
            onSearch={handleSearch}
            filter={filter}
          />
        </div>
      </div>
    </div>
  )
}

function getBadge(status) {
  switch(status) {
    case 'Accepté':
      return 'success'
    case 'En attente':
      return 'warning'
    case 'Refusé':
      return 'danger'
    default:
      return 'light'
  }
}

export default UnArtisanDevis