import React from 'react'
import Navigation from '../../toolsComponents/Navigation'

const NouvelleBanque = () => {

    return (
        <div>

            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <div className="container-fluid">
                    <div className="row justify-content-center">
                        
                        <div className="col-12 col-lg-10 col-xl-8">
                            {/* <!-- HEADER --> */}
                            <div className="header mt-md-5">
                            <div className="header-body">
                                <div className="row align-items-center">
                                <div className="col">

                                    {/* <!-- Pretitle --> */}
                                    <h6 className="header-pretitle">
                                    Nouveau
                                    </h6>

                                    {/* <!-- Title --> */}
                                    <h1 className="header-title">
                                    Ajouter une nouvelle banque
                                    </h1>

                                </div>
                                </div> {/* <!-- / .row --> */}
                            </div>
                            </div>
                        


                            <div className="row">

                                {/* <!-- FORMULAIRE --> */}
                                <div className="col-12 col-xl-12">

                                    <form className="mb-4">

                                        {/* <!-- Nom de l'artisan --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Nom de la banque
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control" />

                                        </div>

                                        {/* <!-- Description --> */}
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1">Description</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                        </div>

                                        <hr />

                                        {/* <!-- Adresse de l'artisan --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Adresse
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control"/>

                                        </div>

                                         {/* <!-- Ville et code postal --> */}
                                         <div className="row">
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Code postal
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" />

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Ville
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Téléphone et email --> */}
                                        <div className="row">

                                            {/* <!-- Numéro de téléphone --> */}
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Numéro de téléphone
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control"  />

                                                </div>

                                                </div>
                                            
                                            {/* <!-- Adresse e-mail --> */}
                                            <div className="col-12 col-md-6">
                                            
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Adresse e-mail
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        <div className="form-group">
                                            <div className="float-right">
                                                {/* <button type="submit" className="btn btn-primary mb-2">Ajouter</button> */}
                                                <a href="/mockups/banques/Credit-Agricole-Du-Languedoc" className="btn btn-primary ml-2">
                                                    Ajouter
                                                </a>
                                            </div>
                                        </div>

                                        <br /><br />
                                    </form>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NouvelleBanque