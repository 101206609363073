import React from 'react'
import Navigation from '../toolsComponents/Navigation'

const MesRendezVous = () => {

    return (
        <div>

            <Navigation granted_modules="ALL" />

            <div className="main-content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">

                            <br />

                            {/* TEEEEST */}
                            <div id='wrap'>

                                <div id='calendar'></div>

                                <div style={{clear:'both'}}></div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default MesRendezVous