import React, { useState } from 'react'
import InputForm from '../forms/InputForm'
import Image from '../images/Image'

/**
 * 
 * @param {onSubmit} photo object {_id, nom, ...} 
 * @returns 
 */
const UnePhotoCard = ({ photo, modalId, onClick, onSubmit, onDelete }) => {

  const [ nom, setNom ] = useState(photo.nom)

  const handleChangeNom = (e) => {
    setNom(e.value)
  }

  const handleSubmit = () => {
    onSubmit({
      ...photo,
      nom
    })
  }

  const handleDelete = () => {
    onDelete(photo)
  }

  const handleClick = () => {
    onClick(photo)
  }

  return (
    <>
      {/* <!-- Edit Modal --> */}
      <div className="modal fade" id={`edit-photo-${photo._id}`} tabindex="-1" role="dialog" aria-labelledby={`edit-photo-${photo._id}`} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`edit-photo-${photo._id}-label`}>Modifier</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <InputForm
                label="Nom de la photo"
                inputName="nom"
                value={nom}
                onChange={handleChangeNom}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="button" className="btn btn-primary" onClick={handleSubmit} data-dismiss="modal">Sauvegarder</button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Delete Modal --> */}
      <div className="modal fade" id={`del-photo-${photo._id}`} tabindex="-1" role="dialog" aria-labelledby={`del-photo-${photo._id}`} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`del-photo-${photo._id}-label`}>Supprimer</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Êtes-vous sûr de vouloir supprimer la photo : {photo.nom} ?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button type="button" className="btn btn-danger" onClick={handleDelete} data-dismiss="modal">Supprimer</button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        {/* <!-- Image --> */}
        <a href="#!" onClick={handleClick} data-toggle="modal" data-target={`#${modalId}`}>
          <Image 
            src={photo.url}
            alt={photo.nom}
            className="card-img-top" />
        </a>

        {/* <!-- Body --> */}
        <div className="card-body h-100">
          <div className="row align-items-center">
              <div className="col">
                {/* <!-- Title --> */}
                <h4 className="mb-2 name">
                  <a href="#!"  data-toggle="modal" data-target={`#${modalId}`} onClick={handleClick}>
                    {photo.nom}
                  </a>
                </h4>
              </div>
              <div className="col-auto">
                {/* <!-- Dropdown --> */}
                <div className="dropdown">
                  <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fe fe-more-vertical"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a href="#!" className="dropdown-item" data-toggle="modal" data-target={`#edit-photo-${photo._id}`}>
                      <i className="fe fe-edit-3"></i> Modifier
                    </a>
                    <a href="#!" className="dropdown-item" data-toggle="modal" data-target={`#del-photo-${photo._id}`}>
                      <i className="fe fe-trash-2"></i> Supprimer
                    </a>
                  </div>
                </div>
              </div>
          </div> {/* <!-- / .row --> */}
        </div>
      </div>
    </>
  )
}

export default UnePhotoCard