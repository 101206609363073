import React from 'react'

const SimpleInfo = ({ children, icon="info"}) => {

  return (
    <div className="alert alert-info" role="alert">
      <p style={{marginBottom: '0px'}}> {icon && <i className={`fe fe-${icon}`} style={{marginRight:"5px"}}></i>}  {children}</p>
    </div>
  )
}

export default SimpleInfo