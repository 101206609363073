import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnProjetHeader from './UnProjetHeader'

const UnProjetOrganisation = () => {

    return (
        <div>

               {/* <!-- MODALS
    ================================================== --> */}
    

    {/* <!-- Modal: Members --> */}
    <div className="modal fade" id="modalMembers" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-card card" data-list='{"valueNames": ["name"]}'>
            <div className="card-header">

              {/* <!-- Title --> */}
              <h4 className="card-header-title" id="exampleModalCenterTitle">
                Add a member
              </h4>

              {/* <!-- Close --> */}
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>

            </div>
            <div className="card-header">

              {/* <!-- Form --> */}
              <form>
                <div className="input-group input-group-flush input-group-merge">

                  {/* <!-- Input --> */}
                  <input type="search" className="form-control form-control-prepended list-search" placeholder="Search" />

                  {/* <!-- Prepend --> */}
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <span className="fe fe-search"></span>
                    </div>
                  </div>

                </div>
              </form>

            </div>
            <div className="card-body">

              {/* <!-- List group --> */}
              <ul className="list-group list-group-flush list my-n3">
                <li className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <a href="./profile-posts.html" className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-5.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="mb-1 name">
                        <a href="./profile-posts.html">Miyah Myles</a>
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small mb-0">
                        <span className="text-success">●</span> Online
                      </p>

                    </div>
                    <div className="col-auto">

                      {/* <!-- Button --> */}
                      <a href="#!" className="btn btn-sm btn-white">
                        Add
                      </a>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </li>
                <li className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <a href="./profile-posts.html" className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-6.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="mb-1 name">
                        <a href="./profile-posts.html">Ryu Duke</a>
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small mb-0">
                        <span className="text-success">●</span> Online
                      </p>

                    </div>
                    <div className="col-auto">

                      {/* <!-- Button --> */}
                      <a href="#!" className="btn btn-sm btn-white">
                        Add
                      </a>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </li>
                <li className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <a href="./profile-posts.html" className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-7.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="mb-1 name">
                        <a href="./profile-posts.html">Glen Rouse</a>
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small mb-0">
                        <span className="text-warning">●</span> Busy
                      </p>

                    </div>
                    <div className="col-auto">

                      {/* <!-- Button --> */}
                      <a href="#!" className="btn btn-sm btn-white">
                        Add
                      </a>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </li>
                <li className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <a href="./profile-posts.html" className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-8.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="mb-1 name">
                        <a href="./profile-posts.html">Grace Gross</a>
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small mb-0">
                        <span className="text-danger">●</span> Offline
                      </p>

                    </div>
                    <div className="col-auto">

                      {/* <!-- Button --> */}
                      <a href="#!" className="btn btn-sm btn-white">
                        Add
                      </a>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>


    {/* <!-- Modal: Search --> */}
    <div className="modal fade" id="sidebarModalSearch" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-vertical" role="document">
        <div className="modal-content">
          <div className="modal-body" data-list='{"valueNames": ["name"]}'>

            {/* <!-- Form --> */}
            <form className="mb-4">
              <div className="input-group input-group-merge input-group-rounded">

                {/* <!-- Input --> */}
                <input type="search" className="form-control form-control-prepended list-search" placeholder="Search" />

                {/* <!-- Prepend --> */}
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <span className="fe fe-search"></span>
                  </div>
                </div>

              </div>
            </form>

            {/* <!-- List group --> */}
            <div className="my-n3">
              <div className="list-group list-group-flush list-group-focus list">
                <a className="list-group-item" href="./team-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <div className="avatar">
                        <img src="./assets/img/avatars/teams/team-logo-1.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="text-body text-focus mb-1 name">
                        Airbnb
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time datetime="2018-05-24">Updated 2hr ago</time>
                      </p>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </a>
                <a className="list-group-item" href="./team-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <div className="avatar">
                        <img src="./assets/img/avatars/teams/team-logo-2.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="text-body text-focus mb-1 name">
                        Medium Corporation
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time datetime="2018-05-24">Updated 2hr ago</time>
                      </p>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </a>
                <a className="list-group-item" href="./project-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <div className="avatar avatar-4by3">
                        <img src="./assets/img/avatars/projects/project-1.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="text-body text-focus mb-1 name">
                        Homepage Redesign
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time datetime="2018-05-24">Updated 4hr ago</time>
                      </p>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </a>
                <a className="list-group-item" href="./project-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <div className="avatar avatar-4by3">
                        <img src="./assets/img/avatars/projects/project-2.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="text-body text-focus mb-1 name">
                        Travels & Time
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time datetime="2018-05-24">Updated 4hr ago</time>
                      </p>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </a>
                <a className="list-group-item" href="./project-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <div className="avatar avatar-4by3">
                        <img src="./assets/img/avatars/projects/project-3.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="text-body text-focus mb-1 name">
                        Safari Exploration
                      </h4>

                      {/* <!-- Time --> */}
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time datetime="2018-05-24">Updated 4hr ago</time>
                      </p>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </a>
                <a className="list-group-item" href="./profile-posts.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <div className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." className="avatar-img rounded-circle" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="text-body text-focus mb-1 name">
                        Dianna Smiley
                      </h4>

                      {/* <!-- Status --> */}
                      <p className="text-body small mb-0">
                        <span className="text-success">●</span> Online
                      </p>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </a>
                <a className="list-group-item" href="./profile-posts.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <div className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Title --> */}
                      <h4 className="text-body text-focus mb-1 name">
                        Ab Hadley
                      </h4>

                      {/* <!-- Status --> */}
                      <p className="text-body small mb-0">
                        <span className="text-danger">●</span> Offline
                      </p>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    {/* <!-- Modal: Activity --> */}
    <div className="modal fade" id="sidebarModalActivity" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-vertical" role="document">
        <div className="modal-content">
          <div className="modal-header">

            {/* <!-- Title --> */}
            <h4 className="modal-title">
              Notifications
            </h4>

            {/* <!-- Navs --> */}
            <ul className="nav nav-tabs nav-tabs-sm modal-header-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#modalActivityAction">Action</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#modalActivityUser">User</a>
              </li>
            </ul>

          </div>
          <div className="modal-body">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="modalActivityAction">

                {/* <!-- List group --> */}
                <div className="list-group list-group-flush list-group-activity my-n3">
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-mail"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Launchday 1.4.0 update email sent
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Sent to all 1,851 subscribers over a 24 hour period
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-archive"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          New project "Goodkit" created
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Looks like there might be a new theme soon.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-code"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Dashkit 1.5.0 was deployed.
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          A successful to deploy to production was executed.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-git-branch"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          "Update Dependencies" branch was created.
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          This branch was created off of the "master" branch.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-mail"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Launchday 1.4.0 update email sent
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Sent to all 1,851 subscribers over a 24 hour period
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-archive"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          New project "Goodkit" created
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Looks like there might be a new theme soon.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-code"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Dashkit 1.5.0 was deployed.
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          A successful to deploy to production was executed.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-git-branch"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          "Update Dependencies" branch was created.
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          This branch was created off of the "master" branch.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-mail"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Launchday 1.4.0 update email sent
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Sent to all 1,851 subscribers over a 24 hour period
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-archive"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          New project "Goodkit" created
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Looks like there might be a new theme soon.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-code"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Dashkit 1.5.0 was deployed.
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          A successful to deploy to production was executed.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-git-branch"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          "Update Dependencies" branch was created.
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          This branch was created off of the "master" branch.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                </div>

              </div>
              <div className="tab-pane fade" id="modalActivityUser">

                {/* <!-- List group --> */}
                <div className="list-group list-group-flush list-group-activity my-n3">
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Dianna Smiley
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files "Launchday Logo" and "New Design".
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Ab Hadley
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Shared the "Why Dashkit?" post with 124 subscribers.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          1h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-offline">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Adolfo Hess
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Exported sales data from Launchday's subscriber data.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          3h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Dianna Smiley
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files "Launchday Logo" and "New Design".
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Ab Hadley
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Shared the "Why Dashkit?" post with 124 subscribers.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          1h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-offline">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Adolfo Hess
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Exported sales data from Launchday's subscriber data.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          3h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Dianna Smiley
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files "Launchday Logo" and "New Design".
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Ab Hadley
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Shared the "Why Dashkit?" post with 124 subscribers.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          1h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-offline">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Adolfo Hess
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Exported sales data from Launchday's subscriber data.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          3h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Dianna Smiley
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files "Launchday Logo" and "New Design".
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Ab Hadley
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Shared the "Why Dashkit?" post with 124 subscribers.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          1h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm avatar-offline">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Adolfo Hess
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Exported sales data from Launchday's subscriber data.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          3h ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    {/* <!-- Modal: Kanban task --> */}
    <div className="modal fade" id="modalKanbanTask" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-lighter">
          <div className="modal-body">

            {/* <!-- Header --> */}
            <div className="row">
              <div className="col">

                {/* <!-- Prettitle --> */}
                <h6 className="text-uppercase text-muted mb-3">
                  <a href="#!" className="text-reset">How to Use Kanban</a>
                </h6>

                {/* <!-- Title --> */}
                <h2 className="mb-2">
                  Update Dashkit to include new components!
                </h2>

                {/* <!-- Subtitle --> */}
                <p className="text-muted mb-0">
                  This is a description of this task. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum magna nisi, ultrices ut pharetra eget.
                </p>

              </div>
              <div className="col-auto">

                {/* <!-- Close --> */}
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    &times;
                  </span>
                </button>

              </div>
            </div> {/* <!-- / .row --> */}

            {/* <!-- Divider --> */}
            <hr className="my-4" />

            {/* <!-- Buttons --> */}
            <div className="mb-4">
              <div className="row">
                <div className="col">

                  {/* <!-- Reaction --> */}
                  <a href="#!" className="btn btn-sm btn-white">
                    😬 1
                  </a>
                  <a href="#!" className="btn btn-sm btn-white">
                    👍 2
                  </a>
                  <a href="#!" className="btn btn-sm btn-white">
                    Add Reaction
                  </a>

                </div>
                <div className="col-auto mr-n3">

                  {/* <!-- Avatar group --> */}
                  <div className="avatar-group d-none d-sm-flex">
                    <a href="./profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                      <img src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle" />
                    </a>
                    <a href="./profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                      <img src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." className="avatar-img rounded-circle" />
                    </a>
                    <a href="./profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                      <img src="./assets/img/avatars/profiles/avatar-4.jpg" alt="..." className="avatar-img rounded-circle" />
                    </a>
                    <a href="./profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Myles">
                      <img src="./assets/img/avatars/profiles/avatar-5.jpg" alt="..." className="avatar-img rounded-circle" />
                    </a>
                  </div>

                </div>
                <div className="col-auto">

                  {/* <!-- Button --> */}
                  <a href="#!" className="btn btn-sm btn-white">
                    Share
                  </a>

                </div>
              </div> {/* <!-- / .row --> */}
            </div>

            {/* <!-- Card --> */}
            <div className="card">
              <div className="card-header">

                {/* <!-- Title --> */}
                <h4 className="card-header-title">
                  Files
                </h4>

                {/* <!-- Button --> */}
                <a href="#!" className="btn btn-sm btn-white">
                  Add files
                </a>

              </div>
              <div className="card-body">
                <div className="list-group list-group-flush my-n3">
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <a href="./project-overview.html" className="avatar">
                          <img src="./assets/img/files/file-1.jpg" alt="..." className="avatar-img rounded" />
                        </a>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Title --> */}
                        <h4 className="mb-1">
                          <a href="./project-overview.html">Launchday logo</a>
                        </h4>

                        {/* <!-- Time --> */}
                        <p className="card-text small text-muted">
                          1.5mb PNG Dave
                        </p>

                      </div>
                      <div className="col-auto">

                        {/* <!-- Dropdown --> */}
                        <div className="dropdown">
                          <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#!" className="dropdown-item">
                              Action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Another action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Something else here
                            </a>
                          </div>
                        </div>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <a href="./project-overview.html" className="avatar">
                          <img src="./assets/img/files/file-1.jpg" alt="..." className="avatar-img rounded" />
                        </a>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Title --> */}
                        <h4 className="mb-1">
                          <a href="./project-overview.html">Launchday logo</a>
                        </h4>

                        {/* <!-- Time --> */}
                        <p className="card-text small text-muted">
                          1.5mb PNG Dave
                        </p>

                      </div>
                      <div className="col-auto">

                        {/* <!-- Dropdown --> */}
                        <div className="dropdown">
                          <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#!" className="dropdown-item">
                              Action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Another action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Something else here
                            </a>
                          </div>
                        </div>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </div>
                </div>

              </div>
            </div>

            {/* <!-- Card --> */}
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-auto">

                    {/* <!-- Avatar --> */}
                    <div className="avatar avatar-sm">
                      <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                    </div>

                  </div>
                  <div className="col ml-n2">

                    {/* <!-- Form --> */}
                    <form className="mt-1">
                      <textarea className="form-control form-control-flush form-control" data-toggle="autosize" rows="1" placeholder="Leave a comment"></textarea>
                    </form>

                  </div>
                  <div className="col-auto align-self-end">

                    {/* <!-- Icons --> */}
                    <div className="text-muted mb-2">
                      <a href="#!" className="text-reset mr-3">
                        <i className="fe fe-camera"></i>
                      </a>
                      <a href="#!" className="text-reset mr-3">
                        <i className="fe fe-paperclip"></i>
                      </a>
                      <a href="#!" className="text-reset">
                        <i className="fe fe-mic"></i>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
              <div className="card-body">

                {/* <!-- Comments --> */}
                <div className="comment mb-3">
                  <div className="row">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <a className="avatar avatar-sm" href="./profile-posts.html">
                        <img src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Body --> */}
                      <div className="comment-body">

                        <div className="row">
                          <div className="col">

                            {/* <!-- Title --> */}
                            <h5 className="comment-title">
                              Ab Hadley
                            </h5>

                          </div>
                          <div className="col-auto">

                            {/* <!-- Time --> */}
                            <time className="comment-time">
                              11:12
                            </time>

                          </div>
                        </div> {/* <!-- / .row --> */}

                        {/* <!-- Text --> */}
                        <p className="comment-text">
                          Looking good Dianna! I like the image grid on the left, but it feels like a lot to process and doesn't really <em>show</em> me what the product does? I think using a short looping video or something similar demo'ing the product might be better?
                        </p>

                      </div>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </div>
                <div className="comment">
                  <div className="row">
                    <div className="col-auto">

                      {/* <!-- Avatar --> */}
                      <a className="avatar avatar-sm" href="./profile-posts.html">
                        <img src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      {/* <!-- Body --> */}
                      <div className="comment-body">

                        <div className="row">
                          <div className="col">

                            {/* <!-- Title --> */}
                            <h5 className="comment-title">
                              Adolfo Hess
                            </h5>

                          </div>
                          <div className="col-auto">

                            {/* <!-- Time --> */}
                            <time className="comment-time">
                              11:12
                            </time>

                          </div>
                        </div> {/* <!-- / .row --> */}

                        {/* <!-- Text --> */}
                        <p className="comment-text">
                          Any chance you're going to link the grid up to a public gallery of sites built with Launchday?
                        </p>

                      </div>

                    </div>
                  </div> {/* <!-- / .row --> */}
                </div>

              </div>
            </div>

            {/* <!-- Card --> */}
            <div className="card mb-0">
              <div className="card-header">

                {/* <!-- Title --> */}
                <h4 className="card-header-title">
                  Activity
                </h4>

              </div>
              <div className="card-body">
                <div className="list-group list-group-flush list-group-activity my-n3">
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Johnathan Goldstein
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files “Launchday Logo” and “Revisiting the Past”.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </div>
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Johnathan Goldstein
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files “Launchday Logo” and “Revisiting the Past”.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </div>
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-auto">

                        {/* <!-- Avatar --> */}
                        <div className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        {/* <!-- Heading --> */}
                        <h5 className="mb-1">
                          Johnathan Goldstein
                        </h5>

                        {/* <!-- Text --> */}
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files “Launchday Logo” and “Revisiting the Past”.
                        </p>

                        {/* <!-- Time --> */}
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> {/* <!-- / .row --> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    {/* <!-- Modal: Kanban task --> */}
    <div className="modal fade" id="modalKanbanTaskEmpty" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-lighter">
          <div className="modal-body">

            {/* <!-- Header --> */}
            <div className="row">
              <div className="col">

                {/* <!-- Prettitle --> */}
                <h6 className="text-uppercase text-muted mb-3">
                  <a href="#!" className="text-reset">How to Use Kanban</a>
                </h6>

                {/* <!-- Title --> */}
                <h2 className="mb-2">
                  Update Dashkit to include new components!
                </h2>

                {/* <!-- Subtitle --> */}
                <textarea className="form-control form-control-flush form-control-auto" data-toggle="autosize" rows="1" placeholder="Add a description..."></textarea>

              </div>
              <div className="col-auto">

                {/* <!-- Close --> */}
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    &times;
                  </span>
                </button>

              </div>
            </div> {/* <!-- / .row --> */}

            {/* <!-- Divider --> */}
            <hr className="my-4" />

            {/* <!-- Buttons --> */}
            <div className="mb-4">
              <div className="row">
                <div className="col">

                  {/* <!-- Button --> */}
                  <a href="#!" className="btn btn-sm btn-white">
                    Add Reaction
                  </a>

                </div>
                <div className="col-auto">

                  {/* <!-- Button --> */}
                  <a href="#!" className="btn btn-sm btn-white">
                    Share
                  </a>

                </div>
              </div> {/* <!-- / .row --> */}
            </div>

            {/* <!-- Card --> */}
            <div className="card">
              <div className="card-body">
                <div className="dropzone dropzone-multiple" data-toggle="dropzone" data-options='{"url": "https://"}'>

                  {/* <!-- Fallback --> */}
                  <div className="fallback">
                    <div className="custom-file">
                      <input type="file" className="custom-file-input" id="customFileUpload" multiple />
                      <label className="custom-file-label" htmlFor="customFileUpload">Choose file</label>
                    </div>
                  </div>

                  {/* <!-- Preview --> */}
                  <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
                    <li className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col-auto">

                          {/* <!-- Image --> */}
                          <div className="avatar">
                            <img className="avatar-img rounded" src="data:image/svg+xml,%3csvg3c/svg%3e" alt="..." data-dz-thumbnail />
                          </div>

                        </div>
                        <div className="col ml-n3">

                          {/* <!-- Heading --> */}
                          <h4 className="mb-1" data-dz-name>...</h4>

                          {/* <!-- Text --> */}
                          <small className="text-muted" data-dz-size></small>

                        </div>
                        <div className="col-auto">

                          {/* <!-- Dropdown --> */}
                          <div className="dropdown">
                            <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fe fe-more-vertical"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a href="#!" className="dropdown-item" data-dz-remove>
                                Remove
                              </a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>

            {/* <!-- Card --> */}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">

                    {/* <!-- Avatar --> */}
                    <div className="avatar avatar-sm">
                      <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                    </div>

                  </div>
                  <div className="col ml-n2">

                    {/* <!-- Form --> */}
                    <form className="mt-1">
                      <textarea className="form-control form-control-flush" data-toggle="autosize" rows="1" placeholder="Leave a comment"></textarea>
                    </form>

                  </div>
                  <div className="col-auto align-self-end">

                    {/* <!-- Icons --> */}
                    <div className="text-muted mb-2">
                      <a href="#!" className="text-reset mr-3">
                        <i className="fe fe-camera"></i>
                      </a>
                      <a href="#!" className="text-reset mr-3">
                        <i className="fe fe-paperclip"></i>
                      </a>
                      <a href="#!" className="text-reset">
                        <i className="fe fe-mic"></i>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


            <Navigation granted_modules="ALL" />

            <div className="main-content">
                
                <UnProjetHeader isActive="organisation" />

                {/* <!-- CONTENT --> */}
      <div className="tab-content">
        <div className="tab-pane fade show active" id="kanbanColumns" role="tabpanel" aria-labelledby="kanbanColumnsToggle">
          <div className="container-fluid kanban-container">
            <div className="row">
              <div className="col-12">

                {/* <!-- Card --> */}
                <div className="card">
                  <div className="card-header card-header-flush">

                    {/* <!-- Title --> */}
                    <h4 className="card-header-title">
                      How to Use Kanban
                    </h4>

                    {/* <!-- Dropdown --> */}
                    <div className="dropdown">
                      <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical"></i>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>

                  </div>
                  <div className="card-body">

                    {/* <!-- Category --> */}
                    <div className="kanban-category">

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>🤚 Drag me</strong> around to move me within this list of between lists.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>👆 Click me</strong> to open a modal that shows all the description, comments, files, and activity associated with this card.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTaskEmpty">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>🐣 Click me</strong> to see that same modal, but for a task that is brand new and doesn't have activity or files.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>👇 Click "Add Card"</strong> at the bottom of this column to see what creating a new card looks like. You can even add a date.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>👉 Click "Add List"</strong> past the last column on the right to see what it looks like to name a new column.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>⚙️ Click the grid/list toggle</strong> in the top right of this page to see what these tasks look like organized as a draggable list!
                            </p>

                          </div>
                        </div>
                      </div>

                    </div>

                    {/* <!-- Add --> */}
                    <div className="kanban-add">
                      <div className="card card-sm card-flush mb-0">
                        <div className="card-body">

                          {/* <!-- Link --> */}
                          <div className="text-center">
                            <a className="kanban-add-link" href="#!">Add Card</a>
                          </div>

                          {/* <!-- Form --> */}
                          <form className="kanban-add-form d-none">

                            {/* <!-- Input --> */}
                            <div className="form-group">
                              <textarea className="form-control form-control-flush form-control-auto" placeholder="Draft your card"></textarea>
                            </div>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col d-flex align-items-center">

                                {/* <!-- Icon --> */}
                                <span className="text-muted small mr-2">
                                  <i className="fe fe-clock"></i>
                                </span>

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-sm form-control-flush form-control-auto text-muted" placeholder="No due date" />

                              </div>
                              <div className="col-auto">

                                {/* <!-- Buttons --> */}
                                <button className="btn btn-sm btn-white" type="reset">
                                  Cancel
                                </button>
                                <button className="btn btn-sm btn-primary" type="submit">
                                  Add
                                </button>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </form>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div className="col-12">

                {/* <!-- Card --> */}
                <div className="card">
                  <div className="card-header card-header-flush">

                    {/* <!-- Title --> */}
                    <h4 className="card-header-title">
                      Release 1.1.0
                    </h4>

                    {/* <!-- Dropdown --> */}
                    <div className="dropdown">
                      <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical"></i>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>

                  </div>
                  <div className="card-body">

                    {/* <!-- Category --> */}
                    <div className="kanban-category">

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Create the release notes for the new pages so customers are aware.
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-clock"></i> Jun 9
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                                    <img src="assets/img/avatars/profiles/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">

                          {/* <!-- Image --> */}
                          <img src="assets/img/kanban/kanban-2.jpg" alt="..." className="card-img-top" />

                          {/* <!-- Body --> */}
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Finish the design for blog listings and articles, including mixed media
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-message-circle"></i> 23 <i className="fe fe-clock ml-3"></i> Sep 9
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                                    <img src="assets/img/avatars/profiles/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                                    <img src="assets/img/avatars/profiles/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                    <img src="assets/img/avatars/profiles/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>

                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTaskEmpty">
                          <div className="card-body">

                            {/* <!-- Badge --> */}
                            <p className="mb-3">
                              <span className="badge badge-soft-success">
                                Reviewed
                              </span>
                            </p>

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              Clear all the deprecation warnings for out of date NPM packages
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Review all the indentation changes to be sure they will resolve all customer issues
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-clock"></i> Jun 11
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Miles">
                                    <img src="assets/img/avatars/profiles/avatar-5.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>
                        </div>
                      </div>

                    </div>

                    {/* <!-- Add --> */}
                    <div className="kanban-add">
                      <div className="card card-sm card-flush mb-0">
                        <div className="card-body">

                          {/* <!-- Link --> */}
                          <div className="text-center">
                            <a className="kanban-add-link" href="#!">Add Card</a>
                          </div>

                          {/* <!-- Form --> */}
                          <form className="kanban-add-form d-none">

                            {/* <!-- Input --> */}
                            <div className="form-group">
                              <textarea className="form-control form-control-flush form-control-auto" placeholder="Draft your card"></textarea>
                            </div>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col d-flex align-items-center">

                                {/* <!-- Icon --> */}
                                <span className="text-muted small mr-2">
                                  <i className="fe fe-clock"></i>
                                </span>

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-sm form-control-flush form-control-auto text-muted" data-toggle="flatpickr" data-options='{"dateFormat": "M j"}' placeholder="No due date" />

                              </div>
                              <div className="col-auto">

                                {/* <!-- Buttons --> */}
                                <button className="btn btn-sm btn-white" type="reset">
                                  Cancel
                                </button>
                                <button className="btn btn-sm btn-primary" type="submit">
                                  Add
                                </button>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </form>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div className="col-12">

                {/* <!-- Card --> */}
                <div className="card">
                  <div className="card-header card-header-flush">

                    {/* <!-- Title --> */}
                    <h4 className="card-header-title">
                      Next Up
                    </h4>

                    {/* <!-- Dropdown --> */}
                    <div className="dropdown">
                      <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical"></i>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>

                  </div>
                  <div className="card-body">

                    {/* <!-- Category --> */}
                    <div className="kanban-category">

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Design portfolio pages and a case study focused page
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-clock"></i> Jul 6
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ryu Duke">
                                    <img src="assets/img/avatars/profiles/avatar-6.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              Create a screenshot component extension for highlighting hotspots with popover hovers
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">

                          {/* <!-- Image --> */}
                          <img src="assets/img/kanban/kanban-1.jpg" alt="..." className="card-img-top" />

                          {/* <!-- Body --> */}
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Include an even larger library of illustrations that we can provide in SVG that cover more use cases
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-message-circle"></i> 23 <i className="fe fe-clock ml-3"></i> Sep 9
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                                    <img src="assets/img/avatars/profiles/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                                    <img src="assets/img/avatars/profiles/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                    <img src="assets/img/avatars/profiles/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>

                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              Create basic auth pages (login, sign up, forgot password) even though we don't have any settings yet
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Code up the utility pages for basic errors and failure states
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-clock"></i> Jul 12
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                                    <img src="assets/img/avatars/profiles/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>
                        </div>
                      </div>

                    </div>

                    {/* <!-- Add --> */}
                    <div className="kanban-add">
                      <div className="card card-sm card-flush mb-0">
                        <div className="card-body">

                          {/* <!-- Link --> */}
                          <div className="text-center">
                            <a className="kanban-add-link" href="#!">Add Card</a>
                          </div>

                          {/* <!-- Form --> */}
                          <form className="kanban-add-form d-none">

                            {/* <!-- Input --> */}
                            <div className="form-group">
                              <textarea className="form-control form-control-flush form-control-auto" placeholder="Draft your card"></textarea>
                            </div>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col d-flex align-items-center">

                                {/* <!-- Icon --> */}
                                <span className="text-muted small mr-2">
                                  <i className="fe fe-clock"></i>
                                </span>

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-sm form-control-flush form-control-auto text-muted" data-toggle="flatpickr" data-options='{"dateFormat": "M j"}' placeholder="No due date" />

                              </div>
                              <div className="col-auto">

                                {/* <!-- Buttons --> */}
                                <button className="btn btn-sm btn-white" type="reset">
                                  Cancel
                                </button>
                                <button className="btn btn-sm btn-primary" type="submit">
                                  Add
                                </button>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </form>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div className="col-12">

                {/* <!-- Card --> */}
                <div className="card">
                  <div className="card-header card-header-flush">

                    {/* <!-- Title --> */}
                    <h4 className="card-header-title">
                      Release 1.1.0
                    </h4>

                    {/* <!-- Dropdown --> */}
                    <div className="dropdown">
                      <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fe fe-more-vertical"></i>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a href="#!" className="dropdown-item">
                          Action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Another action
                        </a>
                        <a href="#!" className="dropdown-item">
                          Something else here
                        </a>
                      </div>
                    </div>

                  </div>
                  <div className="card-body">

                    {/* <!-- Category --> */}
                    <div className="kanban-category">

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Create the release notes for the new pages so customers are aware.
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-clock"></i> Jun 9
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                                    <img src="assets/img/avatars/profiles/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">

                          {/* <!-- Image --> */}
                          <img src="assets/img/kanban/kanban-2.jpg" alt="..." className="card-img-top" />

                          {/* <!-- Body --> */}
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Finish the design for blog listings and articles, including mixed media
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-message-circle"></i> 23 <i className="fe fe-clock ml-3"></i> Sep 9
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                                    <img src="assets/img/avatars/profiles/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                                    <img src="assets/img/avatars/profiles/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                    <img src="assets/img/avatars/profiles/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>

                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTaskEmpty">
                          <div className="card-body">

                            {/* <!-- Badge --> */}
                            <p className="mb-3">
                              <span className="badge badge-soft-success">
                                Reviewed
                              </span>
                            </p>

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              Clear all the deprecation warnings for out of date NPM packages
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p>
                              Review all the indentation changes to be sure they will resolve all customer issues
                            </p>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted">
                                  <i className="fe fe-clock"></i> Jun 11
                                </p>

                              </div>
                              <div className="col-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Miles">
                                    <img src="assets/img/avatars/profiles/avatar-5.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </div>
                        </div>
                      </div>

                    </div>

                    {/* <!-- Add --> */}
                    <div className="kanban-add">
                      <div className="card card-sm card-flush mb-0">
                        <div className="card-body">

                          {/* <!-- Link --> */}
                          <div className="text-center">
                            <a className="kanban-add-link" href="#!">Add Card</a>
                          </div>

                          {/* <!-- Form --> */}
                          <form className="kanban-add-form d-none">

                            {/* <!-- Input --> */}
                            <div className="form-group">
                              <textarea className="form-control form-control-flush form-control-auto" placeholder="Draft your card"></textarea>
                            </div>

                            {/* <!-- Footer --> */}
                            <div className="row align-items-center">
                              <div className="col d-flex align-items-center">

                                {/* <!-- Icon --> */}
                                <span className="text-muted small mr-2">
                                  <i className="fe fe-clock"></i>
                                </span>

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-sm form-control-flush form-control-auto text-muted" data-toggle="flatpickr" data-options='{"dateFormat": "M j"}' placeholder="No due date" />

                              </div>
                              <div className="col-auto">

                                {/* <!-- Buttons --> */}
                                <button className="btn btn-sm btn-white" type="reset">
                                  Cancel
                                </button>
                                <button className="btn btn-sm btn-primary" type="submit">
                                  Add
                                </button>

                              </div>
                            </div> {/* <!-- / .row --> */}

                          </form>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div className="col-12">

                {/* <!-- Card --> */}
                <div className="kanban-add">
                  <div className="card mb-0">
                    <div className="card-body">

                      {/* <!-- Link --> */}
                      <div className="text-center">
                        <a className="kanban-add-link" href="#!">Add list</a>
                      </div>

                      {/* <!-- Form --> */}
                      <form className="kanban-add-form d-none">
                        <div className="d-flex align-items-center">

                          {/* <!-- Input --> */}
                          <input type="text" className="form-control form-control-flush form-control-auto h4" placeholder="Name your list" />

                          {/* <!-- Close --> */}
                          <button className="close close-sm ml-3" type="reset">
                            <i className="fe fe-x"></i>
                          </button>

                        </div>
                      </form>

                    </div>
                  </div>
                </div>

              </div>
            </div> {/* <!-- / .row --> */}
          </div>
        </div>
        <div className="tab-pane fade" id="kanbanList" role="tabpanel" aria-labelledby="kanbanListToggle">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

                {/* <!-- List group --> */}
                <div className="list-group list-group-lg list-group-flush">
                  <div className="list-group-item">

                    {/* <!-- Header --> */}
                    <div className="row align-items-center mb-4">
                      <div className="col">

                        {/* <!-- Heading --> */}
                        <h2 className="mb-0">How to Use Kanban</h2>

                      </div>
                      <div className="col-auto">

                        {/* <!-- Drodpown --> */}
                        <div className="dropdown">
                          <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#!" className="dropdown-item">
                              Action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Another action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Something else here
                            </a>
                          </div>
                        </div>

                      </div>
                    </div> {/* <!-- / .row --> */}

                    {/* <!-- Category --> */}
                    <div className="kanban-category">

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>🤚 Drag me</strong> around to move me within this list of between lists.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>👆 Click me</strong> to open a modal that shows all the description, comments, files, and activity associated with this card.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTaskEmpty">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>🐣 Click me</strong> to see that same modal, but for a task that is brand new and doesn't have activity or files.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>👇 Click "Add Card"</strong> at the bottom of this section to see what creating a new card looks like. You can even add a date.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>👇 Click "Add List"</strong> past the last list section at the bottom of this page to see what it looks like to name a new list.
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              <strong>⚙️ Click the grid/column toggle</strong> in the top right of this page to see what these tasks look like organized as a draggable column!
                            </p>

                          </div>
                        </div>
                      </div>

                    </div>

                    {/* <!-- Add --> */}
                    <div className="kanban-add">
                      <div className="card card-sm card-flush mb-0">
                        <div className="card-body">

                          {/* <!-- Link --> */}
                          <a className="kanban-add-link" href="#!">+ Add Card</a>

                          {/* <!-- Form --> */}
                          <form className="kanban-add-form d-none">
                            <div className="row align-items-center">
                              <div className="col-12 col-md">

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-flush form-control-auto" placeholder="Draft your card" />

                              </div>
                              <div className="col-6 col-md-auto d-flex align-items-center">

                                {/* <!-- Icon --> */}
                                <span className="text-muted small mr-2">
                                  <i className="fe fe-clock"></i>
                                </span>

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-sm form-control-flush form-control-auto text-muted" data-toggle="flatpickr" data-options='{"dateFormat": "M j"}' placeholder="No due date" />

                              </div>
                              <div className="col-6 col-md-auto">

                                {/* <!-- Buttons --> */}
                                <button className="btn btn-sm btn-white" type="reset">
                                  Cancel
                                </button>
                                <button className="btn btn-sm btn-primary" type="submit">
                                  Add
                                </button>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </form>

                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="list-group-item">

                    {/* <!-- Header --> */}
                    <div className="row align-items-center mb-4">
                      <div className="col">

                        {/* <!-- Heading --> */}
                        <h2 className="mb-0">Release 1.1.0</h2>

                      </div>
                      <div className="col-auto">

                        {/* <!-- Drodpown --> */}
                        <div className="dropdown">
                          <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#!" className="dropdown-item">
                              Action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Another action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Something else here
                            </a>
                          </div>
                        </div>

                      </div>
                    </div> {/* <!-- / .row --> */}

                    {/* <!-- Category --> */}
                    <div className="kanban-category">

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-12 col-sm">

                                {/* <!-- Body --> */}
                                <p className="mb-sm-0">
                                  Create the release notes for the new pages so customers are aware.
                                </p>

                              </div>
                              <div className="col col-sm-auto">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted mb-0">
                                  <i className="fe fe-clock"></i> Jun 9
                                </p>

                              </div>
                              <div className="col-auto col-sm-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                                    <img src="assets/img/avatars/profiles/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-12 col-sm">

                                {/* <!-- Body --> */}
                                <p className="mb-sm-0">
                                  Finish the design for blog listings and articles, including mixed media
                                </p>

                              </div>
                              <div className="col col-sm-auto">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted mb-0">
                                  <i className="fe fe-message-circle"></i> 23 <i className="fe fe-clock ml-3"></i> Sep 9
                                </p>

                              </div>
                              <div className="col-auto col-sm-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                                    <img src="assets/img/avatars/profiles/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                                    <img src="assets/img/avatars/profiles/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                    <img src="assets/img/avatars/profiles/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTaskEmpty">
                          <div className="card-body">
                            <span className="badge badge-soft-success mr-2">Reviewed</span> Clear all the deprecation warnings for out of date NPM packages
                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-12 col-sm">

                                {/* <!-- Body --> */}
                                <p className="mb-sm-0">
                                  Review all the indentation changes to be sure they will resolve all customer issues
                                </p>

                              </div>
                              <div className="col col-sm-auto">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted mb-0">
                                  <i className="fe fe-clock"></i> Jun 11
                                </p>

                              </div>
                              <div className="col-auto col-sm-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Miles">
                                    <img src="assets/img/avatars/profiles/avatar-5.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </div>
                        </div>
                      </div>

                    </div>

                    {/* <!-- Add --> */}
                    <div className="kanban-add">
                      <div className="card card-sm card-flush mb-0">
                        <div className="card-body">

                          {/* <!-- Link --> */}
                          <a className="kanban-add-link" href="#!">+ Add Card</a>

                          {/* <!-- Form --> */}
                          <form className="kanban-add-form d-none">
                            <div className="row align-items-center">
                              <div className="col-12 col-md">

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-flush form-control-auto" placeholder="Draft your card" />

                              </div>
                              <div className="col-6 col-md-auto d-flex align-items-center">

                                {/* <!-- Icon --> */}
                                <span className="text-muted small mr-2">
                                  <i className="fe fe-clock"></i>
                                </span>

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-sm form-control-flush form-control-auto text-muted" data-toggle="flatpickr" data-options='{"dateFormat": "M j"}' placeholder="No due date" />

                              </div>
                              <div className="col-6 col-md-auto">

                                {/* <!-- Buttons --> */}
                                <button className="btn btn-sm btn-white" type="reset">
                                  Cancel
                                </button>
                                <button className="btn btn-sm btn-primary" type="submit">
                                  Add
                                </button>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </form>

                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="list-group-item">

                    {/* <!-- Header --> */}
                    <div className="row align-items-center mb-4">
                      <div className="col">

                        {/* <!-- Heading --> */}
                        <h2 className="mb-0">Next Up</h2>

                      </div>
                      <div className="col-auto">

                        {/* <!-- Dropdown --> */}
                        <div className="dropdown">
                          <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#!" className="dropdown-item">
                              Action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Another action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Something else here
                            </a>
                          </div>
                        </div>

                      </div>
                    </div> {/* <!-- / .row --> */}

                    {/* <!-- Category --> */}
                    <div className="kanban-category">

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-12 col-sm">

                                {/* <!-- Body --> */}
                                <p className="mb-sm-0">
                                  Design portfolio pages and a case study focused page
                                </p>

                              </div>
                              <div className="col col-sm-auto">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted mb-0">
                                  <i className="fe fe-clock"></i> Jul 6
                                </p>

                              </div>
                              <div className="col-auto col-sm-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Rye Duke">
                                    <img src="assets/img/avatars/profiles/avatar-6.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              Create a screenshot component extension for highlighting hotspots with popover hovers
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-12 col-sm">

                                {/* <!-- Body --> */}
                                <p className="mb-sm-0">
                                  Include an even larger library of illustrations that we can provide in SVG that cover more use cases
                                </p>

                              </div>
                              <div className="col col-sm-auto">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted mb-0">
                                  <i className="fe fe-message-circle"></i> 23 <i className="fe fe-clock ml-3"></i> Sep 9
                                </p>

                              </div>
                              <div className="col-auto col-sm-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                                    <img src="assets/img/avatars/profiles/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                                    <img src="assets/img/avatars/profiles/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                    <img src="assets/img/avatars/profiles/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTaskEmpty">
                          <div className="card-body">

                            {/* <!-- Body --> */}
                            <p className="mb-0">
                              Create basic auth pages (login, sign up, forgot password) even though we don't have any settings yet
                            </p>

                          </div>
                        </div>
                      </div>

                      {/* <!-- Card --> */}
                      <div className="kanban-item">
                        <div className="card card-sm mb-3" data-toggle="modal" data-target="#modalKanbanTask">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-12 col-sm">

                                {/* <!-- Body --> */}
                                <p className="mb-sm-0">
                                  Code up the utility pages for basic errors and failure states
                                </p>

                              </div>
                              <div className="col col-sm-auto">

                                {/* <!-- Time --> */}
                                <p className="card-text small text-muted mb-0">
                                  <i className="fe fe-clock"></i> Jul 12
                                </p>

                              </div>
                              <div className="col-auto col-sm-auto">

                                {/* <!-- Avatar group --> */}
                                <div className="avatar-group">
                                  <a href="profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                                    <img src="assets/img/avatars/profiles/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                  </a>
                                </div>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </div>
                        </div>
                      </div>

                    </div>

                    {/* <!-- Add --> */}
                    <div className="kanban-add">
                      <div className="card card-sm card-flush mb-0">
                        <div className="card-body">

                          {/* <!-- Link --> */}
                          <a className="kanban-add-link" href="#!">+ Add Card</a>

                          {/* <!-- Form --> */}
                          <form className="kanban-add-form d-none">
                            <div className="row align-items-center">
                              <div className="col-12 col-md">

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-flush form-control-auto" placeholder="Draft your card" />

                              </div>
                              <div className="col-6 col-md-auto d-flex align-items-center">

                                {/* <!-- Icon --> */}
                                <span className="text-muted small mr-2">
                                  <i className="fe fe-clock"></i>
                                </span>

                                {/* <!-- Input --> */}
                                <input type="text" className="form-control form-control-sm form-control-flush form-control-auto text-muted" data-toggle="flatpickr" data-options='{"dateFormat": "M j"}' placeholder="No due date" />

                              </div>
                              <div className="col-6 col-md-auto">

                                {/* <!-- Buttons --> */}
                                <button className="btn btn-sm btn-white" type="reset">
                                  Cancel
                                </button>
                                <button className="btn btn-sm btn-primary" type="submit">
                                  Add
                                </button>

                              </div>
                            </div> {/* <!-- / .row --> */}
                          </form>

                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="list-group-item">

                    {/* <!-- Add --> */}
                    <div className="kanban-add">

                      {/* <!-- Link --> */}
                      <a className="kanban-add-link h2" href="#!">
                        + Add List
                      </a>

                      {/* <!-- Form --> */}
                      <form className="kanban-add-form d-none">
                        <div className="row align-items-center">
                          <div className="col-12 col-md">

                            {/* <!-- Button --> */}
                            <input type="text" className="form-control form-control-flush form-control-auto h2" placeholder="Name your list" />

                          </div>
                          <div className="col-auto">

                            {/* <!-- Buttons --> */}
                            <button className="btn btn-sm btn-white" type="reset">
                              Cancel
                            </button>
                            <button className="btn btn-sm btn-primary" type="submit">
                              Add
                            </button>

                          </div>
                        </div> {/* <!-- / .row --> */}
                      </form>

                    </div>

                  </div>
                </div>

              </div>
            </div> {/* <!-- / .row --> */}
          </div>
        </div>
      </div>

            </div>
        </div> 
    )
}

export default UnProjetOrganisation