import React from 'react'
import SelectTypeTravaux from '../../../toolsComponents/SelectTypeTravaux'
import helper from '../uneCalculatriceHelper'
import InputForm from '../../../toolsComponents/forms/InputForm'

/**
 * initialState -> initial data to feed this component
 * onChange -> to manage when a field is changed
 */
class InformationsGenerales extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.props.initialState
  }

  async updateState() {
    // Update dynamic fields
    const tmpState = { infosGenerales: this.state }

    // manage frais de notaires
    if( this.state.fraisNotaires === undefined) {
      await this.setState({
        fraisNotaires: helper.performFraisNotaires(tmpState)
      })
    }
    tmpState.infosGenerales = this.state

    this.setState({
      coutTravaux: helper.performCoutsTravaux(tmpState),
      coutTotalProjet: helper.performCoutTotalProjet(tmpState),
      coutTotalm2: helper.performCoutsAchatRenovationM2(tmpState),
      coutTotalReno: helper.performCoutsAchatRenovation(tmpState),
    })
  }

  handleChange = async (event) => {
    await this.setState({
      [event.inputName]: event.value
    })


    // On ne peut pas considérer que c'est une valeur dynamique, il faut pouvoir la sauvegarder car l'utilisateur peut la modifier
    // Il faut plutôt la considérer comme une valeur static assistée
    // Elle ne doit donc pas être dans un helper
    if (event.inputName === "fraisNotaires") {
      await this.setState({
        fraisNotaires: event.value,
      })
    }
    else if( event.inputName === "coutAchat" ) {
      await await this.setState({
        fraisNotaires: undefined,
      })
    }

    await this.updateState()

    if (typeof this.props.onChange === 'function') this.props.onChange(this.state)

  }

  async componentDidMount() {
    await this.updateState()
  }

  async componentDidUpdate(prevProps) {
    if(this.props.initialState !== prevProps.initialState) {
      await this.setState(this.props.initialState)
      await this.updateState()
    }
  }

  render() {
    return (<>
      <div className='col-12'>
        <div className='row'>
          <div className='col-8'>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title'>Informations générales</h4>
              </div>
              
              <div className='card-body'>
                <div className='row'>
                  <div className="col-6">
                    <InputForm
                      label="Surface du bâti"
                      inputName="surface"
                      type="number"
                      value={this.state?.surface}
                      onChange={this.handleChange}
                      placeholder="ex: 35"
                      prepend="m²"
                    /> 
                  </div>

                  <div className="col-6">
                    <SelectTypeTravaux
                      inputName="typedetravaux"
                      value={this.state?.typedetravaux}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="col-6">
                    <InputForm 
                      label={"Coût d'achat du projet"}
                      type={"number"}
                      inputName={"coutAchat"}
                      value={this.state?.coutAchat}
                      onChange={this.handleChange}
                      placeholder="ex: 110000"
                      prepend={"€"}
                    />
                  </div>

                  <div className="col-6">
                    <InputForm
                      label="Frais de notaires estimés"
                      type="number"
                      inputName="fraisNotaires"
                      value={this.state?.fraisNotaires}
                      onChange={this.handleChange}
                      placeholder="ex: 7000"
                      prepend={"€"}
                      tooltip={"= 8 % du coût d'achat"}
                    />
                  </div>

                  <div className="col-6">
                    <InputForm
                      label="Coût des meubles"
                      type="number"
                      inputName="coutMeubles"
                      value={this.state?.coutMeubles}
                      onChange={this.handleChange}
                      placeholder="ex: 110000"
                      prepend={"€"}
                    />
                  </div>
                  
                </div>
              </div>

            </div>
          </div>

          <div className='col-4'>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title'>Coûts totaux</h4>
              </div>
              
              <div className='card-body'>
                <div className='row'>
                  <div className="col-12">
                    <InputForm
                      label="Coût des travaux approximatif"
                      type="number"
                      inputName="coutTravaux"
                      value={(isNaN(this.state?.coutTravaux) ? 0 : this.state.coutTravaux)}
                      readOnly
                      prepend={"€"}
                      tooltip={"= surface X moyenne du type de travaux"}
                    />
                  </div>

                  <div className="col-6">
                    <InputForm
                      label={"Coût total avec rénovation"}
                      type="number"
                      inputName="coutTotalReno"
                      value={(isNaN(this.state?.coutTotalReno) ? 0 : this.state.coutTotalReno)}
                      readOnly
                      prepend={"€"}
                      tooltip={"= coût d'achat + frais de notaires + coût des travaux approximatif"}
                    />
                  </div>

                  <div className="col-6">
                    <InputForm
                      label={"Coût total /m² avec réno"}
                      type="number"
                      inputName="coutTotalProjetParM2"
                      value={(isNaN(this.state?.coutTotalm2) ? 0 : this.state.coutTotalm2)}
                      readOnly
                      prepend={"€/m²"}
                      tooltip={"= coût total avec rénovation / surface"}
                    />
                  </div>

                  <div className="col-12">
                    <InputForm
                      label={"Coût total du projet"}
                      type="number"
                      name="coutTotalProjet"
                      value={(isNaN(this.state?.coutTotalProjet) ? 0 : this.state.coutTotalProjet)}
                      readOnly
                      prepend={"€"}
                      tooltip={"= coût total avec rénovation + coût des meubles"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
  }
}


export default InformationsGenerales