import React from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm'
import SubmitForm from '../../../toolsComponents/forms/SubmitForm'
import SelectNotation from '../../../toolsComponents/SelectNotation'

const UneEtudeAppreciations = ({ etude, onChange, onSubmit }) => {

  const getNotationClass = () => {
    if(!etude || !etude.notation || etude.notation === '' || parseInt(etude.notation) < 10 ) return 'text-danger'
    else if(parseInt(etude.notation) < 15 ) return 'text-warning'
    else return 'text-success'
  }

  return (
    <>
      <form>

        {/* Notes */}
        <h2>Appréciations</h2>

        <div className="row">
          <div className="col-2">
            <SelectNotation 
              label="Notation globale de la ville"
              inputName="notation"
              value={etude.notation}
              type="textarea"
              onChange={onChange}
            />
          </div>

          <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
              <p style={{paddingTop: '35px', paddingRight: '32px'}}>
                <span className={getNotationClass()}>●</span>
              </p>
          </div>
        </div>
        
        <InputForm 
          label="Point positifs"
          inputName="points_positifs"
          value={etude.points_positifs}
          type="textarea"
          onChange={onChange}
        />

        <InputForm 
          label="Point négatifs"
          inputName="points_negatifs"
          value={etude.points_negatifs}
          type="textarea"
          onChange={onChange}
        />

        {/* <!-- Sauvegarder --> */}
        <SubmitForm
          label="Sauvegarder"
          onSubmit={onSubmit}
        />
      </form>
    
    </>
  )
}

export default UneEtudeAppreciations