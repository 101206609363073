import React from 'react'
import Navigation from '../../toolsComponents/Navigation'
import UnArtisanHeader from './UnArtisanHeader'

const UnArtisan = () => {

    return (
        <div>
            {/* <!-- MODAL AJOUTER UN CONTACT --> */}
            <div className="modal fade" id="exampleModalNewContact" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ajouter un contact</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Nom du contact</label>
                                <input type="text" className="form-control" id="recipient-name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Numéro de téléphone</label>
                                <input type="phone" className="form-control" id="recipient-name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Adresse email</label>
                                <input type="email" className="form-control" id="recipient-name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Photo de profil</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL MODIFIER UN CONTACT --> */}
            <div className="modal fade" id="exampleModalEditContact" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifier un contact</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <form>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Nom du contact</label>
                                <input type="text" className="form-control" id="recipient-name" value="David MONTEIL"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Numéro de téléphone</label>
                                <input type="phone" className="form-control" id="recipient-name" value="(+ 33) 4 66 49 17 65"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Adresse email</label>
                                <input type="email" className="form-control" id="recipient-name" value="dmonteil@batir-48.com"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Photo de profil</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                            </div>
                        </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-success">Modifier</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- MODAL SUPPRIMER UN CONTACt --> */}
            <div className="modal fade" id="exampleModalDelContact" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Supprimer un contact</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                        <p>Etes-vous sûr de vouloir supprimer le contact : David MONTEIL ?</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger">Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>

            <Navigation granted_modules="ALL" />

            <div className="main-content">

                <UnArtisanHeader isActive="general"/>

                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-12">

                            <div className="row">

                                {/* <!-- FORMULAIRE --> */}
                                <div className="col-8 col-xl-8">

                                    <form className="mb-4">

                                        {/* <!-- Nom de l'artisan --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Nom de l'artisan
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control" value="Bâtir 48 SARL" />

                                        </div>

                                        {/* <!-- Description --> */}
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1">Description</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                        </div>

                                        <hr />

                                        {/* <!-- Adresse de l'artisan --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Adresse
                                            </label>

                                            {/* <!-- Input --> */}
                                            <input type="text" className="form-control" value="29 Rue des Alouettes"/>

                                        </div>

                                         {/* <!-- Ville et code postal --> */}
                                         <div className="row">
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Code postal
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" value="48 000"/>

                                                </div>

                                                </div>
                                            <div className="col-12 col-md-6">

                                                {/* <!-- Start date --> */}
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Ville
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" value="Mende"/>

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        {/* <!-- Téléphone et email --> */}
                                        <div className="row">

                                            {/* <!-- Numéro de téléphone --> */}
                                            <div className="col-12 col-md-6">

                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Numéro de téléphone
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" value="(+ 33) 4 66 49 17 65" />

                                                </div>

                                                </div>
                                            
                                            {/* <!-- Adresse e-mail --> */}
                                            <div className="col-12 col-md-6">
                                            
                                                <div className="form-group">

                                                    {/* <!-- Label --> */}
                                                    <label>
                                                        Adresse e-mail
                                                    </label>

                                                    {/* <!-- Input --> */}
                                                    <input type="text" className="form-control" value="contact@batir48.com" />

                                                </div>

                                            </div>
                                        </div> {/* <!-- / .row --> */}

                                        <hr />

                                        {/* <!-- Corps de métier --> */}
                                        <div className="form-group">

                                            {/* <!-- Label  --> */}
                                            <label>
                                                Corps de métier
                                            </label>

                                            {/* <!-- Checkboxes --> */}
                                                
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                <label className="form-check-label" htmlFor="defaultCheck1">
                                                    Architecte
                                                </label>
                                            </div>


                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                                                <label className="form-check-label" htmlFor="defaultCheck2">
                                                    Carreleur
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck3" />
                                                <label className="form-check-label" htmlFor="defaultCheck3">
                                                    Couvreur
                                                </label>
                                            </div>



                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck4" />
                                                <label className="form-check-label" htmlFor="defaultCheck4">
                                                    Electricien
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck5" checked/>
                                                <label className="form-check-label" htmlFor="defaultCheck5">
                                                    Gros oeuvre
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck5" />
                                                <label className="form-check-label" htmlFor="defaultCheck5">
                                                    Peintre
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck7" />
                                                <label className="form-check-label" htmlFor="defaultCheck7">
                                                    Plombier
                                                </label>
                                            </div>

                                        </div>


                                        <div className="form-group">
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary mb-2">Sauvegarder</button>
                                            </div>
                                        </div>

                                        <br /><br />
                                    </form>
                            </div>

                                {/* CARDS*/}
                                <div className="col-4 col-xl-4">

                                    {/* <!-- Contacts --> */}
                                    <div className="card">
                                            
                                        <div className="card-header">

                                            {/* <!-- Title --> */}
                                            <h4 className="card-header-title">
                                                Contacts
                                            </h4>

                                        </div>

                                        <div className="card-body">

                                            <div className="list-group list-group-flush my-n3">

                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">

                                                            {/* <!-- Avatar --> */}
                                                            <span className="avatar">
                                                            <img src="/assets/img/avatars/profiles/avatar-3.jpg" alt="..." className="avatar-img rounded-circle" />
                                                            </span>

                                                        </div>
                                                        <div className="col ml-n2">

                                                            {/* <!-- Title --> */}
                                                            <h4 className="mb-1">
                                                            <span >David MONTEIL</span>
                                                            </h4>

                                                            {/* <!-- Email --> */}
                                                            <p className="card-text small" style={{marginBottom:"0px"}}>
                                                            <a href="mailto:">dmonteil@batir-48.com</a>
                                                            </p>

                                                            {/* <!-- Téléphone --> */}
                                                            <p className="card-text small">
                                                            <a href="tel:(+ 33) 4 66 49 17 65">(+ 33) 4 66 49 17 65</a>
                                                            
                                                            </p>

                                                        </div>

                                                        {/* <!-- Actions --> */}
                                                        <div className="col-auto">

                                                            {/* <!-- Dropdown --> */}
                                                            <div className="dropdown">
                                                                <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fe fe-more-vertical"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalEditContact">
                                                                    <i className="fe fe-edit-3"></i> Modifier
                                                                    </a>
                                                                    <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelContact">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">

                                                            {/* <!-- Avatar --> */}
                                                            <span className="avatar">
                                                            <img src="/assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle" />
                                                            </span>

                                                        </div>
                                                        <div className="col ml-n2">

                                                            {/* <!-- Title --> */}
                                                            <h4 className="mb-1">
                                                            <span>Romain TAMUEL</span>
                                                            </h4>

                                                            {/* <!-- Email --> */}
                                                            <p className="card-text small" style={{marginBottom:"0px"}}>
                                                            <a href="mailto:">rtamuel@batir-48.com</a>
                                                            </p>

                                                            {/* <!-- Téléphone --> */}
                                                            <p className="card-text small">
                                                            <a href="tel:(+ 33) 4 66 49 17 65">(+ 33) 4 66 36 42 85</a>
                                                            </p>

                                                        </div>

                                                        {/* <!-- Actions --> */}
                                                        <div className="col-auto">

                                                            {/* <!-- Dropdown --> */}
                                                            <div className="dropdown">
                                                                <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fe fe-more-vertical"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <a href="#!" className="dropdown-item"  data-toggle="modal" data-target="#exampleModalEditContact">
                                                                    <i className="fe fe-edit-3"></i> Modifier
                                                                    </a>
                                                                    <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#exampleModalDelContact">
                                                                    <i className="fe fe-trash-2"></i> Supprimer
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>
                                                </div>


                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-12" style={{textAlign:'center'}}>

                                                            <a  href="#!" data-toggle="modal" data-target="#exampleModalNewContact">Ajouter un contact</a>

                                                        </div>

                                                    </div>
                                                </div> 
                                            </div>


                                        </div>
                                        
                                    </div>

                                    {/* <!-- Rendez-vous --> */}
                                    <div className="card">
                                            
                                        <div className="card-header">

                                            {/* <!-- Title --> */}
                                            <h4 className="card-header-title">
                                                Prochains rendez-vous
                                            </h4>

                                            <a href="/mockups/mes-rendez-vous" className="small">Voir plus</a>

                                        </div>

                                        <div className="card-body">

                                            <div className="list-group list-group-flush my-n3">

                                                {/* RDV 1 */}
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">

                                                            {/* <!-- Avatar --> */}
                                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres" className="avatar">
                                                            <img src="/assets/img/mockups/projet-5-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                                            </a>

                                                        </div>
                                                        <div className="col ml-n2">

                                                            {/* <!-- Title --> */}
                                                            <h4 className="mb-1">
                                                            <span>Mardi 27 avril 2021 à 16h30</span>
                                                            </h4>

                                                            {/* <!-- Téléphone --> */}
                                                            <p className="card-text small">
                                                            <small>Vérifier les fondations du chalet à Florac Trois Rivières</small>
                                                            </p>

                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                {/* RDV 2 */}
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">

                                                            {/* <!-- Avatar --> */}
                                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres" className="avatar">
                                                            <img src="/assets/img/mockups/projet-3-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                                            </a>

                                                        </div>
                                                        <div className="col ml-n2">

                                                            {/* <!-- Title --> */}
                                                            <h4 className="mb-1">
                                                            <span>Mercredi 28 avril 2021 à 11h</span>
                                                            </h4>

                                                            {/* <!-- Téléphone --> */}
                                                            <p className="card-text small">
                                                            <small>Comment agrandir l'espace de vie</small>
                                                            </p>

                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                {/* RDV 3 */}
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">

                                                            {/* <!-- Avatar --> */}
                                                            <a href="/mockups/mes-projets/Chalet-en-pin-douglas-a-Florac-Trois-Rivieres" className="avatar">
                                                            <img src="/assets/img/mockups/projet-4-avatar.jpeg" alt="..." className="avatar-img rounded-circle" />
                                                            </a>

                                                        </div>
                                                        <div className="col ml-n2">

                                                            {/* <!-- Title --> */}
                                                            <h4 className="mb-1">
                                                            <span>Mercredi 28 avril 2021 à 18h</span>
                                                            </h4>

                                                            {/* <!-- Téléphone --> */}
                                                            <p className="card-text small">
                                                            <small>Ajouter une extension dans le jardin</small>
                                                            </p>

                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-12" style={{textAlign:'center'}}>

                                                            <a  href="/mockups/mes-rendez-vous">Ajouter un rendez-vous</a>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnArtisan