import React from 'react'
import InputForm from '../../../toolsComponents/forms/InputForm'
import SubmitForm from '../../../toolsComponents/forms/SubmitForm'

const UneEtudeMeilleurAgents = ({ etude, onChange, onSubmit }) => {

  const clearNomURL = () => {
    return etude.commune?.nom.replace(' ', '-').replace("'", '-').toLowerCase()
  }

  const clearCPURL = () => {
    return etude.commune?.cp.split('/')[0]
  }

  return (
    <>
      <div className="row">
        <div className="col" style={{textAlign:'center'}}>
          <a href={`https://www.meilleursagents.com/prix-immobilier/${clearNomURL()}-${clearCPURL()}/`} target="_blank" rel="noreferrer">
            Afficher la carte des prix de {etude.commune?.nom} dans meilleurs agents.
          </a>
        </div>
      </div>

      <br />

      <form>
        <h2>Tarifs</h2>

        <InputForm 
          label="Prix d'achat moyen au m² pour un appartement"
          inputName="prix_achat_m2"
          value={etude.prix_achat_m2}
          prepend="€ / m²"
          type="number"
          onChange={onChange}
        />

        <div className="row">
          <div className="col">
            <InputForm 
              label="Evolution il y a 1 an"
              inputName="prix_achat_m2_1an"
              value={etude.prix_achat_m2_1an}
              prepend="%"
              type="number"
              onChange={onChange}
            />
          </div>
          
          <div className="col">
            <InputForm 
              label="Evolution il y a 2 ans"
              inputName="prix_achat_m2_2ans"
              value={etude.prix_achat_m2_2ans}
              prepend="%"
              type="number"
              onChange={onChange}
            />
          </div>
          
          <div className="col">
          <InputForm 
              label="Evolution il y a 5 ans"
              inputName="prix_achat_m2_5ans"
              value={etude.prix_achat_m2_5ans}
              prepend="%"
              type="number"
              onChange={onChange}
            />
          </div>
        </div>

        <h2>Quartiers</h2>

        <div className="row">
          <div className="col">
            <InputForm 
              label="Premier quartier idéal"
              inputName="quartier_ideal1"
              value={etude.quartier_ideal1}
              sideBadgeValue="+"
              sideBadgeColor="success"
              onChange={onChange}
            />
          </div>
          
          <div className="col">
            <InputForm 
              label="Deuxième quartier idéal"
              inputName="quartier_ideal2"
              value={etude.quartier_ideal2}
              sideBadgeValue="+"
              sideBadgeColor="success"
              onChange={onChange}
            />
          </div>
          
          <div className="col">
            <InputForm 
              label="Troisième quartier idéal"
              inputName="quartier_ideal3"
              value={etude.quartier_ideal3}
              sideBadgeValue="+"
              sideBadgeColor="success"
              onChange={onChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <InputForm 
              label="Premier quartier à éviter"
              inputName="quartier_eviter1"
              value={etude.quartier_eviter1}
              sideBadgeValue="-"
              sideBadgeColor="danger"
              onChange={onChange}
            />
          </div>
          
          <div className="col">
            <InputForm 
              label="Deuxième quartier à éviter"
              inputName="quartier_eviter2"
              value={etude.quartier_eviter2}
              sideBadgeValue="-"
              sideBadgeColor="danger"
              onChange={onChange}
            />
          </div>
          
          <div className="col">
            <InputForm 
              label="Troisième quartier à éviter"
              inputName="quartier_eviter3"
              value={etude.quartier_eviter3}
              sideBadgeValue="-"
              sideBadgeColor="danger"
              onChange={onChange}
            />
          </div>
        </div>

        {/* <!-- Sauvegarder --> */}
        <SubmitForm
          label="Sauvegarder"
          onSubmit={onSubmit}
        />
      </form> 
    </>
  )
}

export default UneEtudeMeilleurAgents