import React from 'react'
import Navigation from './Navigation'

const EnConstruction = () => {

  return (
    <div>
      <Navigation granted_modules="ALL" />

      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <br /><br />

              <h1 style={{textAlign:"center"}}>Cette page n'existe pas encore, elle est en construction :)</h1>
            </div>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default EnConstruction