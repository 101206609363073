import React from 'react'
import Navigation from '../toolsComponents/Navigation'

const Tutoriels = () => {


    return (
        <div>
            <Navigation granted_modules="ALL" />

            <div className="main-content bg-fixed-bottom" style={{backgroundImage:'url(/assets/img/illustrations/sticky.svg)'}}>

                <div className="container-fluid">
                    <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-10">

                        {/* <!-- Header --> */}
                        <div className="header mt-md-5">
                            <div className="header-body">

                                {/* <!-- Pretitle --> */}
                                <h6 className="header-pretitle">
                                Tutoriels
                                </h6>

                                {/* <!-- Title --> */}
                                <h1 className="header-title">
                                Découvrez toute la puissance de ProjetImmo.io
                                </h1>

                            </div>
                        </div>

                        {/* <!-- Main Card --> */}
                        <div className="card">

                            <div className="card-body text-center">
                                <div className="row">

                                    <div className="col-12 col-md-6 col-xl-4">

                                        {/* Tuto 1 */}
                                        <div className="card">

                                            <a href="project-overview.html">
                                                <img src="/assets/img/mockups/video1.png" alt="..." className="card-img-top" />
                                            </a>

                                            <div className="card-body">
                                                <div className="row align-items-center">

                                                    <div className="col">

                                                        <h4 className="mb-2 name">
                                                            <a href="project-overview.html" >Comment poser les fondations de son investissement immobilier ?</a>
                                                        </h4>

                                                        <p className="card-text small text-muted" style={{paddingTop:"6px"}}>
                                                            Alex Ferreira
                                                        </p>

                                                    </div>
                                                    
                                                </div> 
                                            </div>

                                            <div className="card-footer card-footer-boxed">
                                                <div className="row align-items-center">

                                                    <div className="col">
                                                        <div className="row align-items-center no-gutters">

                                                            <div className="col-auto">

                                                                <div className="small mr-2">
                                                                    Difficulté : &nbsp;&nbsp; 
                                                                    <i className="fe fe-star text-warning active"></i>
                                                                </div>

                                                            </div>

                                                        </div> 
                                                    </div>
                                                    
                                                </div> 
                                            </div>
                                            
                                        </div>

                                    </div>
                                        
                                </div> {/* <!-- / .row --> */}
                            </div>
                            
                        </div>

                        
                    </div>
                    </div>

                </div> {/* <!-- / .main-content --> */}
                    
            </div> 
        </div>
    )
}

export default Tutoriels