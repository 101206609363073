import React from 'react'

const SelectSituationFamiliale = ({ label="Situation familiale", inputName="situation_familiale", value, onChange }) => {

  const handleChange = (e) => {
    if(onChange) onChange({value: e.target.value, inputName})
  }

  return (
    <div className="form-group">
      <label htmlFor={inputName+'-select'}>
        {label}
      </label>

      <select className="form-control" name={inputName} id={inputName+'-select'} onChange={handleChange} value={value}>
        <option value="">---</option>
        <option value="Célibataire">Célibataire</option>
        <option value="Divorcé">Divorcé</option>
        <option value="Marié">Marié</option>
        <option value="Pacsé">Pacsé</option>
        <option value="Séparé">Séparé</option>
        <option value="Veuf">Veuf</option>
      </select>
    </div>
  )
}

export default SelectSituationFamiliale