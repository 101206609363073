import React from 'react'
import UneEtudeValeursFoncieresNoData from './UneEtudeValeursFoncieresNoData'
import UneEtudeValeursFoncieresAppartements from './UneEtudeValeursFoncieresAppartements'
import UneEtudeValeursFoncieresForm from './UneEtudeValeursFoncieresForm'
import UneEtudeValeursFoncieresMaisons from './UneEtudeValeursFoncieresMaisons'
import defined from '../../../ressources/defined'

const UneEtudeValeursFoncieres = ({ etude, withButton = true, onChange, onSubmit }) => {

  const clearNomURL = () => {
    return etude.commune?.nom.replace(' ', '-').replace("'", '-').toLowerCase()
  }

  const clearCPURL = () => {
    return etude.commune?.cp.split('/')[0]
  }

  const getTotalVentes = () => {
    let result = 0

    if(etude && etude.valeurs_foncieres && etude.valeurs_foncieres.appartements && etude.valeurs_foncieres.maisons) {
      result = getTotalAppartements() + getTotalMaisons()
    }
  
    return result
  }

  const getTotalAppartements = () => {
    let result = 0

    if(etude && etude.valeurs_foncieres && etude.valeurs_foncieres.appartements) {
      for(const a of etude.valeurs_foncieres.appartements) { result += a.global.nb_transactions }
    }
  
    return result
  }

  const getTotalMaisons = () => {
    let result = 0

    if(etude && etude.valeurs_foncieres && etude.valeurs_foncieres.maisons) {
      for(const m of etude.valeurs_foncieres.maisons) { result += m.global.nb_transactions }
    }
  
    return result
  }

  return (
    <>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="moreInfosVF" tabindex="-1" role="dialog" aria-labelledby="moreInfosVFLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="moreInfosVFLabel">Comment interprêter ces chiffres ?</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Vous pourrez trouver des annonces avec un prix au m² inférieur à celui affiché sur notre fourchette basse et supérieur à celui affiché sur notre fourchette haute. Bien souvent, cela sera dû au <strong>facteur de localisation</strong> (rue très bien valorisée vs quartier mal fréquenté) et/ou <strong>d'état du bien</strong> en question (délabré v.s neuf). <br /><br />
              Prenez aussi en compte que le prix d'une annonce est <strong>le prix demandé et non le prix final</strong> (négociation). <br /><br />
              Nos estimations sont calculées à partir des données des notaires recensant les transactions réalisées depuis 2016 en France. L'indice de confiance est basé sur le nombre de transactions réalisées, permettant une analyse statistique plus ou moins précise.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
            </div>
          </div>
        </div>
      </div>

      {(!etude.valeurs_foncieres || getTotalVentes() === 0) &&
        <UneEtudeValeursFoncieresNoData
          etude={etude}
          notEnoughtData={false}
          withButton={withButton}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      }

      {etude.valeurs_foncieres && 
        getTotalVentes() > 0 &&
        (getTotalMaisons() < defined.SALES_THRESHOLD || getTotalAppartements() < defined.SALES_THRESHOLD) &&
        <>
          <UneEtudeValeursFoncieresForm
            etude={etude}
            withButton={withButton}
            onChange={onChange}
            onSubmit={onSubmit}
          />
          <br /><br />
        </>
      }

      {etude.valeurs_foncieres &&
        getTotalVentes() > 0 &&
        <>
          <div className="row">
            <div className="col-6">
              <UneEtudeValeursFoncieresAppartements 
                etude={etude}
              />
            </div>

            <div className="col-6">
              <UneEtudeValeursFoncieresMaisons 
                etude={etude}
              />
            </div>
          </div>

          <div className="row">
            <div className="col" style={{textAlign:'center'}}>
              <p>Toutes les données proviennent de l'INSEE.</p>
              <p>Pour affiner votre recherche, vous pouvez également <a href={`https://www.meilleursagents.com/prix-immobilier/${clearNomURL()}-${clearCPURL()}/`} target="_blank" rel="noreferrer">regarder les données de Meilleurs Agents</a></p>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default UneEtudeValeursFoncieres