import React, { useState } from 'react'
import InputForm from '../forms/InputForm'
import SelectTypeBienPatrimoine from '../SelectTypeBienPatrimoine'
import SimpleError from '../SimpleError'

const AddUnBienModal = ({ onSubmit }) => {

  const [ titre, setTitre ] = useState('')
  const [ type, setType ] = useState('')

  const [ errorTitre, setErrorTitre ] = useState(false)
  const [ errorType, setErrorType ] = useState(false)

  const [ isDismissable, setIsDismissable ] = useState(false)

  const handleChangeTitre = ( e ) => {
    setTitre(e.value)

    setIsDismissable(true)
    if( e.value === '' || type === '' )
      setIsDismissable(false)
  }

  const handleChangeType = ( e ) => {
    setType(e.value)

    setIsDismissable(true)
    if( e.value === '' || titre === '' )
      setIsDismissable(false)
  }

  const handleSubmit = () => {

    let isError = false

    setErrorTitre(false)
    if( titre === '' ) {
      setErrorTitre(true)
      isError = true
    }

    setErrorType(false)
    if( type === '' ) {
      setErrorType(true)
      isError = true
    }

    if( !isError ) {
      onSubmit({
        titre,
        type
      })
    }
  }

  return <>
    <div className="modal fade" id={`add-bien`} tabindex="-1" role="dialog" aria-labelledby={`add-bien`} aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`add-bien-label`}>Ajouter un bien</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <InputForm
              label="Titre du bien"
              inputName="titre"
              value={titre}
              onChange={handleChangeTitre}
            />

            {errorTitre &&
              <SimpleError withHeader={false}>
                Le titre est requis
              </SimpleError>
            }

            <SelectTypeBienPatrimoine
              inputName="type"
              value={type}
              onChange={handleChangeType}
            />

            {errorType &&
              <SimpleError withHeader={false}>
                Le type de bien est requis
              </SimpleError>
            }
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
            <button type="button" className="btn btn-primary" data-dismiss={isDismissable ? 'modal' : ''} onClick={handleSubmit}>Ajouter</button>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default AddUnBienModal