import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./features/auth/login/loginSlice";
import etudesReducer from "./features/etudes/etudesSlice";
import uneEtudeReducer from "./features/uneEtude/uneEtudeSlice";
import artisansReducer from "./features/artisans/artisansSlice";
import unArtisanReducer from "./features/unArtisan/unArtisanSlice";
import banquesReducer from "./features/banques/banquesSlice";
import formationsReducer from "./features/formations/formationsSlice";
import tutorielsReducer from "./features/tutoriels/tutorielsSlice";
import registerSlice from "./features/auth/register2/registerSlice";
import uneBanqueReducer from "./features/uneBanque/uneBanqueSlice";
import uneBanqueOffresPretReducer from "./features/uneBanque/uneBanqueOffresPretSlice";
import unTutorielReducer from "./features/unTutoriel/unTutorielSlice";
import unArtisanDevisReducer from "./features/unArtisan/unArtisanDevisSlice";
import uneFormationReducer from "./features/uneFormation/uneFormationSlice";
import uneOffrePretReducer from "./features/uneOffrePret/uneOffrePretSlice";
import projetsReducer from "./features/projets/projetsSlice";
import unProjetReducer from "./features/unProjet/unProjetSlice";
import registerCheckoutReducer from "./features/auth/register2/checkout/registerCheckoutSlice";
import unProfilReducer from "./features/unProfil/unProfilSlice";
import resetPasswordReducer from "./features/auth/resetPassword/resetPasswordSlice";
import newPasswordReducer from "./features/auth/newPassword/newPasswordSlice";
import uneCalculatriceRentabiliteReducer from "./features/uneCalculatrice/uneCalculatriceRentabiliteSlice";
import unProjetSimulationTravauxReducer from "./features/unProjet/UnProjetSimulationTravaux/unProjetSimulationTravauxSlice";
import unPatrimoineReducer from "./features/unPatrimoine/unPatrimoineSlice";
import unBienReducer from "./features/unBien/unBienSlice";
import unBienDocumentsReducer from "./features/unBien/unBienDocumentsSlice";

export default configureStore({
  reducer: {
    register: registerSlice,
    registerCheckout: registerCheckoutReducer,
    login: loginReducer,
    resetPassword: resetPasswordReducer,
    newPassword: newPasswordReducer,

    unProfil: unProfilReducer,

    etudes: etudesReducer,
    uneEtude: uneEtudeReducer,

    uneCalculatriceRentabilite: uneCalculatriceRentabiliteReducer,

    artisans: artisansReducer,
    unArtisan: unArtisanReducer,
    unArtisanDevis: unArtisanDevisReducer,

    banques: banquesReducer,
    uneBanque: uneBanqueReducer,
    uneBanqueOffresPret: uneBanqueOffresPretReducer,

    uneOffrePret: uneOffrePretReducer,

    formations: formationsReducer,
    uneFormation: uneFormationReducer,

    tutoriels: tutorielsReducer,
    unTutoriel: unTutorielReducer,

    projets: projetsReducer,
    unProjet: unProjetReducer,
    unProjetSimulationTravaux: unProjetSimulationTravauxReducer,

    unPatrimoine: unPatrimoineReducer,
    unBien: unBienReducer,
    unBienDocuments: unBienDocumentsReducer,
  },
});
